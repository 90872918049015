import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import {toast} from "react-hot-toast";
import {useNavigate} from "react-router-dom";
import api from "../../../api";
import LocationSelector from "./LocationSelector";
import Button from "../../../components/common/Button";
import TextArea from "../../../components/common/TextArea";
import Box from "../../../components/common/Box";
import ChainedContext from "../../../contexts/chainedContext";
import SettingsReceiver from "../../../contexts/settings_context/Settings_Receiver";
import OrganisationReceiver from "../../../contexts/organisation/receiver";

function CPDForm({ settings, organisation, startAt, endAt, onCancel }) {
  const navigate = useNavigate();

  const [start, setStart] = useState(startAt);
  const [end, setEnd] = useState(endAt);
  const [roles, setRoles] = useState([]);
  const [cpds, setCpds] = useState([]);
  const [location, setLocation] = useState({});
  const [role, setRole] = useState({});
  const [details, setDetails] = useState("");

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    api.settings.getCPDConfig().then((res) => {
      setCpds(res);
    });
    api.settings.getRoles().then((res) => {
      setRoles(
        res.filter((r) => {
          return r.requiresCPD === true;
        })
      );
    });
  };
  const handleSubmit = () => {
    api.calendar.createEvent({
      title: "CPD for: " + role.name,
      location,
      start,
      end,
      cpdRole: role._id,
      isCpdEvent: true
    }).then((res) => {
      toast.success("Successfully created new event");
      navigate(`/events/event/${res._id}`);
    });
  };

  return (
    <div className="w-full ">
      <div className="py-2 text-center text-xl">
        Create Instructor Led CPD Session
      </div>
      <div className="grid grid-cols-2 gap-2">
        <div>
          <Box
            title="Roles requiring CPD"
            other={
              <span className="text-sm italic">* Instructor led only</span>
            }
          >
            <div className="flex flex-wrap gap-2 p-4 text-sm">
              {roles.sort((a, b) => {
                if(a.name > b.name ) return 1;
                if(a.name < b.name ) return -1;
                return 0;
              }).map((r) => {
                let title = r.name;
                if (r.instructorOnly) title = title + " *";
                return (
                  <Button
                    key={r._id}
                    text={title}
                    onClick={() => setRole(r)}
                    colour={`${role._id === r._id && "bg-green-600"}`}
                  />
                );
              })}
            </div>
          </Box>
          <Box>
            <div className="p-2">
              <div className="gap-4p-2 flex justify-around ">
                <div className="flex items-center  text-center">
                  Role
                  {role._id ? (
                    <CheckIcon className="h-6 text-green-600" />
                  ) : (
                    <XMarkIcon className="h-6 text-red-500" />
                  )}
                </div>
                <div className="flex items-center  text-center">
                  Location
                  {location.geometry ? (
                    <CheckIcon className="h-6 text-green-600" />
                  ) : (
                    <XMarkIcon className="h-6 text-red-500" />
                  )}
                </div>
                <div className="flex items-center  text-center">
                  Dates
                  {start !== undefined && end !== undefined && start !== end ? (
                    <CheckIcon className="h-6 text-green-600" />
                  ) : (
                    <XMarkIcon className="h-6 text-red-500" />
                  )}
                </div>
              </div>
              <div className="grid gap-2 sm:grid-cols-2">
                <div className="w-full">
                  <label htmlFor={"date"} className={`labelClass`}>
                    Start
                  </label>
                  <DatePicker
                    onChange={(date) => {
                      setStart(date);
                      setEnd(date);
                    }}
                    selected={start ? new Date(start) : ""}
                    dateFormat="dd-MM-yy HH:mm"
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={30}
                    nextMonthButtonLabel=">"
                    previousMonthButtonLabel="<"
                    className=" w-full rounded border border-pp bg-white p-1 dark:border-zinc-500  dark:bg-zinc-700"
                    data-disable-touch-keyboard
                  />
                </div>
                <div className="w-full ">
                  <label
                    htmlFor={"date"}
                    className={`labelClass ${
                      start !== undefined && start === end && "!text-red-500"
                    }`}
                  >
                    End
                  </label>
                  <DatePicker
                    onChange={(date) => setEnd(date)}
                    selected={
                      end ? new Date(end) : start ? new Date(start) : ""
                    }
                    minDate={start}
                    dateFormat="dd-MM-yy HH:mm"
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={30}
                    nextMonthButtonLabel=">"
                    previousMonthButtonLabel="<"
                    className=" w-full rounded border border-pp bg-white p-1 dark:border-zinc-500  dark:bg-zinc-700"
                    data-disable-touch-keyboard
                  />
                </div>
              </div>
              <TextArea
                label="Details"
                value={details}
                onChange={(e) => setDetails(e.target.value)}
              />
              <div className="mt-4 flex justify-between border-t border-zinc-500 pt-4">
                <button
                  className="rounded-lg bg-red-500 px-4 py-2 text-white"
                  onClick={onCancel}
                >
                  Cancel
                </button>

                <button
                  className="rounded-lg bg-green-500 px-4 py-2 text-white"
                  onClick={handleSubmit}
                >
                  Add
                </button>
              </div>
            </div>
          </Box>
        </div>
        <Box title="Location">
          <LocationSelector
            location={location}
            onChange={setLocation}
            organisation={organisation}
            settings={settings}
          />
        </Box>
      </div>
    </div>
  );
}
export default ChainedContext(CPDForm, [
  [SettingsReceiver, "settings"],
  [OrganisationReceiver, "organisation"],
]);
