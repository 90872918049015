import React, { useMemo } from "react";
import CustomTileLayer from "../custom";
import { XYZ } from "ol/source";

const Google_Satellite = ({ visible = true, zIndex = 0 }) => {
  const source = useMemo(
    () =>
      new XYZ({
        url: "https://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}",
        projection: "EPSG:3857",
        attributions: [
          '© Google <a href="https://developers.google.com/maps/terms">Terms of Use.</a>',
        ],
        attributionsCollapsible: false,
      }),
    []
  );

  return <CustomTileLayer source={source} visible={visible} zIndex={zIndex} />;
};
export default Google_Satellite;
