import React, { Fragment, useState } from "react";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { Dialog, Transition } from "@headlessui/react";
import Button from "../common/Button";
import Box from "../common/Box";

const LocationList = ({
  locations,
  onEditClick,
  onDeleteClick,
  onOrderChange,
  onNewClick,
  onLocationClick,
  hideExtra,
  hideOrdering = false,
  canAdd = true,
  canEdit = true,
  canDelete = true,
}) => {
  const [deleteModal, setDeleteModal] = useState({});

  const renderDeleteModal = () => (
    <Transition appear show={deleteModal._id !== undefined} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={() => setDeleteModal({})}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="my-8 inline-block w-full max-w-md transform overflow-hidden rounded-2xl border-4 border-gray-400 bg-gray-200 p-6 text-left align-middle shadow-xl transition-all">
              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                Deleting Selected Location
              </Dialog.Title>
              <div className="mt-2">
                <p className="text-sm text-gray-900">
                  Are you sure you want to?
                </p>
              </div>

              <div className="mt-4 flex justify-between">
                <button
                  type="button"
                  className="inline-flex justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 text-sm font-medium text-red-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                  onClick={() => setDeleteModal({})}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="inline-flex justify-center rounded-md border border-transparent bg-green-100 px-4 py-2 text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                  onClick={() => {
                    onDeleteClick(deleteModal);
                    setDeleteModal({});
                  }}
                >
                  Delete
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );

  return (
    <>
      {renderDeleteModal()}
      <div className="w-full">
        <Box
          title="Locations"
          button={canAdd ? {
            text: "Add Location",
            colour: "bg-green-600 text-sm text-white hover:text-gray-200",
            action: () => onNewClick(),
          } : false}
        >
          <div className="w-full ">
            <table className="xl:text-md min-w-full table-fixed divide-y divide-gray-300  text-sm dark:divide-zinc-400">
              <thead className="bg-gray-100 text-black dark:bg-zinc-700 dark:text-white">
                <tr>
                  <th
                    scope="col"
                    className="px-2 py-3 text-left text-xs font-medium uppercase "
                  >
                    Details
                  </th>
                  {!hideOrdering && (
                    <th
                      scope="col"
                      className="px-2 py-3 text-left text-xs font-medium uppercase "
                    >
                      Order
                    </th>
                  )}
                  {canEdit && <th
                    scope="col"
                    className="px-2 py-3 text-left text-xs font-medium uppercase "
                  >
                    Actions
                  </th>}
                </tr>
              </thead>
              <tbody className="divide-x divide-y divide-gray-300 dark:divide-gray-500">
                {locations && locations.length > 0 ? (
                  locations.toSorted((a, b) => {
                    if(a.properties?.name?.toLowerCase() > b.properties?.name?.toLowerCase()) return 1;
                    if(a.properties?.name?.toLowerCase() < b.properties?.name?.toLowerCase()) return -1;
                    return 0;
                  }).map((item, index) => (
                    <tr
                      key={index}
                      className={`cursor-pointer focus:bg-gray-300`}
                    >
                      <td
                        className="px-2 py-3 hover:bg-gray-50 dark:hover:bg-zinc-700"
                        onClick={() => onLocationClick(item)}
                      >
                        <div className="grid gap-1 xl:grid-cols-3">
                          <div className=" space-y-1 text-primary dark:text-zinc-400 xl:col-span-2">
                            <div className=" ">
                              Name:
                              <span className="pl-1 text-black dark:text-white">
                                {item.properties.name}
                              </span>
                            </div>
                            <div className=" ">
                              Type:
                              <span className="pl-1 text-ss dark:text-ss">
                                {item.properties.type}
                              </span>
                            </div>
                            <div className=" ">
                              Address:
                              <span className="pl-1 text-black dark:text-white">
                                {item.properties.address}
                              </span>
                            </div>
                            <div className=" ">
                              w3w:
                              <span className="pl-1 text-black dark:text-white">
                                {Array.isArray(item.properties.w3w)
                                  ? item.properties.w3w.map((w) => {
                                      return w + " ";
                                    })
                                  : item.properties.w3w}
                              </span>
                            </div>
                          </div>
                          <div className=" space-y-1 text-primary dark:text-zinc-400 xl:text-right">
                            <div className=" ">
                              Grid:
                              <span className="pl-1 text-black dark:text-white">
                                {item.properties.grid}
                              </span>
                            </div>
                            <div className=" ">
                              Postcode:
                              <span className="pl-1 text-black dark:text-white">
                                {item.properties.postcode}
                              </span>
                            </div>
                            {!hideExtra && (
                              <>
                                <div className=" ">
                                  Dept:
                                  <span className="pl-1 text-black dark:text-white">
                                    {item.properties.dept}
                                  </span>
                                </div>
                                <div className=" ">
                                  Time:
                                  <span className="pl-1 text-black dark:text-white">
                                    {item.properties.time}
                                  </span>
                                </div>
                                <div className=" ">
                                  Order:
                                  <span className="pl-1 text-black dark:text-white">
                                    {item.order}
                                  </span>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </td>
                      {!hideOrdering && canEdit && (
                        <td className=" border-l border-gray-200 px-2 py-3 dark:border-zinc-700 xl:w-20">
                          <div className="text-sm ">
                            <div className="mx-auto gap-4 md:grid md:grid-cols-1">
                              <div className="mb-2 md:mb-0">
                                <Button
                                  colour="bg-gray-200 dark:bg-zinc-700"
                                  disabled={item.order === 0}
                                  hoverColour="hover:bg-gray-300"
                                  icon={<ChevronUpIcon className="h-5 w-5" />}
                                  onClick={() => onOrderChange(item._id, "up")}
                                />
                              </div>
                              <Button
                                colour="bg-gray-200 dark:bg-zinc-700"
                                disabled={item.order >= locations.length - 1}
                                icon={<ChevronDownIcon className="h-5 w-5" />}
                                onClick={() => onOrderChange(item._id, "down")}
                              />
                            </div>
                          </div>
                        </td>
                      )}
                      {canEdit && <td className=" px-2 py-3 xl:w-20">
                        <div className="text-sm ">
                          <div className="mx-auto md:grid md:grid-cols-1 md:gap-4 ">
                            <div className="mb-2 md:mb-0">
                              <Button
                                colour="bg-gray-100 dark:bg-zinc-700"
                                icon={
                                  <PencilIcon className="h-5 w-5 text-blue-600" />
                                }
                                onClick={() => onEditClick(item)}
                              />
                            </div>
                            {canDelete && <Button
                              colour="bg-gray-100 dark:bg-zinc-700"
                              icon={
                                <TrashIcon className="h-5 w-5 text-red-600" />
                              }
                              onClick={() => setDeleteModal(item)}
                            />}
                          </div>
                        </div>
                      </td>}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td className="px-2 py-3" colSpan={3}>
                      <div className="text-center text-sm font-medium">
                        No Data
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </Box>
      </div>
    </>
  );
};

export default LocationList;
