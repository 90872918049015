import { useEffect, useState, Fragment, Suspense } from "react";
import { Routes, Route, Outlet, Link, NavLink } from "react-router-dom";
import {
  Bars3CenterLeftIcon,
  CogIcon,
  HomeIcon,
  UserGroupIcon,
  XMarkIcon,
  ArrowRightOnRectangleIcon,
  UserIcon,
  BriefcaseIcon,
  TruckIcon,
  FireIcon,
  ChartPieIcon,
} from "@heroicons/react/24/outline";
import { Dialog, Transition } from "@headlessui/react";
import { FaBabyCarriage, FaCalendarAlt, FaMapPin } from "react-icons/fa";
import { GiBatteries } from "react-icons/gi";
import { BsMortarboardFill } from "react-icons/bs";
import { Toaster } from "react-hot-toast";
import TS from "./assets/images/logo.png";
import TSO from "./assets/images/logo-orange.png";
import { AuthComponents, Authentication } from "./lib/_sso";
import _buildNumber from "./_buildNumber";
import AppConfig from "./_appConfig";
import SettingsProvider from "./contexts/settings_context/Settings_Provider";
import OrganisationProvider from "./contexts/organisation/Org_Provider";
import API from "./api";
import DocumentPages from "./pages/documents";
import Home from "./pages/Home";
import Settings from "./pages/settings";
import Events from "./pages/events";
import TeamPages from "./pages/team";
import Training from "./pages/training";
import VehiclePages from "./pages/vehicles";
import RecruitmentPages from "./pages/recruitment";
import LocationPages from "./pages/locations";
import AssetsPages from "./pages/assets";
import IncidentPages from "./pages/incident";
import ReportPages from "./pages/reports";
import Skel from "./components/common/Skel";
import FrontendUpdateChecker from "./lib/frontendUpdateChecker";
import HasWebConsoleAccess from "./lib/HasPortalAccess";
import { SocketConnection } from "./lib/socketio-with-auth";


import "./index.css";
import "react-datepicker/dist/react-datepicker.css";
import "mapbox-gl/dist/mapbox-gl.css";


function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
function App() {
  return (
    <AuthComponents.Authentication
      config={{
        clientId: AppConfig.auth.clientId,
        applicationServer: AppConfig.api_server,
        localPrefix: AppConfig.app_storage_prefix,
      }}
    >
      <HasWebConsoleAccess>
        <Toaster position="top right" />
        <FrontendUpdateChecker />

        <SocketConnection socketServer={AppConfig.socket_server}>
          <SettingsProvider api={API}>
          <OrganisationProvider loadData={() => Authentication.userData.organisation}>
            <Suspense fallback={<Skel />}>
              <div className="w-full">
                <Routes>
                  <Route path="/" element={<Layout />}>
                    <Route index element={<Home />} />
                    <Route path={"/asset/*"} element={<AssetsPages />} />
                    <Route path={"/documents/*"} element={<DocumentPages />} />
                    <Route path={"/team/*"} element={<TeamPages />} />
                    <Route path={"/settings/*"} element={<Settings />} />
                    <Route path={"/training/*"} element={<Training />} />
                    <Route path={"/vehicles/*"} element={<VehiclePages />} />
                    <Route path={"/recruitment/*"} element={<RecruitmentPages />}/>
                    <Route path={"/events/*"} element={<Events />} />
                    <Route path={"/locations/*"} element={<LocationPages />} />
                    <Route path={"/incidents/*"} element={<IncidentPages />} />
                    <Route path={"/reports/*"} element={<ReportPages />} />
                    <Route path="*" element={<NoMatch />} />
                  </Route>
                </Routes>
              </div>
            </Suspense>
          </OrganisationProvider>
        </SettingsProvider>
        </SocketConnection>
      </HasWebConsoleAccess>
    </AuthComponents.Authentication>
  );
}

export default App;

const Layout = () => {
  const [darkTheme, setDarkTheme] = useState(true);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [user, setUser] = useState({});
  const [archiveCount, setArchiveCount] = useState(0);

  useEffect(() => {
    if (
      localStorage.getItem("color-theme") === "dark" ||
      (!("color-theme" in localStorage) &&
        window.matchMedia("(prefers-color-scheme: dark)").matches)
    ) {
      setDarkTheme(true);
    } else {
      setDarkTheme(false);
    }
    getData();
  }, []);
  useEffect(() => {
    const getArchiveCount = () => {
      API.member.archivedCount().then(res => {
        setArchiveCount(res.total);
      }).catch(err => {

      })
    };
    getArchiveCount();
    const repeatTask = setInterval(() => {
      getArchiveCount();
    }, 60 * 1000);
    return () => {
      clearInterval(repeatTask)
    }
  }, []);

  const navigation = [
    { name: "Home", href: "/", icon: HomeIcon, exact: true, show: true },
    { name: "The Team", href: "/team", icon: UserGroupIcon, exact: true, show: Authentication.can("teamsite:admin") || Authentication.can("teamsite:management") },
    { name: "Vehicles", href: "/vehicles", icon: TruckIcon, exact: false, show: Authentication.can("teamsite:admin") || Authentication.can("teamsite:management") },
    { name: "Calendar", href: "/events", icon: FaCalendarAlt, exact: false, show: Authentication.can("events:read") },
    { name: "Locations", href: "/locations", icon: FaMapPin, exact: false, show: true },
    { name: "Training", href: "/training", icon: BsMortarboardFill, exact: false, show: Authentication.can("teamsite:admin") || Authentication.can("teamsite:management") || Authentication.can("teamsite:power_user") },
    { name: "Recruitment", href: "/recruitment", icon: FaBabyCarriage, exact: false, show: Authentication.can("teamsite:recruit_management") },
    { name: "Assets", href: "/asset", icon: GiBatteries, exact: false, show: Authentication.can("teamsite:assets_management") },
    { name: "Documents", href: "/documents", icon: BriefcaseIcon, exact: false, show: true },
    { name: "Reports", href: "/reports", icon: ChartPieIcon, exact: false, show: Authentication.can("teamsite:reports") },
  ];
  const secondaryNavigation = [
    { name: "Incidents", href: "/incidents", icon: FireIcon, show: Authentication.can("teamsite:incident_management") },
    { name: "Archived" + (archiveCount !== 0 ? ` (${archiveCount})`: ""), href: "/team/archived", icon: UserGroupIcon, show: Authentication.can("teamsite:admin") || Authentication.can("teamsite:management")  },
    { name: "Settings", href: "/settings", icon: CogIcon, show: Authentication.can("settings:update") }
  ];

  const changeTheme = () => {
    if (darkTheme) {
      document.documentElement.classList.remove("dark");
      localStorage.setItem("color-theme", "light");
    } else {
      document.documentElement.classList.add("dark");
      localStorage.setItem("color-theme", "dark");
    }
    setDarkTheme(!darkTheme);
  };
  const themeButton = () => {
    return (
      <button
        onClick={changeTheme}
        id="theme-toggle"
        type="button"
        className="group flex w-full items-center gap-4 rounded-md px-2.5 py-1 text-base text-black hover:bg-gray-200 hover:text-primary dark:text-white dark:hover:bg-gray-700"
      >
        {darkTheme ? (
          <svg
            id="theme-toggle-light-icon"
            className="h-5 w-5 "
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M21.752 15.002A9.718 9.718 0 0118 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 003 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 009.002-5.998z"
            />
          </svg>
        ) : (
          <svg
            id="theme-toggle-dark-icon"
            className="h-5 w-5 "
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 3v2.25m6.364.386l-1.591 1.591M21 12h-2.25m-.386 6.364l-1.591-1.591M12 18.75V21m-4.773-4.227l-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
            />
          </svg>
        )}{" "}
        Theme
      </button>
    );
  };
  const getData = () => {
    const user = Authentication.userData;
    setUser(user);
  };

  return (
    <div className="overflow-y-scroll scrollbar-hide">
      <div className="min-h-full scrollbar-hide">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative z-40 lg:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-zinc-100 pb-4 pt-5 dark:bg-gray-900">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute right-0 top-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full "
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex flex-shrink-0 items-center px-4">
                    <img
                      className="mx-auto h-10 w-auto"
                      src={darkTheme ? TS : TSO}
                      alt="Teamsite logo"
                    />
                  </div>
                  <div className="text-center text-xl text-pp dark:text-white ">
                    TeamSite
                  </div>
                  <nav
                    className="mt-5 h-full flex-shrink-0 divide-y divide-cyan-800 overflow-y-scroll"
                    aria-label="Sidebar"
                  >
                    <div className="space-y-1 px-2">
                      {navigation.filter((n) => {
                        return n.show === true;
                      }).map((item) => (
                        <NavLink
                          key={item.name}
                          to={item.href}
                          end={item.exact}
                          role={"menuitem"}
                          className={classNames(
                            "group flex items-center rounded-md px-2 py-2 text-base text-black hover:bg-gray-200 hover:text-primary dark:text-white  dark:hover:bg-gray-500 md:py-1"
                          )}
                          onClick={() => setSidebarOpen(false)}
                          aria-current={item.current ? "page" : undefined}
                        >
                          <item.icon
                            className="mr-4 h-6 w-6 flex-shrink-0 text-primary"
                            aria-hidden="true"
                          />
                          {item.name}
                        </NavLink>
                      ))}
                    </div>
                    <div className="mt-2 pt-4">
                      <div className="space-y-1 px-2">
                        {secondaryNavigation
                          .filter((n) => {
                            return n.show === true;
                          })
                          .map((item) => (
                            <NavLink
                              key={item.name}
                              to={item.href}
                              end={item.exact}
                              role={"menuitem"}
                              className="group flex items-center rounded-md px-2 py-2 text-base  text-black hover:bg-gray-200 dark:text-white "
                            >
                              <item.icon
                                className="mr-4 h-6 w-6 text-primary"
                                aria-hidden="true"
                              />
                              {item.name}
                            </NavLink>
                          ))}
                        {themeButton()}
                      </div>
                    </div>
                    <div className="mb-4 flex flex-col justify-start p-4">
                      <div className="text-md flex gap-2 font-medium text-primary dark:text-white">
                        <UserIcon className="h-6 w-6" />
                        <span className={"truncate"}>{user.name}</span>
                      </div>
                      <button
                        onClick={() => Authentication.logout()}
                        className={
                          "text-md flex py-3 text-left align-middle text-cyan-700 hover:text-red-400"
                        }
                      >
                        <ArrowRightOnRectangleIcon className="mr-2 w-6 text-red-800" />
                        Sign Out
                      </button>
                      <p className="ml-2 mt-4 text-xs font-medium text-gray-500">
                        Version: 5.{_buildNumber}
                      </p>
                    </div>
                  </nav>
                </Dialog.Panel>
              </Transition.Child>
              <div className="w-14 flex-shrink-0" aria-hidden="true">
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="z-50 hidden overflow-y-scroll scrollbar-hide lg:fixed lg:inset-y-0 lg:flex lg:w-44 lg:flex-col xl:w-52">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          {/* deleted overflow-y-scroll from below */}
          <div className="flex flex-grow flex-col  border-r-2 border-gray-300 bg-zinc-100 pb-2 pt-4 dark:border-primary  dark:bg-black">
            <div className="flex flex-shrink-0 items-center px-1">
              <img
                className="mx-auto h-16 w-auto "
                src={darkTheme ? TS : TSO}
                alt="Teamsite"
              />
            </div>

            <nav
              className="mt-2 flex flex-1 flex-col divide-y-2 divide-gray-300 overflow-y-auto dark:divide-primary xl:mt-8"
              aria-label="Sidebar"
            >
              <div className="px-2 text-gray-800 hover:text-gray-200 2xl:space-y-2">
                {navigation.filter((n) => {
                  return n.show === true;
                }).map((item) => (
                  <NavLink
                    key={item.name}
                    to={item.href}
                    className={({ isActive }) =>
                      [
                        " group flex items-center rounded-md px-2 py-1 text-sm font-medium lg:text-lg",
                        isActive
                          ? "bg-gray-200 text-black dark:bg-gray-700 dark:text-white dark:hover:bg-gray-500"
                          : "text-gray-800  hover:bg-pp hover:text-white dark:text-white dark:hover:bg-gray-700",
                      ]
                        .filter(Boolean)
                        .join(" ")
                    }
                    role={"menuitem"}
                    end={item.exact}
                  >
                    <item.icon
                      className="mr-4 h-6 w-6 flex-shrink-0  "
                      aria-hidden="true"
                    />
                    {item.name}
                  </NavLink>
                ))}
              </div>
              <div className="mt-2 pt-2 xl:mt-4">
                <div className="px-2 text-gray-800 hover:text-gray-200 xl:space-y-1">
                  {secondaryNavigation
                    .filter((n) => {
                      return n.show === true;
                    })
                    .map((item) => (
                      <NavLink
                        key={item.name}
                        to={item.href}
                        className={({ isActive }) =>
                          [
                            " group flex items-center rounded-md px-2  py-1 text-sm font-medium leading-6 xl:text-lg",
                            isActive
                              ? "bg-gray-100 text-black dark:bg-gray-700 dark:text-white dark:hover:bg-gray-500"
                              : "text-gray-800  hover:bg-pp hover:text-white dark:text-white dark:hover:bg-gray-700",
                          ]
                            .filter(Boolean)
                            .join(" ")
                        }
                        role={"menuitem"}
                        end={item.exact}
                      >
                        <item.icon
                          className="mr-4 h-6 w-6 flex-shrink-0  "
                          aria-hidden="true"
                        />
                        {item.name}
                      </NavLink>
                    ))}
                  {themeButton()}
                </div>
              </div>
            </nav>
            <div className="flex justify-start border-t-2  border-gray-300 p-4 dark:border-primary">
              <div className=" w-full ">
                <div className=" text-left">
                  <div className="flex flex-col justify-start xl:space-y-2">
                    <div className="text-md flex gap-2 font-medium text-primary dark:text-primary-dark">
                      <UserIcon className="h-6"/>
                      <div className="group flex relative">
                        <span className={"truncate"}>{user.name}</span>
                      </div>
                    </div>
                    <div className={"text-xs text-gray-400 dark:text-gray-600 truncate"}>{user.organisation?.name}</div>
                    <button
                      onClick={() => Authentication.logout()}
                      className={
                        "text-md flex py-2 text-left align-middle text-cyan-700 hover:text-red-400"
                      }
                    >
                      <ArrowRightOnRectangleIcon className="mr-2 w-6 text-red-800"/>
                      Sign Out
                    </button>
                    <p className="ml-2 mt-4 text-xs font-medium text-gray-500">
                      Version: 5.{_buildNumber}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-1 flex-col lg:pl-44 xl:pl-52">
          <div className="flex h-16 flex-shrink-0 border-b border-zinc-300 bg-gray-100 dark:border-gray-700 dark:bg-gray-900 lg:hidden">
            <button
              type="button"
              className="px-4 text-gray-100 "
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3CenterLeftIcon
                className="h-6 w-6 text-gray-600 dark:text-white"
                aria-hidden="true"
              />
            </button>

            <div className="flex flex-1 justify-between px-4 sm:px-6 lg:mx-auto lg:px-8">
              <div className="0 flex flex-1 items-center text-xl">Teamsite</div>
            </div>
          </div>
          <main>
            <Outlet />
          </main>
        </div>
      </div>
    </div>
  );
};

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}