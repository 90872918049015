import React from "react";
import toast from "react-hot-toast";
import API from "../api";
import BuildNumber from "../_buildNumber";

export default class FrontendUpdateChecker extends React.Component {
  checkInterval = 5; // 5 minute updates check
  updateChecker = null;
  state = {
    showReloadWarning: false,
  };
  componentDidMount() {
    this.checkForUpdates();
    this.updateChecker = setInterval(() => {
      this.checkForUpdates();
    }, this.checkInterval * 60 * 1000);
  }
  checkForUpdates() {
    API._getBuildNumber().then((version) => {
      if (version.toString() !== BuildNumber.toString()){
          toast(t => {
              return (
                  <div className={`${t.visible ? 'animate-enter' : 'animate-leave'}`}>
                      <p>There is a new version of the <strong>TeamSite</strong> application. When you have a free moment please click
                          "Reload Application" to load the latest update.</p>
                      <button className="bg-gray-400 text-white rounded-xl p-2 mt-4" onClick={() => toast.dismiss(t.id)} >Close</button>
                      <button className="bg-blue-600 text-white rounded-xl p-2 mt-4 float-right"  onClick={() => {
                          // eslint-disable-next-line
                          window.location = "/";
                      }}>Reload Application</button>
                  </div>
              );
          }, {
              duration: this.checkInterval * 60 * 1000 - 5,
          });
      }
    });
  }
  render() {
    return null;
  }
}