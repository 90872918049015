import React, { useState, useEffect, useContext } from "react";
import dayjs from "dayjs";
import { PencilIcon, PrinterIcon } from "@heroicons/react/24/outline";
import { useNavigate, useParams } from "react-router-dom";
import AssetContext from "../_context";
import api from "../../../../api";
import Box from "../../../../components/common/Box";
import DataBox from "../../../../components/common/DataBox";
import DataRow from "../../../../components/common/DataRow";
import Layout from "../../../../components/common/Layout";
import Button from "../../../../components/common/Button";

export default function ServicePage() {
  const navigate = useNavigate();
  const { service_id } = useParams();
  const asset = useContext(AssetContext);

  const [service, setService] = useState({
    type: "",
    date_scheduled: "",
    date_serviced: "",
    notes: "",
    status: "",
    replacedParts: [],
    documents: [],
    supplier: "",
    asset: "",
    partsCost: "",
    labourCost: "",
    totalCost: "",
  });
  useEffect(() => {
    api.assets.service.show(asset._id, service_id).then((res) => {
      setService({
        ...service,
        ...res,
      });
    });
  }, []);

  const gotoSupplier = (id) => {
    navigate(`/asset/suppliers/${id}`);
  };

  return (
    <Layout full>
      <div className="mb-4 text-center text-2xl">
        <div className="grid gap-4 p-2 sm:grid-cols-5">
          <DataBox
            label="Name"
            value={asset.name}
            onClick={() => navigate(`/asset/view/${asset._id}`)}
          />
          <DataBox
            label="Asset Num"
            value={asset.assetNum}
            onClick={() => navigate(`/asset/view/${asset._id}`)}
          />
          <DataBox label="Service Interval" value={asset.serviceInterval} />
          <DataBox
            label="Last Service"
            value={asset.LastService ? asset.LastService : "--"}
          />
          <DataBox
            label="Next Service"
            value={asset.NextService ? asset.NextService : "--"}
          />
        </div>
      </div>
      <div className="grid grid-cols-1 gap-2 md:grid-cols-2">
        <div className="col-span-1 ">
          <Box title="Service Details">
            <div className="flex justify-around gap-1 bg-zinc-200 p-2 dark:bg-zinc-800">
              <Button
                text="Edit"
                icon={<PencilIcon className="h-6 pr-2 text-blue-400" />}
                onClick={() =>
                  navigate(
                    `/asset/view/${asset._id}/service/${service._id}/edit`
                  )
                }
              />
              <Button
                text="Print"
                icon={<PrinterIcon className="h-6 pr-2 text-blue-400" />}
                onClick={() => setCopy(true)}
              />
            </div>
            <DataRow label={"Service Type"} value={service.type} />
            <DataRow
              label={"Scheduled Date"}
              value={
                service.date_scheduled
                  ? dayjs(service.date_scheduled).format("DD-MM-YYYY")
                  : "--"
              }
            />
            <DataRow
              label={"Serviced Date"}
              value={
                service.date_serviced
                  ? dayjs(service.date_serviced).format("DD-MM-YYYY")
                  : "--"
              }
            />
            <DataRow label={"Status"} value={service.status} />
            <DataRow label={"Parts Cost"} value={service.partsCost} />
            <DataRow label={"Labour Cost"} value={service.labourCost} />
            <DataRow label={"Total Cost"} value={service.totalCost} />
            <DataRow
              label={"Supplier"}
              value={
                asset.supplier && asset.supplier.name ? (
                  <Button
                    onClick={() => gotoSupplier(asset.supplier._id)}
                    text={asset.supplier.name}
                  />
                ) : (
                  "--"
                )
              }
            />
            <DataRow label={"Notes"} value={asset.notes} />
          </Box>
        </div>
        <div className="col-span-1 space-y-2">
          <Box title="Documents">
            <table className="tableClass">
              <thead>
                <tr className="tableHeadRowClass">
                  <th className="tableHeadCellClass ">Name</th>

                  <th className="tableHeadCellClass w-28">Type</th>
                </tr>
              </thead>
              <tbody className="tableBodyClass">
                {service.documents.map((a, i) => {
                  return (
                    <tr key={i} className="tableBodyRowClass">
                      <td className="tableBodyCellClass">{a.className}</td>

                      <td className="tableBodyCellClass">{a.type}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Box>

          <Box title="Replaced Parts">
            <table className="tableClass">
              <thead>
                <tr className="tableHeadRowClass">
                  <th className="tableHeadCellClass w-20">Qty</th>

                  <th className="tableHeadCellClass ">Item</th>
                  <th className="tableHeadCellClass w-20">Cost</th>
                </tr>
              </thead>
              <tbody className="tableBodyClass">
                {service.replacedParts.map((a, i) => {
                  return (
                    <tr key={i} className="tableBodyRowClass">
                      <td className="tableBodyCellClass">{a.qty}</td>

                      <td className="tableBodyCellClass">{a.item}</td>
                      <td className="tableBodyCellClass">{a.cost}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Box>
        </div>
      </div>
    </Layout>
  );
}
