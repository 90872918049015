import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import DayJS from "dayjs";
import Input from "../../../components/common/Input";
import Button from "../../../components/common/Button";

const IncidentPeriodForm = ({editPeriod = null, onSubmit}) => {
  const [refNum, setRefNum] = useState("");
  const [startAt, setStartAt] = useState("");
  const [endAt, setEndAt] = useState("");
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if(editPeriod !== null){
      setRefNum(editPeriod.refNum);
      setStartAt(DayJS(editPeriod.startAt).toDate());
      setEndAt(DayJS(editPeriod.endAt).toDate());
    }
  }, [editPeriod]);

  const validate = () => {
    const newErrors = {};

    if(!refNum) newErrors.refNum = "Can not be empty";
    if(!startAt) newErrors.startAt = "Can not be empty";
    if(!endAt) newErrors.endAt = "Can not be empty";

    if(DayJS(startAt).isAfter(DayJS(endAt))) newErrors.startAt = "Must be set before End At"

    return newErrors;
  };
  const resetForm = () => {
    setRefNum("");
    setStartAt("");
    setEndAt("");
  }
  const handleSubmit = () => {
    const errors = validate();
    setErrors(errors);

    if (Object.keys(errors).length === 0) {
      onSubmit({
        refNum,
        startAt,
        endAt
      }).then(() => {
        resetForm();
      });
    }
  }

  return (
    <>
      <div className="flex justify-around gap-4 p-2">
        <div className="w-40">
          <Input label={"ID/Ref"} value={refNum} field={"refNum"} onChange={e => setRefNum(e.target.value)} error={errors.refNum !== undefined} />
          <span className={"text-red-500"}>{errors.refNum}</span>
        </div>
        <div className={"w-full"}>
          <label className={`${errors.startAt !== undefined ? "text-red-400" : "labelClass"}`}>Start At</label>
          <DatePicker
            name="startAt"
            onChange={date => setStartAt(date)}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            timeInputLabel="Time:"
            dateFormat="dd-MM-yyyy HH:mm"
            maxDate={new Date()}
            className="inputClass z-50"
            selected={startAt}
          />
          <span className={"text-red-500"}>{errors.startAt}</span>
        </div>
        <div className={"w-full"}>
          <label className={`${errors.endAt !== undefined ? "text-red-400" : "labelClass"}`}>End At</label>
          <DatePicker
            name="endAt"
            onChange={date => setEndAt(date)}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            timeInputLabel="Time:"
            dateFormat="dd-MM-yyyy HH:mm"
            maxDate={new Date()}
            className="inputClass z-50"
            selected={endAt}
          />
          <span className={"text-red-500"}>{errors.endAt}</span>
        </div>
        <div className={"w-32 pt-7"}>
          <Button text={editPeriod !== null ? "Save" : "Add"} onClick={handleSubmit} colour="bg-green-700 text-white"/>
        </div>
      </div>
    </>
  );
}
export default IncidentPeriodForm;