import React, { useEffect, useState } from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import CommonFunctions from "../../../lib/CommonFunctions";
import ChainedContext from "../../../contexts/chainedContext";
import SettingsReceiver from "../../../contexts/settings_context/Settings_Receiver";

const WhatThreeWordsForm = ({ settings, onLocationChange, location }) => {
  const [first, setFirst] = useState("");
  const [second, setSecond] = useState("");
  const [third, setThird] = useState("");
  const [error, setError] = useState(null);

  useEffect(() => {
    if (location?.properties !== undefined) {
      if (
        Array.isArray(location.properties?.w3w) &&
        location.properties?.w3w.length === 3
      ) {
        setFirst(location.properties?.w3w[0]);
        setSecond(location.properties?.w3w[1]);
        setThird(location.properties?.w3w[2]);
      } else {
        const data =
          location.properties?.w3w && location.properties?.w3w.split(".");
        setFirst(data[0]);
        setSecond(data[1]);
        setThird(data[2]);
      }
    }
  }, [location]);

  const handleFindLocationWithWords = async () => {
    setError(null);

    const w3w = await CommonFunctions.findW3w(first, second, third);
    if (w3w.error !== undefined) {
      setError(w3w.error);
      return;
    }
    const grid = CommonFunctions.getGrid(
      w3w.coordinates.lat,
      w3w.coordinates.lng,
      settings.gridType
    );
    // console.log(w3w);
    const geoJSON = {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [w3w.coordinates.lng, w3w.coordinates.lat],
      },
      properties: {
        ...(location?.properties || {}),
        name: first + "." + second + "." + third,
        w3w: w3w.words,
        grid: grid?.toUpperCase() || "",
        address: "",
        town: "",
        postcode: "",
      },
    };

    onLocationChange(geoJSON);
  };

  return (
    <>
      {error && (
        <div className={"mb-4 rounded border border-red-900 p-2 text-red-500"}>
          {error}
        </div>
      )}
      <div className="flex flex-row items-center gap-2">
        <input
          name="first"
          value={first}
          placeholder="jammy"
          onChange={(e) => setFirst(e.target.value.toUpperCase())}
          autoComplete="off"
          className={
            "w-full rounded-md border border-gray-600 bg-gray-200 p-2 uppercase dark:bg-zinc-700 "
          }
        />
        <input
          name="second"
          value={second}
          placeholder="generally"
          onChange={(e) => setSecond(e.target.value.toUpperCase())}
          autoComplete="off"
          className={
            "w-full rounded-md border border-gray-600 bg-gray-200 p-2 uppercase dark:bg-zinc-700 "
          }
        />
        <input
          name="third"
          value={third}
          placeholder="petulant"
          onChange={(e) => setThird(e.target.value.toUpperCase())}
          autoComplete="off"
          className={
            "w-full rounded-md border border-gray-600 bg-gray-200 p-2 uppercase dark:bg-zinc-700 "
          }
        />
        <button
          disabled={third === "" || second === "" || first === ""}
          onClick={handleFindLocationWithWords}
          className="rounded-md p-2 text-primary hover:text-gray-700"
        >
          <MagnifyingGlassIcon className="h-8 cursor-pointer" />
        </button>
      </div>
    </>
  );
};
export default ChainedContext(WhatThreeWordsForm, [
  [SettingsReceiver, "settings"],
]);
