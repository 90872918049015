import React from "react";
import {toast} from "react-hot-toast";
import {useNavigate, useSearchParams} from "react-router-dom";
import EventEntryForm from "./_components/EventEntryForm";
import Box from "../../components/common/Box";
import api from "../../api";

const CreateEvent = () => {
  const [search, setSearch] = useSearchParams();
  const navigate = useNavigate();

  const handleCreateEvent = (data) => {
    api.calendar.createEvent(data).then((res) => {
      toast.success("Successfully created new event");
      navigate(`/events/event/${res._id}`);
    });
  };

  return (
    <div className={"p-4"}>
      <Box title={"Add Event"}>
        <div className={"p-2"}>
          <EventEntryForm onSubmit={handleCreateEvent} startAt={search.get("startAt")} endAt={search.get("endAt")} onCancel={() => setIsOpen(false)}/>
        </div>
      </Box>
    </div>
  );
}
export default CreateEvent;