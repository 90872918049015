import { APIRequest } from "../lib/_sso";
import AppConfig from "../_appConfig";
const server = AppConfig.api_server;

export default {
  list: () => APIRequest.get(`${server}/course/list`).then(res => res.data),
  quickAddResult: data => APIRequest.post(`${server}/course/quick_add_result`, data).then(res => res.data),

  events: {
    upcoming: () => APIRequest.get(`${server}/course/events/upcoming`).then(res => res.data),
    past: () => APIRequest.get(`${server}/course/events/past`).then(res => res.data),
    add: data => APIRequest.post(`${server}/course/events`, data).then(res => res.data),
    update: (id, data) => APIRequest.patch(`${server}/course/events/${id}`, data).then(res => res.data),
    delete: id => APIRequest.delete(`${server}/course/events/${id}`).then(res => res.data),
    details: id => APIRequest.get(`${server}/course/events/${id}`).then(res => res.data),
    results: id => APIRequest.get(`${server}/course/events/${id}/results`).then(res => res.data),
    updateAttendance: (id, data) => APIRequest.patch(`${server}/course/events/${id}/attendance`, data).then(res => res.data),
    updateResult: (id, memberId, data) => APIRequest.patch(`${server}/course/events/${id}/member/${memberId}/results`, data).then(res => res.data),
    assignMemberMissingRoles: (eventId, memberId) => APIRequest.post(`${server}/course/events/${eventId}/member/${memberId}/assign_missing_roles`, {}).then(res => res.data)
  }
}