import React from "react";
import {Outlet, Route, Routes} from "react-router-dom";
import SupplierDash from "./dash";
import Supplier from "./supplier";
import SupplierNav from "./navBar";
import ContactsPage from "./contacts";
import AddSupplier from "./addSupplier";
import AssetsPage from "./assets";
import EditSupplier from "./editSupplier";

function SupplierPages() {
  return (
    <>
      <SupplierNav />
      <Routes>
        <Route exact path="/contacts" element={<ContactsPage />} />
        <Route exact path="/edit" element={<EditSupplier />} />
        <Route exact path="/asset" element={<AssetsPage />} />
        <Route index element={<Supplier />} />
      </Routes>
    </>
  );
}

export default function SuppliersPages() {
  return (
    <Routes>
      <Route index element={<SupplierDash />} />
      <Route path="/add" element={<AddSupplier />} />
      <Route path="/:supplier_id/*" element={<SupplierPages />} />
    </Routes>
  );
}
