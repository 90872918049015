import React, {useEffect, useMemo, useState} from "react";
import {toast} from "react-hot-toast";
import DayJS from "dayjs";
import {APIRequest} from "../../../lib/_sso";
import AppConfig from "../../../_appConfig";
import Select from "../../../components/common/Select";
import ToggleSwitch from "../../../components/common/ToggleSwitch";
import {useSocketIo} from "../../../lib/socketio-with-auth";


const server = AppConfig.api_server;


const TeamSiteMigration = ({ config, auth }) => {
  const socketIo = useSocketIo();

  const [isLoadingHeaderData, setIsLoadingHeaderData] = useState(false);
  const [isImportRunning, setIsImportRunning] = useState(false);
  const [importCompleteData, setImportCompleteData] = useState(null)

  const [pullFrom, setPullFrom] = useState("");
  const [organisation, setOrganisation] = useState("");

  const [wipeDestination, setWipeDestination] = useState(false);
  const [pushToCentral, setPushToCentral] = useState(false);
  const [overviewData, setOverviewData] = useState({});

  const [logs, setLogs] = useState([
    [new Date(), "Importer is ready"]
  ]);

  const selectedTeamSite = useMemo(() => {
    const a = config?.pullFrom?.filter(a => a.key === pullFrom) || []
    return a[0] !== undefined ? a[0] : false;
  }, [config, pullFrom]);

  useEffect(() => {
    if (auth.token === "" || pullFrom === "" || organisation === "") return;

    setIsLoadingHeaderData(true);
    APIRequest.get(`${server}/settings/importer/data/${pullFrom}/${organisation}/overview?token=${auth.token}`).then(res => {
      setOverviewData(res.data);
      setIsLoadingHeaderData(false);
    }).catch(() => {
      setOverviewData({});
      setIsLoadingHeaderData(false);
      toast.error("Unable to retrieve overview data from TeamSite")
    })
  }, [auth.token, pullFrom, organisation]);
  useEffect(() => {
    if (!socketIo) return;

    const handleNewLogEntry = (logData) => {
      setLogs((currentLogs) => {
        return [logData].concat(currentLogs);
      });
    };
    const handleComplete = (data) => {
      setImportCompleteData(JSON.stringify(data.oldIds));
      setIsImportRunning(false);
    }

    socketIo.on("import:progress", handleNewLogEntry);
    socketIo.on("import:complete", handleComplete);
    return () => {
      socketIo.removeListener("import:progress", handleNewLogEntry);
      socketIo.removeListener("import:complete", handleComplete);
    }
  }, [socketIo])

  const handleDataImport = () => {
    setIsImportRunning(true);
    setLogs([]);

    socketIo.emit("import:teamsite:data", {
      token: auth.token,
      teamsiteId: pullFrom,
      organisationId: organisation,
      wipeDestination,
      pushToCentral
    });
  };

  return (
    <>
      {config.pullFrom === undefined && <div>Loading...</div>}
      {config.pullFrom !== undefined && <>
        Configure Data Import

        <div className="flex-1">
          <label className="labelClass">Pull Data From</label>
          <div className="">
            <Select
              disabled={isImportRunning}
              options={config.pullFrom.map(item => {
                return {
                  key: item.key,
                  text: `${item.type} | ${item.server}`,
                  value: item.key
                }
              })}
              value={pullFrom}
              onChange={(e) => setPullFrom(e.target.value)}
            />
          </div>

          {selectedTeamSite && <>
            <label className="labelClass">Which Team</label>
            <div className="">
              <Select
                disabled={isImportRunning}
                options={selectedTeamSite.organisations.sort((a, b) => {
                  if (a.name > b.name) return 1;
                  if (a.name < b.name) return -1;
                  return 0;
                }).map(item => {
                  return {
                    key: item._id,
                    value: item._id,
                    text: item.name
                  }
                })}
                value={organisation}
                onChange={(e) => setOrganisation(e.target.value)}
              />
            </div>
          </>}

          {isLoadingHeaderData && <div>Loading...</div>}
          {overviewData.assets !== undefined && <>
            <h2 className={"pt-4"}>The Data Set</h2>
            <div className={"m-4 flex flex-wrap items-start gap-4"}>{Object.keys(overviewData).map((key, i) => {
              return (
                <div key={i} className={"border rounded p-2 text-center"}>{overviewData[key]}<br/>{key}</div>
              );
            })}</div>

            <div className="">
              <label className="labelClass">Update Team Members Central Accounts? (i.e. this TeamSite should become source of truth after import)</label>
              <ToggleSwitch
                disabled={isImportRunning}
                isChecked={pushToCentral}
                label1={"No"}
                label2={"Yes"}
                onChange={() => setPushToCentral(!pushToCentral)}
              />
            </div>
            <div className="">
              <label className="labelClass">Acknowledge that the destination will be wiped before import begins?</label>
              <ToggleSwitch
                disabled={isImportRunning}
                isChecked={wipeDestination}
                label1={"No"}
                label2={"Yes"}
                onChange={() => setWipeDestination(!wipeDestination)}
              />
            </div>


            <div className="text-center">
              {isImportRunning && <>
                <div className={"border rounded my-4 p-4 text-xl"}>Importing data... please wait</div>
              </>}
              {importCompleteData && <>
                <label>Imported Old IDs | Save this for future imports</label>
                <textarea rows={5} className={"w-full border rounded dark:text-black p-2"}>{importCompleteData}</textarea>
              </>}
            </div>
            <div className={"border rounded my-2 p-1 overflow-y-auto h-60"}>{logs.map((item, index) => {
              return (<div key={index} className={"pb-1"}>
                <span className={"text-sm mr-3"}>{DayJS(item[0]).format("DD-MM-YYYY hh:mm:sss a")}</span> {item[1]}
              </div>);
            })}</div>
            <div className="text-right">
              <button
                disabled={isImportRunning || !wipeDestination}
                onClick={handleDataImport}
                className="h-10 cursor-pointer rounded-md border border-green-500 bg-green-300 bg-opacity-40 px-4 py-2 text-white hover:border-green-300 disabled:cursor-not-allowed disabled:opacity-50"
              >Begin</button>
            </div>
          </>}
        </div>
      </>}
    </>
  );
}

export default TeamSiteMigration;