import React, { useState, useEffect, Fragment } from "react";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";
import Axios from "axios";
import { toast } from "react-hot-toast";
import api from "../../../api";
import fileDownloader from "js-file-download";
import ChainedContext from "../../../contexts/chainedContext";
import MemberReceiver from "../../../contexts/member_context/Member_Receiver";
import Select from "../../../components/common/Select";
import Box from "../../../components/common/Box";
import Input from "../../../components/common/Input";
import TextArea from "../../../components/common/TextArea";
import Button from "../../../components/common/Button";
import { Skeleton } from "../../../components/ui/skeleton";
import FileUploader from "../../../components/common/FileUploader";
import AppConfig from "../../../_appConfig";
import PrettyBytes from "pretty-bytes";

function CPD({ member }) {
  const [roles, setRoles] = useState([]);
  const [role, setRole] = useState(null);
  const [date, setDate] = useState("");
  const [cpdType, setCPDType] = useState("");
  const [details, setDetails] = useState("");
  const [hours, setHours] = useState(0);
  const [configs, setConfigs] = useState([]);
  const [cpdTypes, setCPDTypes] = useState([]);
  const [records, setRecords] = useState([]);
  const [files, setFiles] = useState([]);
  const [editAttachments, setEditAttachments] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [editingId, setEditingId] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getData();
  }, [member]);
  useEffect(() => {
    if (role !== null) {
      api.settings.getCPDConfigByRole(role).then((res) => {
        setCPDTypes(res);
      });
    }
  }, [role]);

  const getData = () => {
    Promise.all([
      api.member.cpd.getRoles(member._id),
      api.member.cpd.getRecords(member._id),
      api.member.cpd.getRequirements(member._id),
    ])
      .then((res) => {
        setRoles(res[0]);
        setRecords(res[1]);
        setConfigs(res[2]);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const onSubmit = () => {
    let formData = new FormData();
    formData.append("filesCount", files.length);
    for (const fileIndex in files) {
      formData.append("files[" + fileIndex + "]", files[fileIndex]);
    }
    formData.append("role", role);
    formData.append("date", date);
    formData.append("type", cpdType);
    formData.append("details", details);
    formData.append("hours", hours);

    if (isEdit && editingId) {
      formData.append(
        "editAttachments",
        JSON.stringify(editAttachments.map((a) => a._id))
      );

      api.member.cpd.editRecord(member._id, editingId, formData).then(() => {
        getData();
        onCancel();
      });
    } else {
      api.member.cpd.addRecord(member._id, formData).then(() => {
        getData();
        onCancel();
      });
    }
  };
  const startFileDownload = (a, b) => {
    api.member.cpd
      .downloadFile(member._id, a, b)
      .then((res) => {
        toast.success("Starting to download file...");
        Axios.get(AppConfig.api_server + "/.." + res.link, {
          responseType: "blob",
        })
          .then((fileData) => {
            fileDownloader(fileData.data, res.filename);
          })
          .catch(() => {
            toast.error(
              "An error occurred while downloading the requested file"
            );
          });
      })
      .catch(() => {
        toast.error(
          "Failed to start file download due to an error while retrieving a access link"
        );
      });
  };
  const startEditing = (theCPD) => {
    setIsEdit(true);
    setEditingId(theCPD._id);
    setRole(theCPD.role._id);
    setDate(new Date(theCPD.date));
    setDetails(theCPD.details);
    setCPDType(theCPD.type);
    setHours(theCPD.hours);
    setEditAttachments(theCPD.attachments);
  };
  const onCancel = () => {
    setIsEdit(false);
    setEditingId("");
    setEditAttachments([]);

    setRole(null);
    setDate("");
    setDetails("");
    setCPDType("");
    setHours("");
    setFiles([]);
  };

  return (
    <div className="space-y-4 p-4">
      <Box title={`${member.firstName}'s roles requiring CPD`}>
        {loading && (
          <div className="space-y-6 p-4">
            <div className="flex gap-8">
              <Skeleton className="h-20 w-28 p-4 dark:bg-zinc-700"></Skeleton>
              <Skeleton className="h-20 w-28 p-4 dark:bg-zinc-700"></Skeleton>
              <Skeleton className="h-20 w-28 p-4 dark:bg-zinc-700"></Skeleton>
              <Skeleton className="h-20 w-28 p-4 dark:bg-zinc-700"></Skeleton>
              <Skeleton className="h-20 w-28 p-4 dark:bg-zinc-700"></Skeleton>
              <Skeleton className="h-20 w-28 p-4 dark:bg-zinc-700"></Skeleton>
            </div>
          </div>
        )}
        {!loading && (
          <div className="flex gap-2 flex-wrap p-2">
            {roles.sort((a, b) => {
              if(a.role.name > b.role.name ) return 1;
              if(a.role.name < b.role.name ) return -1;
              return 0;
            }).map((r, i) => {
              return (
                <button
                  key={i}
                  onClick={() => setRole(r.role._id)}
                  className={`rounded p-2 ${
                    r.isActive ? "oncall" : "nonOp"
                  } border text-sm dark:border-zinc-500`}
                >
                  {r.role.name}
                </button>
              );
            })}
            {roles.length === 0 && (
              <div className="p-4 text-xl">No roles to display</div>
            )}
          </div>
        )}
        {role !== null && (
          <div className="p-2">
            <div className={"border-t border-blue-600 pt-2"}>
            <div className="flex gap-4">
              <div className="w-3/4 p-4">
                <div className="flex gap-4">
                  <div className="w-80">
                    <Select
                      label="Role"
                      options={roles
                        .filter((r) => {
                          return r.role.requiresCPD === true;
                        })
                        .map((item) => {
                          return {
                            key: item.role._id,
                            text: item.role.name,
                            value: item.role._id,
                          };
                        })}
                      value={role}
                      onChange={(e) => setRole(e.target.value)}
                    />
                  </div>
                  <div className="w-80 ">
                    <label
                      htmlFor={"date"}
                      className={`block   dark:text-zinc-400 `}
                    >
                      Date
                    </label>
                    <DatePicker
                      onChange={(date) => {
                        setDate(date);
                      }}
                      selected={date}
                      // showTimeSelect
                      // timeFormat="HH:mm"
                      // timeIntervals={5}
                      // timeInputLabel="Time:"
                      dateFormat="dd-MM-yy"
                      maxDate={new Date()}
                      nextMonthButtonLabel=">"
                      previousMonthButtonLabel="<"
                      className=" w-full rounded border border-pp bg-white p-1 dark:border-zinc-500  dark:bg-zinc-700"
                      data-disable-touch-keyboard
                    />
                  </div>
                  <div className="w-80">
                    <Select
                      label="CPD Requirement"
                      options={cpdTypes.map((item) => {
                        return {
                          key: item._id,
                          text: item.type,
                          value: item.type,
                        };
                      })}
                      value={cpdType}
                      onChange={(e) => setCPDType(e.target.value)}
                    />
                  </div>
                  <div className="w-24">
                    <Input
                      type="number"
                      label={"Hours"}
                      onChange={(e) => setHours(e.target.value)}
                      value={hours}
                    />
                  </div>
                </div>
                <TextArea
                  label={"What did you do"}
                  onChange={(e) => setDetails(e.target.value)}
                  value={details}
                />
              </div>
              <div className="w-1/4">
                <FileUploader onFileSelected={setFiles} isMulti />
              </div>
            </div>
            {editAttachments.length >= 1 && (
              <div className={"px-4"}>
                <h3 className={"labelClass"}>Attachments</h3>
                <table className="tableClass">
                  <thead>
                    <tr className="tableHeadRowClass">
                      <th className="tableHeadCellClass">Filename</th>
                      <th className="tableHeadCellClass">Type</th>
                      <th className="tableHeadCellClass">Size</th>
                      <th className="tableHeadCellClass">{""}</th>
                    </tr>
                  </thead>
                  <tbody className="tableBodyClass">
                    {editAttachments.map((attachment) => {
                      return (
                        <tr
                          key={attachment._id}
                          className={`tableBodyRowClass`}
                        >
                          <td className="tableBodyCellClass">
                            {attachment.filename}
                          </td>
                          <td className="tableBodyCellClass w-64">
                            {attachment.contentType}
                          </td>
                          <td className="tableBodyCellClass w-32">
                            {PrettyBytes(attachment.length)}
                          </td>
                          <td className="tableBodyCellClass w-12">
                            <button
                              className={
                                "w-7 rounded-3xl border p-1 text-sm text-red-600"
                              }
                              onClick={() =>
                                setEditAttachments((a) =>
                                  a.filter((b) => b._id !== attachment._id)
                                )
                              }
                            >
                              X
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}

            <div className="mt-2 flex flex-row justify-between gap-2 border-t border-gray-600 p-4">
              <Button
                text="Cancel"
                onClick={onCancel}
                colour="nonOp  border border-red-800 hover:bg-red-600"
                textColour="text-black dark:text-white"
                hoverColour="text-gray-100"
              />
              {isEdit ? (
                <Button
                  text="Update "
                  onClick={onSubmit}
                  textColour="text-black dark:text-white"
                  colour="oncall border border-green-700 hover:bg-green-600 "
                  hoverColour="text-gray-100"
                />
              ) : (
                <Button
                  text="Add "
                  onClick={onSubmit}
                  textColour="text-black dark:text-white"
                  colour="oncall border border-green-700 hover:bg-green-600 "
                  hoverColour="text-gray-100"
                />
              )}
            </div>
          </div>
          </div>
        )}
      </Box>
      {roles.length >= 1 && <>
        <Box title="CPD Requirements">
          <table className="tableClass">
            <thead>
            <tr className="tableHeadRowClass">
              {/* <th className="w-4 px-4 py-3 text-left  ">Role</th> */}
              <th className="tableHeadCellClass">Type</th>
              <th className="tableHeadCellClass">Required Hours</th>
              <th className="tableHeadCellClass">Within</th>
              <th className="tableHeadCellClass">Actual Hours</th>
            </tr>
            </thead>
            <tbody className="tableBodyClass">
            {configs.map((c) => {
              return (
                <Fragment key={c._id}>
                  <tr
                    key={c._id}
                    onClick={() => setRole(c.role._id)}
                    className={`tableBodyRowClass cursor-pointer`}
                  >
                    <th colSpan={5} className="tableHeadCellClass text-lg">
                      {c.role?.name}
                    </th>
                  </tr>
                  {c.config.map((cn) => {
                    return (
                      <tr
                        key={cn._id}
                        className={`tableBodyRowClass ${
                          cn.actual >= cn.hours && " oncall"
                        } `}
                      >
                        {/* <td></td> */}
                        <td className="border-b p-2 pl-6 dark:border-zinc-700">
                          {cn.type}
                        </td>
                        <td className=" border-b p-2 text-center dark:border-zinc-700">
                          {cn.hours}
                        </td>
                        <td className=" border-b p-2 text-center dark:border-zinc-700">
                          {cn.within} {cn.withinType}
                        </td>
                        <td className="w-20 border-b p-2 text-center dark:border-zinc-700">
                          {cn.actual}
                        </td>
                      </tr>
                    );
                  })}
                </Fragment>
              );
            })}
            </tbody>
          </table>
        </Box>
        <Box title="CPD Records">
          <div className="p-0">
            <table className="tableClass">
              <thead>
              <tr className="tableHeadRowClass">
                <th className="tableHeadCellClass">Date</th>
                <th className="tableHeadCellClass">Role</th>
                <th className="tableHeadCellClass">Type</th>
                <th className="tableHeadCellClass">Hours</th>
                <th className="tableHeadCellClass">Details</th>
                <th className="tableHeadCellClass">Attachments</th>
              </tr>
              </thead>
              <tbody className="tableBodyClass">
              {records !== undefined ? (
                records.map((r) => {
                  return (
                    <tr
                      key={r._id}
                      onClick={() => startEditing(r)}
                      className={`tableBodyRowClass cursor-pointer`}
                    >
                      <td className="tableBodyCellClass w-28">
                        {dayjs(r.date).format("DD-MM-YYYY")}
                      </td>
                      <td className="tableBodyCellClass w-80">{r.role?.name}</td>
                      <td className="tableBodyCellClass">{r.type}</td>
                      <td className="tableBodyCellClass w-15 text-center">
                        {r.hours}
                      </td>
                      <td className="tableBodyCellClass">{r.details}</td>
                      <td className="tableBodyCellClass w-80">
                        {r.attachments === undefined ||
                        r.attachments.length === 0
                          ? "--"
                          : r.attachments.map((attachment) => {
                            return (
                              <div key={attachment._id}>
                                <button
                                  className={"underline"}
                                  onClick={() =>
                                    startFileDownload(r._id, attachment._id)
                                  }
                                >
                                  {attachment.filename}
                                </button>
                              </div>
                            );
                          })}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={6} className="tableBodyCellClass">
                    No CPD records
                  </td>
                </tr>
              )}
              </tbody>
            </table>
          </div>
        </Box>
      </>}
    </div>
  );
}
export default ChainedContext(CPD, [[MemberReceiver, "member"]]);
