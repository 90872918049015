import React, {useEffect, useState} from "react";
import Input from "../common/Input";
import Button from "../common/Button";
import {PencilIcon} from "@heroicons/react/24/outline";
import {TrashIcon} from "@heroicons/react/20/solid";
import Toast from "react-hot-toast";
import ConfirmBox from "../common/Confirm";


const ManageWebLinks = ({ className = "", links, onChange, isDeleting = () => {} }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [title, setTitle] = useState("");
  const [url, setUrl] = useState("");

  useEffect(() => {
    if(deleteIndex !== null){
      doDelete();
    }
  }, [deleteIndex]);

  const handleSubmit = () => {
    if(title.trim().length === 0){
      Toast.error("Title must be supplied");
      return;
    }
    if(url.trim().length === 0){
      Toast.error("URL must be supplied");
      return;
    }

    if(!(url.toLowerCase().substring(0, 7) === "http://" || url.toLowerCase().substring(0, 8) === "https://")){
      Toast.error("URL must start with http:// or https://");
      return;
    }

    if(isEdit !== false){
      onChange(links.map((a, i) => {
        if(i !== editIndex) return a;
        return {
          title: title.trim(),
          url: url.trim()
        }
      }));
      setIsEdit(false);
      setEditIndex(null);
    } else {
      onChange(links.concat([{
        title: title.trim(),
        url: url.trim()
      }]));
    }

    setTitle("");
    setUrl("");
  };
  const handleEdit = (item, index) => {
    setIsEdit(true);
    setEditIndex(index);
    setTitle(item.title);
    setUrl(item.url);
  };
  const handleDelete = (item, index) => {
    setDeleteIndex(index);
  };
  const doDelete = () => {
    onChange(links.filter((a, i) => i !== deleteIndex));
    setDeleteIndex(null);
  }

  return (
    <div className={className}>
      {/*{ deleteIndex !== null && <ConfirmBox show={true} title={"Delete web link"} onClose={() => setDeleteIndex(null)} action={() => doDelete()} />}*/}
      <div className={"flex w-full flex-wrap items-end gap-2 p-2 align-middle"}>
        <div className={"flex-1"}>
          <label className="labelClass">Title</label>
          <Input
            value={title}
            type={"text"}
            field={"title"}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="SarStuff Website..."
          />
        </div>
        <div className={"flex-1"}>
          <label className="labelClass">URL</label>
          <Input
            value={url}
            type={"text"}
            field={"url"}
            onChange={(e) => setUrl(e.target.value)}
            placeholder="https://sarstuff.co.uk..."
          />
        </div>
        <div className="">
          <Button
            disabled={title === "" || url === ""}
            onClick={handleSubmit}
            colour={"bg-green-700 text-white"}
            text={isEdit ? "Update" : "Add"}
          />
        </div>
      </div>
      <div className="w-full pt-4">
        <table className="w-full">
          <tbody className="border-t border-gray-300 dark:border-zinc-500">{links.sort((a, b) => {
            if(a.title > b.title) return 1;
            if(a.title < b.title) return 0;
            return 0;
          }).map((item, index) => {
            return (
              <tr key={index} className="border-b border-gray-300 py-4 text-black dark:border-zinc-500 dark:text-white">
                <td className="py-2 pl-2 text-lg">{item.title}</td>
                <td className="pr-2 pt-2 text-right">
                  <button
                    onClick={() => handleEdit(item, index)}
                    className="mr-2 rounded-md border border-gray-400 bg-gray-700 bg-opacity-80 px-2 py-1 text-sm text-white hover:border-gray-300 hover:bg-gray-800"
                  >
                    <PencilIcon className="h-6 w-6 text-gray-200"/>
                  </button>
                  <button
                    className="rounded-md border border-red-400 bg-red-700 bg-opacity-80 px-2 py-1 text-sm text-white hover:border-red-300 hover:bg-red-800"
                    onClick={() => handleDelete(item, index)}
                  >
                    <TrashIcon className="h-6 w-6 text-red-200"/>
                  </button>
                </td>
              </tr>
            );
          })}</tbody>
        </table>
        {links?.length === 0 && <div className={"pb-4 text-center"}>No items to display</div>}
      </div>
    </div>
  );
}
export default ManageWebLinks;