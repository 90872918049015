import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import api from "../../api";
import Box from "../../components/common/Box";
import { Skeleton } from "../../components/ui/skeleton";
import ChainedContext from "../../contexts/chainedContext";
import SettingsReceiver from "../../contexts/settings_context/Settings_Receiver";
import RecruitForm from "./_components/RecruitForm";

function AddRecruit({ settings }) {
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    settings?._refresh();
    setLoading(false);
  }, []);

  const addRecruit = async (data) => {
    return await api.recruitment.addRecruit(data).then(res => {
      toast.success("Recruit added.");
      navigate(`/recruitment/recruits/${res._id}`);
    }).catch(err => {
      toast.error("Failed to add recruit: " + (err.response?.data?.message || err.message));
    });
  };
  return (
    <div className="w-full p-4">
      <Box title="Add Recruit">
        {loading && (
          <div className="grid grid-cols-3 gap-4 p-2">
            <div className="space-y-4">
              <Skeleton className="h-10 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
              <Skeleton className="h-10 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
              <Skeleton className="h-10 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
            </div>
            <div className="space-y-4">
              <Skeleton className="h-10 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
              <Skeleton className="h-10 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
              <Skeleton className="h-10 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
            </div>
            <div className="space-y-4">
              <Skeleton className="h-10 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
              <Skeleton className="h-10 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
              <Skeleton className="h-10 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
            </div>
          </div>
        )}
        {!loading && (
          <RecruitForm
            onSubmit={(data) => addRecruit(data)}
            onCancel={() => navigate(`/recruitment`)}
          />
        )}
      </Box>
    </div>
  );
}
export default ChainedContext(AddRecruit, [
  [SettingsReceiver, "settings"]
]);