import React, {useCallback} from "react";
import { useNavigate } from "react-router-dom";
import api from "../../api";
import AssetDetailsForm from "./asset/_components/assetDetailsForm";

export default function AddAsset() {
  const navigate = useNavigate();

  const handleAddAsset = useCallback(data => {
    return api.assets.add(data).then(res => {
      navigate(`/asset/view/${res._id}`);
    });
  }, [navigate]);
  const handleFormCancel = useCallback(() => {
    navigate(`/asset`)
  }, [navigate]);

  return (
    <div className={"pl-2 pr-4 pt-1"}>
      <AssetDetailsForm onChange={handleAddAsset} onCancel={handleFormCancel} />
    </div>
  );
}