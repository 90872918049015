import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import Box from "../../components/common/Box";
import DataRow from "../../components/common/DataRow";
import Button from "../../components/common/Button";
import api from "../../api";
import DayJS from "dayjs";
import {ArrowRightIcon, PencilIcon} from "@heroicons/react/24/outline";
import {CheckIcon, TrashIcon, XMarkIcon} from "@heroicons/react/20/solid";

const ViewIncident = () => {
  const { incidentId} = useParams();
  const navigation = useNavigate();

  const [incident, setIncident] = useState({});
  const [periods, setPeriods] = useState([]);
  const [members, setMembers] = useState([]);

  useEffect(() => {
    api.incidents.getIncident(incidentId).then(res => {
      setIncident(res);
    });
    api.incidents.getAllMembers(incidentId).then(res => {
      setMembers(res);
    })
    api.incidents.periods.getPeriods(incidentId).then(res => {
      setPeriods(res);
    });
  }, [incidentId]);

  return (
    <div className="w-full p-2">
      <div className="grid grid-cols-2 gap-4">
        <div>
          <Box title={`Incident: ${incident.name}`} button={{
            action: () => navigation(`/incidents/edit/${incident._id}`),
            colour: "",
            text: "Edit"
          }}>
            <div className="flex">
              <DataRow label={"Incident Ref"} value={incident.refNum}/>
              <DataRow label={"Name"} value={incident.name}/>
            </div>
            <div className="flex">
              <DataRow label={"Outcome"} value={incident.outcome}/>
              <DataRow label={"Opened"} value={DayJS(incident.opened).format("DD-MM-YYYY")}/>
            </div>
          </Box>
        </div>
        <div>
          <Box title={"Operational Periods/Deployments"}>
            <table className="tableClass">
              <thead className="tableHeadClass">
              <tr className="tableHeadRowClass">
                <th className="tableHeadCellClass w-20">Ref</th>
                <th className="tableHeadCellClass">Start At</th>
                <th className="tableHeadCellClass">End At</th>
                <th className="tableHeadCellClass w-32">Total Members</th>
              </tr>
              </thead>
              <tbody className="tableBodyClass">
              {periods.map((m) => {
                return (
                  <tr key={m._id} className="tableBodyRowClass">
                    <td className="tableBodyCellClass text-lg">{m.refNum}</td>
                    <td className="tableBodyCellClass text-lg">{DayJS(m.startAt).format("DD-MM-YYYY HH:mm")}</td>
                    <td className="tableBodyCellClass text-lg">{DayJS(m.endAt).format("DD-MM-YYYY HH:mm")}</td>
                    <td className="tableBodyCellClass text-lg">{m._totalMembers}</td>
                  </tr>
                );
              })}
              </tbody>
            </table>
          </Box>
        </div>
      </div>
      <div>
        <Box title="Members">
          <table className="tableClass">
            <thead className="tableHeadClass">
            <tr className="tableHeadRowClass">
              <th className="tableHeadCellClass w-20">Callsign</th>
              <th className="tableHeadCellClass">Name</th>
              {periods.map((op) => {
                return (
                  <th className="tableHeadCellClass w-20">OP #{op.refNum}</th>
                );
              })}
            </tr>
            </thead>
            <tbody className="tableBodyClass">
            {members.map((m) => {
              return (
                <tr key={m._id} className="tableBodyRowClass">
                  <td className="tableBodyCellClass text-lg">{m.callsign}</td>
                  <td className="tableBodyCellClass text-lg">{m.firstName} {m.lastName}</td>
                  {periods.map((op) => {
                    if(m._periodIds.indexOf(op._id) === -1){
                      return (
                        <td className="tableBodyCellClass text-lg">
                          <XMarkIcon className="mx-auto h-8 text-red-700"/>
                        </td>
                      );
                    }
                    return (
                      <td className="tableBodyCellClass text-lg">
                      <CheckIcon className="mx-auto h-8 text-green-600 dark:text-green-400"/>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
            </tbody>
          </table>
        </Box>
      </div>
    </div>
  );
}
export default ViewIncident;