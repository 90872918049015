import Authentication from "../Authentication";

export default (props) => {
  let canNotDoIt;
  if (Array.isArray(props.scope)) {
    canNotDoIt = true;
    for (const scope of props.scope) {
      if (!Authentication.canNot(scope, props.context)) canNotDoIt = false;
    }
  } else {
    canNotDoIt = Authentication.canNot(props.scope, props.context);
  }
  return canNotDoIt ? props.children : null;
};
