import React, { Component } from "react";
import { Routes, Route, Outlet, Link } from "react-router-dom";
import { AuthComponents } from "../../lib/_sso";
import RecruitmentDashboard from "./Dashboard";
import RecruimentNav from "./_components/NavBar";
import ActiveRecruits from "./ActiveRecruits";
import AddRecruit from "./AddRecruit";
import RecruitEvents from "./events/RecruitEvents";
import CreateRecruitEvent from "./events/CreateRecruitEvent";
import RecruitEventView from "./events/RecruitEventView";
import RecruitEventClone from "./events/RecruitEventClone";
import RecruitEventEdit from "./events/RecruitEventEdit";
import RecruitPage from "./RecruitPage";
import RecruitEdit from "./RecruitEdit";
import IntakePage from "./IntakePage";

export default class RecruitmentPages extends Component {
  render() {
    return (
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<RecruitmentDashboard />} />
          <Route path="/events" element={<RecruitEvents />} />
          <Route path="/events/create" element={<CreateRecruitEvent />} />
          <Route path="/events/event/:event_id" element={<RecruitEventView />} />
          <Route path="/events/event/:event_id/clone" element={<RecruitEventClone />} />
          <Route path="/events/event/:event_id/edit" element={<RecruitEventEdit />} />

          <Route path="/recruits" element={<ActiveRecruits />} />
          <Route path="/recruits/add" element={<AddRecruit />} />
          <Route path="/recruits/:recruit_id" element={<RecruitPage />} />
          <Route path="/recruits/:recruit_id/edit" element={<RecruitEdit />} />
          <Route path="/intake/:intake_id" element={<IntakePage />} />
          <Route path="*" element={<NoMatch2 />} />
        </Route>
      </Routes>
    );
  }
}
function Layout() {
  const scope = "teamsite:recruit_management";

  return (
    <div className="min-h-full">
      <RecruimentNav />
      <main>
        <AuthComponents.Can scope={scope}>
          <Outlet />
        </AuthComponents.Can>
        <AuthComponents.CanNot scope={scope}>
          <div className={"p-2 text-red-500"}>Access Denied: You do not have permission to access this page.</div>
        </AuthComponents.CanNot>
      </main>
    </div>
  );
}
function NoMatch2() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}
