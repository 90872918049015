import { APIRequest } from "../lib/_sso";
import AppConfig from "../_appConfig";

const server = AppConfig.api_server;

export default {
  postcode: {
    getAddresses: (postcode) =>
      APIRequest.get(server + `/feeds/postcode/addresses/${postcode}`).then(
        (res) => res.data
      ),
    getUPRN: (uprn) =>
      APIRequest.get(server + `/feeds/postcode/addresses/uprn/${uprn}`).then(
        (res) => res.data
      ),
    getAroundPoint: (lng1, lat1, dist = 1000) =>
      APIRequest.get(
        server +
          `/feeds/postcode/addresses/point?lng1=${lng1}&lat1=${lat1}&dist=${dist}`
      ).then((res) => res.data),
    getWithin: (lng1, lat1, lng2, lat2) =>
      APIRequest.get(
        server +
          `/feeds/postcode/addresses/within?lng1=${lng1}&lat1=${lat1}&lng2=${lng2}&lat2=${lat2}`
      ).then((res) => res.data),
  },
};
