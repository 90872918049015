import axios  from "axios";
import Bridge from "../bridge";


function applyHeaders() {
  return {
    headers: {
      Authorization:
        "Bearer " +
        localStorage.getItem(Bridge.getConfig("localPrefix") + "access_token")
    }
  };
}
export default {
  auth: {
    exchange_code: (code) => {
      return axios
        .post(Bridge.getConfig("applicationServer") + "/../oauth/code_exchange", {
          code
        })
        .then(res => res.data);
    },
    logout: () => {
      return axios
        .delete(Bridge.getConfig("applicationServer") + "/../oauth/token", applyHeaders())
        .then(res => res.data);
    },
    user_details: access_token => {
      return axios
        .get(Bridge.getConfig("applicationServer") + "/../oauth/user_details", {
          headers: {
            Authorization: "Bearer " + access_token
          }
        })
        .then(res => res.data);
    }
  }
}
