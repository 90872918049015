import React, {useEffect, useMemo, useState} from "react";
import DayJS from "dayjs";
import ChainedContext from "../../../contexts/chainedContext";
import MemberReceiver from "../../../contexts/member_context/Member_Receiver";
import SettingsReceiver from "../../../contexts/settings_context/Settings_Receiver";
import OrganisationReceiver from "../../../contexts/organisation/receiver";
import {Skeleton} from "../../../components/ui/skeleton";
import Box from "../../../components/common/Box";
import Stat from "../../../components/common/Stat";
import API from "../../../api";
import {toast} from "react-hot-toast";

const Deployments = ({member, settings, organisation}) => {
  const [loading, setLoading] = useState(true);
  const [dashboard, setDashboard] = useState({
    total: 0,
    oldCallouts: 0,
    types: []
  });
  const [deployments, setDeployments] = useState([]);
  const [filterByType, setFilterByType] = useState(null);

  useEffect(() => {
    Promise.all([
      API.member.deploymentStats(member._id),
      API.member.deploymentList(member._id)
    ]).then(res => {
      setDashboard(res[0]);
      setDeployments(res[1]);
      setLoading(false);
    }).catch(err => {
      toast.error("Unable to retrieve deployment data from server at this time. ERROR: " + err.message)
    });
  }, [member]);

  const filteredDeployments = useMemo(() => {
    return deployments.filter(item => {
      if(filterByType === null) return true;

      return item.incident.type === filterByType
    })
  }, [deployments, filterByType])

  return (
    <div className="w-full p-2">
      {loading && (
        <div className="grid grid-cols-2 gap-4">
          <div className="space-y-8">
            <Skeleton className="h-80 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
            <Skeleton className="h-80 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
          </div>
          <div className=" space-y-8">
            <Skeleton className="h-52 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
            <Skeleton className="h-20 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
            <Skeleton className="h-20 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
          </div>
        </div>
      )}
      {!loading && <>
        <Box title={"Deployments"}>
          <div className="flex flex-wrap gap-2 p-2">
            <Stat
              label="Total Deployments"
              value={dashboard.total}
            />
            {dashboard.oldCallouts !== 0 && <Stat
              label="Previous Deployment Total"
              value={dashboard.oldCallouts}
              colour={"bg-purple-300 bg-opacity-40"}
            />}
            {dashboard.types.map((item, i) => {
              return (
                <Stat
                  key={i}
                  label={item.title.toUpperCase()}
                  action={() => setFilterByType(item.title)}
                  value={item.value}
                  colour={filterByType === item.title ? "bg-blue-300 bg-opacity-40" : undefined}
                />
              );
            })}
          </div>
          {filterByType !== null && <button onClick={() => setFilterByType(null)} className={"ml-2 rounded-md border border-transparent bg-red-100 text-red-900 px-4 py-2 text-sm font-medium hover:bg-red-200"}>Clear Filter</button>}

          <table className="tableClass">
            <thead>
              <tr className="tableHeadRowClass">
                <th className="tableHeadCellClass">Date</th>
                <th className="tableHeadCellClass">Name</th>
                <th className="tableHeadCellClass">Type</th>
                <th className="tableHeadCellClass">Outcome</th>
              </tr>
            </thead>
            <tbody className="tableBodyClass">{filteredDeployments.map(deployment => {
              return (
                <tr key={deployment._id} className="tableBodyRowClass">
                  <td className="tableBodyCellClass">{DayJS(deployment.period !== undefined ? deployment.period.startAt : deployment.incident.opened).format("DD-MM-YYYY HH:mm")}</td>
                  <td className="tableBodyCellClass">{deployment.incident.name}</td>
                  <td className="tableBodyCellClass">{deployment.incident.type.toUpperCase()}</td>
                  <td className="tableBodyCellClass">{deployment.incident.outcome}</td>
                </tr>
              );
            })}</tbody>
          </table>
        </Box>
      </>}
    </div>
  );
}
export default ChainedContext(Deployments, [
  [MemberReceiver, "member"],
  [SettingsReceiver, "settings"],
  [OrganisationReceiver, "organisation"],
]);