import Modal from "../../../components/common/Modal";
import React, {lazy, Suspense, useMemo, useState} from "react";
import Button from "../../../components/common/Button";
import api from "../../../api";
import {toast} from "react-hot-toast";

const AssignMembers = lazy(() => import("../asset/_components/assignMembers"));
const AssignLocations = lazy(() => import("../asset/_components/assignLocations"));
const AssignVehicles = lazy(() => import("../asset/_components/assignVehicles"));

const MultiAssignAsset = ({ assets, onClose }) => {
  const [type, setType] = useState("Member");

  const assetNames = useMemo(() => {
    return assets.map(asset => asset.name).sort((a, b) => {
      if(a < b) return -1;
      if(a > b) return 1;
      return 0;
    })
  }, [assets]);

  const handleAssignment = (assigned) => {
    toast.promise(Promise.all(assets.map(async asset => api.assets.assign(asset._id, {
      [assigned.type]: assigned.id,
    }))), {
      loading: "Updating assets, this may take a while...",
      success: "Successfully updated asset assignments.",
      error: "There were one or more assignments that triggered an error and could not be updated."
    }).then(() => {
      onClose();
    });
  }

  return (
    <Modal
      show={true}
      onClose={() => onClose()}
      title={`Multi asset assignment`}
    >
      <div className="border-b border-gray-700 p-4">
        Assets: {assetNames.join(', ')}
      </div>

      <div className="flex justify-around px-2 pt-2 ">
        <Button
          style={"text-xl"}
          text="Member"
          positive={type === "Member"}
          onClick={() => setType("Member")}
        />
        <Button
          style={"text-xl"}
          text="Vehicle"
          positive={type === "Vehicle"}
          onClick={() => setType("Vehicle")}
        />
        <Button
          style={"text-xl"}
          text="Location"
          positive={type === "Location"}
          onClick={() => setType("Location")}
        />
      </div>
      <div>
        {type === "Member" && (
          <Suspense fallback={<div>Loading...</div>}>
            <AssignMembers
              data={data => handleAssignment(data)}
              clearData={false}
            />
          </Suspense>
        )}
        {type === "Location" && (
          <Suspense fallback={<div>Loading...</div>}>
            <AssignLocations
              data={data => handleAssignment(data)}
              clearData={false}
            />
          </Suspense>
        )}
        {type === "Vehicle" && (
          <Suspense fallback={<div>Loading...</div>}>
            <AssignVehicles
              data={data => handleAssignment(data)}
              clearData={false}
            />
          </Suspense>
        )}
      </div>
    </Modal>
  );
}
export default MultiAssignAsset;