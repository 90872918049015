import React, {useCallback, useEffect, useState} from "react";
import {CheckIcon, XMarkIcon, MinusIcon} from "@heroicons/react/24/solid";
import {toast} from "react-hot-toast";
import API from "../../../api";
import {Skeleton} from "../../../components/ui/skeleton";
import {useNavigate} from "react-router-dom";
import Model from "../../../components/common/Model";

const ConvertRecruit = ({recruit}) => {
  const [checks, setChecks] = useState([]);
  const [isPass, setIsPass] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showTempPassword, setShowTempPassword] = useState(false);
  const [tempPassword, setTempPassword] = useState("");
  const [userId, setUserId] = useState("");
  const navigate = useNavigate();

  const runChecks = useCallback(() => {
    API.recruitment.convert.runChecks(recruit._id).then(res => {
      setChecks(res.checks);
      setIsPass(res.isPass);
      setIsLoading(false);
    }).catch(err => {
      toast.error(err.isAxiosError ? err.response.data.message : "There was an unexpected error while processing your request");
    })
  }, [recruit])

  useEffect(() => {
    runChecks();
  }, [runChecks]);

  const handleBeginConvert = () => {
    setIsLoading(true);
    API.recruitment.convert.convertToMember(recruit._id).then(res => {
      toast.success("Successfully converted recruit into a Team Member");

      if (res.tempPassword !== undefined) {
        setShowTempPassword(true);
        setTempPassword(res.tempPassword);
        setUserId(res._id);
      } else {
        navigate(`/team/${res._id}`);
      }
    }).catch(err => {
      runChecks();
      toast.error(err.isAxiosError ? err.response.data.message : "There was an unexpected error while converting recruit.");
    }).finally(() => {
      setIsLoading(false);
    });
  }

  if (isLoading) {
    return (
      <div className="my-5 mx-2 col-span-2 space-y-4">
        <Skeleton className="h-5 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
        <Skeleton className="h-5 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
        <Skeleton className="h-5 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
      </div>
    );
  }

  return (
    <div className={"p-2"}>
      {showTempPassword && <Model disableOutsideClick onClose={() => navigate(`/team/${userId}`)}>
        <div className={"mt-4"}>
          A new SarStuff Central account has been created for this team member.<br/><br/>
          Their temporary password is <span className={"p-1 border rounded"}>{tempPassword}</span><br/><br/>

          <button className={"mt-4 p-2 border rounded w-full oncall"} onClick={() => navigate(`/team/${userId}`)}>GoTo
            Team Member
          </button>
        </div>
      </Model>}

      {checks.map((check, i) => {
        return (
          <div
            className={`flex rounded border my-2 ${check.isPass ? (check.isWarning !== undefined && check.isWarning ? "border-yellow-500 dark:border-yellow-300" : "border-green-500 dark:border-green-300") : "border-red-500 dark:border-red-300"}`}
            key={i}>
            <div className={"w-full p-2"}>
              {check.title}
              <div className={"pl-4 text-red-500 dark:text-red-300"}>{check.reason}</div>
            </div>
            <div className={"w-16 p-2"}>{check.isPass ?
              (check.isWarning !== undefined && check.isWarning ? <MinusIcon className={"h-6 text-yellow-500 dark:text-yellow-300"} /> : <CheckIcon className={"h-6 text-green-500 dark:text-green-300"} />) :
              <XMarkIcon className={"h-6 text-red-500 dark:text-red-300"} />}</div>
          </div>
        )
      })}

      {isPass && <button className={"w-full mt-4 border rounded p-2 oncall"} onClick={() => handleBeginConvert()}>Convert to full Team Member</button>}
    </div>
  )
}
export default ConvertRecruit;