import React, {useState, useEffect} from "react";
import Toast from "react-hot-toast";
import Box from "../../../../components/common/Box";
import {Skeleton} from "../../../../components/ui/skeleton";
import API from "../../../../api";
import DayJS from "dayjs";
import DataRow from "../../../../components/common/DataRow";

const RequiredCoursesGrid = ({member}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [requiredCourses, setRequiredCourses] = useState([]);

  useEffect(() => {
    API.member.courses.getRequired(member._id).then(res => {
      setRequiredCourses(res);
      setIsLoading(false);
    }).catch(err => {
      Toast.error("Unable to retrieve list of required courses due to error:  " + (err.response?.data?.message || err.message));
    })
  }, [member]);

  return (
    <Box title="Required Courses">
      {isLoading ? (<div className="grid grid-cols-3 gap-4 p-2">
        <Skeleton className="h-40 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
        <Skeleton className="h-40 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
        <Skeleton className="h-40 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
        <Skeleton className="h-40 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
        <Skeleton className="h-40 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
        <Skeleton className="h-40 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
      </div>) : <div className={"grid gap-2 p-2 md:grid-cols-2 xl:grid-cols-3"}>
        {requiredCourses.map(item => {
          const isValid = item.expires !== false && DayJS(item.expires).isAfter(DayJS());

          return (
            <div className={`border border-zinc-300 shadow hover:border-zinc-400 dark:border-zinc-700 ${isValid ? "!border-green-500 " : "!border-red-700"}`} key={item._id}>
              <div className={`mb-2 border-b-2 border-zinc-300 bg-zinc-200 p-1 text-center text-lg dark:border-zinc-700 dark:bg-zinc-800 ${isValid ? "!border-green-500 !bg-green-800 !bg-opacity-50" : "!border-red-700"}`}>
                {item.title}
              </div>
              <DataRow small label="Vaild for" value={item.validFor + " year(s)"} />
              <DataRow small label="Expires" value={item.expires !== false ? DayJS(item.expires).format("DD-MM-YYYY") : "--"} />
            </div>
          );
        })}
      </div>}
    </Box>
  );
}
export default RequiredCoursesGrid;