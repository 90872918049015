import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Tab } from "@headlessui/react";
import Map, { Source, Layer } from "react-map-gl";
import * as turf from "@turf/turf";
import { useNavigate, useSearchParams } from "react-router-dom";
import API from "../../api";
import Box from "../../components/common/Box";
import Stat from "../../components/common/Stat";
import Input from "../../components/common/Input";
import MemberList from "../../components/common/MemberList";
import SettingsReceiver from "../../contexts/settings_context/Settings_Receiver";
import OrganisationReceiver from "../../contexts/organisation/receiver";
import ChainedContext from "../../contexts/chainedContext";
import { Skeleton } from "../../components/ui/skeleton";
import { Authentication } from "../../lib/_sso";
import CommonFunctions from "../../lib/CommonFunctions";
import {toast} from "react-hot-toast";

const returnTabButtonStyle = (selected) => {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  return classNames(
    "w-full py-2.5 text-lg leading-5 font-medium  rounded-lg",
    selected
      ? "text-gray-100 bg-green-900 bg-opacity-50"
      : "text-blue-100 hover:bg-white/[0.12] hover:text-white bg-gray-800"
  );
};

function TeamList({ settings, organisation }) {
  const [members, setMembers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [lon, setLon] = useState(0.8);
  const [lat, setLat] = useState(51);
  const [searchQuery, setSearchQuery] = useState("");
  const [status, setStatus] = useState("");
  const [filter, setFilter] = useState("");
  const [stationFilter, setStationFilter] = useState("");
  const [stations, setStations] = useState([]);

  const navigate = useNavigate();
  const [search, setSearch] = useSearchParams();
  const mapRef = React.useRef();

  const getData = () => {
    API.member
      .allCache()
      .then((res) => {
        setMembers(res);
      })
      .finally(() => {
        setLoading(false);
      });
    API.settings.getRoles().then((res) => {
      setRoles(res);
    });
  };
  const getStationsData = useCallback(() => {
    API.settings.stations.getStations().then((res) => {
      setStations(res);
    });
  }, []);
  const onMapLoad = React.useCallback(() => {
    setTimeout(() => {
      if (list.length) {
        getBounds();
      }
    }, 300);
  }, []);

  useEffect(() => {
    getStationsData();
  }, [getStationsData]);
  useEffect(() => {
    getData();
    settings._refresh();
  }, []);
  useEffect(() => {
    const interval = setInterval(() => {
      API.member
        .all()
        .then((res) => {
          setMembers(res);
        })
        .finally(() => {
          setLoading(false);
        });
    }, 100000);
    return () => clearInterval(interval);
  }, []);
  useEffect(() => {
    setLon(organisation.defaultMapCenter[0]);
    setLat(organisation.defaultMapCenter[1]);
  }, [organisation]);
  useEffect(() => {
    getBounds();
  }, [list]);

  const handleCsvExport = () => {
    CommonFunctions.generateCSV("Member List Export", [
      { csv_field: "Unit ID", database_field: "callsign" },
      { csv_field: "Name", database_field: "name" },
      { csv_field: "Telephone", database_field: "phone" },
      { csv_field: "Email", database_field: "email" },
      { csv_field: "Roles", database_field: "roles" },
      { csv_field: "Operational", database_field: "operational" },
      { csv_field: "On Call", database_field: "onCall" },
    ], getFilteredMembers.map(a => {
      return {
        ...a,
        name: `${a.firstName} ${a.lastName}`,
        onCall: a.onCall ? "Yes" : "No",
        operational: a.operational ? "Yes" : "No",
        roles: a.Roles.filter(b => b.isActive).map(b => b.role?.name).sort().join(", ")
      }
    }));
  };
  const handleEmailClipboard = () => {
    navigator.clipboard.writeText(getFilteredMembers.map(a => a.email).join(";"));
    toast.success("Successfully copied to clipboard");
  };
  const setStatusValue = (val) => {
    setSearch({
      query: searchQuery,
      status: val,
      filter,
    });
    setStatus(val);
  };
  const setQueryValue = (val) => {
    setSearch({
      query: val,
      status,
      filter,
    });
    setSearchQuery(val);
  };
  const setFilterValue = (val) => {
    setSearch({
      query: searchQuery,
      status,
      filter: val,
    });
    setFilter(val);
  };

  const getFilteredMembers = useMemo(() => {
    return members
      .filter((item) => {
        if (search.get("status") !== null) {
          switch (search.get("status")) {
            case "on-call":
              return item.onCall;
            case "off-call":
              return !item.onCall;
            case "non-op":
              return !item.operational;
          }
        }
        return true;
      })
      .filter((item) => {
        if (search.get("filter") === null || search.get("filter") === "")
          return true;

        let roleMatch = [];
        if (item.Roles) {
          roleMatch = item.Roles.filter((r) => r.name === search.get("filter"));
        }
        return roleMatch.length >= 1;
      })
      .filter((item) => {
        let isMatch = true;
        let stations = item.stations || [];
        if (stationFilter !== "") {
          isMatch = stations.includes(stationFilter);
        }
        return isMatch;
      })
      .filter((item) => {
        const searchQuery =
          search.get("query") !== null ? search.get("query").toLowerCase() : "";
        if (searchQuery.trim() === "") return true;

        const firstNameMatch = item.firstName
          ? item.firstName.toLowerCase().search(searchQuery) !== -1
          : false;
        const surnameMatch = item.lastName
          ? item.lastName.toLowerCase().search(searchQuery) !== -1
          : false;
        const callsignMatch = item.callsign
          ? item.callsign.toLowerCase().search(searchQuery) !== -1
          : false;

        return firstNameMatch || surnameMatch || callsignMatch;
      });
  }, [members, search, stationFilter]);
  const memberGeoJSON = useMemo(() => {
    const final = getFilteredMembers
      .filter((member) => {
        return member.coords !== undefined;
      })
      .map((m) => {
        if (m.coords !== undefined) {
          return m.coords;
        }
      });
    setList(final);
    return {
      type: "FeatureCollection",
      features: final,
    };
  }, [filter, getFilteredMembers]);

  const getBounds = async () => {
    if (mapRef.current) {
      if (list.length > 0) {
        var bounds = turf.bbox({ type: "FeatureCollection", features: list });
        mapRef.current.fitBounds(bounds, {
          padding: 80,
          maxZoom: 16,
        });
      }
    }
  };

  return (
    <div className="w-full space-y-4 p-1 xl:p-4">
      <Box title="Filters">
        <div className="grid gap-4 p-2 xl:grid-cols-3">
          <div className="space-y-4 p-2">
            <div className="grid gap-4 md:grid-cols-4">
              <Stat
                label="Team"
                colour={`${
                  search.get("status") === null || search.get("status") === ""
                    ? "border-yellow-400"
                    : "border-gray-500"
                } border bg-gray-300 bg-opacity-40`}
                action={() => setStatusValue("")}
                value={members.length}
              />
              <Stat
                label="On-Call"
                colour={`${
                  search.get("status") === "on-call"
                    ? "border-yellow-400"
                    : "border-green-500"
                } border bg-green-300 bg-opacity-40`}
                action={() => setStatusValue("on-call")}
                value={members.filter((u) => u.onCall).length}
              />
              <Stat
                label="Off-Call"
                colour={`${
                  search.get("status") === "off-call"
                    ? "border-yellow-400"
                    : "border-blue-500"
                } border bg-blue-300 bg-opacity-40`}
                action={() => setStatusValue("off-call")}
                value={members.filter((u) => !u.onCall).length}
              />
              <Stat
                label="Non-Op"
                colour={`${
                  search.get("status") === "non-op"
                    ? "border-yellow-400"
                    : "border-red-500"
                } border bg-red-300 bg-opacity-40`}
                action={() => setStatusValue("non-op")}
                value={members.filter((u) => !u.operational).length}
              />
            </div>

            <Input
              placeholder="search... firstName, lastName, callsign"
              name="search"
              onChange={(e) => setQueryValue(e.target.value)}
              value={search.get("query") || ""}
            />
            {/* {stations.length > 0 && (
             <div className="">
             <div className="text-center text-lg tracking-wider">
             Stations
             </div>
             <div className="flex flex-row flex-wrap gap-4 p-2">
             {stations.map((r) => {
             return (
             <div
             key={r._id}
             onClick={() => setStationFilter(r._id)}
             className={`${
             stationFilter === r._id
             ? "bg-green-800 hover:bg-green-900"
             : "bg-gray-800 hover:bg-gray-900"
             } cursor-pointer rounded-lg border border-gray-600 p-2`}
             >
             {r.name}
             </div>
             );
             })}
             {stationFilter !== "" && (
             <button
             onClick={() => setStationFilter("")}
             className={`cursor-pointer rounded bg-red-400 p-2 text-white `}
             >
             clear
             </button>
             )}
             </div>
             </div>
             )} */}
            <div className={"flex gap-2 text-xs"}>
              <button
                onClick={() => handleCsvExport()}
                className={`cursor-pointer rounded border p-2 text-white`}
              >
                Export to .csv
              </button>
              <button
                onClick={() => handleEmailClipboard()}
                className={`cursor-pointer rounded border p-2 text-white`}
              >
                Copy email addresses to clipboard
              </button>
            </div>

          </div>
          <div className="p-2 xl:col-span-2">
            {stations.length > 0 && (
              <div className="mb-2 border-b border-zinc-700">
                {/* <div className="text-center text-lg tracking-wider">
                 Stations
                 </div> */}
                <div className="flex flex-row flex-wrap gap-4 p-2 text-sm">
                  {stations.sort((a, b) => {
                    if(a.name > b.name ) return 1;
                    if(a.name < b.name ) return -1;
                    return 0;
                  }).map((r) => {
                    return (
                      <div
                        key={r._id}
                        onClick={() => setStationFilter(r._id)}
                        className={` rounded border p-2 ${
                          stationFilter === r._id
                            ? "bg-green-300 dark:bg-green-700"
                            : "border-zinc-500"
                        } cursor-pointer hover:border-zinc-400`}
                      >
                        {r.name}
                      </div>
                    );
                  })}
                  {stationFilter !== "" && (
                    <button
                      onClick={() => setStationFilter("")}
                      className={`cursor-pointer rounded bg-red-400 p-2 text-white `}
                    >
                      Clear station
                    </button>
                  )}
                </div>
              </div>
            )}
            <div className="flex flex-wrap gap-2 text-sm">
              {roles &&
                roles.length &&
                roles.sort((a, b) => {
                  if(a.name > b.name ) return 1;
                  if(a.name < b.name ) return -1;
                  return 0;
                }).map((r) => {
                  return (
                    <button
                      key={r._id}
                      onClick={() => setFilterValue(r.name)}
                      className={` rounded border p-2 ${
                        filter === r.name
                          ? "bg-green-300 dark:bg-green-700"
                          : "border-zinc-500"
                      } hover:border-zinc-400`}
                    >
                      {r.name}
                    </button>
                  );
                })}
              {filter !== "" && (
                <button
                  onClick={() => setFilterValue("")}
                  className={`cursor-pointer rounded bg-red-400 p-2 text-white `}
                >
                  Clear Role
                </button>
              )}
            </div>
          </div>
        </div>
      </Box>
      <Box
        title="Team Details"
        button={
          Authentication.can("members:create") && {
            text: "Add",
            colour:
              "bg-green-600 text-zinc-300 hover:bg-primary hover:text-white ",
            action: () => navigate(`/team/add`),
          }
        }
      >
        {loading && (
          <div className="space-y-6 p-4">
            <div className="flex gap-8">
              <Skeleton className="h-8 w-1/3 dark:bg-zinc-700"></Skeleton>
              <Skeleton className="h-8 w-1/3 dark:bg-zinc-700"></Skeleton>
              <Skeleton className="h-8 w-1/3 dark:bg-zinc-700"></Skeleton>
            </div>
            <div className="flex gap-8">
              <Skeleton className="h-8 w-1/2 dark:bg-zinc-700"></Skeleton>
              <Skeleton className="h-8 w-1/2 dark:bg-zinc-700"></Skeleton>
            </div>
          </div>
        )}
        {!loading && (
          <Tab.Group defaultIndex={0}>
            <Tab.List className="flex space-x-1 border-b border-gray-700  bg-blue-900/20 p-1 pb-2">
              <Tab className={({ selected }) => returnTabButtonStyle(selected)}>
                Team List
              </Tab>
              <Tab className={({ selected }) => returnTabButtonStyle(selected)}>
                Map
              </Tab>
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel>
                <div className="">
                  <MemberList
                    users={getFilteredMembers}
                    member={(id) => navigate(`/team/${id}`)}
                  />
                </div>
              </Tab.Panel>
              <Tab.Panel>
                <Map
                  reuseMaps
                  initialViewState={{
                    longitude: lon,
                    latitude: lat,
                    zoom: 8,
                  }}
                  ref={mapRef}
                  onLoad={onMapLoad}
                  style={{ width: "100%", height: "70vh" }}
                  mapStyle={`mapbox://styles/m1ck/ckmm689sa19hw17lnszd04t7t`}
                  mapboxAccessToken="pk.eyJ1IjoibTFjayIsImEiOiJjampzYnZ4NTMweTI1M3dwNGgxejFwaDY0In0.VBIMha3e0xnGefuoODjybg"
                >
                  <Source id="vehicles" type="geojson" data={memberGeoJSON}>
                    <Layer
                      onClick={() => console.log("bob")}
                      type="symbol"
                      layout={{
                        // "icon-image": "castle",
                        // "icon-size": 1,
                        "text-field": ["get", "firstName"],
                        "text-offset": [0, 0.6],
                        "text-radial-offset": 0.6,
                        "text-variable-anchor": [
                          "top",
                          "bottom",
                          "left",
                          "right",
                        ],

                        "text-justify": "auto",
                        "icon-allow-overlap": true,
                        // "text-allow-overlap": true,
                      }}
                    />
                    <Layer
                      type="circle"
                      paint={{
                        "circle-color": "#ff0000",
                        // "text-allow-overlap": true,
                      }}
                    />
                  </Source>
                </Map>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        )}
      </Box>
    </div>
  );
}
export default ChainedContext(TeamList, [
  [SettingsReceiver, "settings"],
  [OrganisationReceiver, "organisation"],
]);
