import React, { useState, useEffect } from "react";
import Box from "../../components/common/Box";
import { Skeleton } from "../../components/ui/skeleton";
import { useNavigate } from "react-router-dom";
import { Disclosure, Transition, Dialog } from "@headlessui/react";
import dayjs from "dayjs";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import Input from "../../components/common/Input";
import DatePicker from "react-datepicker";
import api from "../../api";
import { toast } from "react-hot-toast";
import { AuthComponents } from "../../lib/_sso";
import EventLine from "../../components/common/EventLine";

export default function RecruitmentDashboard() {
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [intakes, setIntakes] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [ref, setRef] = useState("");
  const [type, setType] = useState("");
  const [id, setID] = useState("");
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [upcomingLoading, setUpcomingLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    api.recruitment.getIntakes().then((res) => {
      setIntakes(res);
      setLoading(false);
    });
    api.recruitment.upcomingEvents().then((res) => {
      setUpcomingEvents(res);
      setUpcomingLoading(false);
    });
  };

  const handleEdit = (d) => {
    setRef(d.ref);
    setType(d.type);
    setStartDate(d.start);
    setID(d._id);
    setIsEdit(true);
    setIsOpen(true);
  };
  const handleDelete = (d) => {
    setRef(d.ref);
    setType(d.type);
    setStartDate(d.start);
    setID(d._id);
    setIsDelete(true);
    setIsOpen(true);
  };
  const onCancel = () => {
    setRef("");
    setType("");
    setStartDate("");
    setID("");
    setIsEdit(false);
    setIsOpen(false);
    setIsDelete(false);
  };
  const onSubmit = () => {
    const data = {
      ref,
      type,
      start: startDate,
    };
    if (isEdit) {
      api.recruitment.updateIntake(id, data).then(() => {
        getData();
        setIsOpen(false);
        setIsEdit(false);
        toast.success("Intake updated");
      });
    } else {
      api.recruitment.addIntake(data).then(() => {
        getData();
        setIsOpen(false);
        setIsEdit(false);
        toast.success("Intake added");
      });
    }
  };
  const onDelete = () => {
    api.recruitment.deleteIntake(id).then(() => {
      toast.success("Intake deleted");
      onCancel();
      getData();
    });
  };
  const IntakeDialog = () => {
    return (
      <Transition
        show={isOpen}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Dialog
          open={isOpen}
          onClose={onCancel}
          className="fixed inset-0 z-10 overflow-y-auto "
        >
          <div className="min-h-screen text-center">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-80" />
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className="my-8 inline-block w-full max-w-2xl transform  rounded-2xl border-4 border-gray-700 bg-gray-50 p-6 text-left align-middle shadow-xl transition-all dark:bg-zinc-700">
              <div className="mb-2 border-b border-zinc-300 py-4 text-center dark:border-zinc-500">
                <Dialog.Title className={"text-2xl"}>
                  {isEdit ? "Update " : isDelete ? "Delete" : "Add New "} Intake
                </Dialog.Title>
              </div>
              {!isDelete ? (
                <div className="flex gap-2">
                  <Input
                    label="Ref"
                    onChange={(e) => {
                      setRef(e.target.value);
                    }}
                    value={ref}
                  />
                  <Input
                    label="Type"
                    onChange={(e) => {
                      setType(e.target.value);
                    }}
                    value={type}
                  />
                  <div className="overflow-show w-full">
                    <label
                      htmlFor={"date"}
                      className={`block   dark:text-zinc-400 `}
                    >
                      Intake Start Date
                    </label>
                    <DatePicker
                      onChange={(date) => {
                        setStartDate(date);
                      }}
                      selected={startDate ? new Date(startDate) : ""}
                      // value={startDate}
                      dateFormat="dd-MM-yy"
                      nextMonthButtonLabel=">"
                      previousMonthButtonLabel="<"
                      className=" w-full rounded border border-pp bg-white p-1 dark:border-zinc-500  dark:bg-zinc-700"
                      data-disable-touch-keyboard
                    />
                  </div>
                </div>
              ) : (
                <div className="p-4">
                  Are you sure you want to delete intake{" "}
                  <span className="text-xl italic text-primary dark:text-primary-dark">
                    {ref}, {type}
                  </span>
                </div>
              )}
              <div className="mt-4 flex justify-between border-t border-zinc-500 pt-4">
                <button
                  className="rounded-lg bg-primary px-4 py-2 text-white"
                  onClick={onCancel}
                >
                  Cancel
                </button>
                {isDelete ? (
                  <button
                    className="rounded-lg bg-red-500 px-4 py-2 text-white disabled:bg-red-500"
                    onClick={onDelete}
                  >
                    Delete
                  </button>
                ) : (
                  <button
                    className="rounded-lg bg-green-500 px-4 py-2 text-white disabled:bg-green-500"
                    onClick={onSubmit}
                  >
                    Save
                  </button>
                )}
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  };

  return (
    <div className="grid gap-4 p-4 md:grid-cols-2">
      <Box
        title="Active Intakes"
        button={{
          text: "Add",
          colour: "hover:bg-primary hover:text-white",
          action: () => setIsOpen(true),
        }}
      >
        {IntakeDialog()}
        {loading && (
          <div className="grid grid-cols-3 gap-4 p-2">
            <div className="space-y-8">
              <Skeleton className="h-10 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
              <Skeleton className="h-10 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
              <Skeleton className="h-10 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
            </div>
            <div className="col-span-2 space-y-8">
              <Skeleton className="h-10 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
              <Skeleton className="h-10 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
              <Skeleton className="h-10 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
            </div>
          </div>
        )}
        {!loading && (
          <table className="min-w-full table-auto border-separate divide-y divide-gray-200 text-sm  lg:text-lg">
            <thead>
              <tr className="bg-gray-300 text-xs uppercase tracking-wider text-gray-900 dark:bg-zinc-700 dark:text-white">
                <th className="flex gap-1 px-4 py-3 text-left">Ref</th>
                <th className="px-4 py-3 text-left">Date</th>
                <th className="px-4 py-3 text-left">Type</th>
                <th className="px-4 py-3 text-left">Recruits</th>
                <th className="px-4 py-3 text-left"></th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 text-left">
              {intakes.length > 0 ? (
                intakes.map((u) => {
                  return (
                    <tr
                      key={u._id}
                      onClick={() => navigate(`/recruitment/intake/${u._id}`)}
                      className={`
           cursor-pointer hover:bg-gray-300 dark:hover:bg-zinc-700`}
                    >
                      <td className="text-md p-2">
                        <span className="text-lg">{u.ref}</span>
                      </td>
                      <td className="p-2">
                        {dayjs(u.start).format("DD-MM-YYYY")}
                      </td>
                      <td className="p-2">{u.type}</td>
                      <td className="w-20 p-2 text-center">
                        {u.recruits.length}
                      </td>
                      <td className="w-32 space-x-1 pr-2 pt-2 text-center">
                          <button
                            // disabled={d.isAvailableDefault || d.isAssignedDefault}
                            onClick={() => handleEdit(u)}
                            className="rounded-md border bg-primary bg-opacity-80  px-2 py-1 text-sm  text-white hover:bg-gray-800 disabled:opacity-20"
                          >
                            <PencilIcon className="h-6 w-6 text-yellow-200 " />
                          </button>
                          <button
                            className="rounded-md border border-red-400 bg-red-700 bg-opacity-80 px-2 py-1 text-sm text-white hover:border-red-300 hover:bg-red-800 disabled:opacity-20"
                            onClick={() => {
                              handleDelete(u);
                            }}
                          >
                            <TrashIcon className="h-6 w-6 text-red-200" />
                          </button>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={5} className="p-2">
                    No active intakes
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </Box>
      <Box title="Upcoming Recruit Events">
        {upcomingLoading ? (
          <div className="flex flex-wrap justify-center gap-4 p-4">
            <Skeleton className="h-20 w-1/4 bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
            <Skeleton className="h-20 w-1/4 bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
            <Skeleton className="h-20 w-1/4 bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
          </div>
        ) : (
          <table>
            <tbody>
            {upcomingEvents.map((event, i) => {
              return <EventLine key={i} data={event}/>;
            })}
            {upcomingEvents.length === 0 && <tr>
              <td colSpan={1} className="p-2">
                No upcoming events
              </td>
            </tr>}

            </tbody>
          </table>
        )}
      </Box>
    </div>
  );
}
