import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import api from "../../api";
import { Skeleton } from "../../components/ui/skeleton";
import Box from "../../components/common/Box";
import Input from "../../components/common/Input";

export default function Logs() {
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    api.settings.auditLogs().then((res) => {
      setLogs(res);
      setLoading(false);
    });
  };
  const filterList = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };
  const getFilteredMembers = () => {
    let filterItems = logs.filter((item) => {
      if (searchQuery === null || searchQuery.trim() === "") {
        return true;
      }
      let firstNameMatch = item.member?.firstName
        ? item.member?.firstName.toLowerCase().search(searchQuery) !== -1
        : false;
      let surnameMatch = item.member?.lastName
        ? item.member?.lastName.toLowerCase().search(searchQuery) !== -1
        : false;
      let callsignMatch = item.vehicle?.callsign
        ? item.vehicle?.callsign.toLowerCase().search(searchQuery) !== -1
        : false;
      let makeMatch = item.vehicle?.make
        ? item.vehicle?.make.toLowerCase().search(searchQuery) !== -1
        : false;
      let modelMatch = item.vehicle?.model
        ? item.vehicle?.model.toLowerCase().search(searchQuery) !== -1
        : false;
      let regNumMatch = item.vehicle?.regNumber
        ? item.vehicle?.regNumber.toLowerCase().search(searchQuery) !== -1
        : false;
      let recruitFirstNameMatch = item.recruit?.firstName
        ? item.member?.firstName.toLowerCase().search(searchQuery) !== -1
        : false;
      let recruitSurnameMatch = item.recruit?.lastName
        ? item.member?.lastName.toLowerCase().search(searchQuery) !== -1
        : false;

      return (
        firstNameMatch ||
        surnameMatch ||
        regNumMatch ||
        modelMatch ||
        makeMatch ||
        callsignMatch ||
        recruitFirstNameMatch ||
        recruitSurnameMatch
      );
    });
    return filterItems;
  };

  return (
    <div className="w-full p-4">
      <Box title="Audit Log">
        <div className="p-2">
          <Input
            placeholder={"search...."}
            onChange={filterList}
            value={searchQuery}
          />
        </div>

        {loading && (
          <div className="space-y-6 p-4">
            <div className="space-y-4">
              <Skeleton className="h-8 w-full dark:bg-zinc-700"></Skeleton>
              <Skeleton className="h-8 w-full dark:bg-zinc-700"></Skeleton>
              <Skeleton className="h-8 w-full dark:bg-zinc-700"></Skeleton>
              <Skeleton className="h-8 w-full dark:bg-zinc-700"></Skeleton>
              <Skeleton className="h-8 w-full dark:bg-zinc-700"></Skeleton>
              <Skeleton className="h-8 w-full dark:bg-zinc-700"></Skeleton>
              <Skeleton className="h-8 w-full dark:bg-zinc-700"></Skeleton>
              <Skeleton className="h-8 w-full dark:bg-zinc-700"></Skeleton>
            </div>
          </div>
        )}
        {!loading && (
          <table className="lg:text-md min-w-full table-auto divide-y divide-zinc-400 border border-zinc-500 text-sm">
            <thead className="text-left">
              <tr className="divide-x divide-zinc-500 bg-gray-300 text-xs uppercase tracking-widest text-gray-900 dark:bg-zinc-700 dark:text-white">
                <th className="px-4 py-3">Date</th>
                <th className="px-4 py-3">Message</th>
                <th className="px-4 py-3">Member</th>
                <th className="px-4 py-3">Vehicle </th>
                <th className="px-4 py-3">Type</th>
                <th className="px-4 py-3">Logged By</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-zinc-500 text-left">
              {logs !== undefined ? (
                getFilteredMembers().map((l) => {
                  return (
                    <tr
                      key={l._id}
                      className={`divide-x divide-zinc-500`}
                    >
                      <td className="text-md p-2">
                        {dayjs(l.createdAt).format("DD-MM-YYYY HH:mm")}
                      </td>
                      <td className="p-2">{l.message}</td>
                      <td className="p-2">
                        {l.member ? (l.member?.firstName + " " + l.member?.lastName) : (l.recruit ? ("[Recruit] " + l.recruit?.firstName + " " + l.recruit?.lastName) : " -- ")}
                      </td>
                      <td className="p-2">
                        {l.vehicle ? (l.vehicle?.callSign ? l.vehicle?.callSign : (l.vehicle?.make + " " + l.vehicle?.model + " " + l.vehicle?.regNumber)) : " -- "}
                      </td>
                      <td className="w-32 p-2 text-center">{l.type}</td>
                      <td className="w-32 p-2 text-center">{l.loggedBy}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={6}>No audit logs</td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </Box>
    </div>
  );
}