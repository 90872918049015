import {Skeleton} from "../ui/skeleton";
import Stat from "../common/Stat";
import Box from "../common/Box";
import React, {useEffect, useState} from "react";
import API from "../../api";
import {useNavigate} from "react-router-dom";


const ExpiringRequirements = () => {
  const [loading, setLoading] = useState(true);
  const [requirementsDash, setRequirementsDash] = useState({
    expired: [],
    threeMonths: [],
    sixMonths: [],
  });
  const [requirementSelected, setRequirementSelected] = useState("expired");

  const navigate = useNavigate();

  useEffect(() => {
    API.reports.requirements.dashStats().then((res) => {
      setRequirementsDash(res);
      setLoading(false);
    });
  }, []);

  return (
    <Box title="Expiring Requirements">
      <div className={"flex justify-center gap-1 pt-2 text-center"}>
        <button
          className={`${
            requirementSelected === "expired"
              ? "bg-primary"
              : "bg-zinc-500"
          } m-1 rounded px-2 py-1 text-white`}
          onClick={() => setRequirementSelected("expired")}
        >
          Expired
        </button>
        <button
          className={`${
            requirementSelected === "threeMonths"
              ? "bg-primary"
              : "bg-zinc-500"
          } m-1 rounded px-2 py-1 text-white`}
          onClick={() => setRequirementSelected("threeMonths")}
        >
          In 3 months
        </button>
        <button
          className={`${
            requirementSelected === "sixMonths"
              ? "bg-primary"
              : "bg-zinc-500"
          } m-1 rounded px-2 py-1 text-white`}
          onClick={() => setRequirementSelected("sixMonths")}
        >
          In 6 months
        </button>
      </div>

      {loading ? (
        <div className="flex flex-wrap justify-center gap-4 p-4">
          <Skeleton className="h-20 w-1/4 bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
          <Skeleton className="h-20 w-1/4 bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
          <Skeleton className="h-20 w-1/4 bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
        </div>
      ) : (
        <div className="flex flex-wrap gap-4 p-4">
          {requirementsDash[requirementSelected].map((item) => {
            return (
              <Stat
                key={item._id}
                action={() =>
                  navigate(
                    `/reports/requirements?id=${item._id}&label=${item.title}`
                  )
                }
                label={item.title}
                value={item.value}
                colour="bg-blue-300 bg-opacity-40"
              />
            );
          })}
        </div>
      )}
    </Box>
  );
}
export default ExpiringRequirements;