import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import api from "../../../api";
import Tooltip from "../../../components/common/Tooltip";
import { useNavigate } from "react-router-dom";

export default function AssetList({ vehicle, weight }) {
  const [loading, setLoading] = useState(true);
  const [assets, setAssets] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    api.assets.reports.getAssigned("vehicle", vehicle).then((res) => {
      setAssets(res);
      weight(
        res.filter(asset => !isNaN(asset.weight)).reduce((val, asset) => {
          return val + asset.weight;
        }, 0)
      );
      setLoading(false);
    });
  };

  return (
    <div className="w-full ">
      <table className="tableClass ">
        <thead>
          <tr className="tableHeadRowClass">
            <th className="tableHeadCellClass">Asset</th>
            <th className="tableHeadCellClass">Asset Number</th>
            {/* <th className="tableHeadCellClass">Serial Number</th> */}
            <th className="tableHeadCellClass">Status</th>
            <th className="tableHeadCellClass hidden 2xl:table-cell">
              Last Service
            </th>
            <th className="tableHeadCellClass hidden 2xl:table-cell">
              Last Check
            </th>
            <th className="tableHeadCellClass text-center">
              Weight <span className="subtle text-xs italic">Kg</span>
            </th>
            {/* <th className="tableHeadCellClass">Expires Date</th> */}
            <th className="tableHeadCellClass">Tags</th>
          </tr>
        </thead>
        <tbody className="tableBodyClass">
          {!loading ? (
            assets.map((a) => {
              const onViewClick = () => {
                navigate(`/asset/view/${a._id}`);
              };
              //   console.log(a);

              let status = "";
              switch (true) {
                case a.status === "Non-Operational" ||
                  a.status === "Out for Repair":
                  status = "bg-red-900 ";
                  break;
                case a.status === "Faulty":
                  status = "bg-yellow-900 ";
                  break;
                default:
                  status = "bg-green-900 ";
                  break;
              }
              let lastCheck = "";
              if (a.lastCheck.status) {
                switch (true) {
                  case a.lastCheck.status === "scheduled":
                    // case a.lastCheck.status === "scheduled" &&
                    //   dayjs(a.lastCheck.date).isAfter(dayjs):
                    lastCheck = " ";
                    break;
                  case a.lastCheck.status === "OK":
                    lastCheck = "bg-green-900 ";
                    break;
                  default:
                    lastCheck = "bg-red-900 ";
                    break;
                }
              }
              let expires = "";
              switch (true) {
                case dayjs(a.date_expires).isBefore(dayjs()):
                  expires = " bg-red-900 bg-opacity-50 ";
                  break;
                case dayjs(a.date_expires).isAfter(dayjs()) &&
                  dayjs(a.date_expires).isBefore(dayjs().add(3, "month")):
                  expires = " bg-yellow-900 bg-opacity-50 ";
                  break;
                case dayjs(a.date_expires).isAfter(dayjs()) &&
                  dayjs(a.date_expires).isAfter(dayjs().add(3, "month")):
                  expires = " bg-green-900 bg-opacity-50 ";
                  break;

                default:
                  expires = " ";
                  break;
              }

              return (
                <tr
                  key={a._id}
                  className={`tableBodyRowClass cursor-pointer text-sm`}
                >
                  <td className="p-2" onClick={onViewClick}>
                    {a.name}
                  </td>
                  <td className="w-1/8 p-2" onClick={onViewClick}>
                    {a.assetNum}
                  </td>
                  {/* <td className="p-2" onClick={onViewClick}>
                          {a.serialNum}
                        </td> */}
                  <td
                    className={`p-2 ${status} w-1/8 bg-opacity-50`}
                    onClick={onViewClick}
                  >
                    {a.status}
                  </td>
                  <td
                    className="hidden p-2 2xl:table-cell"
                    onClick={onViewClick}
                  >
                    {a.lastService
                      ? dayjs(a.lastService).format("DD-MM-YYYY")
                      : "--"}
                  </td>
                  <td
                    className={`hidden p-2 2xl:table-cell ${
                      // a.lastCheck.status
                      //   ? a.lastCheck.status === "OK"
                      //     ? "bg-green-900 bg-opacity-50"
                      //     : "has-tooltip bg-red-900 bg-opacity-50"
                      //   : ""
                      lastCheck
                    }`}
                    onClick={onViewClick}
                  >
                    {a.lastCheck.note && (
                      <Tooltip
                        style={`${
                          a.lastCheck.status !== "OK"
                            ? "bg-red-900"
                            : "bg-green-900"
                        }`}
                        text={a.lastCheck.note}
                      />
                    )}
                    {a.lastCheck.date
                      ? dayjs(a.lastCheck.date).format("DD-MM-YYYY")
                      : "--"}
                  </td>
                  {/* <td className={`p-2 ${expires}`} onClick={onViewClick}>
                          {a.date_expires
                            ? dayjs(a.date_expires).format("DD-MM-YY")
                            : "--"}
                        </td> */}
                  <td className="text-center">{a.weight ? a.weight : "--"}</td>
                  <td className="p-2" onClick={onViewClick}>
                    {a.tags.map((t, i) => {
                      return (
                        <span
                          key={i}
                          className="m-1 rounded-lg border border-gray-700 bg-gray-800 p-2"
                        >
                          {t}
                        </span>
                      );
                    })}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan="8" className="h-12 p-4 text-2xl">
                Loading...
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}
