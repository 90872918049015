import {Route, Routes} from "react-router-dom";
import React from "react";
import AddService from "./addService";
import ViewService from "./viewService";
import EditService from "./editService";

const ServicePages = () => {
  return (
    <Routes>
      <Route path="/add" element={<AddService />} />
      <Route path="/:service_id" element={<ViewService />} />
      <Route path="/:service_id/edit" element={<EditService />} />
    </Routes>
  );
}
export default ServicePages;