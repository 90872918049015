import React from "react";
import CourseEventForm from "../../../../components/widgets/CourseEventForm";
import Modal from "../../../../components/common/Modal";
import API from "../../../../api";
import Toast from "react-hot-toast";

const CourseEventAddModel = ({ onClose }) => {
  const handleSubmit = (data) => {
    return API.courses.events
      .add(data)
      .then(() => {
        onClose();
      })
      .catch((err) => {
        Toast.error(err.message);
      });
  };

  return (
    <Modal
      title={"Add Course Event"}
      onClose={onClose}
      disableOutsideClick
      className={"max-w-5xl"}
    >
      <CourseEventForm onCancel={onClose} onSubmit={handleSubmit} />
    </Modal>
  );
};
export default CourseEventAddModel;
