import React, { Component } from "react";

const AlertBox = (props) => {
  let colour = "bg-blue-100 border-t-4 border-blue-500 text-blue-900";
  let icon = (
    <svg className="fill-current h-6 w-6 text-primary mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
    </svg>
  );
  if (props.priority) {
    switch (props.priority) {
      case "low":
        break;
      case "medium":
        colour = "bg-yellow-100 border-t-4 border-yellow-500 text-yellow-900";
        icon = (
          <svg className="fill-current h-6 w-6 text-yellow-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
          </svg>
        );
        break;
      case "high":
        colour = "bg-red-100 border-t-4 border-red-500 text-red-900";
        icon = (
          <svg className="fill-current h-6 w-6 text-red-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
          </svg>
        );
        break;
      default:
        colour = "bg-blue-50 border-t-4 border-primary text-blue-900";
        icon = (
          <svg className="fill-current h-6 w-6 text-primary mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
          </svg>
        );
        break;
    }

    return (
      <div className={` px-4 py-3 shadow-md rounded ${colour}`} role="alert">
        <div className="flex items-center">
          <div className="py-2">{icon}</div>
          <div>
            <p className="pb-1 text-xl font-bold ">{props.header}</p>
            <p className="text-md tracking-wider">{props.message}</p>
          </div>
        </div>
      </div>
    );
  }
};
export default AlertBox;
// return <Message className={"clickable alerts"} color={color} onClick={this.props.action} icon={icon} header={this.props.header} info={this.props.content} />;
