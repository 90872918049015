import dayjs from "dayjs";
import React, { useEffect, useState, useMemo } from "react";
import Box from "../../components/common/Box";
import {Skeleton} from "../../components/ui/skeleton";
import CommonFunctions from "../../lib/CommonFunctions";
import API from "../../api";

const MemberDeployments = () => {
  const [data, setData] = useState([]);
  const [deploymentTypes, setDeploymentTypes] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    API.reports.memberDeployments.getResults().then(res => {
      setData(res.results);
      setDeploymentTypes(res.deploymentTypes);
      setLoading(false);
    })
  }, []);

  const handleCsvExport = () => {
    let headerFields = [
      { csv_field: "First Name", database_field: "firstName" },
      { csv_field: "Surname", database_field: "lastName" },
      { csv_field: "Callsign", database_field: "teamId" },
    ]
    deploymentTypes.forEach(dt => {
      headerFields.push({
        csv_field: dt,
        database_field: "totals_" + dt,
      })
    })
    headerFields.push(
      { csv_field: "Total", database_field: "total" },
    )
    CommonFunctions.generateCSV(`Members Deployment Totals`, headerFields, data.map(item => {
      let totalsObj = {
        total: item.attended_callouts.total
      };
      deploymentTypes.forEach(dt => {
        totalsObj["totals_" + dt] = item.attended_callouts.type[dt] !== undefined ? item.attended_callouts.type[dt] : "0"
      })

      return {
        ...item,
        ...totalsObj,
      }
    }));
  }

  return (
    <>
      {loading ? (
        <div className="flex flex-wrap justify-center gap-4 p-4">
          <Skeleton className="h-20 w-1/4 bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
          <Skeleton className="h-20 w-1/4 bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
          <Skeleton className="h-20 w-1/4 bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
        </div>
      ) : (
        <div className="col-span-1 md:col-span-1">
          <Box title={"Members Deployment Totals"} button={data.length >= 1 ? {
            text: "CSV Export",
            colourOverride: "text-sm text-white hover:text-gray-200 border",
            action: () => handleCsvExport()
          } : false}>
            <table className={`tableClass`}>
              <thead>
              <tr className="tableHeadRowClass">
                <th className="tableHeadCellClass">Name</th>
                {deploymentTypes.map((dt, dti) => {
                  return (
                    <td key={dti} className="tableHeadCellClass w-40 text-center">
                      {dt}
                    </td>
                  )
                })}
                <th className="tableHeadCellClass w-40 text-center">Total</th>
              </tr>
              </thead>
              <tbody className="tableBodyClass">{data.map((item, index) => {
                return (
                  <tr key={index} className="tableBodyRowClass">
                    <td className="tableBodyCellClass">
                      {item.name}{" "}{item.teamId ? (<span className="italic">({item.teamId})</span>) : (<></>)}
                    </td>
                    {deploymentTypes.map((dt, dti) => {
                      return (
                        <td key={dti} className="tableBodyCellClass text-center">
                          {item.attended_callouts.type[dt] !== undefined ? item.attended_callouts.type[dt] : "--"}
                        </td>
                      )
                    })}
                    <td className={`tableBodyCellClass text-center`}>
                      {item.attended_callouts.total}
                    </td>
                  </tr>
                );
              })}
              </tbody>
            </table>
          </Box>
        </div>
      )}
    </>
  );
}
export default MemberDeployments;