import React, { useEffect, useState, useMemo, useContext } from "react";
import { Vector as VectorSource } from "ol/source";
import { Vector as VectorLayer } from "ol/layer";
import { Fill, Stroke, Style, Text } from "ol/style";
import { Draw, Modify, Snap } from "ol/interaction";
import GeoJSONManager from "ol/format/GeoJSON";
import { get } from "ol/proj";
import * as Layers from "../layers";
import MapSelector from "../../mapping/ol/MapSelector";
import { Map, Projection } from "../context";

const style = new Style({
  stroke: new Stroke({
    color: "blue",
    width: 4,
  }),
  text: new Text({
    fill: new Fill({
      color: "white",
    }),
    backgroundFill: new Fill({ color: [0, 0, 0, 0.3] }),
    padding: [3, 5, 3, 5],
    textAlign: "center",
  }),
});
const DrawLayer = ({ GeoJSON, type, onFeatureUpdate }) => {
  const mapContext = useContext(Map);
  const projectionContext = useContext(Projection);

  const source = useMemo(() => new VectorSource({ wrapX: false }), []);
  const vector = useMemo(
    () =>
      new VectorLayer({
        source,
        style,
      }),
    [source, style]
  );

  useEffect(() => {
    if (!GeoJSON) return;

    source.addFeature(
      new GeoJSONManager().readFeature(GeoJSON, {
        featureProjection: get(projectionContext.projection),
      })
    );

    return () => {
      source.clear();
    };
  }, [projectionContext, GeoJSON]);
  useEffect(() => {
    if (!mapContext) return;

    const draw = new Draw({
      source: source,
      type: type,
      freehand: false,
    });

    draw.on("drawstart", function () {
      source.clear();
    });
    draw.on("drawend", function (evt) {
      const area = new GeoJSONManager().writeFeatureObject(evt.feature, {
        featureProjection: mapContext.getView().getProjection().getCode(),
      });

      onFeatureUpdate(area);
    });

    mapContext.addInteraction(draw);

    return () => {
      mapContext.removeInteraction(draw);
    };
  }, [mapContext, source, type]);
  useEffect(() => {
    if (!mapContext) return;

    const snap = new Snap({ source: source });
    const modify = new Modify({ source: source });

    modify.on("modifyend", function (evt) {
      const area = new GeoJSONManager().writeFeatureObject(evt.features.array_[0], {
        featureProjection: mapContext.getView().getProjection().getCode(),
      });

      onFeatureUpdate(area);
    });

    mapContext.addInteraction(snap);
    mapContext.addInteraction(modify);

    return () => {
      mapContext.removeInteraction(snap);
      mapContext.removeInteraction(modify);
    };
  }, [mapContext, source]);
  useEffect(() => {
    if (!mapContext) return;

    mapContext.addLayer(vector);

    return () => {
      mapContext.removeLayer(vector);
    };
  }, [mapContext, vector]);

  return null;
};

const DrawController = ({ geoJSON, onFeatureUpdate, stopEdit }) => {
  const [drawType, setType] = useState("None");
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    if (!geoJSON) return;

    setType(geoJSON.geometry.type);
  }, [edit, geoJSON]);
  useEffect(() => {
    // if (!geoJSON) return;
    if (stopEdit) {
      setEdit(false);
    }
  }, [stopEdit]);
  console.log(stopEdit);
  if (geoJSON) {
    return (
      <>
        <div aria-label="drawControl" className="drawControl absolute left-2 top-28 rounded p-2 text-white bg-gray-500 z-30 text-sm border border-gray-400">
          <div className="">Draw Control</div>
          <hr className="py-1" />
          <MapSelector action={() => setEdit(false)} type={"sat"} label="None" checked={!edit} />
          <MapSelector action={() => setEdit(true)} type={"sat"} label="Edit" checked={edit} />
        </div>

        {edit && <DrawLayer GeoJSON={geoJSON} type={drawType} onFeatureUpdate={onFeatureUpdate} />}
        {!edit && <Layers.GeoJSON geoJSON={geoJSON} zIndex={90} style={() => style} />}
      </>
    );
  }

  return (
    <>
      <div aria-label="drawControl" className="drawControl absolute left-2 top-24 rounded p-2 text-white bg-gray-500 bg-opacity-80 text-sm border border-gray-400 z-30">
        <div className="">Draw Control</div>
        <hr className="py-1" />
        <div onClick={() => setType("None")} className="cursor-pointer">
          <input type="radio" checked={drawType === "None"} onChange={() => setType("None")} /> None/Clear
        </div>
        <div onClick={() => setType("Polygon")} className="cursor-pointer py-1">
          <input type="radio" checked={drawType === "Polygon"} onChange={() => setType("Polygon")} /> Area
        </div>
        <div onClick={() => setType("LineString")} className="cursor-pointer">
          <input type="radio" checked={drawType === "LineString"} onChange={() => setType("LineString")} /> Route
        </div>
      </div>

      {drawType !== "None" && <DrawLayer type={drawType} onFeatureUpdate={onFeatureUpdate} />}
    </>
  );
};
export default DrawController;
