import React, {useState, useEffect, useMemo} from "react";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import Box from "../../components/common/Box";
import Layout from "../../components/common/Layout";
import Button from "../../components/common/Button";
import Tooltip from "../../components/common/Tooltip";
import ToggleSwitch from "../../components/common/ToggleSwitch";
import api from "../../api";
import MultiAssignAsset from "./_components/multiAssignAsset";
import MultiAssetCheck from "./_components/multiAssetCheck";

const AssetList = () => {
  const navigate = useNavigate();

  const [assets, setAssets] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [showArchived, setShowArchived] = useState(false);
  const [loading, setLoading] = useState(true);
  const [tags, setTags] = useState([]);
  const [filterTag, setFilterTag] = useState("");
  const [groupSelect, setGroupSelect] = useState([]);
  const [showMultiAssign, setShowMultiAssign] = useState(false);
  const [showMultiCheck, setShowMultiCheck] = useState(false);

  const groupSelectedObjs = useMemo(() => {
    return groupSelect.map(a => {
      const found = assets.filter(b => b._id === a)
      if(found[0] !== undefined){
        return found[0];
      }
      return false;
    }).filter(a => a !== false)
  }, [groupSelect, assets]);

  const getData = () => {
    return api.assets.all().then((res) => {
      setAssets(res);
      if (res.length) {
        setFiltered(
          res.filter((a) => {
            return a.archived === showArchived;
          })
        );
      }
    }).finally(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    api.assets.settings.all().then((res) => {
      if (res.tags !== undefined) {
        setTags(
          res.tags.map((t, i) => {
            return {
              key: i,
              text: t,
              value: t,
            };
          })
        );
      }
    });
    getData();
  }, []);
  useEffect(() => {
    if (filterTag !== "") {
      let list = assets.filter((a) => {
        return a.archived === showArchived;
      });
      list = list.filter((item) => {
        let hasTag = item.tags.filter((tag) => {
          return tag === filterTag;
        });
        return hasTag.length >= 1;
      });
      setFiltered(list);
    } else {
      setFiltered(
        assets.filter((a) => {
          return a.archived === showArchived;
        })
      );
    }
  }, [filterTag, showArchived]);

  const filterBy = (data) => {
    if (data) {
      if (data === filterTag) {
        data = "";
      }
      setFilterTag(data);
    }
  };

  const filterList = (e) => {
    let results = assets.filter(item => {
      return item.archived === showArchived;
    })
    if (e.target.value.length > 0) {
      results = results.filter(item => {
        return (
          (item.name || "").toLowerCase().search(e.target.value.toLowerCase()) !== -1 ||
          (item.serialNum || "").toLowerCase().search(e.target.value.toLowerCase()) !== -1
        );
      });
    }
    setFiltered(results);
  };
  const addToGroup = (id) => {
    let list = [];
    if (groupSelect.includes(id)) {
      let current = groupSelect.filter((item) => {
        return item !== id;
      });
      setGroupSelect(current);
    } else if (id) {
      list.push(id);
      setGroupSelect(groupSelect.concat(list));
    }
  };

  return (
    <Layout full>
      {showMultiAssign && <MultiAssignAsset assets={groupSelectedObjs} onClose={() => {
        setShowMultiAssign(false);
        getData();
      }} />}
      {showMultiCheck && <MultiAssetCheck assets={groupSelectedObjs} onClose={() => {
        setShowMultiCheck(false);
        getData();
      }} />}

      <div className="grid grid-cols-1 gap-4 md:grid-cols-1">
        <div className="md:col-span- col-span-1">
          <Box>
            <div className="grid sm:grid-cols-3">
              <div className="col-span-1 space-y-2 p-2">
                <input
                  autoComplete="off"
                  name="filter"
                  placeholder="asset/serial number"
                  onChange={filterList}
                  className={`inputClass`}
                />
                <div className="flex items-center justify-between">
                  <div className="px-2">Selected: {groupSelect.length}</div>
                  <div className="p-2">
                    <span
                      onClick={() => setShowArchived(true)}
                      className={`${
                        showArchived && "bg-blue-900 text-white"
                      } m-1 cursor-pointer  rounded-lg p-2 hover:bg-blue-800 dark:text-white`}
                    >
                      Archived
                    </span>
                    <span
                      onClick={() => setShowArchived(false)}
                      className={`${
                        !showArchived && "bg-blue-900 text-white"
                      } m-1 cursor-pointer rounded-lg p-2 hover:bg-blue-800 dark:text-white`}
                    >
                      Active
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-span-2 ">
                <div className="col-span-2 flex flex-wrap gap-1 p-2">
                  {tags.sort((a, b) => {
                    if(a.value > b.value) return 1;
                    if(a.value < b.value) return -1;
                    return 0;
                  }).map((t) => {
                    return (
                      <span
                        onClick={() => filterBy(t.value)}
                        key={t.key}
                        className={`${
                          t.value === filterTag
                            ? " bg-green-800 text-white hover:bg-green-800"
                            : " bg-zinc-300 dark:bg-gray-700"
                        }  cursor-pointer rounded-lg p-2 py-1.5 text-sm hover:bg-gray-600 dark:text-white`}
                      >
                        {t.text}
                      </span>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="flex gap-4 border-t border-zinc-300 p-2 dark:border-zinc-700">
              <Button
                colour={"text-sm bg-zinc-300 dark:bg-zinc-500 dark:border-zinc-400"}
                text="Add Check"
                onClick={() => setShowMultiCheck(true)}
                disabled={groupSelect.length <= 0}
              />
              {/*<Button*/}
              {/*  colour={*/}
              {/*    " text-sm bg-zinc-300 dark:bg-zinc-500 dark:border-zinc-400"*/}
              {/*  }*/}
              {/*  text="Add Service"*/}
              {/*  disabled={groupSelect.length <= 0}*/}
              {/*/>*/}
              <Button
                colour={"text-sm bg-zinc-300 dark:bg-zinc-500 dark:border-zinc-400"}
                text="Assign"
                onClick={() => setShowMultiAssign(true)}
                disabled={groupSelect.length <= 0}
              />
            </div>
            <table className="tableClass">
              <thead>
                <tr className="tableHeadRowClass">
                  <th className="tableHeadCellClass"></th>
                  <th className="tableHeadCellClass">Asset</th>
                  <th className="tableHeadCellClass">Asset Number</th>
                  {/* <th className="tableHeadCellClass">Serial Number</th> */}
                  <th className="tableHeadCellClass">Status</th>
                  <th className="tableHeadCellClass hidden xl:table-cell">
                    Last Service
                  </th>
                  <th className="tableHeadCellClass">Last Check</th>
                  {/* <th className="tableHeadCellClass">Expires Date</th> */}
                  <th className="tableHeadCellClass">Tags</th>
                  <th className="tableHeadCellClass">Assigned</th>
                </tr>
              </thead>
              <tbody className="tableBodyClass">
                {!loading ? (
                  filtered.sort((a, b) => {
                    if(a.name > b.name) return 1;
                    if(a.name < b.name) return -1;
                    return 0;
                  }).map((a) => {
                    const onViewClick = () => {
                      navigate(`/asset/view/${a._id}`);
                    };
                    const assigned = (
                      <div
                        onClick={() =>
                          navigate(`/${a.assignedTo.type}/${a.assignedTo._id}`)
                        }
                        className="w-auto rounded-xl border border-gray-700 px-2 py-1 text-center"
                      >
                        {a.assignedTo.name}
                      </div>
                    );
                    let status = "";
                    switch (true) {
                      case a.status === "Non-Operational" ||
                        a.status === "Out for Repair":
                        status = "bg-red-900 ";
                        break;
                      case a.status === "Faulty":
                        status = "bg-yellow-900 ";
                        break;
                      default:
                        status = "bg-green-900 ";
                        break;
                    }
                    let lastCheck = "";
                    if (a.lastCheck.status) {
                      switch (true) {
                        case a.lastCheck.status === "scheduled":
                          // case a.lastCheck.status === "scheduled" &&
                          //   dayjs(a.lastCheck.date).isAfter(dayjs):
                          lastCheck = " ";
                          break;
                        case a.lastCheck.status === "OK":
                          lastCheck = "bg-green-900 text-white";
                          break;
                        default:
                          lastCheck = "bg-red-900 text-white";
                          break;
                      }
                    }
                    let expires = "";
                    switch (true) {
                      case dayjs(a.date_expires).isBefore(dayjs()):
                        expires = " bg-red-900 bg-opacity-50 ";
                        break;
                      case dayjs(a.date_expires).isAfter(dayjs()) &&
                        dayjs(a.date_expires).isBefore(dayjs().add(3, "month")):
                        expires = " bg-yellow-900 bg-opacity-50 ";
                        break;
                      case dayjs(a.date_expires).isAfter(dayjs()) &&
                        dayjs(a.date_expires).isAfter(dayjs().add(3, "month")):
                        expires = " bg-green-900 bg-opacity-50 ";
                        break;

                      default:
                        expires = " ";
                        break;
                    }

                    return (
                      <tr
                        key={a._id}
                        className={`tableBodyRowClass cursor-pointer text-sm`}
                      >
                        <td className="w-6 p-2">
                          <ToggleSwitch
                            isChecked={groupSelect.includes(a._id)}
                            onChange={() => addToGroup(a._id)}
                          />
                        </td>
                        <td className="p-2" onClick={onViewClick}>
                          {a.name}
                        </td>
                        <td className="w-1/8 p-2" onClick={onViewClick}>
                          {a.assetNum}
                        </td>
                        {/* <td className="p-2" onClick={onViewClick}>
                          {a.serialNum}
                        </td> */}
                        <td
                          className={`p-2 ${status} w-1/8 bg-opacity-50`}
                          onClick={onViewClick}
                        >
                          {a.status}
                        </td>
                        <td
                          className="hidden p-2 xl:table-cell"
                          onClick={onViewClick}
                        >
                          {a.lastService
                            ? dayjs(a.lastService).format("DD-MM-YYYY")
                            : "--"}
                        </td>
                        <td
                          className={`p-2 ${
                            // a.lastCheck.status
                            //   ? a.lastCheck.status === "OK"
                            //     ? "bg-green-900 bg-opacity-50"
                            //     : "has-tooltip bg-red-900 bg-opacity-50"
                            //   : ""
                            lastCheck
                          }`}
                          onClick={onViewClick}
                        >
                          {a.lastCheck.note && (
                            <Tooltip
                              style={`${
                                a.lastCheck.status !== "OK"
                                  ? "bg-red-900"
                                  : "bg-green-900"
                              }`}
                              text={a.lastCheck.note}
                            />
                          )}
                          {a.lastCheck.date
                            ? dayjs(a.lastCheck.date).format("DD-MM-YYYY")
                            : "--"}
                        </td>
                        <td className="p-2" onClick={onViewClick}>
                          {a.tags.map((t, i) => {
                            return (
                              <span
                                key={i}
                                className="m-1 rounded-lg border border-gray-700 p-2 dark:bg-gray-800"
                              >
                                {t}
                              </span>
                            );
                          })}
                        </td>
                        <td className="w-1/8 p-2">
                          {a.assignedTo.name ? assigned : "--"}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="8" className="h-12 p-4 text-2xl">
                      Loading...
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </Box>
        </div>
      </div>
    </Layout>
  );
};
export default AssetList;