import React, {useContext, useEffect} from "react";
import {
  LayersToggle_Register,
  LayersToggle_Active
} from "../context";

const Toggle = ({title, active = true, children}) => {
  const toggleListContext = useContext(LayersToggle_Active);
  const toggleRegisterContext = useContext(LayersToggle_Register);

  useEffect(() => {
    if(!toggleRegisterContext) return;

    toggleRegisterContext.register(title, active)
    return () => {
      toggleRegisterContext.deregister(title);
    }
  }, [title, toggleRegisterContext, active]);

  if (toggleListContext.find(a => a.title === title && a.isActive) === undefined) return null;
  return children;
};
export default Toggle;