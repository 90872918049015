import React, {useEffect, useState} from "react";
import {Tab} from "@headlessui/react";
import useLocalStorage from "../../../hooks/useLocalStorage";
import Box from "../../../components/common/Box";
import {APIRequest} from "../../../lib/_sso";
import AppConfig from "../../../_appConfig";
import TeamSiteMigration from "./teamsite_migration";
import AssetsPatch from "./assets_patch";
import {toast} from "react-hot-toast";

const server = AppConfig.api_server;

const returnTabButtonStyle = (selected) => {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  return classNames(
    "w-full py-2.5 text-lg leading-5 font-medium  rounded-lg",
    selected
      ? "text-gray-100 bg-green-900 bg-opacity-50"
      : "text-blue-100 dark:hover:bg-white/[0.12] hover:text-white bg-gray-800"
  );
};

const ImporterPage = () => {
  const [otp, setOtp] = useState("");
  const [auth, setAuth] = useLocalStorage("importer_auth", {expiresAt: Date.now() / 1000, token: ""});
  const [config, setConfig] = useState({});

  useEffect(() => {
    if (auth.token === "") return;

    APIRequest.get(`${server}/settings/importer/config?token=${auth.token}`).then(res => {
      setConfig(res.data);
    });
  }, [auth.token]);
  useEffect(() => {
    if(auth.expiresAt < (Date.now() / 1000) && auth.token !== "") setAuth({expiresAt: Date.now() / 1000, token: ""});
  }, [auth])

  const loginViaOtp = () => {
    APIRequest.post(`${server}/settings/importer/login`, {
      otp
    }).then(res => {
      setAuth(res.data);
    }).catch(err => {
      toast.error("Unable to login...")
    })
  }

  return (
    <div className={"p-1 pr-4"}>
      <Box title={"Data Importer"}>
        <div className={"p-2"}>
          <div className={"p-2 border rounded mb-4 text-red-700"}>This page is reserved for SarStuff team members to migrate data between systems.</div>

          {auth.token === "" ? <div className={"flex justify-center"}>
            <div className="flex w-full items-center gap-2 p-2 align-middle">
              <input
                value={otp}
                className="w-full rounded-md border-gray-400 bg-white p-2 shadow-sm dark:bg-zinc-700"
                type={"text"}
                onChange={e => setOtp(e.target.value)}
                placeholder="Yubico OTP..."
              />
              <button
                disabled={otp === ""}
                onClick={loginViaOtp}
                className="h-10 cursor-pointer rounded-md border border-green-500 bg-green-300 bg-opacity-40 px-4 py-2 text-green-500 hover:border-green-300"
              >Login
              </button>
            </div>
          </div> : <>
              <Tab.Group defaultIndex={0}>
                <Tab.List className="flex space-x-1 border-b border-zinc-200  p-1 pb-2 dark:border-zinc-700 dark:bg-blue-900/20">
                  <Tab className={({ selected }) => returnTabButtonStyle(selected)}>
                    Data Migration
                  </Tab>
                  <Tab className={({ selected }) => returnTabButtonStyle(selected)}>
                    Asset Assignment Patch
                  </Tab>
                </Tab.List>
                <Tab.Panels>
                  <Tab.Panel className={"p-1"}>
                    <TeamSiteMigration config={config} auth={auth} />
                  </Tab.Panel>
                  <Tab.Panel className={"p-1"}>
                    <AssetsPatch config={config} auth={auth} />
                  </Tab.Panel>
                </Tab.Panels>
              </Tab.Group>
          </>}
        </div>
      </Box>
    </div>
  );
}
export default ImporterPage;