import { APIRequest } from "../lib/_sso";
import AppConfig from "../_appConfig";

const server = AppConfig.api_server;

const courses = {
  all: () =>
    APIRequest.get(`${server}/settings/courses`).then((res) => res.data),
  create: (data) =>
    APIRequest.post(`${server}/settings/courses`, data).then((res) => res.data),
  update: (id, data) =>
    APIRequest.patch(`${server}/settings/courses/${id}`, data).then(
      (res) => res.data
    ),
  delete: (id) =>
    APIRequest.delete(`${server}/settings/courses/${id}`).then(
      (res) => res.data
    ),
};
const requirements = {
  all: () =>
    APIRequest.get(`${server}/settings/requirements`).then((res) => res.data),
  create: (data) =>
    APIRequest.post(`${server}/settings/requirements`, data).then(
      (res) => res.data
    ),
  update: (id, data) =>
    APIRequest.patch(`${server}/settings/requirements/${id}`, data).then(
      (res) => res.data
    ),
  delete: (id) =>
    APIRequest.delete(`${server}/settings/requirements/${id}`).then(
      (res) => res.data
    ),
};
const stations = {
  getStations: () => APIRequest.get(`${server}/settings/stations`).then(res => res.data),
  createStation: data => APIRequest.post(`${server}/settings/stations`, data).then(res => res.data),
  updateStation: (id, data) => APIRequest.patch(`${server}/settings/stations/${id}`, data).then(res => res.data),
  deleteStation: id => APIRequest.delete(`${server}/settings/stations/${id}`).then(res => res.data),
};

export default {
  courses,
  requirements,
  stations,

  all: () => APIRequest.get(`${server}/settings`).then((res) => res.data),
  updateSystem: (data) =>
    APIRequest.patch(`${server}/settings`, data).then((res) => res.data),
  addComp: (data) =>
    APIRequest.post(`${server}/settings/competency/add`, data).then(
      (res) => res.data
    ),
  updateComp: (id, data) =>
    APIRequest.patch(`${server}/settings/competency/update/${id}`, data).then(
      (res) => res.data
    ),
  deleteComp: (id) =>
    APIRequest.delete(`${server}/settings/competency/update/${id}`).then(
      (res) => res.data
    ),
  getComps: () =>
    APIRequest.get(`${server}/settings/competency`).then((res) => res.data),
  getComp: (id) =>
    APIRequest.get(`${server}/settings/competency/${id}`).then(
      (res) => res.data
    ),
  getCompModules: () =>
    APIRequest.get(`${server}/settings/competency/modules`).then(
      (res) => res.data
    ),
  addCompModules: (data) =>
    APIRequest.post(`${server}/settings/competency/modules`, data).then(
      (res) => res.data
    ),
  updateCompModules: (id, data) =>
    APIRequest.patch(`${server}/settings/competency/modules/${id}`, data).then(
      (res) => res.data
    ),
  deleteCompModules: (id) =>
    APIRequest.delete(`${server}/settings/competency/modules/${id}`).then(
      (res) => res.data
    ),
  getCompSections: () =>
    APIRequest.get(`${server}/settings/competency/section`).then(
      (res) => res.data
    ),
  addCompSection: (data) =>
    APIRequest.post(`${server}/settings/competency/section`, data).then(
      (res) => res.data
    ),
  updateCompSection: (id, data) =>
    APIRequest.patch(`${server}/settings/competency/section/${id}`, data).then(
      (res) => res.data
    ),
  deleteCompSection: (id) =>
    APIRequest.delete(`${server}/settings/competency/section/${id}`).then(
      (res) => res.data
    ),
  addCompSkill: (data) =>
    APIRequest.post(`${server}/settings/competency/skill`, data).then(
      (res) => res.data
    ),
  getCompSkills: () =>
    APIRequest.get(`${server}/settings/competency/skill`).then(
      (res) => res.data
    ),
  updateCompSkills: (id, data) =>
    APIRequest.patch(`${server}/settings/competency/skill/${id}`, data).then(
      (res) => res.data
    ),
  deleteCompSkills: (id) =>
    APIRequest.delete(`${server}/settings/competency/skill/${id}`).then(
      (res) => res.data
    ),
  addRole: (data) =>
    APIRequest.post(`${server}/settings/roles`, data).then((res) => res.data),
  getRoles: () =>
    APIRequest.get(`${server}/settings/roles`).then((res) => res.data),
  updateRole: (id, data) =>
    APIRequest.patch(`${server}/settings/roles/${id}`, data).then(
      (res) => res.data
    ),
  deleteRole: (id) =>
    APIRequest.delete(`${server}/settings/roles/${id}`).then((res) => res.data),
  addCPDConfig: (data) =>
    APIRequest.post(`${server}/settings/cpd`, data).then((res) => res.data),
  updateCPDConfig: (id, data) =>
    APIRequest.patch(`${server}/settings/cpd/${id}`, data).then(
      (res) => res.data
    ),
  deleteCPDConfig: (id) =>
    APIRequest.delete(`${server}/settings/cpd/${id}`).then((res) => res.data),
  getCPDConfig: () =>
    APIRequest.get(`${server}/settings/cpd`).then((res) => res.data),
  getCPDConfigByRole: (id) =>
    APIRequest.get(`${server}/settings/cpd/role/${id}`).then((res) => res.data),
  syncCentralMembers: () =>
    APIRequest.get(`${server}/settings/central_sync`).then((res) => res.data),
  syncMembersRoles: () =>
    APIRequest.get(`${server}/settings/central_sync_roles`).then((res) => res.data),

  auditLogs: () => APIRequest.get(`${server}/logs`).then((res) => res.data),

  getIncidentTypesList: () => APIRequest.get(`${server}/settings/incident_types_list`).then((res) => res.data),
};
