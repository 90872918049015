import React, {useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {toast} from "react-hot-toast";
import Box from "../../components/common/Box";
import IncidentForm from "./_components/IncidentForm";
import api from "../../api";


function IncidentAdd() {
  const navigation = useNavigate();

  const handleSubmit = (data) => {
    api.incidents.createIncident(data).then(res => {
      navigation(`/incidents/edit/${res._id}`);
      toast.success("Successfully created incident, please now complete the final details.");
    }).catch(err => {
      if(err.isAxiosError){
        if(err.response.data.message !== undefined){
          toast.error(err.response.data.message);
          return;
        }

        toast.error("There was a network error while submitting your data, please try again shortly or check your internet connection.");
        return;
      }
      toast.error("There was an unexpected error while processing your request.");
    });
  };

  return (
    <div className="w-full p-2">
      <Box title="Add Incident">
        <IncidentForm onSubmit={handleSubmit} />
      </Box>
    </div>
  );
}

export default IncidentAdd;