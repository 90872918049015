import Authentication from "../Authentication";

export default props => {
  let canDoIt;
  if (Array.isArray(props.scope)) {
    canDoIt = false;
    for (const scope of props.scope) {
      if (Authentication.can(scope, props.context)) canDoIt = true;
    }
  } else {
    canDoIt = Authentication.can(props.scope, props.context);
  }
  return canDoIt ? props.children : null;
}