import React, { useState, useEffect } from "react";
import ManageSupplierContacts from "./manageSupplierContacts";
import api from "../../../api";
import Layout from "../../../components/common/Layout";
import { useParams } from "react-router-dom";
import { toast } from "react-hot-toast";

export default function ContactsPage() {
  const [supplier, setSupplier] = useState({});
  const { supplier_id } = useParams();

  useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    api.assets.suppliers.show(supplier_id).then((res) => {
      setSupplier(res);
    });
  };

  const handleSupplierContactsUpdate = (contacts) => {
    return api.assets.suppliers
      .update(supplier_id, {
        ...supplier,
        contacts,
      })
      .then(() => {
        toast.success("Contact Saved");
        getData();
      });
  };

  return (
    <Layout full>
      <div className="">
        <ManageSupplierContacts
          contacts={supplier.contacts}
          onCancel={() => getData()}
          onSubmit={(data) => handleSupplierContactsUpdate(data)}
        />
      </div>
    </Layout>
  );
}
