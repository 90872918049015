import {useSearchParams} from "react-router-dom";
import React, {useEffect, useMemo, useState} from "react";
import Box from "../../components/common/Box";
import API from "../../api";
import DayJS from "dayjs";
import CommonFunctions from "../../lib/CommonFunctions";

const returnTabButtonStyle = (selected) => {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  return classNames(
    "w-full py-2.5 text-lg leading-5 font-medium  rounded-lg",
    selected
      ? "text-gray-100 bg-green-900 bg-opacity-50"
      : "text-blue-100 hover:bg-white/[0.12] hover:text-white bg-gray-800"
  );
};

const CoursesReport = () => {
  const [courses, setCourses] = useState([]);
  const [reportResults, setReportResults] = useState([]);
  const [isResultLoading, setIsResultLoading] = useState(false);

  const [search, setSearch] = useSearchParams({
    filter: "expired"
  });


  useEffect(() => {
    API.settings.courses.all().then((res) => {
      setCourses(res);
    });
  }, []);


  useEffect(() => {
    setIsResultLoading(true);
    API.reports.qualifications.getCourseDetailed(search.get("id") === null ? "" : search.get("id"), search.get("filter")).then(res => {
      setReportResults(res);
    }).finally(() => {
      setIsResultLoading(false);
    });
  }, [search])


  const onFilterChange = (course_id, filterLevel = "expired") => {
    if (course_id === null) {
      setSearch({ filter: filterLevel });
    } else {
      setSearch({ id: course_id, filter: filterLevel });
    }
  };
  const handleCsvExport = () => {
    const header = [
      { csv_field: "Callsign", database_field: "callsign" },
      { csv_field: "Name", database_field: "name" },
      { csv_field: "Course", database_field: "course" },
      { csv_field: "Expires", database_field: "expires" },
    ];

    const body = [];
    reportResults.forEach((item) => {
      body.push({
        callsign: item.callsign,
        name: item.firstName + " " + item.lastName,
        course: item.course?.name,
        expires: DayJS(item.result?.expiresAt).format("DD-MM-YYYY")
      });
    });

    CommonFunctions.generateCSV("Expired Courses", header, body);
  }

  return (
    <div className={"p-2"}>
      <h1 className={"p-2 text-2xl"}>Courses Report</h1>
      <Box title="Filter">
        <div className={"flex gap-2 flex-wrap p-4"}>
            {courses.sort((a, b) => {
              if(a.name > b.name) return 1;
              if(a.name < b.name) return -1;
              return 0;
            }).map((course) => {
              return (
                <button
                  className={`border p-2 ${
                    search.get("id") === course._id &&
                    "bg-green-200 dark:bg-green-800"
                  }`}
                  key={course._id}
                  onClick={() => onFilterChange(course._id, search.get("filter") !== null ? search.get("filter") : "expired")}
                >
                  {course.name}
                </button>
              );
            })}
            {search.get("id") !== null && (
              <button
                className={`border bg-red-400 p-2`}
                onClick={() => onFilterChange(null, search.get("filter") !== null ? search.get("filter") : "expired")}
              >
                Clear
              </button>
            )}
        </div>
      </Box>
      <Box
        title="Report Results"
        button={!isResultLoading && reportResults.length ? {
          text: "CSV Export",
          colour: "text-sm text-white hover:text-gray-200",
          action: handleCsvExport,
        } : {}}
      >
        <div className={"flex space-x-1 border-b border-gray-700  bg-blue-900/20 p-1 pb-2"}>
          <button onClick={() => onFilterChange(search.get("id") !== null ? search.get("id") : null, "expired")} className={returnTabButtonStyle(search.get("filter") === "expired")}>Expired</button>
          <button onClick={() => onFilterChange(search.get("id") !== null ? search.get("id") : null, "threeMonths")} className={returnTabButtonStyle(search.get("filter") === "threeMonths")}>In 3 months</button>
          <button onClick={() => onFilterChange(search.get("id") !== null ? search.get("id") : null, "sixMonths")} className={returnTabButtonStyle(search.get("filter") === "sixMonths")}>In 6 months</button>
        </div>

        {isResultLoading ? <div className={"text-center p-2"}>
          Loading...
        </div> : <>
          {reportResults.length === 0 ? <div className={"text-center p-2"}>
            There are no results to display
          </div> : <>
            <table className="tableClass">
              <thead>
              <tr className="tableHeadRowClass">
                <th className="tableHeadCellClass">Callsign</th>
                <th className="tableHeadCellClass">Name</th>
                <th className="tableHeadCellClass">Course</th>
                <th className="tableHeadCellClass">Expires</th>
              </tr>
              </thead>
              <tbody className="tableBodyClass">{reportResults.map(result => {
                return (
                  <tr key={result._id} className={`tableBodyRowClass`}>
                    <td className="tableBodyCellClass">
                      {result.callsign}
                    </td>
                    <td className="tableBodyCellClass">
                      {result.firstName} {result.lastName}
                    </td>
                    <td className="tableBodyCellClass">
                      {result.course?.name}
                    </td>
                    <td className="tableBodyCellClass">
                      {DayJS(result.result?.expiresAt).format("DD-MM-YYYY")}
                    </td>
                  </tr>
                );
              })}</tbody>
            </table>
          </>}
        </>}


      </Box>
    </div>
  );
}
export default CoursesReport;