import React from "react";
import TeamRequirements from "./_components/TeamRequirements";
import RoleList from "./_components/RoleList";

const RecruitmentSettings = () => {
  return (
    <div className="p-4">
      <div className="grid grid-cols-2 gap-4">
        <RoleList />
        <TeamRequirements />
      </div>
    </div>
  );
};
export default RecruitmentSettings;
