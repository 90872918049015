import React, { useContext, useMemo } from "react";
import { Vector as VectorSource } from "ol/source";
import GeoJSONManager from "ol/format/GeoJSON";
import { get } from "ol/proj";
import { Projection } from "../context";
import { VectorLayer } from "../index.js";

const GeoJSON = (props) => {
  const projectionContext = useContext(Projection);
  const data = useMemo(() => {
    if (props.geoJSON?.type === "FeatureCollection") {
      return props.geoJSON;
    }
    return {
      type: "FeatureCollection",
      features: Array.isArray(props.geoJSON) ? props.geoJSON : [props.geoJSON],
    };
  }, [props.geoJSON]);

  const finalSource = useMemo(
    () =>
      new VectorSource({
        features: new GeoJSONManager().readFeatures(data, {
          featureProjection: get(projectionContext.projection),
        }),
      }),
    [projectionContext, data]
  );

  if (data.features.length === 0) {
    return null;
  }

  return <VectorLayer {...props} source={finalSource} />;
};
export default GeoJSON;