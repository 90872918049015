import React from "react";
import { Routes, Route, Outlet, Link } from "react-router-dom";
import FocusReport from "./focus";
import Dashboard from "./dashboard";
import {AuthComponents} from "../../../lib/_sso";

export default function TrainingReportsPages() {
  const scope = "training:competencies:read";

  return (
    <>
      <AuthComponents.Can scope={scope}>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Dashboard />} />
            <Route path={"/focus/"} element={<FocusReport />} />
            {/* <Route path={"/courses/*"} element={<Courses />} /> */}
            <Route path="*" element={<NoMatch />} />
          </Route>
        </Routes>
      </AuthComponents.Can>
      <AuthComponents.CanNot scope={scope}>
        <div className={"p-2 text-red-500"}>Access Denied: You do not have permission to access this page.</div>
      </AuthComponents.CanNot>
    </>
  );
}
function Layout() {
  return (
    <div className="min-h-full">
      <main>
        <Outlet />
      </main>
    </div>
  );
}
function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}
