import {Fragment} from "react";

const NewLineBreaker = ({ value = ""}) => {

  return (
    <>
      {value.split("\n").map((a, b) => {
        return <Fragment key={b}>{b !== 0 && <br />}{a}</Fragment>;
      })}
    </>
  );
}
export default NewLineBreaker;