import Box from "../common/Box";
import React, {useEffect, useState} from "react";
import DayJS from "dayjs";
import API from "../../api";
import Toast from "react-hot-toast";
import DataRow from "../common/DataRow";
import DatePicker from "react-datepicker";
import Input from "../common/Input";
import Model from "../common/Model";
import TextArea from "../common/TextArea";


const Requirement = ({ requirement, onUpdate, onReload }) => {
  const [openForm, setOpenForm] = useState(false);
  const [appliedFor, setAppliedFor] = useState(null);
  const [completedAt, setCompletedAt] = useState(null);
  const [paidAt, setPaidAt] = useState(null);
  const [methodOfPayment, setMethodOfPayment] = useState(null);
  const [notes, setNotes] = useState(null);
  const [expiresAt, setExpiresAt] = useState(null);
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    if (requirement.result !== null) {
      setAppliedFor(requirement.result.appliedFor);
      setCompletedAt(requirement.result.completedAt);
      setPaidAt(requirement.result.paidAt);
      setMethodOfPayment(requirement.result.methodOfPayment);
      setNotes(requirement.result.notes);
      setExpiresAt(requirement.result.expiresAt);
      if (DayJS(requirement.result.expiresAt).isAfter(DayJS())) {
        setIsValid(true);
      }
    }
  }, [requirement]);

  const handleSubmit = () => {
    onUpdate(requirement._id, {
      appliedFor,
      completedAt,
      paidAt,
      methodOfPayment,
      notes,
      expiresAt,
    }).then(() => {
      onReload();
      setOpenForm(false);
      Toast.success("Successfully updated requirement");
    })
    .catch((err) => {
      Toast.error("Error updating requirement:\n\r\n\r  " + (err?.response?.data?.message || err.message));
    });
  };
  const handleAutoSetExpiry = (fromDate) => {
    if (requirement.validFor !== 0 && parseInt(requirement.validFor) >= 1) {
      setExpiresAt(DayJS(fromDate).add(requirement.validFor, "years").toDate());
    }
  };

  const renderMembership = () => {
    return (
      <>
        {/* <div className={""}> */}
        <DataRow
          small
          label="Paid At"
          value={
            requirement.result !== null && requirement.result.paidAt !== null
              ? DayJS(requirement.result.paidAt).format("DD-MM-YYYY")
              : "--"
          }
        />
        <DataRow
          small
          label="Payment Method"
          value={
            requirement.result !== null &&
            requirement.result.methodOfPayment !== ""
              ? requirement.result.methodOfPayment
              : "--"
          }
        />
        <DataRow
          small
          label="Expires"
          value={
            requirement.result !== null && requirement.result.expiresAt !== null
              ? DayJS(requirement.result.expiresAt).format("DD-MM-YYYY")
              : "--"
          }
        />
        {/* </div> */}
      </>
    );
  };
  const renderVetting = () => {
    return (
      <>
        <DataRow
          small
          label="Applied For"
          value={
            requirement.result !== null &&
            requirement.result.appliedFor !== null
              ? DayJS(requirement.result.appliedFor).format("DD-MM-YYYY")
              : "--"
          }
        />
        <DataRow
          small
          label="Expires"
          value={
            requirement.result !== null && requirement.result.expiresAt !== null
              ? DayJS(requirement.result.expiresAt).format("DD-MM-YYYY")
              : "--"
          }
        />
        <DataRow
          small
          label="Notes"
          value={
            requirement.result !== null && requirement.result.notes !== null ? requirement.result.notes : "--"
          }
        />
      </>
    );
  };
  const renderCourse = () => {
    return (
      <>
        <DataRow
          small
          label="Completed"
          value={
            requirement.result !== null &&
            requirement.result.completedAt !== null
              ? DayJS(requirement.result.completedAt).format("DD-MM-YYYY")
              : "--"
          }
        />
        <DataRow
          small
          label="Expires"
          value={
            requirement.result !== null && requirement.result.expiresAt !== null
              ? DayJS(requirement.result.expiresAt).format("DD-MM-YYYY")
              : "--"
          }
        />
      </>
    );
  };

  const renderForm = () => {
    if (openForm === false) return null;

    const renderFields = () => {
      switch (requirement.type) {
        case "membership":
          return (
            <>
              <div className="overflow-show w-full">
                <label htmlFor={"date"} className={`labelClass`}>
                  Paid At
                </label>
                <DatePicker
                  onChange={(date) => {
                    setPaidAt(date);
                    handleAutoSetExpiry(date);
                  }}
                  selected={paidAt ? new Date(paidAt) : ""}
                  dateFormat="dd-MM-yyyy"
                  nextMonthButtonLabel=">"
                  previousMonthButtonLabel="<"
                  className="inputClass"
                  data-disable-touch-keyboard
                />
              </div>
              <div className="w-full">
                <Input
                  label="Payment Method"
                  value={methodOfPayment}
                  onChange={(e) => setMethodOfPayment(e.target.value)}
                />
              </div>
            </>
          );
        case "vetting":
          return (
            <>
              <div className="overflow-show w-full">
                <label htmlFor={"date"} className={`labelClass`}>
                  Applied For
                </label>
                <DatePicker
                  onChange={(date) => {
                    setAppliedFor(date);
                    handleAutoSetExpiry(date);
                  }}
                  selected={appliedFor ? new Date(appliedFor) : ""}
                  dateFormat="dd-MM-yyyy"
                  nextMonthButtonLabel=">"
                  previousMonthButtonLabel="<"
                  className="inputClass"
                  data-disable-touch-keyboard
                />
              </div>
              <div className="w-full">
                <TextArea label={"Notes"} value={notes} field={"notes"} onChange={e => setNotes(e.target.value)}/>
              </div>
            </>
          );
        case "course":
          return (
            <>
              <div className="overflow-show w-full">
                <label htmlFor={"date"} className={`labelClass`}>
                  Completed At
                </label>
                <DatePicker
                  onChange={(date) => {
                    setCompletedAt(date);
                    handleAutoSetExpiry(date);
                  }}
                  selected={completedAt ? new Date(completedAt) : ""}
                  dateFormat="dd-MM-yyyy"
                  nextMonthButtonLabel=">"
                  previousMonthButtonLabel="<"
                  className="inputClass"
                  data-disable-touch-keyboard
                />
              </div>
            </>
          );
      }
    };

    return (
      <Model
        open={true}
        title={"Update Requirement: " + requirement.title}
        disableOutsideClick
        onClose={() => {
          setOpenForm(false);
        }}
      >
        {renderFields()}

        <div className="overflow-show w-full">
          <label htmlFor={"date"} className={`labelClass`}>
            Expiry Date
          </label>
          <DatePicker
            onChange={(date) => setExpiresAt(date)}
            selected={expiresAt ? new Date(expiresAt) : ""}
            dateFormat="dd-MM-yyyy"
            nextMonthButtonLabel=">"
            previousMonthButtonLabel="<"
            className="inputClass"
            data-disable-touch-keyboard
          />
        </div>

        <div className="mt-4 flex justify-between border-t border-gray-700 pt-4">
          <button
            className="rounded-lg bg-red-600 px-4 py-2 text-white"
            onClick={() => setOpenForm(false)}
          >
            Cancel
          </button>
          <button
            className="rounded-lg bg-blue-600 px-4 py-2 text-white"
            onClick={handleSubmit}
          >
            Save
          </button>
        </div>
      </Model>
    );
  };

  return (
    <div
      className={`${!requirement._isSelf && "cursor-pointer"} border border-zinc-300  shadow  hover:border-zinc-400 dark:border-zinc-700  ${
        isValid ? "!border-green-500 " : "!border-red-700"
      }`}
    >
      <div className="">
        {renderForm()}

        <div onClick={() => {
          if(requirement._isSelf === false) {
            setOpenForm(true);
          }
        }}>
          <div
            className={`mb-2 border-b-2 border-zinc-300 bg-zinc-200 p-1 text-center text-lg dark:border-zinc-700 dark:bg-zinc-800 ${
              isValid
                ? "!border-green-500 !bg-green-800 !bg-opacity-50"
                : "!border-red-700"
            }`}
          >
            {requirement.title}
          </div>
          {requirement.type === "membership" && renderMembership()}
          {requirement.type === "vetting" && renderVetting()}
          {requirement.type === "course" && renderCourse()}
        </div>
      </div>
    </div>
  );
};

const RequirementsGrid = ({requirements, onUpdate, onReload}) => {

  return (
    <Box title="Requirements">
      <div className={"grid gap-2 p-2 md:grid-cols-2 xl:grid-cols-3"}>
        {requirements.map((requirement, i) => {
          return (
            <Requirement
              key={i}
              onUpdate={onUpdate}
              requirement={requirement}
              onReload={onReload}
            />
          );
        })}
      </div>
    </Box>
  );
}
export default RequirementsGrid;