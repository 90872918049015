import React, {useState, useEffect, useContext, useCallback} from "react";
import { useNavigate } from "react-router-dom";
import api from "../../../api";
import AssetContext from "./_context";
import AssetDetailsForm from "./_components/assetDetailsForm";
import ConfirmBox from "../../../components/common/Confirm";
import {toast} from "react-hot-toast";

export default function EditAsset() {
  const navigate = useNavigate();
  const assetOriginal = useContext(AssetContext);
  const [asset, setAsset] = useState({});
  const [showDelete, setShowDelete] = useState(false);

  useEffect(() => {
    api.assets.show(assetOriginal._id).then(res => {
      setAsset({
        ...assetOriginal,
        ...res,
      });
    });
  }, [assetOriginal]);

  const handleEditAsset = useCallback(data => {
    return api.assets.update(asset._id, data).then(() => {
      toast.success("Successfully updated asset");
      navigate(`/asset/view/${asset._id}`);
    });
  }, [asset, navigate]);
  const handleFormCancel = useCallback(() => {
    navigate(`/asset/view/${asset._id}`);
  }, [asset, navigate]);
  const handleDeleteAsset = useCallback(() => {
    api.assets.remove(asset._id).then(() => {
      toast.success("Successfully deleted asset");
      navigate(`/asset/list`);
    });
  }, [asset, navigate]);

  return (
    <div className={"pl-2 pr-4 pt-1"}>
      {showDelete && <ConfirmBox show title={"Delete Asset"} action={() => handleDeleteAsset()} onClose={() => setShowDelete(false)} />}
      <AssetDetailsForm isEdit asset={asset} onChange={handleEditAsset} onCancel={handleFormCancel} onDelete={() => setShowDelete(true)} />
    </div>
  );
}
