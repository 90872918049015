import React, {useState, useEffect, useMemo} from "react";
import {
  CheckIcon,
  XMarkIcon,
  ArrowsUpDownIcon,
} from "@heroicons/react/20/solid";
import DayJS from "dayjs";

export default function MemberList({users = [], member, sortList = null, archive}) {
  const [sortBy, setSortBy] = React.useState("surname"); // surname, firstname, callsign

  const handleSortToggle = () => {
    switch (sortBy) {
      case "surname":
        setSortBy("firstname");
        break;
      case "firstname":
        setSortBy("callsign");
        break;
      case "callsign":
        setSortBy("none");
        break;
      default:
        setSortBy("surname");
        break;
    }
  }

  const sortedList = useMemo(() => {
    if (users === undefined || users.length === 0) return [];

    return users.toSorted((a, b) => {
      if (sortBy === "surname") {
        if ((a.lastName || "") < (b.lastName || "")) return -1;
        if ((a.lastName || "") > (b.lastName || "")) return 1;
      }
      if (sortBy === "firstname") {
        if ((a.firstName || "") < (b.firstName || "")) return -1;
        if ((a.firstName || "") > (b.firstName || "")) return 1;
      }
      if (sortBy === "callsign") {
        if ((a.callsign || "zzzzzzzzzzzzzzzz") < (b.callsign || "zzzzzzzzzzzzzzzz")) return -1;
        if ((a.callsign || "zzzzzzzzzzzzzzzz") > (b.callsign || "zzzzzzzzzzzzzzzz")) return 1;
      }

      return 0;
    })
  }, [users, sortBy]);

  return (
    <table className="lg:text-md min-w-full table-auto border-separate divide-y divide-gray-200 text-sm">
      <thead>
      <tr className="bg-gray-300 text-xs uppercase tracking-wider text-gray-900 dark:bg-zinc-700 dark:text-white">
        <th
          className="flex gap-1 px-4 py-3 text-left"
          onClick={() => handleSortToggle()}
        >
          Name
          {sortBy !== "none" ? (
            <><ArrowsUpDownIcon className="h-4 text-green-500"/> <span className={"text-xs lowercase"}>[{sortBy}]</span></>
          ) : (
            <ArrowsUpDownIcon className="h-4"/>
          )}
        </th>
        <th className="px-4 py-3 text-left">Contact Info</th>
        <th className="hidden px-4 py-3 text-left xl:table-cell">Town</th>
        <th className="hidden px-4 py-3 text-left lg:table-cell">Roles</th>
        <th className=" hidden gap-1 px-4 py-3 text-center xl:flex" onClick={() => handleSortToggle()}>
          {archive ? "Marked for Deletion" : "On-call"}
        </th>
      </tr>
      </thead>
      <tbody className="divide-y divide-gray-200 text-left">
      {sortedList.length !== 0 ? (
        sortedList.map((u) => {
          return (
            <tr
              key={u._id}
              onClick={() => member(u._id)}
              className={`${u.onCall && "oncall"} ${!u.onCall && "offcall"} ${
                !u.operational && "nonOp"
              } cursor-pointer hover:bg-gray-300 dark:hover:bg-zinc-500`}
            >
              <td className="text-md p-2">
                  <span className="text-lg">
                    {u.firstName} {u.lastName}
                  </span>
                <br/>
                <span className="text-zinc-400 dark:text-zinc-300">
                    {u.callsign && "(" + u.callsign + ")"}
                  </span>
              </td>
              <td className="p-2">
                <span className={"text-xl"}>{u.phone ? u.phone : "--"}</span>
                <br/>
                {u.email}
              </td>
              <td className="hidden p-2 xl:table-cell">{u.Town}</td>
              <td className="hidden p-2 lg:table-cell">
                <div className="flex flex-wrap gap-1 text-sm">
                  {u.Roles.sort((a, b) => {
                    if (a.role?.name > b.role?.name) return 1;
                    if (a.role?.name < b.role?.name) return -1;
                    return 0;
                  }).map((r) => {
                    return (
                      <div
                        key={r._id}
                        className={`rounded px-2 py-1 ${
                          r.isActive ? "oncall" : "nonOp"
                        }`}
                      >
                        <div>{r.role?.name}</div>
                      </div>
                    );
                  })}
                </div>
              </td>
              <td
                className={`hidden ${
                  archive ? "w-44" : "w-32"
                } p-2 text-center xl:table-cell`}
              >
                {archive ? (
                  u.keepAccount === false ? (
                    <>
                      <CheckIcon className="mx-auto h-8 text-green-600 dark:text-green-400"/>
                      {DayJS(u.deleteMemberAt).format("DD-MM-YYYY HH:mm")}
                    </>
                  ) : (
                    <XMarkIcon className="mx-auto h-8 text-red-700"/>
                  )
                ) : u.operational === true ? (
                  u.onCall ? (
                    <CheckIcon className="mx-auto h-8 text-green-600 dark:text-green-400"/>
                  ) : (
                    <XMarkIcon className="mx-auto h-8 text-red-700"/>
                  )
                ) : (
                  "Non-Op"
                )}
              </td>
            </tr>
          );
        })
      ) : (
        <tr>
          <td colSpan={3}>No members</td>
        </tr>
      )}
      </tbody>
    </table>
  );
}
