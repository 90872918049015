import React, { useState, useEffect } from "react";
import Box from "../../../components/common/Box";
import Button from "../../../components/common/Button";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon, TrashIcon, XMarkIcon } from "@heroicons/react/20/solid";
import ToggleSwitch from "../../../components/common/ToggleSwitch";
import { PencilIcon } from "@heroicons/react/24/outline";
import Input from "../../../components/common/Input";
import api from "../../../api";
import Select from "../../../components/common/Select";
import ChainedContext from "../../../contexts/chainedContext";
import SettingsReceiver from "../../../contexts/settings_context/Settings_Receiver";
import Toast, {toast} from "react-hot-toast";
import API from "../../../api";

const roleRequirements = [
  { key: 1, text: "None", value: "0:0:0" },
  { key: 2, text: "Course", value: "0:0:1" },
  { key: 3, text: "Competency", value: "0:1:0" },
  { key: 4, text: "Competency + Course", value: "0:1:1" },
  { key: 5, text: "CPD", value: "1:0:0" },
  { key: 6, text: "CPD + Course", value: "1:0:1" },
  { key: 7, text: "CPD + Competency", value: "1:1:0" },
  { key: 8, text: "CPD + Comp + Course", value: "1:1:1" },
];

const RoleList = ({ settings }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [roles, setRoles] = useState([]);
  const [name, setName] = useState("");
  const [del, setDelete] = useState("");
  const [delName, setDeleteName] = useState("");
  const [requiresCPD, setRequiresCPD] = useState(false);
  const [requiresCompetency, setRequiresCompetency] = useState(false);
  const [requiresCourse, setRequiresCourse] = useState(false);
  const [editID, setEditID] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [instructorOnly, setInstructorOnly] = useState(false);
  const [ignoreInactiveRoles, setIgnoreInactiveRoles] = useState(false);

  useEffect(() => {
    getRoles();
  }, []);
  useEffect(() => {
    if(!requiresCPD) setInstructorOnly(false);
  }, [requiresCPD]);
  useEffect(() => {
    setIgnoreInactiveRoles(settings.ignoreInactiveRoles);
  }, [settings]);

  const getRoles = () => {
    api.settings.getRoles().then((res) => {
      setRoles(res);
    });
  };
  const handleEdit = (item) => {
    setName(item.name);
    setRequiresCPD(item.requiresCPD);
    setRequiresCompetency(item.requiresCompetency);
    setRequiresCourse(item.requiresCourse);
    setEditID(item._id);
    setInstructorOnly(item.instructorOnly);
    setIsEdit(true);
    settings._refresh();
  };
  const handleAdd = () => {
    const data = {
      name,
      requiresCompetency,
      requiresCPD,
      requiresCourse,
      instructorOnly,
    };

    const apiHandler = isEdit ? api.settings.updateRole(editID, data) : api.settings.addRole(data);

    apiHandler.then(() => {
      getRoles();
      setName("");
      setRequiresCPD(false);
      setRequiresCompetency(false);
      setRequiresCourse(false);
      setIsEdit(false);
      setInstructorOnly(false);
      setEditID(null);
      settings._refresh();

      Toast.success(`Successfully ${isEdit ? "updated" : "created"} role`)
    }).catch(err => {
      Toast.error(err.response.data.message);
    });
  };

  const handleDelete = () => {
    if (del !== "") {
      api.settings.deleteRole(del).then(() => {
        settings._refresh();
        getRoles();
        setDeleteName("");
        setDelete("");
        setIsOpen(false);
        Toast.success(`Successfully deleted role`)
      }).catch(err => {
        Toast.error(err.response.data.message);
      });
    }
  };
  const CloseDialog = () => {
    return (
      <Transition
        show={isOpen}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Dialog
          open={isOpen}
          onClose={() => setIsOpen(false)}
          className="fixed inset-0 z-10 overflow-y-auto "
        >
          <div className="min-h-screen text-center">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-80" />
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className="my-8 inline-block w-full max-w-2xl transform overflow-hidden rounded-2xl border-4 border-gray-700 bg-gray-50 p-6 text-left align-middle shadow-xl transition-all dark:bg-zinc-700">
              <div className="pt-4">
                <Dialog.Title>
                  Delete Role{" "}<span className="font-bold italic">{delName}</span>?
                </Dialog.Title>
                <Dialog.Description>This will also unlink the role from any members and recruits.</Dialog.Description>
              </div>

              <div className="mt-4 flex justify-between border-t border-gray-700 pt-4">
                <button
                  className="rounded-lg bg-primary px-4 py-2 text-white"
                  onClick={() => setIsOpen(false)}
                >
                  Cancel
                </button>
                <button
                  className="rounded-lg bg-red-500 px-4 py-2 text-white disabled:bg-red-500"
                  onClick={handleDelete}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  };

  const submitSettingsData = (data) => {
    const isLoading = toast.loading("Updating settings...");
    API.settings.updateSystem(data).then(() => {
      toast.success("Settings updated");
      settings._refresh();
    }).catch(err => {
      toast.error("An error occurred while updating settings, Error: " + (err.response?.data?.message || err.message))
    }).finally(() => {
      toast.dismiss(isLoading);
    });
  };

  return (
    <Box title={"Team Roles"}>
      <div className="w-full">
        <div className={"border-b-2 border-gray-500 p-3"}>
          <label className="labelClass">Temporarily push all team member roles to SarStuff Central & SarSYS regardless if they have met all the requirements for CPD / Courses / Competency.</label>
          <ToggleSwitch
            isChecked={ignoreInactiveRoles}
            label1={"No"}
            label2={"Yes"}
            onChange={() => {
              submitSettingsData({ ignoreInactiveRoles: !ignoreInactiveRoles });
            }}
          />
        </div>
        <div className="flex w-full flex-wrap items-end gap-2 p-2 align-middle">
          <div className="flex-1">
            <label className="labelClass">Role Name</label>
            <Input
              value={name}
              type={"text"}
              field={"roles"}
              onChange={(e) => setName(e.target.value)}
              placeholder="role..."
            />
          </div>
          <div className="flex-1">
            <label className="labelClass">Requires</label>
            <div className="">
              <Select
                options={roleRequirements}
                dontSort
                value={
                  (requiresCPD ? "1" : "0") +
                  ":" +
                  (requiresCompetency ? "1" : "0") +
                  ":" +
                  (requiresCourse ? "1" : "0")
                }
                onChange={(e) => {
                  const val = e.target.value.split(":");
                  setRequiresCPD(val[0] === "1");
                  setRequiresCompetency(val[1] === "1");
                  setRequiresCourse(val[2] === "1");
                }}
              />
            </div>
          </div>
          {requiresCPD && <div className="">
            <label className="labelClass">Instructor led CPD only</label>
            <ToggleSwitch
              isChecked={instructorOnly}
              label1={"No"}
              label2={"Yes"}
              onChange={() => {
                // setUpdate(true);
                setInstructorOnly(!instructorOnly);
              }}
            />
          </div>}
          <div className="">
            <Button
              disabled={name === ""}
              onClick={handleAdd}
              colour={"bg-green-700 text-white"}
              text={isEdit ? "Update" : "Add"}
            />
          </div>
        </div>
        {CloseDialog()}
        <div className="w-full pt-4">
          <table className="tableClass">
            <thead className="tableHeadClass">
              <tr className="tableHeadRowClass">
                <th className="tableHeadCellClass">Name</th>
                <th className="tableHeadCellClass text-center">Requires Competency</th>
                <th className="tableHeadCellClass text-center">Requires Course</th>
                <th className="tableHeadCellClass text-center">Requires CPD</th>
                <th className="tableHeadCellClass text-center">Instructor Led CPD</th>
                <th className="tableHeadCellClass"></th>
              </tr>
            </thead>
            <tbody className="tableBodyClass">
              {roles &&
                roles.sort((a, b) => {
                  if(a.name > b.name) return 1;
                  if(a.name < b.name) return -1;
                  return 0;
                }).map((d, i) => {
                  return (
                    <tr className="tableBodyRowClass " key={i}>
                      <td className="tableBodyCellClass">{d.name}</td>
                      <td className="tableBodyCellClass w-20 text-center   ">
                        {d.requiresCompetency ? (
                          <CheckIcon className="mx-auto h-6"/>
                        ) : (
                          <XMarkIcon className="mx-auto h-6"/>
                        )}
                      </td>
                      <td className="tableBodyCellClass w-20 text-center   ">
                        {d.requiresCourse ? (
                          <CheckIcon className="mx-auto h-6"/>
                        ) : (
                          <XMarkIcon className="mx-auto h-6"/>
                        )}
                      </td>
                      <td className="tableBodyCellClass w-20 text-center  ">
                        {d.requiresCPD ? (
                          <CheckIcon className="mx-auto h-6"/>
                        ) : (
                          <XMarkIcon className="mx-auto h-6"/>
                        )}
                      </td>
                      <td className="tableBodyCellClass w-20 text-center   ">
                        {d.instructorOnly ? (
                          <CheckIcon className="mx-auto h-6"/>
                        ) : (
                          <XMarkIcon className="mx-auto h-6"/>
                        )}
                      </td>
                      <td className="tableBodyCellClass w-32 space-x-1 text-center">
                        <button
                          // disabled={d.isAvailableDefault || d.isAssignedDefault}
                          onClick={() => handleEdit(d)}
                          className="rounded-md border bg-primary bg-opacity-80  px-2 py-1 text-sm  text-white hover:bg-gray-800 disabled:opacity-20"
                        >
                          <PencilIcon className="h-6 w-6 text-yellow-200 "/>
                        </button>
                        <button
                          className="rounded-md border border-red-400 bg-red-700 bg-opacity-80 px-2 py-1 text-sm text-white hover:border-red-300 hover:bg-red-800 disabled:opacity-20"
                          onClick={() => {
                            setDelete(d._id);
                            setDeleteName(d.name);
                            setIsOpen(true);
                          }}
                        >
                          <TrashIcon className="h-6 w-6 text-red-200"/>
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </Box>
  );
};
export default ChainedContext(RoleList, [
  [SettingsReceiver, "settings"]
]);