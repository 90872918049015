import React, { useContext, useEffect } from "react";
import { ScaleLine } from "ol/control";
import { Map } from "../context";

const ScaleControl = () => {
  const mapContext = useContext(Map);

  useEffect(() => {
    if (!mapContext) return;

    let scaleControl = new ScaleLine({ bar: true, text: false, minWidth: 125 });
    mapContext.controls.push(scaleControl);

    return () => mapContext.controls.remove(scaleControl);
  }, [mapContext]);

  return null;
};
export default ScaleControl;
