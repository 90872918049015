import React, {useState, Suspense, lazy, useEffect} from "react";
import api from "../../../../api";
import Button from "../../../../components/common/Button";

const AssignMembers = lazy(() => import("./assignMembers"));
const AssignLocations = lazy(() => import("./assignLocations"));
const AssignVehicles = lazy(() => import("./assignVehicles"));

export default function AssignAsset({ asset, action }) {
  const [type, setType] = useState("Member");
  const [updated, setUpdated] = useState(false);
  const [clear, setClear] = useState(false);
  const [assigned, setAssigned] = useState({
    type: "",
    name: "",
    id: "",
  });

  useEffect(() => {
    if(asset !== undefined){
      if(asset.AssignedTo !== undefined){
        setAssigned(asset.AssignedTo);
      }
    }
  }, [asset]);

  const AssignData = (data) => {
    setAssigned({
      type: data.type,
      name: data.name,
      id: data.id,
    });
  };
  const AssignAsset = () => {
    setClear(false);
    let data = {
      [assigned.type]: assigned.id,
    };
    api.assets.assign(asset._id, data).then((res) => {
      action();
      setAssigned({
        type: "",
        name: "",
        id: "",
      });
      setClear(true);
      setUpdated(true);
      setTimeout(() => {
        setUpdated(false);
      }, 2000);
    });
  };

  return (
    <div>
      <div className=" w-full">
        <div className="flex items-center justify-center gap-4 border-b border-gray-700 p-4">
          {updated ? (
            <div className="text-center text-2xl text-green-600">Updated!</div>
          ) : (
            <>
              <div className="text-xl text-gray-400">
                Assign asset{" "}
                <span className="text-2xl text-zinc-700 dark:text-white ">
                  {asset.name ? asset.assetNum : asset.name}
                </span>{" "}
                to:{" "}
                <span className="text-2xl text-zinc-700 dark:text-white  ">
                  {assigned.type ? assigned.type : "--"}
                </span>{" "}
                name:{" "}
                <span className="text-2xl text-zinc-700 dark:text-white ">
                  {assigned.name ? assigned.name : "--"}
                </span>
              </div>
              <div>
                <Button
                  style={"text-lg"}
                  text="Update Assignment"
                  colour="bg-green-700 text-white"
                  onClick={AssignAsset}
                />
              </div>
            </>
          )}
        </div>
        <div className="flex justify-around px-2 pt-2 ">
          <Button
            style={"text-xl"}
            text="Member"
            positive={type === "Member"}
            onClick={() => setType("Member")}
          />
          <Button
            style={"text-xl"}
            text="Vehicle"
            positive={type === "Vehicle"}
            onClick={() => setType("Vehicle")}
          />
          <Button
            style={"text-xl"}
            text="Location"
            positive={type === "Location"}
            onClick={() => setType("Location")}
          />
        </div>
        <div>
          {type === "Member" && (
            <Suspense fallback={<div>Loading...</div>}>
              <AssignMembers
                data={(data) => AssignData(data)}
                clearData={clear}
              />
            </Suspense>
          )}
          {type === "Location" && (
            <Suspense fallback={<div>Loading...</div>}>
              <AssignLocations
                data={(data) => AssignData(data)}
                clearData={clear}
              />
            </Suspense>
          )}
          {type === "Vehicle" && (
            <Suspense fallback={<div>Loading...</div>}>
              <AssignVehicles
                data={(data) => AssignData(data)}
                clearData={clear}
              />
            </Suspense>
          )}
        </div>
      </div>
    </div>
  );
}
