import React from "react";
import {Link, Outlet, Route, Routes} from "react-router-dom";
import ReportsNav from "./_components/NavBar.jsx";
import Dashboard from "./dashboard.jsx";
import Requirements from "./requirements.jsx";
import Courses from "./courses.jsx";
import MemberActivity from "./memberActivity.jsx";
import MemberDeployments from "./memberDeployments.jsx";
import LowlandReport from "./lowlandReport.jsx";
import {AuthComponents} from "../../lib/_sso";

const ReportsIndex = () => {
 return (
   <Routes>
     <Route path="/" element={<Layout />}>
       <Route index element={<Dashboard />} />
       <Route path={"/requirements"} element={<Requirements />} />
       <Route path={"/courses"} element={<Courses />} />
       <Route path={"/member_activity"} element={<MemberActivity />} />
       <Route path={"/member_deployments"} element={<MemberDeployments />} />
       <Route path={"/lowland_report"} element={<LowlandReport />} />
       <Route path="*" element={<NoMatch />} />
     </Route>
   </Routes>
 );
}
export default ReportsIndex;

function Layout() {
  const scope = "teamsite:reports";

  return (
    <div className="min-h-full">
      <ReportsNav />
      <main>
        <AuthComponents.Can scope={scope}>
          <Outlet />
        </AuthComponents.Can>
        <AuthComponents.CanNot scope={scope}>
          <div className={"p-2 text-red-500"}>Access Denied: You do not have permission to access this page.</div>
        </AuthComponents.CanNot>
      </main>
    </div>
  );
}
function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}