import React, {useState, useCallback, useMemo, useEffect} from "react";
import {Outlet, Route, Routes, useParams} from "react-router-dom";
import AssetContext from "./_context";
import API from "../../../api";
import AssetView from "./assetView";
import AssetEdit from "./assetEdit";
import ServicePages from "./service";

export default function AssetPages() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<AssetView />} />
        <Route path="/edit" element={<AssetEdit />} />
        <Route path="/service/*" element={<ServicePages />} />
      </Route>
    </Routes>
  );
}
function Layout() {
  const [error, setError] = useState(null);
  const [initialLoading, setLoading] = useState(true);
  const [asset, setAsset] = useState({});
  const { asset_id } = useParams();

  const refreshData = useCallback(() => {
    API.assets.show(asset_id).then(res => {
      setError(null);
      setAsset(res);
    }).catch(err => {
      if(err.response.data.message !== undefined){
        setError(err.response.data.message);
        return;
      }
      setError(err.message)
    }).finally(() => {
      setLoading(false)
    })
  }, [asset_id])
  const contextVal = useMemo(() => {
    return {
      _refresh: refreshData,
      ...asset
    }
  }, [asset, refreshData]);

  useEffect(() => {
    refreshData();
  }, [refreshData]);

  if(initialLoading) return (<div className={"p-2"}>Loading Asset...</div>);
  if(error) return  (<div className={"p-2 text-red-500"}>Error: {error}</div>);

  return (
    <AssetContext.Provider value={contextVal}>
      <Outlet />
    </AssetContext.Provider>
  );
}