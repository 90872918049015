import React, {
  useState,
  useEffect,
  Suspense,
  useContext,
  useCallback,
} from "react";
import dayjs from "dayjs";
import { Tab, Transition, Dialog } from "@headlessui/react";
import {
  PencilIcon,
  PlusCircleIcon,
  PrinterIcon,
} from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import { toast } from "react-hot-toast";
import api from "../../../api";
import Box from "../../../components/common/Box";
import Layout from "../../../components/common/Layout";
import { AuthComponents, Authentication } from "../../../lib/_sso";
import AssetContents from "./_components/assetContents";
import AssetServices from "./_components/assetServices";
import AssignAsset from "./_components/assignAsset";
import AssetContext from "./_context";
import DataRow from "../../../components/common/DataRow";
import Button from "../../../components/common/Button";
import Modal from "../../../components/common/Modal";
import Input from "../../../components/common/Input";
import ToggleSwitch from "../../../components/common/ToggleSwitch";
import Blank from "../../../assets/images/assets_blank.png";
import DisplayAndUpdatePhoto from "../../team/member/_components/DisplayAndUpdatePhoto";
import API from "../../../api";

const returnTabButtonStyle = (selected) => {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  return classNames(
    "w-full py-2.5 text-xl  font-medium  ]",
    selected
      ? "dark:text-zinc-100 bg-green-200 hover:bg-green-300 text-zinc-700 dark:bg-green-900 dark:bg-opacity-50"
      : "bg-zinc-200 hover:bg-zinc-300 text-zinc-700 dark:hover:bg-zinc-900 dark:hover:text-white dark:bg-zinc-800 dark:text-zinc-200"
  );
};

export default function AssetPage() {
  const canUpdate = true;
  const navigate = useNavigate();
  const asset = useContext(AssetContext);

  const [scheduled, setScheduled] = useState(null);
  const [check, setCheck] = useState(false);
  const [copy, setCopy] = useState(false);
  const [viewCheck, setViewCheck] = useState(false);
  const [checkID, setCheckID] = useState("");
  const [theCheck, setTheCheck] = useState({
    date_checked: "",
    date_scheduled: "",
    status: "",
    note: "",
  });
  const [assetChecks, setAssetChecks] = useState([]);
  const [assetCopy, setAssetCopy] = useState({
    assetNum: "",
    name: "",
    serialNum: "",
    copyContents: "",
    copyAssigned: "",
  });

  const getAssetChecks = useCallback(() => {
    api.assets.checks.all(asset._id).then((res) => {
      setAssetChecks(res);
    });
  }, [asset]);

  const handleGetUploadSpec = useCallback(() => {
    return API.assets.photo.getUploadConfig(asset._id);
  }, [asset]);
  const handleGettingPhoto = useCallback(() => {
    return API.assets.photo.getAccessLink(asset._id);
  }, [asset]);
  const handlePhotoUpload = useCallback(
    (formData) =>
      API.assets.photo.uploadPhoto(asset._id, formData).then((a) => {
        asset._refresh();
        return a;
      }),
    [asset]
  );

  useEffect(() => {
    asset._refresh();
  }, []);
  useEffect(() => {
    getAssetChecks();
  }, [getAssetChecks]);
  useEffect(() => {
    if (copy) {
      setAssetCopy({
        ...assetCopy,
        name: asset.name,
      });
    }
    return () => {
      setAssetCopy({
        assetNum: "",
        name: "",
        serialNum: "",
        copyContents: "",
        copyAssigned: "",
      });
    };
  }, [copy]);
  useEffect(() => {
    if (scheduled === false) {
      setTheCheck({
        ...theCheck,
        date_checked: new Date(),
      });
    }
    if (scheduled === true) {
      setTheCheck({
        ...theCheck,
        date_checked: "",
      });
    }
  }, [scheduled]);

  const onTheCheckChange = (e) => {
    setTheCheck({ ...theCheck, [e.target.name]: e.target.value });
  };
  const handleAddCheck = () => {
    api.assets.checks.add(asset._id, theCheck).then(() => {
      asset._refresh();
      setCheck(false);
      getAssetChecks();
      setTheCheck({
        date_checked: "",
        date_scheduled: "",
        status: "",
        note: "",
      });
    });
  };
  const handleUpdateCheck = () => {
    api.assets.checks.update(asset._id, checkID, theCheck).then(() => {
      setViewCheck(false);
      getAssetChecks();
      setTheCheck({
        date_checked: "",
        date_scheduled: "",
        status: "",
        note: "",
      });
    });
  };
  const AddCheckDialog = () => {
    return (
      <Modal
        show={check}
        onClose={() => setCheck(false)}
        title={` Add Asset Check for ${asset.name}`}
        children={
          <div>
            {" "}
            <div className="mt-4 w-full text-center text-2xl">
              Is this a future scheduled check?
            </div>
            <div className="mt-4 flex justify-around border-t border-gray-700 pt-4">
              <button
                className={`rounded-lg bg-blue-600 px-4 py-4 text-xl ${
                  scheduled === false && "bg-opacity-20"
                } ${
                  scheduled === true &&
                  "ring-offset-bak ring ring-yellow-700 ring-offset-1"
                }`}
                onClick={() => setScheduled(true)}
              >
                Yes
              </button>
              <button
                className={`rounded-lg bg-blue-600 px-4 py-4 text-xl disabled:bg-red-500 ${
                  scheduled === true && "bg-opacity-20"
                } ${
                  scheduled === false &&
                  "ring-offset-bak ring ring-yellow-700 ring-offset-1"
                }`}
                onClick={() => setScheduled(false)}
              >
                No
              </button>
            </div>
            <div className=" mt-4  border-t border-gray-700 pt-4">
              {scheduled === false && (
                <div className="w-full space-y-2 py-2 text-left">
                  <div className="">
                    <label className={`labelClass`}>Date Checked</label>
                    <DatePicker
                      name="date_checked"
                      onChange={(date) =>
                        setTheCheck({
                          ...theCheck,
                          date_checked: date,
                        })
                      }
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={60}
                      timeInputLabel="Time:"
                      dateFormat="dd-MM-yy HH:mm"
                      showPopperArrow={false}
                      maxDate={new Date()}
                      className="inputClass z-50"
                      selected={theCheck.date_checked}
                    />
                  </div>
                  <div>
                    <label className={`labelClass`}>Note</label>
                    <textarea
                      rows={3}
                      name="note"
                      value={theCheck.note}
                      onChange={onTheCheckChange}
                      className={`inputClass w-full`}
                    />
                  </div>
                  <div className="mt-4 flex justify-around border-t border-gray-700 pt-4">
                    <button
                      className={`rounded-lg bg-gray-300 px-4 py-4 text-xl disabled:bg-red-500 dark:bg-gray-900 ${
                        theCheck.status === "Ok" && "border bg-opacity-20"
                      } ${
                        theCheck.status === "Faulty" &&
                        "ring-offset-bak border-0 bg-red-600 ring ring-yellow-700 ring-offset-1"
                      }`}
                      onClick={() =>
                        setTheCheck({
                          ...theCheck,
                          status: "Faulty",
                        })
                      }
                    >
                      Faulty
                    </button>
                    <button
                      className={`rounded-lg  bg-gray-300 px-4 py-4 text-xl disabled:bg-red-500 dark:bg-gray-900 ${
                        theCheck.status === "Faulty" && "border bg-opacity-20"
                      } ${
                        theCheck.status === "OK" &&
                        "ring-offset-bak border-0 bg-green-600 ring ring-yellow-700 ring-offset-1"
                      }`}
                      onClick={() =>
                        setTheCheck({
                          ...theCheck,
                          status: "OK",
                        })
                      }
                    >
                      OK
                    </button>
                  </div>
                </div>
              )}
              {scheduled && (
                <div className="flex w-full items-center justify-between space-y-2 py-2 text-left">
                  <div className=" ">
                    <label className={`labelClass`}>Date Scheduled</label>
                    <DatePicker
                      name="date_scheduled"
                      onChange={(date) =>
                        setTheCheck({
                          ...theCheck,
                          date_scheduled: date,
                        })
                      }
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={60}
                      timeInputLabel="Time:"
                      dateFormat="dd-MM-yy HH:mm"
                      showPopperArrow={false}
                      minDate={new Date()}
                      className="inputClass z-50 justify-center"
                      selected={theCheck.date_scheduled}
                    />
                  </div>
                  {theCheck.date_scheduled && (
                    <div className="text-lg">
                      Check due in{" "}
                      {dayjs(theCheck.date_scheduled).diff(dayjs(), "days")}{" "}
                      days.
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="mt-4 flex justify-between border-t border-gray-700 pt-4">
              <Button
                text="Cancel"
                onClick={() => setCheck(false)}
                colour="bg-red-700 text-white"
              />
              <Button
                text="Add"
                onClick={handleAddCheck}
                colour="bg-green-700 text-white"
              />
            </div>
          </div>
        }
      />
    );
  };
  const handleCopyAsset = () => {
    toast.promise(api.assets.clone(asset._id, assetCopy).then(res => {
      navigate(`/asset/view/${res._id}`);
      setCopy(false);
    }), {
      loading: "Cloning Asset...",
      error: "There was a error while creating a clone, please try again.",
      success: "Successfully cloned asset, taking you to the new asset..."
    }).then(() => {

    })
  };

  const CopyAssetDialog = () => {
    return (
      <Modal
        show={copy}
        onClose={() => setCopy(false)}
        title={` Copy Asset ${asset.name}`}
        children={
          <div>
            <div className="space-y-4 p-4">
              <ToggleSwitch
                label="Copy contents"
                isChecked={assetCopy.copyContents}
                onChange={(e) =>
                  setAssetCopy({
                    ...assetCopy,
                    copyContents: e,
                  })
                }
              />
              <ToggleSwitch
                label="Copy assigned"
                isChecked={assetCopy.copyAssigned}
                onChange={(e) =>
                  setAssetCopy({
                    ...assetCopy,
                    copyAssigned: e,
                  })
                }
              />
              <Input
                label={"Name"}
                field="name"
                type={"text"}
                value={assetCopy.name}
                onChange={(e) =>
                  setAssetCopy({
                    ...assetCopy,
                    [e.target.name]: e.target.value,
                  })
                }
                placeholder="Dry suit.."
              />
              <div className="flex gap-4">
                <Input
                  label={"Asset Number"}
                  field="assetNum"
                  type={"text"}
                  value={assetCopy.assetNum.toUpperCase()}
                  onChange={(e) =>
                    setAssetCopy({
                      ...assetCopy,
                      [e.target.name]: e.target.value,
                    })
                  }
                  placeholder="ABC123"
                />
                <Input
                  label={"Serial Number"}
                  field="serialNum"
                  type={"text"}
                  value={assetCopy.serialNum}
                  onChange={(e) =>
                    setAssetCopy({
                      ...assetCopy,
                      [e.target.name]: e.target.value,
                    })
                  }
                  placeholder="AX456777788889000000"
                />
              </div>
            </div>
            <div className="mt-4 flex justify-between border-t border-gray-700 pt-4">
              <Button
                text="Cancel"
                onClick={() => setCopy(false)}
                colour="bg-red-700 text-white"
              />
              <Button
                text="Copy"
                onClick={handleCopyAsset}
                colour="bg-green-700 text-white"
              />
            </div>
          </div>
        }
      />
    );
  };

  const ViewCheckDialog = () => {
    return (
      <Transition
        show={viewCheck}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Dialog
          open={viewCheck}
          onClose={() => setViewCheck(false)}
          className="fixed inset-0 z-10 overflow-y-auto "
        >
          <div className="min-h-screen text-center">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-80" />
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className="my-2 inline-block w-full max-w-2xl transform rounded-2xl border-4 border-gray-700 bg-gray-800 p-4 text-center align-middle shadow-xl transition-all">
              <div className="border-b border-gray-900">
                <Dialog.Title>
                  View Asset Check for{" "}
                  <span className="italic">
                    {asset.name} {asset.assetNum}
                  </span>
                </Dialog.Title>
              </div>
              <div className="mt-4  border-t border-gray-700 pt-4 text-left">
                <div className="w-full py-2">
                  <label className={`labelClass `}>Note</label>
                  <textarea
                    rows={3}
                    name="note"
                    value={theCheck.note}
                    onChange={onTheCheckChange}
                    className={`inputClass w-full`}
                  />
                  <div className="">
                    <label className={`labelClass`}>Date Checked</label>
                    <DatePicker
                      name="date_checked"
                      onChange={(date) =>
                        setTheCheck({
                          ...theCheck,
                          date_checked: date,
                        })
                      }
                      maxDate={new Date()}
                      className="inputClass z-50"
                      selected={theCheck.date_checked}
                    />
                  </div>
                  {/* <DatePicker
                   onChange={(date) =>
                   setTheCheck({
                   ...theCheck,
                   date_checked: date,
                   })
                   }
                   selected={theCheck.date_checked}
                   shouldCloseOnSelect={true}
                   dateFormat="dd-MM-yyyy"
                   maxDate={new Date()}
                   nextMonthButtonLabel=">"
                   previousMonthButtonLabel="<"
                   className="block w-full text-base p-1 md:text-lg bg-gray-900 border-2 border-gray-700 rounded shadow-sm"
                   /> */}

                  <div className="mt-4 flex justify-around border-t border-gray-700 pt-4">
                    <button
                      className={`rounded-lg bg-gray-900 px-4 py-4 text-xl disabled:bg-red-500 ${
                        theCheck.status === "OK" && "border bg-opacity-20"
                      } ${
                        theCheck.status === "Faulty" &&
                        "ring-offset-bak border-0 bg-red-600 ring ring-yellow-700 ring-offset-1"
                      }`}
                      onClick={() =>
                        setTheCheck({
                          ...theCheck,
                          status: "Faulty",
                        })
                      }
                    >
                      Faulty
                    </button>
                    <button
                      className={`rounded-lg bg-gray-900 px-4 py-4 text-xl disabled:bg-red-500 ${
                        theCheck.status === "Faulty" && "border bg-opacity-20"
                      } ${
                        theCheck.status === "OK" &&
                        "ring-offset-bak border-0 bg-green-600 ring ring-yellow-700 ring-offset-1"
                      }`}
                      onClick={() =>
                        setTheCheck({
                          ...theCheck,
                          status: "OK",
                        })
                      }
                    >
                      OK
                    </button>
                  </div>
                </div>
              </div>
              <div className="mt-4 flex justify-between border-t border-gray-700 pt-4">
                <button
                  className="rounded-lg bg-red-600 px-4 py-2"
                  onClick={() => setViewCheck(false)}
                >
                  Cancel
                </button>
                <button
                  disabled={theCheck.status === "" ? true : false}
                  className="rounded-lg bg-blue-600 px-4 py-2 disabled:bg-red-500 disabled:bg-opacity-30"
                  onClick={handleUpdateCheck}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  };
  const viewCheckData = (data) => {
    if (!data.date_checked) {
      setViewCheck(true);
      setCheckID(data._id);
    }
  };
  const goto = (data) => {
    switch (data.type) {
      case "member":
        navigate(`/team/${data.id}`);
        break;
      case "location":
        navigate(`/training/locations/${data.id}`);
        break;
      case "vehicle":
        navigate(`/team/vehicles/${data.id}`);
        break;
      default:
        break;
    }
  };
  const gotoSupplier = (id) => {
    navigate(`/asset/suppliers/${id}`);
  };

  return (
    <Layout full>
      {AddCheckDialog()}
      {ViewCheckDialog()}
      {CopyAssetDialog()}
      <div className="grid grid-cols-1 xl:grid-cols-3 xl:gap-4">
        <div className="col-span-1 ">
          <Box
            title={`Asset '${
              asset.assetNum ? asset.assetNum : asset.name
            }' Details`}
          >
            <div className="w-full">
              <div className="flex justify-around gap-1 bg-zinc-200 p-2 dark:bg-zinc-800">
                <>
                    <Button
                      text="Edit"
                      icon={<PencilIcon className="h-6 pr-2 text-blue-400" />}
                      onClick={() => navigate(`/asset/view/${asset._id}/edit`)}
                    />
                    <Button
                      text="Copy"
                      icon={
                        <PlusCircleIcon className="h-6 pr-2 text-green-400" />
                      }
                      onClick={() => setCopy(true)}
                    />
                  <Button
                    text="Print"
                    icon={<PrinterIcon className="h-6 pr-2 text-blue-400" />}
                    onClick={() => setCopy(true)}
                  />
                </>
              </div>
              <div>
                <DataRow
                  label="Name"
                  value={
                    <>
                      {asset.archived && (
                        <span className="italic text-red-600">
                          ** Archived **
                        </span>
                      )}
                      <span className="pl-2 ">{asset.name}</span>
                    </>
                  }
                />
              </div>
              <DisplayAndUpdatePhoto
                className="w-full border-8 border-white shadow-lg dark:shadow-zinc-600"
                canUpdate={true}
                onGetUploadSpec={handleGetUploadSpec}
                onGetPhoto={handleGettingPhoto}
                onUpload={handlePhotoUpload}
                popoverText={"Change Asset Photo"}
                blankImage={Blank}
              />
              {asset.archived && (
                <div>
                  <DataRow
                    label="Archived Date"
                    value={dayjs(asset.date_archived).format("DD-MM-YYYY")}
                  />
                </div>
              )}
              <div>
                <DataRow
                  label="Asset Num"
                  value={asset.assetNum ? asset.assetNum : "--"}
                />
              </div>
              <div>
                <DataRow
                  label="Serial Num"
                  value={asset.serialNum ? asset.serialNum : "--"}
                />
              </div>
              <div className="grid grid-cols-2 divide-x divide-zinc-300 dark:divide-zinc-700">
                <DataRow
                  label="Purchase Date"
                  value={
                    asset.date_purchased
                      ? dayjs(asset.date_purchased).format("DD-MM-YYYY")
                      : "--"
                  }
                />

                <DataRow
                  label="Expiry Date"
                  value={
                    asset.date_expires
                      ? dayjs(asset.date_expires).format("DD-MM-YYYY")
                      : "--"
                  }
                />
              </div>
              <div>
                <DataRow
                  label="Tags"
                  value={
                    <div className="flex flex-wrap gap-1">
                      {asset.tags &&
                        asset.tags.map((t, i) => {
                          return (
                            <div
                              key={i}
                              className={`m-1 cursor-pointer rounded-lg bg-zinc-300 px-2 py-1 text-sm text-zinc-700 hover:bg-gray-600 dark:bg-zinc-700 dark:text-white `}
                            >
                              {t}
                            </div>
                          );
                        })}
                    </div>
                  }
                />
              </div>
              <div className="grid grid-cols-2 divide-x divide-zinc-300 dark:divide-zinc-700">
                <DataRow
                  label="Taskable"
                  value={
                    asset.taskable ? (
                      <span className="text-green-600">Yes</span>
                    ) : (
                      <span className="text-red-600">No</span>
                    )
                  }
                  tooltip={"Assign asset in SarSys"}
                />

                {asset.taskable && (
                  <div className="">
                    <DataRow label="Taskable Type" value={asset.taskableType} />
                  </div>
                )}
              </div>
              <div className="grid grid-cols-2 divide-x divide-zinc-300 dark:divide-zinc-700">
                <DataRow label="Weight" sub={"(Kg)"} value={asset.weight} />
                <DataRow
                  label="Status"
                  value={asset.status}
                  warning={asset.status !== "Operational"}
                />
              </div>
              <div className="grid grid-cols-2 divide-x divide-zinc-300 dark:divide-zinc-700">
                <DataRow
                  label="Service Interval"
                  sub={"(months)"}
                  value={asset.serviceInterval}
                />

                <div className="">
                  <DataRow
                    label="Last Service"
                    value={
                      asset.LastService
                        ? dayjs(asset.LastService).format("DD-MM-YYYY")
                        : "--"
                    }
                  />
                </div>
              </div>
              {/* <div className="">
                <DataRow
                  label="Status"
                  value={asset.status}
                  warning={asset.status !== "Operational" ? true : false}
                />
              </div> */}
              <div>
                <DataRow
                  label="Assigned"
                  value={
                    <span className="px-2 text-white">
                      {asset.AssignedTo && asset.AssignedTo.name ? (
                        <button
                          onClick={() => goto(asset.AssignedTo)}
                          className="rounded-xl border border-gray-700 bg-gray-800 px-4 py-2 text-right hover:bg-gray-900"
                        >
                          {asset.AssignedTo.name}
                        </button>
                      ) : (
                        "--"
                      )}
                    </span>
                  }
                />
              </div>
              <div>
                <DataRow
                  label="Supplier"
                  value={
                    asset.supplier && asset.supplier.name ? (
                      <Button
                        text={asset.supplier.name}
                        onClick={() => gotoSupplier(asset.supplier._id)}
                      />
                    ) : (
                      "--"
                    )
                  }
                />
              </div>
              <div>
                <DataRow
                  label="Details"
                  value={asset?.details?.split("\n").map((a, i) => (
                    <span key={i}>
                      {a}
                      <br />
                    </span>
                  ))}
                />
              </div>
            </div>
          </Box>
        </div>
        <div className="col-span-2">
          <Box>
            <Tab.Group>
              <Tab.List className="flex  divide-x divide-zinc-300 border-b border-zinc-300 dark:divide-zinc-600 dark:border-zinc-600 ">
                <Tab
                  className={({ selected }) => returnTabButtonStyle(selected)}
                >
                  Checks
                </Tab>

                <Tab
                  className={({ selected }) => returnTabButtonStyle(selected)}
                >
                  Services
                </Tab>
                  <Tab
                    className={({ selected }) => returnTabButtonStyle(selected)}
                  >
                    Assign Asset
                  </Tab>
                {asset.hasContents && (
                  <Tab
                    className={({ selected }) => returnTabButtonStyle(selected)}
                  >
                    Contents
                  </Tab>
                )}
              </Tab.List>
              <Tab.Panels>
                <Tab.Panel>
                  <div className="">
                    <div className="p-2">
                      <Button
                        text={"Add Check"}
                        colour={
                          "bg-green-600 hover:bg-green-700 hover:text-zinc-200 text-white"
                        }
                        onClick={() =>
                          !asset.archived && canUpdate && setCheck(true)
                        }
                      />
                    </div>

                    <div
                      className="w-full flex-grow-0 overflow-y-auto md:flex-1"
                      style={{ height: "70vh" }}
                    >
                      <div className="">
                        <table className="tableClass">
                          <thead>
                            <tr className="tableHeadRowClass">
                              <th className="tableHeadCellClass w-1/6">Date</th>
                              <th className="tableHeadCellClass">Notes</th>
                              <th className="tableHeadCellClass w-1/6">
                                Status
                              </th>
                              <th className="tableHeadCellClass w-1/6">
                                Checked By
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {assetChecks.map((c) => {
                              return (
                                <tr
                                  key={c._id}
                                  className={`tableBodyRowClass  ${
                                    c.alert && " bg-red-900 bg-opacity-30"
                                  } hover:bg-gray-700 ${
                                    c.date_checked && "cursor-not-allowed"
                                  }
                                  ${canUpdate ? "cursor-pointer" : ""}`}
                                  onClick={() => canUpdate && viewCheckData(c)}
                                >
                                  <td className="tableBodyCellClass">
                                    {c.theDate
                                      ? dayjs(c.theDate).format(
                                          "DD-MM-YYYY HH:mm "
                                        )
                                      : "--"}
                                  </td>
                                  <td className="tableBodyCellClass">
                                    {c.note}
                                  </td>
                                  <td className="tableBodyCellClass">
                                    {c.status}
                                  </td>
                                  <td className="tableBodyCellClass">
                                    {c.checked_By}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </Tab.Panel>

                <Tab.Panel>
                  <div className="gap-4 md:flex">
                    <div className="w-full flex-grow-0 md:flex-1">
                      <Suspense fallback={<div>Loading...</div>}>
                        <AssetServices
                          canUpdate={canUpdate}
                          asset={asset}
                          action={getAssetChecks}
                        />
                      </Suspense>
                    </div>
                  </div>
                </Tab.Panel>
                  <Tab.Panel>
                    <div className="gap-4 md:flex">
                      <div className="w-full flex-grow-0 md:flex-1">
                        <Suspense fallback={<div>Loading...</div>}>
                          <AssignAsset
                            asset={asset}
                            action={() => {
                              getAssetChecks();
                              asset._refresh();
                            }}
                          />
                        </Suspense>
                      </div>
                    </div>
                  </Tab.Panel>
                {asset.hasContents && (
                  <Tab.Panel>
                    <div className="gap-4 md:flex">
                      <div className="w-full flex-grow-0 md:flex-1">
                        <Suspense fallback={<div>Loading...</div>}>
                          <AssetContents
                            asset={asset}
                            action={getAssetChecks}
                            canUpdate={canUpdate}
                          />
                        </Suspense>
                      </div>
                    </div>
                  </Tab.Panel>
                )}
              </Tab.Panels>
            </Tab.Group>
          </Box>
        </div>
      </div>
    </Layout>
  );
}
