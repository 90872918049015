import React from "react";
import { Dialog, Transition } from "@headlessui/react";
import Loader from "./Loader";

import Button from "./Button";

const PdfPrintingProgress = (props) => {
  return (
    <Transition
      show={props.active}
      enter="transition duration-100 ease-out"
      enterFrom="transform scale-95 opacity-0"
      enterTo="transform scale-100 opacity-100"
      leave="transition duration-75 ease-out"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-95 opacity-0"
    >
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={() => {
          // do nothing
        }}
        style={{ zIndex: 999 }}
      >
        <div
          className="z-50 min-h-screen px-4 text-center"
          style={{ zIndex: 999 }}
        >
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-80" />
          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <div className="my-8 inline-block w-full max-w-md transform overflow-hidden rounded-2xl bg-gray-800 p-6 text-center align-middle shadow-xl transition-all">
            {props.error === null ? (
              <React.Fragment>
                <h1>Please Wait...</h1>
                <Loader>Creating PDF...</Loader>
                <button>{""}</button>
                {/* put in a blank button to get around the must contain focusable item limit */}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <h1>PDF Error</h1>
                <div className={"my-2 bg-red-800 bg-opacity-50"}>
                  {props.error}
                </div>
                <Button text={"Close"} onClick={props.onClose} />
              </React.Fragment>
            )}
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
export default PdfPrintingProgress;
