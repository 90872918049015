import React, {useState} from "react";
import Modal from "../../../components/common/Modal";
import FileUploader from "../../../components/common/FileUploader";
import PrettyBytes from "pretty-bytes";
import TextArea from "../../../components/common/TextArea";
import Toast from "react-hot-toast";
import Loader from "../../../components/common/Loader";

const PopupFormFileUpload = ({ onClose, onUpload }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileDescription, setFileDescription] = useState("");
  const [isUploading, setIsUploading] = useState(false);

  const handleSubmit = () => {
    if (selectedFile == null) return;
    setIsUploading(true);

    onUpload(selectedFile, fileDescription).then(() => {
      onClose();
    }).catch(err => {
      setIsUploading(false);
      Toast.error(err.response.data.message);
    });
  };

  return (
    <Modal onClose={onClose}>
      {isUploading ? <>

        <div className={"text-xl text-center my-8"}>
          Uploading please wait...
          <Loader className={"mt-3"} />
        </div>
      </> : <>
        {selectedFile === null && (
          <div className={"pt-12"}><FileUploader onFileSelected={file => setSelectedFile(file[0])} maxSize={(20 * 1024) * 1024} /></div>
        )}
        {selectedFile !== null && (
          <div className={"pb-2"}>
            File: {selectedFile.name}
            <br />
            Size: {PrettyBytes(selectedFile.size, { binary: true })} <br />
            <button
              className="rounded-lg bg-red-500 px-4 py-2 text-white"
              onClick={() => setSelectedFile(null)}
            >
              Clear
            </button>
          </div>
        )}
        <TextArea
          label="File Description"
          value={fileDescription}
          onChange={(e) => setFileDescription(e.target.value)}
        />
        <div className="mt-4 flex justify-between border-t border-zinc-500 pt-4">
          <button
            className="rounded-lg bg-primary px-4 py-2 text-white"
            onClick={onClose}
          >Cancel</button>
          <button
            className="rounded-lg bg-green-500 px-4 py-2 text-white disabled:bg-gray-500"
            onClick={handleSubmit}
          >Upload</button>
        </div>
      </>}


    </Modal>
  );
};
export default PopupFormFileUpload;