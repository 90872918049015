import React, {useEffect, useState, useCallback} from "react";
import {useParams} from "react-router-dom";
import {toast} from "react-hot-toast";
import DayJS from "dayjs";
import {PencilIcon, ArrowRightIcon} from "@heroicons/react/24/outline";
import {TrashIcon} from "@heroicons/react/20/solid";
import Box from "../../components/common/Box";
import IncidentForm from "./_components/IncidentForm";
import IncidentPeriodForm from "./_components/IncidentPeriodForm";
import IncidentPeriodMembers from "./_components/IncidentPeriodMembers";
import api from "../../api";
import ConfirmBox from "../../components/common/Confirm";


const EditIncident = () => {
  const { incidentId} = useParams();
  const [incident, setIncident] = useState({});
  const [periods, setPeriods] = useState([]);
  const [editPeriod, setEditPeriod] = useState({});
  const [selectedPeriod, setSelectedPeriod] = useState({});
  const [removePeriodPrompt, setRemovePeriodPrompt] = useState({});

  const getData = useCallback(() => {
    api.incidents.getIncident(incidentId).then(res => {
      setIncident(res);
    });
    api.incidents.periods.getPeriods(incidentId).then(res => {
      setPeriods(res);
    });
  }, [incidentId]);

  useEffect(() => {
    getData();
  }, [getData]);

  const handleDeletePeriod = () => {
    api.incidents.periods.deletePeriod(incidentId, removePeriodPrompt._id).then(() => {
      setRemovePeriodPrompt({});
      setEditPeriod({});
      setSelectedPeriod({});
      getData();

      toast.success("Successfully deleted incident period");
    })
  }
  const handlePeriodSubmit = data => {
    if(editPeriod._id !== undefined){
      return api.incidents.periods.updatePeriod(incidentId, editPeriod._id, data).then(() => {
        getData();
        setEditPeriod({});
        toast.success("Period updated successfully.");
      })
    } else {
      return api.incidents.periods.createPeriod(incidentId, data).then(() => {
        getData();
        toast.success("Period created successfully.");
      });
    }
  };

  const handleDetailsUpdate = data => {
    api.incidents.updateIncident(incidentId, data).then(() => {
      toast.success("Successfully updated incident details.");
    }).catch(err => {
      if(err.isAxiosError){
        if(err.response.data.message !== undefined){
          toast.error(err.response.data.message);
          return;
        }

        toast.error("There was a network error while submitting your data, please try again shortly or check your internet connection.");
        return;
      }
      toast.error("There was an unexpected error while processing your request.");
    });
  }


  return (
    <div className="w-full p-2">
      {removePeriodPrompt._id !== undefined && <ConfirmBox title={"Deleting this operational period/deployment will also delete related search records against team members"} onClose={() => setRemovePeriodPrompt({})} show={true} action={() => handleDeletePeriod()} />}
      <Box title={`Edit Incident: ${incident.name}`}>
        <IncidentForm editIncident={incident._id !== undefined ? incident : null} onSubmit={handleDetailsUpdate} />
      </Box>
      <div className="grid grid-cols-2 gap-4">
        <Box title={"Operational Periods/Deployments"}>
          <IncidentPeriodForm editPeriod={editPeriod._id !== undefined ? editPeriod : null} onSubmit={handlePeriodSubmit} />

          <div className={"border-t-2 h-1 my-3"}></div>
          <table className="tableClass">
            <thead className="tableHeadClass">
            <tr className="tableHeadRowClass">
              <th className="tableHeadCellClass w-20">Ref</th>
              <th className="tableHeadCellClass">Start At</th>
              <th className="tableHeadCellClass">End At</th>
              <th className="tableHeadCellClass w-32">Total Members</th>
              <th className="tableHeadCellClass w-40"></th>
            </tr>
            </thead>
            <tbody className="tableBodyClass">
            {periods.map((m) => {
              return (
                <tr key={m._id} className="tableBodyRowClass">
                  <td className="tableBodyCellClass text-lg">{m.refNum}</td>
                  <td className="tableBodyCellClass text-lg">{DayJS(m.startAt).format("DD-MM-YYYY HH:mm")}</td>
                  <td className="tableBodyCellClass text-lg">{DayJS(m.endAt).format("DD-MM-YYYY HH:mm")}</td>
                  <td className="tableBodyCellClass text-lg">{m._totalMembers}</td>
                  <td className="tableBodyCellClass justify-center flex gap-2">
                    <button
                      onClick={() => setEditPeriod(m)}
                      className="rounded-md border bg-opacity-80 px-2 py-1 text-sm text-white border-gray-400 bg-gray-700 hover:border-gray-300 hover:bg-gray-800"
                    ><PencilIcon className="h-6 w-6 text-gray-200" /></button>
                    <button
                      className="rounded-md border bg-opacity-80 px-2 py-1 text-sm text-white border-red-400 bg-red-700 hover:border-red-300 hover:bg-red-800"
                      onClick={() => setRemovePeriodPrompt(m)}
                    ><TrashIcon className="h-6 w-6 text-red-200" /></button>
                    <button
                      onClick={() => setSelectedPeriod(m)}
                      className="rounded-md border bg-opacity-80 px-2 py-1 text-sm text-white border-blue-400 bg-blue-700 hover:border-blue-300 hover:bg-blue-800"
                    ><ArrowRightIcon className="h-6 w-6 text-gray-200" /></button>
                  </td>
                </tr>
              );
            })}
            </tbody>
          </table>
        </Box>
        <Box title="Period Members">
          <IncidentPeriodMembers incident={incident} period={selectedPeriod} onDataReload={() => getData()} />
        </Box>
      </div>
    </div>
  );
}
export default EditIncident;