import React from "react";
import PropTypes from "prop-types";
import Select, { createFilter } from "react-select";

export default function SelectZero({
  label = "",
  name = "",
  value = "",
  options = [],
  onChange = () => {},
  className = "",
  error = false,
  isSearchable = true,
  disabled = false,
}) {
  // need to change ring colour to suit rest of style
  const customStyles = {
    option: (provided, state) => ({
      color: "text-primary dark:text-primary-dark",
      padding: 10,
      background: state.isSelected
        ? `${state.isFocused ? "#222" : "#222"}`
        : `${state.isFocused ? "#222" : "#111827"}`,
      cursor: "pointer",
    }),

    control: (control) => ({
      ...control,
      background: `${error ? "#7c0707" : "#222"}`,
      border: `1px solid ${error ? "#FF0000" : "#4B5663"}`,
    }),

    menu: (menu) => ({
      ...menu,
      background: "#111827",
      padding: 0,
    }),

    menuList: (provided) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
    }),

    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";
      const color = "text-primary";

      return { ...provided, opacity, transition, color };
    },

    input: (provided) => ({
      ...provided,
      color: "text-primary",
    }),
  };

  options = options
    ? options.map((item) => ({ label: item.text, value: item.value }))
    : [];
  const filterConfig = {
    ignoreCase: true,
    ignoreAccents: true,
    trim: true,
    matchFrom: "start",
  };
  return (
    <div className="">
      <label
        htmlFor={name}
        className={`block ${
          error ? "text-red-400" : "text-primary dark:text-zinc-400"
        }`}
      >
        {label}
      </label>

      <Select
        isDisabled={disabled}
        aria-labelledby="aria-label"
        filterOption={createFilter(filterConfig)}
        name={name}
        autoFocus
        isSearchable={isSearchable}
        value={
          options && value
            ? options.find((item) => item.value === value)
              ? options.find((item) => item.value === value)
              : { label: "", value: "" }
            : { label: "", value: "" }
        }
        onChange={(e) => onChange({ target: { name: name, value: e.value } })}
        options={options}
        classNamePrefix="my-react-select"
        className={`my-react-select-container tailwindShadowFix text-sm ${className} rounded`}
        // styles={customStyles}
      />
    </div>
  );
}

SelectZero.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  error: PropTypes.bool,
};
