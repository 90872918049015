import React, { useEffect, useState, useMemo } from "react";
import DayJS from "dayjs";
import ChainedContext from "../../../../contexts/chainedContext";
import MemberReceiver from "../../../../contexts/member_context/Member_Receiver";
import { Skeleton } from "../../../../components/ui/skeleton";
import api from "../../../../api";
import Box from "../../../../components/common/Box";
import QuickCourseAdd from "./_components/QuickCourseAdd";
import ToggleSwitch from "../../../../components/common/ToggleSwitch";
import { Authentication } from "../../../../lib/_sso"
const Courses = ({ member}) => {
  const [loading, setLoading] = useState(true);
  const [showAdd, showAddCourse] = useState(false);
  const [hideExpired, setHideExpired] = useState(true);
  const [courses, setCourses] = useState([]);
  const [roles, setRoles] = useState([]);
  const profile = Authentication.getUserData();

  const getData = () => {
    setLoading(true);

    Promise.all([
      api.member.courses.getRoles(member._id),
      api.member.courses.getCourses(member._id),
    ])
      .then((res) => {
        setRoles(res[0]);
        setCourses(res[1]);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, [member]);

  let courseList = useMemo(() => {
    if(!hideExpired) return courses;

    return courses.filter(course => DayJS(course.expiresAt).isAfter(DayJS()))
  }, [courses, hideExpired]);

  courseList = courseList.sort((a, b) => {
    if(a.expiresAt > b.expiresAt) return -1;
    if(a.expiresAt < b.expiresAt) return 1;
    return 0;
  })

  return (
    <div className="space-y-4 p-4">
      <Box title={`${member.firstName}'s roles requiring courses`}>
        {loading ? (
          <div className="space-y-6 p-4">
            <div className="flex gap-8">
              <Skeleton className="h-20 w-28 p-4 dark:bg-zinc-700"></Skeleton>
              <Skeleton className="h-20 w-28 p-4 dark:bg-zinc-700"></Skeleton>
              <Skeleton className="h-20 w-28 p-4 dark:bg-zinc-700"></Skeleton>
              <Skeleton className="h-20 w-28 p-4 dark:bg-zinc-700"></Skeleton>
              <Skeleton className="h-20 w-28 p-4 dark:bg-zinc-700"></Skeleton>
              <Skeleton className="h-20 w-28 p-4 dark:bg-zinc-700"></Skeleton>
            </div>
          </div>
        ) : (
          <div className="flex gap-2 flex-wrap p-2">
            {roles.map((r, i) => {
              return (
                <div key={i} className={`rounded p-2 ${r.isActive ? "oncall" : "nonOp"} border text-sm dark:border-zinc-500`}>
                  {r.role.name}
                </div>
              );
            })}
            {roles.length === 0 && (
              <div className="p-4 text-xl">No roles to display</div>
            )}
          </div>
        )}
      </Box>

      <Box
        title={"Members Courses"}
        button={(member.centralId !== profile.sub) && {
          text: "Add Course",
          colour: "bg-green-700 text-sm text-white hover:text-gray-200",
          action: () => showAddCourse(true),
        }}
      >
        <div className={"m-2"}>
          <ToggleSwitch
            label={"Hide Expired"}
            isChecked={hideExpired}
            onChange={() => {
              setHideExpired(!hideExpired);
            }}
          />
        </div>

        {showAdd && (
          <QuickCourseAdd
            onClose={() => {
              showAddCourse(false);
              getData();
            }}
          />
        )}

        {courses.length === 0 ? (
          <div className={"p-2 text-red-500"}>
            Member has not completed any courses yet.
          </div>
        ) : (
          <table className="tableClass">
            <thead className="tableHeadClass">
              <tr className="tableHeadRowClass">
                <th className="tableHeadCellClass">Name</th>
                <th className="tableHeadCellClass">Comment</th>
                <th className="tableHeadCellClass w-44 text-center">
                  Completed At
                </th>
                <th className="tableHeadCellClass w-44 text-center">
                  Expiry Date
                </th>
                <th className="tableHeadCellClass w-44 text-center">Result</th>
              </tr>
            </thead>
            <tbody className="tableBodyClass">
              {courseList.map((course) => {
                const isValid = DayJS(course.expiresAt).isAfter(DayJS());
                return (
                  <tr key={course._id} className={`tableBodyRowClass ${isValid && course.isPass && "!bg-green-800 !bg-opacity-50"}`}>
                    <td className="tableBodyCellClass">{course.course?.name || "[Deleted Course]"}</td>
                    <td className="tableBodyCellClass">{course.comment}</td>
                    <td className="tableBodyCellClass">
                      {DayJS(course.startAt).format("DD-MM-YYYY")}
                    </td>
                    <td className="tableBodyCellClass">
                      {DayJS(course.expiresAt).format("DD-MM-YYYY")}
                    </td>
                    <td className="tableBodyCellClass">
                      {course.isPass ? "Pass" : "Fail"}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </Box>
    </div>
  );
};
export default ChainedContext(Courses, [
  [MemberReceiver, "member"]
]);