import React from "react";
import LocationManager from "../../../components/locationManager";
import ChainedContext from "../../../contexts/chainedContext";
import MemberReceiver from "../../../contexts/member_context/Member_Receiver";
import api from "../../../api";
import {toast} from "react-hot-toast";

function Locations({ member }) {
  return (
    <div className="p-4">
      <LocationManager
        showAddInitial={true}
        locations={
          member ? member.locations && member?.locations?.features : []
        }
        handleAddLocation={(data) => {
          const features = member ? member.locations && member?.locations?.features : [];
          features.push(data);
          return api.member.update(member._id, {
            locations: {
              type: "FeatureCollection",
              features
            }
          }).then(() => {
            toast.success("Successfully added new location.")
            member._refresh();
          });
        }}
        handleEditLocation={(data) => {
          const features = member ? member.locations && member?.locations?.features : [];
          return api.member.update(member._id, {
            locations: {
              type: "FeatureCollection",
              features: features.map(feature => {
                if(feature._id === data._id) return data;
                return feature;
              })
            }
          }).then(() => {
            toast.success("Successfully updated location.")
            member._refresh();
          });
        }}
        handleDeleteLocation={(data) => {
          const features = member ? member.locations && member?.locations?.features : [];
          return api.member.update(member._id, {
            locations: {
              type: "FeatureCollection",
              features: features.filter(feature => {
                return feature._id !== data._id;
              })
            }
          }).then(() => {
            toast.success("Successfully deleted location.")
            member._refresh();
          });
        }}
        onLocationClick={() => false}
        hideExtra={true}
        hideOrdering
      />
    </div>
  );
}
export default ChainedContext(Locations, [
  [MemberReceiver, "member"]
]);
