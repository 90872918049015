import React, {useEffect, useState} from "react";
import Input from "../common/Input";
import {PencilIcon} from "@heroicons/react/24/outline";
import {TrashIcon} from "@heroicons/react/20/solid";
import Box from "../common/Box";
import ConfirmBox from "../common/Confirm";


const NextOfKinList = ({ value = [], onChange }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [id, setID] = useState("");
  const [del, setDelete] = useState({});

  const [name, setName] = useState("");
  const [relationship, setRelationship] = useState("");
  const [phone, setPhone] = useState("");

  useEffect(() => {
    setIsDisabled(name.trim() === "");
  }, [name]);

  const clearForm = () => {
    setIsEdit(false);
    setID("");
    setName("");
    setRelationship("");
    setPhone("");
  };

  const handleEdit = item => {
    setID(item._id)
    setName(item.name);
    setRelationship(item.relationship);
    setPhone(item.phone);
    setIsEdit(true);
  }
  const addNOK = () => {
    const newData = {
      name,
      relationship,
      phone,
    };
    if (isEdit) {
      onChange(value.map(a => {
        if(a._id !== id) return a;
        return newData;
      }))
    } else {
      const data = [].concat(value);
      data.push(newData);
      onChange(data);
    }
    clearForm();
  };
  const removeNOK = () => {
    onChange(value.filter(a => a._id !== del._id));
    setDelete({});
  }

  return (
    <Box title={"Next of Kin"}>
      {del._id !== undefined && <ConfirmBox show={true} onClose={() => setDelete({})} title={`Delete Next of Kin: ${del.name}`} action={removeNOK} />}
      <div className="flex items-end gap-2 p-4">
        <Input
          label={"Name"}
          placeholder="name.."
          name="name"
          onChange={(e) => setName(e.target.value)}
          value={name}
        />
        <Input
          label={"Relationship"}
          placeholder="wife.."
          name="relationship"
          onChange={(e) => setRelationship(e.target.value)}
          value={relationship}
        />
        <Input
          label={"Phone"}
          placeholder="0123..."
          name="phone"
          onChange={(e) => setPhone(e.target.value)}
          value={phone}
        />
        <button
          className="nonOp w-48 rounded p-2"
          onClick={() => {
            clearForm();
            setIsEdit(false);
          }}
        >
          Clear
        </button>
        <button
          className={`oncall w-80 rounded p-2 ${isDisabled ? "cursor-not-allowed opacity-20 " : "bg-opacity-60"}`}
          onClick={addNOK}
          disabled={isDisabled}
        >
          {isEdit ? "Update" : "Add"}
        </button>
      </div>
      <div>
        <table className="min-w-full table-auto border-separate divide-y divide-gray-200 text-sm  lg:text-lg">
          <thead>
            <tr className="bg-gray-300 text-xs uppercase tracking-wider text-gray-900 dark:bg-zinc-700 dark:text-white">
              <th className="flex gap-1 px-4 py-3 text-left">Name</th>
              <th className="px-4 py-3 text-left">Relationship</th>
              <th className="px-4 py-3 text-left">Phone</th>
              <th className="px-4 py-3 text-left"></th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 text-left">
          {value.length > 0 ? value.map((u) => {
              return (
                <tr key={u._id} className={`cursor-pointer hover:bg-gray-300 dark:hover:bg-zinc-700`}>
                  <td className="p-2 text-lg">{u.name}</td>
                  <td className="p-2">{u.relationship}</td>
                  <td className="p-2">{u.phone}</td>
                  <td className="w-32 space-x-1 pr-2 pt-2 text-center">
                    <button
                      onClick={() => handleEdit(u)}
                      className="rounded-md border bg-primary bg-opacity-80  px-2 py-1 text-sm  text-white hover:bg-gray-800 disabled:opacity-20"
                    >
                      <PencilIcon className="h-6 w-6 text-yellow-200 " />
                    </button>
                    <button
                      className="rounded-md border border-red-400 bg-red-700 bg-opacity-80 px-2 py-1 text-sm text-white hover:border-red-300 hover:bg-red-800 disabled:opacity-20"
                      onClick={() => setDelete(u)}
                    >
                      <TrashIcon className="h-6 w-6 text-red-200" />
                    </button>
                  </td>
                </tr>
              );
            })
           : (
            <tr>
              <td colSpan={4}><div className={"text-center py-3"}>No items to display</div></td>
            </tr>
          )}
          </tbody>
        </table>
      </div>
    </Box>
  );
};
export default NextOfKinList;