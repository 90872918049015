import React, { useEffect, useState } from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import ChainedContext from "../../../contexts/chainedContext";
import SettingsReceiver from "../../../contexts/settings_context/Settings_Receiver";
import CommonFunctions from "../../../lib/CommonFunctions";

const AddressForm = ({ settings, onLocationChange, location }) => {
  const [grid, setGrid] = useState("");

  useEffect(() => {
    if (location?.properties !== undefined) {
      if (location?.properties?.grid) {
        setGrid(location?.properties?.grid);
      }
    }
  }, [location]);
  const handleFindGrid = async () => {
    let coordinates = CommonFunctions.getLatLng(grid, settings.gridType);
    if (coordinates) {
      const w3w = await CommonFunctions.getW3w(coordinates[1], coordinates[0]);
      let geoJSON = {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: coordinates,
        },
        properties: {
          ...(location?.properties || {}),
          name: grid.toUpperCase(),
          w3w: w3w !== null ? w3w : "",
          // w3w: w3w !== null ? w3w.split(".") : [], //not sure why we arent using w3w.words
          grid: grid.toUpperCase(),
          address: "",
          town: "",
          postcode: "",
        },
      };
      onLocationChange(geoJSON);
    }
  };

  return (
    <div className="flex flex-row items-center justify-between">
      <input
        name="grid"
        value={grid}
        placeholder={
          settings.gridType === "OSGB"
            ? "TQ123456"
            : settings.gridType === "OSIE"
            ? "J123456"
            : "33 N 455887 3972689"
        }
        onChange={(e) => setGrid(e.target.value)}
        autoComplete="off"
        className={
          "w-full rounded-md border border-gray-600 bg-gray-200 p-2 uppercase "
        }
      />
      <button
        disabled={grid === ""}
        onClick={handleFindGrid}
        className="rounded-md p-2 text-primary hover:text-gray-700"
      >
        <MagnifyingGlassIcon className="h-8 cursor-pointer" />
      </button>
    </div>
  );
};
export default ChainedContext(AddressForm, [[SettingsReceiver, "settings"]]);
