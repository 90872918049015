import DayJS from "dayjs";
import React, {useEffect, useState} from "react";
import DatePicker from "react-datepicker";
import {toast} from "react-hot-toast";
import API from "../../../../api";
import Select from "../../../../components/common/Select";
import ManageWebLinks from "../../../../components/widgets/ManageWebLinks";
import Box from "../../../../components/common/Box";
import Input from "../../../../components/common/Input";
import TextArea from "../../../../components/common/TextArea";
import DataRow from "../../../../components/common/DataRow";
import Button from "../../../../components/common/Button";
import LocationSelector from "../../../events/_components/LocationSelector";


const CompSessionForm = ({ onSubmit, onCancel, data = {}, isEdit = false, className = "" }) => {
  const [error, setError] = useState(null);
  const [comps, setComps] = useState([]);
  const [locations, setLocations] = useState([]);

  // Data fields
  const [competency, setCompetency] = useState("");
  const [location, setLocation] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [webLinks, setWebLinks] = useState([]);
  const [startAt, setStartAt] = useState(DayJS().startOf("hour").toDate());
  const [finishAt, setFinishAt] = useState(DayJS().startOf("hour").add(1, "hour").toDate());

  useEffect(() => {
    API.settings.getComps().then((res) => {
      setComps(res);
    });
    API.locations.all().then((res) => {
      setLocations(res);
    });
  }, []);

  useEffect(() => {
    if(data._id === undefined) return;

    if(data.competency) setCompetency(data.competency._id);
    if(data.location) setLocation(data.location._id);
    if(data.title) setTitle(data.title);
    if(data.description) setDescription(data.description);
    if(data.webLinks) setWebLinks(data.webLinks);
    setStartAt(DayJS(data.startAt).toDate());
    setFinishAt(DayJS(data.finishAt).toDate());

  }, [data]);

  const handleSubmit = () => {
    if (competency === "") {
      setError("Must select a competency");
      return;
    }
    if (startAt === "") {
      setError("Must set a Start At");
      return;
    }
    if (finishAt === "") {
      setError("Must set a Finish At");
      return;
    }
    if (title === "") {
      setError("Must set a Title");
      return;
    }
    if (DayJS(startAt).isAfter(DayJS(finishAt))) {
      setError("Start At is less then Finish At");
      return;
    }
    setError(null);

    onSubmit({
      competency,
      location,
      startAt,
      finishAt,
      title,
      description,
      webLinks
    }).catch((err) => {
      setError(err.message);
    });
  };

  return (
    <div className={className}>
      {error && <div className={"m-2 text-red-500"}>{error}</div>}
      <div className={"flex gap-4"}>
        <div className={"w-full"}>
          <label className="labelClass">Competency</label>
          <Select
            options={comps.map((item) => ({
              key: item._id,
              text: item.name,
              value: item._id,
            }))}
            value={competency}
            disabled={isEdit}
            onChange={(e) => setCompetency(e.target.value)}
          />
          {competency && <>
            <Input
              type="text"
              label={"Title"}
              onChange={(e) => setTitle(e.target.value)}
              value={title}
            />

            <div className="flex gap-4">
              <div className={"w-full"}>
                <label htmlFor={"date"} className={`labelClass`}>
                  Start At
                </label>
                <DatePicker
                  onChange={(date) => {
                    setStartAt(date);
                    if (date > finishAt) {
                      setFinishAt(date);
                      toast("'Finish At' has been updated as it was set less then 'Start At'");
                    }
                  }}
                  selected={startAt}
                  dateFormat="dd-MM-yyyy hh:mma"
                  nextMonthButtonLabel=">"
                  previousMonthButtonLabel="<"
                  className="inputClass"
                  showTimeSelect
                  // minDate={DayJS().startOf("day").toDate()}
                  data-disable-touch-keyboard
                />
              </div>
              <div className={"w-full"}>
                <label htmlFor={"date"} className={`labelClass`}>
                  Finish At
                </label>
                <DatePicker
                  onChange={(date) => setFinishAt(date)}
                  selected={finishAt}
                  dateFormat="dd-MM-yyyy hh:mma"
                  nextMonthButtonLabel=">"
                  previousMonthButtonLabel="<"
                  className="inputClass"
                  showTimeSelect
                  minDate={startAt}
                  data-disable-touch-keyboard
                />
              </div>
            </div>

            <TextArea
              label="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </>}
        </div>
        {competency && <div className={"w-full"}>
          <>
            <label className="labelClass">Location</label>
            <Select
              options={locations.map((item) => ({
                key: item._id,
                text: item.properties.name,
                value: item._id,
              }))}
              value={location}
              onChange={(e) => setLocation(e.target.value)}
            />
          </>
          {/*{location?.properties !== undefined ? (*/}
          {/*  <div className="">*/}
          {/*    <label htmlFor={"date"} className={`labelClass`}>*/}
          {/*      Location*/}
          {/*    </label>*/}
          {/*    <DataRow label="Name" value={location.properties.name} />*/}
          {/*    <DataRow label="Town" value={location.properties.town} />*/}
          {/*    <DataRow*/}
          {/*      label="Postcode"*/}
          {/*      value={location.properties.postcode}*/}
          {/*    />*/}
          {/*    <Button*/}
          {/*      text="Change Location"*/}
          {/*      onClick={() => setLocation({})}*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*) : (*/}
          {/*  <Box title="Location">*/}
          {/*    <LocationSelector*/}
          {/*      location={location}*/}
          {/*      onChange={setLocation}*/}
          {/*    />*/}
          {/*  </Box>*/}
          {/*)}*/}

          <Box title={"Web Links"} className={"pt-5"}>
            <ManageWebLinks links={webLinks} onChange={links => setWebLinks(links)}/>
          </Box>
        </div>}


      </div>

      <div className="grid items-end gap-4 p-2 sm:grid-cols-4">


      </div>

      <div className="mt-4 flex justify-between border-t border-zinc-500 pt-4">
        {onCancel && (
          <button
            className="rounded-lg bg-primary px-4 py-2 text-white"
            onClick={onCancel}
          >
            Cancel
          </button>
        )}
        <button
          className="rounded-lg bg-green-500 px-4 py-2 text-white"
          onClick={handleSubmit}
        >
          {isEdit ? "Update" : "Create"}
        </button>
      </div>
    </div>
  );
};
export default CompSessionForm;