import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";
import { Transition, Dialog } from "@headlessui/react";
import { FaEdit } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import { FcCancel } from "react-icons/fc";
import Box from "../../../../components/common/Box";
import api from "../../../../api";
import range from "lodash/range";
const years = range(dayjs(new Date()).year(), dayjs(new Date()).year() + 20, 1);
// console.log(years);
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export default function AssetContents({ asset, canUpdate }) {
  const [openDelete, setOpenDelete] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [deleteRecord, setDeleteRecord] = useState({});
  const [contents, setContents] = useState([]);
  const [content, setContent] = useState({
    qty: "",
    item: "",
    expiryDate: "",
  });

  useEffect(() => {
    getData();
  }, [asset]);

  const getData = () => {
    api.assets.contents.getContents(asset._id).then((res) => {
      setContents(res);
    });
  };
  const addContent = () => {
    api.assets.contents.addContents(asset._id, content).then((res) => {
      setContent({ qty: "", item: "", expiryDate: "" });
      getData();
    });
  };
  const openDialog = (data) => {
    setDeleteRecord(data);
    setOpenDelete(true);
  };
  const deleteContent = () => {
    api.assets.contents.delContents(asset._id, deleteRecord._id).then(() => {
      getData();
      setOpenDelete(false);
    });
  };
  const updateContent = () => {
    api.assets.contents
      .updateContent(asset._id, content._id, content)
      .then(() => {
        getData();
        setIsUpdate(false);
        setContent({ qty: "", item: "", expiryDate: "" });
      });
  };
  const DeleteDialog = () => {
    return (
      <Transition
        show={openDelete}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Dialog
          open={openDelete}
          onClose={() => setOpenDelete(false)}
          className="fixed inset-0 z-10 overflow-y-auto "
        >
          <div className="min-h-screen text-center">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-80" />
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className="my-2 inline-block w-full max-w-2xl transform rounded-2xl border-4 border-gray-700 bg-gray-800 p-4 text-center align-middle shadow-xl transition-all">
              <div className="text-2xl">
                <Dialog.Title className="text-xl">
                  Delete{" "}
                  <span className="italic">
                    {asset.name} {asset.assetNum}
                  </span>{" "}
                  content item
                  <span className="italic"> {deleteRecord.item}</span>
                </Dialog.Title>
              </div>

              <div className="mt-4 flex justify-between border-t border-gray-700 pt-4">
                <button
                  className="rounded-lg bg-red-600 px-4 py-2"
                  onClick={() => setOpenDelete(false)}
                >
                  Cancel
                </button>
                <button
                  className="rounded-lg bg-green-600 px-4 py-2 disabled:bg-red-500"
                  onClick={deleteContent}
                >
                  Yes Delete
                </button>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  };

  return (
    <div>
      {DeleteDialog()}
      <div className=" w-full">
        {canUpdate && (
          <div className="justify-arround flex gap-2 p-2">
            <div className="flex w-1/6 flex-col text-center">
              <label className={`labelClass`}>Qty</label>
              <input
                onChange={(e) =>
                  setContent({
                    ...content,
                    qty: e.target.value,
                  })
                }
                value={content.qty}
                name="qty"
                placeholder="qty"
                className="inputClass"
              />
            </div>
            <div className="flex w-4/6 flex-col text-center">
              <label className={`labelClass`}>Item</label>
              <input
                onChange={(e) =>
                  setContent({
                    ...content,
                    item: e.target.value,
                  })
                }
                value={content.item}
                name="item"
                placeholder="item"
                className="inputClass"
              />
            </div>
            <div className="flex flex-col  text-center">
              <label className={`labelClass`}>Expiry Date</label>

              <DatePicker
                autoComplete="off"
                renderCustomHeader={({
                  date,
                  changeYear,
                  changeMonth,
                  decreaseMonth,
                  increaseMonth,
                  prevMonthButtonDisabled,
                  nextMonthButtonDisabled,
                }) => (
                  <div
                    style={{
                      margin: 10,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <button
                      className="pr-1 text-lg"
                      onClick={decreaseMonth}
                      disabled={prevMonthButtonDisabled}
                    >
                      {"<"}
                    </button>

                    <select
                      value={months[dayjs(date).month()]}
                      className="p-1"
                      onChange={({ target: { value } }) =>
                        changeMonth(months.indexOf(value))
                      }
                    >
                      {months.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                    <select
                      value={dayjs(date).year()}
                      className="p-1"
                      onChange={({ target: { value } }) => changeYear(value)}
                    >
                      {years.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>

                    <button
                      className="pl-1 text-lg"
                      onClick={increaseMonth}
                      disabled={nextMonthButtonDisabled}
                    >
                      {">"}
                    </button>
                  </div>
                )}
                name="expiryDate"
                onChange={(date) =>
                  setContent({
                    ...content,
                    expiryDate: date,
                  })
                }
                dateFormat="dd-MM-yyyy"
                minDate={new Date()}
                className="inputClass z-50 "
                selected={
                  content.expiryDate ? new Date(content.expiryDate) : ""
                }
              />
            </div>
            <div className="items-middle flex w-1/6 pt-8">
              {isUpdate ? (
                <div className="flex gap-2">
                  <button
                    className="text-red rounded-lg border  bg-gray-200 px-4 py-2"
                    onClick={() => {
                      setContent({ qty: "", item: "", expiryDate: "" });
                      setIsUpdate(false);
                    }}
                  >
                    <FcCancel />
                  </button>
                  <button
                    onClick={updateContent}
                    className="w-full rounded-lg border border-gray-700 bg-green-600 px-4 py-2"
                  >
                    Save
                  </button>
                </div>
              ) : (
                <button
                  onClick={addContent}
                  className="rounded-lg border border-gray-700 bg-green-600 px-4 py-2"
                >
                  Add
                </button>
              )}
            </div>
          </div>
        )}
        <div className="mt-4">
          <table className="tableClass">
            <thead>
              <tr className="tableHeadRowClass">
                <th className="tableHeadCellClass ">Qty</th>
                <th className="tableHeadCellClass">Item</th>
                <th className="tableHeadCellClass">Expiry Date</th>
                <th></th>
              </tr>
            </thead>
            <tbody className="tableBodyClass">
              {contents.map((a) => {
                const expired = dayjs(a.expiryDate).isBefore(dayjs());
                return (
                  <tr
                    key={a._id}
                    className={`tableBodyRowClass ${
                      expired ? "bg-red-700 bg-opacity-30" : ""
                    }`}
                  >
                    <td className="tableBodyCellClass">{a.qty}</td>
                    <td className="tableBodyCellClass">{a.item}</td>
                    <td className={`tableBodyCellClass`}>
                      {a.expiryDate
                        ? dayjs(a.expiryDate).format("DD-MM-YYYY")
                        : "--"}
                    </td>
                    <td className="tableBodyCellClass  w-28 items-center justify-center gap-2 space-x-2 p-1 text-center ">
                      <button
                        className="rounded-lg border  bg-blue-800 p-2 text-white hover:bg-opacity-60"
                        onClick={() => {
                          setContent(a);
                          setIsUpdate(true);
                        }}
                      >
                        <FaEdit />
                      </button>

                      <button
                        className="rounded-lg border bg-red-800 p-2 text-white hover:bg-opacity-60"
                        onClick={() => openDialog(a)}
                      >
                        <MdDeleteForever />
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
