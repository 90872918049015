import React, {useState} from "react";
import {toast} from "react-hot-toast";
import {useNavigate} from "react-router-dom";
import Box from "../../components/common/Box";
import BaseDetailsForm from "./_components/BasicDetailsForm";
import API from "../../api";
import Model from "../../components/common/Model";

const AddMember = () => {
  const navigate = useNavigate();

  const [showTempPassword, setShowTempPassword] = useState(false);
  const [tempPassword, setTempPassword] = useState("");
  const [userId, setUserId] = useState("");

  const onSubmit = data => {
    return API.member.create(data).then(res => {
      toast.success("Successfully added new team member");
      if(res.tempPassword !== undefined){
        setShowTempPassword(true);
        setTempPassword(res.tempPassword);
        setUserId(res._id);
      } else {
        navigate(`/team/${res._id}`);
      }
    })
  };

  return (
    <div className="w-full p-4">
      {showTempPassword && <Model disableOutsideClick onClose={() => navigate(`/team/${userId}`)}>
        <div className={"mt-4"}>
          A new SarStuff Central account has been created for this team member.<br /><br />
          Their temporary password is <span className={"p-1 border rounded"}>{tempPassword}</span><br /><br />

          <button className={"mt-4 p-2 border rounded w-full oncall"} onClick={() => navigate(`/team/${userId}`)}>GoTo Team Member</button>
        </div>
      </Model>}
      <Box title="Add Team Member" className="p-2 md:col-span-2">
        <BaseDetailsForm onSubmit={onSubmit} onCancel={() => navigate(`/team`)} />
      </Box>
    </div>
  );
};
export default AddMember;