import React from "react";

const Box = ({
  title,
  button,
  children,
  styles = "",
  headerStyle = " text-lg ",
  className = "",
  other,
}) => {
  return (
    <div className={`m-1 w-full ${className}`}>
      <div className="dark:shadow-0 w-full rounded border-4 border-primary p-0 shadow">
        {title && (
          <div className="flex h-10 w-full items-center justify-between border-b border-primary bg-primary">
            {title && (
              <header className={`align-center rounded-t-md px-2 tracking-wider text-white ${headerStyle}`}>{title}</header>
            )}
            {other && other}
            {button && (
              <button
                onClick={button.action}
                className={`has-tooltip m-2 px-2 py-1 text-white hover:text-gray-200 ${button.colourOverride} flex items-center gap-1 rounded-lg text-center hover:bg-opacity-80`}
              >
                {button.icon}
                {button.text}
              </button>
            )}
          </div>
        )}
        <div className={`${styles}`}>{children}</div>
      </div>
    </div>
  );
};
export default Box;
