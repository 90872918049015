import React, { useState, useEffect } from "react";
import Input from "../../../components/common/Input";
import DOB from "../../../components/common/DOB";
import ToggleSwitch from "../../../components/common/ToggleSwitch";

const BaseDetailsForm = ({ isEdit, member = {}, onSubmit, onCancel }) => {
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    phoneSecondary: "",
    email: "",
    callsign: "",
    isArchived: false,
  });
  const [formError, setFormError] = useState(null);
  const [formFieldError, setFormFieldError] = useState({});

  useEffect(() => {
    if (member._id !== undefined) {
      setData(member);
    }
  }, [member]);

  const onChange = (e, forceLowercase = false) => {
    setData((prevState) => {
      return {
        ...prevState,
        [e.target.name]: forceLowercase
          ? e.target.value.toLowerCase().trim()
          : e.target.value,
      };
    });
  };

  const handleSubmit = () => {
    const errors = {};
    if (data.firstName.trim().length === 0) {
      errors.firstName = "First Name is required";
    }
    if (data.lastName.trim().length === 0) {
      errors.lastName = "Surname is required";
    }
    if (data.phone.trim().length === 0) {
      errors.phone = "Phone Number is required";
    }
    if (data.email.trim().length === 0) {
      errors.email = "Email Address is required";
    }

    setFormFieldError(errors);
    if (Object.keys(errors).length === 0) {
      setFormError(null);
      // toast("sending details");
      onSubmit(data)
        .then(() => {})
        .catch((err) => {
          setFormError(
            err.isAxiosError ? err.response.data.message : err.message
          );
        });
    }
  };

  return (
    <>
      {formError && <div className={"p-2 text-red-500"}>{formError}</div>}
      <div className="space-y-3 p-2 text-zinc-400 sm:col-span-2">
        <div className="flex flex-wrap items-center gap-2 md:flex-nowrap">
          <div className="w-full">
            <Input
              label="First Name*"
              field={"firstName"}
              value={data.firstName}
              error={formFieldError.firstName}
              onChange={onChange}
            />
          </div>
          <div className="w-full">
            <Input
              label="Surname*"
              field={"lastName"}
              value={data.lastName}
              error={formFieldError.lastName}
              onChange={onChange}
            />
          </div>
          <div className="w-full">
            <Input
              label="Nick Name"
              field={"nickName"}
              value={data.nickName}
              onChange={onChange}
            />
          </div>
        </div>
        <div className="flex flex-wrap items-center gap-2 md:flex-nowrap">
          <div className="flex w-full items-center gap-2">
            {/*<PhoneNumberField*/}
            {/*  label="Mobile Phone Number*"*/}
            {/*  country={Authentication.userData.organisation ? Authentication.userData.organisation.country.toLowerCase() : "gb"}*/}
            {/*  value={data.phone}*/}
            {/*  onChange={a => {*/}
            {/*    setData((prevState) => {*/}
            {/*      return { ...prevState, phone: a }*/}
            {/*    });*/}
            {/*  }} />*/}
            <Input
              label="Mobile Phone Number*"
              field={"phone"}
              value={data.phone}
              error={formFieldError.phone}
              onChange={onChange}
            />
          </div>
          <div className="flex w-full items-center gap-2">
            <Input
              label="Secondary Mobile Phone Number"
              field={"phoneSecondary"}
              value={data.phoneSecondary}
              onChange={onChange}
            />
          </div>

          <div className=" w-full items-center gap-2 text-left">
            <Input
              label="Callsign"
              field={"callsign"}
              value={data.callsign}
              onChange={onChange}
            />
          </div>
          <div className="w-full items-center gap-2 text-left">
            <Input
              label="Force ID"
              field={"forceId"}
              value={data.forceId}
              onChange={onChange}
            />
          </div>
        </div>

        <div className="flex flex-wrap items-center gap-2 md:flex-nowrap">
          {!isEdit && (
            <div className="flex w-full items-center gap-2">
              <Input
                label="Primary / SarStuff Central Login Email Address*"
                field={"email"}
                value={data.email}
                error={formFieldError.email}
                onChange={(e) => onChange(e, true)}
              />
            </div>
          )}
          <div className="flex w-full items-center gap-2">
            <Input
              label="Secondary Email Address"
              field={"email_secondary"}
              value={data.email_secondary}
              onChange={(e) => onChange(e, true)}
            />
          </div>
        </div>
        {/* <div className="flex items-center gap-2"> */}
        <div className="grid gap-2 lg:grid-cols-3">
          <DOB date={member.dob} submit={(d) => setData({ ...data, dob: d })} />
          <DOB
            label="Date Joined"
            diffLabel="Years"
            date={data.dateJoined}
            submit={(d) => setData({ ...data, dateJoined: d })}
            hideAge={false}
          />
          <div className="">
            <div className="">
              <label
                htmlFor={"date"}
                className={`mb-1 block  dark:text-zinc-400 `}
              >
                Archive the member
              </label>
              <ToggleSwitch
                isChecked={data.isArchived}
                label1={"No"}
                label2={"Yes"}
                onChange={() => {
                  setData({
                    ...data,
                    isArchived: !data.isArchived,
                  });
                }}
              />
            </div>
            {data.isArchived && (
              <div className="">
                <label
                  htmlFor={"date"}
                  className={`mb-1 block  dark:text-zinc-400 `}
                >
                  Keep the account?
                </label>
                <ToggleSwitch
                  isChecked={data.keepAccount}
                  label1={"No"}
                  label2={"Yes"}
                  onChange={() => {
                    setData({
                      ...data,
                      keepAccount: !data.keepAccount,
                    });
                  }}
                />
              </div>
            )}
          </div>
        </div>
        {/* </div> */}
      </div>
      <div className="mt-4 flex justify-between border-t border-zinc-700 p-4">
        <button onClick={onCancel} className="nonOp rounded p-2">
          Cancel
        </button>
        <button onClick={() => handleSubmit()} className="oncall rounded p-2">
          {isEdit ? "Update" : "Create"}
        </button>
      </div>
    </>
  );
};
export default BaseDetailsForm;
