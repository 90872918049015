import React, { useState, useEffect } from "react";
import api from "../../api";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import Box from "../../components/common/Box";

export default function ContentsReport() {
  const navigate = useNavigate();

  const [contents, setContents] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    api.assets.reports.getContentReport().then((res) => {
      setContents(res);
      setLoading(false);
    });
  };
  // console.log(loading);
  return (
    <div className="p-4">
      <Box title="Asset Contents Expiry Report">
        {!loading ? (
          <table className="tableClass">
            <thead>
            <tr className="tableHeadRowClass">
              <th className="tableHeadCellClass ">Qty</th>
              <th className="tableHeadCellClass ">Item</th>
              <th className="tableHeadCellClass text-center">Expiry Date</th>
              <th className="tableHeadCellClass">Asset</th>
            </tr>
            </thead>
            <tbody className="tableBodyClass">
            {contents.map((a) => {
              const expired = dayjs(a.expiryDate).isBefore(dayjs());
              return (
                <tr key={a._id} className={`tableBodyRowClass `}>
                  <td className={`tableBodyCellClass  w-20 text-center`}>
                    {a.qty}
                  </td>

                  <td className={`tableBodyCellClass  p-2 `}>{a.item}</td>
                  <td
                    className={`tableBodyCellClass w-32 text-center ${
                      expired ? "bg-red-900  text-red-300" : ""
                    } p-2 `}
                  >
                    {a.expiryDate
                      ? dayjs(a.expiryDate).format("DD-MM-YYYY")
                      : "--"}
                  </td>
                  <td
                    onClick={() => navigate(`/asset/view/${a.asset._id}`)}
                    className={`tableBodyCellClass  flex cursor-pointer gap-2 p-2 hover:bg-opacity-50`}
                  >
                    {a.asset.name} - {a.asset.assetNum}
                  </td>
                </tr>
              );
            })}
            </tbody>
          </table>
        ) : (
          <div>Loading asset contents...</div>
        )}
      </Box>
    </div>
  );
}
