import React, { useEffect, useMemo, useState } from "react";
import DatePicker from "react-datepicker";
import DayJS from "dayjs";
import { toast } from "react-hot-toast";
import Box from "../../../components/common/Box";
import Input from "../../../components/common/Input";
import Select from "../../../components/common/Select";
import ToggleSwitch from "../../../components/common/ToggleSwitch";
import TextArea from "../../../components/common/TextArea";
import ChainedContext from "../../../contexts/chainedContext";
import SettingsReceiver from "../../../contexts/settings_context/Settings_Receiver";
import OrganisationReceiver from "../../../contexts/organisation/receiver";
import LocationSelector from "./LocationSelector";
import API from "../../../api";
import {TrashIcon} from "@heroicons/react/20/solid";
import ConfirmBox from "../../../components/common/Confirm";
import {useNavigate} from "react-router-dom";

const EventEntryForm = ({
  onSubmit,
  onCancel,
  isClone = false,
  isEdit = false,
  data,
  startAt = "",
  endAt = "",
  settings,
  organisation,
  eventTypes
}) => {
  const navigate = useNavigate();

  const [start, setStart] = useState(startAt);
  const [end, setEnd] = useState(endAt);
  const [type, setType] = useState("");
  const [title, setTitle] = useState("");
  const [details, setDetails] = useState("");
  const [organiser, setOrganiser] = useState("");
  const [organiserID, setOrganiserID] = useState("");
  const [note, setNote] = useState("");
  const [signUp, setSignUp] = useState(true);
  const [allDay, setAllDay] = useState(false);
  const [members, setMembers] = useState([]);
  const [location, setLocation] = useState({});
  const [deleteWarning, setDeleteWarning] = useState(false);


  useEffect(() => {
    API.member.allCache().then((res) => {
      setMembers(
        res.map((m) => {
          return {
            key: m._id,
            text: m.firstName + " " + m.lastName,
            value: m._id,
          };
        })
      );
    });
  }, []);

  useEffect(() => {
    if (data === undefined || data._id === undefined) return;

    setTitle(data.title);
    setStart(data.start);
    setEnd(data.end);
    setDetails(data.description);
    setOrganiser(data.organiser);
    if(data.organiserID !== undefined && data.organiserID._id !== undefined){
      setOrganiserID(data.organiserID._id);
    }
    setLocation({
      type: "Feature",
      geometry: data.geometry ? data.geometry : {},
      properties: data.properties ? data.properties : {},
    });
    setType(data.eventType);
    setSignUp(data.canSignUp ? data.canSignUp : false);
    setNote(data.note);
    setAllDay(data.allDay ? data.allDay : false);
  }, [data]);

  const handleSubmit = () => {
    if (DayJS(start).isAfter(DayJS(end))) {
      toast.error("End time & date must be grater then the start time & date.");
      return;
    }

    onSubmit({
      start,
      end,
      description: details,
      title,
      note,
      organiser,
      organiserID,
      // location,
      properties: location.properties,
      geometry: location.geometry,
      allDay,
      eventType: type,
      canSignUp: signUp,
    });
  };
  const handleDelete = () => {
    API.calendar.deleteEvent(data._id).then(() => {
      navigate(`/recruitment/events`);
      toast.success("Successfully deleted event");
    })
  }

  return (
    <>
      {deleteWarning && <ConfirmBox show title={"Are you sure you would like to permanently delete the selected event."} action={handleDelete} onClose={() => setDeleteWarning(false)}/>}
      <div className="grid gap-2 sm:grid-cols-2">
        <Box title="Details">
          <div className="space-y-4 p-2">
            <Input
              label="Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <div className="grid gap-2 sm:grid-cols-2">
              <div className="w-full">
                <label htmlFor={"date"} className={`block dark:text-zinc-400`}>
                  Start
                </label>
                <DatePicker
                  onChange={(date) => setStart(date)}
                  selected={start ? new Date(start) : ""}
                  dateFormat="dd-MM-yy HH:mm"
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={30}
                  nextMonthButtonLabel=">"
                  previousMonthButtonLabel="<"
                  className=" w-full rounded border border-pp bg-white p-1 dark:border-zinc-500  dark:bg-zinc-700"
                  data-disable-touch-keyboard
                />
              </div>
              <div className="w-full">
                <label htmlFor={"date"} className={`block dark:text-zinc-400`}>
                  End
                </label>
                <DatePicker
                  onChange={(date) => setEnd(date)}
                  selected={end ? new Date(end) : start ? new Date(start) : ""}
                  minDate={start}
                  dateFormat="dd-MM-yy HH:mm"
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={30}
                  nextMonthButtonLabel=">"
                  previousMonthButtonLabel="<"
                  className=" w-full rounded border border-pp bg-white p-1 dark:border-zinc-500  dark:bg-zinc-700"
                  data-disable-touch-keyboard
                />
              </div>
            </div>
            <div className="grid items-center gap-2 sm:grid-cols-2">
              <Select
                label={"Type"}
                options={eventTypes}
                value={type}
                onChange={(e) => setType(e.target.value)}
              />
              <div className="px-1">
                <label
                  htmlFor={"date"}
                  className={`mb-1 block  dark:text-zinc-400 `}
                >
                  All day event
                </label>
                <ToggleSwitch
                  posColour="bg-green-800"
                  negColour="bg-red-800"
                  isChecked={allDay}
                  onChange={() => setAllDay(!allDay)}
                />
              </div>
            </div>
            <TextArea
              label="Details"
              value={details}
              onChange={(e) => setDetails(e.target.value)}
            />
            <div className="flex justify-around gap-2">
              <Select
                label={"Organiser"}
                options={members}
                value={organiserID}
                onChange={(e) => {
                  setOrganiser(
                    members.filter((m) => {
                      return m.value === e.target.value;
                    })[0].text
                  );
                  setOrganiserID(e.target.value);
                }}
              />
            </div>
            <TextArea
              label="Private Notes"
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
          </div>
        </Box>
        <Box title="Location">
          <LocationSelector
            location={location}
            onChange={setLocation}
            organisation={organisation}
            settings={settings}
          />
        </Box>
      </div>
      <div className="mt-4 flex justify-between border-t border-zinc-500 pt-4">
        <button
          className="rounded-lg bg-red-500 px-4 py-2 text-white"
          onClick={onCancel}
        >
          Cancel
        </button>
        {isEdit && <button
          className="rounded-md border bg-opacity-80 px-2 py-1 text-sm text-white border-red-400 bg-red-700 hover:border-red-300 hover:bg-red-800"
          onClick={() => setDeleteWarning(true)}
        >
          <TrashIcon className="h-6 w-6 text-red-200"/>
        </button>}
        <button
          className="rounded-lg bg-green-500 px-4 py-2 text-white"
          onClick={handleSubmit}
        >
          {isEdit ? "Update" : "Add"}
        </button>
      </div>
    </>
  );
};
export default ChainedContext(EventEntryForm, [
  [SettingsReceiver, "settings"],
  [OrganisationReceiver, "organisation"],
]);
