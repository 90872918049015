import Select from "../../../components/common/Select";
import React, {useEffect, useMemo, useState} from "react";
import {APIRequest} from "../../../lib/_sso";
import {toast} from "react-hot-toast";
import AppConfig from "../../../_appConfig";
import DayJS from "dayjs";
import {useSocketIo} from "../../../lib/socketio-with-auth";

const server = AppConfig.api_server;

const AssetsPatch = ({ config, auth }) => {
  const socketIo = useSocketIo();

  const [isImportRunning, setIsImportRunning] = useState(false);
  const [organisations, setOrganisations] = useState([]);

  const [pullFrom, setPullFrom] = useState("");
  const [organisation, setOrganisation] = useState("");
  const [lookupTable, setLookupTable] = useState("");

  const [logs, setLogs] = useState([
    [new Date(), "Importer is ready"]
  ]);

  useEffect(() => {
    APIRequest.get(`${server}/settings/importer/assets_fix/local_orgs?token=${auth.token}`).then(res => {
      setOrganisations(res.data);
      // setIsLoadingHeaderData(false);
    }).catch(() => {
      setOrganisations([]);
      // setIsLoadingHeaderData(false);
      toast.error("Unable to retrieve overview data from TeamSite")
    })
  }, [auth.token]);
  useEffect(() => {
    if (!socketIo) return;

    const handleNewLogEntry = (logData) => {
      setLogs((currentLogs) => {
        return [logData].concat(currentLogs);
      });
    };
    const handleComplete = (data) => {
      setIsImportRunning(false);
    }

    socketIo.on("import:progress", handleNewLogEntry);
    socketIo.on("import:complete", handleComplete);
    return () => {
      socketIo.removeListener("import:progress", handleNewLogEntry);
      socketIo.removeListener("import:complete", handleComplete);
    }
  }, [socketIo])

  const lookupTableObj = useMemo(() => {
    if(lookupTable !== "") return JSON.parse(lookupTable);

    return {};
  }, [lookupTable])

  const handleDataImport = () => {
    setIsImportRunning(true);
    setLogs([]);

    const lookupTable = {
      oldAssetIds: lookupTableObj?.oldAssetIds || {},
      oldMemberIds: lookupTableObj?.oldMemberIds || {},
      oldVehicleIds: lookupTableObj?.oldVehicleIds || {}
    }

    socketIo.emit("import:teamsite:asset_patch", {
      token: auth.token,
      organisationId: organisation,
      teamsiteId: pullFrom,
      lookupTable
    });
  }

  return (
    <div>
      <div>Fix member asset assignment</div>

      <div>
        <label className="labelClass">Which Team</label>
        <div>
          <Select
            disabled={isImportRunning}
            options={organisations.sort((a, b) => {
              if (a.name > b.name) return 1;
              if (a.name < b.name) return -1;
              return 0;
            }).map(item => {
              return {
                key: item._id,
                value: item._id,
                text: item.name
              }
            })}
            value={organisation}
            onChange={(e) => setOrganisation(e.target.value)}
          />
        </div>
        {organisation !== "" && <>


          <div className={"pt-2"}>
            <label className="labelClass">Pull Data From</label>
            <Select
              disabled={isImportRunning}
              options={config.pullFrom.map(item => {
                return {
                  key: item.key,
                  text: `${item.type} | ${item.server}`,
                  value: item.key
                }
              })}
              value={pullFrom}
              onChange={(e) => setPullFrom(e.target.value)}
            />
          </div>

          <div className={"pt-2"}>
            <label className="labelClass">Prior import ID lookup table</label>
            <textarea rows={5}
                      className={"w-full border rounded dark:text-black p-2"}
                      onChange={e => setLookupTable(e.target.value)}
            ></textarea>
          </div>

          <div className={"border rounded my-2 p-1 overflow-y-auto h-60"}>{logs.map((item, index) => {
            return (<div key={index} className={"pb-1"}>
              <span className={"text-sm mr-3"}>{DayJS(item[0]).format("DD-MM-YYYY hh:mm:sss a")}</span> {item[1]}
            </div>);
          })}</div>
          <div className="text-right">
            <button
              disabled={isImportRunning}
              onClick={handleDataImport}
              className="h-10 cursor-pointer rounded-md border border-green-500 bg-green-300 bg-opacity-40 px-4 py-2 text-white hover:border-green-300 disabled:cursor-not-allowed disabled:opacity-50"
            >Begin</button>
          </div>
        </>}
      </div>
    </div>
  );
}
export default AssetsPatch;