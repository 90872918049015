import {Dialog, Transition} from "@headlessui/react";
import React from "react";

const Model = ({title, show = true, onClose = () => {}, children}) => {
  return (
    <Transition
      show={show}
      enter="transition duration-100 ease-out"
      enterFrom="transform scale-95 opacity-0"
      enterTo="transform scale-100 opacity-100"
      leave="transition duration-75 ease-out"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-95 opacity-0"
    >
      <Dialog open={show} onClose={() => onClose()} className="fixed inset-0 z-10 overflow-y-auto">
        <div className="min-h-screen text-center">
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-80" />
          <span className="h-screen" aria-hidden="true">&#8203;</span>
          <div className="my-8 inline-block h-1/2 w-full max-w-7xl transform rounded-2xl border-4 border-gray-700 bg-gray-50 p-6 text-left align-middle shadow-xl transition-all dark:bg-zinc-700">
            {title && <div className="pt-4 text-center">
              <Dialog.Title className={"text-2xl"}>{title}</Dialog.Title>
            </div>}
            {children}
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
export default Model;