import React, {useEffect, useMemo, useState, useCallback} from 'react';
import DayJS from "dayjs";
import {useNavigate} from "react-router-dom";
import {toast} from "react-hot-toast";
import Box from "../../components/common/Box";
import Stat from "../../components/common/Stat";
import api from "../../api";
import Modal from "../../components/common/Modal";
import DataRow from "../../components/common/DataRow";
import Button from "../../components/common/Button";

const ImportIncidents = () => {
  const navigation = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [sarSysIncidents, setSarsSysIncidents] = useState([]);
  const [noSarSys, setNoSarSys] = useState(false);
  const [sarSysIncident, setSarsSysIncident] = useState({});

  const stats = useMemo(() => {
    return {
      total: sarSysIncidents.length,
      thisYear: sarSysIncidents.reduce((acc, incident) => {
        if(DayJS(incident.created).year() === DayJS().year()){
          return acc + 1;
        }
        return acc;
      }, 0),
      thisMonth: sarSysIncidents.reduce((acc, incident) => {
        if(DayJS(incident.created).year() === DayJS().year() && DayJS(incident.created).month() === DayJS().month()){
          return acc + 1;
        }
        return acc;
      }, 0)
    }
  }, [sarSysIncidents]);

  const getData = useCallback(() => {
    api.incidents.sarsys.allIncidents().then(res => {
      if(res.error !== undefined){
        setNoSarSys(true);
        return;
      }
      setSarsSysIncidents(res);
    }).finally(() => {
      setIsLoading(false)
    });
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  const handleBeginImport = (_id) => {
    api.incidents.sarsys.viewIncident(_id).then(res => {
      setSarsSysIncident(res);
    })
  };
  const handleIncidentImport = () => {
    api.incidents.sarsys.importIncident(sarSysIncident.sarsysId).then(() => {
      getData();
      toast.success("Successfully imported SarSYS incident into TeamSite.");
      setSarsSysIncident({});
    }).catch(err => {
      if(err.isAxiosError){
        if(err.response.data.message !== undefined){
          toast.error(err.response.data.message);
          return;
        }

        toast.error("There was a network error while submitting your data, please try again shortly or check your internet connection.");
        return;
      }
      toast.error("There was an unexpected error while processing your request.");
    });
  }

  const renderImportModel = () => {
    if(sarSysIncident.sarsysId === undefined) return null;

    return (
      <Modal onClose={() => setSarsSysIncident({})}>
        <h1 className={"text-2xl"}>View SarSYS Incident</h1>
        <h3 className={"text-xl mb-4"}>{sarSysIncident.name}</h3>

        <div className={"w-full "}>
          {sarSysIncident.periods.map(p => {
            return (
              <div className={"border p-2"} key={p.refNum}>
                <DataRow label={"Ref/Period Num"} value={p.refNum}/>

                <div className={"flex gap-2"}>
                  <div className={"w-full"}>
                    <DataRow label={"Start Date"} value={DayJS(p.start).format("DD-MM-YYYY HH:mm")}/>
                  </div>
                  <div className={"w-full"}>
                    <DataRow label={"Resources"} value={p.members.length}/>
                  </div>
                  <div className={"w-full"}>
                    <DataRow label={"End Date"} value={DayJS(p.end).format("DD-MM-YYYY HH:mm")}/>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <DataRow label={"Outcome"} value={sarSysIncident.outcome}/>

        <div className="p-2">
          <Button text={"Import"} onClick={handleIncidentImport} colour="bg-green-700 text-white"/>
        </div>
      </Modal>
    );
  };

  if(noSarSys){
    return (
      <div className="w-full p-2">
        <div className={"text-red-500"}>Your team currently does not have SarSYS/SarSYS [CAD].</div>
      </div>
    );
  }

  return (
    <div className="w-full p-2">
      {renderImportModel()}
      <Box title="SarSYS Incidents">
        {isLoading ? <div className={"p-2"}>Loading...</div> : <>
          <div className="flex justify-around gap-4 p-2">
            <Stat label={"All Time"} value={stats.total}/>
            <Stat label={"This Year"} value={stats.thisYear}/>
            <Stat label={"This Month"} value={stats.thisMonth}/>
            <Stat label={"Total Imported"} value={stats.thisMonth}/>
          </div>
          <div>
            <table className="tableClass">
              <thead className="tableHeadClass">
              <tr className="tableHeadRowClass">
                <th className="tableHeadCellClass w-40">Date</th>
                <th className="tableHeadCellClass">Name</th>
                <th className="tableHeadCellClass w-32">Type</th>
                <th className="tableHeadCellClass w-40">Total Op Periods</th>
                <th className="tableHeadCellClass w-40">Total Resources</th>
                <th className="tableHeadCellClass">Outcome</th>
                <th className="tableHeadCellClass w-32"></th>
              </tr>
              </thead>
              <tbody className={"tableBodyClass"}>{sarSysIncidents.map(item => {
                return (
                  <tr key={item._id} className={"tableBodyRowClass"}>
                    <td className={"tableBodyCellClass"}>{DayJS(item.created).format("DD-MM-YYYY HH:mm")}</td>
                    <td className={"tableBodyCellClass"}>{item.name}</td>
                    <td className={"tableBodyCellClass uppercase"}>{item.type}</td>
                    <td className={"tableBodyCellClass"}>{item._totalOpPeriods}</td>
                    <td className={"tableBodyCellClass"}>{item._totalResources}</td>
                    <td className={"tableBodyCellClass"}>{item.outcome}</td>
                    <td className={"tableBodyCellClass"}>
                      {item._localId !== false ?
                        <button className={"border p-2 w-full"} onClick={() => navigation(`/incidents/view/${item._localId}`)}>View</button> :
                        <button className={"border p-2 w-full text-white bg-blue-500"} onClick={() => handleBeginImport(item._id)}>View for Import</button>
                      }
                    </td>
                  </tr>
                );
              })}</tbody>
            </table>
          </div>
        </>}
      </Box>
    </div>
  );
}
export default ImportIncidents;