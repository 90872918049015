import React, { Fragment, useMemo } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { PlusIcon } from "@heroicons/react/20/solid";
import { NavLink } from "react-router-dom";
import ChainedContext from "../../../../contexts/chainedContext";
import MemberReceiver from "../../../../contexts/member_context/Member_Receiver";
import SettingsReceiver from "../../../../contexts/settings_context/Settings_Receiver";
import OrganisationReciever from "../../../../contexts/organisation/receiver";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function MemberNav({ member, settings }) {
  const navigation = [
    { name: "Dashboard", href: `/team/${member._id}`, exact: true },
    { name: "Details", href: `/team/${member._id}/details`, exact: true },
    { name: "Medical", href: `/team/${member._id}/medical`, exact: true },
    { name: "Locations", href: `/team/${member._id}/locations`, exact: false },
    {
      name: "Competencies",
      href: `/team/${member._id}/competencies`,
      exact: true,
    },
    { name: "CPD", href: `/team/${member._id}/cpd`, exact: true },
    { name: "Courses", href: `/team/${member._id}/courses`, exact: false },
    { name: "Deployments", href: `/team/${member._id}/deployments`, exact: false },
    { name: "Assets", href: `/team/${member._id}/assets`, exact: false },
  ];
  return (
    <Disclosure
      as="nav"
      className="border-b-2 border-gray-300 bg-gray-50 dark:border-primary dark:bg-zinc-800"
    >
      {({ open }) => (
        <>
          <div className="mx-auto  px-4 sm:px-2 lg:px-4">
            <div className="flex h-16 justify-between">
              <div className="flex">
                <div className="-ml-2 mr-2 flex items-center md:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-900 hover:bg-gray-700 hover:text-white dark:text-white ">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                    <div className="px-4">Member Menu</div>
                  </Disclosure.Button>
                </div>
                <div className="hidden text-primary dark:text-white md:ml-2 md:items-center xl:flex xl:space-x-2">
                  {member.firstName} {member.lastName}{" "}
                  {member.callsign && "- (" + member.callsign + ")"}
                </div>
                <div className="hidden md:flex md:items-center xl:ml-4 xl:space-x-2">
                  {navigation.map((item) => {
                    return (
                      <NavLink
                        key={item.name}
                        to={item.href}
                        end={item.exact}
                        className={({ isActive }) =>
                          isActive
                            ? "relative rounded border bg-gray-200 px-3 py-2 text-primary dark:border-zinc-600 dark:bg-zinc-700 dark:text-white"
                            : "relative rounded-md px-3 py-2 text-gray-500 hover:bg-primary hover:text-zinc-300 dark:text-zinc-400"
                        }
                        // className={classNames(item.current ? "bg-gray-900 text-white" : "text-gray-900 hover:bg-primary hover:text-white", "px-3 py-2 rounded-md ")}
                        aria-current={item.current ? "page" : undefined}
                      >
                        {item.name}{" "}
                        {item.badge !== undefined && (
                          <span
                            className={` ml-2 rounded-full  border border-gray-400 bg-gray-100 px-1.5 pb-0.5 pt-1 align-middle text-sm font-bold text-primary shadow `}
                          >
                            {item.badge}
                          </span>
                        )}
                      </NavLink>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="md:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2 sm:px-2">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    item.current
                      ? "bg-gray-900 text-white"
                      : "text-gray-300 hover:bg-gray-700 hover:text-white",
                    "block rounded-md px-3 py-2 text-base font-medium"
                  )}
                  aria-current={item.current ? "page" : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
export default ChainedContext(MemberNav, [
  [MemberReceiver, "member"],
  [SettingsReceiver, "settings"],
  [OrganisationReciever, "organisation"],
]);
