import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import { Tab } from "@headlessui/react";
import Toast, {toast} from "react-hot-toast";
import DayJS from "dayjs";
import Box from "../../../components/common/Box";
import DataRowRoles from "../../../components/common/DataRowRoles";
import { Skeleton } from "../../../components/ui/skeleton";
import API from "../../../api";
import { ArrowsUpDownIcon } from "@heroicons/react/20/solid";
import Modal from "../../../components/common/Modal";
import TextArea from "../../../components/common/TextArea";
import Select from "../../../components/common/Select";
import ConfirmBox from "../../../components/common/Confirm";
import MappingDefaults from "../../../components/mapping_defaults";
import { Layers, Map } from "../../../components/sarstuff_mapping";
import * as MapStyles from "../../../components/mapping_styles";
import ChainedContext from "../../../contexts/chainedContext";
import OrganisationReceiver from "../../../contexts/organisation/receiver";
import SettingsReceiver from "../../../contexts/settings_context/Settings_Receiver";
import CourseEventEditModel from "./_components/CourseEventEditModel";
import DataRow from "../../../components/common/DataRow";
import {AuthComponents, Authentication} from "../../../lib/_sso";

const returnTabButtonStyle = (selected) => {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  return classNames(
    "w-full py-2.5 text-lg leading-5 font-medium  rounded border dark:border-zinc-500",
    selected
      ? "text-gray-100 bg-green-900 bg-opacity-50"
      : "text-blue-100 hover:bg-white/[0.12] hover:text-white bg-gray-800"
  );
};

let ManageResult = ({ course, eventId, member, onResultUpdate, settings }) => {
  const [isPass, setIsPass] = useState(true);
  const [showScoreModel, setShowScoreModel] = useState(false);
  const [comment, setComment] = useState("");
  const profile = Authentication.getUserData();

  const navigate = useNavigate();

  useEffect(() => {
    if (member._result !== null) {
      setComment(member._result.comment);
      setIsPass(member._result.isPass);
    }
  }, [member]);

  const handleSubmit = () => {
    const inProgress = Toast.loading("Updating members result...");
    API.courses.events.updateResult(eventId, member._id, {
      isPass,
      comment,
    }).then(() => {
      onResultUpdate();
      setShowScoreModel(false);
      Toast.success("Result updated successfully");
    }).catch((err) => {
      Toast.error("Unable to update result due to a server error: " + (err?.response?.data?.message || err.message));
    }).finally(() => {
      Toast.dismiss(inProgress);
    });
  };

  const handleAssignMissingRoles = () => {
    const inProgress = Toast.loading("Assigning missing roles, please wait...");
    API.courses.events.assignMemberMissingRoles(eventId, member._id).then(() => {
      toast.success("Successfully assigned missing roles.");
      onResultUpdate();
    }).catch((err) => {
      Toast.error("Unable to update result due to a server error");
    }).finally(() => {
      Toast.dismiss(inProgress);
    });
  }

  return (
    <div className="text-center">
      {showScoreModel !== false && (
        <Modal show={true} title={`${member.firstName} ${member.lastName}`} onClose={() => setShowScoreModel(false)} disableOutsideClick>
          {(member.centralId === profile.sub) ? <>
            <div className={"p-2"}>
              <div className={"text-red-500 dark:text-red-400 text-center"}>Forbidden: You can not modify your own course result.</div>
              <div className={"mt-4 flex gap-2 justify-center"}>
                <button className={"rounded p-2 nonOp border text-sm dark:border-zinc-500"} onClick={() => setShowScoreModel(false)}>Close</button>
              </div>
            </div>
          </> : <>
            {(!member._hasRole && settings.promptMissingCompetenciesRoles) && <div className={"p-2"}>
              One or more of the following role(s): <span className={"font-bold"}>{course?.roles?.join(", ")}</span> are not assigned to <strong>{member?.firstName} {member?.lastName}</strong> profile.

              <div className={"mt-4 flex gap-2 justify-between"}>
                <button className={"rounded p-2 nonOp border text-sm dark:border-zinc-500"} onClick={() => setShowScoreModel(false)}>Close</button>
                <button className={"rounded p-2 offcall border text-sm dark:border-zinc-500"} onClick={() => navigate(`/team/${member._id}`)}>Go to member</button>
                <button className={"rounded p-2 oncall border text-sm dark:border-zinc-500"} onClick={() => handleAssignMissingRoles()}>Assign missing roles</button>
              </div>
            </div>}
            {(member._hasRole || !settings.promptMissingCompetenciesRoles) && <div>
              <Select
                label="Result"
                options={[
                  { key: 1, value: "pass", text: "Pass" },
                  { key: 2, value: "fail", text: "Fail" },
                ]}
                value={isPass ? "pass" : "fail"}
                onChange={(e) => setIsPass(e.target.value === "pass")}
              />

              <TextArea
                label="Comment"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
              <div className="mt-4 flex justify-between border-t border-zinc-500 pt-4">
                <button className="rounded-lg bg-primary px-4 py-2 text-white" onClick={() => setShowScoreModel(false)}>
                  Cancel
                </button>
                <button className="rounded-lg bg-green-500 px-4 py-2 text-white disabled:bg-green-500" onClick={handleSubmit}>
                  Update
                </button>
              </div>
            </div>}
          </>}
        </Modal>
      )}
      {member._result !== null ? (
        <div className="flex items-center justify-center gap-4">
          {member._result.isPass ? (
            <span className={"text-lg text-green-500"}>Pass</span>
          ) : (
            <span className={"text-lg text-red-500"}>Fail</span>
          )}
        </div>
      ) : (
        <div className="w-full">
          No Result
        </div>
      )}

      <AuthComponents.Can scope={"course:events:manage_results"}>
        <button className={"rounded border p-2 text-sm dark:border-zinc-500"} onClick={() => setShowScoreModel(true)}>
          Change
        </button>
      </AuthComponents.Can>
    </div>
  );
};
ManageResult = ChainedContext(ManageResult, [[SettingsReceiver, "settings"]]);
const TableList = ({team, setAttendance, listType, eventId, course, onReload}) => {
  const [sortBy, setSortBy] = React.useState("surname"); // surname, firstname, callsign

  const handleSortToggle = () => {
    switch (sortBy) {
      case "surname":
        setSortBy("firstname");
        break;
      case "firstname":
        setSortBy("callsign");
        break;
      case "callsign":
        setSortBy("none");
        break;
      default:
        setSortBy("surname");
        break;
    }
  }

  const sortedList = useMemo(() => {
    if (team === undefined || team.length === 0) return [];

    return team.toSorted((a, b) => {
      if (sortBy === "surname") {
        if ((a.lastName || "") < (b.lastName || "")) return -1;
        if ((a.lastName || "") > (b.lastName || "")) return 1;
      }
      if (sortBy === "firstname") {
        if ((a.firstName || "") < (b.firstName || "")) return -1;
        if ((a.firstName || "") > (b.firstName || "")) return 1;
      }
      if (sortBy === "callsign") {
        if ((a.callsign || "zzzzzzzzzzzzzzzz") < (b.callsign || "zzzzzzzzzzzzzzzz")) return -1;
        if ((a.callsign || "zzzzzzzzzzzzzzzz") > (b.callsign || "zzzzzzzzzzzzzzzz")) return 1;
      }

      return 0;
    })
  }, [team, sortBy]);

  if (sortedList.length === 0) {
    return (
      <div className={"p-2 text-center"}>There are no items to display</div>
    );
  }

  return (
    <table className="tableClass">
      <thead>
        <tr className="tableHeadRowClass">
          <th
            onClick={() => handleSortToggle()}
            className="tableHeadCellClass flex gap-1"
          >
            Name
            {sortBy !== "none" ? (
              <><ArrowsUpDownIcon className="h-4 text-green-500"/> <span className={"text-xs lowercase"}>[{sortBy}]</span></>
            ) : (
              <ArrowsUpDownIcon className="h-4"/>
            )}
          </th>
          {listType === "attending" && (
            <th className="tableHeadCellClass">Result</th>
          )}
          <AuthComponents.Can scope={"course:events:manage_results"}>
            <th className="tableHeadCellClass">Actions</th>
          </AuthComponents.Can>
        </tr>
      </thead>
      <tbody className="tableBodyClass">
        {sortedList.map((u) => {
            return (
              <tr key={u._id} className={`tableBodyRowClass`}>
                <td className={`p-2 ${u.onCall && "oncall"} ${!u.onCall && "offcall"} ${!u.operational && "nonOp"}`}>
                  {u.firstName} {u.lastName}
                  <br />
                  {u.callsign && "(" + u.callsign + ")"}
                </td>
                {listType === "attending" && (
                  <td>
                    <ManageResult
                      course={course}
                      eventId={eventId}
                      member={u}
                      onResultUpdate={onReload}
                    />
                  </td>
                )}
                <AuthComponents.Can scope={"course:events:manage_results"}>
                  <td className={"w-40"}>
                  <div className={"flex justify-center gap-2"}>
                    {listType !== "notAttending" && (
                      <button
                        className={`nonOp rounded border p-2 text-sm dark:border-zinc-500`}
                        onClick={() => setAttendance(u, "notAttending")}
                      >
                        Not Going
                      </button>
                    )}
                    {listType !== "attending" && (
                      <button
                        className={`oncall rounded border p-2 text-sm dark:border-zinc-500`}
                        onClick={() => setAttendance(u, "attending")}
                      >
                        Going
                      </button>
                    )}
                  </div>
                </td>
                </AuthComponents.Can>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};

const ManageCourse = ({ organisation }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [showDeleteResultPrompt, setShowDeleteResultPrompt] = useState(null);
  const [showEditModel, setShowEditModel] = useState(false);
  const [team, setTeam] = useState({
    attending: [],
    notAttending: [],
    unConfirmed: [],
  });
  const [membersResults, setMembersResults] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);
  const [event, setEvent] = useState({});
  const { eventId } = useParams();
  const getData = useCallback(() => {
    Promise.all([
      API.courses.events.details(eventId),
      API.member.all(),
      API.courses.events.results(eventId),
    ])
      .then((res) => {
        setEvent(res[0]);
        setTeamMembers(res[1]);
        setMembersResults(res[2]);

        setIsLoading(false);
      })
      .catch((err) => {});
  }, [eventId]);

  const mapCenter = organisation.defaultMapCenter;

  const handleAttendanceUpdate = (member, status, force = false) => {
    setShowDeleteResultPrompt(null);
    if (status !== "attending") {
      if (member._result !== undefined && member._result !== null) {
        if (!force) {
          setShowDeleteResultPrompt({
            member,
            status,
          });
          return;
        }
      }
    }

    API.courses.events
      .updateAttendance(eventId, {
        memberId: member._id,
        status,
      })
      .then(() => {
        getData();
      });
  };

  useEffect(() => {
    getData();
  }, [getData]);
  useEffect(() => {
    if (teamMembers.length === 0) return;

    const joinedList = [].concat(event.attending, event.notAttending);

    setTeam({
      attending: event.attending.map((item) => {
        const member = teamMembers.find((a) => item === a._id);
        if(member){
          return {
            ...member,
            _hasRole: (event?.course === undefined || event?.course === null) ? false : event?.course?.roles?.filter(singleRole => member.Roles.filter(memberRole => singleRole === (memberRole?.role?.name || memberRole.name) ).length === 0).length === 0,
            _result: membersResults.find((a) => a.member === member?._id) || null,
          };
        }
        return false;
      }).filter(a => a !== false),
      notAttending: event.notAttending.map((item) =>
        teamMembers.find((a) => item === a._id)
      ).filter(a => a !== undefined),
      unConfirmed: teamMembers.filter(
        (item) => joinedList.indexOf(item._id) === -1
      ).filter(a => a !== undefined),
    });
  }, [event, teamMembers, membersResults]);

  const renderDeletePrompt = () => {
    if (showDeleteResultPrompt === null) return null;

    return (
      <ConfirmBox
        show
        title={`Changing attendance will DELETE ${showDeleteResultPrompt.member.firstName}'s result.`}
        action={() =>
          handleAttendanceUpdate(
            showDeleteResultPrompt.member,
            showDeleteResultPrompt.status,
            true
          )
        }
        onClose={() => setShowDeleteResultPrompt(null)}
      />
    );
  };

  if (isLoading) {
    return (
      <div className="space-y-6 p-4">
        <div className="flex gap-8">
          <Skeleton className="h-8 w-1/3 dark:bg-zinc-700"></Skeleton>
          <Skeleton className="h-8 w-1/3 dark:bg-zinc-700"></Skeleton>
          <Skeleton className="h-8 w-1/3 dark:bg-zinc-700"></Skeleton>
        </div>
        <div className="flex gap-8">
          <Skeleton className="h-8 w-1/2 dark:bg-zinc-700"></Skeleton>
          <Skeleton className="h-8 w-1/2 dark:bg-zinc-700"></Skeleton>
        </div>
      </div>
    );
  }
  
  return (
    <div className={"w-full space-y-2 p-4"}>
      {showDeleteResultPrompt && renderDeletePrompt()}
      {showEditModel && (
        <CourseEventEditModel
          event={event}
          onClose={() => {
            getData();
            setShowEditModel(false);
          }}
        />
      )}
      <div className={"grid grid-cols-2 gap-4"}>
        <div className={"col-span-1"}>
          <Box
            title={event.title || "[no name]"}
            button={Authentication.can("course:events:write") ? {
              action: () => setShowEditModel(true),
              text: "Edit",
              colour: "text-white hover:text-gray-200",
            } : null}
          >

            <div className="grid lg:grid-cols-2">
              <DataRow label="Course" value={event.course?.name || "[N/A]"} />
              <DataRow
                label="Organiser"
                value={
                  event.organiser !== undefined &&
                  event.organiser._id !== undefined
                    ? event.organiser.firstName + " " + event.organiser.lastName
                    : event.organiserName
                }
              />
              <DataRow
                label="Start At"
                value={DayJS(event.start).format("DD-MM-YYYY HH:mm")}
              />

              <DataRow
                label="End At"
                value={DayJS(event.end).format("DD-MM-YYYY HH:mm")}
              />
              <DataRow
                label="Can Sign Up"
                value={event.canSignUp ? "Yes" : "No"}
              />
              <DataRow
                label="Valid For"
                value={<span>{event.course?.validFor} years</span>}
              />
              <DataRowRoles roles={event.course?.roles || []} />
            </div>
            <DataRow
              label="Course Description"
              value={event.course?.description}
            />
            <DataRow label="Event Description" value={event.description} />
            <DataRow label="Notes" value={event.note} />
            {event.webLinks !== undefined && event.webLinks.length !== 0 && <div className="col-span-2 text-center lg:col-span-1">
              <span className="text-primary dark:text-primary-dark">Web Links</span>
              <div className={"p-2 flex gap-2 flex-wrap"}>
                {event.webLinks.sort((a, b) => {
                  if(a.title > b.title) return 1;
                  if(a.title < b.title) return -1;
                  return 0;
                }).map((item, index) => {
                  return (
                    <a key={index} href={item.url} target={"_new"} className={"border rounded border-black dark:border-white hover:underline py-1 px-3"}>{item.title}</a>
                  );
                })}
              </div>
            </div>}
          </Box>
          {event.location !== undefined && event.location.geometry !== undefined && <Box title={"Location"}>
            <div>
              <DataRow label="Name" value={event.location.properties?.name} />
          
              {event.location.properties?.address !== undefined && (
                <DataRow label="Address" value={event.location.properties?.address}/>
              )}
              <div className={"grid grid-cols-2"}>
                <DataRow label="Town" value={event.location.properties?.town} />
                <DataRow label="Postcode" value={event.location.properties?.postcode}/>
              </div>
          
              <Map height={350} center={mapCenter}>
                <MappingDefaults />
          
                <Layers.BoundingBox active={true}>
                  <Layers.GeoJSON
                    geoJSON={event.location}
                    zIndex={10}
                    style={MapStyles.Marker}
                  />
                </Layers.BoundingBox>
              </Map>
            </div>
          </Box>}
        </div>
        <div className={"col-span-1"}>
          <Tab.Group defaultIndex={0}>
            <Tab.List className="flex space-x-1 border-b border-zinc-200  p-1 pb-2 dark:border-zinc-700 dark:bg-blue-900/20">
              <Tab className={({ selected }) => returnTabButtonStyle(selected)}>
                Attending ({team.attending.length})
              </Tab>
              <Tab className={({ selected }) => returnTabButtonStyle(selected)}>
                Not Attending ({team.notAttending.length})
              </Tab>
              <Tab className={({ selected }) => returnTabButtonStyle(selected)}>
                Not Responded ({team.unConfirmed.length})
              </Tab>
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel>
                <Box>
                  <TableList
                    team={team.attending}
                    setAttendance={handleAttendanceUpdate}
                    listType={"attending"}
                    eventId={eventId}
                    course={event?.course}
                    onReload={getData}
                  />
                </Box>
              </Tab.Panel>
              <Tab.Panel>
                <Box>
                  <TableList
                    team={team.notAttending}
                    setAttendance={handleAttendanceUpdate}
                    listType={"notAttending"}
                  />
                </Box>
              </Tab.Panel>
              <Tab.Panel>
                <Box>
                  <TableList
                    team={team.unConfirmed}
                    setAttendance={handleAttendanceUpdate}
                    listType={"unconfirmed"}
                  />
                </Box>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    </div>
  );
};
export default ChainedContext(ManageCourse, [
  [OrganisationReceiver, "organisation"],
]);
