import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import Box from "../../components/common/Box";
import Layout from "../../components/common/Layout";
import api from "../../api";
import {Skeleton} from "../../components/ui/skeleton";
import Select from "../../components/common/Select";
import CommonFunctions from "../../lib/CommonFunctions";

const timeSpanOptions = [
  { text: "1 Month", value: 1 },
  { text: "3 Months", value: 3 },
  { text: "6 Months", value: 6 },
  { text: "12 Months", value: 12 },
];
const trainingBreakpoints = {
  red: 15,
  yellow: 25,
  green: 35,
};
const deploymentBreakpoints = {
  red: 50,
  yellow: 60,
  green: 75,
};

export default function MemberActivity() {
  const [data, setData] = useState([]);
  const [queryParams, setQueryParams] = useState({
    deploymentType: "None",
    trainingType: "None",
    timeSpan: 12,
  });
  const [trainingTypes, setTrainingTypes] = useState([]);
  const [deploymentTypes, setDeploymentTypes] = useState([]);
  const [currentParams, setCurrentParams] = useState({
    deploymentType: "None",
    trainingType: "None",
    timeSpan: 12,
  });
  const [mountLoading, setMountLoading] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (mountLoading) {
      Promise.all([
        api.settings.getIncidentTypesList(),
        api.settings.all()
      ]).then((res) => {
        if (res[0].length > 0) {
          setDeploymentTypes(res[0]);
        }

        if (res[1]) {
          // if (res[1].activityReportDeploymentBreakpoints) {
          //   setDeploymentBreakpoints(res[1].activityReportDeploymentBreakpoints);
          // }
          // if (res[1].activityReportTrainingBreakpoints) {
          //   setTrainingBreakpoints(res[1].activityReportTrainingBreakpoints);
          // }

          if (res[1].event_types !== undefined) {
            setTrainingTypes(res[1].event_types.map((item) => {
              return { text: item.name, value: item.name };
            }));
          }

          let query_params = {};

          if (res[1].activityReportDeploymentDefault !== undefined) {
            query_params.deploymentType = res[1].activityReportDeploymentDefault;
          }

          if (res[1].activityReportTrainingDefault !== undefined) {
            query_params.trainingType = res[1].activityReportTrainingDefault;
          }

          setQueryParams({ ...query_params, ...queryParams });
          setCurrentParams({ ...query_params, ...queryParams });
        }
      }).finally(() => {
        setMountLoading(false);
      });
    }
  }, [mountLoading, queryParams]);
  useEffect(() => {
    if (loading && !mountLoading) {
      api.reports.memberActivity.getResults(queryParams.trainingType, queryParams.deploymentType, queryParams.timeSpan).then((res) => {
        setCurrentParams({ ...queryParams });
        setData(res);
        setLoading(false);
      }).catch((e) => {
        console.log("ERROR");
      });
    }
  }, [loading, mountLoading, queryParams]);

  const handleCsvExport = () => {
    CommonFunctions.generateCSV(`Activity List Deployment_${currentParams.deploymentType} Event_${currentParams.trainingType}`, [
      { csv_field: "First Name", database_field: "firstName" },
      { csv_field: "Surname", database_field: "lastName" },
      { csv_field: "Callsign", database_field: "teamId" },

      { csv_field: "Last Deployment At", database_field: "last_search" },
      { csv_field: "Deployments (Last " + currentParams.timeSpan + " months)", database_field: "searches_last_period_percentage" },
      { csv_field: "Deployments Attended", database_field: "searches_last_period_attended" },

      { csv_field: "Last Event At", database_field: "last_training" },
      { csv_field: "Events (last " + currentParams.timeSpan + " months)", database_field: "training_last_period_percentage" },
      { csv_field: "Events Attended", database_field: "training_last_period_attended" },
    ], data.map(item => {
      return {
        ...item,
        last_search: `${item.last_search !== "N/A" ? dayjs(item.last_search).format("DD-MM-YY") : item.last_search}`,
        searches_last_period_percentage: `${Math.round(item.searches_last_period_percentage)}%`,

        last_training: `${item.last_training !== "N/A" ? dayjs(item.last_training).format("DD-MM-YY") : item.last_training}`,
        training_last_period_percentage: `${Math.round(item.training_last_period_percentage)}%`
      }
    }));
  }

  return (
    <Layout full>
      <div className=" grid grid-cols-1 md:grid-cols-1 gap-4 ">
        <div className="col-span-1 md:col-span-1">
          <Box>
            <div>
              <div className="inline-block w-1/3 p-2">
                <Select
                  label="Deployment Type"
                  field="deploymentType"
                  options={deploymentTypes}
                  value={queryParams.deploymentType}
                  disabled={loading === true}
                  onChange={(e) => {
                    e.preventDefault();
                    setQueryParams({
                      ...queryParams,
                      deploymentType: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="inline-block w-1/3 p-2">
                <Select
                  label="Event Type"
                  name="training_type"
                  options={trainingTypes}
                  value={queryParams.trainingType}
                  disabled={loading === true}
                  onChange={(e) => {
                    e.preventDefault();
                    setQueryParams({
                      ...queryParams,
                      trainingType: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="inline-block w-1/3 p-2">
                <Select
                  dontSort={true}
                  label="Time Span"
                  name="time_span"
                  value={queryParams.timeSpan}
                  options={timeSpanOptions}
                  disabled={loading === true}
                  onChange={(e) => {
                    e.preventDefault();
                    setQueryParams({
                      ...queryParams,
                      timeSpan: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            {(!loading &&
              (currentParams.deploymentType !== queryParams.deploymentType ||
                currentParams.timeSpan !== queryParams.timeSpan ||
                currentParams.trainingType !== queryParams.trainingType)) ||
            (!loading && data && data.length === 0) ? (
              <div className="p-2">
                <button
                  type="button"
                  className="inline w-full oncall rounded p-2"
                  onClick={() => setLoading(true)}
                >
                  Search
                </button>
              </div>
            ) : (
              <></>
            )}
          </Box>
        </div>
        {loading ? (
            <div className="flex flex-wrap justify-center gap-4 p-4">
              <Skeleton className="h-20 w-1/4 bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
              <Skeleton className="h-20 w-1/4 bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
              <Skeleton className="h-20 w-1/4 bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
            </div>
        ) : (
          <div className="col-span-1 md:col-span-1">
            <Box title={"Activity List"} button={data.length >= 1 ? {
              text: "CSV Export",
              colourOverride: "text-sm text-white hover:text-gray-200 border",
              action: () => handleCsvExport()
            } : false}>
              <table className={`tableClass`}>
                <thead>
                <tr className="tableHeadRowClass">
                  <th className="tableHeadCellClass">Name</th>
                  <th className="tableHeadCellClass w-40 text-center">Last Deployment</th>
                  <th className="tableHeadCellClass w-40 text-center">Deployments <br/>(Last {currentParams.timeSpan} Months)</th>
                  <th className="tableHeadCellClass w-40 text-center">Deployments <br/>Attended</th>
                  <th className="tableHeadCellClass w-40 text-center">Last Event</th>
                  <th className="tableHeadCellClass w-40 text-center">Events <br/>(Last {currentParams.timeSpan} Months)</th>
                  <th className="tableHeadCellClass w-40 text-center">Events <br/> Attended</th>
                </tr>
                </thead>
                <tbody className="tableBodyClass">{data.map((item, index) => {
                  let trainingPercentColor = "";
                  if(deploymentBreakpoints.red && deploymentBreakpoints.yellow && deploymentBreakpoints.green){
                    if(Math.round(item.training_last_period_percentage) <= trainingBreakpoints.red){
                      trainingPercentColor = "mar-negative";
                    } else {
                      if(Math.round(item.training_last_period_percentage) > trainingBreakpoints.red && Math.round(item.training_last_period_percentage) < trainingBreakpoints.green){
                        trainingPercentColor = "mar-warning";
                      } else {
                        if(Math.round(item.training_last_period_percentage) >= trainingBreakpoints.green && Math.round(item.training_last_period_percentage) > trainingBreakpoints.yellow){
                          trainingPercentColor = "mar-positive";
                        }
                      }
                    }
                  }

                  let deploymentPercentColor = "";
                  if(deploymentBreakpoints.red && deploymentBreakpoints.yellow && deploymentBreakpoints.green){
                    if(Math.round(item.searches_last_period_percentage) <= deploymentBreakpoints.red){
                      deploymentPercentColor = "mar-negative";
                    } else {
                      if(Math.round(item.searches_last_period_percentage) > deploymentBreakpoints.red && Math.round(item.searches_last_period_percentage) < deploymentBreakpoints.green){
                        deploymentPercentColor = "mar-warning";
                      } else {
                        if(Math.round(item.searches_last_period_percentage) >= deploymentBreakpoints.green && Math.round(item.searches_last_period_percentage) > deploymentBreakpoints.yellow){
                          deploymentPercentColor = "mar-positive";
                        }
                      }
                    }
                  }

                  return (
                    <tr key={index} className="tableBodyRowClass">
                      <td className="tableBodyCellClass">
                        {item.name}{" "}{item.teamId ? (<span className="italic">({item.teamId})</span>) : (<></>)}
                      </td>
                      <td className="tableBodyCellClass text-center">
                        {item.last_search !== "N/A" ? dayjs(item.last_search).format("DD-MM-YY") : item.last_search}
                      </td>
                      <td className="tableBodyCellClass text-center">
                        {item.searches_last_period_attended}
                      </td>
                      <td className={`tableBodyCellClass text-center ${deploymentPercentColor}`}>
                        {Math.round(item.searches_last_period_percentage)}%
                      </td>
                      <td className="tableBodyCellClass text-center">
                        {item.last_training !== "N/A" ? dayjs(item.last_training).format("DD-MM-YY") : item.last_training}
                      </td>
                      <td className="tableBodyCellClass text-center">
                        {item.training_last_period_attended}
                      </td>
                      <td className={`tableBodyCellClass text-center ${trainingPercentColor}`}>
                        {Math.round(item.training_last_period_percentage)}%
                      </td>
                    </tr>
                  );
                })}
                </tbody>
              </table>
            </Box>
          </div>
        )}
      </div>
    </Layout>
  );
}
