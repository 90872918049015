import React from "react";
import Tooltip from "./Tooltip";

export default function DataRow({
  label,
  value,
  tooltip,
  sub,
  warning,
  small = false,
}) {
  return (
    <div
      className={`has-tooltip flex w-full items-center justify-between border-b border-zinc-300 p-2 text-sm dark:border-zinc-700 xl:text-lg ${
        warning && "dark:bg-orange-10 bg-orange-600 bg-opacity-20 "
      }`}
    >
      <div className="flex items-center">
        <span className={`labelClass ${small ? "text-sm" : "text-lg"}`}>
          {label}
          {sub && (
            <span className="text-sm italic text-orange-500"> {sub}</span>
          )}
          :
        </span>
        {tooltip && (
          <>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 cursor-pointer pl-1"
              fill="none"
              viewBox="0 0 24 24"
              stroke="orange"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <Tooltip text={tooltip} />
          </>
        )}
      </div>
      <span className="px-2 text-zinc-800 dark:text-white">
        {value ? value : "--"}
      </span>
    </div>
  );
}
