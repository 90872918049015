import React, {useCallback, useState} from "react";
import API from "../../../api";
import Modal from "../../../components/common/Modal";
import Toast from "react-hot-toast";

const PopupFileDelete = ({ onClose, file }) => {
  const [submitError, setSubmitError] = useState(null);

  const handleDeleteFile = useCallback(() => {
    API.documents
    .deleteFile(file._id)
    .then(() => {
      Toast.success("Successfully deleted document.");
      onClose();
    })
    .catch((err) => {
      setSubmitError(err.response.data.message);
    });
  }, [file]);

  return (
    <Modal title={"Delete File"}>
      <p className={"text-red"}>
        Are you sure you want to permanently delete the file:{" "}
        <strong>{file.file?.filename}</strong>?
      </p>
      {submitError && <div className={"py-1 text-red-500"}>{submitError}</div>}

      <div className="mt-4 flex justify-between border-t border-zinc-500 pt-4">
        <button
          className="rounded-lg bg-primary px-4 py-2 text-white"
          onClick={onClose}
        >
          Cancel
        </button>
        <button
          className="rounded-lg bg-green-500 px-4 py-2 text-white disabled:bg-gray-500"
          onClick={handleDeleteFile}
        >
          Delete File
        </button>
      </div>
    </Modal>
  );
};
export default PopupFileDelete;