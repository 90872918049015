import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import API from "../../../api";
import Box from "../../../components/common/Box";
import Input from "../../../components/common/Input";
import DOB from "../../../components/common/DOB";
import ChainedContext from "../../../contexts/chainedContext";
import MemberReceiver from "../../../contexts/member_context/Member_Receiver";
import BaseDetailsForm from "../_components/BasicDetailsForm";

function EditMemberPage({ member }) {
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    toast.promise(
      API.member.update(member._id, data).then(() => {
        member._refresh();
        navigate(`/team/${member._id}`);
      }),
      {
        loading: "Updating...",
        success: "Successfully updated members details.",
        error: (err) => `This just happened: ${err.toString()}`,
      }
    );

    // toast.success("Successfully updated members details");
  };

  return (
    <div className="w-full space-y-2 p-4">
      <div className="grid gap-4 md:grid-cols-1">
        <Box
          title={`${member.firstName} ${member.lastName} ${
            member.callsign && "- (" + member.callsign + ")"
          }`}
        >
          <BaseDetailsForm
            member={member}
            isEdit
            onSubmit={onSubmit}
            onCancel={() => navigate(`/team/${member._id}`)}
          />
        </Box>
      </div>
    </div>
  );
}
export default ChainedContext(EditMemberPage, [[MemberReceiver, "member"]]);
