import React, { useEffect, useMemo, useState } from "react";
import Button from "../common/Button";
import AddressForm from "./forms/address";
import PostcodeForm from "./forms/postcode";
import GridForm from "./forms/grid";
import WhatThreeWordsForm from "./forms/w3w";
import MapClickForm from "./forms/mapClick";
import OtherFields from "./forms/_otherFields";
import PickableLocationsForm from "./forms/pickableLocations";
import ChainedContext from "../../contexts/chainedContext";
import SettingsReceiver from "../../contexts/settings_context/Settings_Receiver";

const LocForms = [
  { title: "Address", component: AddressForm },
  { title: "Postcode", component: PostcodeForm },
  { title: "Grid", component: GridForm },
  // {title: "Pre-defined", component: PreDefinedForm},
  { title: "W3W", component: WhatThreeWordsForm },
  { title: "Map Click", component: MapClickForm },
];

const LocationForm = ({
  location,
  setLocation,
  handleSubmit,
  handleCancel,
  onFormTypeChange,
  isEdit = false,
  miniMode = false,
  locations = [],
}) => {
  const [selectedForm, setSelectedForm] = useState(0);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    onFormTypeChange(LocForms.find((a, b) => b === selectedForm).title);
  }, [selectedForm]);

  const filteredForms = useMemo(() => {
    const list = LocForms.filter((a) => {
      if (!miniMode) return true;
      const hiddenInMiniMode = ["Grid", "W3W", "Map Click"];

      return hiddenInMiniMode.indexOf(a.title) === -1;
    });

    if (miniMode)
      list.push({ title: "Locations", component: PickableLocationsForm });

    return list;
  }, [LocForms, miniMode]);

  return (
    <>
      <div className="flex w-full items-center justify-around p-2">
        {filteredForms.map((item, index) => {
          return (
            <Button
              key={index}
              text={item.title}
              active={selectedForm === index}
              onClick={() => setSelectedForm(index)}
            />
          );
        })}
      </div>
      <div className="p-4">
        {filteredForms
          .filter((a, i) => i === selectedForm)
          .map((item, index) => {
            const TheComponent = item.component;
            return (
              <TheComponent
                key={index}
                onLocationChange={(loc) =>
                  setLocation({
                    // _id: location._id || "",
                    ...loc,
                  })
                }
                location={location}
                locations={locations}
              />
            );
          })}
        {location && (
          <OtherFields
            miniMode={miniMode}
            location={location}
            isAdd={!isEdit}
            errors={errors}
            onLocationChange={(loc) =>
              setLocation({
                _id: location._id,
                ...loc,
              })
            }
            onSubmit={() => handleSubmit(location)}
            onCancel={() => handleCancel()}
          />
        )}
      </div>
    </>
  );
};
export default ChainedContext(LocationForm, [[SettingsReceiver, "settings"]]);
