import React, {useState, useEffect} from "react";
import DatePicker from "react-datepicker";
import ToggleSwitch from "../../../components/common/ToggleSwitch";
import Input from "../../../components/common/Input";
import TextArea from "../../../components/common/TextArea";
import ChainedContext from "../../../contexts/chainedContext";
import SettingsReceiver from "../../../contexts/settings_context/Settings_Receiver";
import DayJS from "dayjs";
import ConfirmBox from "../../../components/common/Confirm";
import {TrashIcon} from "@heroicons/react/20/solid";

const ChecklistForm = ({settings, vehicle_id, editLog = {}, onDelete, onCancel, onSubmit}) => {
  const [error, setError] = useState({});
  const [data, setData] = useState({
    date: new Date(),
  });
  const [checkResponses, setCheckResponses] = useState({});
  const [deleteWarning, setDeleteWarning] = useState(false);

  useEffect(() => {
    if(editLog._id !== undefined){
      console.log(editLog);

      const theChecks = {};
      editLog.checks.forEach(a => {
        theChecks[a.name] = a.value;
      })
      setCheckResponses(theChecks);
      setData({
        date: DayJS(editLog.date).toDate(),
        finish: editLog.finish,
        start: editLog.start,
        notes: editLog.notes
      })
    }

  }, [editLog]);

  const handleSubmit = () => {
    onSubmit({
      ...data,
      checks: checkResponses,
      vehicle: vehicle_id,
    });
  }
  const handleDelete = () => {
    onDelete();
  }

  return (
    <>
      {deleteWarning && <ConfirmBox show title={"Are you sure you would like to permanently delete the selected vehicle log entry."} action={handleDelete} onClose={() => setDeleteWarning(false)} />}
      <div className="w-full">
        <label htmlFor={"date"} className={`block dark:text-zinc-400`}>Date</label>
        <DatePicker
          onChange={(date) => {
            setData((prevState) => {
              return {...prevState, date: date};
            });
          }}
          selected={data.date ? new Date(data.date) : new Date()}
          showTimeSelect
          timeFormat="HH:mm"
          timeIntervals={5}
          timeInputLabel="Time:"
          dateFormat="dd-MM-yy HH:mm"
          nextMonthButtonLabel=">"
          previousMonthButtonLabel="<"
          className="w-full rounded border border-pp bg-white p-1 dark:border-zinc-500 dark:bg-zinc-700"
          data-disable-touch-keyboard
        />
      </div>
      <ul className="space-y-4">
        {settings.vehicleChecklist?.map((c) => {
          const currentVal = checkResponses[c] !== undefined ? checkResponses[c] : false;
          return (
            <li key={c} className="flex justify-between p-2">
              {c}{" "}
              <ToggleSwitch
                onChange={(e) => {
                  setCheckResponses((prevState) => {
                    return {
                      ...prevState,
                      [c]: e,
                    };
                  });
                }}
                isChecked={currentVal}
              />
            </li>
          );
        })}
      </ul>
      <Input
        label={"Starting Mileage"}
        value={data.start}
        onChange={(e) =>
          setData((prevState) => {
            return {...prevState, start: e.target.value};
          })
        }
      />
      <Input
        label={"Finished Mileage"}
        value={data.finish}
        onChange={(e) =>
          setData((prevState) => {
            return {...prevState, finish: e.target.value};
          })
        }
      />
      <TextArea
        label={"Notes"}
        value={data.notes}
        onChange={(e) =>
          setData((prevState) => {
            return {...prevState, notes: e.target.value};
          })
        }
      />
      {/* {error && (
       <div className="py-4 text-xl text-red-500">{error}</div>
       )} */}

      <div className="mt-4 flex justify-between border-t border-zinc-500 pt-4">
        <button className="rounded-lg bg-primary px-4 py-2 text-white" onClick={() => onCancel()}>
          Cancel
        </button>
        {editLog._id !== undefined && <button
          className="rounded-md border bg-opacity-80 px-2 py-1 text-sm text-white border-red-400 bg-red-700 hover:border-red-300 hover:bg-red-800"
          onClick={() => setDeleteWarning(true)}
        >
          <TrashIcon className="h-6 w-6 text-red-200" />
        </button>}
        <button className="rounded-lg bg-green-500 px-4 py-2 text-white disabled:bg-red-500" onClick={() => handleSubmit()}>
          Update
        </button>
      </div>

    </>
  );
}
export default ChainedContext(ChecklistForm, [
  [SettingsReceiver, "settings"],
]);