import React from "react";
import TopNavBar from "../../../components/ui/TopNavBar";
import ChainedContext from "../../../contexts/chainedContext";
import SettingsReceiver from "../../../contexts/settings_context/Settings_Receiver";

function ReportsNav({ settings }) {
  const navigation = [
    { name: "Dashboard", href: `/reports`, exact: true, show: true },
    { name: "Requirements", href: `/reports/requirements`, exact: true, show: true },
    { name: "Courses", href: `/reports/courses`, exact: true, show: true },
    { name: "Member Activity", href: `/reports/member_activity`, exact: true, show: true },
    { name: "Member Deployments", href: `/reports/member_deployments`, exact: true, show: true },
    { name: "Lowland Report", href: `/reports/lowland_report`, exact: true, show: settings.enableLowlandReport },
  ];

  return (
    <TopNavBar navigation={navigation} />
  );
}
export default ChainedContext(ReportsNav, [
  [SettingsReceiver, "settings"]
]);