import React, { useState, useEffect } from "react";
import Box from "../../components/common/Box";
import dayjs from "dayjs";
import api from "../../api";
import { useNavigate, useParams } from "react-router-dom";

export default function IntakePage() {
  const [intake, setIntake] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { intake_id } = useParams();

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    api.recruitment.getIntake(intake_id).then((res) => {
      setIntake(res);
    });
  };
  return (
    <div className="p-4">
      <div className="grid gap-2 sm:grid-cols-3">
        <Box title={"Intake"}>
          <div className="grid items-end space-y-4 p-4 sm:grid-cols-2">
            <div className="">
              Ref:{" "}
              <span className="text-black dark:text-white">{intake.ref}</span>
            </div>
            <div className="text-right">
              Type:{" "}
              <span className="text-black dark:text-white">{intake.type}</span>
            </div>
            <div className="text-left">
              Start:{" "}
              <span className="text-black dark:text-white">
                {intake.start ? dayjs(intake.start).format("DD-MM-YYYY") : "--"}
              </span>
            </div>
            <div className="text-right">
              Completed:{" "}
              <span className="text-black dark:text-white">
                {" "}
                {intake.completed
                  ? dayjs(intake.completed).format("DD-MM-YYYY")
                  : "--"}
              </span>
            </div>
          </div>
        </Box>
        <div className="col-span-2">
          <Box title={"Recruits"}>
            <table className="lg:text-md min-w-full table-auto border-separate divide-y divide-gray-200  text-sm">
              <thead>
                <tr className="bg-gray-300 text-xs uppercase tracking-wider text-gray-900 dark:bg-zinc-700 dark:text-white">
                  <th className="flex gap-1 px-4 py-3 text-left   ">Name</th>
                  <th className="px-4 py-3 text-left    ">Contact Info</th>
                  <th className="px-4 py-3 text-left   ">Town</th>

                  {/* <th className="flex gap-1 px-4 py-3 text-center   ">On-call</th> */}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 text-left">
                {intake.Recruits !== undefined ? (
                  intake.Recruits.map((u) => {
                    return (
                      <tr
                        key={u._id}
                        onClick={() =>
                          navigate(`/recruitment/recruits/${u._id}`)
                        }
                        className={` ${u.isArchived && "nonOp"}
                     cursor-pointer  hover:bg-gray-300 dark:hover:bg-zinc-600`}
                      >
                        <td className="text-md border border-zinc-300 p-2 dark:border-zinc-500">
                          <span className="text-lg">
                            {u.firstName} {u.lastName}{" "}
                          </span>
                          {u.isArchived && (
                            <span className="italic text-zinc-400 dark:text-zinc-300">
                              (Archived)
                            </span>
                          )}
                          <br />
                        </td>
                        <td className="border border-zinc-300 p-2 dark:border-zinc-500">
                          {u.phone ? u.phone : "--"}
                          <br />
                          {u.email}
                        </td>
                        <td className="border border-zinc-300 p-2 dark:border-zinc-500">
                          {u.Town}
                        </td>

                        {/* <td className="w-32 border border-zinc-300 p-2 text-center dark:border-zinc-500"></td> */}
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={3}>No recruits</td>
                  </tr>
                )}
              </tbody>
            </table>
          </Box>
        </div>
      </div>
    </div>
  );
}
