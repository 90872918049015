import React, { useState, useEffect } from "react";
import Box from "../../components/common/Box";
import { Skeleton } from "../../components/ui/skeleton";
import API from "../../api";
import EventLine from "../../components/common/EventLine";
import ExpiringQualifications from "../../components/widgets/ExpiringQualifications";

const Dashboard = () => {
  const [trainingLoading, setTrainingLoading] = useState(true);
  const [upcomingTraining, setUpcomingTraining] = useState([]);

  useEffect(() => {
    API.training.dashboardTraining().then((res) => {
      setTrainingLoading(false);
      setUpcomingTraining(res);
    });
  }, []);

  return (
    <div className="p-4">
      <div className={"grid gap-4 lg:grid-cols-2"}>
        <ExpiringQualifications />
        <Box title={"Upcoming Training"}>
          {trainingLoading ? (
            <div className="flex flex-wrap justify-center gap-4 p-4">
              <Skeleton className="h-20 w-1/4 bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
              <Skeleton className="h-20 w-1/4 bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
              <Skeleton className="h-20 w-1/4 bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
            </div>
          ) : (upcomingTraining.length === 0 ? (<div className={"p-2 text-center"}>
            [No upcoming training to display]
          </div>) : (
            <table>
              <tbody>
                {upcomingTraining.map((event, i) => {
                  return <EventLine key={i} data={event} />;
                })}
              </tbody>
            </table>
          ))}
        </Box>
      </div>
    </div>
  );
};
export default Dashboard;
