import React, { useState, useEffect } from "react";
import Layout from "../../components/common/Layout";
import SettingsArrayList from "../settings/_components/SettingsArrayList";
import api from "../../api";
export default function AssetSettings() {
  const [settings, setSettings] = useState({
    tags: []
  });

  useEffect(() => {
    getSettings();
  }, []);
  const getSettings = () => {
    api.assets.settings.all().then((res) => {
      setSettings(res);
    });
  };
  const handleUpdate = (data) => {
    api.assets.settings.update(data).then(() => {
      getSettings();
    });
  };

  return (
    <div className="p-4">
      <SettingsArrayList
        title="Asset Tags"
        field="tags"
        data={settings.tags}
        onSubmit={handleUpdate}
        onDelete={handleUpdate}
      />
    </div>
  );
}
