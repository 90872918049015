import React, { useState } from "react";
import Box from "../../../components/common/Box";
import { Dialog, Transition } from "@headlessui/react";
import { TrashIcon } from "@heroicons/react/20/solid";
import { GithubPicker, CompactPicker } from "react-color";
import { PencilIcon } from "@heroicons/react/24/outline";
import Input from "../../../components/common/Input";

const SettingsColourList = ({
  title,
  label,
  field,
  onSubmit,
  onDelete,
  data,
  style,
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [value, setValue] = useState("");
  const [del, setDelete] = useState("");
  const [order, setOrder] = useState(1);
  const [editID, setEditID] = useState(null);
  const [colour, setColour] = useState("#1273DE");
  const [isOpen, setIsOpen] = useState(false);

  const handleEdit = (item) => {
    setValue(item.name);
    setOrder(item.order);
    setColour(item.colour);
    setEditID(item._id);
    setIsEdit(true);
  };
  const handleAdd = () => {
    let fd = data;
    if (!Array.isArray(fd)) {
      fd = [];
    }
    if (isEdit) {
      fd = fd.map((item) => {
        if (item._id !== editID) {
          return item;
        }
        return {
          ...item,
          colour: colour,
          order: order,
          name: value,
          // name: value.replace(/\w\S*/g, function (txt) {
          //   return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
          // }),
        };
      });
    } else {
      fd.push({
        colour: colour,
        order: order,
        name: value,
        // name: value.replace(/\w\S*/g, function (txt) {
        //   return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        // }),
      });
    }

    let theField = field;
    const list = {};
    list[theField] = fd;
    onSubmit(list);
    setValue("");
    setOrder(0);
    setIsEdit(false);
    setEditID(null);
    setColour("#1273DE");
  };
  const handleDelete = () => {
    let newList = data.filter((item, i) => {
      return del._id !== item._id;
    });
    let theField = field;
    const list = {};
    list[theField] = newList;
    onDelete(list);
    setIsOpen(false);
  };
  const CloseDialog = () => {
    return (
      <Transition
        show={isOpen}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Dialog
          open={isOpen}
          onClose={() => setIsOpen(false)}
          className="fixed inset-0 z-10 overflow-y-auto "
        >
          <div className="min-h-screen text-center">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-80" />
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className="my-8 inline-block w-full max-w-2xl transform overflow-hidden rounded-2xl border-4 border-gray-700 bg-gray-50 p-6 text-left align-middle shadow-xl transition-all dark:bg-zinc-700">
              <div className="pt-4">
                <Dialog.Title>
                  Delete <span className="italic">{title}</span> Value{" "}
                  <span className="font-bold italic">{del.name}</span>
                </Dialog.Title>
              </div>

              <div className="mt-4 flex justify-between border-t border-gray-700 pt-4">
                <button
                  className="rounded-lg bg-primary px-4 py-2 text-white"
                  onClick={() => setIsOpen(false)}
                >
                  Cancel
                </button>
                <button
                  className="rounded-lg bg-red-500 px-4 py-2 text-white disabled:bg-red-500"
                  onClick={handleDelete}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  };

  return (
    <Box title={title}>
      <div className="flex w-full flex-col ">
        <div className="5xl:flex-nowrap flex w-full flex-wrap items-center gap-2 p-2 align-middle">
          <CompactPicker
            triangle="hide"
            color={colour}
            onChangeComplete={(e) => setColour(e.hex)}
          />
          <div className="flex items-end gap-2">
            <Input
              value={value}
              type={"text"}
              field={field}
              onChange={(e) => setValue(e.target.value)}
              className="w-full flex-1"
            />

            <button
              disabled={value === "" ? true : false}
              onClick={handleAdd}
              className="h-10 cursor-pointer rounded-md border border-green-500 bg-green-300 bg-opacity-40   px-4 py-2 text-green-500  hover:border-green-300"
            >
              {isEdit ? "Update" : "Add"}
            </button>
          </div>
        </div>
        {CloseDialog()}
        <div className="w-full pt-4">
          <table className="tableClass">
            <thead className="tableHeadClass">
              <tr className="tableHeadRowClass">
                <th className="tableHeadCellClass">Name</th>
                <th className="tableHeadCellClass">Colour</th>
                <th className="tableHeadCellClass"></th>
              </tr>
            </thead>
            <tbody className="tableBodyClass">
              {data &&
                data.map((d) => {
                  return (
                    <tr className="tableBodyRowClass" key={d._id}>
                      <td className="tableBodyCellClass text-lg">{d.name}</td>
                      <td
                        className="tableBodyCellClass w-20"
                        style={{ backgroundColor: d.colour }}
                      >
                        {d.colour}
                      </td>

                      <td className="  w-28 gap-2 text-center">
                        <div className="flex justify-center gap-2">
                          <button
                            onClick={() => handleEdit(d)}
                            className="rounded-md border bg-primary   bg-opacity-80 px-2 py-1  text-sm text-white hover:bg-gray-800"
                          >
                            <PencilIcon className="h-4 w-4 text-yellow-200 " />
                          </button>
                          <button
                            className="rounded-md border border-red-400  bg-red-700 bg-opacity-80 px-2 py-1 text-sm text-white hover:border-red-300 hover:bg-red-800"
                            onClick={() => {
                              setDelete(d);
                              setIsOpen(true);
                            }}
                          >
                            <TrashIcon className="h-4 w-4 text-red-200" />
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </Box>
  );
};
export default SettingsColourList;
