import React, { useState, useEffect } from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {toast} from "react-hot-toast";
import DayJS from "dayjs";
import API from "../../api";
import EventEntryForm from "./_components/EventEntryForm";
import CPDForm from "./_components/CPDForm";
import Box from "../../components/common/Box";
import Button from "../../components/common/Button";
import CourseEventForm from "../../components/widgets/CourseEventForm";
import CompSessionForm from "../training/competency/_components/CompSessionForm";
import {AuthComponents} from "../../lib/_sso";

export default function EventChoice() {
  const [search, setSearch] = useSearchParams();
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [eventType, setEventType] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if(search.has("eventType")){
      setEventType(search.get("eventType"));
    }
    if(search.has("startAt")){
      setStart(DayJS(search.get("startAt")).toDate());
    }
    if(search.has("endAt")){
      setEnd(DayJS(search.get("endAt")).toDate());
    }
  }, [search]);

  const handleCourseCreate = (data) => {
    return API.courses.events.add(data).then(res => {
      toast.success("Successfully created new course event");
      navigate("/training/courses/event/" + res._id);
    })
  };
  const handleCompetencySubmit = (data) => {
    return API.training.competencies.createCollections(data).then(res => {
      toast.success("Successfully created new  competency session");
      navigate("/training/competencies/session/" + res._id);
    });
  };
  const handleCreateEvent = (data) => {
    API.calendar.createEvent(data).then(res => {
      toast.success("Successfully created new event");
      navigate("/events/event/" + res._id);
    });
  };

  return (
    <div className="w-full space-y-2 p-2">
      <Box title="Pick an event type">
        <div className="flex justify-around p-4">
          <Button text="Create Event" onClick={() => setEventType("event")} />
          <Button text="Create Course" onClick={() => setEventType("course")} />
          <Button text="Create Competency Session" onClick={() => setEventType("competency")}/>
          <Button text="Create CPD Session" onClick={() => setEventType("cpd")}/>
        </div>
      </Box>
      <div>
        {eventType === "event" && <EventEntryForm startAt={start} endAt={end} onSubmit={handleCreateEvent} onCancel={() => setEventType("")} />}

        {eventType === "course" && <Box title={"Add Course"}>
          <AuthComponents.Can scope={"course:events:write"}>
            <div className={"p-2"}><CourseEventForm onCancel={() => setEventType("")} onSubmit={handleCourseCreate} /></div>
          </AuthComponents.Can>
          <AuthComponents.CanNot scope={"course:events:write"}>
            <div className={"p-2 text-red-500"}>Access Denied: You do not have permission to access this page.</div>
          </AuthComponents.CanNot>
        </Box>}

        {eventType === "competency" && <Box title={"Add Competency Session"}>
          <AuthComponents.Can scope={"competency:session:write"}>
            <div className={"p-2"}><CompSessionForm onSubmit={handleCompetencySubmit} onCancel={() => setEventType("")}/></div>
          </AuthComponents.Can>
          <AuthComponents.CanNot scope={"competency:session:write"}>
            <div className={"p-2 text-red-500"}>Access Denied: You do not have permission to access this page.</div>
          </AuthComponents.CanNot>
        </Box>}

        {eventType === "cpd" && <CPDForm startAt={start} endAt={end} onCancel={() => setEventType("")} />}
      </div>
    </div>
  );
}
