import React, { useMemo } from "react";
import CustomTileLayer from "../custom";
import {XYZ} from "ol/source";

const SarStuff_Satellite = ({ visible = true, zIndex = 0}) => {

  const source = useMemo(() => new XYZ({
    url: "https://api.mapbox.com/styles/v1/m1ck/cl93yezwu00td16pinm86a51q/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoibTFjayIsImEiOiJjaXdnbDNqbXAwMDBpMnRtc2NqaHFlNnQyIn0.zO_jIx7-NUZVb49fU3pVYQ",
    projection: "EPSG:3857",
    attributions: [
      '<b>SarStuff - &copy; <a href ="https://www.mapbox.com" target="_blank">Mapbox</a> - <a href ="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a></b>',
    ],
    attributionsCollapsible: false,
  }), []);

  return (
    <CustomTileLayer source={source} visible={visible} zIndex={zIndex} />
  );
}
export default SarStuff_Satellite;