import React from "react";

const Input = ({
  label,
  field,
  type = "text",
  onChange,
  placeholder,
  value,
  maxLength,
  style = "w-full",
  error,
  disabled = false,
  uppercase = false,
  required = false,
}) => {
  return (
    <div className={style}>
      <label
        htmlFor={field}
        className={`${error ? "text-red-400" : "labelClass"}`}
      >
        {label}
        {required && (
          <span className="pl-1 text-red-400">
            <sup>*</sup>
          </span>
        )}
      </label>
      <input
        disabled={disabled}
        type={type}
        name={field}
        aria-label={field}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        maxLength={maxLength}
        autoComplete="off"
        className={`block w-full border px-2 py-1 tracking-wide text-black placeholder-zinc-300 shadow-sm dark:text-white dark:placeholder-zinc-600  ${
          disabled
            ? "text-md  border-zinc-700 bg-gray-100 dark:bg-zinc-800 "
            : "text-md border-zinc-300 bg-white dark:border-zinc-500 dark:bg-zinc-700 "
        } ${error ? "!border-red-400" : "border-primary "} ${
          uppercase && "uppercase"
        } rounded-md`}
      />
      {error && <span className={"text-red-400"}>{error}</span>}
    </div>
  );
};
export default Input;
