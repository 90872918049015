import { GT_WGS84, GT_OSGB, GT_Irish } from "./geotools2";
import Utm, { LatLon } from "./geodesy/utm.js";
import xmlbuilder from "xmlbuilder";
import dayjs from "dayjs";
// const w3w = require("@what3words/api");

// w3w.setOptions({ key: "H4C958GH" });
class CommonFunctions {
  getGrid = (lat, lng, gridType) => {
    switch (gridType) {
      case "OSGB":
        let wgs84 = new GT_WGS84();
        wgs84.setDegrees(lat, lng);
        let osgb = wgs84.getOSGB();
        if (osgb.status === "OK" && osgb.eastings > 0 && osgb.northings > 0) {
          try {
            let gridref = osgb.getGridRef(4).replace(/\s/g, "");
            return gridref;
          } catch (err) {
            console.log(err);
            return null;
          }
        }
        break;
      case "OSIE":
        let irish = new GT_WGS84();
        irish.setDegrees(lat, lng);
        let irishGrid = irish.getIrish();
        if (
          irishGrid.status === "OK" &&
          irishGrid.eastings > 0 &&
          irishGrid.northings > 0
        ) {
          try {
            let gridref = irishGrid.getGridRef(4).replace(/\s/g, "");
            return gridref;
          } catch (err) {
            console.log(err);
            return null;
          }
        } else {
          let osgbI = irish.getOSGB();
          if (
            osgbI.status === "OK" &&
            osgbI.eastings > 0 &&
            osgbI.northings > 0
          ) {
            try {
              let gridrefI = osgbI.getGridRef(4).replace(/\s/g, "");
              return gridrefI;
            } catch (err) {
              console.log(err);
              return null;
            }
          }
        }
        break;
      case "UTM":
        if (lat && lng) {
          try {
            let LL = new LatLon(lat, lng);
            const utmCoord = LL.toUtm();
            return utmCoord.toString();
          } catch (err) {
            console.log(err);
            return null;
          }
        }

        break;
      default:
        let wgs84D = new GT_WGS84();
        wgs84D.setDegrees(lat, lng);
        let osgbD = wgs84D.getOSGB();
        if (
          osgbD.status === "OK" &&
          osgbD.eastings > 0 &&
          osgbD.northings > 0
        ) {
          try {
            let gridrefD = osgbD.getGridRef(4).replace(/\s/g, "");
            return gridrefD;
          } catch (err) {
            console.log(err);
            return null;
          }
        }
        break;
    }
  };
  getEN = (lat, lng) => {
    let wgs84 = new GT_WGS84();
    wgs84.setDegrees(lat, lng);
    let osgb = wgs84.getOSGB();
    if (osgb.status === "OK" && osgb.eastings > 0 && osgb.northings > 0) {
      return [osgb.eastings, osgb.northings];
    }
  };
  getW3w = async (lat, lng) => {
    if (lat && lng) {
      try {
        let data = await window.what3words.api.convertTo3wa({
          lat: lat,
          lng: lng,
        });
        if (data) {
          return data.words;
        }
        return null;
      } catch (err) {
        console.log(err);
        return null;
      }
    }
    return null;
  };
  findW3w = async (first, second, third) => {
    try {
      if (first && second && third) {
        let data = await window.what3words.api.convertToCoordinates(
          first + "." + second + "." + third
        );
        if (data) {
          return data;
        }
      }
    } catch (error) {
      if (error.code === "BadWords") {
        return { error: "BadWords" };
      }
      console.log("CF", error);
    }

    return null;
  };
  getLatLng = (grid, gridType) => {
    switch (gridType) {
      case "OSGB":
        let osgb = new GT_OSGB();
        if (osgb.parseGridRef(grid.toUpperCase())) {
          let wgs84 = osgb.getWGS84();
          let lat = wgs84.latitude;
          let lng = wgs84.longitude;
          return [lng, lat];
        } else {
          let osgbI = new GT_Irish();
          if (osgbI.parseGridRef(grid.toUpperCase())) {
            let wgs84 = osgbI.getWGS84();
            let lat = wgs84.latitude;
            let lng = wgs84.longitude;
            return [lng, lat];
          }
        }
        break;
      case "OSIE":
        let osIrish = new GT_Irish();
        if (osIrish.parseGridRef(grid.toUpperCase())) {
          let wgs84 = osIrish.getWGS84();
          let lat = wgs84.latitude;
          let lng = wgs84.longitude;
          return [lng, lat];
        } else {
          let osgbI = new GT_OSGB();
          if (osgbI.parseGridRef(grid.toUpperCase())) {
            let wgs84I = osgbI.getWGS84();
            let lat = wgs84I.latitude;
            let lng = wgs84I.longitude;
            return [lng, lat];
          }
        }
        break;
      case "UTM":
        if (grid) {
          try {
            let UTM = Utm.parse(grid);
            const ll = UTM.toLatLon();
            return [ll.lng, ll.lat];
          } catch (err) {
            console.log(err);
            return null;
          }
        }
        break;
      default:
        return;
    }
  };
  gridCheck = (grid, gridType) => {
    switch (gridType) {
      case "OSGB":
        let osgb = new GT_OSGB();
        if (osgb.parseGridRef(grid.toUpperCase())) {
          return true;
        } else {
          let osIrish = new GT_Irish();
          if (osIrish.parseGridRef(grid.toUpperCase())) {
            return true;
          }
          return false;
        }
      case "OSIE":
        let osIrish = new GT_Irish();
        if (osIrish.parseGridRef(grid.toUpperCase())) {
          return true;
        } else {
          let osgbI = new GT_OSGB();
          if (osgbI.parseGridRef(grid.toUpperCase())) {
            return true;
          }
          return false;
        }
      case "UTM":
        return true;
      default:
        return false;
    }
  };
  getLatLngEN = (e, n) => {
    let osgb = new GT_OSGB();
    osgb.setGridCoordinates(e, n);
    let coords = osgb.getWGS84();
    return coords;
  };
  getLatLngUTM = (data) => {
    const utm = Utm.parse(data);
    let latlon = utm.toLatLon();
    latlon.grid = data;
    return latlon;
  };
  returnAddress = (suggest, gridType) => {
    if (suggest) {
      let coords = [suggest.location.lng, suggest.location.lat];
      let components = suggest.gmaps.address_components;
      let admin_area = "";
      let postal_town = "";
      let postal_code = "";
      // eslint-disable-next-line
      let name = "";
      // eslint-disable-next-line
      let street = "";
      let county = "";
      let town = "";
      for (let component = 0; component < components.length; component++) {
        if (components[component].types[0] === "locality") {
          admin_area = components[component].long_name;
        }
        if (components[component].types[0] === "postal_town") {
          postal_town = components[component].long_name;
        }
        if (components[component].types[0] === "administrative_area_level_2") {
          county = components[component].long_name;
        }
        if (components[component].types[0] === "postal_code") {
          postal_code = components[component].long_name;
        }
        if (admin_area) {
          town = admin_area;
        } else {
          town = postal_town;
        }
      }

      let address = {
        geoJSON: {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: coords,
          },
          properties: {
            name: suggest.label,
            town: town,
            county: county,
            postcode: postal_code,
            address: suggest.gmaps.formatted_address,
            grid: this.getGrid(
              suggest.location.lat,
              suggest.location.lng,
              gridType
            ),
          },
        },
      };
      return address;
    }
  };
  generateCSV(report_title, header_fields, body_data, pre_header_fields = []) {
    if (body_data && body_data.length !== 0) {
      let csv_rows = [];
      let csv_header = [];

      if (pre_header_fields.length > 0) {
        pre_header_fields.forEach((item) => {
          csv_rows.push(item);
        });

        csv_rows.push("");
      }

      header_fields.forEach((item) => {
        csv_header.push(item.csv_field);
      });

      csv_rows.push(csv_header.join(","));

      body_data.forEach((item) => {
        csv_rows.push(
          header_fields
            .map((header) => {
              let clean_value = (
                item[header.database_field] === undefined
                  ? ""
                  : "" + item[header.database_field]
              ).replace(/"|'/g, "");
              return `"${clean_value}"`;
            })
            .join(",")
        );
      });

      let data_blob = new Blob([csv_rows.join("\n")], { type: "text/csv" });
      let a = document.createElement("a");

      a.setAttribute("hidden", "");
      a.setAttribute("href", window.URL.createObjectURL(data_blob));
      a.setAttribute(
        "download",
        `${report_title} - ${dayjs().format("YYYY-MM-DD")}.csv`
      );
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }
  //Google Map Functions
  convertPolygonGeoToGM = (data) => {
    if (data) {
      // console.log("$$$$$", data);
      if (data.geoJSON.geometry.type === "Polygon") {
        let raw = data.geoJSON && data.geoJSON.geometry.coordinates;
        let finalData = raw[0].map((coord) => {
          return {
            lat: parseFloat(coord[1]),
            lng: parseFloat(coord[0]),
          };
        });
        return finalData;
      } else {
        let raw = data.geoJSON && data.geoJSON.geometry.coordinates;
        let finalData = raw.map((coord) => {
          return {
            lat: parseFloat(coord[1]),
            lng: parseFloat(coord[0]),
          };
        });
        return finalData;
      }
    }
  };
  convertFloodDataGeoToGM = (data) => {
    if (data) {
      // console.log("$$$$$", data);
      if (data.geometry.type === "Polygon") {
        let raw = data && data.geometry.coordinates;
        let finalData = raw[0].map((coord) => {
          return {
            lat: parseFloat(coord[1]),
            lng: parseFloat(coord[0]),
          };
        });
        return finalData;
      } else if (data.geometry.type === "MultiPolygon") {
        let raw = data && data.geometry.coordinates;

        let finalData = raw.map((coord) => {
          coord.map((c) => {
            console.log("c", c);
            return {
              lat: parseFloat(c[1]),
              lng: parseFloat(c[0]),
            };
          });
        });
        console.log("finalData", finalData);
        return finalData;
      }
    }
  };
  convertGeoToGM = (data) => {
    if (data) {
      let raw = data.geoJSON.geometry.coordinates;
      let finalData = {
        lat: raw[1],
        lng: raw[0],
      };

      return finalData;
    }
  };
  createWaypointGPX = (data) => {
    let gpx = xmlbuilder
      .create("gpx", {
        encoding: "UTF-8",
      })
      .att("creator", "Sarstuff")
      .att("version", "1.1")
      .att("xmlns", "http://www.topografix.com/GPX/1/1")
      .att("xmlns:xsi", "http://www.w3.org/2001/XMLSchema-instance")
      .att(
        "xsi:schemaLocation",
        "http://www.topografix.com/GPX/1/1 " +
          "http://www.topografix.com/GPX/1/1/gpx.xsd " +
          "http://www.garmin.com/xmlschemas/GpxExtensions/v3 " +
          "http://www.garmin.com/xmlschemas/GpxExtensionsv3.xsd " +
          "http://www.garmin.com/xmlschemas/TrackPointExtension/v1 " +
          "http://www.garmin.com/xmlschemas/TrackPointExtensionv1.xsd"
      );

    let metadata = gpx.ele("metadata");
    metadata.ele("name", data.activityType ? data.activityType : "Boom");
    data.waypoints.forEach((wayP) => {
      let bob = gpx.ele("wpt");
      bob.att("lat", wayP.latitude);
      bob.att("lon", wayP.longitude);
      bob.ele("desc", wayP.name);
      bob.ele("name", wayP.type);
    });
    return gpx.end({ pretty: true });
  };
}
export default new CommonFunctions();
