import React, { useState, useEffect, Fragment, useContext } from "react";
import DatePicker from "react-datepicker";
import { Transition, Listbox } from "@headlessui/react";
import {
  CursorArrowRaysIcon,
  CheckIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import Layout from "../../../../components/common/Layout";
import Box from "../../../../components/common/Box";
import Input from "../../../../components/common/Input";
import Button from "../../../../components/common/Button";
import api from "../../../../api";
import AssetContext from "../_context";
import DataBox from "../../../../components/common/DataBox";

export default function AddService() {
  const navigate = useNavigate();
  const asset = useContext(AssetContext);

  const [part, setPart] = useState({
    qty: "",
    item: "",
    cost: "",
  });
  const [service, setService] = useState({
    type: "",
    date_scheduled: "",
    date_serviced: "",
    notes: "",
    status: "",
    replacedParts: [],
    supplier: "",
    asset: "",
    partsCost: "",
    labourCost: "",
    totalCost: "",
  });
  const [suppliers, setSuppliers] = useState([]);
  const [selected, setSelected] = useState({
    name: "",
    _id: "",
  });

  useEffect(() => {
    api.assets.suppliers.all().then((res) => {
      setSuppliers(res);
    });
  }, []);
  const addPart = () => {
    setService({
      ...service,
      replacedParts: service.replacedParts.concat(part),
    });
    setPart({ qty: "", item: "", cost: "" });
  };
  const addService = () => {
    let data = service;
    data.asset = asset._id;
    data.supplier = selected._id;
    console.log(data);
    api.assets.service.add(asset._id, data).then((res) => {
      navigate(`/asset/view/${asset._id}`);
    });
  };
  const deletePart = (part) => {
    let parts = service.replacedParts.filter((p) => {
      return p != part;
    });
    setService({
      ...service,
      replacedParts: parts,
    });
  };
  const onServiceChange = (e) => {
    setService({ ...service, [e.target.name]: e.target.value });
  };
  const combinedCost =
    parseFloat(service.partsCost) + parseFloat(service.labourCost);

  return (
    <Layout full>
      <div className="mb-4 text-center text-2xl">
        {/* <Box> */}
        <div className="grid gap-4 p-2 sm:grid-cols-5">
          <DataBox
            label="Name"
            value={asset.name}
            onClick={() => navigate(`/asset/view/${asset._id}`)}
          />
          <DataBox
            label="Asset Num"
            value={asset.assetNum}
            onClick={() => navigate(`/asset/view/${asset._id}`)}
          />
          <DataBox label="Service Interval" value={asset.serviceInterval} />
          <DataBox
            label="Last Service"
            value={asset.LastService ? asset.LastService : "--"}
          />
          <DataBox
            label="Next Service"
            value={asset.NextService ? asset.NextService : "--"}
          />
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4">
        <div className="col-span-1">
          <Box title="Add Service ">
            <div className="p-2">
              <div className="flex  gap-2">
                <Input
                  label={"Service Type"}
                  field="type"
                  type={"text"}
                  value={service.type}
                  onChange={onServiceChange}
                  placeholder="Annual service"
                />
                <div className=" ">
                  <label className={`labelClass `}>Date Scheduled</label>
                  <DatePicker
                    name="date_scheduled"
                    onChange={(date) =>
                      setService({
                        ...service,
                        date_scheduled: date,
                      })
                    }
                    dateFormat="dd-MM-yyyy"
                    className="inputClass z-50"
                    selected={service.date_scheduled}
                  />
                </div>
                <div className="">
                  <label className={`labelClass`}>Date Serviced</label>
                  <DatePicker
                    name="date_serviced"
                    onChange={(date) =>
                      setService({
                        ...service,
                        date_serviced: date,
                      })
                    }
                    dateFormat="dd-MM-yyyy"
                    maxDate={new Date()}
                    className="inputClass z-50"
                    selected={service.date_serviced}
                  />
                </div>
              </div>
              <div className=" ">
                <label className={`labelClass `}>Supplier</label>
                <Listbox
                  value={selected}
                  onChange={(e) => setSelected(e)}
                  className=""
                >
                  <div className="relative  ">
                    <Listbox.Button className="inputClass h-10">
                      <span className="block truncate">{selected.name}</span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center p-2">
                        <CursorArrowRaysIcon
                          className="h-5 w-5 text-white"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>
                    <Transition
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute w-full overflow-auto rounded-md shadow-lg focus:outline-none sm:text-sm">
                        {suppliers.map((person, personIdx) => (
                          <Listbox.Option
                            key={personIdx}
                            className={({ active }) =>
                              `${
                                active
                                  ? "bg-amber-100 text-zinc-300"
                                  : "text-white"
                              }
                          relative cursor-default select-none border-b p-2  hover:bg-gray-700 dark:border-zinc-700 dark:bg-zinc-800`
                            }
                            value={person}
                          >
                            {({ selected, active }) => (
                              <div className="flex justify-between">
                                <div
                                  className={`${
                                    selected ? "font-medium" : "font-normal"
                                  }  truncate `}
                                >
                                  {person.name}
                                </div>
                                {selected ? (
                                  <div
                                    className={`${
                                      active
                                        ? "text-amber-600"
                                        : "text-amber-600"
                                    }
                                  `}
                                  >
                                    <CheckIcon
                                      className="h-5 w-5 text-green-600"
                                      aria-hidden="true"
                                    />
                                  </div>
                                ) : null}
                              </div>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </Listbox>
              </div>

              <div className="flex justify-around gap-4">
                <div className=" w-full">
                  <Input
                    label={"Parts Cost"}
                    field="partsCost"
                    type={"number"}
                    value={service.partsCost}
                    onChange={onServiceChange}
                    placeholder="100"
                  />
                </div>
                <div className=" w-full">
                  <Input
                    label={"Labour Cost"}
                    field="labourCost"
                    type={"number"}
                    value={service.labourCost}
                    onChange={onServiceChange}
                    placeholder="100"
                  />
                </div>
                <div className=" w-full">
                  <Input
                    label={"Total Cost"}
                    field="totalCost"
                    type={"number"}
                    value={service.totalCost}
                    onChange={onServiceChange}
                    placeholder="100"
                  />

                  <span className="p-2 italic text-gray-400">
                    (parts + labour = {isNaN(combinedCost) ? 0 : combinedCost})
                  </span>
                </div>
              </div>
              {/* <div className="flex gap-4 justify-around">
               <Select
               label="Status"
               field="status"
               onChange={onServiceChange}
               options={[
               { key: 1, text: "scheduled", value: "scheduled" },
               { key: 2, text: "pass", value: "pass" },
               { key: 3, text: "fail", value: "fail" },
               ]}
               />
               </div> */}
              <div className="flex justify-around gap-4">
                <div className=" w-full">
                  <label className={`labelClass `}>Notes</label>
                  <textarea
                    rows={3}
                    name="notes"
                    value={service.notes}
                    onChange={onServiceChange}
                    className={`inputClass`}
                  />
                </div>
              </div>
              <div className="flex justify-between gap-4 p-4">
                <Button
                  negative
                  text="Cancel"
                  onClick={() => navigate(`/asset/view/${asset._id}`)}
                />
                <Button positive text="Add Service" onClick={addService} />
              </div>
            </div>
          </Box>
        </div>
        <div className="col-span-1 ">
          {/*<Box title="Add Service Documents">*/}
          {/*  <div className="text-center text-2xl p-4">*/}
          {/*    TODO: Tim Add uploader*/}
          {/*  </div>*/}
          {/*</Box>*/}

          <Box title="Replaced Parts List">
            <div className="mt-2 p-2">
              <div className="justify-arround flex gap-2 p-2">
                <div className="flex w-1/6 flex-col text-center">
                  <label className={`labelClass `}>Qty</label>
                  <input
                    autoComplete="off"
                    onChange={(e) =>
                      setPart({
                        ...part,
                        qty: e.target.value,
                      })
                    }
                    value={part.qty}
                    name="qty"
                    placeholder="qty"
                    className="inputClass"
                  />
                </div>
                <div className="flex w-4/6 flex-col text-center">
                  <label className={`labelClass`}>Item</label>
                  <input
                    autoComplete="off"
                    onChange={(e) =>
                      setPart({
                        ...part,
                        item: e.target.value,
                      })
                    }
                    value={part.item}
                    name="item"
                    placeholder="item"
                    className="inputClass"
                  />
                </div>
                <div className="flex flex-col  text-center">
                  <label className={`labelClass `}>Total Cost</label>
                  <input
                    autoComplete="off"
                    onChange={(e) =>
                      setPart({
                        ...part,
                        cost: e.target.value,
                      })
                    }
                    value={part.cost}
                    name="item"
                    placeholder="cost"
                    className="inputClass"
                  />
                </div>
                <div className="items-middle flex pt-8">
                  <Button
                    onClick={addPart}
                    text="Add"
                    colour="bg-green-700 text-white"
                  />
                </div>
              </div>
            </div>
            <div className="mt-4">
              <table className="tableClass">
                <thead>
                  <tr className="tableHeadRowClass">
                    <th className="tableHeadCellClass ">Qty</th>

                    <th className="tableHeadCellClass ">Item</th>
                    <th className="tableHeadCellClass ">Cost</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody className="tableBodyClass">
                  {service.replacedParts.map((a, i) => {
                    return (
                      <tr key={i} className="tableBodyRowClass">
                        <td className="tableBodyCellClass w-20">{a.qty}</td>
                        <td className="tableBodyCellClass">{a.item}</td>
                        <td className="tableBodyCellClass w-28">{a.cost}</td>
                        <td className="w-10 p-2 text-right ">
                          <Button
                            colour="bg-red-700 text-white"
                            onClick={() => deletePart(a)}
                            icon={<XMarkIcon className="h-4" />}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </Box>
        </div>
      </div>
    </Layout>
  );
}
