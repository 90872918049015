import React, {useEffect, useState} from "react";
import ChainedContext from "../../../../contexts/chainedContext";
import MemberReceiver from "../../../../contexts/member_context/Member_Receiver";
import Box from "../../../../components/common/Box";
import API from "../../../../api"
import Input from "../../../../components/common/Input";
import Toast from "react-hot-toast";
import DayJS from "dayjs";
import {TrashIcon} from "@heroicons/react/20/solid";
import ConfirmBox from "../../../../components/common/Confirm";
import {AuthComponents} from "../../../../lib/_sso";

const MemberNotes = ({member}) => {
  const [notes, setNotes] = useState([]);
  const [note, setNote] = useState("");
  let [isDisabled, setIsDisabled] = useState(false);
  const [del, setDelete] = useState({});


  useEffect(() => {
    API.member.notes.allNotes(member._id).then((notes) => setNotes(notes));
  }, [member]);

  const addNote = () => {
    setIsDisabled(true);
    API.member.notes.createNote(member._id, {note}).then(() => {
      setNote("");
      Toast.success("Successfully created new member note");

      API.member.notes.allNotes(member._id).then((notes) => setNotes(notes));
    }).catch(err => {
      Toast.error("Unable to create new member note, error: " + err.message);
    }).finally(() => {
      setIsDisabled(false);
    });
  };
  const removeNote = () => {
    API.member.notes.removeNote(member._id, del._id).then(() => {
      Toast.success("Successfully deleted member note");

      API.member.notes.allNotes(member._id).then((notes) => setNotes(notes));
    }).catch(err => {
      Toast.error("Unable to delete member note, error: " + err.message);
    }).finally(() => {
      setDelete({});
    })
  }

  if(note.trim() === "") isDisabled = true

  return (
    <Box title={"Notes"}>
      <div className="flex items-end gap-2 p-4">
        <Input
          label={"Note"}
          name="note"
          onChange={(e) => setNote(e.target.value)}
          value={note}
        />
        <button
          className={`oncall w-32 rounded p-2 ${isDisabled ? "cursor-not-allowed opacity-20 " : "bg-opacity-60"}`}
          onClick={addNote}
          disabled={isDisabled}
        >
          Add
        </button>
      </div>

      {del._id !== undefined && <ConfirmBox show={true} onClose={() => setDelete({})} title={`Delete selected note`} action={removeNote} />}
      <table className="min-w-full table-auto border-separate divide-y divide-gray-200 text-sm  lg:text-lg">
        <thead>
          <tr className="bg-gray-300 text-xs uppercase tracking-wider text-gray-900 dark:bg-zinc-700 dark:text-white">
            <th className="px-4 py-3 text-left">Date</th>
            <th className="px-4 py-3 text-left">Note</th>
            <th className="px-4 py-3 text-left">Written By</th>
            <th className="px-4 py-3 text-left w-20"></th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 text-left">{notes.length > 0 ? notes.map(item => {
          return (
            <tr key={item._id}>
              <td className="px-4 py-3 text-left">{DayJS(item.date).format("DD-MM-YYYY HH:mm")}</td>
              <td className="px-4 py-3 text-left">{item.message}</td>
              <td className="px-4 py-3 text-left">{item.loggedBy}</td>
              <td className="px-4 py-3 text-left w-20">
                <AuthComponents.Can scope="member:notes:delete">
                  <button
                    className="rounded-md border border-red-400 bg-red-700 bg-opacity-80 px-2 py-1 text-sm text-white hover:border-red-300 hover:bg-red-800 disabled:opacity-20"
                    onClick={() => setDelete(item)}
                  >
                    <TrashIcon className="h-6 w-6 text-red-200"/>
                  </button>
                </AuthComponents.Can>
              </td>
            </tr>
          )
        }) : (
          <tr>
            <td colSpan={4}>
              <div className={"text-center py-3"}>No items to display</div>
            </td>
          </tr>
        )}</tbody>
      </table>
    </Box>
  );
};
export default ChainedContext(MemberNotes, [
  [MemberReceiver, "member"]
]);