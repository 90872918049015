import React, { useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { TrashIcon } from "@heroicons/react/20/solid";
import { PencilIcon } from "@heroicons/react/24/outline";
import Box from "../../../components/common/Box";
import ToggleSwitch from "../../../components/common/ToggleSwitch";

const SettingsList = ({ title, field, onSubmit, onDelete, data }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [editID, setEditID] = useState(null);
  const [value, setValue] = useState("");
  const [isRequired, setIsRequired] = useState(false);
  const [del, setDelete] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  const handleAdd = () => {
    let fd = data;
    if (!Array.isArray(fd)) {
      fd = [];
    }

    if (isEdit) {
      fd = fd.map((item) => {
        if (item._id !== editID) {
          return item;
        }
        return {
          ...item,
          name: value.replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
          }),
          isRequired,
        };
      });
    } else {
      fd.push({
        name: value.replace(/\w\S*/g, function (txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }),
        isRequired,
      });
    }

    let theField = field;
    const list = {};
    list[theField] = fd;
    onSubmit(list);
    setValue("");
    setIsEdit(false);
    setEditID(null);
  };
  const handleDelete = () => {
    let newList = data.filter((item, i) => {
      return del._id !== item._id;
    });
    let theField = field;
    const list = {};
    list[theField] = newList;
    onDelete(list);
    setIsOpen(false);
  };
  const handleEdit = (item) => {
    setValue(item.name);
    setIsRequired(item.isRequired);
    setEditID(item._id);
    setIsEdit(true);
  };

  const CloseDialog = () => {
    return (
      <Transition
        show={isOpen}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Dialog
          open={isOpen}
          onClose={() => setIsOpen(false)}
          className="fixed inset-0 z-10 overflow-y-auto "
        >
          <div className="min-h-screen text-center">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-80" />
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className="my-8 inline-block w-full max-w-2xl transform overflow-hidden rounded-2xl border-4 border-gray-700 bg-gray-50 p-6 text-left align-middle shadow-xl transition-all dark:bg-zinc-700">
              <div className="pt-4">
                <Dialog.Title>
                  Delete <span className="italic">{title}</span> Value{" "}
                  <span className="font-bold italic">{del.name}</span>
                </Dialog.Title>
              </div>

              <div className="mt-4 flex justify-between border-t border-gray-700 pt-4">
                <button
                  className="rounded-lg bg-primary px-4 py-2 text-white"
                  onClick={() => setIsOpen(false)}
                >
                  Cancel
                </button>
                <button
                  className="rounded-lg bg-red-500 px-4 py-2 text-white disabled:bg-red-500"
                  onClick={handleDelete}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  };

  return (
    <Box title={title}>
      <div className="flex w-full flex-col ">
        <div className="flex w-full items-center gap-2 p-2 align-middle">
          <input
            value={value}
            className="w-full rounded-md border-gray-400 bg-white p-2 shadow-sm dark:bg-zinc-700"
            type={"text"}
            field={field}
            onChange={(e) => setValue(e.target.value)}
            placeholder="..."
          />
          <div className="px-1">
            <label
              htmlFor={"isRequired"}
              className={`mb-1 block  dark:text-zinc-400 `}
            >
              Required
            </label>
            <ToggleSwitch
              posColour="bg-green-800"
              negColour="bg-red-800"
              isChecked={isRequired}
              onChange={() => setIsRequired(!isRequired)}
            />
          </div>
          <button
            disabled={value === ""}
            onClick={handleAdd}
            className="h-10 cursor-pointer rounded-md border border-green-500 bg-green-300 bg-opacity-40 px-4 py-2 text-green-500 hover:border-green-300"
          >
            {isEdit ? "Update" : "Add"}
          </button>
        </div>
        {CloseDialog()}
        <div className="w-full pt-4">
          <table className="w-full">
            <thead className="tableHeadClass">
              <tr className="tableHeadRowClass">
                <th className="tableHeadCellClass">Name</th>
                <th className="tableHeadCellClass">Required</th>
                <th className="tableHeadCellClass"></th>
              </tr>
            </thead>
            <tbody className="tableBodyClass">
              {data &&
                data
                  .sort((a, b) => {
                    if (a.name > b.name) return 1;
                    if (a.name < b.name) return -1;
                    return 0;
                  })
                  .map((d, i) => {
                    return (
                      <tr key={d._id} className="tableBodyRowClass">
                        <td className="tableBodyCellClass text-lg">{d.name}</td>
                        <td className="tableBodyCellClass w-20">
                          {d.isRequired ? "Yes" : "No"}
                        </td>
                        <td className="pr-2 pt-2 text-right">
                          <button
                            onClick={() => handleEdit(d)}
                            className="mr-2 rounded-md border border-gray-400 bg-gray-700 bg-opacity-80 px-2 py-1 text-sm text-white hover:border-gray-300 hover:bg-gray-800"
                          >
                            <PencilIcon className="h-6 w-6 text-gray-200" />
                          </button>
                          <button
                            className="rounded-md border border-red-400 bg-red-700 bg-opacity-80 px-2 py-1 text-sm text-white hover:border-red-300 hover:bg-red-800"
                            onClick={() => {
                              setDelete(d);
                              setIsOpen(true);
                            }}
                          >
                            <TrashIcon className="h-6 w-6 text-red-200" />
                          </button>
                        </td>
                      </tr>
                    );
                  })}
            </tbody>
          </table>
        </div>
      </div>
    </Box>
  );
};
export default SettingsList;
