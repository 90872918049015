import React, {useMemo} from "react";
import {ArrowsUpDownIcon} from "@heroicons/react/20/solid";
import {AuthComponents} from "../../../../lib/_sso";

const TableList = ({ team, screen = false, setSelectedTeamMember, setAttendance }) => {
  const [sortBy, setSortBy] = React.useState("surname"); // surname, firstname, callsign

  const handleSortToggle = () => {
    switch (sortBy) {
      case "surname":
        setSortBy("firstname");
        break;
      case "firstname":
        setSortBy("callsign");
        break;
      case "callsign":
        setSortBy("none");
        break;
      default:
        setSortBy("surname");
        break;
    }
  }

  const sortedList = useMemo(() => {
    if (team === undefined || team.length === 0) return [];

    return team.toSorted((a, b) => {
      if (sortBy === "surname") {
        if ((a.lastName || "") < (b.lastName || "")) return -1;
        if ((a.lastName || "") > (b.lastName || "")) return 1;
      }
      if (sortBy === "firstname") {
        if ((a.firstName || "") < (b.firstName || "")) return -1;
        if ((a.firstName || "") > (b.firstName || "")) return 1;
      }
      if (sortBy === "callsign") {
        if ((a.callsign || "zzzzzzzzzzzzzzzz") < (b.callsign || "zzzzzzzzzzzzzzzz")) return -1;
        if ((a.callsign || "zzzzzzzzzzzzzzzz") > (b.callsign || "zzzzzzzzzzzzzzzz")) return 1;
      }

      return 0;
    })
  }, [team, sortBy]);

  if(sortedList.length === 0) {
    return (
      <div className={"p-2 text-center"}>There are no items to display</div>
    );
  }

  return (
    <table className="tableClass">
      <thead>
        <tr className="tableHeadRowClass">
          <th onClick={() => handleSortToggle()} className="tableHeadCellClass flex gap-1">
            Name
            {sortBy !== "none" ? (
              <><ArrowsUpDownIcon className="h-4 text-green-500"/> <span className={"text-xs lowercase"}>[{sortBy}]</span></>
            ) : (
              <ArrowsUpDownIcon className="h-4"/>
            )}
          </th>
          {screen === "results" && <th className="tableHeadCellClass">Result</th>}
          <AuthComponents.Can scope={"competency:session:write"}><th className="tableHeadCellClass">Actions</th></AuthComponents.Can>
        </tr>
      </thead>
      <tbody className="tableBodyClass">
        {sortedList.map((u) => {
          return (
            <tr key={u._id} className={`tableBodyRowClass`}>
              <td className={`p-2 ${u.onCall && "oncall"} ${!u.onCall && "offcall"} ${!u.operational && "nonOp"}`}>
                {u.firstName} {u.lastName}
                <br/>
                {u.callsign && "(" + u.callsign + ")"}
              </td>
              {screen === "results" && <td  className={"w-40 text-center"}>
                <button
                  className={"rounded border p-2 text-sm border-zinc-800 dark:border-zinc-500"}
                  onClick={() => setSelectedTeamMember(u)}
                >
                  <AuthComponents.Can scope={"competency:session:write"}>Manage Scores</AuthComponents.Can>
                  <AuthComponents.CanNot scope={"competency:session:write"}>View Scores</AuthComponents.CanNot>
                </button>
              </td>}
              <AuthComponents.Can scope={"competency:session:write"}><td className={"w-40"}>
                <div className={"flex justify-center gap-2"}>
                  {screen !== "notAttending" && (
                    <button
                      className={`nonOp rounded border p-2 text-sm dark:border-zinc-500`}
                      onClick={() => setAttendance(u, false)}
                    >
                      Not Going
                    </button>
                  )}
                  {screen !== "results" && (
                    <button
                      className={`oncall rounded border p-2 text-sm dark:border-zinc-500`}
                      onClick={() => setAttendance(u, true)}
                    >
                      Going
                    </button>
                  )}
                </div>
              </td></AuthComponents.Can>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
export default TableList;