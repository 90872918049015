import React, {useState} from "react";
import {Dialog, Transition} from "@headlessui/react";
import {TrashIcon} from "@heroicons/react/20/solid";
import {PencilIcon} from "@heroicons/react/24/outline";
import Box from "../../../components/common/Box";
import Select from "../../../components/common/Select";

const SettingsList = ({title, field, onSubmit, onDelete, items, data}) => {
  const [value, setValue] = useState("");
  const [del, setDelete] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const handleAdd = () => {
    let fd = data;
    if (!Array.isArray(fd)) {
      fd = [];
    }

    fd.push(value);

    let theField = field;
    const list = {};
    list[theField] = fd;
    onSubmit(list);
    setValue("");
  };
  const handleDelete = () => {
    let newList = data.map(a => a._id).filter((item, i) => {
      return del !== item;
    });
    let theField = field;
    const list = {};
    list[theField] = newList;
    onDelete(list);
    setIsOpen(false);
  };

  const CloseDialog = () => {
    const item = items.find(a => a._id === del);
    return (
      <Transition
        show={isOpen}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Dialog
          open={isOpen}
          onClose={() => setIsOpen(false)}
          className="fixed inset-0 z-10 overflow-y-auto "
        >
          <div className="min-h-screen text-center">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-80"/>
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className="my-8 inline-block w-full max-w-2xl transform overflow-hidden rounded-2xl border-4 border-gray-700 bg-gray-50 p-6 text-left align-middle shadow-xl transition-all dark:bg-zinc-700">
              <div className="pt-4">
                <Dialog.Title>
                  Delete <span className="italic">{title}</span> Value{" "}
                  <span className="font-bold italic">{item?.name || "[N/A]"}</span>
                </Dialog.Title>
              </div>

              <div className="mt-4 flex justify-between border-t border-gray-700 pt-4">
                <button
                  className="rounded-lg bg-primary px-4 py-2 text-white"
                  onClick={() => setIsOpen(false)}
                >
                  Cancel
                </button>
                <button
                  className="rounded-lg bg-red-500 px-4 py-2 text-white disabled:bg-red-500"
                  onClick={handleDelete}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  };

  return (
    <Box title={title}>
      <div className="flex w-full flex-col ">
        <div className="flex w-full items-center gap-2 p-2 align-middle">
          <Select
            options={items?.filter(a => data.filter(b => b._id === a._id).length === 0).map((a, i) => {
              return {
                key: i,
                text: a.name,
                value: a._id
              }
            })}
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />

          <button
            disabled={value === ""}
            onClick={handleAdd}
            className="h-10 cursor-pointer rounded-md border border-green-500 bg-green-300 bg-opacity-40 px-4 py-2 text-green-500 hover:border-green-300"
          >
            Add
          </button>
        </div>
        {CloseDialog()}
        <div className="w-full pt-4">
          <table className="w-full">
            <tbody className="border-t border-gray-300 dark:border-zinc-500">
            {data &&
              data
              .sort((a, b) => {
                if (a.name > b.name) return 1;
                if (a.name < b.name) return -1;
                return 0;
              })
              .map((d, i) => {
                return (
                  <tr
                    key={i}
                    className="border-b border-gray-300 py-4 text-black dark:border-zinc-500 dark:text-white"
                  >
                    <td className="py-2 pl-2 text-lg">{d.name}</td>
                    <td className="pr-2 pt-2 text-right">
                      <button
                        className="rounded-md border border-red-400 bg-red-700 bg-opacity-80 px-2 py-1 text-sm text-white hover:border-red-300 hover:bg-red-800"
                        onClick={() => {
                          setDelete(d._id);
                          setIsOpen(true);
                        }}
                      >
                        <TrashIcon className="h-6 w-6 text-red-200"/>
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {data?.length === 0 && <div className={"pb-4 text-center"}>No items to display</div>}
        </div>
      </div>
    </Box>
  );
};
export default SettingsList;
