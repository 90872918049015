import React from "react";
// import Tooltip from "./Tooltip";

export default function Button({
  colour = "  border-zinc-300 dark:border-zinc-600 ",
  text = "",
  textColour,
  hoverColour = "hover:bg-gray-300 dark:hover:bg-zinc-700 hover:text-primary dark:hover:text-primary-dark",
  icon = null,
  onClick,
  // tooltip,
  active,
  tooltipStyle = " bg-gray-50 ",
  disabled = false,
}) {
  return (
    <div
      onClick={disabled === false ? onClick : () => {}}
      className={`flex items-center  justify-center justify-items-center rounded-md border px-1 py-1 align-middle shadow-md  ${colour} ${
        active && "bg-primary text-white  hover:text-white"
      }	${disabled ? "cursor-not-allowed opacity-50" : "cursor-pointer"} ${
        disabled === false ? hoverColour : ""
      }`}
    >
      <div className={`has-tooltip  ${textColour} `}>
        <>
          <div className="flex items-center px-2 align-middle">
            <div>{icon !== null ? icon : ""}</div>
            <div className="">{text}</div>
          </div>
        </>
      </div>
    </div>
  );
}
