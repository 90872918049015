import React, {useContext, useState} from "react";
import {LayersToggle_Active, LayersToggle_Register} from "../context";

const TileLayers = ({layers, activeIndex = 0}) => {
  const [index, setIndex] = useState(activeIndex);
  const toggleListContext = useContext(LayersToggle_Active);
  const toggleRegisterContext = useContext(LayersToggle_Register);

  const selectedTab = layers.find((tab, i) => i === index);

  return (
    <>
      <div aria-label="drawControl" className="drawControl text-white absolute right-2 top-2 rounded p-2 bg-gray-500 bg-opacity-80 text-md border border-gray-400 z-30">
        <div className="flex flex-col gap-1 pb-2 tracking-wider">
          <div className="">Layer Control</div>
          <hr className="py-1" />
          {layers.map((a, b) => {
            return (
              <div key={b} onClick={() => setIndex(b)} className="cursor-pointer">
                <input type="radio" checked={b === index} onChange={() => setIndex(b)} /> {a.title}
              </div>
            )
          })}
          <hr />
          <div>{toggleListContext.map((layer, index) => {
            const action = () => toggleRegisterContext.toggle(layer.title);
            return (
              <div key={index} onClick={action} className="cursor-pointer">
                <input type="checkbox" checked={layer.isActive} onChange={action} /> {layer.title}
              </div>
            );
          })}</div>
        </div>
      </div>
      {selectedTab.layer}
    </>
  )
};
export default TileLayers;