import { APIRequest } from "../lib/_sso";
import AppConfig from "../_appConfig";

const server = AppConfig.api_server;

export default {
  getRootObjects: () => APIRequest.get(`${server}/documents`).then(res => res.data),
  getFolderObjects: folderId => APIRequest.get(`${server}/documents/${folderId}`).then(res => res.data),
  listFolders: () => APIRequest.get(`${server}/documents/list_folders`).then(res => res.data),
  createFolder: data => APIRequest.post(`${server}/documents/create_folder`, data).then(res => res.data),
  renameFolder: (folderId, data) => APIRequest.patch(`${server}/documents/folder/${folderId}/rename`, data).then(res => res.data),
  deleteFolder: folderId => APIRequest.delete(`${server}/documents/folder/${folderId}`).then(res => res.data),

  totalUsage: () => APIRequest.get(`${server}/documents/total_usage`).then(res => res.data),
  uploadFile: (folderId, file, fileDescription) => {
    let formData = new FormData();
    formData.append("file", file);
    formData.append("folderId", folderId);
    formData.append("description", fileDescription);

    return APIRequest.post(`${server}/documents/upload_file`, formData, {headers: {"Content-Type": "multipart/form-data"}}).then(res => res.data);
  },
  deleteFile: fileId => APIRequest.delete(`${server}/documents/file/${fileId}`).then(res => res.data),
  downloadFile: fileId => APIRequest.get(`${server}/documents/file/${fileId}/download`).then(res => res.data),
  renameFile: (fileId, filename, description) => APIRequest.patch(`${server}/documents/file/${fileId}/rename`, {filename, description}).then(res => res.data),
  moveFile: (fileId, newParentId) => APIRequest.patch(`${server}/documents/file/${fileId}/move`, {newParentId}).then(res => res.data)
}