export default [
  {
    date: "23/01/2025",
    type: "Enhancement",
    details: "List assets assigned to a team member on there profile page.",
  },
  {
    date: "21/01/2025",
    type: "Enhancement",
    details: "Tables containing team members is now sortable via, firstname, surname & callsign.",
  },
  {
    date: "14/01/2025",
    type: "Enhancement",
    details: "Added most recent note to show on active recruits page.",
  },
  {
    date: "11/01/2025",
    type: "Bug Fix",
    details: "Fixed Lowland Report incorrectly identifying competency training hours.",
  },
  {
    date: "17/12/2024",
    type: "Enhancement",
    details: "Added new report to total all the searches at each team member has completed.",
  },
  {
    date: "06/12/2024",
    type: "Bug Fix",
    details: "Fixed opening vehicles assets tab crashing the page & unit vehicles being hidden on the vehicles list.",
  },
  {
    date: "26/11/2024",
    type: "Bug Fix",
    details: "Hide vehicles belonging to archived team members.",
  },
  {
    date: "24/10/2024",
    type: "Bug Fix",
    details: "Fixed permissions issue with Power User not being able to manage competency sessions correctly.",
  },
  {
    date: "24/10/2024",
    type: "Enhancement",
    details: "Added toggle in settings to re-enable the warning message for adding competency/course scores for a team member who does not have all the roles.",
  },
  {
    date: "08/10/2024",
    type: "Enhancement",
    details: "Added ability to have all three requirements for a role & for a course and competency.",
  },
  {
    date: "08/10/2024",
    type: "Bug Fix",
    details: "Fixed issue with tree view for Competency crashing when editing section.",
  },
  {
    date: "16/09/2024",
    type: "Enhancement",
    details: "Added CSV export to the member activity report.",
  },
  {
    date: "06/09/2024",
    type: "Enhancement",
    details: "Archiving recruits is now possible.",
  },
  {
    date: "12/08/2024",
    type: "Enhancement",
    details: "Prevent access to add/edit your own course result, competency result, requirement records & Change operational status.",
  },
  {
    date: "09/08/2024",
    type: "Bug Fix",
    details: "General bug fixes and improvements recently reported.",
  },
  {
    date: "19/07/2024",
    type: "Bug Fix",
    details: "General bug fixes and improvements recently reported.",
  },
  {
    date: "08/07/2024",
    type: "Bug Fix",
    details: "General bug fixes and improvements recently reported.",
  },
  {
    date: "03/07/2024",
    type: "Bug Fix",
    details: "Fixed issue with What3Words incorrectly generating the wrong words from a Postcode/Address lookup on event locations.",
  },
  {
    date: "27/06/2024",
    type: "Bug Fix",
    details: "Fixed issue with importing SarSYS incidents were retrieving the period start and end times were blank.",
  },
  {
    date: "25/06/2024",
    type: "Bug Fix",
    details: "General bug fixes and improvements recently reported.",
  },
  {
    date: "07/06/2024",
    type: "Bug Fix",
    details: "Fixed app permissions, resolved issue with asset search not filtering results.",
  },
  {
    date: "20/05/2024",
    type: "Enhancement",
    details: "Added the ability to store some notes within vetting for Team Requirements",
  },
  {
    date: "20/05/2024",
    type: "Bug Fix",
    details: "Fixed event editing now allowing type to be changed after creation.",
  },
  {
    date: "13/05/2024",
    type: "Bug Fix",
    details: "Bug fixes on documents updated date, member join date now editable & what3words map-click being incorrect within locations.",
  },
  {
    date: "24/04/2024",
    type: "Enhancement",
    details: "Brought over the member activity report from old TeamSite.",
  },
  {
    date: "15/04/2024",
    type: "Enhancement",
    details: "Added the ability to delete Team Requirements within settings.",
  },
  {
    date: "15/04/2024",
    type: "Bug Fix",
    details: "A recruit event now shows recruit attendance as well as team members who marked themselves via the app.",
  },
  {
    date: "12/04/2024",
    type: "Bug Fix",
    details: "General bug fixes and improvements recently reported.",
  },
  {
    date: "03/04/2024",
    type: "Enhancement",
    details: "Show required courses on a team members dashboard.",
  },
  {
    date: "27/03/2024",
    type: "Bug Fix",
    details: "Fixing issues relating to the documents store, display the total used storage and quota limit.",
  },
  {
    date: "25/03/2024",
    type: "Bug Fix",
    details: "Fixing vehicles list not showing the correct MOT status flag.",
  },
  {
    date: "21/03/2024",
    type: "Bug Fix",
    details: "Fixing bugs relating Next of Kin management for members and recruits.",
  },
  {
    date: "14/03/2024",
    type: "Enhancement",
    details: "Improved the interface for managing competency sessions and the members scores.",
  },
  {
    date: "13/03/2024",
    type: "Enhancement",
    details: "General tidy up and enhancements like alphabetical sort of lists",
  },
  {
    date: "08/03/2024",
    type: "Enhancement",
    details: "Resolve issue with Competency Sessions not loading on member page, added ability to edit and delete the session.",
  },
  {
    date: "16/02/2024",
    type: "Bug Fix",
    details: "Fixing bugs relating to when a existing team is migrated from TeamSite v4.",
  },
  {
    date: "06/02/2024",
    type: "Enhancement",
    details: "Better UI for managing competency results and for marking as attending or not.",
  },
  {
    date: "01/02/2024",
    type: "Bug Fix",
    details: "Many highlighted bugs have been resolved with some new features like deleting events, vehicles and archiving team members now working.",
  },
  {
    date: "08/01/2024",
    type: "Happy Birthday",
    details: "Release new version to production.",
  }
];
