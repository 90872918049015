import React, { useMemo } from "react";
import {XYZ} from "ol/source";
import CustomTileLayer from "../custom";

const OS_Local = ({ visible = true, zIndex = 0}) => {

  const source = useMemo(() => new XYZ({
    url: "https://api.os.uk/maps/raster/v1/wmts?key=V7IAHdsFFMvH8RHPl1r1k3X0lVo7u0WG&service=WMTS&request=GetTile&version=2.0.0&height=256&width=256&outputFormat=image/png&style=default&layer=Road_3857&tileMatrixSet=EPSG:3857&tileMatrix={z}&tileRow={y}&tileCol={x}",
    projection: "EPSG:3857",
    attributions: [
      '© <a href="https://www.ordnancesurvey.co.uk/" target="_blank">Ordnance Survey</a>',
    ],
    attributionsCollapsible: false,
  }), []);

  return (
    <CustomTileLayer source={source} visible={visible} zIndex={zIndex} />
  );
}
export default OS_Local;