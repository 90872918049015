import React, { useState, useEffect } from "react";
import SettingsReceiver from "../../contexts/settings_context/Settings_Receiver";
import OrganisationReceiver from "../../contexts/organisation/receiver";
import ChainedContext from "../../contexts/chainedContext";
import api from "../../api";
import { toast } from "react-hot-toast";
import { TrashIcon } from "@heroicons/react/20/solid";
import { Dialog, Transition } from "@headlessui/react";
import { PencilIcon } from "@heroicons/react/24/outline";
import Box from "../../components/common/Box";
import Select from "../../components/common/Select";
import ToggleSwitch from "../../components/common/ToggleSwitch";

const CPD_Settings = ({ settings }) => {
  const [roles, setRoles] = useState([]);
  const [cpds, setCpds] = useState([]);
  const [role, setRole] = useState("");
  const [type, setType] = useState("");
  const [hours, setHours] = useState(0);
  const [days, setDays] = useState(0);
  const [timeFrame, setTimeFrame] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [instructorOnly, setInstructorOnly] = useState(false);
  const [editID, setEditID] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [del, setDelete] = useState("");
  const [error, setError] = useState(null);
  const [cpdCheck, setCPDCheck] = useState(settings.cpdRoleCheck);
  const [update, setUpdate] = useState(false);

  useEffect(() => {
    settings._refresh();
    getData();
  }, []);
  useEffect(() => {
    if (update === true) {
      onSettingsSubmit({ cpdRoleCheck: cpdCheck });
    }
  }, [cpdCheck]);

  const numbers = [];
  for (let i = 1; i < 32; i++) {
    // console.log("i", i);
    numbers.push(
      <option key={i} value={i}>
        {i}
      </option>
    );
  }

  const getData = () => {
    api.settings.getCPDConfig().then((res) => {
      setCpds(res);
    });
    api.settings.getRoles().then((res) => {
      setRoles(res);
    });
  };
  const handleEdit = (item) => {
    setRole(item.role._id);
    setType(item.type);
    setHours(item.hours);
    setDays(item.within);
    setTimeFrame(item.withinType);
    setEditID(item._id);
    setIsEdit(true);
    setInstructorOnly(item.instructorOnly);
  };

  const validateForm = () => {
    if(parseInt(hours) === 0 || hours + "" === ""){
      toast.error("'Hours' must be not be blank.")
      return false;
    }
    if(isNaN(parseInt(hours))){
      toast.error("'Hours' must be a number.")
      return false;
    }
    if(days + "" === "0"){
      toast.error("'Every' must be not be blank.")
      return false;
    }
    if(timeFrame === ""){
      toast.error("'Duration' must be not be blank.")
      return false;
    }

    return true;
  }
  const onUpdate = () => {
    if(!validateForm()) return;

    const data = {
      role,
      hours,
      within: days,
      withinType: timeFrame,
      type,
      instructorOnly,
    };
    toast.promise(
      api.settings.updateCPDConfig(editID, data).then(() => {
        getData();
        setRole("");
        setType("");
        setHours(0);
        setDays(0);
        setTimeFrame("");
        setEditID(null);
        setIsEdit(false);
        setInstructorOnly(false);
      }),
      {
        loading: "Updating...",
        success: "CPD config updated.",
        error: (err) => `This just happened: ${err.toString()}`,
      }
    );
  };
  const onSubmit = () => {
    if(!validateForm()) return;

    const data = {
      role,
      hours,
      within: days,
      withinType: timeFrame,
      type,
    };
    toast.promise(
      api.settings.addCPDConfig(data).then((res) => {
        getData();
        setRole("");
        setType("");
        setHours(0);
        setDays(0);
        setTimeFrame("");
        setInstructorOnly(false);
      }),
      {
        loading: "adding...",
        success: "CPD config added.",
        error: (err) => `This just happened: ${err.toString()}`,
      }
    );
  };
  const onSettingsSubmit = async (data) => {
    try {
      await api.settings.updateSystem(data);
      settings._refresh();
      setUpdate(false);
      toast.success("Settings updated.");
    } catch (err) {
      console.error("Unable to save", err);
    }
  };
  const handleDelete = () => {
    setError(null);
    api.settings
      .deleteCPDConfig(del._id)
      .then((res) => {
        console.log("res", res);
        setIsOpen(false);
        getData();
        toast.success("CPD deleted.");
      })
      .catch((err) => {
        let errorMessage = "There has been and error";
        if (err.isAxiosError !== undefined) {
          if (err.response.data.message !== undefined) {
            errorMessage = err.response.data.message;
          }
        }
        setError(errorMessage);
      });
  };
  const DeleteDialog = () => {
    return (
      <Transition
        show={isOpen}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Dialog
          open={isOpen}
          onClose={() => setIsOpen(false)}
          className="fixed inset-0 z-10 overflow-y-auto "
        >
          <div className="min-h-screen text-center">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-80" />
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className="my-8 inline-block w-full max-w-2xl transform overflow-hidden rounded-2xl border-4 border-gray-700 bg-gray-50 p-6 text-left align-middle shadow-xl transition-all dark:bg-zinc-700">
              <div className="pt-4 text-center">
                <Dialog.Title className={"text-2xl"}>
                  Delete CPD Requirement{" "}
                  <span className="font-bold italic">{del.name}</span>
                </Dialog.Title>
                <div className="py-4 text-ss">
                  This action is not reversable so please be sure!
                </div>
                {error && (
                  <div className="py-4 text-xl text-red-500">{error}</div>
                )}
              </div>

              <div className="mt-4 flex justify-between border-t border-zinc-500 pt-4">
                <button
                  className="rounded-lg bg-primary px-4 py-2 text-white"
                  onClick={() => setIsOpen(false)}
                >
                  Cancel
                </button>
                <button
                  className="rounded-lg bg-red-500 px-4 py-2 text-white disabled:bg-red-500"
                  onClick={handleDelete}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  };

  return (
    <div className="space-y-4 p-4">
      {DeleteDialog()}

      <Box title="CPD Settings">
        <div className="p-2">
          <span className="text-primary dark:text-primary-dark">
            Roles requiring CPD:
          </span>
          {roles
            .filter((role) => {
              return role.requiresCPD === true;
            })
            .sort((a, b) => {
              if(a.name > b.name ) return 1;
              if(a.name < b.name ) return -1;
              return 0;
            })
            .map((r, i) => {
              return (
                <span
                  key={i}
                  className="m-1 rounded bg-zinc-200 px-2 py-1 text-sm dark:bg-zinc-700 "
                >
                  {r.name}
                </span>
              );
            })}
        </div>
        <div className="mt-4 space-y-2 p-2">
          <p className="text-primary dark:text-primary-dark">
            Should lack of CPD mark a role as inactive?
          </p>
          <ToggleSwitch
            isChecked={cpdCheck}
            label1={"No"}
            label2={"Yes"}
            onChange={() => {
              setUpdate(true);
              setCPDCheck(!cpdCheck);
            }}
          />
        </div>
      </Box>
      <Box title="Configue CPD Requirements">
        <div className="flex flex-wrap items-end gap-2 p-2">
          <div className="w-80">
            {/* <label className="labelClass">Role</label> */}
            <Select
              label={"Role"}
              options={roles
                .filter((role1) => {
                  return role1.requiresCPD === true;
                })
                .map((item) => {
                  return {
                    key: item._id,
                    text: item.name,
                    value: item._id,
                  };
                })}
              value={role}
              onChange={(e) => setRole(e.target.value)}
            />
          </div>
          <div>
            {/* role requires */}
            <div className="w-20">
              <label className="labelClass">Hours</label>
              <input
                value={hours}
                className="inputClass w-20"
                type={"text"}
                onChange={(e) => setHours(e.target.value)}
                placeholder="role..."
              />
            </div>
          </div>
          {/* <div> hours of CPD every</div> */}
          <div className="w-20">
            <label className="labelClass">Every</label>
            <select
              value={days}
              onChange={(e) => setDays(e.target.value)}
              className={`inputClass p-1.5`}
            >
              <option value={0}>--</option>
              {numbers}
            </select>
            {/* <Select
             label="Duration"
              options={numbers}
              value={days}
              onChange={(e) => setDays(e.target.value)}
            /> */}
          </div>
          <div className="w-36">
            {/* <label className="labelClass">Duration</label> */}
            <Select
              label="Duration"
              options={[
                {
                  key: 0,
                  text: "days",
                  value: "days",
                },
                {
                  key: 1,
                  text: "months",
                  value: "months",
                },
                {
                  key: 2,
                  text: "years",
                  value: "years",
                },
              ]}
              value={timeFrame}
              onChange={(e) => setTimeFrame(e.target.value)}
            />
          </div>
          <div className="w-80">
            <input
              value={type}
              className=" inputClass w-full"
              type={"text"}
              onChange={(e) => setType(e.target.value)}
              placeholder="requirements if needed..."
            />
          </div>
          {/* <div className="mr-2 text-center">
            <label className="labelClass">Instructor Lead only CPD</label>
            <ToggleSwitch
              isChecked={instructorOnly}
              label1={"No"}
              label2={"Yes"}
              onChange={() => {
                setUpdate(true);
                setInstructorOnly(!instructorOnly);
              }}
            />
          </div> */}
          <button
            disabled={role === "" ? true : false}
            onClick={isEdit ? onUpdate : onSubmit}
            className=" h-10 cursor-pointer rounded-md border border-green-500 bg-green-300 bg-opacity-40   px-4 py-2 text-green-500  hover:border-green-300"
          >
            {isEdit ? "Update" : "Add"}
          </button>
        </div>
      </Box>
      <Box title="CPD Requirements">
        <table className="tableClass">
          <thead className="tableHeadClass">
            <tr className="tableHeadRowClass ">
              <th className="tableHeadCellClass">Role</th>
              <th className="tableHeadCellClass">Hours</th>
              <th className="tableHeadCellClass">Number</th>
              <th className="tableHeadCellClass">Timeframe</th>
              <th className="tableHeadCellClass">Requirements</th>
              {/* <th className="tableHeadCellClass">Instructor Only</th> */}
              <th className="tableHeadCellClass"></th>
            </tr>
          </thead>
          <tbody className="tableBodyClass">
            {cpds &&
              cpds?.map((d) => {
                return (
                  <tr className="tableBodyRowClass " key={d._id}>
                    <td className="tableBodyCellClass">{d.role === null ? "[NO ROLE ASSIGNED]" : d.role.name} </td>
                    <td className="tableBodyCellClass  text-center   ">
                      {d.hours}
                    </td>
                    <td className="tableBodyCellClass text-center ">
                      {d.within}
                    </td>
                    <td className="tableBodyCellClass text-center ">
                      {d.withinType}
                    </td>
                    <td className="tableBodyCellClass text-left ">{d.type}</td>

                    {/* <td className="tableBodyCellClass w-44 text-left">
                      {d.instructorOnly ? "Yes" : "No"}
                    </td> */}
                    <td className="tableBodyCellClass w-32 space-x-1 text-center">
                      <button
                        // disabled={d.isAvailableDefault || d.isAssignedDefault}
                        onClick={() => handleEdit(d)}
                        className="rounded-md border bg-primary bg-opacity-80  px-2 py-1 text-sm  text-white hover:bg-gray-800 disabled:opacity-20"
                      >
                        <PencilIcon className="h-6 w-6 text-yellow-200 " />
                      </button>
                      <button
                        className="rounded-md border border-red-400 bg-red-700 bg-opacity-80 px-2 py-1 text-sm text-white hover:border-red-300 hover:bg-red-800 disabled:opacity-20"
                        onClick={() => {
                          setDelete(d);
                          setIsOpen(true);
                        }}
                      >
                        <TrashIcon className="h-6 w-6 text-red-200" />
                      </button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </Box>
    </div>
  );
};
export default ChainedContext(CPD_Settings, [
  [SettingsReceiver, "settings"],
  [OrganisationReceiver, "organisation"],
]);
