import React from "react";
import {Link, Route, Routes} from "react-router-dom";
import Dashboard from "./dashboard";
import ManageSession from "./manageSession";
import {AuthComponents} from "../../../lib/_sso";

const Competency = () => {
const scope = "training:competencies:read";

  return (
    <>
      <AuthComponents.Can scope={scope}>
          <Routes>
            <Route index element={<Dashboard />} />
            <Route path={"/session/:collectionId"} element={<ManageSession />} />
            <Route path="*" element={<NoMatch2 />} />
          </Routes>
      </AuthComponents.Can>
      <AuthComponents.CanNot scope={scope}>
        <div className={"p-2 text-red-500"}>Access Denied: You do not have permission to access this page.</div>
      </AuthComponents.CanNot>
    </>

  );
};
export default Competency;

function NoMatch2() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}