import React, { useState, useEffect } from "react";
import Member from "./Member_Context";
import { useParams, useNavigate, redirect } from "react-router-dom";
import Loader from "../../components/common/Loader";

function Member_Receiver({ api, children }) {
  const { member_id } = useParams();
  const [firstLoad, setFirstLoad] = useState(true);
  const [member, setMember] = useState({});

  useEffect(() => {
    _refresh();
  }, []);

  const _refresh = () => {
    api.member
      .show(member_id)
      .then((res) => {
        setMember(res);
        setFirstLoad(false);
      })
      .catch((err) => {
        console.log("err", err.response.status);
        if (err.response.status === 404) {
          console.log("here");
          // redirect("/login");
        }
        return err;
      });
  };
  return (
    <Member.Provider
      value={{
        _refresh: _refresh,
        ...member,
      }}
    >
      {firstLoad ? <Loader /> : children}
    </Member.Provider>
  );
}
export default Member_Receiver;
