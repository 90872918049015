import React from "react";
import {
  CheckIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import dayjs from "dayjs";

export default function VehicleList({ vehicles = [], vehicle }) {

  const dateCheck = (type, date, yearOfManufacture) => {
    if (type === "tax") {
      if (date > dayjs().format("YYYY-MM-DD")) {
        return true;
      }
    }
    if (type === "ins") {
      if (date > dayjs().format("YYYY-MM-DD")) {
        return true;
      }
    }
    if (type === "mot") {
      if (yearOfManufacture) {
        if (yearOfManufacture > dayjs().subtract(3, "year").format("YYYY")) {
          return true;
        }
      }
      if (date > dayjs().format("YYYY-MM-DD")) {
        return true;
      }
    }
    return false;
  };

  return (
    <table className="tableClass">
      <thead>
        <tr className="tableHeadRowClass">
          <th
            onClick={() => setSortByName(!sortByName)}
            className="tableHeadCellClass flex gap-1  "
          >
            Make Model
          </th>
          <th className="tableHeadCellClass ">Registration</th>
          <th className="tableHeadCellClass ">Seats</th>
          <th className="tableHeadCellClass ">Doors</th>
          <th className="tableHeadCellClass hidden xl:table-cell">Kit </th>
          <th className="tableHeadCellClass hidden xl:table-cell">
            Statutory{" "}
          </th>
          <th
            onClick={() => setSortByName(!sortByName)}
            className="tableHeadCellClass flex justify-center gap-1 text-center  "
          >
            On-call/Available
          </th>
        </tr>
      </thead>
      <tbody className="tableBodyClass">
        {vehicles.length !== 0 ? (
          vehicles.map((u) => {
            const tax = dateCheck("tax", u.taxDueDate);
            const ins = dateCheck("ins", u.insuranceExpiry);
            const mot = dateCheck("mot", u.taxDueDate, u.yearOfManufacture);
            return (
              <tr
                key={u._id}
                onClick={() => vehicle(u._id)}
                className={`tableBodyRowClass cursor-pointer ${u.approved ? "oncall" : "nonOp"}`}
              >
                <td className="tableBodyCellClass">
                  <span className="text-lg">
                    {u.make} {u.model}
                  </span>
                  <br />
                  <span className="tableBodyCellClass">
                    {u.unitVehicle === false
                      ? (u.member !== undefined ? (u.member.firstName + " " + u.member.lastName + " (" + u.member.callsign + ")") : "[Unknown Member]") :
                      u.callsign ? u.callsign && (<span className="italic">({u.callsign})</span>) : ""}
                  </span>
                </td>
                <td className="tableBodyCellClass w-36 text-lg tracking-wider">
                  {u.regNumber.toUpperCase()}
                </td>
                <td className="tableBodyCellClass w-20 text-center text-lg">
                  {u.seats}
                </td>

                <td className="tableBodyCellClass w-20 text-center text-lg">
                  {u.doors}
                </td>
                <td className="tableBodyCellClass hidden xl:table-cell">
                  <div className="flex flex-wrap gap-1 text-sm">
                    {u.kit.map((r) => {
                      return (
                        <div
                          key={r}
                          className={`rounded bg-zinc-200 px-2 py-1 dark:bg-zinc-700 `}
                        >
                          <div>{r}</div>
                        </div>
                      );
                    })}
                  </div>
                </td>
                <td className="tableBodyCellClass hidden w-52 text-center xl:table-cell">
                  <div className="flex justify-center gap-4 ">
                    <span
                      className={`text-xs ${
                        mot
                          ? "text-green-800 dark:text-green-400"
                          : "text-red-500"
                      } `}
                    >
                      MoT
                    </span>
                    <span
                      className={`text-xs ${
                        ins
                          ? "text-green-800 dark:text-green-400"
                          : "text-red-500"
                      } `}
                    >
                      Ins
                    </span>
                    <span
                      className={`text-xs ${
                        tax
                          ? "text-green-800 dark:text-green-400"
                          : "text-red-500"
                      } `}
                    >
                      Tax
                    </span>
                  </div>
                </td>
                <td className="tableBodyCellClass w-56 text-center">
                  {u.unitVehicle === false ? (
                    (u.member !== undefined && u.member.onCall) ? (
                      <CheckIcon className="mx-auto h-8 text-green-700 dark:text-green-400" />
                    ) : (
                      <XMarkIcon className="mx-auto h-8 text-red-700" />
                    )
                  ) : u.approved ? (
                    <CheckIcon className="mx-auto h-8 text-green-700 dark:text-green-400" />
                  ) : (
                    <XMarkIcon className="mx-auto h-8 text-red-700" />
                  )}
                </td>
              </tr>
            );
          })
        ) : (
          <tr>
            <td colSpan={7} className="tableBodyCellClass">
              No vehicles
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
}