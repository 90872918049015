import React, { useRef } from "react";
import Geosuggest from "react-geosuggest";
import ChainedContext from "../../../contexts/chainedContext";
import SettingsReceiver from "../../../contexts/settings_context/Settings_Receiver";
import CommonFunctions from "../../../lib/CommonFunctions";

const AddressForm = ({ settings, onLocationChange, location }) => {
  const _geoSuggest = useRef(null);

  const handleOnSuggestSelect = async (suggest) => {
    if (suggest) {
      const coordinates = [suggest.location.lng, suggest.location.lat];
      const grid = CommonFunctions.getGrid(
        coordinates[1],
        coordinates[0],
        settings.gridType
      ); // lat, lng
      const w3w = await CommonFunctions.getW3w(coordinates[1], coordinates[0]);

      let components = suggest.gmaps.address_components;
      let admin_area = "";
      let postal_town = "";
      let postal_code = "";
      let name = suggest.gmaps.name ? suggest.gmaps.name : "";
      let county = "";
      let town = "";

      for (let component = 0; component < components.length; component++) {
        if (components[component].types[0] === "premise" && name === "") {
          name = components[component].long_name;
        }
        if (components[component].types[0] === "locality") {
          admin_area = components[component].long_name;
        }
        if (components[component].types[0] === "postal_town") {
          postal_town = components[component].long_name;
        }
        if (components[component].types[0] === "administrative_area_level_2") {
          county = components[component].long_name;
        }
        if (components[component].types[0] === "postal_code") {
          postal_code = components[component].long_name;
        }
        if (admin_area) {
          town = admin_area;
        } else {
          town = postal_town;
        }
      }

      let geoJSON = {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: coordinates,
        },
        properties: {
          ...(location?.properties || {}),
          name,
          // w3w: w3w !== null ? w3w.split(".") : [],
          w3w: w3w !== null ? w3w : "",
          // w3w: w3w !== null ? w3w.split(".") : [], //not sure why we arent using w3w.words
          grid: grid.toUpperCase(),

          // Address fields
          town: town,
          county: county,
          postcode: postal_code,
          address: suggest.gmaps.formatted_address,
        },
      };
      onLocationChange(geoJSON);
    }
  };

  return (
    <div className="w-full items-center">
      <Geosuggest
        autoComplete="off"
        ref={_geoSuggest}
        placeholder="Molash, Kent"
        inputClassName="w-full rounded-md border border-gray-600 bg-gray-200 p-2 uppercase placeholder-gray-400 dark:bg-zinc-700"
        country={settings.country}
        // location={new window.google.maps.LatLng(area[1], area[0])}
        // radius={10000}
        onSuggestSelect={handleOnSuggestSelect}
        suggestsClassName="bg-zinc-200 dark:bg-zinc-700 "
        suggestsHiddenClassName="geosuggest__suggests--hidden"
        suggestItemClassName="px-1 py-2 border-b border-gray-500 cursor-pointer dark:hover:bg-zinc-500"
      />
    </div>
  );
};
export default ChainedContext(AddressForm, [[SettingsReceiver, "settings"]]);
