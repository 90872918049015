import React, { Component } from "react";
import { Routes, Route, Outlet, Link } from "react-router-dom";
import MainPage from "./MainPage";
import CompetencyPage from "./CompetencySettingsPage";
import Nav from "./_components/NavBar";
import CPD_Settings from "./CPD_Settings";
import VehicleSettings from "./VehicleSettings";
import Courses from "./Courses";
import Logs from "./Logs";
import TeamSettings from "./TeamSettings";
import RecruitmentSettings from "./RecruitmentSettings";
import ChangeLog from "./ChangeLog";
import ImporterPage from "./importer";
import {AuthComponents} from "../../lib/_sso";

export default class SettingsPages extends Component {
  render() {
    return (
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<MainPage />} />
          <Route path="/team" element={<TeamSettings />} />
          <Route path="/vehicle" element={<VehicleSettings />} />
          <Route path="/competency" element={<CompetencyPage />} />
          <Route path="/cpd" element={<CPD_Settings />} />
          <Route path="/recruitment" element={<RecruitmentSettings />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/logs" element={<Logs />} />
          <Route path="/change_log" element={<ChangeLog />} />
          <Route path="/importer" element={<ImporterPage />} />
          <Route path="*" element={<NoMatch2 />} />
        </Route>
      </Routes>
    );
  }
}
function Layout() {
  const scope = "settings:update";

  return (
    <div className="min-h-full">
      <Nav />
      <main>
        <AuthComponents.Can scope={scope}>
          <Outlet />
        </AuthComponents.Can>
        <AuthComponents.CanNot scope={scope}>
          <div className={"p-2 text-red-500"}>Access Denied: You do not have permission to access this page.</div>
        </AuthComponents.CanNot>
      </main>
    </div>
  );
}
function NoMatch2() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the settings page</Link>
      </p>
    </div>
  );
}