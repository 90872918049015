import React from "react";
import DataRow from "./DataRow";

const DataRowRoles = ({ title = "Roles", roles = [] }) => {

  if(!roles || roles.length === 0){
    return (
      <DataRow label={title} value={"--"} />
    );
  }
  return (
   <DataRow label={title} value={<div className={"ml-6 flex flex-wrap gap-2 justify-end"}>
      {roles.map(role => <span key={role} className={"rounded border p-2 text-sm border-zinc-800 dark:border-zinc-500"}>{role}</span>)}
    </div>} />
  );
}
export default DataRowRoles;