import React, { useState, useEffect } from "react";
import LocationManager from "../../components/locationManager";
import api from "../../api";
import {Authentication} from "../../lib/_sso";

const Locations = () => {
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    api.locations.all().then((res) => {
      setLocations(res);
    });
  };

  return (
    <div className="p-4">
      <LocationManager
        canAdd={Authentication.can("teamsite:admin") || Authentication.can("teamsite:management")}
        canEdit={Authentication.can("teamsite:admin") || Authentication.can("teamsite:management")}
        canDelete={Authentication.can("locations:delete")}
        locations={locations}
        handleAddLocation={(data) => {
          return api.locations.add(data).then(() => {
            getData();
          });
        }}
        handleEditLocation={(data) => {
          return api.locations.update(data._id, data).then(() => {
            getData();
          });
        }}
        handleDeleteLocation={(data) => {
          return api.locations.delete(data._id).then(() => {
            getData();
          });
        }}

        hideExtra={true}
        hideOrdering
      />
    </div>
  );
}
export default Locations;
