import React, {useEffect, useState, useMemo, useCallback} from "react";
import {useNavigate, useParams} from "react-router-dom";
import DayJS from "dayjs";
import Toast from "react-hot-toast";
import {Tab} from "@headlessui/react";
import {
  CheckIcon,
  PencilIcon,
  PlusCircleIcon,
  PrinterIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import fileDownloader from "js-file-download";
import API from "../../api";
import Box from "../../components/common/Box";
import DataRow from "../../components/common/DataRow";
import MappingDefaults from "../../components/mapping_defaults";
import {Layers, Map} from "../../components/sarstuff_mapping";
import * as MapStyles from "../../components/mapping_styles";
import {AuthComponents} from "../../lib/_sso";
import Button from "../../components/common/Button";
import PdfPrintingProgress from "../../components/common/PdfPrintingProgress";
import AttendanceList from "./_components/AttendanceList";
import AttendanceListWithResults from "./_components/AttendanceListWithResults";
import NewLineBreaker from "../../components/common/NewLineBreaker";


const returnTabButtonStyle = (selected) => {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return classNames(
    "w-full py-2.5 text-lg leading-5 font-medium  rounded-lg",
    selected
      ? "text-gray-100 bg-green-900 bg-opacity-50"
      : "text-blue-100 hover:bg-white/[0.12] hover:text-white bg-gray-800"
  );
};

const EventView = () => {
  const {event_id} = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({});
  const [attendance, setAttendance] = useState({});
  const [organiser, setOrganiser] = useState({});
  const [printingError, setPrintingError] = useState(null);
  const [isPrinting, setIsPrinting] = useState(false);

  const members = useMemo(() => {
    return attendance.people || []
  }, [attendance]);

  const attending = useMemo(() => {
    return members.map((a) => {
      if (data.Responses !== undefined) {
        const found = data?.Responses.find(
          (r) => r.member?._id === a._id && r.response === true
        );
        if(found !== undefined){
          return {
            ...a,
            _respondedAt: found.respondedAt !== undefined ? found.respondedAt : found.updatedAt
          }
        }
      }
      return false;
    }).filter(a => a !== false);
  }, [data, members]);
  const notAttending = useMemo(() => {
    return members.map((a) => {
      if (data.Responses !== undefined) {
        const found = data?.Responses.find(
          (r) => r.member?._id === a._id && r.response === false
        );
        if(found !== undefined){
          return {
            ...a,
            _respondedAt: found.respondedAt !== undefined ? found.respondedAt : found.updatedAt
          }
        }
      }
      return false;
    }).filter(a => a !== false);
  }, [data, members]);
  const notResponded = useMemo(() => {
    return members.filter((a) => {
      if (data.Responses !== undefined) {
        const found = data?.Responses.find((r) => r.member?._id === a._id);
        return found === undefined;
      }
    });
  }, [data, members]);

  const getData = useCallback(() => {
    API.calendar.getEvent(event_id).then((res) => {
      setData(res);
      setIsLoading(false);
      if (res.organiserID?._id) {
        setOrganiser(res.organiserID);
      }
    });
    API.calendar.retrieveAttendance(event_id).then(res => {
      setAttendance(res);
    });
  }, [event_id]);
  const handle_response = (member, r) => {
    const response = {
      event: data._id,
      member: member._id,
      response: r,
    };
    API.calendar
    .markResponse(event_id, response)
    .then(() => {
      getData();
      Toast.success(
        `Successfully changed attendance to: ${
          r ? "Attending" : "Not Attending"
        }`
      );
    })
    .catch(() => {
      Toast.error(
        "There was an error while updating attendance, try again shortly."
      );
    });
  };
  const handlePrint = () => {
    setPrintingError(null);
    setIsPrinting(true);
    API.calendar
    .printEvent(data._id)
    .then((theData) => {
      fileDownloader(theData, `${data.title}.pdf`);
      setIsPrinting(false);
    })
    .catch((err) => {
      if (err.response !== undefined) {
        setPrintingError(err.response.data.message);
      } else {
        setPrintingError(
          "There was an unexpected error while creating the PDF, please try again later or contact support if issues continues."
        );
      }
    });
  };

  useEffect(() => {
    getData();
  }, [getData]);
  useEffect(() => {
    if (data.organiser) {
      const found = members.find((m) => m._id === data.organiser);
      setOrganiser({
        name: found?.firstName
          ? found?.firstName
          : "--" + " " + found?.lastName
            ? found?.lastName
            : "--",
        phone: found?.phone,
        email: found?.email,
      });
    }
  }, [data]);


  if (isLoading) {
    return <div className={"p-2 text-2xl"}>Loading Event Data...</div>;
  }

  return (
    <div className={"p-2"}>
      <PdfPrintingProgress
        active={isPrinting}
        error={printingError}
        onClose={() => setIsPrinting(false)}
      />
      <div className={"grid gap-4 2xl:grid-cols-4"}>
        <div className={"space-y-4 2xl:col-span-2"}>
          <Box title="Event Details">
            <div className="flex justify-around gap-1 bg-zinc-200 p-2 dark:bg-zinc-800">
              <Button
                text="Edit"
                icon={<PencilIcon className="h-6 pr-2 text-blue-400"/>}
                onClick={() => navigate(`/events/event/${data._id}/edit`)}
              />
              <Button
                text="Print"
                icon={<PrinterIcon className="h-6 pr-2 text-blue-400"/>}
                onClick={handlePrint}
              />
                <Button
                  text="Copy"
                  icon={<PlusCircleIcon className="h-6 pr-2 text-green-400"/>}
                  onClick={() => navigate(`/events/event/${data._id}/clone`)}
                />
            </div>

            <DataRow label={"Title"} value={data.title}/>
            {data.isCpdEvent ? <DataRow
              label={"Type"}
              value={"Instructor Led CPD"}
            /> : <DataRow
              label={"Type"}
              value={data.eventType ? data.eventType : " -- "}
            />}
            <div className="grid items-end lg:grid-cols-2">
              <DataRow
                label={"Start"}
                value={DayJS(data.start).format("DD-MM-YYYY HH:mm")}
              />
              <DataRow
                label={"End"}
                value={
                  data.end ? DayJS(data.end).format("DD-MM-YYYY HH:mm") : " -- "
                }
              />
            </div>
            <DataRow label={"Details"} value={<NewLineBreaker value={data.description} />} />
            <div className="grid space-y-2 pb-2 dark:border-zinc-700 lg:grid-cols-3">
              <DataRow
                label={"Allow Signup"}
                value={
                  data.canSignUp ? (
                    <CheckIcon className="h-6 text-green-500"/>
                  ) : (
                    <XMarkIcon className="h-6 text-red-600"/>
                  )
                }
              />
              <DataRow
                label={"Restricted Signup"}
                value={
                  data.signupRoles !== undefined && data.signupRoles.length > 0 ? (
                    <CheckIcon className="h-6 text-green-500"/>
                  ) : (
                    <XMarkIcon className="h-6 text-red-600"/>
                  )
                }
              />
              <DataRow
                label={"All Day Event"}
                value={
                  data.allDay ? (
                    <CheckIcon className="h-6 text-green-500"/>
                  ) : (
                    <XMarkIcon className="h-6 text-red-600"/>
                  )
                }
              />
            </div>
            <div className="grid space-y-2 border-b  border-zinc-300 pb-2 dark:border-zinc-700 lg:grid-cols-2">
              <div className="col-span-2  text-center lg:col-span-1">
                <span className="text-primary dark:text-primary-dark">
                  Organiser
                </span>
                <div className="ml-4 mt-1 text-left text-black dark:text-white">
                  <div className="text-zinc-400">
                    Name:{" "}
                    <span className="text-black dark:text-white">
                      {data.organiser}
                    </span>
                  </div>
                  <div className="text-zinc-400">
                    Phone:{" "}
                    <span className="text-black dark:text-white">
                      {organiser.phone}
                    </span>
                  </div>
                  <div className="text-zinc-400">
                    Email:{" "}
                    <span className="text-black dark:text-white">
                      {organiser.email}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-span-2 text-center lg:col-span-1">
                <span className="text-primary dark:text-primary-dark">
                  {" "}
                  Location
                </span>
                <div className="ml-4 mt-1 text-left text-black dark:text-white">
                  <div className="text-zinc-400">
                    Name:{" "}
                    <span className="text-black dark:text-white">
                      {data.properties?.name}
                    </span>
                  </div>
                  <div className="text-zinc-400">
                    Address:{" "}
                    <span className="text-black dark:text-white">
                      {data.properties?.address}
                    </span>
                  </div>
                  <div className="text-zinc-400">
                    w3w:{" "}
                    <span className="text-black dark:text-white">
                      {Array.isArray(data.properties?.w3w)
                        ? data.properties?.w3w.join(".")
                        : data.properties?.w3w}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <DataRow label="Private Notes" value={<NewLineBreaker value={data.note} />} />
            {data.webLinks !== undefined && data.webLinks.length !== 0 && <div className="col-span-2 text-center lg:col-span-1">
              <span className="text-primary dark:text-primary-dark">Web Links</span>
              <div className={"p-2 flex gap-2 flex-wrap"}>
                {data.webLinks.sort((a, b) => {
                  if(a.title > b.title) return 1;
                  if(a.title < b.title) return -1;
                  return 0;
                }).map((item, index) => {
                  return (
                    <a key={index} href={item.url} target={"_new"} className={"border rounded border-black dark:border-white hover:underline py-1 px-3"}>{item.title}</a>
                  );
                })}
              </div>
            </div>}
          </Box>
          {data.geometry?.coordinates !== undefined && (
            <Box title={"Location"}>
              <Map height={"50vh"} center={[0.8, 51]}>
                <MappingDefaults/>
                <Layers.BoundingBox active={true}>
                  <Layers.GeoJSON
                    geoJSON={data}
                    zIndex={10}
                    style={MapStyles.Marker}
                  />
                </Layers.BoundingBox>
              </Map>
            </Box>
          )}
        </div>
        <div className={"2xl:col-span-2"}>
          <Box title={"Attendance List"}>
            <Tab.Group defaultIndex={0}>
              <Tab.List
                className="flex flex-wrap space-x-1 border-b border-zinc-200 p-1  pb-2 dark:border-zinc-700 dark:bg-blue-900/20 md:flex-nowrap">
                <Tab
                  className={({selected}) => returnTabButtonStyle(selected)}
                >
                  Attending ({attending.length})
                </Tab>
                <Tab
                  className={({selected}) => returnTabButtonStyle(selected)}
                >
                  Not Attending ({notAttending.length})
                </Tab>
                <Tab
                  className={({selected}) => returnTabButtonStyle(selected)}
                >
                  Not Responded ({notResponded.length})
                </Tab>
              </Tab.List>
              <Tab.Panels>
                <Tab.Panel className={""}>
                  {data.isCpdEvent ? <AttendanceListWithResults
                    event={data}
                    onReload={getData}
                    items={attending}
                    actionButtons={[
                      {
                        title: "Not-Attending",
                        className: "ml-2 rounded p-2 offcall border text-sm dark:border-zinc-500",
                        onClick: (m) => handle_response(m, false),
                      },
                    ]}
                  /> : <AttendanceList
                    showResponsedAt={true}
                    items={attending}
                    actionButtons={[
                      {
                        title: "Not-Attending",
                        className: "ml-2 rounded p-2 offcall border text-sm dark:border-zinc-500",
                        onClick: (m) => handle_response(m, false),
                      },
                    ]}
                  />}
                </Tab.Panel>
                <Tab.Panel className={""}>
                  <AttendanceList
                    showResponsedAt={true}
                    items={notAttending}
                    actionButtons={[
                      {
                        title: "Attending",
                        className:
                          "rounded p-2 oncall border text-sm dark:border-zinc-500",
                        onClick: (m) => handle_response(m, true),
                      },
                    ]}
                  />
                </Tab.Panel>
                <Tab.Panel className={""}>
                  <AttendanceList
                    items={notResponded}
                    actionButtons={[
                      {
                        title: "Attending",
                        className:
                          "rounded p-2 oncall border  dark:border-zinc-500",
                        onClick: (m) => handle_response(m, true),
                      },
                      {
                        title: "Not-Attending",
                        className:
                          "md:ml-2 rounded p-2 offcall border  dark:border-zinc-500",
                        onClick: (m) => handle_response(m, false),
                      },
                    ]}
                  />
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </Box>
        </div>
      </div>
    </div>
  );
};
export default EventView;
