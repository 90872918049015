import React from "react";

const Layout = ({ children, full = false }) => {
  return (
    <main>
      <div
        className={`${full ? "w-full" : "max-w-screen-2xl"} mx-auto p-2 xl:p-4`}
      >
        <div className="">
          <div>{children}</div>
        </div>
      </div>
    </main>
  );
};
export default Layout;
