import React, {useEffect, useState, createContext, useContext} from 'react';
import SocketIO from "socket.io-client";
import {Authentication} from "../_sso";

const SocketIO_Context = createContext({
  socket: null
});

const SocketConnection = ({ socketServer = "/", children }) => {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const tempSocket = SocketIO(socketServer);
    tempSocket.on("connect", () => {
      tempSocket.emit("authentication", Authentication.getAccessToken());
    });
    tempSocket.on("authenticated", () => {
      setSocket(tempSocket);
    });
    tempSocket.on("disconnect", (a) => {
      console.log("socket disconnect: " + a);
    });
    return () => {
      tempSocket.disconnect();
      setSocket(null);
    }
  }, [socketServer]);

  return (
    <SocketIO_Context.Provider value={ socket }>{children}</SocketIO_Context.Provider>
  );
}

const useSocketIo = () => useContext(SocketIO_Context);


export {
  SocketConnection,
  useSocketIo
}