import { APIRequest } from "../lib/_sso";
import AppConfig from "../_appConfig";

const server = AppConfig.api_server;

export default {
  all: () => APIRequest.get(`${server}/locations`).then((res) => res.data),
  delete: (id) => APIRequest.delete(`${server}/locations/${id}`).then((res) => res.data),
  update: (id, data) => APIRequest.patch(`${server}/locations/${id}`, data).then((res) => res.data),
  add: (data) => APIRequest.post(`${server}/locations`, data).then((res) => res.data),
};
