import React, { Component } from "react";
import query_string from "query-string";
import Bridge from "../bridge";
import Authentication from "../Authentication";
import API from "../actions/api";

import "../styles.css";

const defaultConfig = {
  loginServer: "https://central.sarstuff.co.uk",
  applicationRoot:
    window.location.protocol +
    "//" +
    window.location.hostname +
    (window.location.port ? ":" + window.location.port : "") +
    "/",
};

const Loader = (props) => {
  return (
    <div style={{ padding: "1rem", textAlign: "center" }}>
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <span style={{ display: "block" }}>{props.children}</span>
    </div>
  );
};
const Segment = (props) => {
  return (
    <div style={{ padding: "1rem", textAlign: "center" }}>{props.children}</div>
  );
};
const Message = (props) => {
  return (
    <div
      className={`loginWidget-alert ${
        props.info !== undefined && "loginWidget-alert-info"
      } ${props.negative !== undefined && "loginWidget-alert-negative"} ${
        props.warning !== undefined && "loginWidget-alert-warning"
      }`}
    >
      {props.children}
    </div>
  );
};

export default class AuthenticationWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      reload: false,

      processing: false,
      loading: true,
      loggedIn: false,
      error: false,
      user: {},
      access_token: "",
    };
    Bridge.setConfig({
      ...defaultConfig,
      ...props.config,
    });
    Authentication.setComponent(this);
    Bridge.setContextHandlers(props.dynamicScopes || {});
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    Bridge.setConfig({
      ...defaultConfig,
      ...this.props.config,
    }); // Just in case any of the config options were changed after we have already mounted the config
    Bridge.setContextHandlers(this.props.dynamicScopes || {});
  }
  componentDidMount() {
    let urlParams = query_string.parse(window.location.search);
    if (urlParams.code !== undefined) {
      this.setState(
        {
          processing: true,
        },
        () => {
          this.login(urlParams.code);
        }
      );
      return;
    } else {
      this.setState({
        redirect: true,
      });
    }
    this.checkLogin();
  }

  checkLogin() {
    let config = Bridge.getRAWConfig();
    let access_token = localStorage.getItem(
      config.localPrefix + "access_token"
    );
    if (access_token !== undefined && access_token !== null) {
      API.auth
        .user_details(access_token)
        .then((res) => {
          Authentication.setUserData(res);
          Authentication.setUserScopes(res.scopes);
          Authentication.setAccessToken(access_token);
          localStorage.setItem(
            config.localPrefix + "access_token",
            access_token
          );
          try {
            this.setState({
              user: res,
              loading: false,
              loggedIn: true,
              access_token: access_token,
            });
          } catch (e) {
            console.error(e);
          }
        })
        .catch((err) => {
          console.error(err);
          localStorage.removeItem(config.localPrefix + "access_token");
          this.setState({
            loading: false,
            loggedIn: false,
            user: {},
            access_token: "",
          });
        });
    } else {
      localStorage.removeItem(config.localPrefix + "access_token");
      this.setState({
        loading: false,
        loggedIn: false,
        user: {},
        access_token: "",
      });
    }
  }
  login(code) {
    API.auth
      .exchange_code(code)
      .then((res) => {
        API.auth.user_details(res.access_token).then((details) => {
          Authentication.setUserData(details);
          Authentication.setAccessToken(res.access_token);
          Authentication.setUserScopes(details.scopes);
          let config = Bridge.getRAWConfig();
          localStorage.setItem(
            config.localPrefix + "access_token",
            res.access_token
          );
          this.setState({
            user: details,
            loading: false,
            processing: false,
            loggedIn: true,
            reload: true,
            access_token: res.access_token,
          });
        });
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 401) {
            if (err.response.data.error === "invalid_code") {
              this.setState({
                loading: false,
                loggedIn: false,
                processing: false,
                user: {},
                access_token: "",
                error: "Code Expired",
              });
              return;
            } else if (err.response.data.error === "access_denied") {
              this.setState({
                loading: false,
                loggedIn: false,
                processing: false,
                user: {},
                access_token: "",
                error: "Not Allowed",
              });
              return;
            }
          }
          this.setState({
            loading: false,
            loggedIn: false,
            processing: false,
            user: {},
            access_token: "",
            error: "Network Issue",
          });
          return;
        }
        this.setState({
          loading: false,
          loggedIn: false,
          processing: false,
          user: {},
          access_token: "",
          error: "Unknown Issue",
        });
      });
  }
  logout() {
    API.auth
      .logout()
      .then((res) => {
        Authentication.setAccessToken(null);
        Authentication.setUserData(null);
        Authentication.setUserScopes([]);
        let config = Bridge.getRAWConfig();
        localStorage.removeItem(config.localPrefix + "access_token");
        this.setState({
          loading: false,
          loggedIn: false,
          user: {},
          access_token: "",
        });
      })
      .catch((e) => {
        console.error(e);
      });
  }
  getLoginURL() {
    let config = Bridge.getRAWConfig();
    return (
      config.loginServer +
      "/oauth/authorize?client_id=" +
      config.clientId +
      "&redirect_uri=" +
      config.applicationRoot +
      "&response_type=code&scope=profile"
    );
  }
  render() {
    let config = Bridge.getRAWConfig();
    if (this.state.reload) {
      window.location = config.applicationRoot;
      return <Loader>Reloading Application...</Loader>;
    }
    if (
      (config.clientId === undefined ||
        config.localPrefix === undefined ||
        config.applicationServer === undefined) &&
      process.env.NODE_ENV !== "production"
    ) {
      return (
        <Segment>
          <Message negative>
            <h1>Error</h1>
            <br />
            There is a misconfiguration with the AuthenticationWrapper. Please
            ensure: 'clientId', 'localPrefix' & 'applicationServer' are set and
            passed via the config prop.
          </Message>
        </Segment>
      );
    }
    if (this.state.error) {
      if (this.state.error === "Not Allowed") {
        return (
          <Segment>
            <Message negative>
              <h1>Access Denied</h1>
              <br />
              Your SarStuff Central account and/or organisation does not have
              the correct access rights to login into this application therefore
              access has been denied.
              <br />
              <br />
              If you believe that you should have access to this application
              then please contact your Organisation Admins to obtain access.
              Otherwise please send an email to:{" "}
              <strong>support@sarstuff.co.uk</strong>
            </Message>
            <br />
            <button
              onClick={() => (window.location = "/")}
              className={"loginWidget-button-success"}
            >
              Retry Login
            </button>
            <br />
            <br />
            <Message info>
              <h3>Things To Check:</h3>
              <br />
              1. <strong>
                Are you logging into the correct application?
              </strong>{" "}
              A few of our systems have unique website addresses that are
              organisation specific, check the website address above is correct
              for your organisation.
              <br />
              2.{" "}
              <strong>
                Insufficient security groups assigned to your account?
              </strong>{" "}
              You may not have the correct security groups assigned to your
              SarStuff Central account to grant you access to this application.
              <br />
              3. <strong>Application not enabled for organisation?</strong> Your
              organisation might not have access to this application, please
              contact: sales@sarstuff.co.uk if you think this is in error.
            </Message>
          </Segment>
        );
      } else if (this.state.error === "Code Expired") {
        return (
          <Segment>
            <Message warning>
              <h1>Session Expired</h1>
              <br />
              It appears that your login session has expired. You must
              re-authenticate before continuing.
            </Message>
            <br />
            <button
              onClick={() => (window.location = "/")}
              className={"loginWidget-button-success"}
            >
              Login
            </button>
          </Segment>
        );
      }
      return (
        <Segment>
          <Message negative>
            <h1>Service Error</h1>
            <br />
            We were unable to log you into the application as this time. This
            could be due to a temporary server issue or a problem with your
            internet connection. Please try again in a few moments. If the issue
            continues contact SarStuff support at:{" "}
            <strong>support@sarstuff.co.uk</strong>
          </Message>
          <br />
          <button
            onClick={() => (window.location = "/")}
            className={"loginWidget-button-success"}
          >
            Login
          </button>
        </Segment>
      );
    }
    if (this.state.processing) {
      return <Loader>Processing Login...</Loader>;
    }
    if (this.state.loading) {
      return <Loader>Loading...</Loader>;
    }
    if (!this.state.loggedIn) {
      if (this.state.redirect) {
        window.location = this.getLoginURL();
        return <Loader>Redirecting to SarStuff Central...</Loader>;
      }
      return <Loader>Processing Login...</Loader>;
    } else {
      return this.props.children;
    }
  }
}
