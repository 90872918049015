import DayJS from "dayjs";
import {toast} from "react-hot-toast";
import React, {useEffect, useState, useCallback} from "react";
import ToggleSwitch from "../../../components/common/ToggleSwitch";
import api from "../../../api";

const IncidentPeriodMembers = ({incident, period, onDataReload}) => {
  const [attended, setAttended] = useState([]);
  const [members, setMembers] = useState([]);

  const getData = useCallback(() => {
    if (incident._id !== undefined && period._id !== undefined) {
      api.incidents.periods.members.getAssigned(incident._id, period._id).then(res => {
        setAttended(res);
      });
    }
  }, [incident, period]);

  useEffect(() => {
    api.member.all().then((res) => {
      setMembers(res);
    });
  }, []);
  useEffect(() => {
    getData();
  }, [getData]);

  const handleToggleMember = member => {
    if (attended.indexOf(member._id) === -1) { // Assign
      api.incidents.periods.members.assignMember(incident._id, period._id, member._id).then(res => {
        toast.success("Successfully assigned member to incident");
        getData();
        onDataReload();
      }).catch(err => {
        if (err.isAxiosError) {
          if (err.response.data.message !== undefined) {
            toast.error(err.response.data.message);
            return;
          }

          toast.error("There was a network error while submitting your data, please try again shortly or check your internet connection.");
          return;
        }
        toast.error("There was an unexpected error while processing your request.");
      });
    } else {
      api.incidents.periods.members.unassignMember(incident._id, period._id, member._id).then(res => {
        toast.success("Successfully unassigned member from incident");
        getData();
        onDataReload();
      }).catch(err => {
        if (err.isAxiosError) {
          if (err.response.data.message !== undefined) {
            toast.error(err.response.data.message);
            return;
          }

          toast.error("There was a network error while submitting your data, please try again shortly or check your internet connection.");
          return;
        }
        toast.error("There was an unexpected error while processing your request.");
      });
    }
  }

  if (incident._id === undefined || period._id === undefined) {
    return (
      <div className={"p-2"}>Please select a Operational Period/Deployment to assign members too.</div>
    );
  }

  return (
    <div>
      <h1 className={"text-2xl p-2 text-center"}>
        Ref/ID: <span className={"font-bold"}>{period.refNum}</span> |
        Start At: <span className={"font-bold"}>{DayJS(period.startAt).format("DD-MM-YYYY HH:mm")}</span> |
        End At: <span className={"font-bold"}>{DayJS(period.endAt).format("DD-MM-YYYY HH:mm")}</span>
      </h1>

      <table className="tableClass">
        <thead className="tableHeadClass">
        <tr className="tableHeadRowClass">
          <th className="tableHeadCellClass w-32">Callsign</th>
          <th className="tableHeadCellClass">Name</th>
          <th className="tableHeadCellClass w-24">Attended</th>
        </tr>
        </thead>
        <tbody className="tableBodyClass">
        {members.toSorted((a, b) => {
          if(a.lastName.toLowerCase() > b.lastName.toLowerCase()) return 1;
          if(a.lastName.toLowerCase() < b.lastName.toLowerCase()) return -1;
          return 0;
        }).map((m) => {
          return (
            <tr key={m._id} className="tableBodyRowClass">
              <td className="tableBodyCellClass text-lg">{m.callsign}</td>
              <td className="tableBodyCellClass text-lg">{m.firstName} {m.lastName}</td>
              <td className="tableBodyCellClass">
                <ToggleSwitch
                  posColour="bg-green-800"
                  negColour="bg-zinc-600"
                  isChecked={attended.indexOf(m._id) !== -1}
                  onChange={() => handleToggleMember(m)}
                />
              </td>
            </tr>
          );
        })}
        </tbody>
      </table>
    </div>
  );
}
export default IncidentPeriodMembers;