import React, { useState, useEffect } from "react";
import Org from "./Org_context";
import Loader from "../../components/common/Loader";

function Org_Provider({ loadData, children }) {
  // const { member_id } = useParams();
  const [firstLoad, setFirstLoad] = useState(true);
  const [organisation, setOrganisation] = useState({});

  useEffect(() => {
    _refresh();
  }, []);

  const _refresh = () => {
    setOrganisation(loadData);
    setFirstLoad(false);
  };
  return (
    <Org.Provider
      value={{
        _refresh: _refresh,
        ...organisation,
      }}
    >
      {firstLoad ? <Loader /> : children}
    </Org.Provider>
  );
}
export default Org_Provider;
