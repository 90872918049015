import React, {useContext, useEffect, useMemo, useState} from "react";
import GeoJSON from "ol/format/GeoJSON";
import {get} from "ol/proj";
import {vector} from "../../mapping/ol/Source";
import {BoundingBoxFeatures, Map, Projection} from "../context";

const BoundingBox = ({active = false, children}) => {
  const mapContext                              = useContext(Map);
  const projectionContext                       = useContext(Projection);
  const [boundingFeatures, setBoundingFeatures] = useState([]);

  const bboxState = useMemo(() => {
    return {
      register: a => setBoundingFeatures(b => b.concat([a])),
      deregister: a => setBoundingFeatures(b => b.filter(c => a !== c))
    }
  }, []);

  const bboxExtent = useMemo(() => {
    if (boundingFeatures.length === 0) return false;

    let theFeatures = [];
    boundingFeatures.forEach(a => {
      const data = JSON.parse(a);
      if (data?.type === "FeatureCollection"){
        theFeatures = theFeatures.concat(data.features || []);
      }
    });

    return vector({features: new GeoJSON().readFeatures({type: "FeatureCollection", features: theFeatures}, {featureProjection: get(projectionContext.projection)})}).getExtent()
  }, [boundingFeatures])

  useEffect(() => {
    if (!active) return;
    if (!bboxExtent) return;

    mapContext.getView().fit(bboxExtent, {
      padding: [100, 100, 100, 100],
      maxZoom: 16,
    });
  }, [active, mapContext, bboxExtent])

  return (
    <BoundingBoxFeatures.Provider value={bboxState}>
      {children}
    </BoundingBoxFeatures.Provider>
  );
};
export default BoundingBox;