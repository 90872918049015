import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import Box from "../../components/common/Box";
import Layout from "../../components/common/Layout";
import Stat from "../../components/common/Stat";
import Button from "../../components/common/Button";
import api from "../../api";

const AssetDash = () => {
  const navigate = useNavigate();

  const [assets, setAssets] = useState([]);
  const [dash, setDash] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    Promise.all([
      api.assets.all(),
      api.assets.dash()
    ]).then(res => {
      setAssets(res[0]);
      setDash(res[1]);
    }).finally(() => {
      setLoading(false);
    });
  }, []);

  return (
    <Layout full>
      <div className="grid grid-cols-1 gap-4 xl:grid-cols-3">
        <div className="col-span-1">
          <Box title="Asset Management">
            <div className="p-4">
              <div className="flex flex-col gap-4 md:flex-row">
                <Stat
                  label="Archived"
                  value={
                    assets.filter((a) => a.archived === true).length
                  }
                />
                <div className="flex items-center justify-center align-bottom">
                  <Button
                    text="Add New Asset"
                    onClick={() => navigate(`/asset/add`)}
                    colour="bg-green-800 text-white"
                  />
                </div>
                <Stat
                  label="Active"
                  value={
                    assets.filter((a) =>  a.archived === false).length
                  }
                />
              </div>
            </div>
          </Box>
        </div>
        <div className="col-span-1 xl:col-span-2">
          <Box title="Upcoming Servicing and Checks">
            {!loading ? (
              <table className="tableClass">
                <thead className="thc">
                  <tr className="thrc">
                    <th className="thcc">Asset</th>
                    <th className="thcc">Asset Number</th>
                    <th className="thcc">Date</th>
                    <th className="thcc">Type</th>
                  </tr>
                </thead>
                <tbody className="tbc">
                  {dash.map((u) => {
                    return (
                      <tr
                        key={u._id}
                        className={`${u.onCall && "bg-green-900 bg-opacity-50"} tbrc cursor-pointer`}
                        onClick={() => navigate(`/asset/view/${u.asset._id}`)}
                      >
                        <td className="tbcc">{u.asset.name}</td>
                        <td className="tbcc">{u.asset.assetNum}</td>
                        <td className="tbcc">
                          {dayjs(u.theDate).format("DD-MM-YYYY")}
                        </td>
                        <td className="tbcc">{u.type}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <div className={"p-2"}>Loading...</div>
            )}
          </Box>
        </div>
      </div>
    </Layout>
  );
};
export default AssetDash;