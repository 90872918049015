import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import API from "../../api";
import EventEntryForm from "./_components/EventEntryForm";
import Box from "../../components/common/Box";

function EditEvent(){
  const { event_id } = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({});

  useEffect(() => {
    getData();
  }, []);

  const getData = useCallback(() => {
    API.calendar.getEvent(event_id).then((res) => {
      setData(res);

      setIsLoading(false);
    });
  }, [event_id]);

  const handleUpdateEvent = data => {
    API.calendar.updateEvent(event_id, data).then(() => {
      toast.success("Successfully updated event");
      navigate(`/events/event/${event_id}`);
    });
  };

  if(isLoading){
    return (
      <div className={"p-2"}>
        Loading...
      </div>
    );
  }

  return (
    <div className={"p-4"}>
      <Box title={"Edit Event"}>
        <div className={"p-2"}>
          <EventEntryForm isEdit onSubmit={handleUpdateEvent} data={data} onCancel={() => navigate(`/events/event/${event_id}`)} />
        </div>
      </Box>
    </div>
  );
}
export default EditEvent;