import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DayJS from "dayjs";
import { Skeleton } from "../../../components/ui/skeleton";
import API from "../../../api";
import CourseEventAddModel from "./_components/CourseEventAddModel";
import { Tab } from "@headlessui/react";
import Box from "../../../components/common/Box";

const returnTabButtonStyle = (selected) => {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  return classNames(
    "w-full py-2.5 text-lg leading-5 font-medium  rounded border dark:border-zinc-500",
    selected
      ? "text-gray-100 bg-green-900 bg-opacity-50"
      : "text-blue-100 hover:bg-white/[0.12] hover:text-white bg-gray-800"
  );
};

const Courses = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [showAdd, setShowAdd] = useState(false);
  const [pastEvents, setPastEvent] = useState([]);
  const [upcomingEvents, setUpcomingEvent] = useState([]);

  const getData = useCallback(() => {
    setIsLoading(true);
    Promise.all([API.courses.events.upcoming(), API.courses.events.past()])
      .then((res) => {
        setUpcomingEvent(res[0]);
        setPastEvent(res[1]);
        setIsLoading(false);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  const handleNavigateTo = (id) => navigate("event/" + id);

  const renderTable = (items) => {
    return (
      <table className="tableClass">
        <thead>
          <tr className="tableHeadRowClass">
            <th className="tableHeadCellClass">Title</th>
            <th className="tableHeadCellClass">Start</th>
            <th className="tableHeadCellClass">End</th>
            <th className="tableHeadCellClass">Organiser</th>
            <th className="tableHeadCellClass">Location</th>
            <th className="tableHeadCellClass">Self Signup</th>
            <th className="tableHeadCellClass">Total Attending</th>
          </tr>
        </thead>
        <tbody className="tableBodyClass">
          {items.length ? (
            items.toSorted((a, b) => {
              if(a.start < b.start) return 1;
              if(a.start > b.start) return -1;
              return 0;
            }).map((course) => {
              return (
                <tr
                  key={course._id}
                  onClick={() => handleNavigateTo(course._id)}
                  className={`tableBodyRowClass cursor-pointer`}
                >
                  <td className="tableBodyCellClass">{course.title}</td>
                  <td className="tableBodyCellClass">
                    {DayJS(course.start).format("DD-MM-YYYY HH:mm")}
                  </td>
                  <td className="tableBodyCellClass">
                    {DayJS(course.end).format("DD-MM-YYYY HH:mm")}
                  </td>
                  <td className="tableBodyCellClass">
                    {course.organiser !== undefined &&
                    course.organiser._id !== undefined
                      ? course.organiser?.firstName +
                        " " +
                        course.organiser?.lastName
                      : course.organiserName}
                  </td>
                  <td className="tableBodyCellClass">
                    {course.location?.properties?.name || "[N/A]"}
                  </td>
                  <td className="tableBodyCellClass">
                    {course.canSignUp ? "Yes" : "No"}
                  </td>
                  <td className="tableBodyCellClass">
                    {course.attending.length}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={7} className={"tableBodyCellClass"}>
                There are no records to display
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
  };

  return (
    <div className="w-full space-y-2 p-4">
      {showAdd && (
        <CourseEventAddModel
          onClose={() => {
            setShowAdd(false);
            getData();
          }}
        />
      )}
      {isLoading ? (
        <div className="space-y-6 p-4">
          <div className="flex gap-8">
            <Skeleton className="h-8 w-1/3 dark:bg-zinc-700"></Skeleton>
            <Skeleton className="h-8 w-1/3 dark:bg-zinc-700"></Skeleton>
            <Skeleton className="h-8 w-1/3 dark:bg-zinc-700"></Skeleton>
          </div>
          <div className="flex gap-8">
            <Skeleton className="h-8 w-1/2 dark:bg-zinc-700"></Skeleton>
            <Skeleton className="h-8 w-1/2 dark:bg-zinc-700"></Skeleton>
          </div>
        </div>
      ) : (
        <Box
          title="Courses"
          button={{
            action: () => {
              navigate("/events/picker?eventType=course");
              //setShowAdd(true);
            },
            text: "Add Course",
            colour: "text-white hover:text-gray-200",
          }}
        >
          <Tab.Group defaultIndex={0}>
            <Tab.List className="flex space-x-1 border-b border-zinc-200  p-1 pb-2 dark:border-zinc-700 dark:bg-blue-900/20">
              <Tab className={({ selected }) => returnTabButtonStyle(selected)}>
                Current/Future
              </Tab>
              <Tab className={({ selected }) => returnTabButtonStyle(selected)}>
                Past
              </Tab>
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel>{renderTable(upcomingEvents)}</Tab.Panel>
              <Tab.Panel>{renderTable(pastEvents)}</Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </Box>
      )}
    </div>
  );
};
export default Courses;
