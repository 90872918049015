import {createContext} from 'react';

const Map = createContext(null);
const Controls = createContext({
  register: () => {},
  deregister: () => {},
});

const LayersToggle_Active = createContext([]);
const LayersToggle_Register = createContext({
  register: () => {},
  deregister: () => {}
});
const BoundingBoxFeatures = createContext({
  register: () => {},
  deregister: () => {},
})
const Projection = createContext({
  setProjection: () => {},
  projection: "",
});

export {
  Map,
  Controls,
  BoundingBoxFeatures,
  LayersToggle_Active,
  LayersToggle_Register,
  Projection
};