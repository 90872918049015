import dayjs from "dayjs";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import API from "../../../api";
import Tooltip from "../../../components/common/Tooltip";
import {Skeleton} from "../../../components/ui/skeleton";
import ChainedContext from "../../../contexts/chainedContext";
import MemberReceiver from "../../../contexts/member_context/Member_Receiver";
import Box from "../../../components/common/Box";


const Assets = ({ member }) => {
  const [loading, setLoading] = useState(true);
  const [assets, setAssets] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    API.member.assignedAssets(member._id).then(res => {
      setAssets(res);
      setLoading(false);
    })
  }, [member])

  return (
    <div className="w-full p-2">
      {loading && (
        <div className="grid grid-cols-2 gap-4">
          <div className="space-y-8">
            <Skeleton className="h-80 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
            <Skeleton className="h-80 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
          </div>
          <div className=" space-y-8">
            <Skeleton className="h-52 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
            <Skeleton className="h-20 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
            <Skeleton className="h-20 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
          </div>
        </div>
      )}
      {!loading && <>
        <Box title={"Assigned Assets"}>
          {assets.length === 0 ? <div className={"text-center p-3"}>
            There are no items to display
          </div>: <>
            <table className="tableClass">
              <thead>
              <tr className="tableHeadRowClass">
                <th className="tableHeadCellClass">Asset</th>
                <th className="tableHeadCellClass">Asset Number</th>
                {/* <th className="tableHeadCellClass">Serial Number</th> */}
                <th className="tableHeadCellClass">Status</th>
                <th className="tableHeadCellClass hidden xl:table-cell">Last Service</th>
                <th className="tableHeadCellClass">Last Check</th>
                {/* <th className="tableHeadCellClass">Expires Date</th> */}
                <th className="tableHeadCellClass">Tags</th>
              </tr>
              </thead>
              <tbody className="tableBodyClass">
              {assets.sort((a, b) => {
                  if(a.name > b.name) return 1;
                  if(a.name < b.name) return -1;
                  return 0;
                }).map((a) => {
                  const onViewClick = () => {
                    navigate(`/asset/view/${a._id}`);
                  };
                  let status = "";
                  switch (a.status) {
                    case "Non-Operational":
                    case "Out for Repair":
                      status = "bg-red-900 ";
                      break;
                    case "Faulty":
                      status = "bg-yellow-900 ";
                      break;
                    default:
                      status = "bg-green-900 ";
                      break;
                  }
                  let lastCheck = "";
                  if (a.lastCheck.status) {
                    switch (a.lastCheck.status) {
                      case "scheduled":
                        lastCheck = " ";
                        break;
                      case "OK":
                        lastCheck = "bg-green-900 text-white";
                        break;
                      default:
                        lastCheck = "bg-red-900 text-white";
                        break;
                    }
                  }
                  let expires = "";
                  if(dayjs(a.date_expires).isBefore(dayjs())){
                    expires = " bg-red-900 bg-opacity-50 ";
                  } else if (dayjs(a.date_expires).isAfter(dayjs()) && dayjs(a.date_expires).isBefore(dayjs().add(3, "month"))){
                    expires = " bg-yellow-900 bg-opacity-50 ";
                  } else if (dayjs(a.date_expires).isAfter(dayjs()) && dayjs(a.date_expires).isAfter(dayjs().add(3, "month"))){
                    expires = " bg-green-900 bg-opacity-50 ";
                  }

                  return (
                    <tr
                      key={a._id}
                      className={`tableBodyRowClass cursor-pointer text-sm`}
                    >
                      <td className="p-2" onClick={onViewClick}>
                        {a.name}
                      </td>
                      <td className="w-1/8 p-2" onClick={onViewClick}>
                        {a.assetNum}
                      </td>
                      {/* <td className="p-2" onClick={onViewClick}>
                       {a.serialNum}
                       </td> */}
                      <td
                        className={`p-2 ${status} w-1/8 bg-opacity-50`}
                        onClick={onViewClick}
                      >
                        {a.status}
                      </td>
                      <td
                        className="hidden p-2 xl:table-cell"
                        onClick={onViewClick}
                      >
                        {a.lastService
                          ? dayjs(a.lastService).format("DD-MM-YYYY")
                          : "--"}
                      </td>
                      <td
                        className={`p-2 ${
                          // a.lastCheck.status
                          //   ? a.lastCheck.status === "OK"
                          //     ? "bg-green-900 bg-opacity-50"
                          //     : "has-tooltip bg-red-900 bg-opacity-50"
                          //   : ""
                          lastCheck
                        }`}
                        onClick={onViewClick}
                      >
                        {a.lastCheck.note && (
                          <Tooltip
                            style={`${
                              a.lastCheck.status !== "OK"
                                ? "bg-red-900"
                                : "bg-green-900"
                            }`}
                            text={a.lastCheck.note}
                          />
                        )}
                        {a.lastCheck.date
                          ? dayjs(a.lastCheck.date).format("DD-MM-YYYY")
                          : "--"}
                      </td>
                      <td className="p-2" onClick={onViewClick}>
                        {a.tags.map((t, i) => {
                          return (
                            <span
                              key={i}
                              className="m-1 rounded-lg border border-gray-700 p-2 dark:bg-gray-800"
                            >
                                {t}
                              </span>
                          );
                        })}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </> }
        </Box>
      </>}
    </div>
  )
}
export default ChainedContext(Assets, [
  [MemberReceiver, "member"],
])