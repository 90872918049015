import range from "lodash/range";
import dayjs from "dayjs";
import React, {useEffect, useState} from "react";
import {Switch} from "@headlessui/react";
import DatePicker from "react-datepicker";
import Input from "../../../../components/common/Input";
import Select from "../../../../components/common/Select";
import Button from "../../../../components/common/Button";
import Box from "../../../../components/common/Box";
import api from "../../../../api";
import {TrashIcon} from "@heroicons/react/20/solid";
import {AuthComponents} from "../../../../lib/_sso";

const status = [
  { key: 1, text: "Operational", value: "Operational" },
  { key: 2, text: "Non-Operational", value: "Non-Operational" },
  { key: 3, text: "Faulty", value: "Faulty" },
  { key: 4, text: "Out for Repair", value: "Out for Repair" },
];
const taskableTypes = [
  { key: 1, text: "Other", value: "Other" },
  { key: 2, text: "Drone", value: "Drone" },
  { key: 3, text: "Sonar", value: "Sonar" },
  { key: 4, text: "Boat", value: "Boat" },
];
const years = range(dayjs(new Date()).year(), dayjs(new Date()).year() + 20, 1);
const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

const AssetDetailsForm = ({ isEdit = false, asset = {}, onChange, onCancel, onDelete = () => {} }) => {
  const [errors, setErrors] = useState({});

  const [tags, setTags] = useState([]);
  const [theTags, setTheTags] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [selected, setSelected] = useState(null);
  const [data, setData] = useState({
    name: "",
    assetNum: "",
    serialNum: "",
    multi: false,
    qty: "1",
    date_purchased: "",
    purchase_cost: "",
    tags: [], // = theTags
    serviceInterval: "",
    weight: "",
  });

  useEffect(() => {
    api.assets.settings.all().then(res => {
      if (res.tags !== undefined) {
        setTags(res.tags.map((t, i) => {
          return {
            key: i,
            text: t,
            value: t,
          };
        }));
      }
    });
    api.assets.suppliers.all().then(res => {
      setSuppliers(res.map((s) => {
        return {
          key: s._id,
          text: s.name,
          value: s._id,
        };
      }));
    });
  }, []);
  useEffect(() => {
    if(asset._id !== undefined){
      setData(asset);
      if (asset.supplier !== undefined && asset.supplier !== null && asset.supplier._id !== undefined){
        setSelected(asset.supplier._id);
      }
      if(asset.tags !== undefined){
        setTheTags(asset.tags);
      }
    }
  }, [asset]);

  const onAssetChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const manageTags = (data) => {
    let bob = theTags.includes(data);
    if (bob) {
      let frank = theTags.filter((t) => {
        return t !== data;
      });
      setTheTags(frank);
    } else {
      setTheTags(theTags.concat([data]));
    }
  };

  const validate = (data) => {
    const errors = {};
    if (!data.name) errors.name = "Must not be empty!";
    if (!data.assetNum) errors.assetNum = "Must not be empty!";
    if (!data.status) errors.status = "Must not be empty!";
    return errors;
  };

  const onSubmit = () => {
    const finalData = {
      ...data,
      tags: theTags,
      supplier: selected
    };

    const errors = validate(finalData);
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      onChange(finalData);
    }
  }

  const renderTags = () => {
    if (tags.length === 0) {
      return (
        <div className={"p-2 italic text-yellow-700"}>
          There are no tags defined in settings, please add some in-order to assign tags to this asset.
        </div>
      );
    }
    return tags.map((t) => {
      let active = theTags.includes(t.value);
      return (
        <span
          onClick={() => manageTags(t.value)}
          key={t.key}
          className={`m-1 cursor-pointer rounded-lg bg-gray-700 p-2 text-white hover:bg-gray-600 ${active && "bg-green-500"}`}
        >{t.text}</span>
      );
    });
  };

  return (
    <Box title="Asset Details">
      <div className="p-2">
        <div className="flex flex-row gap-4">
          <Input
            label={"Name"}
            field="name"
            type={"text"}
            error={errors.name}
            value={data.name || ""}
            onChange={onAssetChange}
            placeholder="Dry suit.."
          />

          <Input
            label={"Asset Number"}
            field="assetNum"
            type={"text"}
            error={errors.assetNum}
            value={data.assetNum.toUpperCase() || ""}
            onChange={onAssetChange}
            placeholder="ABC123"
          />
        </div>
        <div className="flex flex-row gap-4">
          <Input
            label={"Serial Number"}
            field="serialNum"
            type={"text"}
            value={data.serialNum || ""}
            onChange={onAssetChange}
            placeholder="AX456777788889000000"
          />

          <Select
            label="Status"
            placeholder={"pick one"}
            field={"status"}
            value={data.status || ""}
            error={errors.status}
            options={status}
            onChange={onAssetChange}
            // className={errors.title ? "warning" : ""}
          />
        </div>
        <div className="grid grid-cols-4 justify-center gap-2 p-2">
          <div className="col-span-1 justify-center ">
            <label className={`labelClass`}>Multi</label>
            <Switch
              checked={data.multi || false}
              name="multi"
              onChange={(e) =>
                setData({
                  ...data,
                  multi: e,
                })
              }
              className={`${
                data.multi ? "bg-green-900" : "bg-gray-600"
              } relative inline-flex h-8  w-16 flex-shrink-0  cursor-pointer  items-center rounded-full border-2 border-transparent `}
            >
              <span className="sr-only">Set as multi</span>
              <span
                className={`${
                  data.multi ? "translate-x-10" : "translate-x-0"
                } inline-block h-8 w-8 transform rounded-full border  border-yellow-900 bg-gray-100 shadow-lg ring-0 transition duration-200 ease-in-out`}
              />
            </Switch>
            {data.multi && (
              <Input
                label={"Qty"}
                field="qty"
                type={"string"}
                value={data.qty || ""}
                onChange={onAssetChange}
                placeholder="123"
              />
            )}
          </div>

          <div className="col-span-1 justify-center">
            <label className={`labelClass`}>Has Contents</label>
            <Switch
              checked={data.hasContents || false}
              name="multi"
              onChange={(e) =>
                setData({
                  ...data,
                  hasContents: e,
                })
              }
              className={`${data.hasContents ? "bg-green-900" : "bg-gray-600"} relative inline-flex h-8  w-16 flex-shrink-0  cursor-pointer  items-center rounded-full border-2 border-transparent`}
            >
              <span className="sr-only">Set as hasContents</span>
              <span
                className={`${data.hasContents ? "translate-x-10" : "translate-x-0"} inline-block h-8 w-8 transform rounded-full border  border-yellow-900 bg-gray-100 shadow-lg ring-0 transition duration-200 ease-in-out`}
              />
            </Switch>
          </div>
          {isEdit && <div className="col-span-1 justify-center">
            <label className={`labelClass`}>Archive</label>
            <Switch
              checked={data.archived || false}
              name="multi"
              onChange={(e) =>
                setData({
                  ...data,
                  archived: e,
                })
              }
              className={`${data.archived ? "bg-red-900" : "bg-gray-600"} relative inline-flex h-8  w-16 flex-shrink-0  cursor-pointer  items-center rounded-full border-2 border-transparent`}
            >
              <span className="sr-only">Set as archived</span>
              <span
                className={`${data.archived ? "translate-x-10" : "translate-x-0"} inline-block h-8 w-8 transform rounded-full border  border-yellow-900 bg-gray-100 shadow-lg ring-0 transition duration-200 ease-in-out`}
              />
            </Switch>
          </div>}
          <div className="col-span-1 justify-center">
            <label className={`labelClass`}>Taskable</label>
            <Switch
              checked={data.taskable || false}
              name="multi"
              onChange={(e) =>
                setData({
                  ...data,
                  taskable: e,
                })
              }
              className={`${data.taskable ? "bg-green-900" : "bg-gray-600"} relative inline-flex h-8  w-16 flex-shrink-0  cursor-pointer  items-center rounded-full border-2 border-transparent `}
            >
              <span className="sr-only">Set as taskable</span>
              <span
                className={`${data.taskable ? "translate-x-10" : "translate-x-0"} inline-block h-8 w-8 transform rounded-full border  border-yellow-900 bg-gray-100 shadow-lg ring-0 transition duration-200 ease-in-out`}
              />
            </Switch>
            {data.taskable && (
              <div className="">
                <label
                  className={`text-md block pt-4 font-medium text-yellow-700 `}
                >
                  Taskable Type
                </label>
                <Select
                  placeholder={"pick one"}
                  name={"taskableType"}
                  value={data.taskableType}
                  options={taskableTypes}
                  onChange={(e) =>
                    setData({
                      ...data,
                      taskableType: e.target.value,
                    })
                  }
                  // className={errors.title ? "warning" : ""}
                />
              </div>
            )}
          </div>
        </div>

        <div className="flex flex-col justify-center">
          <label className={`labelClass`}>Supplier</label>
          <Select
            placeholder={"pick one"}
            name={"type"}
            value={selected ? selected : ""}
            options={suppliers ? suppliers : []}
            onChange={(e) => setSelected(e.target.value)}
            // className={errors.title ? "warning" : ""}
          />
          {/* <Listbox value={selected} onChange={(e) => setSelected(e)}>
           <div className="relative mt-1 p-2">
           <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-gray-900 rounded-lg shadow-md cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm">
           <span className="block truncate p-2 text-lg">
           {selected.name}
           </span>
           <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
           <SelectorIcon
           className="w-5 h-5 text-white"
           aria-hidden="true"
           />
           </span>
           </Listbox.Button>
           <Transition
           as={Fragment}
           leave="transition ease-in duration-100"
           leaveFrom="opacity-100"
           leaveTo="opacity-0"
           >
           <Listbox.Options className="absolute w-full py-2 mt-1 overflow-auto text-base bg-gray-800 rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
           {suppliers.map((person, personIdx) => (
           <Listbox.Option
           key={personIdx}
           className={({ active }) =>
           `${
           active
           ? "text-amber-900 bg-amber-100"
           : "text-white"
           }
           cursor-default border-b border-gray-700 hover:bg-gray-700 select-none relative py-2 pl-10 pr-4 text-lg`
           }
           value={person}
           >
           {({ selected, active }) => (
           <>
           <span
           className={`${
           selected ? "font-medium" : "font-normal"
           } block truncate `}
           >
           {person.name}
           </span>
           {selected ? (
           <span
           className={`${
           active
           ? "text-amber-600"
           : "text-amber-600"
           }
           absolute inset-y-0 left-0 flex items-center pl-3`}
           >
           <CheckIcon
           className="w-5 h-5 text-yellow-600"
           aria-hidden="true"
           />
           </span>
           ) : null}
           </>
           )}
           </Listbox.Option>
           ))}
           </Listbox.Options>
           </Transition>
           </div>
           </Listbox> */}
        </div>
        <div className="py-2">
          <label
            className={`text-md block py-2 font-medium text-primary dark:text-zinc-400`}
          >
            Tags
          </label>
          <div className="flex flex-wrap">{renderTags()}</div>
        </div>
        <div className="py-4">
          <div className="grid grid-cols-2 gap-2 2xl:grid-cols-3">
            <div className="col-span-1">
              <label className={`labelClass`}>Date Purchased</label>
              <DatePicker
                onChange={(date) =>
                  setData({
                    ...data,
                    date_purchased: date,
                  })
                }
                selected={data.date_purchased ? new Date(data.date_purchased) : ""}
                shouldCloseOnSelect={true}
                dateFormat="dd-MM-yyyy"
                maxDate={new Date()}
                nextMonthButtonLabel=">"
                previousMonthButtonLabel="<"
                className="inputClass p-1"
              />
            </div>
            <div className="col-span-1">
              <label className={`labelClass`}>Date Expires</label>
              <DatePicker
                autoComplete="off"
                renderCustomHeader={({
                  date,
                  changeYear,
                  changeMonth,
                  decreaseMonth,
                  increaseMonth,
                  prevMonthButtonDisabled,
                  nextMonthButtonDisabled,
                }) => (
                  <div
                    style={{
                      margin: 10,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <button
                      className="pr-1 text-lg"
                      onClick={decreaseMonth}
                      disabled={prevMonthButtonDisabled}
                    >
                      {"<"}
                    </button>

                    <select
                      value={months[dayjs(date).month()]}
                      className="p-1"
                      onChange={({ target: { value } }) =>
                        changeMonth(months.indexOf(value))
                      }
                    >
                      {months.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                    <select
                      value={dayjs(date).year()}
                      className="p-1"
                      onChange={({ target: { value } }) =>
                        changeYear(value)
                      }
                    >
                      {years.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>

                    <button
                      className="pl-1 text-lg"
                      onClick={increaseMonth}
                      disabled={nextMonthButtonDisabled}
                    >
                      {">"}
                    </button>
                  </div>
                )}
                shouldCloseOnSelect={true}
                name="expiryDate"
                onChange={(date) =>
                  setData({
                    ...data,
                    date_expires: date,
                  })
                }
                selected={
                  data.date_expires ? new Date(data.date_expires) : ""
                }
                dateFormat="dd-MM-yyyy"
                minDate={new Date()}
                className="inputClass z-50 p-1 "
              />
            </div>
            <div className="col-span-1 ">
              <Input
                label={"Purchase price"}
                field="purchase_cost"
                type={"text"}
                value={data.purchase_cost || ""}
                onChange={onAssetChange}
                placeholder="£123"
              />
            </div>
            <div className="col-span-1">
              <Input
                label={"Service Interval "}
                field="serviceInterval"
                type={"number"}
                value={data.serviceInterval ? data.serviceInterval : ""}
                onChange={onAssetChange}
                placeholder="6"
              />
              <span className="subtle text-xs italic">in months</span>
            </div>
            <div className="w-full">
              <Input
                label={"weight"}
                field="weight"
                type={"number"}
                value={data.weight || ""}
                onChange={onAssetChange}
                placeholder="0.5"
              />
              <div className="subtle p-1 text-xs italic">in Kg</div>
            </div>
          </div>
        </div>
        <div className="pb-2">
          <div className="flex flex-row gap-4">
            <div className="w-full">
              <label className={`labelClass`}>Details</label>
              <textarea
                rows={3}
                name="details"
                value={data.details || ""}
                onChange={onAssetChange}
                className={`inputClass w-full`}
              />
            </div>
          </div>
        </div>
        <div className="flex justify-between py-4">
          <Button
            text="Cancel"
            colour="bg-blue-700"
            onClick={() => onCancel()}
          />
          {isEdit && <AuthComponents.Can scope={"assets:delete"}><button
            className="rounded-md border bg-opacity-80 px-2 py-1 text-sm text-white border-red-400 bg-red-700 hover:border-red-300 hover:bg-red-800"
            onClick={() => onDelete()}
          >
            <TrashIcon className="h-6 w-6 text-red-200"/>
          </button></AuthComponents.Can>}
          <Button
            text={isEdit ? "Update" : "Add"}
            colour="bg-green-700"
            onClick={onSubmit}
          />
        </div>
      </div>
    </Box>
  );
}
export default AssetDetailsForm;