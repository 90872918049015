import React, { useState, useEffect } from "react";
import Box from "../components/common/Box";
import Stat from "../components/common/Stat";
import API from "../api";
import { useNavigate } from "react-router-dom";
import EventLine from "../components/common/EventLine";

function Home() {
  const [onCall, setOnCall] = useState(0);
  const [offCall, setOffCall] = useState(0);
  const [nonOp, setNonOp] = useState(0);
  const [events, setEvents] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    API.member.dash().then((res) => {
      setOffCall(res.offCall);
      setOnCall(res.onCall);
      setNonOp(res.nonOp);
    });
    API.calendar.getFuture().then((res) => {
      setEvents(res);
    });
  }, []);

  return (
    <div className="p-4">
      <div className="grid gap-4 lg:grid-cols-2">
        <div className="col-span-1 space-y-4">
          <Box title="Team Status">
            <div className="flex gap-4 p-4">
              <Stat
                label="On-Call"
                value={onCall}
                colour="bg-green-300 bg-opacity-40"
                action={() => navigate(`/team/?status=on-call`)}
              />
              <Stat
                label="Off-Call"
                value={offCall}
                colour="bg-blue-300 bg-opacity-40"
                action={() => navigate(`/team/?status=off-call`)}
              />
              <Stat
                label="Non-Op"
                value={nonOp}
                colour="bg-red-300 bg-opacity-40"
                action={() => navigate(`/team/?status=non-op`)}
              />
            </div>
          </Box>
        </div>
        <div className="col-span-1">
          <Box title={`In the next 3 weeks`}>
            <table>
              <tbody>
                {events.map((event, i) => {
                  return <EventLine key={i} data={event} />;
                })}
                {events.length === 0 && <tr>
                  <td colSpan={1} className="p-2">
                    There are no upcoming events in the next 3 weeks.
                  </td>
                </tr>}
              </tbody>
            </table>
          </Box>
        </div>
      </div>
    </div>
  );
}
export default Home;