import React from "react";
import Member from "./Member_Context";

const joinProps = (a, b) => {
  return {
    ...a,
    ...b,
  };
};
export default (Compo, mapContext) => {
  return (props) => {
    return (
      <Member.Consumer>
        {(context) => <Compo {...joinProps(props, mapContext(context))} />}
      </Member.Consumer>
    );
  };
};
