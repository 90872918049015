import React, {useCallback, useState} from "react";
import API from "../../../api";
import Model from "../../../components/ui/model";
import Toast from "react-hot-toast";

const FolderDelete = ({ onClose, folder }) => {
  const [submitError, setSubmitError] = useState(null);

  const handleDeleteFile = useCallback(() => {
    API.documents.deleteFolder(folder._id).then(() => {
      Toast.success("Successfully deleted folder.");
      onClose();
    }).catch(err => {
      setSubmitError(err.response.data.message);
    })
  }, [folder]);

  return (
    <Model title={"Delete Folder"}>
      <p className={"text-red"}>Are you sure you want to permanently delete the folder: <strong>{folder.name}</strong>?</p>
      {submitError && <div className={"text-red-500 py-1"}>{submitError}</div>}

      <div className="mt-4 flex justify-between border-t border-zinc-500 pt-4">
        <button className="rounded-lg bg-primary px-4 py-2 text-white" onClick={onClose}>Cancel</button>
        <button className="rounded-lg bg-green-500 px-4 py-2 text-white disabled:bg-gray-500" onClick={handleDeleteFile}>Delete Folder</button>
      </div>
    </Model>
  );
}
export default FolderDelete;