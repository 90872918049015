import React, { useState } from "react";
import {toast} from "react-hot-toast";
import {useNavigate} from "react-router-dom";
import CourseEventForm from "../../../../components/widgets/CourseEventForm";
import Modal from "../../../../components/common/Modal";
import API from "../../../../api";
import ConfirmBox from "../../../../components/common/Confirm";
import {TrashIcon} from "@heroicons/react/20/solid";
import {AuthComponents} from "../../../../lib/_sso";

const CourseEventEditModel = ({ event, onClose }) => {
  const [deleteWarning, setDeleteWarning] = useState(false);
  const navigate = useNavigate();

  const handleDeleteEvent = () => {
    API.courses.events.delete(event._id).then(() => {
      toast.success("Course Event deleted successfully");
      navigate(`/training/courses`);
    }).catch(() => {
      toast.error("Failed to delete course event due to a server error");
    })
  }
  const handleSubmit = data => {
    return API.courses.events.update(event._id, data).then(() => {
      onClose();
      toast.success("Course Event updated successfully");
    }).catch(err => {
      toast.error("Failed to update course event due to a server error");
    });
  }

  return (
    <>
      <Modal show={!deleteWarning} title={"Edit Course Event"} onClose={onClose} disableOutsideClick className={"max-w-5xl"}>
        <CourseEventForm onCancel={onClose} event={event} isEdit onSubmit={handleSubmit} />
        <div className={"text-center"}>
          <AuthComponents.Can scope={"course:events:delete"}><button
            className="rounded-md border bg-opacity-80 px-2 py-1 text-sm text-white border-red-400 bg-red-700 hover:border-red-300 hover:bg-red-800"
            onClick={() => setDeleteWarning(true)}
          >
            <TrashIcon className="h-6 w-6 text-red-200"/>
          </button></AuthComponents.Can>
        </div>
      </Modal>
      {deleteWarning && <ConfirmBox show title={"WARNING: Deleting this course event will also delete results stored with each team member potentially revoking some roles."}
                                    action={handleDeleteEvent} onClose={() => setDeleteWarning(false)} />}
    </>
  );
}
export default CourseEventEditModel;