import React from "react";
import { Skeleton } from "../ui/skeleton";

export default function Skel() {
  return (
    <div className="w-full p-2">
      <div className="grid grid-cols-2 gap-4">
        <div className="space-y-8">
          <Skeleton className="h-80 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
          <Skeleton className="h-80 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
        </div>
        <div className=" space-y-8">
          <Skeleton className="h-52 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
          <Skeleton className="h-20 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
          <Skeleton className="h-20 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
        </div>
      </div>
    </div>
  );
}
