import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { toast } from "react-hot-toast";
import Box from "../../../components/common/Box";
import Input from "../../../components/common/Input";
import Modal from "../../../components/common/Modal";
import Button from "../../../components/common/Button";
import ToggleSwitch from "../../../components/common/ToggleSwitch";
import ChainedContext from "../../../contexts/chainedContext";
import MemberReceiver from "../../../contexts/member_context/Member_Receiver";
import { Skeleton } from "../../../components/ui/skeleton";
import API from "../../../api";
import Blank from "../../../assets/images/vblank.png";
import DataRow from "../../../components/common/DataRow";
import DisplayAndUpdatePhoto from "./_components/DisplayAndUpdatePhoto";
import NextOfKinList from "../../../components/widgets/NextOfKinList";
import MemberNotes from "./_components/MemberNotes"

function MemberDetails({ member }) {
  const [details, setDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [extra, setExtra] = useState({
    passportNum: member.passportNum,
    driverNum: member.driverNum,
    oldCallouts: member.oldCallouts,
  });

  const [updateExtra, setUpdateExtra] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (member._id) {
      getData();
    }
  }, []);

  const getData = () => {
    Promise.all([API.member.details(member._id)]).then((res) => {
      setDetails(res[0]);
    }).catch((err) => {
      console.log(err);
    }).finally(() => {
      setLoading(false);
    });
  };
  const handleGettingPhoto = (vehicle_id) => {
    return () => API.vehicles.photo.getAccessLink(vehicle_id);
  };

  const setHideAddress = (value) => {
    handleUpdateMember({
      privacy: {
        ...member.privacy,
        hideAddress: value,
      },
    });
  };
  const setHideEmail = (value) => {
    handleUpdateMember({
      privacy: {
        ...member.privacy,
        hideEmail: value,
      },
    });
  };
  const setHidePhone = (value) => {
    handleUpdateMember({
      privacy: {
        ...member.privacy,
        hidePhone: value,
      },
    });
  };
  const handleUpdateMember = async (data) => {
    await API.member.update(member._id, data);
    toast.success("Updated");
    member._refresh();
  };
  const handleNokUpdate = (nokList) => {
    API.member.update(member._id, { nok: nokList }).then(() => {
      member._refresh();
      toast.success("NoK listed successfully updated.");
    }).catch(err => {
      toast.error("Unable to push changes to NoK list to the server due to error: " + (err.response?.data?.message || err.message))
    });
  };

  const handleUpdateExtra = () => {
    API.member.update(member._id, extra).then(() => {
      toast.success("Extra data updated");
      member._refresh();
      setUpdateExtra(false);
    });
  };

  const ExtraInfoDialog = () => {
    return (
      <Modal
        show={updateExtra}
        onClose={() => setUpdateExtra(false)}
        title={` Extra Info for ${member.firstName}`}
        children={
          <div>
            <div className="space-y-4 p-4">
              <Input
                label={"Passport Number"}
                field="passportNum"
                type={"text"}
                value={extra.passportNum}
                onChange={(e) =>
                  setExtra({
                    ...extra,
                    [e.target.name]: e.target.value,
                  })
                }
                placeholder="123456789"
              />
            </div>
            <div className="space-y-4 p-4">
              <Input
                label={"Driving Licence Number"}
                field="driverNum"
                type={"text"}
                value={extra.driverNum}
                onChange={(e) =>
                  setExtra({
                    ...extra,
                    [e.target.name]: e.target.value.toUpperCase(),
                  })
                }
                placeholder="123456789"
              />
            </div>
            <div className="space-y-4 p-4">
              <Input
                label={"Previous Deployment Total"}
                field="oldCallouts"
                type={"number"}
                value={extra.oldCallouts}
                onChange={(e) =>
                  setExtra({
                    ...extra,
                    [e.target.name]: e.target.value.toUpperCase(),
                  })
                }
                placeholder="123456789"
              />
            </div>
            <div className="mt-4 flex justify-between border-t border-gray-700 pt-4">
              <Button
                text="Cancel"
                onClick={() => setUpdateExtra(false)}
                colour="bg-red-700 text-white"
              />
              <Button
                text="Update"
                onClick={handleUpdateExtra}
                colour="bg-green-700 text-white"
              />
            </div>
          </div>
        }
      />
    );
  };

  return (
    <div className="w-full p-2">
      {ExtraInfoDialog()}

      {loading && (
        <div className="grid grid-cols-2 gap-4">
          <div className="space-y-8">
            <Skeleton className="h-80 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
            <Skeleton className="h-80 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
          </div>
          <div className=" space-y-8">
            <Skeleton className="h-52 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
            <Skeleton className="h-20 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
            <Skeleton className="h-20 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
          </div>
        </div>
      )}
      {!loading && (
        <div className="grid xl:grid-cols-3">
          <div className="space-y-4 p-2 xl:col-span-2">
            <Box
              title="Extra Info"
              button={{
                text: "Edit",
                colour: "bg-blue-400 text-sm text-white hover:text-gray-200",
                action: () => setUpdateExtra(true),
              }}
            >
              <DataRow label={"Passport Number"} value={member.passportNum} />
              <DataRow
                label={"Driving Licence Number"}
                value={member.driverNum}
              />
              <DataRow
                label={"Previous Deployment Total"}
                value={member.oldCallouts}
              />
              <DataRow
                label={"Privacy"}
                value={
                  <div className="space-y-4 p-2 pb-4">
                    <ToggleSwitch
                      label="Hide Address"
                      posColour=" bg-green-800"
                      negColour=" bg-zinc-800"
                      isChecked={member.privacy.hideAddress}
                      onChange={() =>
                        setHideAddress(!member.privacy.hideAddress)
                      }
                    />
                    <ToggleSwitch
                      label="Hide Email"
                      posColour=" bg-green-800"
                      negColour=" bg-zinc-800"
                      isChecked={member.privacy.hideEmail}
                      onChange={() => setHideEmail(!member.privacy.hideEmail)}
                    />
                    <ToggleSwitch
                      label="Hide Phone"
                      posColour=" bg-green-800"
                      negColour=" bg-zinc-800"
                      isChecked={member.privacy.hidePhone}
                      onChange={() => setHidePhone(!member.privacy.hidePhone)}
                    />
                  </div>
                }
              />
            </Box>

            <NextOfKinList value={member.nok} onChange={handleNokUpdate} />
            <MemberNotes />
          </div>
          <div className="p-2">
            <Box
              title={"Garage"}
              button={{
                text: "Add",
                colour: "bg-green-700 text-sm text-white hover:text-gray-200",
                action: () =>
                  navigate(`/vehicles/add/?member_id=${member._id}`),
              }}
            >
              {details.Garage.length > 0 ? (
                details.Garage.map((vehicle) => {
                  return (
                    <Link
                      to={`/vehicles/${vehicle._id}`}
                      key={vehicle._id}
                      className=""
                    >
                      <div className="p-2">
                        <DisplayAndUpdatePhoto
                          className="w-full border-8 border-white shadow-lg dark:shadow-zinc-600"
                          canUpdate={false}
                          onGetPhoto={handleGettingPhoto(vehicle._id)}
                          blankImage={Blank}
                        />
                      </div>
                      <div className="border-t border-zinc-300 dark:border-zinc-700">
                        <div className="grid divide-x 2xl:grid-cols-2">
                          <DataRow
                            label={"Registration"}
                            value={
                              vehicle.regNumber
                                ? vehicle.regNumber.toUpperCase()
                                : "--"
                            }
                          />
                          <DataRow label={"Colour"} value={vehicle.colour} />
                          <DataRow label={"Make"} value={vehicle.make} />
                          <DataRow label={"Model"} value={vehicle.model} />
                        </div>
                      </div>
                    </Link>
                  );
                })
              ) : (
                <div className="p-2 text-lg">No Vehicle registered</div>
              )}
            </Box>
          </div>
        </div>
      )}
    </div>
  );
}
export default ChainedContext(MemberDetails, [
  [MemberReceiver, "member"]
]);
