import { APIRequest } from "../lib/_sso";
import AppConfig from "../_appConfig";

const server = AppConfig.api_server;

const photo = {
  getAccessLink: id => APIRequest.get(server + `/recruitment/${id}/photo/link`).then(res => res.data),
  getUploadConfig: id => APIRequest.get(server + `/recruitment/${id}/photo/_uploadConfig`).then(res => res.data),
  uploadPhoto: (id, profilePhoto) => APIRequest.patch(server + `/recruitment/${id}/photo/upload`, profilePhoto, {headers: {"Content-Type": "multipart/form-data"}}),
};
const requirements = {
  all: (recruit_id) => APIRequest.get(`${server}/recruitment/${recruit_id}/requirement`).then(res => res.data),
  update: (recruit_id, requirement_id, data) => APIRequest.patch(`${server}/recruitment/${recruit_id}/requirement/${requirement_id}`, data).then(res => res.data),
};

const intake = {
  getIntakes: () => APIRequest.get(`${server}/recruitment/intake`).then((res) => res.data),
  getIntake: (intake_id) => APIRequest.get(`${server}/recruitment/intake/${intake_id}`).then(res => res.data),
  addIntake: (data) => APIRequest.post(`${server}/recruitment/intake`, data).then(res => res.data),
  updateIntake: (id, data) => APIRequest.patch(`${server}/recruitment/intake/${id}`, data).then(res => res.data),
  deleteIntake: (id) => APIRequest.delete(`${server}/recruitment/intake/${id}`).then(res => res.data),
};
const convert = {
  runChecks: recruit_id => APIRequest.get(`${server}/recruitment/${recruit_id}/convert/check`).then(res => res.data),
  convertToMember: recruit_id => APIRequest.patch(`${server}/recruitment/${recruit_id}/convert`, {}).then(res => res.data),
}
const notes = {
  allNotes: recruit_id => APIRequest.get(server + `/recruitment/${recruit_id}/notes`).then(res => res.data),
  createNote: (recruit_id, data) => APIRequest.post(server + `/recruitment/${recruit_id}/notes`, data).then(res => res.data),
  removeNote: (recruit_id, noteId) => APIRequest.delete(server + `/recruitment/${recruit_id}/notes/${noteId}`).then(res => res.data),
};

export default {
  ...intake,

  addRecruit: (data) => APIRequest.post(`${server}/recruitment`, data).then(res => res.data),
  getActiveRecruits: () => APIRequest.get(`${server}/recruitment`).then(res => res.data),
  getRecruit: (recruit_id) => APIRequest.get(`${server}/recruitment/${recruit_id}`).then(res => res.data),
  updateRecruit: (recruit_id, data) => APIRequest.patch(`${server}/recruitment/${recruit_id}`, data).then(res => res.data),
  upcomingEvents: () => APIRequest.get(`${server}/recruitment/upcoming_events`).then(res => res.data),

  getRecruitRoles: recruit_id => APIRequest.get(`${server}/recruitment/${recruit_id}/roles`).then(res => res.data),
  addRecruitRole: (recruit_id, role_name) => APIRequest.post(`${server}/recruitment/${recruit_id}/roles/add`, {role_name}).then(res => res.data),
  removeRecruitRole: (recruit_id, role_id) => APIRequest.delete(`${server}/recruitment/${recruit_id}/roles/remove/${role_id}`).then(res => res.data),

  photo,
  requirements,
  convert,
  notes
};
