import React, {useCallback, useEffect, useState} from "react";
import {toast} from "react-hot-toast";
import {useNavigate} from "react-router-dom";
import API from "../../../../api";
import DataRowRoles from "../../../../components/common/DataRowRoles";
import ChainedContext from "../../../../contexts/chainedContext";
import SettingsReceiver from "../../../../contexts/settings_context/Settings_Receiver";
import ScoresForm from "./ScoresForm";
import {Skeleton} from "../../../../components/ui/skeleton";
import {AuthComponents, Authentication} from "../../../../lib/_sso";

const TeamMemberScore = ({ collectionId, competency, member, onClose, onAssignMissingRoles, settings }) => {
  const profile = Authentication.getUserData();

  const [isLoading, setIsLoading] = useState(true);

  const [compData, setCompData] = useState({});
  const [memberScores, setMemberScores] = useState({});
  const [session, setSession] = useState({});

  const handleGetMemberScores = useCallback(() => {
    API.training.competencies.memberScores(member._id, collectionId).then((res) => {
      setMemberScores(res);
      setSession(res.session);
    }).catch((err) => {

    }).finally(() => {
      setIsLoading(false);
    });
  }, [member, collectionId]);

  const navigate = useNavigate();

  useEffect(() => {
    API.settings.getComp(competency._id).then((res) => {
      setCompData(res);
    });
  }, [competency]);
  useEffect(() => {
    handleGetMemberScores();
  }, [handleGetMemberScores]);

  const handleSaving = () => {
    API.training.competencies
    .updateMemberScores(member._id, collectionId, {
      ...memberScores,
      session,
    })
    .then(() => {
      toast.success("Successfully saved changes.");
      handleGetMemberScores();
    })
    .catch((err) => {
      toast.error("Unable to save changes due to a error on the server: " + err.response?.data?.message || err.message)
    });
  };

  if (isLoading) {
    return (
      <div className="space-y-6 p-4">
        <div className="flex gap-8">
          <Skeleton className="h-8 w-1/3 dark:bg-zinc-700"></Skeleton>
          <Skeleton className="h-8 w-1/3 dark:bg-zinc-700"></Skeleton>
          <Skeleton className="h-8 w-1/3 dark:bg-zinc-700"></Skeleton>
        </div>
        <div className="flex gap-8">
          <Skeleton className="h-8 w-1/2 dark:bg-zinc-700"></Skeleton>
          <Skeleton className="h-8 w-1/2 dark:bg-zinc-700"></Skeleton>
        </div>
      </div>
    );
  }

  if(member.centralId === profile.sub){
    return (
      <div className={"p-2"}>
        <div className={"text-red-500 dark:text-red-400 text-center"}>Forbidden: You can not modify your own competency scores.</div>
        <div className={"mt-4 flex gap-2 justify-center"}>
          <button className={"rounded p-2 nonOp border text-sm dark:border-zinc-500"} onClick={() => onClose()}>Close</button>
        </div>
      </div>
    );
  }

  if (!member._hasRole && settings.promptMissingCompetenciesRoles) {
    return (
      <div className={"p-2"}>
        One or more of the following role(s): <span className={"font-bold"}>{competency?.roles?.join(", ")}</span> are not assigned to <strong>{member?.firstName} {member?.lastName}</strong> profile.

        <div className={"mt-4 flex gap-2 justify-between"}>
          <button className={"rounded p-2 nonOp border text-sm dark:border-zinc-500"} onClick={() => onClose()}>Close</button>
          <button className={"rounded p-2 offcall border text-sm dark:border-zinc-500"} onClick={() => navigate(`/team/${member._id}`)}>Go to member</button>
          <button className={"rounded p-2 oncall border text-sm dark:border-zinc-500"} onClick={() => onAssignMissingRoles(member)}>Assign missing roles</button>
        </div>
      </div>
    );
  }

  if (memberScores.isEmpty === undefined && memberScores.session === undefined) return null;
  if (!session) return null;

  return (
    <>
      <div className={"flex items-end gap-4 p-2"}>
        <AuthComponents.Can scope={"competency:session:write"}>
          <button onClick={handleSaving} className="w-full h-10 text-white rounded-md border border-green-500 bg-green-300 bg-opacity-40 px-4 py-2 hover:border-green-300">Save Results</button>
        </AuthComponents.Can>
      </div>
      {compData._id !== undefined && (
        <ScoresForm
          compData={compData}
          memberScores={memberScores}
          onChange={(data) => setMemberScores(data)}
        />
      )}
    </>
  );
};
export default ChainedContext(TeamMemberScore, [[SettingsReceiver, "settings"]]);