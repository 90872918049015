import React, {useEffect, useState} from "react";
import Modal from "../../../components/common/Modal";
import Input from "../../../components/common/Input";
import TextArea from "../../../components/common/TextArea";

const PopupFormFolderName = ({ isEdit = false, data, onClose, onSubmit }) => {
  const [submitError, setSubmitError] = useState(null);
  const [name, setName] = useState("");
  const [fileDescription, setFileDescription] = useState("");

  useEffect(() => {
    if (!data) return;
    setName(data.name);
    setFileDescription(data.description || "");
  }, [data]);

  const handleSubmit = () => {
    setSubmitError(null);
    if (name.trim().length >= 1) {
      onSubmit(name.trim(), fileDescription.trim())
      .then(() => {
        onClose();
      })
      .catch((err) => {
        setSubmitError(err.response.data.message);
      });
    } else {
      setSubmitError("Name can not be empty.");
    }
  };

  return (
    <Modal title={isEdit ? "Rename Folder" : "Create Folder"}>
      <Input
        label="Folder Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <TextArea
        label="Description"
        value={fileDescription}
        onChange={(e) => setFileDescription(e.target.value)}
      />
      {submitError && <div className={"py-1 text-red-500"}>{submitError}</div>}

      <div className="mt-4 flex justify-between border-t border-zinc-500 pt-4">
        <button
          className="rounded-lg bg-primary px-4 py-2 text-white"
          onClick={onClose}
        >
          Cancel
        </button>
        <button
          className="rounded-lg bg-green-500 px-4 py-2 text-white disabled:bg-gray-500"
          onClick={handleSubmit}
        >
          {isEdit ? "Rename" : "Create"}
        </button>
      </div>
    </Modal>
  );
};
export default PopupFormFolderName;