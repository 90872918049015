import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import DayJS from "dayjs";
import Model from "../../../../../components/common/Model";
import Select from "../../../../../components/common/Select";
import ChainedContext from "../../../../../contexts/chainedContext";
import MemberReceiver from "../../../../../contexts/member_context/Member_Receiver";
import API from "../../../../../api";
import TextArea from "../../../../../components/common/TextArea";
import { toast } from "react-hot-toast";

const QuickCourseAdd = ({ onClose, member }) => {
  const [startAt, setStartAt] = useState(new Date());
  const [expiresAt, setExpiresAt] = useState(new Date());
  const [course, setCourse] = useState("");
  const [isPass, setIsPass] = useState("");
  const [comment, setComment] = useState("");
  const [pickableCourses, setPickableCourses] = useState([]);

  useEffect(() => {
    API.courses.list().then((res) => {
      setPickableCourses(res);
    });
  }, []);
  useEffect(() => {
    if (course !== "") {
      const theCourse = pickableCourses.find((a) => a._id === course);
      if (theCourse) {
        setExpiresAt(DayJS(startAt).add(theCourse.validFor, "years").toDate());
      }
    }
  }, [course, pickableCourses, startAt]);

  const handleSubmit = () => {
    API.courses
      .quickAddResult({
        course,
        startAt,
        expiresAt,
        isPass,
        comment,
        member: member._id,
      })
      .then(() => {
        toast.success("Created new course result successfully.");
        onClose();
      })
      .catch((err) => {
        let errorMessage = "There has been and error";
        if (err.isAxiosError !== undefined) {
          if (err.response.data.message !== undefined) {
            errorMessage = err.response.data.message;
          }
        }
        toast.error(errorMessage);
      });
  };

  return (
    <Model title={"Quick Course Add"} onClose={onClose} disableOutsideClick>
      <p className={"py-2"}>
        Quickly add a current/prior course to "{member?.firstName}".
      </p>
      <div className="w-full">
        <Select
          label="Course"
          options={pickableCourses.map((item) => {
            return {
              key: item._id,
              text: item.name,
              value: item._id,
            };
          })}
          value={course}
          onChange={(e) => setCourse(e.target.value)}
        />
      </div>
      {course !== "" && (
        <div className="grid grid-cols-2 gap-2 space-y-2">
          <div className="overflow-show w-full">
            <label htmlFor={"date"} className={`labelClass`}>
              Course/Valid From Date
            </label>
            <DatePicker
              onChange={(date) => setStartAt(date)}
              selected={startAt ? new Date(startAt) : ""}
              dateFormat="dd-MM-yy"
              nextMonthButtonLabel=">"
              previousMonthButtonLabel="<"
              className=" inputClass"
              data-disable-touch-keyboard
            />
          </div>
          <div className="overflow-show w-full">
            <label htmlFor={"date"} className={`labelClass`}>
              Expiry Date
            </label>
            <DatePicker
              onChange={(date) => setExpiresAt(date)}
              selected={expiresAt ? new Date(expiresAt) : ""}
              dateFormat="dd-MM-yy"
              nextMonthButtonLabel=">"
              previousMonthButtonLabel="<"
              className="inputClass"
              data-disable-touch-keyboard
            />
          </div>
          <div className="w-full">
            <Select
              label="Result"
              options={[
                { key: 1, text: "Failed", value: false },
                { key: 2, text: "Passed", value: true },
              ]}
              value={isPass}
              onChange={(e) => setIsPass(e.target.value)}
            />
          </div>
          <div className="col-span-2">
            <TextArea
              label="Comment"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
          </div>
        </div>
      )}

      <div className="mt-4 flex justify-between border-t border-zinc-500 pt-4">
        <button
          className="rounded-lg bg-primary px-4 py-2 text-white"
          onClick={onClose}
        >
          Cancel
        </button>
        <button
          className="rounded-lg bg-green-500 px-4 py-2 text-white disabled:bg-green-500"
          onClick={handleSubmit}
        >
          Save
        </button>
      </div>
    </Model>
  );
};
export default ChainedContext(QuickCourseAdd, [[MemberReceiver, "member"]]);
