import React, { useState, useEffect } from "react";
import Box from "../../../components/common/Box";
import Input from "../../../components/common/Input";
import Button from "../../../components/common/Button";
import { Dialog, Transition } from "@headlessui/react";
import { TrashIcon } from "@heroicons/react/20/solid";
import { Skeleton } from "../../../components/ui/skeleton";
import { PencilIcon } from "@heroicons/react/24/outline";
import Select from "../../../components/common/Select";
import API from "../../../api";

const CompSectionsList = ({
  title,
  field,
  modules,
  data,
  refresh,
  module,
  loading = false,
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [order, setOrder] = useState("");
  const [theComp, setComp] = useState("");
  const [del, setDelete] = useState("");
  const [editID, setEditID] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [dataList, setDataList] = useState(data);

  useEffect(() => {
    setDataList(data);
  }, [data]);
  useEffect(() => {
    if (module > "") {
      setComp(module);
    }
  }, [module]);
  useEffect(() => {
    if (module > "") {
      setComp(module);
      setDataList(
        data.filter((s) => {
          return s.module._id === module;
        })
      );
    }
  }, [module, data]);

  const handleEdit = (item) => {
    setName(item.name);
    setDescription(item.description);
    setOrder(item.sortOrder);
    setComp(item.module._id);
    setEditID(item._id);
    setIsEdit(true);
  };
  const addCompModule = () => {
    const data = {
      name: name.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1);
      }),
      description: description,
      module: theComp,
      order: order,
    };
    API.settings.addCompSection(data).then(() => {
      refresh();
    });
    setName("");
    setComp("");
    setOrder("");
    setDescription("");
  };
  const updateCompModule = () => {
    const data = {
      name: name.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1);
      }),
      description: description,
      module: theComp,
      order: order,
    };
    API.settings.updateCompSection(editID, data).then(() => {
      refresh();
    });
    setName("");
    setComp("");
    setOrder("");
    setDescription("");
    setIsEdit(false);
    setEditID(null);
  };
  const handleDelete = () => {
    API.settings.deleteCompSection(del._id).then(() => {
      setIsOpen(false);
      // setDelete(null);
      refresh();
    });
  };
  const CloseDialog = () => {
    return (
      <Transition
        show={isOpen}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Dialog
          open={isOpen}
          onClose={() => setIsOpen(false)}
          className="fixed inset-0 z-10 overflow-y-auto "
        >
          <div className="min-h-screen text-center">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-80" />
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className="my-8 inline-block w-full max-w-2xl transform overflow-hidden rounded-2xl border-4 border-gray-700 bg-gray-50 p-6 text-left align-middle shadow-xl transition-all dark:bg-zinc-700">
              <div className="pt-4 text-center">
                <Dialog.Title className={"text-2xl"}>
                  Delete Competency{" "}
                  <span className="font-bold italic">{del.name}</span>
                </Dialog.Title>
                <div className="py-4 text-ss">
                  This action is not reversable so please be sure!
                </div>
              </div>

              <div className="mt-4 flex justify-between border-t border-zinc-500 pt-4">
                <button
                  className="rounded-lg bg-primary px-4 py-2 text-white"
                  onClick={() => setIsOpen(false)}
                >
                  Cancel
                </button>
                <button
                  className="rounded-lg bg-red-500 px-4 py-2 text-white disabled:bg-red-500"
                  onClick={handleDelete}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  };
  const addComp = (e) => {
    setComp(e);
  };

  return (
    <Box title={title}>
      <div className=" w-full ">
        <div className="grid w-full items-center p-2 md:grid-cols-5">
          <div className="md:col-span-4">
            <div className="col-span-2 flex gap-2">
              <div className="w-full">
                <label className="labelClass">Modules</label>
                <div className="flex w-full  ">
                  <Select
                    options={
                      (modules !== undefined &&
                        modules.map((item) => {
                          return {
                            key: item._id,
                            text: item.name,
                            value: item._id,
                          };
                        })) ||
                      []
                    }
                    value={theComp}
                    onChange={(e) => addComp(e.target.value)}
                  />
                </div>
              </div>
              <div className="w-full">
                <label className=" labelClass">Section</label>
                <Input
                  value={name}
                  type={"text"}
                  field={field}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="section..."
                />
              </div>
              <div className="flex justify-between">
                <div className="w-28">
                  <label className="labelClass">Sort order</label>
                  <Input
                    value={order}
                    type={"number"}
                    field={field}
                    onChange={(e) => setOrder(e.target.value)}
                    placeholder="1"
                  />
                </div>
              </div>
            </div>
            <div className="col-span-2 w-full">
              <label className="labelClass">Description</label>
              <Input
                value={description}
                type={"text"}
                field={field}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="description..."
              />
            </div>
          </div>
          <div className="col-span-1 flex h-full w-full justify-center  pt-4">
            <Button
              disabled={name === "" ? true : false}
              onClick={isEdit ? updateCompModule : addCompModule}
              colour={"bg-green-700 text-white"}
              text={isEdit ? "Update" : "Add"}
            />
          </div>
        </div>
        {CloseDialog()}
        <div className="w-full pt-4">
          {loading && (
            <div className="col-span-2 space-y-2">
              <Skeleton className="h-10 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
              <Skeleton className="h-10 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
              <Skeleton className="h-10 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
              <Skeleton className="h-10 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
            </div>
          )}
          {!loading && (
            <table className="tableClass">
              <thead className="tableHeadClass">
                <tr className="tableHeadRowClass ">
                  <th className="tableHeadCellClass">Name</th>
                  <th className="tableHeadCellClass">Description</th>
                  <th className="tableHeadCellClass">Module</th>
                  <th className="tableHeadCellClass">Order</th>
                  <th className="tableHeadCellClass"></th>
                </tr>
              </thead>
              <tbody className="tableBodyClass">
                {dataList &&
                  dataList.toSorted((a, b) => {
                    if(a.module?.name > b.module?.name) return 1;
                    if(a.module?.name < b.module?.name) return -1;
                    return 0;
                  }).map((d) => {
                    return (
                      <tr className="tableBodyRowClass" key={d._id}>
                        <td className="tableBodyCellClass">{d.name} </td>
                        <td className="tableBodyCellClass">{d.description}</td>
                        <td className="tableBodyCellClass text-center">
                          <div className="flex gap-2">{d.module?.name}</div>
                        </td>
                        <td className="tableBodyCellClass w-20 text-center">{d.sortOrder}</td>
                        <td className="tableBodyCellClass w-32 space-x-1 text-center">
                          <button
                            // disabled={d.isAvailableDefault || d.isAssignedDefault}
                            onClick={() => handleEdit(d)}
                            className="rounded-md border bg-primary bg-opacity-80  px-2 py-1 text-sm  text-white hover:bg-gray-800 disabled:opacity-20"
                          >
                            <PencilIcon className="h-6 w-6 text-yellow-200 " />
                          </button>
                          <button
                            className="rounded-md border border-red-400 bg-red-700 bg-opacity-80 px-2 py-1 text-sm text-white hover:border-red-300 hover:bg-red-800 disabled:opacity-20"
                            onClick={() => {
                              setDelete(d);
                              setIsOpen(true);
                            }}
                          >
                            <TrashIcon className="h-6 w-6 text-red-200" />
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </Box>
  );
};
export default CompSectionsList;
