import { Style, Stroke, Fill, Text, Circle } from "ol/style";

const pointStyle = new Style({
  image: new Circle({
    radius: 8,
    fill: new Fill({ color: "rgba(107, 21, 209, 1)" }),
    stroke: new Stroke({ color: "gray", width: 2 }),
  }),
  text: new Text({
    fill: new Fill({
      color: "white",
    }),
    backgroundFill: new Fill({ color: [0, 0, 0, 0.7] }),
    padding: [2, 3, 3, 2],
    offsetX: 0,
    offsetY: 25,
    // scale: 1.2,

    font: "10px sans-serif",
  }),
});
const smPointStyle = new Style({
  image: new Circle({
    radius: 6,
    fill: new Fill({ color: "rgba(107, 21, 209, 1)" }),
    stroke: new Stroke({ color: "black", width: 1 }),
  }),
  text: new Text({
    fill: new Fill({
      color: "white",
    }),
    backgroundFill: new Fill({ color: [0, 0, 0, 0.7] }),
    padding: [2, 3, 3, 2],
    offsetX: 0,
    offsetY: 18,
    scale: 1.2,

    font: "10px sans-serif",
  }),
});
const DefaultStyle = new Style({
  stroke: new Stroke({
    color: "#ff0000",
    width: 4,
    // width: 50 / map.getView().getResolution(), // calc for setting width in m
  }),
  text: new Text({
    fill: new Fill({
      color: "black",
    }),
    backgroundFill: new Fill({ color: [255, 255, 255, 0.7] }),
    padding: [2, 4, 2, 4],
    textAlign: "center",
    offsetY: 0,
    // scale: 1.2,
    font: "16px sans-serif",
  }),
});
const SinglePointOverride = (feature) => {
  const color = "red";
  const acc = feature.get("acc") || "";
  const label = feature.get("name") || "";

  pointStyle
    .getText()
    .setText(label + (acc ? "\nacc: " + acc.toFixed(1) + " m." : ""));
  pointStyle.getText().getBackgroundFill().setColor("rgba(255,0,0,0.8)");
  pointStyle.getText().setScale(1.8);
  pointStyle.getText().setOffsetY(36);
  const img = pointStyle.getImage();
  img.getFill().setColor(color);
  img.setRadius(img.getRadius());

  return pointStyle;
};
const Marker = (feature) => {
  let color = "blue";
  const label = feature.get("name") || "";
  const type = feature.get("type") || "";
  const ipp = feature.get("ipp") || "";
  const misper = feature.get("misper") || "";
  var img = pointStyle.getImage();
  if (ipp === 1) color = "red";
  if (misper === 1) color = "#30f409";
  img.getFill().setColor(color);
  img.setRadius(img.getRadius());
  pointStyle.getText().setText(label + "\n" + type);
  if (type === "Home Address") {
    pointStyle.getText().getBackgroundFill().setColor("rgba(255, 0, 0,0.8)");
  } else if (misper === 1) {
    pointStyle.getText().getBackgroundFill().setColor("rgba(3, 122, 37,0.8)");
  } else {
    pointStyle.getText().getBackgroundFill().setColor("#000000");
  }

  return pointStyle;
};
const Locations = (feature) => {
  let color = "blue";
  const label = feature.get("name") || "";
  const type = feature.get("type") || "";
  const job = feature.get("job") || "";
  const ipp = feature.get("ipp") || "";
  const misper = feature.get("misper") || "";

  const img = pointStyle.getImage();
  if (type === "Home Address") color = "red";
  if (misper === 1) color = "#30f409";
  img.getFill().setColor(color);
  img.setRadius(img.getRadius());

  pointStyle.getText().setText(label + "\n" + type);
  if (type === "Home Address") {
    pointStyle.getText().getBackgroundFill().setColor("rgba(255, 0, 0,0.8)");
  } else if (misper === 1) {
    pointStyle.getText().getBackgroundFill().setColor("rgba(3, 122, 37,0.8)");
  } else {
    pointStyle.getText().getBackgroundFill().setColor("#000000");
  }
  pointStyle.getText().setScale(1);
  pointStyle.getText().setOffsetY(25);
  return pointStyle;
};
const SarMapTracking = (feature) => {
  let color = feature.get("onlineStatus") || "#eeeeee";
  if (color === "green") color = "#30f409";
  const label = feature.get("title") || "";
  smPointStyle.getImage().getFill().setColor(color);
  smPointStyle.getText().setText(label);
  return smPointStyle;
};

export { DefaultStyle, SinglePointOverride, Marker, Locations, SarMapTracking };
