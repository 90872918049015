import React from "react";
import {Authentication} from "../../../../lib/_sso";
import Modules from "../../../team/member/_components/Modules";
const ScoresForm = ({ compData, memberScores, onChange }) => {

  const onScoreUpdate = (skill, value) => {
    if(memberScores.isEmpty !== undefined){
      return;
    }

    if(memberScores.scores.find(a => a.skill === skill._id) === undefined){
      memberScores.scores.push({
        skill: skill._id
      });
    }

    memberScores.scores = memberScores.scores.map(a => {
      if(a.skill !== skill._id) return a;

      if(skill.type === "score"){
        return {
          ...a,
          result: (value >= skill.scoreConfig.pass_score) ? "pass": "fail",
          score: value
        }
      }
      return {
        ...a,
        result: value,
        score: null
      }
    });

    onChange({...memberScores});
  }

  if(memberScores.isEmpty !== undefined){
    return null;
  }

  return (
    <div className="p-2">
      {compData.children.map((m) => {
        return (
          <div key={m._id} className="p-2">
            <Modules data={m} isScoring={Authentication.can("competency:session:write")} memberScores={memberScores} onScoreUpdate={onScoreUpdate} />
          </div>
        );
      })}
    </div>
  );
}
export default ScoresForm;