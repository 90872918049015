import React, {useEffect, useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Skeleton} from "../ui/skeleton";
import Stat from "../common/Stat";
import Box from "../common/Box";
import API from "../../api";


const ExpiringQualifications = () => {
  const [loading, setLoading] = useState(true);
  const [requirementSelected, setRequirementSelected] = useState("expired");
  const [expiringQualifications, setExpiringQualifications] = useState({
    competencies: [],
    courses: []
  });

  const navigate = useNavigate();

  useEffect(() => {
    API.reports.qualifications.dashStats().then((res) => {
      setExpiringQualifications(res);
      setLoading(false);
    });
  }, []);

  const displayCourses = useMemo(() => {
    return expiringQualifications.courses.map(item => {
      return {
        _id: item._id,
        title: item.name,
        value: item._stats[requirementSelected]
      }
    }).filter(a => a.value > 0);
  }, [expiringQualifications, requirementSelected]);
  const displayComps = useMemo(() => {
    return expiringQualifications.competencies.map(item => {
      return {
        _id: item._id,
        title: item.name,
        value: item._stats[requirementSelected]
      }
    }).filter(a => a.value > 0);
  }, [expiringQualifications, requirementSelected]);

  return (
    <Box title="Expiring Courses">
      <div className={"flex justify-center gap-1 pt-2 text-center"}>
        <button
          className={`${
            requirementSelected === "expired"
              ? "bg-primary"
              : "bg-zinc-500"
          } m-1 rounded px-2 py-1 text-white`}
          onClick={() => setRequirementSelected("expired")}
        >
          Expired
        </button>
        <button
          className={`${
            requirementSelected === "threeMonths"
              ? "bg-primary"
              : "bg-zinc-500"
          } m-1 rounded px-2 py-1 text-white`}
          onClick={() => setRequirementSelected("threeMonths")}
        >
          In 3 months
        </button>
        <button
          className={`${
            requirementSelected === "sixMonths"
              ? "bg-primary"
              : "bg-zinc-500"
          } m-1 rounded px-2 py-1 text-white`}
          onClick={() => setRequirementSelected("sixMonths")}
        >
          In 6 months
        </button>
      </div>

      {loading ? (
        <div className="flex flex-wrap justify-center gap-4 p-4">
          <Skeleton className="h-20 w-1/4 bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
          <Skeleton className="h-20 w-1/4 bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
          <Skeleton className="h-20 w-1/4 bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
        </div>
      ) : (
        <div className={"pb-4 mt-4"}>
          {/*<h2 className={"pl-2 text-lg underline pb-4"}>Courses</h2>*/}
          <div className="flex flex-wrap gap-4 px-4 ">
            {displayCourses.map((item) => {
              return (
                <Stat
                  key={item._id}
                  action={() => navigate(`/reports/courses?id=${item._id}&filter=${requirementSelected}`)}
                  label={item.title}
                  value={item.value}
                  colour="bg-blue-300 bg-opacity-40"
                />
              );
            })}
          </div>
          {displayCourses.length === 0 && <div className={"text-center pb-4"}>There are no items to display</div>}

          {/*<h2 className={"pl-2 text-lg underline pb-4 pt-2"}>Competencies</h2>*/}
          {/*<div className="flex flex-wrap gap-4 px-4">*/}
          {/*  {displayComps.map((item) => {*/}
          {/*    return (*/}
          {/*      <Stat*/}
          {/*        key={item._id}*/}
          {/*        action={() => navigate(`/reports/competencies?id=${item._id}&filter=${requirementSelected}`)}*/}
          {/*        label={item.title}*/}
          {/*        value={item.value}*/}
          {/*        colour="bg-blue-300 bg-opacity-40"*/}
          {/*      />*/}
          {/*    );*/}
          {/*  })}*/}
          {/*</div>*/}
          {/*{displayComps.length === 0 && <div className={"text-center"}>There are no items to display</div>}*/}
        </div>

      )}
    </Box>
  );
}
export default ExpiringQualifications;