import React, { useContext, useEffect, useMemo } from "react";
import OLTileLayer from "ol/layer/Tile";
import { Map, Projection } from "../../context";

const CustomTileLayer = ({ source, visible = true , zIndex = 0}) => {
  const mapContext = useContext(Map);
  const projectionContext = useContext(Projection);

  const tileLayer = useMemo(() => new OLTileLayer({
    source
  }), [source]);

  useEffect(() => {
    tileLayer.setZIndex(zIndex);
  }, [tileLayer, zIndex]);
  useEffect(() => {
    tileLayer.setVisible(visible);
  }, [tileLayer, visible]);
  useEffect(() => {
    if (!mapContext) return;

    if(mapContext.getView().getProjection().getCode() !== source.getProjection().getCode()){
      if(visible){
        projectionContext.setProjection(source.getProjection().getCode());
      }
    }
  }, [mapContext, source, visible])
  useEffect(() => {
    if (!mapContext) return;

    mapContext.addLayer(tileLayer);
    return () => {
      if (mapContext) {
        mapContext.removeLayer(tileLayer);
      }
    };
  }, [mapContext, tileLayer]);

  return null;
}
export default CustomTileLayer;