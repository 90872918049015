import React from "react";
import {Authentication} from "@liquidcomputing/sarstuff__reactjs-singlesignon";
import Logo from "./assets/images/logo.png";
import _buildNumber from "./_buildNumber.js";

export default class ErrorCatchPage extends React.Component {
  state = {
    isError: false,
    error: {},
    info: {}
  };

  componentDidCatch(error, info){
    this.setState({isError: true, error, info});
  }

  render() {
    if (this.state.isError) {
      return (
        <div>
          <img className={"pt-4"} style={{width: 250, margin: "auto"}} src={Logo}/>
          <div className={"m-8 p-2 border rounded border-red-600 text-center"}>
            <div className={"mb-2 text-3xl text-center"}>TeamSite has detected a problem</div>
            Please send a description to <a className={"font-bold underline"} href={"mailto:support@sarstuff.co.uk"}>support@sarstuff.co.uk</a> detailing
            what screen you were in and what you were trying to accomplish.
          </div>
          <div className={"text-center"}>
            <button className="px-4 py-2 bg-blue-600 rounded-lg" onClick={() => window.location = "/"}> Reload TeamSite</button>
          </div>

          <div className={"m-8 border text-center"}><span className={"font-bold underline text-xl mb-2 block"}>Debug Info</span>
            URL: {window.location.toString()}<br />
            Build: #{_buildNumber}<br /><br />

            {Authentication.getUserData() !== null && "UserID: " + Authentication.getUserData().sub}<br />
            {Authentication.getUserData() !== null && "OrgID: " + Authentication.getUserData().organisation._id}<br />
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}