import React, { useState, useEffect } from "react";
import Select from "../../../../components/common/Select";
import dayjs from "dayjs";
const RenderResult = ({ skill, results }) => {
  if (results === undefined) {
    return null;
  }

  const foundScore = results.find((a) => a.skill === skill._id);
  if (!foundScore) {
    return null;
  }
  // console.log(foundScore);
  if (skill.type === "pass/fail") {
    return (
      <div className="flex justify-between gap-2">
        <span
          className={`${
            foundScore.result.toUpperCase() === "PASS"
              ? "text-green-500"
              : "text-red-500"
          }`}
        >
          {foundScore.result.toUpperCase()}
        </span>
        <span className="w-80 text-right">
          Expires: {dayjs(foundScore.session.expiryDate).format("DD-MM-YYYY")}
        </span>
      </div>
    );
  } else if (skill.type === "score") {
    const scoreValue = skill.scoreConfig.scores.find(
      (a) => a.score === foundScore.score
    );
    return (
      <div className="flex justify-between gap-2">
        <span
          className={`${
            foundScore.result.toUpperCase() === "PASS"
              ? "text-green-500"
              : "text-red-500"
          }`}
        >
          {foundScore.result.toUpperCase()}

          {" | (" +
            (scoreValue !== undefined
              ? foundScore.score + ") " + scoreValue.name
              : foundScore.score)}
        </span>
        <span className="w-80 text-right">
          Expires: {dayjs(foundScore.session.expiryDate).format("DD-MM-YYYY")}
        </span>
      </div>
    );
  }
  return "[Unknown]";
};

const RenderScoreForm = ({ skill, score, onChange }) => {
  if (skill.type === "pass/fail") {
    return (
      <div>
        <Select
          options={[
            {
              key: "pass",
              text: "Pass",
              value: "pass",
            },
            {
              key: "fail",
              text: "Fail",
              value: "fail",
            },
          ]}
          value={score}
          onChange={(e) => onChange(e.target.value)}
        />
      </div>
    );
  }
  if (skill.type === "score") {
    return (
      <div>
        <Select
          options={skill.scoreConfig.scores.map((a) => {
            return {
              key: a._id,
              text: a.score + " | " + a.name,
              value: a.score,
            };
          })}
          value={score}
          onChange={(e) => onChange(e.target.value)}
        />
      </div>
    );
  }
  return <p>[Unknown score type]</p>;
};

export default function Modules({
  data,
  isScoring,
  memberScores,
  onScoreUpdate,
  results,
  disabled,
}) {
  return (
    <>
      <div className="w-full p-2">
        <div className="border-b-4 border-zinc-700 p-2 text-xl tracking-wider text-primary dark:text-primary-dark">
          {data.name}
        </div>
        {data.children.map((s) => {
          return (
            <div key={s._id} className="w-full pl-4">
              <div className="p-4 text-xl tracking-wider text-primary dark:text-primary-dark">
                {s.name}
              </div>
              {s.children.map((skill) => {
                let score = "";
                if (isScoring) {
                  const foundScoreObj = memberScores.scores.find(
                    (a) => a.skill === skill._id
                  );
                  if (foundScoreObj) {
                    score =
                      skill.type === "score"
                        ? foundScoreObj.score
                        : foundScoreObj.result;
                  }
                }

                return (
                  // <div key={skill._id} className="flex pl-4 ">
                  <div
                    key={skill._id}
                    className="flex items-center border-y border-zinc-500"
                  >
                    <div className=" ml-4 h-full w-1/3  p-2 tracking-wider">
                      {skill.name}
                    </div>
                    <div className="  h-full w-44  items-stretch border-x border-zinc-500 p-2 text-center">
                      {skill.type}
                    </div>
                    <div className="    h-full w-full flex-wrap p-2 text-left">
                      {isScoring && (
                        <RenderScoreForm
                          skill={skill}
                          score={score}
                          onChange={(data) => onScoreUpdate(skill, data)}
                        />
                      )}
                      {results && (
                        <RenderResult skill={skill} results={results} />
                      )}
                    </div>
                  </div>
                  // </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </>
  );
}
