import React, { useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { TrashIcon } from "@heroicons/react/20/solid";
import { PencilIcon } from "@heroicons/react/24/outline";
import Box from "../../../../components/common/Box";
import Input from "../../../../components/common/Input";
import DOB from "../../../../components/common/DOB";
import dayjs from "dayjs";
const VaccinationCard = ({ title, field, onSubmit, onDelete, data }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [editID, setEditID] = useState(null);
  const [value, setValue] = useState("");
  const [date1, setDate1] = useState("");
  const [date2, setDate2] = useState("");
  const [del, setDelete] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setDate1(dayjs().format("YYYY-MM-DD"));
  }, []);

  const handleAdd = () => {
    let fd = data;
    if (!Array.isArray(fd)) {
      fd = [];
    }
    if (isEdit) {
      fd = fd.map((item, index) => {
        if (index !== editID) {
          return item;
        }

        return {
          ...item,
          name: value.replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
          }),
          taken: date1,
          expires: date2,
        };
      });
    } else {
      fd.push({
        name: value.replace(/\w\S*/g, function (txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1);
        }),
        taken: date1,
        expires: date2,
      });
    }

    let theField = "vaccinations";
    const list = {};
    list[theField] = fd;
    onSubmit(list);
    setValue("");
    setDate1("");
    setDate2("");
    setIsEdit(false);
    setEditID(null);
  };
  const handleDelete = () => {
    let newList = data.filter((item, i) => {
      return del !== item;
    });
    let theField = "vaccinations";
    const list = {};
    list[theField] = newList;
    onDelete(list);
    setIsOpen(false);
  };
  const handleEdit = (item, index) => {
    setValue(item.name);
    setDate1(item.taken);
    setDate2(item.expires);
    setEditID(index);
    setIsEdit(true);
  };

  const CloseDialog = () => {
    return (
      <Transition
        show={isOpen}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Dialog
          open={isOpen}
          onClose={() => setIsOpen(false)}
          className="fixed inset-0 z-10 overflow-y-auto "
        >
          <div className="min-h-screen text-center">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-80" />
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className="my-8 inline-block w-full max-w-2xl transform overflow-hidden rounded-2xl border-4 border-gray-700 bg-gray-50 p-6 text-left align-middle shadow-xl transition-all dark:bg-zinc-700">
              <div className="pt-4">
                <Dialog.Title>
                  Delete <span className="italic">{title}</span> Value{" "}
                  <span className="font-bold italic">{del.name}</span>
                </Dialog.Title>
              </div>

              <div className="mt-4 flex justify-between border-t border-gray-700 pt-4">
                <button
                  className="rounded-lg bg-primary px-4 py-2 text-white"
                  onClick={() => setIsOpen(false)}
                >
                  Cancel
                </button>
                <button
                  className="rounded-lg bg-red-500 px-4 py-2 text-white disabled:bg-red-500"
                  onClick={handleDelete}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  };
  // console.log(date1, date2);
  return (
    <Box title={title}>
      <div className="flex w-full flex-col ">
        <div className="flex flex-wrap justify-center gap-2 px-4">
          <div className="w-full px-4">
            <Input
              label="Vaccine"
              field={"name"}
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />
          </div>
          <div className="flex gap-2">
            <DOB
              label="Taken"
              date={date1}
              submit={(d) => setDate1(d)}
              hideAge={true}
              maxOffset={10}
            />

            <DOB
              label="Expires"
              date={date2}
              submit={(d) => setDate2(d)}
              hideAge={true}
              back={false}
              maxOffset={20}
            />
            <div className="w-32">
              <button
                disabled={value === ""}
                onClick={handleAdd}
                className="mt-6 h-10 cursor-pointer rounded-md border border-green-500 bg-green-300 bg-opacity-40 px-4 py-2 text-green-500 hover:border-green-300"
              >
                {isEdit ? "Update" : "Add"}
              </button>
            </div>
          </div>
        </div>
        {CloseDialog()}
        <div className="w-full pt-4">
          <table className="w-full">
            <thead>
              <tr className="thrc">
                <th className="thcc">Vaccine</th>
                <th className="thcc">Taken</th>
                <th className="thcc">Expires</th>
                <th className="thcc"></th>
              </tr>
            </thead>
            <tbody className="border-t border-gray-300 dark:border-zinc-500">
              {data &&
                data
                  .sort((a, b) => {
                    if (a > b) return 1;
                    if (a < b) return -1;
                    return 0;
                  })
                  .map((d, i) => {
                    return (
                      <tr
                        key={i}
                        className="border-b border-gray-300 py-4 text-black dark:border-zinc-500 dark:text-white"
                      >
                        <td className="py-2 pl-2 text-lg">{d.name}</td>
                        <td className="w-52 py-2 pl-2 text-lg">
                          {d.taken ? dayjs(d.taken).format("DD-MM-YYYY") : "--"}
                        </td>
                        <td className="w-52 py-2 pl-2 text-lg">
                          {d.expires
                            ? dayjs(d.expires).format("DD-MM-YYYY")
                            : "--"}
                        </td>
                        <td className="w-28 pr-2 pt-2 text-right">
                          <button
                            onClick={() => handleEdit(d, i)}
                            className="mr-2 rounded-md border border-gray-400 bg-gray-700 bg-opacity-80 px-2 py-1 text-sm text-white hover:border-gray-300 hover:bg-gray-800"
                          >
                            <PencilIcon className="h-6 w-6 text-gray-200" />
                          </button>
                          <button
                            className="rounded-md border border-red-400 bg-red-700 bg-opacity-80 px-2 py-1 text-sm text-white hover:border-red-300 hover:bg-red-800"
                            onClick={() => {
                              setDelete(d);
                              setIsOpen(true);
                            }}
                          >
                            <TrashIcon className="h-6 w-6 text-red-200" />
                          </button>
                        </td>
                      </tr>
                    );
                  })}
            </tbody>
          </table>
        </div>
      </div>
    </Box>
  );
};
export default VaccinationCard;
