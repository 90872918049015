import NodeCache from "node-cache";
import AppConfig from "../_appConfig";
import { APIRequest } from "../lib/_sso";

const organisationCache = new NodeCache({
  stdTTL: 300,
});
const CACHE_KEY_1 = "theMembership";
const server = AppConfig.api_server;

const competencies = {
  getRoles: (member_id) =>
    APIRequest.get(`${server}/members/${member_id}/competencies/roles`).then(
      (res) => res.data
    ),
  latest_session: (member_id, comp_id) =>
    APIRequest.get(
      `${server}/members/${member_id}/competencies/${comp_id}/session/latest`
    ).then((res) => res.data),
};
const cpd = {
  getRoles: (member_id) =>
    APIRequest.get(`${server}/members/${member_id}/cpd/roles`).then(
      (res) => res.data
    ),
  addRecord: (member_id, data) =>
    APIRequest.post(`${server}/members/${member_id}/cpd`, data, {
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => res.data),
  editRecord: (member_id, cpd_id, data) =>
    APIRequest.patch(`${server}/members/${member_id}/cpd/${cpd_id}`, data, {
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => res.data),
  getRecords: (member_id) =>
    APIRequest.get(`${server}/members/${member_id}/cpd`).then(
      (res) => res.data
    ),
  getRequirements: (member_id) =>
    APIRequest.get(`${server}/members/${member_id}/cpd/config`).then(
      (res) => res.data
    ),
  downloadFile: (member_id, cpd_id, attachment_id) =>
    APIRequest.get(
      `${server}/members/${member_id}/cpd/${cpd_id}/attachment/${attachment_id}/link`
    ).then((res) => res.data),
};
const courses = {
  getRoles: memberId => APIRequest.get(`${server}/members/${memberId}/course/roles`).then(res => res.data),
  getCourses: memberId => APIRequest.get(`${server}/members/${memberId}/course`).then(res => res.data),
  getRequired: memberId => APIRequest.get(`${server}/members/${memberId}/course/required`).then(res => res.data),
};
const requirements = {
  all: (member_id) =>
    APIRequest.get(`${server}/members/${member_id}/requirement`).then(
      (res) => res.data
    ),
  update: (member_id, requirement_id, data) =>
    APIRequest.patch(
      `${server}/members/${member_id}/requirement/${requirement_id}`,
      data
    ).then((res) => res.data),
};
const photo = {
  getAccessLink: (id) =>
    APIRequest.get(server + `/members/${id}/photo/link`).then(
      (res) => res.data
    ),
  getUploadConfig: (id) =>
    APIRequest.get(server + `/members/${id}/photo/_uploadConfig`).then(
      (res) => res.data
    ),
  uploadPhoto: (id, profilePhoto) =>
    APIRequest.patch(server + `/members/${id}/photo/upload`, profilePhoto, {
      headers: { "Content-Type": "multipart/form-data" },
    }),
};
const notes = {
  allNotes: memberId => APIRequest.get(server + `/members/${memberId}/notes`).then(res => res.data),
  createNote: (memberId, data) => APIRequest.post(server + `/members/${memberId}/notes`, data).then(res => res.data),
  removeNote: (memberId, noteId) => APIRequest.delete(server + `/members/${memberId}/notes/${noteId}`).then(res => res.data),
};

export default {
  all: () => APIRequest.get(`${server}/members`).then((res) => res.data),
  formList: () => APIRequest.get(`${server}/members/form_list`).then((res) => res.data),
  archived: () => APIRequest.get(`${server}/members/archived`).then((res) => res.data),
  archivedCount: () => APIRequest.get(`${server}/members/archived/count`).then((res) => res.data),
  allCache: async () => {
    if (organisationCache.has(CACHE_KEY_1)) {
      return organisationCache.get(CACHE_KEY_1);
    }
    return APIRequest.get(`${server}/members`).then((res) => {
      organisationCache.set(CACHE_KEY_1, res.data);
      return res.data;
    });
  },
  dash: () => APIRequest.get(`${server}/members/dash`).then((res) => res.data),
  create: (data) => APIRequest.post(`${server}/members`, data).then((res) => res.data),
  show: (member_id) => APIRequest.get(`${server}/members/${member_id}`).then((res) => res.data),
  details: (member_id) => APIRequest.get(`${server}/members/${member_id}/details`).then((res) => res.data),
  medical: (member_id) => APIRequest.get(`${server}/members/${member_id}/medical`).then((res) => res.data),
  getMemberRoles: (member_id) => APIRequest.get(`${server}/members/${member_id}/roles`).then((res) => res.data),
  update: (member_id, data) =>
    APIRequest.patch(`${server}/members/${member_id}`, data).then((res) => {
      organisationCache.flushAll();
      return res.data;
    }),
  updateMedical: (member_id, data) => APIRequest.patch(`${server}/members/${member_id}/medical`, data).then((res) => res.data),
  addRole: (member_id, data) => APIRequest.post(`${server}/members/${member_id}/addRole`, data).then((res) => {
      organisationCache.flushAll();
      return res.data;
    }),
  deleteRole: (member_id, role_id) => APIRequest.delete(`${server}/members/${member_id}/removeRole/${role_id}`).then((res) => {
      organisationCache.flushAll();
      return res.data;
    }),
  deploymentStats: (member_id) => APIRequest.get(`${server}/members/${member_id}/deployments/stats`).then((res) => res.data),
  deploymentList: (member_id) => APIRequest.get(`${server}/members/${member_id}/deployments/list`).then((res) => res.data),
  assignedAssets: (member_id) => APIRequest.get(`${server}/members/${member_id}/assets`).then((res) => res.data),
  photo,
  competencies,
  cpd,
  courses,
  requirements,
  notes,
};
