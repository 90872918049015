import React, { Component } from "react";
import { Routes, Route, Outlet, Link } from "react-router-dom";
import IncidentNav from "./_components/NavBar";
import IncidentDashboard from "./dashboard";
import IncidentAdd from "./addIncident";
import IncidentEdit from "./editIncident";
import IncidentView from "./viewIncident";
import IncidentImport from "./importIncidents";
import {AuthComponents} from "../../lib/_sso";

export default class IncidentPages extends Component {
  render() {
    return (
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<IncidentDashboard />} />
          <Route path="/add" element={<IncidentAdd />} />
          <Route path="/import" element={<IncidentImport />} />
          <Route path={"/view/:incidentId"} element={<IncidentView />} />
          <Route path={"/edit/:incidentId"} element={<IncidentEdit />} />
          <Route path="*" element={<NoMatch2 />} />
        </Route>
      </Routes>
    );
  }
}
function Layout() {
  const scope = "teamsite:incident_management";

  return (
    <div className="min-h-full">
      <IncidentNav />
      <main>
        <AuthComponents.Can scope={scope}>
          <Outlet />
        </AuthComponents.Can>
        <AuthComponents.CanNot scope={scope}>
          <div className={"p-2 text-red-500"}>Access Denied: You do not have permission to access this page.</div>
        </AuthComponents.CanNot>
      </main>
    </div>
  );
}
function NoMatch2() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}
