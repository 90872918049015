import React, { useState, useEffect, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import API from "../../api";
import Box from "../../components/common/Box";
import Stat from "../../components/common/Stat";
import Input from "../../components/common/Input";
import MemberList from "../../components/common/MemberList";
import SettingsReceiver from "../../contexts/settings_context/Settings_Receiver";
import OrganisationReceiver from "../../contexts/organisation/receiver";
import ChainedContext from "../../contexts/chainedContext";
import { Skeleton } from "../../components/ui/skeleton";

function ArchivedTeamList({ settings, organisation }) {
  const [members, setMembers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [sortByName, setSortByName] = useState(true);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [status, setStatus] = useState("");
  const [filter, setFilter] = useState("");

  const navigate = useNavigate();
  const [search, setSearch] = useSearchParams();

  const getData = () => {
    API.member
      .archived()
      .then((res) => {
        setMembers(res);
      })
      .finally(() => {
        setLoading(false);
      });
    API.settings.getRoles().then((res) => {
      setRoles(res);
    });
  };

  useEffect(() => {
    getData();
    settings._refresh();
  }, []);
  useEffect(() => {
    const interval = setInterval(() => {
      API.member
        .archived()
        .then((res) => {
          setMembers(res);
        })
        .finally(() => {
          setLoading(false);
        });
    }, 100000);
    return () => clearInterval(interval);
  }, []);

  const setStatusValue = (val) => {
    setSearch({
      query: searchQuery,
      status: val,
      filter,
    });
    setStatus(val);
  };
  const setQueryValue = (val) => {
    setSearch({
      query: val,
      status,
      filter,
    });
    setSearchQuery(val);
  };
  const setFilterValue = (val) => {
    setSearch({
      query: searchQuery,
      status,
      filter: val,
    });
    setFilter(val);
  };

  const getFilteredMembers = useMemo(() => {
    return members
      .filter((item) => {
        if (search.get("status") !== null) {
          switch (search.get("status")) {
            case "markedForDeletion":
              return !item.keepAccount;
            case "":
              return item.isArchived;
          }
        }
        return true;
      })
      .filter((item) => {
        if (search.get("filter") === null || search.get("filter") === "")
          return true;

        let roleMatch = [];
        if (item.Roles) {
          roleMatch = item.Roles.filter((r) => r.name === search.get("filter"));
        }
        return roleMatch.length >= 1;
      })
      .filter((item) => {
        const searchQuery =
          search.get("query") !== null ? search.get("query").toLowerCase() : "";
        if (searchQuery.trim() === "") return true;

        const firstNameMatch = item.firstName
          ? item.firstName.toLowerCase().search(searchQuery) !== -1
          : false;
        const surnameMatch = item.lastName
          ? item.lastName.toLowerCase().search(searchQuery) !== -1
          : false;
        const callsignMatch = item.callsign
          ? item.callsign.toLowerCase().search(searchQuery) !== -1
          : false;

        return firstNameMatch || surnameMatch || callsignMatch;
      })
      .sort((a, b) => {
        if (sortByName) {
          if ((a.lastName || "") < (b.lastName || "")) return -1;
          if ((a.lastName || "") > (b.lastName || "")) return 1;
          return 0;
        } else {
          if (a.onCall < b.onCall) return 1;
          if (a.onCall > b.onCall) return -1;
          return 0;
        }
      });
  }, [members, search]);

  return (
    <div className="w-full space-y-4 p-1 xl:p-4">
      <Box title="Filters">
        <div className="grid gap-4 p-2 xl:grid-cols-3">
          <div className="space-y-4 p-2">
            <div className="grid gap-4 md:grid-cols-2">
              <Stat
                label="Team Archived"
                colour={`${
                  search.get("status") === null || search.get("status") === ""
                    ? "border-yellow-400"
                    : "border-gray-500"
                } border bg-gray-300 bg-opacity-40`}
                action={() => setStatusValue("")}
                value={members.length}
              />
              <Stat
                label="Marked For Deletion"
                colour={`${
                  search.get("status") === null || search.get("status") === ""
                    ? "border-red-400"
                    : "border-gray-500"
                } border bg-gray-300 bg-opacity-40`}
                action={() => setStatusValue("markedForDeletion")}
                value={
                  members.filter((m) => {
                    return m.keepAccount === false;
                  }).length
                }
              />
            </div>

            <Input
              placeholder="search... firstName, lastName, callsign"
              name="search"
              onChange={(e) => setQueryValue(e.target.value)}
              value={search.get("query") || ""}
            />
          </div>
          <div className="p-2 xl:col-span-2">
            <div className="flex flex-wrap gap-2 text-sm">
              {roles &&
                roles.length > 0 &&
                roles.sort((a, b) => {
                  if(a.name > b.name ) return 1;
                  if(a.name < b.name ) return -1;
                  return 0;
                }).map((r) => {
                  return (
                    <button
                      key={r._id}
                      onClick={() => setFilterValue(r.name)}
                      className={` rounded border p-2 ${
                        filter === r.name
                          ? "bg-green-300 dark:bg-green-700"
                          : "border-zinc-500"
                      } hover:border-zinc-400`}
                    >
                      {r.name}
                    </button>
                  );
                })}
              {filter !== "" && (
                <button
                  onClick={() => setFilterValue("")}
                  className={`cursor-pointer rounded bg-red-400 p-2 text-white `}
                >
                  Clear role
                </button>
              )}
            </div>
          </div>
        </div>
      </Box>
      <Box title="Archived Team Members">
        {loading && (
          <div className="space-y-6 p-4">
            <div className="flex gap-8">
              <Skeleton className="h-8 w-1/3 dark:bg-zinc-700"></Skeleton>
              <Skeleton className="h-8 w-1/3 dark:bg-zinc-700"></Skeleton>
              <Skeleton className="h-8 w-1/3 dark:bg-zinc-700"></Skeleton>
            </div>
            <div className="flex gap-8">
              <Skeleton className="h-8 w-1/2 dark:bg-zinc-700"></Skeleton>
              <Skeleton className="h-8 w-1/2 dark:bg-zinc-700"></Skeleton>
            </div>
          </div>
        )}
        {!loading && (
          <div className="">
            <MemberList
              archive
              users={getFilteredMembers}
              member={(id) => navigate(`/team/${id}`)}
            />
          </div>
        )}
      </Box>
    </div>
  );
}
export default ChainedContext(ArchivedTeamList, [
  [SettingsReceiver, "settings"],
  [OrganisationReceiver, "organisation"],
]);
