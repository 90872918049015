import React, { useState, useEffect } from "react";
import api from "../../../api";
import Box from "../../../components/common/Box";
import { useNavigate, useParams } from "react-router-dom";
// import LocationMap from "../../../components/mapping/locationMap";

export default function Supplier() {
  const navigate = useNavigate();
  const { supplier_id } = useParams();

  const [supplier, setSupplier] = useState({});
  const [showMap, setShowMap] = useState(false);

  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    if (supplier.address && supplier.address.geoJSON.geometry) {
      setShowMap(true);
    }
  }, [supplier]);

  const getData = () => {
    api.assets.suppliers.show(supplier_id).then((res) => {
      setSupplier(res);
    });
  };

  return (
    <div>
      <div className="flex gap-4 p-4">
        <Box
          title={supplier.name}
          button={{
            text: "Edit ",
            colour: "text-white",
            action: () => navigate(`/asset/suppliers/${supplier._id}/edit`),
          }}
        >
          <div className="flex justify-between p-2 text-xl text-primary dark:text-primary-dark">
            Address:{" "}
            <span className="text-black dark:text-white">
              {supplier.address
                ? supplier.address.geoJSON &&
                  supplier.address.geoJSON.properties &&
                  supplier.address.geoJSON.properties.address
                : "--"}
            </span>
          </div>
          <div className="flex justify-between p-2 text-xl text-primary dark:text-primary-dark">
            Phone:{" "}
            <span className="text-black dark:text-white">{supplier.phone}</span>
          </div>

          <div className="flex justify-between p-2 text-xl text-primary dark:text-primary-dark">
            Type:{" "}
            <span className="text-black dark:text-white">
              {supplier.type &&
                supplier.type.map((t, i) => {
                  return (
                    <span
                      key={i}
                      className="m-2 rounded-lg bg-gray-700 p-2 text-lg text-white"
                    >
                      {t}
                    </span>
                  );
                })}
            </span>
          </div>
          <div className="flex justify-between p-2 text-xl text-primary dark:text-primary-dark">
            Details:{" "}
            <span className="text-black dark:text-white">
              {supplier.details}
            </span>
          </div>
          {showMap && (
            <div>
              {/*<LocationMap*/}
              {/*  marker={supplier.address}*/}
              {/*  mapStyle={{ minHeight: 45 + "vh" }}*/}
              {/*/>*/}
            </div>
          )}
        </Box>
      </div>
    </div>
  );
}
