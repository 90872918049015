import React from "react";

const Loader = (props) => {
  return (
    <div className={`p-1 text-center ${props.className || ""}`}>
      <div className="loader-ring">
        <div>{""}</div>
        <div>{""}</div>
        <div>{""}</div>
        <div>{""}</div>
      </div>
      <span className="block">{props.children}</span>
    </div>
  );
};
export default Loader;
