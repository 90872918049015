import React, { useCallback, useEffect, useState } from "react";
import DayJS from "dayjs";
import {
  TrashIcon,
  PencilSquareIcon,
  HomeIcon,
  CloudArrowDownIcon,
  FolderPlusIcon,
  DocumentPlusIcon,
  FolderIcon,
} from "@heroicons/react/24/outline";
import PrettyBytes from "pretty-bytes";
import fileDownloader from "js-file-download";
import Axios from "axios";
import AppConfig from "../../_appConfig";
import API from "../../api";
import Button from "../../components/common/Button";
import Box from "../../components/common/Box";
import { AuthComponents } from "../../lib/_sso";
import RenameFile from "./_components/RenameFile";
import FolderDelete from "./_components/FolderDelete";
import PopupFormFolderName from "./_components/PopupFormFolderName";
import PopupFormFileUpload from "./_components/PopupFormFileUpload";
import PopupFileDelete from "./_components/PopupFileDelete";
import Loader from "../../components/common/Loader";
import Toast from "react-hot-toast";

const DocumentsList = () => {
  const [showAddFolder, setShowAddFolder] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [editFolder, setEditFolder] = useState({});
  const [deleteFolder, setDeleteFolder] = useState({});
  const [deleteFile, setDeleteFile] = useState({});
  const [renameFile, setRenameFile] = useState({});
  const [parentId, setParentId] = useState("");
  const [folders, setFolders] = useState([]);
  const [files, setFiles] = useState([]);
  const [path, setPath] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalUsage, setTotalUsage] = useState({
    percentage: 0,
    usage: "",
    limit: ""
  });

  const getData = useCallback(() => {
    API.documents.totalUsage().then(res => {
      setTotalUsage(res);
    }).catch(err => {

    });

    const apiPromise =
      parentId === ""
        ? API.documents.getRootObjects()
        : API.documents.getFolderObjects(parentId);

    setIsLoading(true);
    apiPromise
      .then((res) => {
        setFolders(res.folders);
        setFiles(res.files);
        setPath(res.path);
      })
      .catch((err) => {
        Toast.error("Unable to retrieve list of documents, please try again later.")
      }).finally(() => {
        setIsLoading(false);
      });
  }, [parentId]);

  const handleAddFolder = useCallback(
    (name, description) => {
      return API.documents.createFolder({
        name,
        description,
        parent: parentId,
      }).then(() => {
        Toast.success("Successfully created new folder.")
        getData();
      })
    },
    [parentId, getData]
  );
  const handleEditFolder = useCallback(
    (name, description) => {
      return API.documents.renameFolder(editFolder._id, {
        name,
        description
      }).then(() => {
        Toast.success("Successfully renamed folder.")
        getData();
      });
    },
    [editFolder, parentId, getData]
  );
  const handleUploadFile = useCallback(
    (file, fileDescription) => {
      return API.documents.uploadFile(parentId, file, fileDescription).then(() => {
        getData();
      });
    },
    [parentId, getData]
  );
  const handleDownloadFile = useCallback((file) => {
    const downloadProcess = Toast.loading("Starting download, please wait...");
    API.documents.downloadFile(file._id).then((res) => {
      Axios.get(AppConfig.api_server + "/.." + res.link, {
        responseType: "blob",
      }).then((fileData) => {
        Toast.success("Document download successfully initialized");
        fileDownloader(fileData.data, res.filename);
      }).catch(err => {
        Toast.error("Unable to begin document downloading due to error: " + (err.response.data.message || err.message))
      }).finally(() => {
        Toast.dismiss(downloadProcess);
      });
    }).catch(err => {
      Toast.error("Unable to begin document downloading due to error: " + (err.response.data.message || err.message))
      Toast.dismiss(downloadProcess);
    });
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <div>
      <div className={"mb-2 flex items-center gap-2 pt-2 pl-3 "}>
        <div className={"mb-1"}>
          <div className="mb-1 text-base font-medium dark:text-white">Storage Usage: {totalUsage.usage} of {totalUsage.limit}</div>
          <div className="w-full bg-gray-200 rounded-full h-1.5 dark:bg-gray-700">
            <div className={`bg-${totalUsage.percentage > 100 ? "red" : (totalUsage.percentage > 50 ? "yellow" : "blue")}-600 h-1.5 rounded-full dark:bg-${totalUsage.percentage > 100 ? "red" : (totalUsage.percentage > 50 ? "yellow" : "blue")}-500`}
                 style={{width: (totalUsage.percentage > 100 ? 100 : totalUsage.percentage) + "%"}}></div>
          </div>
        </div>
        <div className={"flex gap-2 py-1 px-3"}>
          <AuthComponents.Can scope={"documents:folder:create"}>
            <Button
              colour={"border-0 bg-blue-700 text-white"}
              onClick={() => setShowAddFolder(true)}
              icon={<FolderPlusIcon className="mr-2 h-6"/>}
              text={"Add Folder"}
            />
          </AuthComponents.Can>
          <AuthComponents.Can scope={"documents:files:upload"}>
            <Button
              colour={"border-0 bg-blue-700 text-white"}
              onClick={() => setShowUpload(true)}
              icon={<DocumentPlusIcon className="mr-2 h-6"/>}
              text={"AddFile"}
            />
          </AuthComponents.Can>
        </div>
      </div>
      <div className={"p-2 italic text-sm"}>NOTICE: Files listed here are publicly viewable by all team members from the TeamSite mobile app,
        Sensitive documents should not be stored here and should remain in your teams SharePoint/Teams/Google Drive/Dropbox etc.</div>
      <div className={"mb-2 flex items-center gap-2 p-2 border-t border-b "}>
        <button onClick={() => setParentId("")}>
          <HomeIcon className={"inline h-8"}/>
        </button>
        {path.map((a, i) => {
          return (
            <span key={i} className={"flex  gap-2"}>
              /<button onClick={() => setParentId(a._id)}>{a.name}</button>
            </span>
          );
        })}
      </div>

      {showAddFolder && (
        <PopupFormFolderName
          onClose={() => setShowAddFolder(false)}
          onSubmit={handleAddFolder}
        />
      )}
      {showUpload && (
        <PopupFormFileUpload
          onClose={() => setShowUpload(false)}
          onUpload={handleUploadFile}
        />
      )}
      {editFolder._id && (
        <PopupFormFolderName
          onClose={() => setEditFolder({})}
          isEdit
          data={editFolder}
          onSubmit={handleEditFolder}
        />
      )}
      {deleteFile._id && (
        <PopupFileDelete
          onClose={() => {
            setDeleteFile({});
            getData();
          }}
          file={deleteFile}
        />
      )}
      {renameFile._id && (
        <RenameFile
          onClose={() => {
            setRenameFile({});
            getData();
          }}
          file={renameFile}
        />
      )}
      {deleteFolder._id && (
        <FolderDelete
          onClose={() => {
            setDeleteFolder({});
            getData();
          }}
          folder={deleteFolder}
        />
      )}

      {isLoading ? <div className={"py-5 text-center text-xl"}>
        Loading list...
        <Loader className={"mt-2"}/>
      </div> : <table className="tableClass">
        <thead className="tableHeadClass">
        <tr className={"tableHeadRowClass"}>
          <th className="tableHeadCellClass">Name</th>
          <th className="tableHeadCellClass">Description</th>
          <th className="tableHeadCellClass">Date</th>
          <th className="tableHeadCellClass">Uploaded By</th>
          <th className="tableHeadCellClass">Size</th>
          <th className="tableHeadCellClass">{""}</th>
        </tr>
        </thead>
        <tbody className="tableBodyClass">
        {folders.map((folder) => {
          return (
            <tr key={folder._id} className={"tableBodyRowClass"}>
              <td
                className={"tableBodyCellClass flex cursor-pointer gap-2"}
                onClick={() => setParentId(folder._id)}
              >
                <FolderIcon className="h-6"/>
                {folder.name}
              </td>
              <td className={"tableBodyCellClass"}>
                {folder.description}
              </td>
              <td className={"tableBodyCellClass"}>
                {DayJS(folder.createdAt).format("DD-MM-YYYY HH:mm")}
              </td>
              <td className="tableBodyCellClass">{""}</td>
              <td className="tableBodyCellClass">{""}</td>
              <td className={"tableBodyCellClass w-32 space-x-2 text-right"}>
                <AuthComponents.Can scope={"documents:folder:modify"}>
                  <button onClick={() => setEditFolder(folder)}>
                    <PencilSquareIcon className="h-6 w-6 text-blue-500"/>
                  </button>
                  <button onClick={() => setDeleteFolder(folder)}>
                    <TrashIcon className="h-6 w-6 text-red-500"/>
                  </button>
                </AuthComponents.Can>
              </td>
            </tr>
          );
        })}
        {files.map((document) => {
          return (
            <tr key={document._id} className={"tableBodyRowClass"}>
              <td className={"tableBodyCellClass"}>{document.filename}</td>
              <td className={"tableBodyCellClass"}>{document.description}</td>
              <td className={"tableBodyCellClass"}>
                {DayJS(document.file?.uploadDate).format("DD-MM-YYYY HH:mm")}
              </td>
              <td className={"tableBodyCellClass"}>{document.uploadedBy}</td>
              <td className={"tableBodyCellClass"}>
                {PrettyBytes(document.file?.length || 0, {binary: true, maximumFractionDigits: 0})}
              </td>
              <td className={"tableBodyCellClass w-32 space-x-2 text-right"}>
                <button onClick={() => handleDownloadFile(document)}>
                  <CloudArrowDownIcon className="h-6 w-6 text-white"/>
                </button>
                <AuthComponents.Can scope={"documents:files:modify"}>
                  <button onClick={() => setRenameFile(document)}>
                    <PencilSquareIcon className="h-6 w-6 text-blue-500"/>
                  </button>
                  <button onClick={() => setDeleteFile(document)}>
                    <TrashIcon className="h-6 w-6 text-red-500"/>
                  </button>
                </AuthComponents.Can>
              </td>
            </tr>
          );
        })}
        </tbody>
      </table>}

    </div>
  );
};

const DocumentsIndex = () => {
  return (
    <div className="p-4">
      <Box title="Documents">
      <DocumentsList/>
      </Box>
    </div>
  );
};
export default DocumentsIndex;
