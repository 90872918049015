import React, { useState, useEffect } from "react";
import Box from "../../../components/common/Box";
import Input from "../../../components/common/Input";
import Button from "../../../components/common/Button";
import { Dialog, Transition } from "@headlessui/react";
import {
  CheckIcon,
  PlusIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import { Skeleton } from "../../../components/ui/skeleton";

import { PencilIcon } from "@heroicons/react/24/outline";
import Select from "../../../components/common/Select";
import API from "../../../api";

const types = [
  { key: 1, text: "Pass/Fail", value: "pass/fail" },
  { key: 2, text: "Score", value: "score" },
];

const CompSkillForm = ({
  title,
  field,
  module,
  sections,
  section,
  data,
  refresh,
  loading = false,
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [order, setOrder] = useState("");
  const [theComp, setComp] = useState("");
  const [type, setType] = useState("");
  const [del, setDelete] = useState("");
  const [passScore, setPassScore] = useState("");
  const [scoreScore, setScoreScore] = useState("");
  const [scoreTitle, setScoreTitle] = useState("");
  const [scores, setScores] = useState([]);
  const [scoreDescription, setScoreDescription] = useState("");
  const [requiresCPD, setRequiresCPD] = useState(false);
  const [requiresCompetency, setRequiresCompetency] = useState(false);
  const [editID, setEditID] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [dataList, setDataList] = useState(data);

  useEffect(() => {
    setDataList(data);
  }, [data]);
  useEffect(() => {
    if (module > "") {
      setComp(module);
    }
  }, [module]);
  useEffect(() => {
    if (section > "") {
      setComp(section._id);
    }
  }, [section]);
  useEffect(() => {
    if (section?._id > "") {
      setDataList(
        data.filter((s) => {
          return s.section?._id === section._id;
        })
      );
    }
  }, [section, data]);
  const handleEdit = (item) => {
    // console.log(item);
    setName(item.name);
    setDescription(item.description);
    setOrder(item.sortOrder);
    setType(item.type);
    setScores(item.scoreConfig.scores);
    setPassScore(item.scoreConfig.pass_score);
    setComp(item.section._id);
    setEditID(item._id);
    setIsEdit(true);
  };
  const addSkil = () => {
    const data = {
      name: name.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1);
      }),
      description: description,
      order: order,
      section: theComp,
      type: type,
      scoreConfig: {
        pass_score: passScore,
        scores: scores,
      },
    };
    API.settings.addCompSkill(data).then(() => {
      refresh();
    });
    setName("");
    setOrder("");
    setType("");
    setPassScore("");
    setDescription("");
    setScores([]);
  };
  const updateCompModule = () => {
    const data = {
      name: name.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1);
      }),
      description: description,
      order: order,
      section: theComp,
      type: type,
      scoreConfig: {
        pass_score: passScore,
        scores: scores,
      },
    };
    API.settings.updateCompSkills(editID, data).then(() => {
      refresh();
    });
    setName("");
    setOrder("");
    setType("");
    setPassScore("");
    setDescription("");
    setScores([]);
    setIsEdit(false);
    setEditID(null);
  };
  const handleDelete = () => {
    API.settings.deleteCompSkills(del._id).then(() => {
      setIsOpen(false);
      // setDelete(null);
      refresh();
    });
  };
  const CloseDialog = () => {
    return (
      <Transition
        show={isOpen}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Dialog
          open={isOpen}
          onClose={() => setIsOpen(false)}
          className="fixed inset-0 z-10 overflow-y-auto "
        >
          <div className="min-h-screen text-center">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-80" />
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className="my-8 inline-block w-full max-w-2xl transform overflow-hidden rounded-2xl border-4 border-gray-700 bg-gray-50 p-6 text-left align-middle shadow-xl transition-all dark:bg-zinc-700">
              <div className="pt-4 text-center">
                <Dialog.Title className={"text-2xl"}>
                  Delete Competency{" "}
                  <span className="font-bold italic">{del.name}</span>
                </Dialog.Title>
                <div className="py-4 text-ss">
                  This action is not reversible so please be sure!
                </div>
              </div>

              <div className="mt-4 flex justify-between border-t border-zinc-500 pt-4">
                <button
                  className="rounded-lg bg-primary px-4 py-2 text-white"
                  onClick={() => setIsOpen(false)}
                >
                  Cancel
                </button>
                <button
                  className="rounded-lg bg-red-500 px-4 py-2 text-white disabled:bg-red-500"
                  onClick={handleDelete}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  };
  const addComp = (e) => {
    setComp(e);
  };
  const addScore = () => {
    const data = {
      name: scoreTitle,
      score: scoreScore,
    };
    setScores((a) => {
      return a.concat(data);
    });
    setScoreTitle("");
    setScoreScore("");
  };
  const deleteScore = (d) => {
    let list = scores.filter((s) => {
      return s.name !== d.name;
    });
    setScores(list);
  };
  const skillCheck = () => {
    return false;
  };

  return (
    <Box title={title}>
      <div className=" w-full ">
        <div className="grid w-full grid-cols-1 items-center p-2 md:grid-cols-5 ">
          <div className="col-span-1 md:col-span-4">
            <div className=" flex gap-2">
              <div className="w-full">
                <label className="labelClass">Section</label>
                <div className="flex w-full  ">
                  <Select
                    options={
                      (sections !== undefined &&
                        sections.map((item) => {
                          return {
                            key: item._id,
                            text: item.name,
                            value: item._id,
                          };
                        })) ||
                      []
                    }
                    value={theComp}
                    onChange={(e) => addComp(e.target.value)}
                  />
                </div>
              </div>
              <div className="w-44">
                <label className="labelClass">Sort order</label>
                <Input
                  value={order}
                  type={"number"}
                  field={field}
                  onChange={(e) => setOrder(e.target.value)}
                  placeholder="1"
                />
              </div>
              <div className="w-full">
                <label className="labelClass">Pass Type</label>
                <div className="flex w-full  ">
                  <Select
                    options={types}
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className=" w-full">
              <label className="labelClass">Skill</label>
              <Input
                value={name}
                type={"text"}
                field={field}
                onChange={(e) => setName(e.target.value)}
                placeholder="section..."
              />
            </div>
          </div>
          <div className="flex h-full w-full justify-center pt-4  md:col-span-1">
            <Button
              onClick={isEdit ? updateCompModule : addSkil}
              text={isEdit ? "Update" : "Add"}
              colour={"bg-green-700 text-white"}
            />
          </div>

          <div className="col-span-5 mt-4 flex flex-col border-t border-zinc-300 dark:border-zinc-700 ">
            {type === "score" && (
              <div className="mt-2 flex  w-full items-end gap-2 ">
                <div className="w-32">
                  <label className="labelClass">Pass Mark</label>
                  <Input
                    value={passScore}
                    type={"text"}
                    onChange={(e) => setPassScore(e.target.value)}
                    placeholder="score..."
                  />
                </div>
                <div className="">
                  <label className=" labelClass">Score</label>
                  <Input
                    type={"number"}
                    value={scoreScore}
                    onChange={(e) => setScoreScore(e.target.value)}
                    placeholder="score..."
                  />
                </div>
                <div className="w-full">
                  <label className="labelClass">Title</label>
                  <Input
                    type={"text"}
                    value={scoreTitle}
                    onChange={(e) => setScoreTitle(e.target.value)}
                    placeholder="title..."
                  />
                </div>
                <div className="">
                  <Button
                    disabled={scoreTitle === "" ? true : false}
                    onClick={addScore}
                    icon={<PlusIcon className="h-6" />}
                    colour="bg-green-700 text-white"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        {CloseDialog()}
        <div className="w-full pt-4">
          {type === "score" && (
            <table className="tableClass">
              <thead className="tableHeadClass">
                <tr className="tableHeadRowClass">
                  <th className="tableHeadCellClass w-20">Score</th>
                  <th className="tableHeadCellClass">Title</th>
                  <th className="tableHeadCellClass"></th>
                </tr>
              </thead>
              <tbody className="tableBodyClass">
                {scores?.length > 0 &&
                  scores.map((d) => {
                    return (
                      <tr className="tableBodyClass" key={d._id}>
                        <td className="tableBodyCellClass text-center text-lg ">
                          {d.score}{" "}
                        </td>
                        <td className=" tableBodyCellClass text-lg ">
                          {d.name}
                        </td>

                        <td className="tableBodyCellClass w-32 space-x-1 text-center">
                          <button
                            className="rounded-md border border-red-400 bg-red-700 bg-opacity-80 px-2 py-1 text-sm text-white hover:border-red-300 hover:bg-red-800 disabled:opacity-20"
                            onClick={() => {
                              deleteScore(d);
                            }}
                          >
                            <TrashIcon className="h-6 w-6 text-red-200" />
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          )}
        </div>
        <div className="w-full pt-4">
          {loading && (
            <div className="col-span-2 space-y-2">
              <Skeleton className="h-10 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
              <Skeleton className="h-10 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
              <Skeleton className="h-10 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
              <Skeleton className="h-10 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
            </div>
          )}
          {!loading && (
            <table className=" tableClass">
              <thead className="tableHeadClass">
              <tr className="tableHeadRowClass">
                <th className="tableHeadCellClass">Competency</th>
                <th className="tableHeadCellClass">Section</th>
                <th className="tableHeadCellClass">Skill</th>
                <th className="tableHeadCellClass">Type</th>
                <th className="tableHeadCellClass"></th>
              </tr>
              </thead>
              <tbody className="tableBodyClass">
                {dataList &&
                  dataList.toSorted((a, b) => {
                    if(a.competency?.name > b.competency?.name) return 1;
                    if(a.competency?.name < b.competency?.name) return -1;
                    return 0;
                  }).map((d) => {
                    return (
                      <tr className="tableBodyRowClass" key={d._id}>
                        <td className="tableBodyCellClass">{d.competency?.name}</td>
                        <td className="tableBodyCellClass">{d.section?.name}</td>
                        <td className="tableBodyCellClass">{d.name}</td>
                        <td className="tableBodyCellClass">{d.type}</td>
                        <td className="tableBodyCellClass w-32 space-x-1 text-center">
                          <button
                            onClick={() => handleEdit(d)}
                            className="rounded-md border bg-primary bg-opacity-80  px-2 py-1 text-sm  text-white hover:bg-gray-800 disabled:opacity-20"
                          >
                            <PencilIcon className="h-6 w-6 text-yellow-200 "/>
                          </button>
                          <button
                            className="rounded-md border border-red-400 bg-red-700 bg-opacity-80 px-2 py-1 text-sm text-white hover:border-red-300 hover:bg-red-800 disabled:opacity-20"
                            onClick={() => {
                              setDelete(d);
                              setIsOpen(true);
                            }}
                          >
                            <TrashIcon className="h-6 w-6 text-red-200"/>
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </Box>
  );
};
export default CompSkillForm;