import React from "react";

const TextArea = ({
  label,
  field,

  onChange,
  placeholder,
  value,
  rows = 3,
  style = "w-full",
  error,
  disabled = false,

  required = false,
}) => {
  // console.log("error", error);
  return (
    <div className={style}>
      <label
        htmlFor={field}
        className={`  ${error ? "text-red-400" : "labelClass"}`}
      >
        {label}
        {required && (
          <span className="pl-1 text-red-400">
            <sup>*</sup>
          </span>
        )}
      </label>
      <textarea
        disabled={disabled}
        name={field}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        rows={rows}
        autoComplete="off"
        className={`inputClass ${
          error ? "border-red-400" : "border-zinc-500 "
        } ${
          disabled
            ? "text-md  border-0 bg-gray-100 "
            : "border-border-zinc-500   dark:bg-zinc-700 "
        }  
        
        
        rounded-md`}
      />
    </div>
  );
};
export default TextArea;
