import Bridge from "./bridge";

class Authentication {
    constructor(){
        this.com          = null;
        this.userData     = null;
        this.access_token = null;
        this.userScopes   = null;
    }
    setComponent(com){
        this.com = com;
    }
    getComponent(){
        return this.com;
    }
    logout(){
        this.getComponent().logout();
    }
    setUserData(data){
        this.userData = data;
    }
    getUserData(){
        return this.userData;
    }
    setAccessToken(token){
        this.access_token = token;
    }
    getAccessToken(){
        return this.access_token;
    }
    setUserScopes(data){
        this.userScopes = data.map(i => i.toLowerCase());
    }
    getUserScopes(){
        return this.userScopes;
    }

    /**
     * Checks if the current logged in user has the required scope permission
     * @param scope
     * @param context
     * @returns {boolean}
     */
    can(scope, context = null){
        scope = scope.toLowerCase();
        if(this.userScopes === null || !Array.isArray(this.userScopes)){
            return false;
        }
        let hasScope = this.userScopes.indexOf(scope) !== -1;
        let contextHandlers = Bridge.getContextHandlers();
        if(hasScope && contextHandlers[scope]){
            let func = contextHandlers[scope];
            if(typeof func === "function"){
                return func(this.getUserData(), context);
            }
        }
        return hasScope;
    }
    /**
     * Checks if the current logged in user does not have the required scope permission
     * @param scope
     * @param context
     * @returns {boolean}
     */
    canNot(scope, context = null){
        return !this.can(scope, context);
    }

    /**
     * @deprecated Please start using the .can || .canNot
     * @param scope
     * @returns {boolean}
     */
    hasScope(scope){
        return this.can(scope);
    }
}

export default new Authentication();