import React, { Fragment } from "react";
import { Disclosure } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import {NavLink, useParams} from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function NavBar() {
  const { supplier_id } = useParams();

  const navigation = [
    { name: "Dashboard", href: `/asset/suppliers/${supplier_id}`, exact: true },
    { name: "Contacts", href: `/asset/suppliers/${supplier_id}/contacts`, exact: true },
    { name: "Assets", href: `/asset/suppliers/${supplier_id}/assets`, exact: true }
  ];

  return (
    <Disclosure as="nav" className="border-b-2 border-gray-300 bg-gray-50 dark:border-primary dark:bg-zinc-800">
      {({ open }) => (
        <>
          <div className="mx-auto  px-4 sm:px-2 lg:px-4">
            <div className="flex h-16 justify-between">
              <div className="flex">
                <div className="-ml-2 mr-2 flex items-center md:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-900 hover:bg-gray-700 hover:text-white ">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="hidden text-primary dark:text-white md:ml-2 md:flex md:items-center md:space-x-2">
                  Supplier
                </div>
                <div className="hidden md:ml-4 md:flex md:items-center md:space-x-2">
                  {navigation.map((item) => {
                    // console.log(item.badge);
                    return (
                      <NavLink
                        key={item.name}
                        to={item.href}
                        end={item.exact}
                        className={({ isActive }) =>
                          isActive
                            ? "relative rounded   border bg-gray-200 px-3 py-2 text-primary dark:border-zinc-600 dark:bg-zinc-700 dark:text-white"
                            : "relative  rounded-md px-3 py-2 text-gray-500 hover:bg-primary hover:text-zinc-300 dark:text-zinc-400"
                        }
                        // className={classNames(item.current ? "bg-gray-900 text-white" : "text-gray-900 hover:bg-primary hover:text-white", "px-3 py-2 rounded-md ")}
                        aria-current={item.current ? "page" : undefined}
                      >
                        {item.name}{" "}
                        {item.badge !== undefined && (
                          <span
                            className={` ml-2 rounded-full  border border-gray-400 bg-gray-100 px-1.5 pb-0.5 pt-1 align-middle text-sm font-bold text-primary shadow `}
                          >
                            {item.badge}
                          </span>
                        )}
                      </NavLink>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="md:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2 sm:px-2">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    item.current
                      ? "bg-gray-900 text-white"
                      : "text-gray-300 hover:bg-gray-700 hover:text-white",
                    "block rounded-md px-3 py-2 text-base font-medium"
                  )}
                  aria-current={item.current ? "page" : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
export default NavBar;