import React, { useState, useEffect } from "react";
import { ChevronDoubleRightIcon } from "@heroicons/react/20/solid";
import api from "../../../api";
import Box from "../../../components/common/Box";
import Button from "../../../components/common/Button";

export default function FocusReport() {
  const [comps, setComps] = useState([]);
  const [comp, setComp] = useState({});
  const [module, setModule] = useState({});
  const [section, setSection] = useState({});
  const [report, setReport] = useState([]);

  useEffect(() => {
    getComps();
  }, []);
  useEffect(() => {
    if (section._id) {
      api.training.competencies
        .focusReport(comp._id, section._id)
        .then((res) => {
          setReport(res);
        });
    }

    return () => {
      setReport([]);
    };
  }, [section]);

  const getComps = () => {
    api.settings.getComps().then((res) => {
      setComps(res);
    });
  };
  const getComp = (id) => {
    api.settings.getComp(id).then((res) => {
      setComp(res);
    });
  };

  return (
    <div className="w-full p-2">
      <Box title="Competencies">
        <div className="flex gap-2 flex-wrap space-x-1 p-2">
          {comps.map((c) => {
            return (
              <Button
                key={c._id}
                text={c.name}
                onClick={() => {
                  getComp(c._id);
                  setModule({});
                  setSection({});
                }}
              />
            );
          })}
        </div>
        <div className="flex p-2 italic ">
          <span
            className="animate-fade cursor-pointer text-primary dark:text-blue-300"
            onClick={() => setModule({})}
          >
            {comp.name}
          </span>
          {module._id && (
            <ChevronDoubleRightIcon className="h-6 px-2 text-black dark:text-white" />
          )}
          <span
            className="animate-fade cursor-pointer text-primary dark:text-blue-300"
            onClick={() => setSection({})}
          >
            {module.name}
          </span>
          {section._id && (
            <ChevronDoubleRightIcon className="h-6 px-2 text-black dark:text-white" />
          )}
          <span className="animate-fade text-primary dark:text-blue-300">
            {section.name}
          </span>
        </div>
      </Box>
      {comp._id && module._id === undefined && (
        <Box title="Modules">
          <div className="flex gap-2 flex-wrap space-x-1 p-2">
            {comp.children.map((c) => {
              return (
                <Button
                  key={c._id}
                  text={c.name}
                  onClick={() => setModule(c)}
                />
              );
            })}
          </div>
        </Box>
      )}
      {module._id && section._id === undefined && (
        <Box title="Sections">
          <div className="flex gap-2 flex-wrap space-x-1 p-2">
            {module.children.map((c) => {
              return (
                <Button
                  key={c._id}
                  text={c.name}
                  onClick={() => setSection(c)}
                />
              );
            })}
          </div>
        </Box>
      )}
      {/* {section._id && (
        // <Box title="Parts">
        <div className=" p-2">
          {section.children.map((c) => {
            return (
              <div key={c._id} className="p-2">
                {c.name}
              </div>
            );
          })}
        </div>

        // </Box>
      )} */}

      <hr />

      {section._id && (
        <table className="tableClass">
          <thead className="tableHeadClass">
            <tr className="tableHeadRowClass">
              <th className="tableHeadCellClass">Member</th>
              {section.children.map((c) => {
                return (
                  <th key={c._id} className="tableHeadCellClass text-center">
                    {c.name}
                  </th>
                );
              })}
              <th className="tableHeadCellClass text-center">%</th>
            </tr>
          </thead>
          <tbody className="tableBodyClass">
            {report.map((item) => {
              return (
                <tr key={item._id} className={"tbrc"}>
                  <td className={"tbcc"}>
                    {item.firstName} {item.lastName}
                  </td>
                  {section.children.map((c) => {
                    const foundResult = item._results.filter(
                      (a) => a.skill === c._id
                    );
                    return (
                      <th
                        key={c._id}
                        className={`text-center ${
                          foundResult[0] !== undefined
                            ? foundResult[0].result === "pass"
                              ? "oncall"
                              : "nonOp"
                            : ""
                        }`}
                      >
                        {foundResult[0] !== undefined
                          ? foundResult[0].score !== undefined
                            ? foundResult[0].score
                            : foundResult[0].result
                          : "--"}
                      </th>
                    );
                  })}
                  <td
                    className={`text-center ${
                      item._totalPercent >= 75
                        ? "oncall"
                        : item._totalPercent >= 26
                        ? "offcall"
                        : "nonOp"
                    }`}
                  >
                    {item._totalPercent}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
}
