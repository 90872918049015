import Modal from "../../../components/common/Modal";
import React, {lazy, Suspense, useMemo, useState} from "react";
import Button from "../../../components/common/Button";
import api from "../../../api";
import {toast} from "react-hot-toast";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";

const MultiAssetCheck = ({ assets, onClose }) => {
  const [scheduled, setScheduled] = useState(null);
  const [theCheck, setTheCheck] = useState({
    date_checked: "",
    date_scheduled: "",
    status: "",
    note: "",
  });

  const assetNames = useMemo(() => {
    return assets.map(asset => asset.name).sort((a, b) => {
      if(a < b) return -1;
      if(a > b) return 1;
      return 0;
    })
  }, [assets]);

  const handleAddCheck = () => {
    toast.promise(Promise.all(assets.map(async asset => api.assets.checks.add(asset._id, theCheck))), {
      loading: "Updating assets, this may take a while...",
      success: "Successfully updated assets.",
      error: "There were one or more asset checks that triggered an error and could not be updated."
    }).then(() => {
      onClose();
    });
  };

  const onTheCheckChange = (e) => {
    setTheCheck({ ...theCheck, [e.target.name]: e.target.value });
  };

  return (
    <Modal
      show={true}
      onClose={() => onClose()}
      title={`Multi asset checks`}
    >
      <div className="border-b border-gray-700 p-4">
        Assets: {assetNames.join(', ')}
      </div>

      <div>
        {" "}
        <div className="mt-4 w-full text-center text-2xl">
          Is this a future scheduled check?
        </div>
        <div className="mt-4 flex justify-around border-t border-gray-700 pt-4">
          <button
            className={`rounded-lg bg-blue-600 px-4 py-4 text-xl ${scheduled === false && "bg-opacity-20"} ${scheduled === true && "ring-offset-bak ring ring-yellow-700 ring-offset-1"}`}
            onClick={() => setScheduled(true)}
          >
            Yes
          </button>
          <button
            className={`rounded-lg bg-blue-600 px-4 py-4 text-xl disabled:bg-red-500 ${scheduled === true && "bg-opacity-20"} ${scheduled === false && "ring-offset-bak ring ring-yellow-700 ring-offset-1"}`}
            onClick={() => setScheduled(false)}
          >
            No
          </button>
        </div>
        <div className="mt-4 border-t border-gray-700 pt-4">
          {scheduled === false && (
            <div className="w-full space-y-2 py-2 text-left">
              <div className="">
                <label className={`labelClass`}>Date Checked</label>
                <DatePicker
                  name="date_checked"
                  onChange={(date) =>
                    setTheCheck({
                      ...theCheck,
                      date_checked: date,
                    })
                  }
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={60}
                  timeInputLabel="Time:"
                  dateFormat="dd-MM-yy HH:mm"
                  showPopperArrow={false}
                  maxDate={new Date()}
                  className="inputClass z-50"
                  selected={theCheck.date_checked}
                />
              </div>
              <div>
                <label className={`labelClass`}>Note</label>
                <textarea
                  rows={3}
                  name="note"
                  value={theCheck.note}
                  onChange={onTheCheckChange}
                  className={`inputClass w-full`}
                />
              </div>
              <div className="mt-4 flex justify-around border-t border-gray-700 pt-4">
                <button
                  className={`rounded-lg bg-gray-300 px-4 py-4 text-xl disabled:bg-red-500 dark:bg-gray-900 ${
                    theCheck.status === "Ok" && "border bg-opacity-20"
                  } ${
                    theCheck.status === "Faulty" &&
                    "ring-offset-bak border-0 bg-red-600 ring ring-yellow-700 ring-offset-1"
                  }`}
                  onClick={() =>
                    setTheCheck({
                      ...theCheck,
                      status: "Faulty",
                    })
                  }
                >
                  Faulty
                </button>
                <button
                  className={`rounded-lg  bg-gray-300 px-4 py-4 text-xl disabled:bg-red-500 dark:bg-gray-900 ${
                    theCheck.status === "Faulty" && "border bg-opacity-20"
                  } ${
                    theCheck.status === "OK" &&
                    "ring-offset-bak border-0 bg-green-600 ring ring-yellow-700 ring-offset-1"
                  }`}
                  onClick={() =>
                    setTheCheck({
                      ...theCheck,
                      status: "OK",
                    })
                  }
                >
                  OK
                </button>
              </div>
            </div>
          )}
          {scheduled && (
            <div className="flex w-full items-center justify-between space-y-2 py-2 text-left">
              <div className=" ">
                <label className={`labelClass`}>Date Scheduled</label>
                <DatePicker
                  name="date_scheduled"
                  onChange={(date) =>
                    setTheCheck({
                      ...theCheck,
                      date_scheduled: date,
                    })
                  }
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={60}
                  timeInputLabel="Time:"
                  dateFormat="dd-MM-yy HH:mm"
                  showPopperArrow={false}
                  minDate={new Date()}
                  className="inputClass z-50 justify-center"
                  selected={theCheck.date_scheduled}
                />
              </div>
              {theCheck.date_scheduled && (
                <div className="text-lg">
                  Check due in{" "}
                  {dayjs(theCheck.date_scheduled).diff(dayjs(), "days")}{" "}
                  days.
                </div>
              )}
            </div>
          )}
        </div>
        <div className="mt-4 flex justify-between border-t border-gray-700 pt-4">
          <Button
            text="Cancel"
            onClick={() => onClose()}
            colour="bg-red-700 text-white"
          />
          <Button
            text="Add"
            onClick={handleAddCheck}
            colour="bg-green-700 text-white"
          />
        </div>
      </div>

    </Modal>
  );
}
export default MultiAssetCheck;