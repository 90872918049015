import React, { useState, useEffect } from "react";
import ChainedContext from "../../../contexts/chainedContext";
import MemberReceiver from "../../../contexts/member_context/Member_Receiver";
import { Skeleton } from "../../../components/ui/skeleton";
import api from "../../../api";
import { toast } from "react-hot-toast";
import Box from "../../../components/common/Box";
import Input from "../../../components/common/Input";
import DataRow from "../../../components/common/DataRow";
import VaccinationCard from "./_components/VaccinationCard";
import SettingsArrayList from "../../settings/_components/SettingsArrayList";

function Medical({ member }) {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [update, setUpdate] = useState(false);
  const [medical, setMedical] = useState({
    allergies: [],
    medication: [],
    vaccinations: [],
    bloodGroup: "",
  });
  const [noMedical, setNoMedical] = useState(true);

  useEffect(() => {
    if (member._id) {
      getMedical();
    }
  }, [member]);

  const getMedical = () => {
    api.member
      .medical(member._id)
      .then((res) => {
        setNoMedical(false);
        setMedical(res);
      })
      .catch((err) => {
        console.log(err.response.status);
        if (err.response.status === 403) {
          setNoMedical(true);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleUpdateMedical = (data) => {
    api.member.updateMedical(member._id, data).then(() => {
      toast.success("Medical data updated");
      member._refresh();
      setUpdate(false);
      // setUpdateMedical(false);
    });
  };
  //   console.log(medical);
  return (
    <div className="w-full p-2">
      {loading && (
        <div className="grid grid-cols-2 gap-4">
          <div className="space-y-8">
            <Skeleton className="h-80 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
            <Skeleton className="h-80 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
          </div>
          <div className=" space-y-8">
            <Skeleton className="h-52 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
            <Skeleton className="h-20 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
            <Skeleton className="h-20 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
          </div>
        </div>
      )}
      {!loading && !noMedical && (
        <div className="grid gap-4 sm:grid-cols-2">
          <div className="space-y-4">
            <SettingsArrayList
              title="Allergies"
              field="allergies"
              data={medical.allergies}
              onSubmit={handleUpdateMedical}
              onDelete={handleUpdateMedical}
            />
            <SettingsArrayList
              title="Medication"
              field="medication"
              data={medical.medication}
              onSubmit={handleUpdateMedical}
              onDelete={handleUpdateMedical}
            />
            <Box
              title="Info"
              button={{
                text: "Edit",
                colour: "text-sm text-white hover:text-gray-200",
                action: () => setUpdate(!update),
              }}
            >
              <DataRow
                label={"Blood Group"}
                value={
                  !update ? (
                    medical.bloodGroup
                  ) : (
                    <div className="flex items-center gap-2">
                      <Input
                        value={medical.bloodGroup}
                        onChange={(e) =>
                          setMedical({
                            ...medical,
                            bloodGroup: e.target.value,
                          })
                        }
                      />
                      <button
                        className="mt-1.5 rounded border border-green-500 bg-green-300  bg-opacity-40 px-2  py-1  text-green-500 hover:border-green-300"
                        onClick={() => handleUpdateMedical(medical)}
                      >
                        Update
                      </button>
                    </div>
                  )
                }
              />
            </Box>
          </div>

          <VaccinationCard
            title="Vaccinations"
            data={medical.vaccinations}
            onDelete={handleUpdateMedical}
            onSubmit={handleUpdateMedical}
          />
        </div>
      )}
      {!loading && noMedical && (
        <div className="text-red-400 text-center">No access to medical data since your login does not have the required Security Group assigned:- "TeamSite - Medical"</div>
      )}
    </div>
  );
}
export default ChainedContext(Medical, [
  [MemberReceiver, "member"]
]);