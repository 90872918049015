import React from "react";

const Select = ({
  label,
  field,
  value,
  onChange,
  options = [],
  error,
  disabled,
  hideBlank = false,
  dontSort = false
}) => {
  return (
    <div className="flex w-full flex-col">
      <label
        htmlFor={field}
        className={`   ${error ? "text-red-400" : "labelClass"}`}
      >
        {label}
      </label>
      <select
        disabled={disabled}
        name={field}
        value={value}
        onChange={onChange}
        autoComplete="off"
        className={`block  w-full rounded border  bg-white px-3 py-2 shadow-sm focus:outline-none  dark:border-zinc-500 dark:bg-zinc-700   sm:text-sm ${
          error ? "border-red-400" : "border-zinc-300"
        }`}
      >
        {!hideBlank ? <option value="">{"--"}</option> : ""}
        {options.length &&
          options.sort((a, b) => {
            if(dontSort) return 0;

            if(a.text.toLowerCase() > b.text.toLowerCase()) return 1;
            if(a.text.toLowerCase() < b.text.toLowerCase()) return -1;
            return 0;
          }).map((o, i) => {
            return (
              <option key={i} value={o.value}>
                {o.text}
              </option>
            );
          })}
      </select>
      {error && <span className={"text-red-400"}>{error}</span>}
    </div>
  );
};
export default Select;
