import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { CheckIcon, XMarkIcon } from "@heroicons/react/20/solid";
import {Dialog, Transition} from "@headlessui/react";
import {toast} from "react-hot-toast";
import ChecklistForm from "./ChacklistForm";
import API from "../../../api";
import {PencilIcon} from "@heroicons/react/24/outline";

export default function VehicleCheckList({ vehicle, reloadLogs, logs = [] }) {
  const [editEntry, setEditEntry] = useState({});

  const handleDeleteLog = () => {
    return API.vehicles.deleteVehicleLogEntry(vehicle._id, editEntry._id).then(() => {
      reloadLogs();
      setEditEntry({})
      toast.success("Successfully removed vehicle log entry");
    })
  };
  const handleUpdateLog = (data) => {
    return API.vehicles.updateVehicleLogEntry(vehicle._id, editEntry._id, data).then(() => {
      reloadLogs();
      setEditEntry({})
      toast.success("Successfully updated vehicle log entry");
    })
  };
  const renderEditPopup = () => {

    return (
      <Transition
        show={editEntry._id !== undefined}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Dialog
          open={editEntry._id !== undefined}
          onClose={() => setEditEntry({})}
          className="fixed inset-0 z-10 overflow-y-auto "
        >
          <div className=" min-h-screen text-center">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-80" />
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="my-8 inline-block w-full max-w-2xl transform  rounded-2xl border-4 border-gray-700 bg-gray-50 p-6 text-left  shadow-xl transition-all dark:bg-zinc-700">
              <div className="absolute right-0 top-0 pt-2">
                <button
                  type="button"
                  className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  onClick={() => setEditEntry({})}
                >
                  <span className="sr-only">Close </span>
                  <XMarkIcon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </button>
              </div>
              <div className="space-y-4 pt-4">
                <Dialog.Title className={"text-2xl"}>
                  Vehicle log and check for{" "}
                  <span className="font-bold italic">
                    {vehicle.make} {vehicle.model}
                  </span>
                </Dialog.Title>

                <ChecklistForm vehicle_id={vehicle._id} editLog={editEntry} onDelete={handleDeleteLog} onCancel={() => setEditEntry({})} onSubmit={handleUpdateLog} />
              </div>

            </div>
          </div>
        </Dialog>
      </Transition>
    );
  }
  return (
    <>
      {renderEditPopup()}
      <table className="lg:text-md min-w-full table-fixed divide-y divide-zinc-500">
        <thead>
          <tr className="bg-gray-300 text-xs uppercase tracking-wider text-gray-900 dark:bg-zinc-700 dark:text-white">
            <th className="px-4 py-3 text-left">Date</th>
            <th className="px-4 py-3 text-center">Start Mileage</th>
            <th className="px-4 py-3 text-center">Finish Mileage</th>
            <th className="px-4 py-3 text-left">Checks</th>
            <th className="px-4 py-3 text-left">Notes</th>
            {/*<th className="px-4 py-3 text-left">Attachment</th>*/}
            <th className="px-4 py-3 text-left">Logged By</th>
            <th className="px-4 py-3 text-left"></th>
          </tr>
        </thead>
        <tbody className="divide-y divide-zinc-500 text-left">
        {logs !== undefined ? (
          logs.map((c) => {
            return (
              <tr key={c._id} className={`divide-x divide-zinc-500`}>
                <td className="w-32 p-2 text-sm">
                  {dayjs(c.date).format("DD-MM-YYYY HH:mm")}
                </td>
                <td className="w-32 p-2 text-center">{c.start}</td>
                <td className="w-32 p-2 text-center">{c.finish}</td>
                <td className="p-2">
                  <div className="flex flex-wrap gap-2 text-sm">
                    {c.checks.map((r, i) => {
                      return (
                        <div
                          key={i}
                          className={`border border-zinc-300 px-1 py-1 text-center  dark:border-zinc-700`}
                        >
                          <div className="flex flex-wrap items-center">
                            {r.name}{" "}
                            {r.value ? (
                              <CheckIcon className="mx-auto h-8 text-green-300" />
                            ) : (
                              <XMarkIcon className="mx-auto h-8 text-red-700" />
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </td>
                <td className="w-1/4 p-2">{c.notes}</td>
                {/*<td className="p-2">**attachments**</td>*/}
                <td className="w-56 p-2 text-left">{c.loggedBy}</td>
                <td className="w-16 p-2 text-left">
                  <button
                    onClick={() => setEditEntry(c)}
                    className="rounded-md border bg-opacity-80 px-2 py-1 text-sm text-white border-gray-400 bg-gray-700 hover:border-gray-300 hover:bg-gray-800 mr-2"
                  >
                    <PencilIcon className="h-6 w-6 text-gray-200" />
                  </button>
                </td>
              </tr>
            );
          })
        ) : (
          <tr>
            <td colSpan={6}>No logs</td>
          </tr>
        )}
        </tbody>
      </table>
    </>
  );
}