import React from "react";
import { toast } from "react-hot-toast";
import SettingsReceiver from "../../contexts/settings_context/Settings_Receiver";
import OrganisationReceiver from "../../contexts/organisation/receiver";
import ChainedContext from "../../contexts/chainedContext";
import SettingsArrayList from "./_components/SettingsArrayList";
import API from "../../api";

const VehicleSettings = ({ settings }) => {
  const onSubmit = (data) => {
    API.settings.updateSystem(data).then(() => {
      toast.success("Settings updated");
      settings._refresh();
    });
  };

  return (
    <div className="p-4">
      <div className="grid grid-cols-3 gap-4">
        <SettingsArrayList
          title="Vehicle Kit"
          field="vehicleKit"
          data={settings.vehicleKit}
          onSubmit={onSubmit}
          onDelete={onSubmit}
        />
        <SettingsArrayList
          title="Vehicle Checklist"
          field="vehicleChecklist"
          data={settings.vehicleChecklist}
          onSubmit={onSubmit}
          onDelete={onSubmit}
        />
        <SettingsArrayList
          title="Driving Categories"
          field="drivingCat"
          data={settings.drivingCat}
          onSubmit={onSubmit}
          onDelete={onSubmit}
        />
      </div>
    </div>
  );
};
export default ChainedContext(VehicleSettings, [
  [SettingsReceiver, "settings"],
  [OrganisationReceiver, "organisation"],
]);
