import React from "react";
import {Link, Outlet, Route, Routes} from "react-router-dom";
import Dashboard from "./dashboard";
import AssetNav from "./_components/navBar";
import AssetList from "./assets";
import AddAsset from "./add";
import AssetSettings from "./settings";
import ContentsReport from "./contents";
import SupplierPages from "./suppliers";
import AssetPages from "./asset";
import {AuthComponents} from "../../lib/_sso";

const Assets_Index = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Dashboard />} />
        <Route path="/list" element={<AssetList />} />
        <Route path="/add" element={<AddAsset />} />
        <Route path="/contents" element={<ContentsReport />} />
        <Route path="/settings" element={<AssetSettings />} />
        <Route path="/suppliers/*" element={<SupplierPages />} />
        <Route path="/view/:asset_id/*" element={<AssetPages />} />
        <Route path="*" element={<NoMatch2 />} />
      </Route>
    </Routes>
  );
};
function Layout() {
  const scope = "teamsite:assets_management";

  return (
    <div className="min-h-full">
      <AssetNav />
      <main>
        <AuthComponents.Can scope={scope}>
          <Outlet />
        </AuthComponents.Can>
        <AuthComponents.CanNot scope={scope}>
          <div className={"p-2 text-red-500"}>Access Denied: You do not have permission to access this page.</div>
        </AuthComponents.CanNot>
      </main>
    </div>
  );
}
function NoMatch2() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}

export default Assets_Index;