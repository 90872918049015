import React from "react";
import Box from "../../../components/common/Box";
import ConfirmBox from "../../../components/common/Confirm";
import ToggleSwitch from "../../../components/common/ToggleSwitch";
import Button from "../../../components/common/Button";
import Input from "../../../components/common/Input";
import { TrashIcon } from "@heroicons/react/24/outline";
export default class ManageSupplierContacts extends React.PureComponent {
  state = {
    allowSave: false,
    contacts: [],
    deleteContact: null,
    formError: null,
  };

  componentDidMount() {
    if (this.props.contacts) {
      this.setState({
        contacts: this.props.contacts,
      });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.contacts !== prevProps.contacts) {
      this.setState({
        contacts: this.props.contacts,
      });
    }
  }

  // Form Handlers
  onSubmit() {
    //TODO form validation
    this.setState(
      {
        submitting: true,
        formError: null,
      },
      () => {
        this.props
          .onSubmit(this.state.contacts)
          .then(() => {})
          .catch((err) => {
            let errorMessage =
              "Sorry, an unexpected error occurred while trying to save your changes. Please try again in a few moments";
            if (err.response !== undefined) {
              errorMessage = err.response.data.error_message;
            }
            this.setState({
              formError: errorMessage,
              submitting: false,
            });
          })
          .finally(() => {
            this.setState({
              submitting: false,
              allowSave: false,
            });
          });
      }
    );
  }
  onCancel() {
    this.setState({
      allowSave: false,
    });
    this.props.onCancel();
  }
  handleAddContact() {
    let contacts = this.state.contacts;
    contacts.push({ name: "", phone: "", email: "", primary: false });
    this.setState({
      contacts,
      allowSave: true,
    });
  }
  handleSetPrimary(contactIndex) {
    let contacts = this.state.contacts.map((item, index) => {
      return {
        ...item,
        primary: index === contactIndex,
      };
    });
    this.setState({
      contacts,
      allowSave: true,
    });
  }
  handleDeleteContact() {
    let contactIndex = this.state.deleteContact;
    this.setState({
      contacts: this.state.contacts.filter(
        (item, index) => index !== contactIndex
      ),
      deleteContact: null,
    });
  }
  handleTextChange(field, value, contactIndex) {
    this.setState({
      allowSave: true,
      contacts: this.state.contacts.map((contact, index) => {
        if (index !== contactIndex) {
          return contact;
        }
        return {
          ...contact,
          [field]: value,
        };
      }),
    });
  }
  render() {
    return (
      <Box
        title="Contacts List"
        button={{
          text: "Add Contact",
          colour: "bg-green-600",
          action: () => this.handleAddContact(),
        }}
      >
        <form>
          {this.state.deleteContact !== null && (
            <ConfirmBox
              show={true}
              title={"Delete selected contact"}
              onClose={() => this.setState({ deleteContact: null })}
              action={() => this.handleDeleteContact()}
            />
          )}

          <table className="tableClass ">
            <thead>
              <tr className="tableHeadRowClass">
                <th className="tableHeadCellClass ">Name</th>
                <th className="tableHeadCellClass">Phone</th>
                <th className="tableHeadCellClass ">Email</th>
                <th className="tableHeadCellClass">Primary Contact</th>
                <th>{""}</th>
              </tr>
            </thead>
            <tbody className="tableBodyClass">
              {this.state.contacts.map((contact, index) => {
                return (
                  <tr key={index} className={`tableBodyRowClass `}>
                    <td className="tableBodyCellClass">
                      <Input
                        placeholder="First Name"
                        value={contact.name}
                        onChange={(e) =>
                          this.handleTextChange("name", e.target.value, index)
                        }
                      />
                    </td>
                    <td className="tableBodyCellClass">
                      <Input
                        placeholder="Phone"
                        value={contact.phone}
                        onChange={(e) =>
                          this.handleTextChange("phone", e.target.value, index)
                        }
                      />
                    </td>
                    <td className="tableBodyCellClass">
                      <Input
                        placeholder="Email"
                        value={contact.email}
                        onChange={(e) =>
                          this.handleTextChange("email", e.target.value, index)
                        }
                      />
                    </td>
                    <td className="tableBodyCellClass w-32">
                      <ToggleSwitch
                        isChecked={contact.primary}
                        label1={"No"}
                        label2={"Yes"}
                        onChange={() => {
                          this.handleSetPrimary(index);
                        }}
                      />
                    </td>
                    <td className="tableBodyCellClass w-16">
                      {!contact.primary && (
                        <Button
                          icon={<TrashIcon className="h-4" />}
                          colour={"bg-red-700 text-white"}
                          onClick={() =>
                            this.setState({ deleteContact: index })
                          }
                        />
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <hr />
          <div className={"flex justify-between p-4"}>
            <div>
              <Button
                colour="bg-red-700 text-white"
                text={"Cancel"}
                floated={"left"}
                onClick={() => this.onCancel()}
              />
            </div>
            <div>
              <Button
                text={"save"}
                colour="bg-green-700 text-white"
                floated={"right"}
                onClick={() => this.onSubmit()}
                disabled={!this.state.allowSave}
              />
            </div>
          </div>
        </form>
      </Box>
    );
  }
}
