import React from "react";
import ExpiringRequirements from "../../components/widgets/ExpiringRequirements";
import ExpiringQualifications from "../../components/widgets/ExpiringQualifications";

const Dashboard = () => {
  return (
    <div className="p-4">
      <div className="grid gap-4 lg:grid-cols-2">
        <div className="col-span-1 space-y-4">
          <ExpiringRequirements />
        </div>
        <div className="col-span-1">
          <ExpiringQualifications />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;