import React, { Component } from "react";
import { Routes, Route, Outlet, Link } from "react-router-dom";
import Locations from "./Locations";

export default class LocationPages extends Component {
  render() {
    return (
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Locations />} />
          <Route path="*" element={<NoMatch2 />} />
        </Route>
      </Routes>
    );
  }
}
function Layout() {
  return (
    <div className="min-h-full">
      <main>
        <Outlet />
      </main>
    </div>
  );
}
function NoMatch2() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}
