import React, {useMemo} from "react";
import {toast} from "react-hot-toast";
import {useNavigate, useSearchParams} from "react-router-dom";
import EventEntryForm from "../_components/EventEntryForm";
import Box from "../../../components/common/Box";
import api from "../../../api";
import ChainedContext from "../../../contexts/chainedContext";
import SettingsReceiver from "../../../contexts/settings_context/Settings_Receiver";

const CreateEvent = ({settings}) => {
  const [search, setSearch] = useSearchParams();
  const navigate = useNavigate();

  const handleCreateEvent = (data) => {
    api.calendar.createEvent({
      ...data,
      isRecruitEvent: true
    }).then((res) => {
      toast.success("Successfully created new event");
      navigate(`/recruitment/events`);
      // navigate(`/recruitment/events/event/${res._id}`);
    });
  };

  const eventTypes = useMemo(() => settings && settings?.recruit_event_types?.map((item) => {
    return {
      text: item.name,
      value: item.name,
    };
  }), [settings]);

  return (
    <div className={"p-4"}>
      <Box title={"Add Recruit Event"}>
        <div className={"p-2"}>
          <EventEntryForm onSubmit={handleCreateEvent} eventTypes={eventTypes} startAt={search.get("startAt")} endAt={search.get("endAt")} onCancel={() => setIsOpen(false)}/>
        </div>
      </Box>
    </div>
  );
}
export default  ChainedContext(CreateEvent, [
  [SettingsReceiver, "settings"]
]);