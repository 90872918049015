import { AuthComponents, Authentication } from "./_sso";
import LogoLight from "../assets/images/logo-orange.png";
import LogoDark from "../assets/images/logo.png";

const HasWebConsoleAccess = ({ children, scope = "teamsite:portal:access"}) => {
  return (
    <div>
      <AuthComponents.Can scope={scope}>{children}</AuthComponents.Can>
      <AuthComponents.CanNot scope={scope}>
        <div className={"text-center"}>

          <img className={"pt-4 dark:hidden"} style={{width: 250, margin: "auto"}} src={LogoLight}/>
          <img className={"pt-4 hidden dark:block"} style={{width: 250, margin: "auto"}} src={LogoDark}/>

          <div className={"m-8 p-2 bg-red-500 border-2 rounded-2xl border-black dark:border-red-600 text-white dark:bg-red-900 text-center"}>
            <div className={"mb-6 text-4xl text-center"}>Access Denied</div>

            <div>Your account does not belong to one of the required security groups in SarStuff Central to access the <strong>TeamSite Management Portal</strong>.</div>
            <div className={"pt-3"}>One of the following Security Groups is required:</div>
            <div className={"flex flex-wrap gap-2 my-2 justify-center"}>
              <span className={"border rounded px-2"}>TeamSite - Admin</span>
              <span className={"border rounded px-2"}>TeamSite - Management</span>
              <span className={"border rounded px-2"}>TeamSite - Asset Management</span>
              <span className={"border rounded px-2"}>TeamSite - Power User</span>
              <span className={"border rounded px-2"}>TeamSite - Recruit Management</span>
              <span className={"border rounded px-2"}>Organisation Admins</span>
            </div>
            <div className={"pt-3"}>
              Please speak to your Organisation Admin(s) to be assigned one of these security groups if you believe you should have access to the <strong>TeamSite Management Portal</strong>.
            </div>

          </div>
          <div className={"text-center"}>
            <button className="px-4 py-2 text-white bg-blue-500 dark:bg-blue-900 rounded-lg" onClick={() => Authentication.logout()}>Logout</button>
          </div>
        </div>
      </AuthComponents.CanNot>
    </div>
  );
};
export default HasWebConsoleAccess;