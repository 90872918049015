import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { Disclosure, Tab } from "@headlessui/react";
import {
  ChevronUpIcon,
  TrashIcon,
  PencilIcon,
} from "@heroicons/react/20/solid";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import ChainedContext from "../../contexts/chainedContext";
import SettingsReceiver from "../../contexts/settings_context/Settings_Receiver";
import ArrayCard from "../../components/common/ArrayCard";
import { Skeleton } from "../../components/ui/skeleton";
import API from "../../api";
import Box from "../../components/common/Box";
import DataRow from "../../components/common/DataRow";
import DisplayAndUpdatePhoto from "../team/member/_components/DisplayAndUpdatePhoto";
import RequirementsGrid from "../../components/widgets/RequirementsGrid";
import api from "../../api";
import LocationManager from "../../components/locationManager";
import ConvertRecruit from "./_components/ConvertRecruit";
import RoleCard from "../../components/common/RoleCard";
import NextOfKinList from "../../components/widgets/NextOfKinList";
import RecruitNotes from "./_components/RecruitNotes";

const returnTabButtonStyle = (selected) => {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  return classNames(
    "w-full py-2.5 text-lg leading-5 font-medium  rounded border dark:border-zinc-500",
    selected
      ? "text-gray-100 bg-green-900 bg-opacity-50"
      : "text-blue-100 hover:bg-white/[0.12] hover:text-white bg-gray-800"
  );
};

function RecruitPage({ settings }) {
  const [recruit, setRecruit] = useState({});
  const [loading, setLoading] = useState(true);
  const [requirements, setRequirements] = useState([]);
  const [recruitRoles, setRecruitRoles] = useState([]);

  const navigate = useNavigate();
  const { recruit_id } = useParams();

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    API.recruitment.getRecruit(recruit_id).then(res => setRecruit(res)).catch((err) => {
      console.log(err);
    }).finally(() => {
      setLoading(false);
    });
    API.recruitment.getRecruitRoles(recruit_id).then(res => setRecruitRoles(res));
    API.recruitment.requirements.all(recruit_id).then((res) => {
      setRequirements(res);
    });
  };


  const handleGetUploadSpec = () => {
    return API.recruitment.photo.getUploadConfig(recruit_id);
  };
  const handleGettingPhoto = () => {
    return API.recruitment.photo.getAccessLink(recruit_id);
  };
  const handlePhotoUpload = (formData) => {
    return API.recruitment.photo.uploadPhoto(recruit_id, formData).then((a) => {
      getData();
      return a;
    });
  };
  const handleNokUpdate = (nokList) => {
    API.recruitment.updateRecruit(recruit._id, { nok: nokList }).then(() => {
      getData();
      toast.success("NoK listed successfully updated.");
    }).catch(err => {
      toast.error("Unable to push changes to NoK list to the server due to error: " + (err.response?.data?.message || err.message))
    });
  }

  const handleAddRole = role => {
    const addingRole = toast.loading("Adding role...");
    return API.recruitment.addRecruitRole(recruit_id, role).then(res => {
      getData();
      toast.success("Successfully added role to recruit");
    }).catch(err => {
      toast.error("There was an error while adding role: " + (err.response?.data?.message || err.message))
    }).finally(() => {
      toast.dismiss(addingRole);
    })
  };
  const handleRemoveRole = role => {
    const removingRole = toast.loading("Removing role...");
    return API.recruitment.removeRecruitRole(recruit_id, role._id).then(res => {
      getData();
      toast.success("Successfully removed role from recruit");
    }).catch(err => {
      toast.error("There was an error while removing role: " + (err.response?.data?.message || err.message))
    }).finally(() => {
      toast.dismiss(removingRole);
    })
  }

  const handleRequirementUpdate = (reqId, data) => {
    return API.recruitment.requirements.update(recruit_id, reqId, data);
  };

  return (
    <div className="w-full space-y-2 p-4">
      {loading && (
        <div className="grid grid-cols-3 gap-4">
          <div className="space-y-8">
            <Skeleton className="h-80 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
            <Skeleton className="h-80 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
          </div>
          <div className="col-span-2 space-y-8">
            <Skeleton className="h-52 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
            <Skeleton className="h-20 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
            <Skeleton className="h-20 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
          </div>
        </div>
      )}

      {recruit.isArchived && <div className={"p-2 border rounded border-red-400 dark:border-red-800"}>Recruit has been archived. {!recruit.keepAccount && <span className={"font-bold"}>There account is marked for deletion!</span>}</div>}

      {!loading && (
        <div className="grid grid-cols-3 gap-4 xl:grid-cols-4">
          <Box
            title={`[Recruit] ${recruit.firstName} ${recruit.lastName} `}
            button={{
              text: "Edit",
              colour: "text-sm text-white hover:text-gray-200",
              action: () =>
                navigate(`/recruitment/recruits/${recruit._id}/edit`),
            }}
          >
            <DisplayAndUpdatePhoto
              className="w-full border-8 border-white shadow-lg dark:shadow-zinc-600"
              canUpdate={true}
              onGetUploadSpec={handleGetUploadSpec}
              onGetPhoto={handleGettingPhoto}
              onUpload={handlePhotoUpload}
            />

            <div className=" ">
              <DataRow label={"Phone"} value={recruit.phone} />
              <DataRow
                label={"Secondary Phone"}
                value={recruit.phoneSecondary}
              />
              <DataRow label={"Email"} value={recruit.email} />
              <DataRow label={"Source"} value={recruit.source} />
              <DataRow label={"Intake"} value={recruit.intake?.ref} />
              <DataRow
                label={"DOB"}
                value={recruit?.dob && dayjs(recruit?.dob).format("DD-MM-YYYY")}
              />
              <DataRow
                label={"Date Enquired"}
                value={
                  recruit?.enquired &&
                  dayjs(recruit?.enquired).format("DD-MM-YY")
                }
              />
            </div>
            <div>
              <Disclosure defaultOpen={true}>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full justify-between  bg-zinc-200 px-4 py-2 text-left font-medium dark:bg-zinc-700  ">
                      <span>Roles</span>
                      <ChevronUpIcon
                        className={`${
                          !open ? "rotate-180 transform" : ""
                        } h-5 w-5 dark:text-zinc-200`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="px-4 pb-2 pt-4 text-sm text-gray-500">
                      <RoleCard
                        data={recruitRoles}
                        onAdd={handleAddRole}
                        onDelete={handleRemoveRole}
                      />
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </div>
            <div>
              <Disclosure as="div" className="mt-2">
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full justify-between bg-zinc-200 px-4 py-2 text-left font-medium dark:bg-zinc-700  ">
                      <span>
                        Driving Categories ({recruit.drivingCat.length})
                      </span>
                      <ChevronUpIcon
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5 dark:text-zinc-200`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="px-4 pb-2 pt-4 text-sm text-gray-500">
                      <ArrayCard
                        title="Driving Categories"
                        list={settings.drivingCat}
                        data={recruit.drivingCat}
                        submit={(e) => {
                          return API.recruitment
                            .updateRecruit(recruit._id, { drivingCat: e })
                            .then(() => {
                              getData();
                            });
                        }}
                        recruit={recruit}
                      />
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </div>
          </Box>
          <div className="col-span-2 xl:col-span-3">
            <Tab.Group defaultIndex={0}>
              <Tab.List className="flex space-x-1 border-b border-zinc-200 p-1 pb-2 dark:border-zinc-700 dark:bg-blue-900/20">
                <Tab
                  className={({ selected }) => returnTabButtonStyle(selected)}
                >
                  Details
                </Tab>
                <Tab
                  className={({ selected }) => returnTabButtonStyle(selected)}
                >
                  Locations
                </Tab>
                {!recruit.isArchived && <Tab
                  className={({ selected }) => returnTabButtonStyle(selected)}
                >
                  Convert to Team Member
                </Tab>}
              </Tab.List>
              <Tab.Panels>
                <Tab.Panel>
                  <NextOfKinList value={recruit.nok} onChange={handleNokUpdate} />
                  <RequirementsGrid
                    requirements={requirements}
                    onUpdate={handleRequirementUpdate}
                    onReload={getData}
                  />
                  <RecruitNotes recruit={recruit} />
                </Tab.Panel>
                <Tab.Panel>
                  <Box title={"Locations"}>
                    <div className={"pr-2"}>
                      <LocationManager
                        showAddInitial={true}
                        locations={recruit ? recruit.locations && recruit?.locations?.features.filter(a => a.geometry.coordinates.length === 2) : []}
                        handleAddLocation={(data) => {
                          const features = recruit
                            ? recruit.locations && recruit?.locations?.features
                            : [];
                          features.push(data);
                          return api.recruitment
                            .updateRecruit(recruit._id, {
                              locations: {
                                type: "FeatureCollection",
                                features,
                              },
                            })
                            .then(() => {
                              toast.success("Successfully added new location.");
                              getData();
                            });
                        }}
                        handleEditLocation={(data) => {
                          const features = recruit
                            ? recruit.locations && recruit?.locations?.features
                            : [];
                          return api.recruitment
                            .updateRecruit(recruit._id, {
                              locations: {
                                type: "FeatureCollection",
                                features: features.map((feature) => {
                                  if (feature._id === data._id) return data;
                                  return feature;
                                }),
                              },
                            })
                            .then(() => {
                              toast.success("Successfully updated location.");
                              getData();
                            });
                        }}
                        handleDeleteLocation={(data) => {
                          const features = recruit
                            ? recruit.locations && recruit?.locations?.features
                            : [];
                          return api.recruitment
                            .updateRecruit(recruit._id, {
                              locations: {
                                type: "FeatureCollection",
                                features: features.filter((feature) => {
                                  if (feature._id === data._id) return false;
                                  return true;
                                }),
                              },
                            })
                            .then(() => {
                              toast.success("Successfully deleted location.");
                              getData();
                            });
                        }}
                        onLocationClick={() => false}
                        hideExtra={true}
                        hideOrdering
                        heightMinus={250}
                      />
                    </div>
                  </Box>
                </Tab.Panel>
              </Tab.Panels>
              {!recruit.isArchived && <Tab.Panel>
                <Box title={"Convert to Team Member"}>
                  <ConvertRecruit recruit={recruit} />
                </Box>
              </Tab.Panel>}
            </Tab.Group>
          </div>
        </div>
      )}
    </div>
  );
}
export default ChainedContext(RecruitPage, [[SettingsReceiver, "settings"]]);
