import ChainedContext from "../../../contexts/chainedContext";
import SettingsReceiver from "../../../contexts/settings_context/Settings_Receiver";
import Select from "../../common/Select";
import React from "react";

const PickableLocations = ({ onLocationChange, location, locations }) => {

  const handleSelect = e => {
    const loc = locations.find(location => location._id === e.target.value);
    if(loc){
      let geoJSON = {
        type: loc.type,
        geometry: loc.geometry,
        properties: loc.properties,
      };
      onLocationChange(geoJSON);
    }
  }

  return (
    <div className="w-full">
      <div className="flex flex-row items-center justify-between">

        <Select
          options={locations.map(item => {
            return {
              key: item._id,
              text: item.properties.name,
              value: item._id,
            };
          })}
          onChange={handleSelect}
        />

      </div>
    </div>
  );
};
export default PickableLocations;