import React, {useState, useMemo} from "react";
import {ArrowsUpDownIcon} from "@heroicons/react/20/solid";
import DayJS from "dayjs";

const AttendanceList = ({items, actionButtons, showResponsedAt = false}) => {
  const [sortByName, setSortByName] = useState(true);

  const sortedItems = useMemo(() => {
    return items.sort((a, b) => {
      if (a.lastName > b.lastName) {
        return sortByName ? 1 : -1;
      } else if (a.lastName < b.lastName) {
        return sortByName ? -1 : 1;
      }
      return 0;
    })
  }, [items, sortByName]);

  if (sortedItems.length === 0) {
    return (
      <div className={"p-2 text-center"}>There are no items to display</div>
    );
  }

  return (
    <table className="min-w-full table-auto  divide-x divide-y divide-gray-200 text-sm dark:divide-zinc-500 lg:text-lg">
      <thead className="divide-y divide-gray-200 text-left dark:divide-zinc-500">
      <tr className="bg-gray-300 text-xs uppercase tracking-wider text-gray-900 dark:bg-zinc-700 dark:text-white">
        <th onClick={() => setSortByName(!sortByName)} className="flex gap-1 px-4 py-3 text-left">
          Name
          {sortByName ? (
            <ArrowsUpDownIcon className="h-4 text-green-500"/>
          ) : (
            <ArrowsUpDownIcon className="h-4"/>
          )}
        </th>
        {showResponsedAt && <th>Responded</th>}
        <th>Contact Details</th>
        <th>Change to</th>
      </tr>
      </thead>
      <tbody className="divide-y divide-gray-200 text-left dark:divide-zinc-500">
      {sortedItems.map((item) => {
        return (
          <React.Fragment key={item._id}>
            <tr>
              <td className="p-1">
                {item.firstName} {item.lastName}{" "}
                {item.callsign && (
                  <span className="italic">({item.callsign})</span>
                )}
              </td>
              {showResponsedAt && <td>{DayJS(item._respondedAt).format("DD-MM-YYYY HH:mm")}</td>}
              <td>{item.email}<br/>{item.phone}</td>
              <td className="px-1 py-2">
                <div
                  className="flex flex-wrap items-center space-y-1 text-center align-middle text-xs md:flex-nowrap md:space-y-0">
                  {actionButtons.map((a, i) => {
                    return (
                      <button
                        key={i}
                        className={a.className + " w-full"}
                        onClick={() => a.onClick(item)}
                      >
                        {a.title}
                      </button>
                    );
                  })}
                </div>
              </td>
            </tr>
          </React.Fragment>
        );
      })}
      </tbody>
    </table>
  );
};
export default AttendanceList