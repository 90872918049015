import React, {useState, useEffect, useCallback, useMemo} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import { toast } from "react-hot-toast";
import DayJS from "dayjs";
import api from "../../api";
import SettingsReceiver from "../../contexts/settings_context/Settings_Receiver";
import ChainedContext from "../../contexts/chainedContext";

function CalendarPage({ settings }) {
  const [events, setEvents] = useState([]);
  const [combined, setCombined] = useState([]);
  const [trainingCompetenciesEvents, setTrainingCompetenciesEvents] = useState([]);
  const [courseEvents, setCourseEvents] = useState([]);

  const navigate = useNavigate();
  const [search, setSearch] = useSearchParams();

  const retrieveEvents = useCallback(() => {
    api.calendar
      .retrieveAllCompetenciesDates()
      .then((res) => {
        setTrainingCompetenciesEvents(res);
      })
      .catch(() => {
        toast.error(
          "Unable to retrieve competencies training dates from server."
        );
      });
    api.calendar
      .retrieveAllCourseDates()
      .then((res) => {
        setCourseEvents(res);
      })
      .catch(() => {
        toast.error("Unable to retrieve course dates from server.");
      });
    api.calendar
      .retrieveAll()
      .then((res) => {
        setEvents(res);
      })
      .catch(() => {
        toast.error("Unable to retrieve events from server.");
      });
  }, []);
  const initialDate = useMemo(() => {
    if(search.get("startAt") !== null){
      return search.get("startAt");
    }
    return null;
  }, [search]);
  const initialView = useMemo(() => {
    if(search.get("view") !== null){
      return search.get("view");
    }
    return "";
  }, [search]);


  useEffect(() => {
    settings._refresh();
  }, []);
  useEffect(() => {
    setCombined(events.concat(trainingCompetenciesEvents, courseEvents));
  }, [events, trainingCompetenciesEvents, courseEvents]);
  useEffect(() => {
    retrieveEvents();
  }, [retrieveEvents]);

  const handleEventClick = ({ event, el }) => {
    const ev = event.toPlainObject().extendedProps;
    if (ev) {
      if (ev.eventType === "comp") {
        navigate(`/training/competencies/session/${ev._id}`);
      } else if (ev.eventType === "course") {
        navigate(`/training/courses/event/${ev._id}`);
      } else {
        if (ev.isRecruitEvent !== undefined && ev.isRecruitEvent) {
          navigate(`/recruitment/events/event/${ev._id}`);
        } else {
          navigate(`/events/event/${ev._id}`);
        }
      }
    }
  };
  const handleDateClick = (selectInfo) => {
    const a = DayJS(selectInfo.date).toISOString();
    const b = DayJS(selectInfo.date)
      .add(2, "hours")
      .set("minute", 0)
      .toISOString();
    navigate(`/events/picker?eventType=event&startAt=${a}&endAt=${b}`);
  };
  const handleCalenderViewChange = dateInfo => {
    const startAt = (dateInfo.view.type === "dayGridMonth" && DayJS(dateInfo.start).get("date") !== 1) ?
      DayJS(dateInfo.start).startOf("month").add(1, "month").format("YYYY-MM-DD")
      :
      DayJS(dateInfo.start).format("YYYY-MM-DD");
    setSearch({
      startAt,
      view: dateInfo.view.type
    })
  };

  const timeFormat = {
    // like '14:30:00'
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  };

  return (
    <div className="h-1/2 p-2">
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        height={"99vh"}
        locale={"en-GB"}
        firstDay={1}
        editable
        headerToolbar={{
          left: "prev,next today myCustomButton",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        }}
        selectable={true}
        dateClick={handleDateClick}
        events={combined}
        eventClick={handleEventClick}
        forceEventDuration={true}
        eventTimeFormat={timeFormat}
        datesSet={handleCalenderViewChange}
        initialDate={initialDate}
        initialView={initialView}
        customButtons={{
          myCustomButton: {
            text: "Create Event",
            click: function () {
              const a = DayJS().add(1, "hours").set("minute", 0).toISOString();
              const b = DayJS().add(2, "hours").set("minute", 0).toISOString();
              navigate(`/events/picker?startAt=${a}&endAt=${b}`);
            },
          },
        }}
      />
    </div>
  );
}
export default ChainedContext(CalendarPage, [
  [SettingsReceiver, "settings"]
]);