import React, { Component } from "react";
import { Routes, Route, Outlet, Link, useRouteError } from "react-router-dom";
import MemberPage from "./MemberPage";
import api from "../../../api";
import MemberProvider from "../../../contexts/member_context/Member_Provider";
import EditMember from "./EditMember";
import MemberNav from "./_components/NavBar";
import Locations from "./Locations";
import Competencies from "./Competencies";
import CPD from "./CPD";
import MemberDetails from "./Details";
import Courses from "./courses";
import Medical from "./Medical";
import Deployments from "./Deployments";
import Assets from "./Assets";

export default class MemberPages extends Component {
  render() {
    return (
      <MemberProvider api={api}>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route
              index
              element={<MemberPage />}
              errorElement={<ErrorBoundary />}
            />
            <Route
              path="/edit"
              element={<EditMember />}
              errorElement={<ErrorBoundary />}
            />
            <Route path="/locations" element={<Locations />} />
            <Route path="/deployments" element={<Deployments />} />
            <Route path="/competencies" element={<Competencies />} />
            <Route path="/details" element={<MemberDetails />} />
            <Route path="/medical" element={<Medical />} />
            <Route path="/cpd" element={<CPD />} />
            <Route path="/courses" element={<Courses />} />
            <Route path="/assets" element={<Assets />} />
            <Route path="*" element={<NoMatch2 />} />
          </Route>
        </Routes>
      </MemberProvider>
    );
  }
}
function ErrorBoundary() {
  let error = useRouteError();
  console.error("oi", error);
  // Uncaught ReferenceError: path is not defined
  return <div>Dang!</div>;
}
function Layout() {
  return (
    <div className="min-h-full">
      <MemberNav />
      <main>
        <Outlet />
      </main>
    </div>
  );
}
function NoMatch2() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}
