import assets from './assets';
import calendar from "./calendar";
import courses from "./courses";
import documents from "./documents";
import feeds from "./feeds";
import member from "./member";
import settings from "./settings";
import training from "./training";
import vehicles from "./vehicles";
import recruitment from "./recruitment";
import locations from "./locations";
import reports from "./reports";
import incidents from "./incidents";
import { APIRequest } from "../lib/_sso";
import AppConfig from "../_appConfig";

export default {
  _getBuildNumber: () => APIRequest.get(`${AppConfig.api_server}/_buildNumber`).then((res) => res.data),
  assets,
  calendar,
  courses,
  documents,
  feeds,
  member,
  settings,
  training,
  vehicles,
  recruitment,
  locations,
  reports,
  incidents
};
