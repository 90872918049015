import React, {useRef, useEffect, useState, useMemo} from "react";
import * as ol from "ol";
import {defaults as defaultControls} from "ol/control/defaults";
import View from "ol/View";
import {fromLonLat, toLonLat} from "ol/proj";
import proj4 from "proj4";
import {register} from "ol/proj/proj4";
import {
  Map as MapContext,
  Projection as ProjectionContext,
  LayersToggle_Register,
  LayersToggle_Active
} from "./context";

proj4.defs("EPSG:27700", "+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.9996012717 +x_0=400000 +y_0=-100000 +ellps=airy +towgs84=446.448,-125.157,542.06,0.15,0.247,0.842,-20.489 +units=m +no_defs");
register(proj4);

const Map = ({height = 500, zoom = 14, center, children, onMapClick}) => {
  const mapContainerRef             = useRef();
  const [map, setMap]               = useState(null);
  const [projection, setProjection] = useState("EPSG:3857");
  const [layers, setLayers]         = useState([]);

  const LayerToggleRegister = useMemo(() => {
    return {
      register: (title, isActive) => setLayers(b => b.concat([{
        title,
        isActive
      }])),
      deregister: title => setLayers(b => b.filter(c => title !== c.title)),
      toggle: title => setLayers(a => a.map(b => b.title === title ? {...b, isActive: !b.isActive} : b))
    }
  }, []);

  useEffect(() => {
    let mapObject = new ol.Map({
      view: new View({
        zoom,
        center: fromLonLat(center, projection),
        projection,
      }),
      layers: [],
      overlays: [],
      controls: defaultControls(),
    });
    mapObject.setTarget(mapContainerRef.current);
    setMap(mapObject);

    return () => mapObject.setTarget(undefined);
  }, []);
  useEffect(() => {
    if (!map) return;

    const handleOnMapClick = event => onMapClick !== undefined ? onMapClick(toLonLat(event.coordinate, projection)) : null;

    map.on("singleclick", handleOnMapClick);
    return () => {
      map.un("singleclick", handleOnMapClick);
    }
  }, [map, onMapClick]);
  useEffect(() => {
    if (!map) return;

    map.getView().setCenter(fromLonLat(center, map.getView().getProjection().getCode()))
  }, [map, center]);
  useEffect(() => {
    if (!map) return;

    map.getView().setZoom(zoom);
  }, [map, zoom]);
  useEffect(() => {
    if (!map) return;

    if (map.getView().getProjection().getCode() !== projection){
      const view = new View({
        projection: projection,
        zoom: map.getView().getZoom(),
        center: fromLonLat(toLonLat(map.getView().getCenter(), map.getView().getProjection().getCode()), projection),
      });
      map.setView(view);
    }
  }, [map, projection]);

  return (
    <MapContext.Provider value={map}>
      <ProjectionContext.Provider value={{
        setProjection: setProjection,
        projection: projection
      }}>
        <LayersToggle_Register.Provider value={LayerToggleRegister}>
          <LayersToggle_Active.Provider value={layers}>
            <div className="relative h-full w-full">
              <div ref={mapContainerRef} style={{height: height, width: "100%"}} className="ol-map">
                {children}
              </div>
            </div>
          </LayersToggle_Active.Provider>
        </LayersToggle_Register.Provider>
      </ProjectionContext.Provider>
    </MapContext.Provider>
  );
};
export default Map;