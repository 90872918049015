import React, {useCallback, useEffect, useMemo, useState} from "react";
import {ArrowsUpDownIcon} from "@heroicons/react/20/solid";
import {useNavigate, useParams} from "react-router-dom";
import Toast from "react-hot-toast";
import fileDownloader from "js-file-download";
import {Tab} from "@headlessui/react";
import DayJS from "dayjs";
import {CheckIcon, PencilIcon, PlusCircleIcon, PrinterIcon, XMarkIcon} from "@heroicons/react/24/outline";
import API from "../../../api";
import PdfPrintingProgress from "../../../components/common/PdfPrintingProgress";
import {AuthComponents} from "../../../lib/_sso";
import Box from "../../../components/common/Box";
import Button from "../../../components/common/Button";
import DataRow from "../../../components/common/DataRow";
import {Layers, Map} from "../../../components/sarstuff_mapping";
import MappingDefaults from "../../../components/mapping_defaults";
import * as MapStyles from "../../../components/mapping_styles";
import AttendanceListWithResults from "../../events/_components/AttendanceListWithResults";
import AttendanceList from "../../events/_components/AttendanceList";
import NewLineBreaker from "../../../components/common/NewLineBreaker";

const returnTabButtonStyle = (selected) => {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  return classNames(
    "w-full py-2.5 text-lg leading-5 font-medium  rounded-lg",
    selected
      ? "text-gray-100 bg-green-900 bg-opacity-50"
      : "text-blue-100 hover:bg-white/[0.12] hover:text-white bg-gray-800"
  );
};

const RenderAttendanceList = ({ items, actionButtons }) => {
  const [sortByName, setSortByName] = useState(true);

  if (items.length === 0) {
    return (
      <div className={"p-2 text-center"}>There are no items to display</div>
    );
  }

  return (
    <table className="min-w-full table-auto  divide-x divide-y divide-gray-200 text-sm dark:divide-zinc-500 lg:text-lg">
      <thead className="divide-y divide-gray-200 text-left dark:divide-zinc-500">
      <tr className="bg-gray-300 text-xs uppercase tracking-wider text-gray-900 dark:bg-zinc-700 dark:text-white">
        <th
          onClick={() => setSortByName(!sortByName)}
          className="flex gap-1 px-4 py-3 text-left"
        >
          Name
          {sortByName ? (
            <ArrowsUpDownIcon className="h-4 text-green-500" />
          ) : (
            <ArrowsUpDownIcon className="h-4" />
          )}
        </th>
        {/* <th>Call Sign</th> */}
        <th>Contact Details</th>
        <th>Change to</th>
      </tr>
      </thead>
      <tbody className="divide-y divide-gray-200 text-left dark:divide-zinc-500">
      {items
      .sort((a, b) => {
        if (a.lastName > b.lastName) {
          return sortByName ? 1 : -1;
        } else if (a.lastName < b.lastName) {
          return sortByName ? -1 : 1;
        }
        return 0;
      })
      .map((item) => {
        return (
          <tr key={item._id} className="">
            <td className="p-1">
              {item.firstName} {item.lastName}{" "}
              {item.callsign && (
                <span className="italic">({item.callsign})</span>
              )}
            </td>
            {/* <td>{item.callsign}</td> */}
            <td>
              {item.email}
              <br />
              {item?.phone}
            </td>
            <td className="px-1 py-2">
              <div className="flex flex-wrap items-center space-y-1 text-center align-middle text-xs md:flex-nowrap md:space-y-0">
                {actionButtons.map((a, i) => {
                  return (
                    <button
                      key={i}
                      className={a.className + " w-full"}
                      onClick={() => a.onClick(item)}
                    >
                      {a.title}
                    </button>
                  );
                })}
              </div>
            </td>
          </tr>
        );
      })}
      </tbody>
    </table>
  );
};

const RecruitEventView = () => {
  const { event_id } = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({});
  const [members, setMembers] = useState([]);
  const [recruits, setRecruits] = useState([]);
  const [printingError, setPrintingError] = useState(null);
  const [isPrinting, setIsPrinting] = useState(false);

  const recruit_attending = useMemo(() => {
    return recruits.filter((a) => {
      if (data.Responses !== undefined) {
        const found = data?.Responses.find(r => r.recruit?._id === a._id && r.response === true);
        return found !== undefined;
      }
    });
  }, [data, recruits]);
  const recruit_notAttending = useMemo(() => {
    return recruits.filter((a) => {
      if (data.Responses !== undefined) {
        const found = data?.Responses.find(r => r.recruit?._id === a._id && r.response === false);
        return found !== undefined;
      }
    });
  }, [data, recruits]);
  const recruit_notResponded = useMemo(() => {
    return recruits.filter((a) => {
      if (data.Responses !== undefined) {
        const found = data?.Responses.find(r => r.recruit?._id === a._id);
        return found === undefined;
      }
    });
  }, [data, recruits]);

  const member_attending = useMemo(() => {
    return members.map((a) => {
      if (data.Responses !== undefined) {
        const found = data?.Responses.find(
          (r) => r.member?._id === a._id && r.response === true
        );
        if(found !== undefined){
          return {
            ...a,
            _respondedAt: found.respondedAt !== undefined ? found.respondedAt : found.updatedAt
          }
        }
      }
      return false;
    }).filter(a => a !== false);
  }, [data, members]);
  const member_notAttending = useMemo(() => {
    return members.map((a) => {
      if (data.Responses !== undefined) {
        const found = data?.Responses.find(
          (r) => r.member?._id === a._id && r.response === false
        );
        if(found !== undefined){
          return {
            ...a,
            _respondedAt: found.respondedAt !== undefined ? found.respondedAt : found.updatedAt
          }
        }
      }
      return false;
    }).filter(a => a !== false);
  }, [data, members]);
  const member_notResponded = useMemo(() => {
    return members.filter((a) => {
      if (data.Responses !== undefined) {
        const found = data?.Responses.find((r) => r.member?._id === a._id);
        return found === undefined;
      }
    });
  }, [data, members]);


  const organiser = useMemo(() => {
    if (data.organiserID?._id) {
      return data.organiserID;
    } else if (data.organiser) {
      const found = members.find((m) => m._id === data.organiser);
      return {
        name: (found?.firstName ? found?.firstName : "--") + " " + (found?.lastName ? found?.lastName : "--"),
        phone: found?.phone,
        email: found?.email,
      };
    }
  }, [members, data]);

  const getData = useCallback(() => {
    API.calendar.getEvent(event_id).then((res) => {
      setData(res);
      setIsLoading(false);
    });
  }, [event_id]);

  const handle_recruit_response = (recruit, r) => {
    const response = {
      event: data._id,
      recruit: recruit._id,
      response: r,
    };
    API.calendar.markResponse(event_id, response).then(() => {
      getData();
      Toast.success(`Successfully changed attendance to: ${r ? "Attending" : "Not Attending"}`);
    }).catch(() => {
      Toast.error("There was an error while updating attendance, try again shortly.");
    });
  };
  const handle_member_response = (member, r) => {
    const response = {
      event: data._id,
      member: member._id,
      response: r,
    };
    API.calendar.markResponse(event_id, response).then(() => {
      getData();
      Toast.success(`Successfully changed attendance to: ${r ? "Attending" : "Not Attending"}`);
    }).catch(() => {
      Toast.error("There was an error while updating attendance, try again shortly.");
    });
  };
  const handlePrint = () => {
    setPrintingError(null);
    setIsPrinting(true);

    API.calendar.printEvent(data._id).then(theData => {
      fileDownloader(theData, `${data.title}.pdf`);
      setIsPrinting(false);
    }).catch((err) => {
      if (err.response !== undefined){
        setPrintingError(err.response.data.message);
      } else {
        setPrintingError("There was an unexpected error while creating the PDF, please try again later or contact support if issues continues.");
      }
    });
  };

  useEffect(() => {
    API.member.allCache().then(res => setMembers(res));
    API.recruitment.getActiveRecruits().then(res => setRecruits(res));
  }, []);
  useEffect(() => {
    getData();
  }, [getData]);

  if (isLoading) {
    return <div className={"p-2 text-2xl"}>Loading Event Data...</div>;
  }

  return (
    <div className={"p-2"}>
      <PdfPrintingProgress
        active={isPrinting}
        error={printingError}
        onClose={() => setIsPrinting(false)}
      />
      <div className={"grid gap-4 2xl:grid-cols-4"}>
        <div className={"space-y-4 2xl:col-span-2"}>
          <Box title="Recruit Event Details">
            <div className="flex justify-around gap-1 bg-zinc-200 p-2 dark:bg-zinc-800">
              <Button
                text="Edit"
                icon={<PencilIcon className="h-6 pr-2 text-blue-400" />}
                onClick={() => navigate(`/recruitment/events/event/${data._id}/edit`)}
              />
              <Button
                text="Print"
                icon={<PrinterIcon className="h-6 pr-2 text-blue-400" />}
                onClick={handlePrint}
              />
                <Button
                  text="Copy"
                  icon={<PlusCircleIcon className="h-6 pr-2 text-green-400" />}
                  onClick={() => navigate(`/recruitment/events/event/${data._id}/clone`)}
                />
            </div>

            <DataRow label={"Title"} value={data.title} />
            <DataRow
              label={"Type"}
              value={data.eventType ? data.eventType : " -- "}
            />
            <div className="grid items-end lg:grid-cols-2">
              <DataRow
                label={"Start"}
                value={DayJS(data.start).format("DD-MM-YYYY HH:mm")}
              />
              <DataRow
                label={"End"}
                value={
                  data.end ? DayJS(data.end).format("DD-MM-YYYY HH:mm") : " -- "
                }
              />
            </div>
            <DataRow
              label={"All Day Event"}
              value={
                data.allDay ? (
                  <CheckIcon className="h-6 text-green-500" />
                ) : (
                  <XMarkIcon className="h-6 text-red-600" />
                )
              }
            />
            <DataRow label={"Details"} value={<NewLineBreaker value={data.description} />} />
            <div className="grid space-y-2 border-b  border-zinc-300 pb-2 dark:border-zinc-700 lg:grid-cols-2">
              <div className="col-span-2  text-center  lg:col-span-1">
                <span className="text-primary dark:text-primary-dark">
                  Organiser
                </span>
                <div className="ml-4 mt-1 text-left text-black dark:text-white">
                  <div className="text-zinc-400">
                    Name:{" "}
                    <span className="text-black dark:text-white">
                      {data.organiser}
                    </span>
                  </div>
                  <div className="text-zinc-400">
                    Phone:{" "}
                    <span className="text-black dark:text-white">
                      {organiser?.phone}
                    </span>
                  </div>
                  <div className="text-zinc-400">
                    Email:{" "}
                    <span className="text-black dark:text-white">
                      {organiser?.email}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-span-2 text-center lg:col-span-1">
                <span className="text-primary dark:text-primary-dark">
                  {" "}
                  Location
                </span>
                <div className="ml-4 mt-1 text-left text-black dark:text-white">
                  <div className="text-zinc-400">
                    Name:{" "}
                    <span className="text-black dark:text-white">
                      {data.properties?.name}
                    </span>
                  </div>
                  <div className="text-zinc-400">
                    Address:{" "}
                    <span className="text-black dark:text-white">
                      {data.properties?.address}
                    </span>
                  </div>
                  <div className="text-zinc-400">
                    w3w:{" "}
                    <span className="text-black dark:text-white">
                      {Array.isArray(data.properties?.w3w)
                        ? data.properties?.w3w.join(".")
                        : data.properties?.w3w}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <DataRow label={"Private Notes"} value={<NewLineBreaker value={data.notes} />} />
          </Box>
          {data.geometry?.coordinates !== undefined && (
            <Box title={"Location"}>
              <Map height={"50vh"} center={[0.8, 51]}>
                <MappingDefaults />
                <Layers.BoundingBox active={true}>
                  <Layers.GeoJSON
                    geoJSON={data}
                    zIndex={10}
                    style={MapStyles.Marker}
                  />
                </Layers.BoundingBox>
              </Map>
            </Box>
          )}
        </div>
        <div className={"2xl:col-span-2"}>
          <Box title={"Attendance List"}>
            <Tab.Group defaultIndex={0}>
              <Tab.List className="flex flex-wrap space-x-1 border-b border-zinc-200 p-1  pb-2 dark:border-zinc-700 dark:bg-blue-900/20 md:flex-nowrap">
                <Tab className={({ selected }) => returnTabButtonStyle(selected)}>
                  Recruits
                </Tab>
                <Tab className={({ selected }) => returnTabButtonStyle(selected)}>
                  Team Members
                </Tab>
              </Tab.List>
              <Tab.Panels>
                <Tab.Panel>
                  <Tab.Group defaultIndex={0}>
                    <Tab.List className="flex flex-wrap space-x-1 border-b border-zinc-200 p-1  pb-2 dark:border-zinc-700 dark:bg-blue-900/20 md:flex-nowrap">
                      <Tab className={({ selected }) => returnTabButtonStyle(selected)}>
                        Attending ({recruit_attending.length})
                      </Tab>
                      <Tab className={({ selected }) => returnTabButtonStyle(selected)}>
                        Not Attending ({recruit_notAttending.length})
                      </Tab>
                      <Tab className={({ selected }) => returnTabButtonStyle(selected)}>
                        Not Responded ({recruit_notResponded.length})
                      </Tab>
                    </Tab.List>
                    <Tab.Panels>
                      <Tab.Panel>
                        <RenderAttendanceList
                          items={recruit_attending}
                          actionButtons={[
                            {
                              title: "Not-Attending",
                              className: "ml-2 rounded p-2 offcall border text-sm dark:border-zinc-500",
                              onClick: (m) => handle_recruit_response(m, false),
                            }
                          ]}
                        />
                      </Tab.Panel>
                      <Tab.Panel>
                        <RenderAttendanceList
                          items={recruit_notAttending}
                          actionButtons={[
                            {
                              title: "Attending",
                              className: "rounded p-2 oncall border text-sm dark:border-zinc-500",
                              onClick: (m) => handle_recruit_response(m, true),
                            }
                          ]}
                        />
                      </Tab.Panel>
                      <Tab.Panel>
                        <RenderAttendanceList
                          items={recruit_notResponded}
                          actionButtons={[
                            {
                              title: "Attending",
                              className: "rounded p-2 oncall border dark:border-zinc-500",
                              onClick: (m) => handle_recruit_response(m, true),
                            },
                            {
                              title: "Not-Attending",
                              className: "md:ml-2 rounded p-2 offcall border dark:border-zinc-500",
                              onClick: (m) => handle_recruit_response(m, false),
                            },
                          ]}
                        />
                      </Tab.Panel>
                    </Tab.Panels>
                  </Tab.Group>
                </Tab.Panel>
                <Tab.Panel>
                  <Tab.Group defaultIndex={0}>
                    <Tab.List className="flex flex-wrap space-x-1 border-b border-zinc-200 p-1  pb-2 dark:border-zinc-700 dark:bg-blue-900/20 md:flex-nowrap">
                      <Tab className={({selected}) => returnTabButtonStyle(selected)}>
                        Attending ({member_attending.length})
                      </Tab>
                      <Tab className={({selected}) => returnTabButtonStyle(selected)}>
                        Not Attending ({member_notAttending.length})
                      </Tab>
                      <Tab className={({selected}) => returnTabButtonStyle(selected)}>
                        Not Responded ({member_notResponded.length})
                      </Tab>
                    </Tab.List>
                    <Tab.Panels>
                      <Tab.Panel>
                        <AttendanceList
                          showResponsedAt={true}
                          items={member_attending}
                          actionButtons={[
                            {
                              title: "Not-Attending",
                              className: "ml-2 rounded p-2 offcall border text-sm dark:border-zinc-500",
                              onClick: (m) => handle_member_response(m, false),
                            },
                          ]}
                        />
                      </Tab.Panel>
                      <Tab.Panel>
                        <AttendanceList
                          showResponsedAt={true}
                          items={member_notAttending}
                          actionButtons={[
                            {
                              title: "Attending",
                              className: "rounded p-2 oncall border text-sm dark:border-zinc-500",
                              onClick: (m) => handle_member_response(m, true),
                            },
                          ]}
                        />
                      </Tab.Panel>
                      <Tab.Panel>
                        <AttendanceList
                          items={member_notResponded}
                          actionButtons={[
                            {
                              title: "Attending",
                              className: "rounded p-2 oncall border  dark:border-zinc-500",
                              onClick: (m) => handle_member_response(m, true),
                            },
                            {
                              title: "Not-Attending",
                              className: "md:ml-2 rounded p-2 offcall border  dark:border-zinc-500",
                              onClick: (m) => handle_member_response(m, false),
                            },
                          ]}
                        />
                      </Tab.Panel>
                    </Tab.Panels>
                  </Tab.Group>
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </Box>
        </div>
      </div>
    </div>
  );
}
export default RecruitEventView;