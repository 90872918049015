import React from "react";
import Loader from "./Loader";

export default function Stat({
  label,
  subLabel = "",
  value,
  colour = "bg-zinc-200 dark:bg-zinc-700",
  labelColour = "text-zinc-600 dark:text-zinc-300",
  action,
  loading = false,
}) {
  return (
    <div className={`${colour}  flex-1 shadow-lg sm:rounded-lg`}>
      <div
        className={`flex flex-col items-center justify-center p-2  ${
          action && "cursor-pointer"
        }`}
        onClick={action}
      >
        {loading ? (
          <Loader />
        ) : (
          <h1 className="text-2xl font-medium">{value}</h1>
        )}
        <div className={`text-md mt-1 max-w-2xl text-center ${labelColour}`}>
          {label}
          {subLabel !== "" && <div className={"text-xs"}>{subLabel}</div>}
        </div>
      </div>
    </div>
  );
}
