import React, { useEffect, useState } from "react";
import Modal from "../../../components/common/Modal";
import Input from "../../../components/common/Input";
import API from "../../../api";
import TextArea from "../../../components/common/TextArea";

const RenameFile = ({ onClose, file }) => {
  const [submitError, setSubmitError] = useState(null);
  const [name, setName] = useState("");
  const [ext, setExt] = useState("");
  const [fileDescription, setFileDescription] = useState("");

  useEffect(() => {
    const splitName = file.filename.split(".");
    if (splitName.length >= 2) {
      let tempExt = splitName.pop();
      setExt(tempExt);
    }
    setName(splitName.join("."));
    setFileDescription(file.description || "");
  }, [file]);

  const handleSubmit = () => {
    setSubmitError(null);
    if (name.trim().length >= 1) {
      API.documents.renameFile(file._id, name + "." + ext, fileDescription).then(() => {
        onClose();
      }).catch((err) => {
        setSubmitError(err.response.data.message);
      });
    } else {
      setSubmitError("Name can not be empty.");
    }
  };

  return (
    <Modal title={"Rename File"}>
      <div className="flex items-end gap-2">
        <Input
          label="File Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />{" "}
        .{ext}
      </div>
      <TextArea
        label="File Description"
        value={fileDescription}
        onChange={(e) => setFileDescription(e.target.value)}
      />
      {submitError && <div className={"py-1 text-red-500"}>{submitError}</div>}

      <div className="mt-4 flex justify-between border-t border-zinc-500 pt-4">
        <button
          className="rounded-lg bg-primary px-4 py-2 text-white"
          onClick={onClose}
        >
          Cancel
        </button>
        <button
          className="rounded-lg bg-green-500 px-4 py-2 text-white disabled:bg-gray-500"
          onClick={handleSubmit}
        >
          Rename
        </button>
      </div>
    </Modal>
  );
};
export default RenameFile;
