import React from "react";
import AlertBox from "../../common/AlertBox";

const MapClick = ({ location }) => {
  return (
    <div>
      {location === null && (
        <AlertBox
          priority={"low"}
          message={"Click on the map to add a new location"}
        />
      )}
    </div>
  );
};
export default MapClick;
