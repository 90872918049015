import React, { useState, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import { TrashIcon } from "@heroicons/react/20/solid";
import Box from "../../components/common/Box";
import Select from "../../components/common/Select";
import ToggleSwitch from "../../components/common/ToggleSwitch";
import SelectZero from "../../components/common/SelectZero";
import Input from "../../components/common/Input";
import ConfirmBox from "../../components/common/Confirm";
import Button from "../../components/common/Button";
import API from "../../api";
import ChainedContext from "../../contexts/chainedContext";
import SettingsReceiver from "../../contexts/settings_context/Settings_Receiver";
import OrganisationReceiver from "../../contexts/organisation/receiver";

function AddVehicle({ settings, organisation }) {
  const [regNumber, setRegNumber] = useState("");
  const [make, setMake] = useState("");
  const [model, setModel] = useState("");
  const [fuelType, setFuelType] = useState("");
  const [colour, setColour] = useState("");
  const [taxDueDate, setTaxDue] = useState("");
  const [taxStatus, setTaxStatus] = useState("");
  const [motStatus, setMotStatus] = useState("");
  const [motExpiryDate, setMotExpiryDate] = useState("");
  const [engineCapacity, setEngineCapacity] = useState("");
  const [seats, setSeats] = useState("");
  const [tyres, setTyres] = useState("");
  const [doors, setDoors] = useState("");
  const [callsign, setCallsign] = useState("");
  const [towWeight, setTowWeight] = useState("");
  const [yearOfManufacture, setYearOfManufacture] = useState("");
  const [insuranceExpiry, setInsuranceExpiry] = useState("");
  const [assigned, setAssigned] = useState("");
  const [unitVehicle, setUnitVehicle] = useState(true);
  const [checkOK, setCheckOK] = useState(null);
  const [deleteVehicle, setDeleteVehicle] = useState(null);
  const [member, setMember] = useState(null);
  const [members, setMembers] = useState([]);
  const [locations, setLocations] = useState([]);
  const [errors, setErrors] = useState({});

  const navigate = useNavigate();
  const { vehicle_id } = useParams();
  const [searchParams] = useSearchParams();
  const unit_member = searchParams.get("member_id");
  const tyreTypes = [
    { key: 1, text: "Road", value: "Road" },
    { key: 2, text: "Mud", value: "Mud" },
    { key: 3, text: "Mud/Snow", value: "Mud/Snow" },
    { key: 4, text: "All Terrain", value: "All Terrain" },
  ];

  useEffect(() => {
    getData();
    if (vehicle_id) {
      API.vehicles.get(vehicle_id).then((res) => {
        setMake(res.make);
        setFuelType(res.fuelType);
        setColour(res.colour);
        setTaxDue(res.taxDueDate ? res.taxDueDate : "");
        setTaxStatus(res.taxStatus);
        setMotStatus(res.motStatus);
        setMotExpiryDate(res.motExpiryDate ? res.motExpiryDate : "");
        setEngineCapacity(res.engineCapacity);
        setRegNumber(res.regNumber);
        setModel(res.model);
        setSeats(res.seats);
        setTyres(res.tyres);
        setDoors(res.doors);
        setTowWeight(res.towWeight);
        setCallsign(res.callsign);
        setInsuranceExpiry(res.insuranceExpiry);
        if(res.member){
          setMember(res.member?._id);
        }
        if(res.assigned){
          setAssigned(res.assigned?._id);
        }
        setUnitVehicle(res.unitVehicle);
        setYearOfManufacture(res.yearOfManufacture);
        if (res.make) {
          setCheckOK(true);
        }
      });
    }
  }, []);
  useEffect(() => {
    if (organisation.country) {
      if (organisation.country !== "GB") {
        setCheckOK(true);
      }
    }
  }, [organisation]);

  useEffect(() => {
    validate();
  }, [model, make, colour, member, unitVehicle]);
  useEffect(() => {
    if (unit_member) {
      setUnitVehicle(false);
      setMember(unit_member);
    }
    return () => {
      setUnitVehicle(true);
      setMember(null);
    };
  }, [unit_member]);

  const getData = () => {
    API.locations.all().then((res) => {
      setLocations(res);
    });
    API.member.all().then((res) => {
      setMembers(
        res.map((m) => {
          return {
            key: m._id,
            text: m.firstName + " " + m.lastName,
            value: m._id,
          };
        })
      );
    });
  };
  const checkReg = () => {
    if (regNumber !== "") {
      // const res = bob;
      toast.promise(
        API.vehicles.dvlaLookup(regNumber).then((res) => {
          // console.log(res);

          if (res.dateOfLastV5CIssued) {
            setMake(res.make);
            setFuelType(res.fuelType);
            setColour(res.colour);
            setTaxStatus(res.taxStatus);
            setMotStatus(res.motStatus);

            setTaxDue(
              res.taxDueDate ? new Date(res.taxDueDate) : ""
            );
            setMotExpiryDate(
              res.motExpiryDate ? new Date(res.motExpiryDate) : ""
            );
            setEngineCapacity(res.engineCapacity);
            setYearOfManufacture(res.yearOfManufacture);
            setCheckOK(true);
          }
          if (res.success === false) {
            setCheckOK(false);
          }
        }),
        {
          loading: "getting DVLA data...",
          success: "Data updated.",
          error: (err) => `This just happened: ${err.toString()}`,
        }
      );

      //   });
    }
  };
  const onCancel = () => {
    setMake("");
    setFuelType("");
    setColour("");
    setTaxDue("");
    setTaxStatus("");
    setMotStatus("");
    setMotExpiryDate("");
    setEngineCapacity("");
    setRegNumber("");
    setModel("");
    setSeats("");
    setTyres("");
    setDoors("");
    setTowWeight("");
    setCallsign("");
    setAssigned("");
    setMember(null);
    setUnitVehicle(true);
  };
  const onSubmit = () => {
    const data = {
      regNumber,
      make,
      model,
      fuelType,
      colour,
      taxDueDate,
      taxStatus,
      motStatus,
      motExpiryDate,
      engineCapacity,
      seats,
      tyres,
      doors,
      towWeight,
      member,
      unitVehicle,
      callsign,
      assigned,
      yearOfManufacture,
      insuranceExpiry,
    };
    const theErrors = validate(data);
    setErrors(theErrors);
    if (Object.keys(theErrors).length === 0) {
      vehicle_id
        ? API.vehicles.update(vehicle_id, data).then(() => {
            toast.success("Vehicle updated.");
            navigate(`/vehicles/${vehicle_id}`);
          }).catch(err => {
            toast.error(err.response.data.message);
        })
        : API.vehicles.addVehicle(data).then(() => {
            toast.success("Vehicle created.");
            navigate("/vehicles");
          }).catch(err => {
            toast.error(err.response.data.message);
        });
    }
  };
  const validate = () => {
    const errors = {};
    if (model === "") errors.model = true;
    if (make === "") errors.make = true;
    if (colour === "") errors.colour = true;
    if (unitVehicle === false && member === null) errors.member = true;
    setErrors(errors);
    return errors;
  };
  const handleDeleteVehicle = () => {
    API.vehicles.delete(vehicle_id).then(() => {
      toast.success("Vehicle deleted");
      navigate("/vehicles");
    });
  };

  return (
    <div className="w-full p-4">
      {deleteVehicle !== null && (
        <ConfirmBox
          show={true}
          title={"Delete selected vehicle"}
          onClose={() => setDeleteVehicle(null)}
          action={handleDeleteVehicle}
        />
      )}
      <div className="grid gap-4">
        <Box title="Vehicle Details" className="p-2 md:col-span-2">
          <div className="p-2">
            {organisation.country === "GB" && (
              <>
                <div className="p-2 text-xl text-primary dark:text-primary-dark">
                  Check the registration number first
                </div>
                <div className="ml-8  flex w-80 items-end gap-4">
                  <Input
                    uppercase
                    label="Vehicle Registration Number"
                    value={regNumber}
                    onChange={(e) => setRegNumber(e.target.value)}
                  />
                  <button
                    onClick={checkReg}
                    className="rounded p-2 dark:bg-blue-700"
                  >
                    Check
                  </button>
                </div>
              </>
            )}
            {checkOK && (
              <>
                <div className="p-2">
                  {organisation.country === "GB" && (
                    <div className="py-4 text-xl text-primary dark:text-primary-dark">
                      Add the rest of the details
                    </div>
                  )}
                  <div className="p-2">
                    <ToggleSwitch
                      label1={"Member Vehicle"}
                      label2={"Unit Vehicle"}
                      isChecked={unitVehicle}
                      onChange={() => {
                        setUnitVehicle(!unitVehicle);
                      }}
                    />
                  </div>
                  <div className="flex gap-4">
                    {!unitVehicle && (
                      <SelectZero
                        label="Member"
                        onChange={(e) => setMember(e.target.value)}
                        options={members}
                        value={member}
                        className="w-80 dark:bg-red-700"
                        error={errors.member}
                      />
                    )}
                    {unitVehicle && (
                      <div className="flex flex-wrap gap-4 p-2 lg:flex-nowrap">
                        <Input
                          label="Callsign"
                          value={callsign}
                          onChange={(e) => setCallsign(e.target.value)}
                          error={errors.callsign}
                        />
                        <div>
                          <Select
                            label={"Location"}
                            options={locations.map((item) => ({
                              key: item._id,
                              text: item.properties.name,
                              value: item._id,
                            }))}
                            value={assigned}
                            onChange={(e) => setAssigned(e.target.value)}
                          />
                        </div>
                      </div>
                    )}
                    {organisation.country !== "GB" && (
                      <Input
                        uppercase
                        label="Vehicle Registration Number"
                        value={regNumber}
                        onChange={(e) => setRegNumber(e.target.value)}
                      />
                    )}
                  </div>
                </div>
                <div className="flex flex-wrap gap-4 p-2 lg:flex-nowrap">
                <Input
                    label="Make"
                    value={make}
                    onChange={(e) => setMake(e.target.value)}
                    error={errors.make}
                  />
                  <Input
                    label="Model"
                    value={model}
                    onChange={(e) => setModel(e.target.value)}
                    error={errors.model}
                  />
                  <Input
                    label="Colour"
                    value={colour}
                    onChange={(e) => setColour(e.target.value)}
                    error={errors.colour}
                  />
                </div>
                <div className="flex flex-wrap gap-4 p-2 lg:flex-nowrap">
                  <Input
                    label="Engine"
                    value={engineCapacity}
                    onChange={(e) => setEngineCapacity(e.target.value)}
                  />
                  <Input
                    label="Tax Status"
                    value={taxStatus}
                    onChange={(e) => setTaxStatus(e.target.value)}
                  />
                  <div className="w-full">
                    <label
                      htmlFor={"date"}
                      className={`block dark:text-zinc-400`}
                    >
                      Tax Due
                    </label>
                    <DatePicker
                      onChange={(date) => {
                        setTaxDue(date);
                      }}
                      selected={taxDueDate !== "" ? new Date(taxDueDate) : ""}
                      dateFormat="dd-MM-yy"
                      // maxDate={new Date()}
                      nextMonthButtonLabel=">"
                      previousMonthButtonLabel="<"
                      className="w-full rounded border border-pp bg-white p-1 dark:border-zinc-500 dark:bg-zinc-700"
                      data-disable-touch-keyboard
                    />
                  </div>
                </div>
                <div className="flex flex-wrap gap-4 p-2 lg:flex-nowrap">
                  <Input
                    label="Seats"
                    value={seats}
                    onChange={(e) => setSeats(e.target.value)}
                  />
                  <Input
                    label="Doors"
                    value={doors}
                    onChange={(e) => setDoors(e.target.value)}
                  />
                  <Input
                    label="Tow Weight"
                    value={towWeight}
                    onChange={(e) => setTowWeight(e.target.value)}
                  />
                </div>
                <div className="flex flex-wrap gap-4 p-2 lg:flex-nowrap">
                  <Input
                    label="MoT Status"
                    value={motStatus}
                    onChange={(e) => setMotStatus(e.target.value)}
                  />
                  <div className="w-full ">
                    <label
                      htmlFor={"date"}
                      className={`block dark:text-zinc-400`}
                    >
                      MoT Expiry
                    </label>
                    <DatePicker
                      onChange={(date) => {
                        setMotExpiryDate(date);
                      }}
                      selected={
                        motExpiryDate !== "" ? new Date(motExpiryDate) : ""
                      }
                      dateFormat="dd-MM-yy"
                      // maxDate={new Date()}
                      nextMonthButtonLabel=">"
                      previousMonthButtonLabel="<"
                      className="w-full rounded border border-pp bg-white p-1 dark:border-zinc-500 dark:bg-zinc-700"
                      data-disable-touch-keyboard
                    />
                  </div>
                  <Select
                    label="Tyres"
                    options={tyreTypes}
                    value={tyres}
                    onChange={(e) => setTyres(e.target.value)}
                  />
                </div>
                <div className="flex flex-wrap gap-4 p-2 lg:flex-nowrap">
                  <Input
                    label="Year of Manufacture"
                    value={yearOfManufacture}
                    onChange={(e) => setYearOfManufacture(e.target.value)}
                  />
                  <div className="w-full">
                    <label
                      htmlFor={"date"}
                      className={`block dark:text-zinc-400`}
                    >
                      Insurance Expiry
                    </label>
                    <DatePicker
                      onChange={(date) => {
                        setInsuranceExpiry(date);
                      }}
                      selected={
                        insuranceExpiry ? new Date(insuranceExpiry) : ""
                      }
                      dateFormat="dd-MM-yy"
                      nextMonthButtonLabel=">"
                      previousMonthButtonLabel="<"
                      className="w-full rounded border border-pp bg-white p-1 dark:border-zinc-500 dark:bg-zinc-700"
                      data-disable-touch-keyboard
                    />
                  </div>
                </div>
                <div className="m-4 flex justify-between gap-4 border-t pt-4 dark:border-zinc-500">
                  <Button
                    text="Cancel"
                    onClick={
                      vehicle_id
                        ? () => navigate(`/vehicles/${vehicle_id}`)
                        : onCancel
                    }
                    colour={"nonOp border-red-800"}
                    hoverColour="hover:bg-red-900"
                  />
                  {vehicle_id && (
                    <Button
                      icon={<TrashIcon className="h-6" />}
                      onClick={() => setDeleteVehicle(true)}
                      colour="nonOp border-red-500"
                      hoverColour="hover:bg-red-900"
                    />
                  )}
                  <Button
                    text={vehicle_id ? "Update" : `Add`}
                    onClick={onSubmit}
                    colour="oncall border-green-500"
                    hoverColour=" hover:bg-green-900"
                  />
                </div>
              </>
            )}
            {checkOK !== null && checkOK === false && regNumber.length > 0 && (
              <div className="p-4 text-xl text-red-400">
                Can't find that registration number
              </div>
            )}
          </div>
        </Box>
      </div>
    </div>
  );
}
export default ChainedContext(AddVehicle, [
  [SettingsReceiver, "settings"],
  [OrganisationReceiver, "organisation"],
]);
