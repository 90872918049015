import React, { useEffect, useState, useMemo } from "react";
import DayJS from "dayjs";
import { useNavigate } from "react-router-dom";
import { Skeleton } from "../../../components/ui/skeleton";
import Box from "../../../components/common/Box";
import API from "../../../api";
import Modal from "../../../components/common/Modal";
import CompSessionForm from "./_components/CompSessionForm";
import {Authentication} from "../../../lib/_sso";

const Dashboard = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [showAdd, setShowAdd] = useState(false);
  const [collections, setCollections] = useState([]);
  const [competencies, setCompetencies] = useState([]);
  const [filter, setFilterValue] = useState("");

  useEffect(() => {
    API.training.competencies
      .getCollections()
      .then((res) => {
        let compTypes  = [];

        res.forEach(a => {
          if(compTypes.filter(b => b._id === a.competency._id).length === 0){
            if(a.competency){
              compTypes.push(a.competency);
            }
          }
        })

        setCompetencies(compTypes);
        setCollections(res)
      })
      .catch((err) => {
        //TODO
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const sortedList = useMemo(() => {
    return collections.toSorted((a, b) => {
      if(a.startAt > b.startAt) return -1;
      if(a.startAt < b.startAt) return 1;
      return 0;
    }).filter(a => filter === "" || filter === a.competency?._id);
  }, [filter, collections]);

  const handleCreateSession = data => {
    return API.training.competencies.createCollections(data).then((res) => {
      navigate("session/" + res._id);
    });
  }

  return (
    <div className="w-full space-y-2 p-4">
      {showAdd && <Modal show={true} title={"Create Session"} onClose={() => setShowAdd(false)} disableOutsideClick className={"max-w-5xl"}>
        <CompSessionForm onSubmit={handleCreateSession} onCancel={() => setShowAdd(false)} />
      </Modal>}

      <Box title="Filters">
        <div className="p-2 flex flex-wrap gap-2 text-sm">
          {competencies.toSorted((a, b) => {
            if(a.name > b.name) return 1;
            if(a.name < b.name) return -1;
            return 0;
          }).map(item => {
            return (
              <button
                key={item._id}
                onClick={() => setFilterValue(item._id)}
                className={`rounded border p-2 ${filter === item._id ? "bg-green-300 dark:bg-green-700" : "border-zinc-500"} hover:border-zinc-400`}
              >
                {item.name}
              </button>
            );
          })}
          {competencies.length === 0 && <div>
            No items to display
          </div>}
          {filter !== "" && (
            <button
              onClick={() => setFilterValue("")}
              className={`cursor-pointer rounded bg-red-400 p-2 text-white `}
            >
              Clear
            </button>
          )}
        </div>
      </Box>

      <Box
        title="Competency Session"
        button={Authentication.can("competency:session:write") ? {
          action: () => {
            navigate("/events/picker?eventType=competency");
            // setShowAdd(true)
          },
          text: "Create Session",
          colour: "text-white hover:text-gray-200",
        } : null}
      >
        {isLoading ? (
          <div className="space-y-6 p-4">
            <div className="flex gap-8">
              <Skeleton className="h-8 w-1/3 dark:bg-zinc-700"></Skeleton>
              <Skeleton className="h-8 w-1/3 dark:bg-zinc-700"></Skeleton>
              <Skeleton className="h-8 w-1/3 dark:bg-zinc-700"></Skeleton>
            </div>
            <div className="flex gap-8">
              <Skeleton className="h-8 w-1/2 dark:bg-zinc-700"></Skeleton>
              <Skeleton className="h-8 w-1/2 dark:bg-zinc-700"></Skeleton>
            </div>
          </div>
        ) : (
          <table className="tableClass">
            <thead>
              <tr className="tableHeadRowClass">
                <th className="tableHeadCellClass">Competency</th>
                <th className="tableHeadCellClass">Start</th>
                <th className="tableHeadCellClass">End</th>
                <th className="tableHeadCellClass">Expiry Date</th>
                <th className="tableHeadCellClass">Total Attending</th>
              </tr>
            </thead>
            <tbody className="tableBodyClass">
              {sortedList.length ? (
                sortedList.map((collection) => {
                  return (
                    <tr
                      key={collection._id}
                      onClick={() => navigate("session/" + collection._id)}
                      className={`tableBodyRowClass cursor-pointer`}
                    >
                      <td className="tableBodyCellClass">
                        {collection.competency?.name || "[unknown]"}
                      </td>
                      <td className="tableBodyCellClass">
                        {DayJS(collection.startAt).format("DD-MM-YYYY HH:mm")}
                      </td>
                      <td className="tableBodyCellClass">
                        {DayJS(collection.finishAt).format("DD-MM-YYYY HH:mm")}
                      </td>
                      <td className="tableBodyCellClass">
                        {DayJS(collection.expiryDate).format("DD-MM-YYYY")}
                      </td>
                      <td className="tableBodyCellClass">
                        {collection.attending.length}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={5} className={"tableBodyCellClass"}>
                    There are no records to display
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </Box>
    </div>
  );
};
export default Dashboard;
