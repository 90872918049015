import {Dialog, Transition} from "@headlessui/react";
import React from "react";
import {XMarkIcon} from "@heroicons/react/24/outline";

const Model = ({ show = true, onClose = () => {}, disableOutsideClick = false, className = "max-w-2xl", children, title }) => {

  return (
    <Transition
      show={show}
      enter="transition duration-100 ease-out"
      enterFrom="transform scale-95 opacity-0"
      enterTo="transform scale-100 opacity-100"
      leave="transition duration-75 ease-out"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-95 opacity-0"
    >
      <Dialog
        open={show}
        onClose={() => !disableOutsideClick ? onClose() : null}
        className="fixed inset-0 z-50 overflow-y-auto"
      >
          <div className="min-h-screen text-center">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-80" />
            <span className="h-screen" aria-hidden="true">&#8203;</span>
            <div className={`my-8 inline-block w-full transform rounded-2xl border-4 border-gray-700 bg-zinc-200 p-6 text-left align-middle shadow-xl transition-all dark:bg-zinc-800 ${className}`}>
              <div className="absolute right-0 top-0 pt-2">
                <button
                  type="button"
                  className="ml-1 flex h-10 w-10 items-center justify-center rounded-full z-50"
                  onClick={() => {
                    onClose();
                  }}
                >
                  <span className="sr-only">Close sidebar</span>
                  <XMarkIcon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </button>
              </div>
              {title !== undefined && <div className="border-b border-zinc-500 pt-4 text-2xl"><Dialog.Title>{title}</Dialog.Title></div>}
              {children}
            </div>
        </div>

      </Dialog>
    </Transition>
  );
};
export default Model;