import React, { useEffect, useState, useCallback } from "react";
import DayJS from "dayjs";
import API from "../../../api";
import CommonFunctions from "../../../lib/CommonFunctions";
import Box from "../../../components/common/Box";

const RequirementsOverviewTable = ({ requirements }) => {
  const [reportResults, setReportResults] = useState([]);

  useEffect(() => {
    API.reports.requirements.getOverview().then((res) => {
      setReportResults(res.toSorted((a, b) => {
        if(a.member?.lastName > b.member?.lastName) return 1;
        if(a.member?.lastName < b.member?.lastName) return -1;
        return 0;
      }));
    });
  }, [requirements]);

  const handleCsvExport = useCallback(() => {
    const header = [
      { csv_field: "Callsign", database_field: "callsign" },
      { csv_field: "Name", database_field: "name" },
    ];
    requirements.forEach((requirement) => {
      header.push({
        csv_field: requirement.title,
        database_field: requirement._id,
      });
    });

    const body = [];
    reportResults.forEach((item) => {
      let data = {
        callsign: item.member?.callsign,
        name: item.member?.firstName + " " + item.member?.lastName,
      };

      requirements.forEach((requirement) => {
        data[requirement._id] = "[N/A]";

        const memberResult = item.requirements.filter(
          (a) => a._id === requirement._id
        );
        if (memberResult[0] !== undefined) {
          if (memberResult[0].result !== false) {
            data[requirement._id] = DayJS(memberResult[0].result).format("DD-MM-YYYY");
          }
        }
      });

      body.push(data);
    });

    CommonFunctions.generateCSV("Requirements Overview", header, body);
  }, [requirements, reportResults]);

  return (
    <Box
      title="Overview Report"
      button={{
        text: "CSV Export",
        colour: "text-sm text-white hover:text-gray-200",
        action: handleCsvExport,
      }}
    >
      <table className="tableClass">
        <thead>
          <tr className="tableHeadRowClass">
            <th className="tableHeadCellClass">Callsign</th>
            <th className="tableHeadCellClass">Name</th>
            {requirements.map((requirement) => {
              return (
                <th key={requirement._id} className="tableHeadCellClass">
                  {requirement.title}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className="tableBodyClass">
          {reportResults.map((result) => {
            return (
              <tr key={result.member._id} className={`tableBodyRowClass`}>
                <td className="tableBodyCellClass">
                  {result.member?.callsign}
                </td>
                <td className="tableBodyCellClass">
                  {result.member?.firstName} {result.member?.lastName}
                </td>
                {requirements.map((requirement) => {
                  let res = "[N/A]";
                  let resColor = "bg-red-200 dark:bg-red-900";
                  const memberResult = result.requirements.filter(
                    (a) => a._id === requirement._id
                  );
                  if (memberResult[0] !== undefined) {
                    if (memberResult[0].result !== false) {
                      res = DayJS(memberResult[0].result).format("DD-MM-YYYY");
                      if (DayJS(memberResult[0].result).isAfter(DayJS())) {
                        resColor = "bg-green-200 dark:bg-green-900";
                      }
                    }
                  }
                  return (
                    <th
                      key={requirement._id}
                      className={`tableHeadCellClass text-center ${resColor}`}
                    >
                      {res}
                    </th>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </Box>
  );
};
export default RequirementsOverviewTable;
