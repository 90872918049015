import React, { useState, useEffect } from "react";
import Box from "../../../../components/common/Box";
import Modules from "./Modules";
import ChainedContext from "../../../../contexts/chainedContext";
import MemberReceiver from "../../../../contexts/member_context/Member_Receiver";
import API from "../../../../api";

const CompList = ({ member, comp }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [compSessionLatest, setLatestSession] = useState([]);

  useEffect(() => {
    API.member.competencies
      .latest_session(member._id, comp._id)
      .then((res) => {
        setLatestSession(res);
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  }, [member, comp]);

  return (
    <div className="space-y-2">
      <Box title={comp.name}>
        {/* <div className="p-2">{comp.description}</div> */}
        <div className="p-2">
          {comp.children.map((m) => {
            return (
              <div key={m._id} className="w-full p-2">
                <Modules data={m} results={compSessionLatest} />
              </div>
            );
          })}
        </div>
      </Box>
    </div>
  );
};
export default ChainedContext(CompList, [[MemberReceiver, "member"]]);

// const Modules = (m) => {
//   return <div className="p-2">{m.name}</div>;
// };

// <table className=" min-w-full border-separate divide-y divide-gray-200 ">
//             <thead className="bg-gray-50 dark:bg-zinc-700 dark:text-white">
//               <tr className=" text-sm uppercase ">
//                 <th className="">Name</th>
//                 <th className="">Description</th>
//                 <th className="">Competency</th>
//                 <th className="">Order</th>
//                 <th className=""></th>
//               </tr>
//             </thead>
//             <tbody className="divide-y divide-gray-200 bg-gray-100 dark:bg-zinc-600">
//               {/* {dataList &&
//                 dataList.map((d) => {
//                   return (
//                     <tr className=" border-b border-gray-300 py-4 " key={d._id}>
//                       <td className="py-2 pl-2 text-lg ">{d.name} </td>
//                       <td className=" py-2 pl-2  text-lg ">{d.description}</td>
//                       <td className=" py-2 pl-2 text-center ">
//                         <div className="flex  gap-2">{d.module?.name}</div>
//                       </td>
//                       <td className="w-20 py-2 pl-2 text-center ">
//                         {d.sortOrder}
//                       </td>
//                       <td className="w-32 space-x-1 pr-2 pt-2 text-center">
//                         <button
//                           // disabled={d.isAvailableDefault || d.isAssignedDefault}
//                           onClick={() => handleEdit(d)}
//                           className="rounded-md border bg-primary bg-opacity-80  px-2 py-1 text-sm  text-white hover:bg-gray-800 disabled:opacity-20"
//                         >
//                           <PencilIcon className="h-6 w-6 text-yellow-200 " />
//                         </button>
//                         <button
//                           className="rounded-md border border-red-400 bg-red-700 bg-opacity-80 px-2 py-1 text-sm text-white hover:border-red-300 hover:bg-red-800 disabled:opacity-20"
//                           onClick={() => {
//                             setDelete(d);
//                             setIsOpen(true);
//                           }}
//                         >
//                           <TrashIcon className="h-6 w-6 text-red-200" />
//                         </button>
//                       </td>
//                     </tr>
//                   );
//                 })} */}
//             </tbody>
//           </table>
