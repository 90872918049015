import React, { useState, useEffect } from "react";
import api from "../../../api";
import ChainedContext from "../../../contexts/chainedContext";
import MemberReceiver from "../../../contexts/member_context/Member_Receiver";
import SettingsReceiver from "../../../contexts/settings_context/Settings_Receiver";
import OrganisationReceiver from "../../../contexts/organisation/receiver";
import CompList from "./_components/CompList";
import { Skeleton } from "../../../components/ui/skeleton";
import Box from "../../../components/common/Box";

function Competencies({ member }) {
  const [roles, setRoles] = useState([]);
  const [roleName, setRoleName] = useState(null);
  const [comps, setComps] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getData();
  }, [member]);

  const getData = () => {
    api.member.competencies.getRoles(member._id).then((res) => {
      setRoles(res);
      setLoading(false);
    });
  };
  const getComps = (data, rn) => {
    setRoleName(rn);
    setComps([]);
    Promise.all(
      data.map((c) => {
        return api.settings.getComp(c._id);
      })
    ).then((res) => {
      setComps(res);
    });
  };
  console.log(roles);
  return (
    <div className="space-y-4 p-4">
      <Box title={`${member.firstName}'s roles requiring competency`}>
        {loading && (
          <div className="space-y-6 p-4">
            <div className="flex gap-8">
              <Skeleton className="h-20 w-28 p-4 dark:bg-zinc-700"></Skeleton>
              <Skeleton className="h-20 w-28 p-4 dark:bg-zinc-700"></Skeleton>
              <Skeleton className="h-20 w-28 p-4 dark:bg-zinc-700"></Skeleton>
              <Skeleton className="h-20 w-28 p-4 dark:bg-zinc-700"></Skeleton>
              <Skeleton className="h-20 w-28 p-4 dark:bg-zinc-700"></Skeleton>
              <Skeleton className="h-20 w-28 p-4 dark:bg-zinc-700"></Skeleton>
            </div>
          </div>
        )}
        {!loading && (
          <div className="flex gap-2 flex-wrap p-2">
            {roles.filter(a => a.roles !== null).toSorted((a, b) => {
              if(a.role.name > b.role.name) return 1;
              if(a.role.name < b.role.name) return -1;
              return 0;
            }).map((r, i) => {
              return (
                <button
                  key={i}
                  onClick={() => getComps(r.comps, r.role.name)}
                  className={`rounded p-4 ${
                    r.isActive ? "oncall" : "nonOp"
                  } border dark:border-zinc-500 `}
                >
                  {r.role.name}
                </button>
              );
            })}
            {roles.length === 0 && (
              <div className="p-4 text-xl">No roles to display</div>
            )}
          </div>
        )}
      </Box>
      {roleName && (
        <div className="p-2">
          {comps.length === 0 && (
            <div className={"text-red-500"}>
              There are no competencies for role "{roleName}" to display.
            </div>
          )}
          {comps.map((c) => {
            return <CompList key={c._id} comp={c} />;
          })}
        </div>
      )}
    </div>
  );
}
export default ChainedContext(Competencies, [
  [MemberReceiver, "member"],
  [SettingsReceiver, "settings"],
  [OrganisationReceiver, "organisation"],
]);
