import React, { useState, useEffect } from "react";
import { Authentication } from "../../../lib/_sso";
import api from "../../../api";
import Box from "../../../components/common/Box";
import Layout from "../../../components/common/Layout";
import { useNavigate } from "react-router-dom";

export default function SupplierDash() {
  const navigate = useNavigate();

  const [suppliers, setSuppliers] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    api.assets.suppliers.all().then((res) => {
      setSuppliers(res);
    });
  };

  return (
    <Layout full>
      <Box
        title="Suppliers"
        button={
          {
            text: "Add Supplier",
            colour: "text-zinc-300 hover:bg-primary hover:text-white ",
            action: () => navigate(`/asset/suppliers/add`),
          }
        }
      >
        <div className="w-full">
          {/*<div className="p-2">Filter bar</div>*/}
          <table className="tableClass">
            <thead>
              <tr className="thrc">
                <th className="thcc">Name</th>
                <th className="thcc ">Primary Contact</th>
                <th className="thcc">Type</th>
              </tr>
            </thead>
            <tbody className="tbc">
              {suppliers.sort((a, b) => {
                if(a.name > b.name) return 1;
                if(a.name < b.name) return -1;
                return 0;
              }).map((a) => {
                let contact = [];
                if (a.contacts) {
                  contact = a.contacts.filter((contact) => {
                    return contact.primary;
                  });
                }
                return (
                  <tr
                    key={a._id}
                    className={`tbrc cursor-pointer`}
                    onClick={() => navigate(`/asset/suppliers/${a._id}`)}
                  >
                    <td className="tbcc">{a.name}</td>
                    <td className="tbcc">
                      {contact[0] ? (
                        <span>
                          {contact[0].name ? contact[0].name : "--"}
                          <span className="cellTitle"> Phone: </span>
                          {contact[0].phone ? contact[0].phone : "--"}
                          <br />
                          <span className="cellTitle"> Email: </span>
                          {contact[0].email ? contact[0].email : "--"}
                        </span>
                      ) : (
                        "--"
                      )}
                    </td>
                    <td className="tbcc text-right">
                      {a.type.sort((a, b) => {
                        if(a > b) return 1;
                        if(a < b) return -1;
                        return 0;
                      }).map((t, i) => {
                        return (
                          <span
                            key={i}
                            className="m-1 rounded-lg border border-gray-700 p-2 dark:bg-gray-800"
                          >
                            {t}
                          </span>
                        );
                      })}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Box>
    </Layout>
  );
}
