import React, { useState, useEffect } from "react";

import Input from "../../../components/common/Input";
import Select from "../../../components/common/Select";
import DOB from "../../../components/common/DOB";
import api from "../../../api";
import dayjs from "dayjs";
import DatePicker from "react-datepicker";
import ChainedContext from "../../../contexts/chainedContext";
import SettingsReceiver from "../../../contexts/settings_context/Settings_Receiver";
import ToggleSwitch from "../../../components/common/ToggleSwitch";

const RecruitForm = ({ onSubmit, onCancel, isEdit, recruit = {}, settings }) => {
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    nickName: "",
    phone: "",
    phoneSecondary: "",
    email: "",
    dob: "",
    intake: "",
    source: "",

    isArchived: false,
    keepAccount: false,
  });
  const [intakes, setIntakes] = useState([]);

  useEffect(() => {
    getIntakes();
  }, []);
  useEffect(() => {
    if(recruit !== undefined && recruit._id !== undefined){
      setData(recruit);
    }
  }, [recruit]);

  const onChange = (e) => {
    setData((prevState) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  };
  const getIntakes = () => {
    api.recruitment.getIntakes().then((res) => {
      setIntakes(res);
    });
  };
  const clearForm = () => {
    setData({
      firstName: "",
      lastName: "",
      nickName: "",
      phone: "",
      phoneSecondary: "",
      email: "",
      dob: "",
      intake: "",
      source: "",
      isArchived: false,
      keepAccount: false,
    });
  };
  const sendData = () => {
    onSubmit({
      ...data,
      intake: data.intake === "" ? null : data.intake,
    }).then(() => {
      clearForm();
    });
  };

  return (
    <div>
      <div className="grid gap-2 sm:grid-cols-1">
        <div className="space-y-3 p-2 text-zinc-400 sm:col-span-1">
          <div className="flex flex-wrap items-center gap-2 md:flex-nowrap">
            <div className="w-full">
              <Input
                label="First name"
                field={"firstName"}
                value={data.firstName}
                onChange={onChange}
              />
            </div>
            <div className=" w-full">
              <Input
                label="Last Name"
                field={"lastName"}
                value={data.lastName}
                onChange={onChange}
              />
            </div>
            <div className="w-full">
              <Input
                label="Nick Name"
                field={"nickName"}
                value={data.nickName}
                onChange={onChange}
              />
            </div>
          </div>
          <div className="flex items-center gap-2">
            <Input
              label="Phone"
              field={"phone"}
              value={data.phone}
              onChange={onChange}
            />
            <Input
              label="Secondary Phone"
              field={"phoneSecondary"}
              value={data.phoneSecondary}
              onChange={onChange}
            />
            <Input
              label="Email"
              field={"email"}
              value={data.email}
              onChange={onChange}
            />
          </div>

          <div className="flex items-center gap-2 md:w-1/3">
            <DOB date={data.dob} submit={(d) => setData({ ...data, dob: d })} />
          </div>

          <div className="flex flex-wrap items-center gap-2 md:flex-nowrap">
            <Select
              label="Source"
              field="source"
              options={settings && settings.source && settings.source.map((s, i) => {
                return {
                  key: i,
                  text: s,
                  value: s,
                };
              })}
              value={data.source}
              onChange={onChange}
            />
            <Select
              label="Intake"
              options={
                intakes &&
                intakes.toSorted((a, b) => {
                  if (a.start < b.start) return 1;
                  if (a.start > b.start) return -1;
                  return 0;
                }).map((intake) => {
                  return {
                    key: intake._id,
                    text: intake.ref + " " + intake.type + " " + dayjs(intake.start).format("DD-MM-YYYY"),
                    value: intake._id,
                  };
                })
              }
              dontSort={true}
              field="intake"
              value={data.intake}
              onChange={onChange}
            />
            <div className="w-full ">
              <label htmlFor={"date"} className={`block   dark:text-zinc-400 `}>
                Enquired
              </label>
              <DatePicker
                onChange={(date) => {
                  setData((prevState) => {
                    return {...prevState, enquired: date};
                  });
                }}
                selected={data.enquired ? new Date(data.enquired) : ""}
                dateFormat="dd-MM-yy"
                maxDate={new Date()}
                nextMonthButtonLabel=">"
                previousMonthButtonLabel="<"
                className=" w-full rounded border border-pp bg-white p-1 dark:border-zinc-500  dark:bg-zinc-700"
                data-disable-touch-keyboard
              />
            </div>
            {isEdit && <div className="w-full">
              <div className="">
                <label
                  htmlFor={"date"}
                  className={`mb-1 block  dark:text-zinc-400 `}
                >
                  Archive recruit
                </label>
                <ToggleSwitch
                  isChecked={data.isArchived}
                  label1={"No"}
                  label2={"Yes"}
                  onChange={() => {
                    setData({
                      ...data,
                      isArchived: !data.isArchived,
                    });
                  }}
                />
              </div>
              {data.isArchived && (
                <div className="">
                  <label
                    htmlFor={"date"}
                    className={`mb-1 block  dark:text-zinc-400 `}
                  >
                    Keep the account?
                  </label>
                  <ToggleSwitch
                    isChecked={data.keepAccount}
                    label1={"No"}
                    label2={"Yes"}
                    onChange={() => {
                      setData({
                        ...data,
                        keepAccount: !data.keepAccount,
                      });
                    }}
                  />
                </div>
              )}
            </div>}
          </div>
        </div>
      </div>
      <div className="mt-4 flex justify-between border-t border-zinc-700 p-4">
        <button onClick={onCancel} className="nonOp rounded p-2">
          Cancel
        </button>
        <button onClick={sendData} className="oncall rounded p-2">
          {isEdit ? "Update" : "Create"}
        </button>
      </div>
    </div>
  );
}
export default ChainedContext(RecruitForm, [
  [SettingsReceiver, "settings"]
]);