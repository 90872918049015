import React, { useMemo } from "react";
import {XYZ} from "ol/source";
import CustomTileLayer from "../custom";
import TileGrid from "ol/tilegrid/TileGrid";
import OSLogo from "../../../../mapping/ol/os-logo.png";

const tileGrid = new TileGrid({
  resolutions: [896.0, 448.0, 224.0, 112.0, 56.0, 28.0, 14.0, 7.0, 3.5, 1.75],
  origin: [-238375.0, 1376256.0],
});
const OS_Local = ({ visible = true, zIndex = 0}) => {

  const source = useMemo(() => new XYZ({
    url: "https://tiles.sarstuff.net/maps/ordnance_survey/Leisure_27700/{z}/{x}_{y}.png",
    projection: "EPSG:27700",
    tileGrid,
    attributions: [
      '© <a href="https://www.ordnancesurvey.co.uk/" target="_blank">Ordnance Survey</a>',
    ],
    attributionsCollapsible: false,
  }), []);

  return (
    <>
      <CustomTileLayer source={source} visible={visible} zIndex={zIndex} />
      {visible && (
        <img
          src={OSLogo}
          className="OSLogo absolute z-50 left-2 bottom-12 w-28"
          alt={"OS Logo"}
        />
      )}
    </>
  );
}
export default OS_Local;