import { APIRequest } from "../lib/_sso";
import AppConfig from "../_appConfig";

const server = AppConfig.api_server;

const competencies = {
  focusReport: (compID, sectionID) =>
    APIRequest.get(
      `${server}/training/competencies/focus/comp/${compID}/section/${sectionID}`
    ).then((res) => res.data),

  getCollections: () =>
    APIRequest.get(`${server}/training/competencies/session`).then(
      (res) => res.data
    ),
  createCollections: (data) =>
    APIRequest.post(`${server}/training/competencies/session`, data).then(
      (res) => res.data
    ),
  getCollection: (collectionId) =>
    APIRequest.get(
      `${server}/training/competencies/session/${collectionId}`
    ).then((res) => res.data),
  updateCollection: (collectionId, data) => APIRequest.patch(
    `${server}/training/competencies/session/${collectionId}`,
    data
  ).then(res => res.data),
  deleteCollection: (collectionId) => APIRequest.delete(
    `${server}/training/competencies/session/${collectionId}`
  ),
  getGroupedMembersForCollection: (collectionId) =>
    APIRequest.get(
      `${server}/training/competencies/session/${collectionId}/members`
    ).then((res) => res.data),
  markAttending: (memberId, collectionId) =>
    APIRequest.patch(
      `${server}/training/competencies/session/${collectionId}/member/${memberId}/attendance`,
      { isAttending: true }
    ),
  markNotAttending: (memberId, collectionId) =>
    APIRequest.patch(
      `${server}/training/competencies/session/${collectionId}/member/${memberId}/attendance`,
      { isAttending: false }
    ),
  memberScores: (memberId, collectionId) =>
    APIRequest.get(
      `${server}/training/competencies/session/${collectionId}/member/${memberId}/scores`
    ).then((res) => res.data),
  updateMemberScores: (memberId, collectionId, data) =>
    APIRequest.patch(
      `${server}/training/competencies/session/${collectionId}/member/${memberId}/scores`,
      data
    ).then((res) => res.data),
  assignMemberMissingRoles: (memberId, collectionId) => APIRequest.post(`${server}/training/competencies/session/${collectionId}/member/${memberId}/assign_missing_roles`, {}).then((res) => res.data)
};

export default {
  dashboardTraining: () => APIRequest.get(`${server}/training/dashboard_training`).then(res => res.data),

  competencies,
};
