import {ArrowsUpDownIcon} from "@heroicons/react/20/solid";
import DayJS from "dayjs";
import React, {useState, useEffect, useMemo} from "react";
import Box from "../../components/common/Box";
import { Skeleton } from "../../components/ui/skeleton";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import api from "../../api";

export default function ActiveRecruits() {
  const [loading, setLoading] = useState(true);
  const [recruits, setRecruits] = useState([]);
  const [sortBy, setSortBy] = React.useState("surname"); // surname, firstname, callsign

  const navigate = useNavigate();

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    api.recruitment.getActiveRecruits().then((res) => {
      setRecruits(res);
      setLoading(false);
    });
  };

  const handleSortToggle = () => {
    switch (sortBy) {
      case "surname":
        setSortBy("firstname");
        break;
      case "firstname":
        setSortBy("callsign");
        break;
      case "callsign":
        setSortBy("none");
        break;
      default:
        setSortBy("surname");
        break;
    }
  }

  const sortedList = useMemo(() => {
    if (recruits === undefined || recruits.length === 0) return [];

    return recruits.toSorted((a, b) => {
      if (sortBy === "surname") {
        if ((a.lastName || "") < (b.lastName || "")) return -1;
        if ((a.lastName || "") > (b.lastName || "")) return 1;
      }
      if (sortBy === "firstname") {
        if ((a.firstName || "") < (b.firstName || "")) return -1;
        if ((a.firstName || "") > (b.firstName || "")) return 1;
      }
      if (sortBy === "callsign") {
        if ((a.callsign || "zzzzzzzzzzzzzzzz") < (b.callsign || "zzzzzzzzzzzzzzzz")) return -1;
        if ((a.callsign || "zzzzzzzzzzzzzzzz") > (b.callsign || "zzzzzzzzzzzzzzzz")) return 1;
      }

      return 0;
    })
  }, [recruits, sortBy]);

  return (
    <div className="w-full p-4">
      <Box
        title="Active Recruits"
        button={{
          text: "Add",
          colour: " hover:bg-primary hover:text-white ",
          action: () => {
            navigate("/recruitment/recruits/add");
          },
        }}
      >
        {loading && (
          <div className="grid grid-cols-1 gap-4 p-2">
            <div className="space-y-4">
              <Skeleton className="h-10 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
              <Skeleton className="h-10 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
              <Skeleton className="h-10 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
            </div>
          </div>
        )}
        {!loading && (
          <table className="lg:text-md min-w-full table-auto border-separate divide-y divide-gray-200  text-sm">
            <thead>
            <tr className="bg-gray-300 text-xs uppercase tracking-wider text-gray-900 dark:bg-zinc-700 dark:text-white">
              <th
                className="flex gap-1 px-4 py-3 text-left"
                 onClick={() => handleSortToggle()}
              >
                Name
                {sortBy !== "none" ? (
                  <><ArrowsUpDownIcon className="h-4 text-green-500"/> <span className={"text-xs lowercase"}>[{sortBy}]</span></>
                ) : (
                  <ArrowsUpDownIcon className="h-4"/>
                )}
              </th>
              <th className="px-4 py-3 text-left">Contact Info</th>
              <th className="px-4 py-3 text-left">Source</th>
              <th className="px-4 py-3 text-left">Intake</th>
              <th className="px-4 py-3 text-left">Last Note</th>
            </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 text-left">
              {sortedList.length !== 0 ? (
                sortedList.map((u) => {
                  let lastNote = false;
                  if(u.notes !== undefined){
                    if(u.notes.length > 0){
                      lastNote = u.notes[u.notes.length - 1]
                    }
                  }
                  return (
                    <tr
                      key={u._id}
                      onClick={() => navigate(`/recruitment/recruits/${u._id}`)}
                      className={`cursor-pointer hover:bg-gray-300 dark:hover:bg-zinc-600`}
                    >
                      <td className="text-md border border-zinc-300 p-2 dark:border-zinc-500">
                        <span className="text-lg">
                          {u.firstName} {u.lastName}
                        </span>
                        <br/>
                        <span className="text-zinc-400 dark:text-zinc-300">
                          {u.callsign && "(" + u.callsign + ")"}
                        </span>
                      </td>
                      <td className="border border-zinc-300 p-2 dark:border-zinc-500">
                        {u.phone ? u.phone : "--"}
                        <br/>
                        {u.email}
                      </td>
                      <td className="border border-zinc-300 p-2 dark:border-zinc-500">
                        {u.source || "--"}
                      </td>
                      <td className="border border-zinc-300 p-2 dark:border-zinc-500">
                        {u.intake ? <>
                          {u.intake?.ref} {u.intake?.type} {dayjs(u.intake?.start).format("DD-MM-YYYY")}
                        </> : <>--</>}
                      </td>
                      <td className="border border-zinc-300 p-2 dark:border-zinc-500">
                        {lastNote === false? "--" : (
                          <>
                            <div>Date: {DayJS(lastNote.date).format("DD-MM-YYYY HH:mm")}</div>
                            <div>By: {lastNote.loggedBy}</div>
                            <div className={"mt-2"}>{lastNote.message}</div>
                          </>
                        )}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={3}>No members</td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </Box>
    </div>
  );
}