import React, { useState, useMemo, useCallback } from "react";
import { Map, Layers } from "../sarstuff_mapping";
import * as MapStyles from "../mapping_styles";
import MappingDefaults from "../mapping_defaults";
import ListLocations from "./listLocations";
import LocationForm from "./locationForm";
import ChainedContext from "../../contexts/chainedContext";
import OrganisationReceiver from "../../contexts/organisation/receiver";
import UseWindowDimensions from "../../components/UseWindowDimensions";
import CommonFunctions from "../../lib/CommonFunctions";
import SettingsReceiver from "../../contexts/settings_context/Settings_Receiver";
import Box from "../common/Box";

function LocationManager({
  organisation,
  locations,
  handleAddLocation,
  handleEditLocation,
  handleDeleteLocation,
  handleOrderChange,
  onLocationClick = null,
  settings,
  showAddInitial = false,
  hideExtra = true,
  hideOrdering = false,
  heightMinus = 100,
  miniMode = false,
  canEdit = true,
  canAdd = true,
  canDelete = true,
  onLocationChange,
}) {
  const [showAdd, setShowAdd] = useState(showAddInitial);
  const [location, setLocation] = useState(null);
  const [allowMapClick, setAllowMapClick] = useState(false);
  const mapCenter = useMemo(() => organisation.defaultMapCenter, [organisation]);

  /** Required to auto-scale the map view to the full height of the view port */
  const { height } = UseWindowDimensions();

  /**
   * To prevent unnecessary re-render of child components we are using the useCallback to return a
   * static memorised callback function that is only updated if the prop 'job' is updated as we depend on it.
   *
   * This case it's for handling the logic that will decide if the mapOnClick will update the location
   */
  const handleFormTypeChange = useCallback((formType) => {
    if (formType === "Map Click") {
      setAllowMapClick(true);
    } else {
      setAllowMapClick(false);
    }
  }, []);

  /**
   * To prevent unnecessary re-render of child components we are using the useCallback to return a
   * static memorised callback function that is only updated if the prop 'job' is updated as we depend on it.
   *
   * This case it's for handling the logic clear out the single location and reset the form to hide it when the
   * user clicked the 'cancel' button
   */
  const handleFormCancel = useCallback(() => {
    setShowAdd(false);
    setLocation(null);
  }, []);

  /**
   * To prevent unnecessary re-render of child components we are using the useCallback to return a
   * static memorised callback function that is only updated if the prop 'job' is updated as we depend on it.
   *
   * This case it's for handling the logic when the map is clicked only if allowMapClick is true
   */
  const handleMapClick = useCallback(
    async (latLng) => {
      if (!allowMapClick) return;

      const grid = CommonFunctions.getGrid(
        latLng[1],
        latLng[0],
        settings.gridType
      ); // lat, lng
      const w3w = await CommonFunctions.getW3w(latLng[1], latLng[0]);
      setLocation({
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: latLng,
        },
        properties: {
          ...(location?.properties || {}),
          w3w: w3w !== null ? w3w.split(".") : [],
          grid: grid.toUpperCase(),

          name: location?.properties?.name
            ? location?.properties?.name
            : grid.toUpperCase(),
          address: "",
          town: "",
          postcode: "",
        },
      });
    },
    [allowMapClick]
  );

  /**
   * This creates a memorised object that is only updated when prop 'locations' is updated.
   * Returns a GeoJSON FeatureCollection of the current locations we are displaying in the list
   */
  const locationsGeoJSON = useMemo(() => {
    return locations?.filter((a) =>
      location?._id !== undefined ? location._id !== a._id : true
    );
  }, [location, locations]);

  if (miniMode) {
    return (
      <div>
        <LocationForm
          miniMode
          locations={locations}
          location={location}
          setLocation={setLocation}
          onFormTypeChange={handleFormTypeChange}
          handleCancel={handleFormCancel}
          handleSubmit={(data) => {
            onLocationChange(data);
            setLocation(null);
          }}
          hideExtra={hideExtra}
        />

        <Map height={"50vh"} center={mapCenter} onMapClick={handleMapClick}>
          <MappingDefaults />

          <Layers.BoundingBox active={true}>
            {location !== null && (
              <Layers.GeoJSON
                geoJSON={location}
                zIndex={10}
                style={MapStyles.Marker}
              />
            )}
          </Layers.BoundingBox>
        </Map>
      </div>
    );
  }

  return (
    <div className="grid gap-2 sm:grid-cols-2">
      <div className="col-span-1 space-y-2">
        {!showAdd && canEdit && location?._id !== undefined && (
          <Box>
            <LocationForm
              isEdit={true}
              location={location}
              setLocation={setLocation}
              onFormTypeChange={handleFormTypeChange}
              handleCancel={handleFormCancel}
              handleSubmit={(data) =>
                handleEditLocation(data).then(() => {
                  setLocation(null);
                })
              }
              hideExtra={hideExtra}
            />
          </Box>
        )}
        {showAdd && canAdd && location?._id === undefined && (
          <Box>
            <LocationForm
              location={location}
              setLocation={setLocation}
              onFormTypeChange={handleFormTypeChange}
              handleCancel={handleFormCancel}
              handleSubmit={(data) =>
                handleAddLocation(data).then(() => {
                  setShowAdd(false);
                  setLocation(null);
                })
              }
              hideExtra={hideExtra}
            />
          </Box>
        )}

        {/* Location List */}
        <ListLocations
          canAdd={canAdd}
          canEdit={canEdit}
          canDelete={canDelete}
          locations={locations}
          onLocationClick={a => {
            if(onLocationClick !== null) {
              onLocationChange(a);
            } else {
              setShowAdd(false);
              setLocation(a);
            }
          }}
          onNewClick={() => {
            setShowAdd(!showAdd);
            setLocation(null);
          }}
          onEditClick={(item) => {
            setShowAdd(false);
            setLocation(item);
          }}
          onDeleteClick={handleDeleteLocation}
          onOrderChange={handleOrderChange}
          hideExtra={hideExtra}
          hideOrdering={hideOrdering}
        />
      </div>
      <Box className="col-span-1">
        <Map
          height={height - heightMinus}
          center={mapCenter}
          onMapClick={handleMapClick}
        >
          <MappingDefaults />
          <Layers.BoundingBox active={true}>
            {location !== null && (
              <Layers.GeoJSON
                geoJSON={location}
                zIndex={10}
                style={MapStyles.Marker}
              />
            )}
            <Layers.Toggle title={"Locations"} active={true}>
              {location === null && locations?.length >= 1 && (
                <Layers.GeoJSON
                  geoJSON={locationsGeoJSON}
                  zIndex={5}
                  style={MapStyles.Locations}
                />
              )}
            </Layers.Toggle>
          </Layers.BoundingBox>
        </Map>
      </Box>
    </div>
  );
}

export default ChainedContext(LocationManager, [
  [SettingsReceiver, "settings"],
  [OrganisationReceiver, "organisation"],
]);
