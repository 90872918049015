import React, {useEffect, useState} from "react";
import Toast from "react-hot-toast";
import API from "../../../api";
import Model from "../../../components/common/Model";
import Select from "../../../components/common/Select";
import Input from "../../../components/common/Input";
import TextArea from "../../../components/common/TextArea";

const ManageResult = ({eventId, member, cpdConfigs, onResultUpdate}) => {
  const [showCommentPrompt, setShowCommentPrompt] = useState(false);
  const [showAsEdit, setShowAsEdit] = useState(false);
  const [newResult, setNewResult] = useState(false);
  const [comment, setComment] = useState("");
  const [cpdResults, setCpdResults] = useState([]);

  useEffect(() => {
    if (member._result !== undefined && member._result !== null) {
      setComment(member._result.comment);
      setNewResult(member._result.isPass);
      if(member._result.cpdRecords !== undefined){
        setCpdResults(member._result.cpdRecords.map(a => {
          return {
            type: a.type,
            hours: a.hours
          }
        }));
      }
    }
  }, [member]);

  const handleResultUpdate = (isPass) => {
    setNewResult(isPass);
    setShowCommentPrompt(true);
  };
  const handleSubmit = () => {
    if(newResult){
      const hasHours = cpdResults.filter(a => parseInt(a.hours) > 0).length >= 1;
      if(!hasHours){
        Toast.error("At least one hour has to been assigned to a CPD");
        return;
      }
    }
    API.calendar.updateAttendanceResult(eventId, member._id, {
      isPass: newResult,
      comment,
      cpdResults
    })
    .then(() => {
      onResultUpdate();
      setShowCommentPrompt(false);
      setShowAsEdit(false);
      Toast.success("Result updated successfully");
    })
    .catch((err) => {
      Toast.error("Unable to update result due to a server error");
    });
  };

  const renderModel = () => {
    if (!showCommentPrompt && !showAsEdit) return null;

    return (
      <Model
        title={"Change results for " + member.firstName + " " + member.lastName + " to " + (newResult ? "Pass" : "Fail") + "?"}
        onClose={() => setShowCommentPrompt(false)}
      >
        {showAsEdit && (
          <Select
            label="Result"
            options={[
              {key: 1, value: "pass", text: "Pass"},
              {key: 2, value: "fail", text: "Fail"},
            ]}
            value={newResult ? "pass" : "fail"}
            onChange={(e) => setNewResult(e.target.value === "pass")}
          />
        )}

        {newResult && <div>{cpdConfigs.map(cpdConfig => {
          const cpdResult = cpdResults.filter(a => a.type === cpdConfig.type);

          return (
            <div key={cpdConfig._id}>
              <Input
                label={cpdConfig.type + " [Hours Complete]"}
                value={cpdResult[0] !== undefined ? cpdResult[0].hours : "0"}
                onChange={(e) => setCpdResults(a => {
                  if (cpdResult[0] === undefined) {
                    return a.concat({
                      type: cpdConfig.type,
                      hours: e.target.value
                    });
                  } else {
                    return a.map(b => {
                      if (b.type !== cpdConfig.type) return b;
                      return {
                        ...b,
                        hours: e.target.value
                      }
                    })
                  }
                })}
              />
            </div>
          );
        })}</div>}

        <TextArea
          label="Comment"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
        <div className="mt-4 flex justify-between border-t border-zinc-500 pt-4">
          <button className="rounded-lg bg-primary px-4 py-2 text-white" onClick={() => {
            setShowCommentPrompt(false);
            setShowAsEdit(false);
          }}>Cancel
          </button>
          <button className="rounded-lg bg-green-500 px-4 py-2 text-white disabled:bg-green-500"
                  onClick={handleSubmit}>Update
          </button>
        </div>
      </Model>
    );
  };

  return (
    <div className="text-center">
      {renderModel()}
      {member._result !== undefined && member._result !== null ? (
        <div className="flex items-center justify-center gap-4">
          {member._result.isPass ? (
            <span className={"text-lg text-green-500"}>Pass</span>
          ) : (
            <span className={"text-lg text-red-500"}>Fail</span>
          )}

          <button
            className={"rounded border p-2 text-sm dark:border-zinc-500"}
            onClick={() => setShowAsEdit(true)}
          >
            Change
          </button>
        </div>
      ) : (
        <div className="w-full">
          No Result
          <div className="flex justify-center gap-2">
            <button
              className={
                "nonOp rounded border p-2 text-xs dark:border-zinc-500"
              }
              onClick={() => handleResultUpdate(false)}
            >
              Has Failed
            </button>
            <button
              className={
                "oncall rounded border p-2 text-xs dark:border-zinc-500"
              }
              onClick={() => handleResultUpdate(true)}
            >
              Has Passed
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
export default ManageResult;