import React, { useState, useEffect } from "react";

export default function ReportsDashboard() {
  const [data, setData] = useState({});

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {};

  return <div className="w-full p-2">Dash</div>;
}
