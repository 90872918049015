import AuthenticationWrapper from "./components/AuthenticationWrapper";
import Can from "./components/Can";
import CanNot from "./components/CanNot";
import APIRequest from "./APIRequest";
import Authentication from "./Authentication";

const AuthComponents = {
    Authentication: AuthenticationWrapper,
    Can,
    CanNot
};

export {
    AuthComponents,
    APIRequest,
    Authentication,
    AuthenticationWrapper
}