import React, {useEffect, useState} from "react";
import API from "../../../api";
import Address from "../../../components/locationManager/forms/address";
import Postcode from "../../../components/locationManager/forms/postcode";
import Select from "../../../components/common/Select";
import W3w from "../../../components/locationManager/forms/w3w";
import Input from "../../../components/common/Input";
import {Layers, Map} from "../../../components/sarstuff_mapping";
import MappingDefaults from "../../../components/mapping_defaults";
import * as MapStyles from "../../../components/mapping_styles";

const LocationSelector = ({ onChange, location, organisation, settings }) => {
  const [addType, setAddType] = useState("");
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    API.locations.all().then((res) => {
      setLocations(res);
    });
  }, []);

  const renderGeo = () => {
    return (
      <div>
        <Address
          setting={settings}
          onLocationChange={(e) => onChange(e)}
          location={(e) => console.log("loc", e)}
        />
      </div>
    );
  };
  const renderPostcode = () => {
    return (
      <div>
        <Postcode
          setting={settings}
          onLocationChange={(e) => onChange(e)}
          location={(e) => console.log("loc", e)}
        />
      </div>
    );
  };
  const addLoc = (loc) => {
    const found = locations.find((l) => l._id === loc);
    if(!found){
      onChange({
        type: "Feature",
        geometry: {},
        properties: {},
      });
      return;
    }
    onChange(found);
  };
  const renderLocPicker = () => {
    return (
      <div className="p-2">
        <Select
          label="Pick Location"
          options={locations.sort((a, b) => {
            if(a.properties.name > b.properties.name) return 1;
            if(a.properties.name < b.properties.name) return -1;
            return 0;
          }).map((l) => {
            return {
              key: l._id,
              text: l.properties.name,
              value: l._id,
            };
          })}
          onChange={(e) => addLoc(e.target.value)}
        />
      </div>
    );
  };
  const renderW3W = () => {
    return (
      <div>
        <W3w
          setting={settings}
          onLocationChange={(e) => setLocation(e)}
          location={(e) => console.log("loc", e)}
        />
      </div>
    );
  };

  return (
    <>
      <div className="p-2">
        <div className="flex justify-around p-2">
          <button
            onClick={() => setAddType("address")}
            className="rounded border p-2 dark:border-zinc-500"
          >
            Address
          </button>
          <button
            onClick={() => setAddType("postcode")}
            className="rounded border p-2 dark:border-zinc-500"
          >
            Postcode
          </button>
          <button
            onClick={() => setAddType("w3w")}
            className="rounded border p-2 dark:border-zinc-500"
          >
            W3W
          </button>
          <button
            onClick={() => setAddType("location")}
            className="rounded border p-2 dark:border-zinc-500"
          >
            Locations
          </button>
        </div>
        <div className="p-2">
          {addType === "address" && renderGeo()}
          {addType === "postcode" && renderPostcode()}
          {addType === "w3w" && renderW3W()}
          {addType === "location" && renderLocPicker()}
        </div>
      </div>
      <div className="p-2">
        {location?.properties?.name && (
          <Input
            label="Location Name"
            value={location.properties?.name}
            onChange={(e) =>
              setLocation({
                ...location,
                properties: {
                  ...location.properties,
                  name: e.target.value,
                },
              })
            }
          />
        )}
      </div>

      <Map height={"50vh"} center={organisation?.defaultMapCenter || [0.8, 51]}>
        <MappingDefaults hideFullScreen />
        <Layers.BoundingBox active={true}>
          {location?.geometry?.coordinates && (
            <Layers.GeoJSON
              geoJSON={location}
              zIndex={10}
              style={MapStyles.Marker}
            />
          )}
        </Layers.BoundingBox>
      </Map>
    </>
  );
};
export default LocationSelector;