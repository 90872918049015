import {Tab} from "@headlessui/react";
import DayJS from "dayjs";
import React, {useState, useEffect, useMemo} from "react";
import dayjs from "dayjs";
import Toast from "react-hot-toast";
import Box from "../../components/common/Box";
import Layout from "../../components/common/Layout";
import Select from "../../components/common/Select";
import {Skeleton} from "../../components/ui/skeleton";
import CommonFunctions from "../../lib/CommonFunctions";
import SettingsDropdownList from "../settings/_components/SettingsDropdownList";
import CompDropdownList from "./_components/CompDropdownList";
import API from "../../api";

const returnTabButtonStyle = (selected) => {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return classNames(
    "w-full py-2.5 text-lg leading-5 font-medium  rounded-lg",
    selected
      ? "text-gray-100 bg-green-900 bg-opacity-50"
      : "text-blue-100 dark:hover:bg-white/[0.12] hover:text-white bg-gray-800"
  );
};

const LowlandReport = () => {
  const [config, setConfig] = useState({});
  const [loadingError, setLoadingError] = useState(false);
  const [reportIsRunning, setReportIsRunning] = useState(false);
  const [year, setYear] = useState(DayJS().year());
  const [reportResults, setReportResults] = useState(false);

  const pickableYears = useMemo(() => {
    return [
      DayJS().year(),
      DayJS().year() - 1,
      // DayJS().year() - 2,
      // DayJS().year() - 3,
      // DayJS().year() - 4,
    ]
  }, [])

  const getConfig = () => {
    API.reports.lowland.getConfig().then(res => {
      setConfig(res);
    }).catch(err => {
      setLoadingError("Unable to retrive report config from server.");
    })
  }

  useEffect(() => {
    getConfig();
  }, []);

  const getReport = () => {
    setReportIsRunning(true);
    setReportResults(false);
    API.reports.lowland.runReport(year).then(res => {
      setReportResults(res);
    }).catch(err => {

    }).finally(() => {
      setReportIsRunning(false);
    })
  }
  const onSubmit = (data) => {
    API.reports.lowland.setConfig(data).then(() => {
      getConfig();
      Toast.success("Successfully updated.");
    }).catch(err => {
      console.error("Unable to save config", err);
    })
  };

  const handleCsvExport = () => {
    CommonFunctions.generateCSV(`Annual Maintenance of Qualification - ${year}`, [
      {csv_field: "First Name", database_field: "firstName"},
      {csv_field: "Surname", database_field: "lastName"},
      {csv_field: "Callsign", database_field: "callsign"},

      {csv_field: "Manage Searches", database_field: "passesManagedSearches"},
      {csv_field: "Total Searches", database_field: "totalSearches"},
      {csv_field: "Longest Inactivity (Months)", database_field: "longestBetweenSearch"},

      {csv_field: "Competencies Training", database_field: "passesCompetencyTraining"},
      {csv_field: "Training Hours", database_field: "competencyTrainingHours"},
      {csv_field: "Passes Fitness Assessment", database_field: "passesFitnessAssessment"},
    ], reportResults.map(a => {
      return {
        ...a,
        passesManagedSearches: a.results.passesManagedSearches ? "Pass" : "Fail",
        totalSearches: a.results.totalSearches,
        longestBetweenSearch: a.results.longestBetweenSearch,
        passesCompetencyTraining: a.results.passesCompetencyTraining ? "Pass" : "Fail",
        competencyTrainingHours: a.results.competencyTrainingHours,
        passesFitnessAssessment: a.results.passesFitnessAssessment ? "Pass" : "Fail",
      }
    }));
  }


  return (
    <Layout full>
      <h2>Annual Maintenance of Qualification</h2>
      <ol className={"list-disc pl-6 mb-4"}>
        <li>Participation in Managed Searches: Minimum of four searches within 12 months, with no inactivity period exceeding six months.</li>
        <li>Competency-Based Refresher Training: Minimum of 16 hours annually, covering critical skills and knowledge areas.</li>
        <li>Fitness Assessment: Annual Lowland Rescue fitness test to ensure physical readiness.</li>
      </ol>
      <Tab.Group defaultIndex={0}>
      <Tab.List className="flex space-x-1 border-b border-zinc-200  p-1 pb-2 dark:border-zinc-700 dark:bg-blue-900/20">
          <Tab className={({selected}) => returnTabButtonStyle(selected)}>
            Lowland Report
          </Tab>
          <Tab className={({selected}) => returnTabButtonStyle(selected)}>
            Settings
          </Tab>
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel className={"p-1"}>
            {/*<p className={"text-center"}>&gt;&gt;&gt; This report a work in progress &lt;&lt;&lt;</p>*/}
            <div className={"flex gap-2"}>
              <div className={"w-20"}>
                <Select
                  disabled={reportIsRunning}
                  dontSort
                  options={pickableYears.map(year => {
                    return {
                      id: year,
                      text: "" + year,
                      value: year
                    }
                  })}
                  value={year}
                  onChange={(e) => setYear(e.target.value)}
                />
              </div>
              <button
                disabled={year === "" || reportIsRunning}
                onClick={getReport}
                className="h-10 cursor-pointer rounded-md border border-green-500 bg-green-300 bg-opacity-40 px-4 py-2 mt-1 text-green-500 hover:border-green-300 disabled:opacity-60 disabled:cursor-not-allowed"
              >
                Run Report
              </button>
            </div>
            {reportIsRunning ? <div>
              <div className="flex flex-wrap justify-center gap-4 p-4">
                <Skeleton className="h-20 w-1/4 bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
                <Skeleton className="h-20 w-1/4 bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
                <Skeleton className="h-20 w-1/4 bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
                <Skeleton className="h-20 w-1/4 bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
                <Skeleton className="h-20 w-1/4 bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
                <Skeleton className="h-20 w-1/4 bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
              </div>
            </div> : <>
              {reportResults && <div className={"mt-4 border-t py-2"}>
                {reportResults?.length === 0 ? <div className={"text-center"}>No results to display.</div> : <Box title={"Results"} button={{
                  text: "CSV Export",
                  colourOverride: "text-sm text-white hover:text-gray-200 border",
                  action: () => handleCsvExport()
                }}>
                  <table className={`tableClass`}>
                    <thead>
                    <tr className="tableHeadRowClass">
                      <th className="tableHeadCellClass">Name</th>
                      <th className="tableHeadCellClass w-40 text-center">Manage Searches</th>
                      <th className="tableHeadCellClass w-40 text-center">Total Searches</th>
                      <th className="tableHeadCellClass w-40 text-center">Longest Inactivity (Months)</th>
                      <th className="tableHeadCellClass w-40 text-center">Competencies Training</th>
                      <th className="tableHeadCellClass w-40 text-center">Training Hours</th>
                      <th className="tableHeadCellClass w-40 text-center">Passes Fitness Assessment</th>
                    </tr>
                    </thead>
                    <tbody className="tableBodyClass">{reportResults.map(item => {
                      const result = item.results;
                      return <tr key={item._id} className="tableBodyRowClass">
                        <td className="tableBodyCellClass">{item.firstName} {item.lastName}{" "}{item.callsign ? (<span className="italic">({item.callsign})</span>) : (<></>)}</td>
                        <td className={`tableBodyCellClass text-center ${result.passesManagedSearches ? "mar-positive" : "mar-negative"}`}>{result.passesManagedSearches ? "Pass" : "Fail"}</td>
                        <td className="tableBodyCellClass text-center">{result.totalSearches}</td>
                        <td className="tableBodyCellClass text-center">{result.longestBetweenSearch}</td>
                        <td className={`tableBodyCellClass text-center ${result.passesCompetencyTraining ? "mar-positive" : "mar-negative"}`}>{result.passesCompetencyTraining ? "Pass" : "Fail"}</td>
                        <td className="tableBodyCellClass text-center">{result.competencyTrainingHours}</td>
                        <td className={`tableBodyCellClass text-center  ${result.passesFitnessAssessment ? "mar-positive" : "mar-negative"}`}>{result.passesFitnessAssessment ? "Pass" : "Fail"}</td>
                      </tr>
                    })}</tbody>
                  </table>
                </Box>}
              </div>}
            </>}
          </Tab.Panel>
          <Tab.Panel className={"p-1"}>
            <div className={"pt-2 grid gap-4 grid-cols-3"}>
              <SettingsDropdownList
                title="Managed Search Types"
                field="lowlandManagedSearchTypes"
                items={config._pickableSearchTypes?.filter(a => config.lowlandManagedSearchTypes.indexOf(a) === -1).map((a, i) => {
                  return {
                    key: i,
                    text: a,
                    value: a
                  }
                }) || []}
                data={config.lowlandManagedSearchTypes || []}
                onSubmit={onSubmit}
                onDelete={onSubmit}
              />
              <CompDropdownList
                title="Competency Training"
                field="refresherTraining"
                items={config._pickableCompetencies || []}
                data={config.refresherTraining || []}
                onSubmit={onSubmit}
                onDelete={onSubmit}
              />
              <div>
                <CompDropdownList
                  title="Fitness Assessment (Competencies)"
                  field="lowlandFitness"
                  items={config._pickableCompetencies || []}
                  data={config.lowlandFitness || []}
                  onSubmit={onSubmit}
                  onDelete={onSubmit}
                />
                <CompDropdownList
                  title="Fitness Assessment (Courses)"
                  field="lowlandFitnessCourse"
                  items={config._pickableCourses || []}
                  data={config.lowlandFitnessCourse || []}
                  onSubmit={onSubmit}
                  onDelete={onSubmit}
                />
              </div>
            </div>
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </Layout>
  );
}
export default LowlandReport;