import DayJS from "dayjs";
import React, { useState, useEffect, useCallback, useMemo } from "react";
import API from "../../../api";
import CommonFunctions from "../../../lib/CommonFunctions";
import Box from "../../../components/common/Box";

const RequirementsDetailedTable = ({ requirement, requirements, title }) => {
  const [headers, setHeaders] = useState([]);
  const [reportResults, setReportResults] = useState([]);

  useEffect(() => {
    API.reports.requirements.getDetailed(requirement).then((res) => {
      setReportResults(res.results.toSorted((a, b) => {
        if((a.result !== null ? a.result.expiresAt : "") > (b.result !== null ? b.result.expiresAt : "")) return 1;
        if((a.result !== null ? a.result.expiresAt : "") < (b.result !== null ? b.result.expiresAt : "")) return -1;
        return 0;
      }));
      setHeaders(res.headers);
    });
  }, [requirement]);

  const requirementTitle = useMemo(() => {
    return requirements.filter((a) => a._id === requirement)[0].title;
  }, [requirement, requirements]);

  const handleCsvExport = useCallback(() => {
    const header = [
      { csv_field: "Callsign", database_field: "callsign" },
      { csv_field: "Name", database_field: "name" },
    ];
    headers.forEach((a) => {
      header.push({
        csv_field: a.title,
        database_field: a.field,
      });
    });

    const body = [];
    reportResults.forEach((item) => {
      let data = {
        callsign: item.member?.callsign,
        name: item.member?.firstName + " " + item.member?.lastName,
      };

      headers.forEach((aa) => {
        data[aa.field] = "[N/A]";

        if (item.result !== null) {
          data[aa.field] =
            aa.isDate !== undefined
              ? DayJS(item.result[aa.field]).format("DD-MM-YYYY")
              : item.result[aa.field];
        }
      });

      body.push(data);
    });

    CommonFunctions.generateCSV(
      "Requirements Detailed - " + requirementTitle,
      header,
      body
    );
  }, [requirementTitle, reportResults, headers]);
  // console.log(requirement);
  return (
    <Box
      title={`Detailed Report ${title}`}
      button={{
        text: "CSV Export",
        colour: "text-sm text-white hover:text-gray-200",
        action: handleCsvExport,
      }}
    >
      <table className="tableClass">
        <thead>
          <tr className="tableHeadRowClass">
            <th className="tableHeadCellClass">Callsign</th>
            <th className="tableHeadCellClass">Name</th>
            {headers.map((header, key) => {
              return (
                <th key={key} className="tableHeadCellClass">
                  {header.title}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className="tableBodyClass">
          {reportResults.map((item) => {
            return (
              <tr key={item.member._id} className={`tableBodyRowClass`}>
                <td className="tableBodyCellClass">{item.member?.callsign}</td>
                <td className="tableBodyCellClass">
                  {item.member?.firstName} {item.member?.lastName}
                </td>
                {headers.map((header, key) => {
                  let res = "[N/A]";
                  let resColor = "";

                  if (item.result !== null) {
                    res =
                      header.isDate !== undefined
                        ? DayJS(item.result[header.field]).format("DD-MM-YYYY")
                        : item.result[header.field];
                    if (header.field === "expiresAt") {
                      resColor = "bg-red-200 dark:bg-red-900";

                      if (DayJS(item.result[header.field]).isAfter(DayJS())) {
                        resColor = "bg-green-200 dark:bg-green-900";
                      }
                    }
                  } else {
                    resColor = "bg-red-200 dark:bg-red-900";
                  }

                  return (
                    <th
                      key={key}
                      className={`tableHeadCellClass text-center ${resColor}`}
                    >
                      {res}
                    </th>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </Box>
  );
};
export default RequirementsDetailedTable;
