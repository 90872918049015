import React, { useState, useEffect } from "react";
import Input from "../../common/Input";
import Select from "../../common/Select";
import Button from "../../common/Button";
import ChainedContext from "../../../contexts/chainedContext";
import SettingsReceiver from "../../../contexts/settings_context/Settings_Receiver";

const OtherFields = ({
  settings,
  isAdd,
  errors,
  onSubmit,
  onCancel,
  location,
  onLocationChange,
  hideExtra = true,
  miniMode = false
}) => {
  const [types, setTypes] = useState([]);

  useEffect(() => {
    let list = [
      { key: "ha", text: "Home Address", value: "Home Address" },
      { key: "work", text: "Work Address", value: "Work Address" },
    ];
    list = list.concat(
      settings.locTypes
        ? settings.locTypes.sort((a, b) => a > b ? 1 : (a < b ? -1 : 0)).map((l, i) => {
            return { key: i, text: l, value: l };
          })
        : []
    );
    setTypes(list);
  }, [settings]);

  const handleUpdate = (field, data) => {
    onLocationChange({
      ...location,
      properties: {
        ...(location?.properties || {}),
        [field]: data,
      },
    });
  };
  const handleSetName = (e) => {
    handleUpdate("name", e.target.value);
  };
  const handleSetLocationType = (e) => {
    handleUpdate("type", e.target.value);
  };
  const handleSetLocationTime = (e) => {
    handleUpdate("time", e.target.value);
  };
  const handleSetLocationDepartment = (e) => {
    handleUpdate("dept", e.target.value);
  };

  return (
    <>
      <div className="flex flex-col gap-2">
        <div className="flex flex-row justify-between gap-2 pt-4">
          <Input
            label="Name"
            value={location?.properties?.name}
            type="text"
            onChange={handleSetName}
          />
          <Input
            disabled={true}
            label="Grid"
            value={location?.properties?.grid?.toUpperCase()}
            type="text"
          />
        </div>

        {(location?.properties?.address ||
          location?.properties?.town ||
          location?.properties?.postcode) && (
          <div className="grid grid-cols-2 gap-2 pt-4">
            <Input
              disabled={true}
              label="Address"
              value={location?.properties?.address}
              type="text"
              onChange={() => {}}
              style={"col-span-2"}
            />
            <Input
              disabled={true}
              label="Town"
              value={location?.properties?.town}
              type="text"
              onChange={() => {}}
            />
            <Input
              disabled={true}
              label="Postcode"
              value={location?.properties?.postcode}
              type="text"
              onChange={() => {}}
            />
          </div>
        )}

        {!miniMode && <div className="grid grid-cols-2 gap-2 pt-4">
          <div>
            <Select
              label={"Location Type"}
              field={"type"}
              value={location?.properties?.type ? location.properties.type : ""}
              error={errors.type ? errors.type : ""}
              onChange={handleSetLocationType}
              options={types}
            />
          </div>
          {!hideExtra && (
            <>
              <Input
                label="Dept"
                value={location?.properties?.dept}
                type="text"
                field="dept"
                onChange={handleSetLocationDepartment}
              />
              <Input
                label="Time"
                value={location?.properties?.time}
                type="text"
                field="time"
                onChange={handleSetLocationTime}
              />
            </>
          )}
        </div>}
        <div className="mt-2 flex flex-row justify-between gap-2 border-t border-gray-600 py-4">
          <Button
            text="Cancel"
            onClick={onCancel}
            colour="nonOp  border border-red-500 hover:bg-red-600"
            textColour="text-black dark:text-white"
            hoverColour="text-gray-100"
          />
          {!isAdd ? (
            <Button
              text="Update Location"
              onClick={onSubmit}
              textColour="text-black dark:text-white"
              colour="oncall border border-green-500 hover:bg-green-600 "
              hoverColour="text-gray-100"
            />
          ) : (
            <Button
              text="Add Location"
              onClick={onSubmit}
              textColour="text-black dark:text-white"
              colour="oncall border border-green-500 hover:bg-green-600 "
              hoverColour="text-gray-100"
            />
          )}
        </div>
      </div>
    </>
  );
};
export default ChainedContext(OtherFields, [[SettingsReceiver, "settings"]]);
