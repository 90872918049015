import React from "react";
import { Controls, TileLayer, TileLayers, Layers } from "../sarstuff_mapping";
import * as MapStyles from "../mapping_styles";

const MappingDefaults = ({ tracking = false, hideFullScreen = false }) => {
  /**
   * This file is used to setup a default looking map that this system will use, this will not end up in the final NPM and each system will have there own unique version
   */
  // const showLight = () => {
  //   if (
  //     localStorage.getItem("color-theme") === "dark" ||
  //     (!("color-theme" in localStorage) &&
  //       window.matchMedia("(prefers-color-scheme: dark)").matches)
  //   ) {
  //     return false;
  //   }
  //   return true;
  // };

  return (
    <>
      {!hideFullScreen &&  <Controls.FullScreen />}
      <Controls.Scale />
      <TileLayers
        activeIndex={0}
        layers={[
          // showLight === true && {
          //   title: "Light",
          //   layer: <TileLayer.SarStuff_Light />,
          // },
          // showLight === false && {
          //   title: "Dark",
          //   layer: <TileLayer.SarStuff_Dark />,
          // },
          { title: "SarStuff", layer: <TileLayer.SarStuff_Satellite /> },
          { title: "OS - Local", layer: <TileLayer.OS_Local /> },
          { title: "OS - 1-25", layer: <TileLayer.OS_125 /> },
          // {title: "Google - Hybrid", layer: <TileLayer.Google_Hybrid/>},
          {
            title: "Google - Satellite",
            layer: <TileLayer.Google_Satellite />,
          },
        ]}
      />
    </>
  );
};

export default MappingDefaults;
