import React, {useCallback, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import DayJS from "dayjs";
import TeamMemberScore from "./_components/TeamMemberScore";
import TableList from "./_components/TableList";
import Box from "../../../components/common/Box";
import API from "../../../api";
import {Tab} from "@headlessui/react";
import Modal from "../../../components/common/Modal";
import CompSessionForm from "./_components/CompSessionForm";
import Toast, {toast} from "react-hot-toast";
import ConfirmBox from "../../../components/common/Confirm";
import {Skeleton} from "../../../components/ui/skeleton";
import DataRow from "../../../components/common/DataRow";
import DataRowRoles from "../../../components/common/DataRowRoles";
import {Layers, Map} from "../../../components/sarstuff_mapping";
import MappingDefaults from "../../../components/mapping_defaults";
import * as MapStyles from "../../../components/mapping_styles";
import ChainedContext from "../../../contexts/chainedContext";
import OrganisationReceiver from "../../../contexts/organisation/receiver";
import {TrashIcon} from "@heroicons/react/20/solid";
import {AuthComponents, Authentication} from "../../../lib/_sso";
import NewLineBreaker from "../../../components/common/NewLineBreaker";

const returnTabButtonStyle = (selected) => {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return classNames(
    "w-full py-2.5 text-lg leading-5 font-medium  rounded border dark:border-zinc-500",
    selected
      ? "text-gray-100 bg-green-900 bg-opacity-50"
      : "text-blue-100 hover:bg-white/[0.12] hover:text-white bg-gray-800"
  );
};

const ManageSession = ({ organisation }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [team, setTeam] = useState({
    attending: [],
    notAttending: [],
    unConfirmed: []
  });
  const [competency, setCompetency] = useState({});
  const [session, setSession] = useState({});
  const [selectedTeamMember, setSelectedTeamMember] = useState(null);
  const [showEdit, setShowEdit] = useState(false);
  const [deleteWarning, setDeleteWarning] = useState(false);
  const [warnResultsWipe, setWarningsWipe] = useState(false);

  const {collectionId} = useParams();
  const navigate = useNavigate();
  const mapCenter = organisation.defaultMapCenter;

  const getData = useCallback(() => {
    Promise.all([
      API.training.competencies.getGroupedMembersForCollection(collectionId),
      API.training.competencies.getCollection(collectionId)
    ]).then(res => {
      setTeam(res[0]);
      setSession(res[1]);
      setCompetency(res[1].competency);
      setIsLoading(false);
    });
  }, [collectionId]);

  useEffect(() => {
    getData();
  }, [getData]);

  const handleSetAttendance = (member, isAttending) => {
    const theApi = isAttending ?
      API.training.competencies.markAttending(member._id, collectionId) :
      API.training.competencies.markNotAttending(member._id, collectionId);

    const isUpdating = toast.loading("Updating attendance...")
    theApi.then(() => {
      getData();
      toast.success("Successfully updated attendance.");
    }).catch(err => {
      toast.error("Unable to update attendance due to an error on the server: " + err.response?.data?.message || err.message)
    }).finally(() => {
      toast.dismiss(isUpdating);
    });
  };
  const handleSetAttendanceWarning = (member, isAttending) => {
    if(!isAttending){
      setWarningsWipe(member);
    }
  };
  const handleAssignMissingRoles = member => {
    const isUpdating = toast.loading("Assigning missing roles...")
    API.training.competencies.assignMemberMissingRoles(member._id, collectionId).then(() => {
      toast.success("Successfully assigned missing roles.");
      getData();
      setSelectedTeamMember(null);
    }).catch(err => {
      toast.error("Unable to assigning missing roles due to an error on the server: " + err.response?.data?.message || err.message)
    }).finally(() => {
      toast.dismiss(isUpdating);
    })
  };
  const handleUpdate = (data) => {
    return API.training.competencies.updateCollection(collectionId, data).then(() => {
      setShowEdit(false);
      getData();
      Toast.success("Successfully updated Competency Session details");
    });
  };
  const handleDelete = () => {
    API.training.competencies.deleteCollection(collectionId).then(() => {
      toast.success("Competency Session deleted successfully");
      navigate(`/training/competencies`);
    }).catch(err => {
      toast.error("Failed to delete Competency Session due to a server error: " + err.message);
    });
  };

  if (isLoading) {
    return (
      <div className="space-y-6 p-4">
        <div className="flex gap-8">
          <Skeleton className="h-8 w-1/3 dark:bg-zinc-700"></Skeleton>
          <Skeleton className="h-8 w-1/3 dark:bg-zinc-700"></Skeleton>
          <Skeleton className="h-8 w-1/3 dark:bg-zinc-700"></Skeleton>
        </div>
        <div className="flex gap-8">
          <Skeleton className="h-8 w-1/2 dark:bg-zinc-700"></Skeleton>
          <Skeleton className="h-8 w-1/2 dark:bg-zinc-700"></Skeleton>
        </div>
      </div>
    );
  }

  return (
    <div className={"w-full space-y-2 p-4"}>
      {showEdit && <Modal show={!deleteWarning} title={"Edit Session"} onClose={() => setShowEdit(false)} disableOutsideClick className={"max-w-5xl"}>
        <CompSessionForm data={session} isEdit onSubmit={handleUpdate} onCancel={() => setShowEdit(false)}/>
        <div className={"text-center"}>
          <AuthComponents.Can scope={"competency:session:delete"}><button
            className="rounded-md border bg-opacity-80 px-2 py-1 text-sm text-white border-red-400 bg-red-700 hover:border-red-300 hover:bg-red-800"
            onClick={() => setDeleteWarning(true)}
          >
            <TrashIcon className="h-6 w-6 text-red-200"/>
          </button></AuthComponents.Can>
        </div>
      </Modal>}
      {deleteWarning && <ConfirmBox show title={"WARNING: Deleting this session will also delete results stored with each team member potentially revoking some roles."}
                                    action={handleDelete} onClose={() => setDeleteWarning(false)}/>}
      {warnResultsWipe && <ConfirmBox title={"Wipe " + warnResultsWipe.firstName + " " + warnResultsWipe.lastName + " results and mark them as not attending"} onClose={() => setWarningsWipe(false)} action={() => handleSetAttendance(warnResultsWipe, false)} show/>}

      {selectedTeamMember !== null && (
        <Modal show={true} title={`${selectedTeamMember.firstName} ${selectedTeamMember.lastName}`} onClose={() => setSelectedTeamMember(null)} disableOutsideClick>
          <TeamMemberScore
            onClose={() => setSelectedTeamMember(null)}
            collectionId={collectionId}
            competency={competency}
            member={selectedTeamMember}
            onAssignMissingRoles={handleAssignMissingRoles}
          />
        </Modal>
      )}


      <div className={"grid grid-cols-2 gap-4"}>
        <div className={"col-span-1"}>
          <Box
            title={"Competency Session"}
            button={Authentication.can("competency:session:write") ? {
              action: () => setShowEdit(true),
              text: "Edit",
              colour: "text-white hover:text-gray-200",
            } : null}
          >
            <DataRow label="Title" value={session.title} />
            <DataRow label="Competency" value={competency?.name || "Unknown Competency [ likely been deleted ]"} />
            <div className="grid lg:grid-cols-2">
              <DataRow label="Start At" value={DayJS(session.startAt).format("DD-MM-YYYY HH:mm")} />
              <DataRow label="End At" value={DayJS(session.finishAt).format("DD-MM-YYYY HH:mm")} />
              <DataRow label="Valid For" value={(competency?.validFor || "[unknown]") + " years"} />
              <DataRow label="Expires At" value={DayJS(session.expiryDate).format("DD-MM-YYYY")} />
            </div>
            <DataRowRoles roles={competency?.roles || []} />
            <DataRow label="Description" value={<NewLineBreaker value={session?.description} />} />
            {session.webLinks !== undefined && session.webLinks.length !== 0 && <div className="col-span-2 text-center lg:col-span-1">
              <span className="text-primary dark:text-primary-dark">Web Links</span>
              <div className={"p-2 flex gap-2 flex-wrap"}>
                {session.webLinks.sort((a, b) => {
                  if(a.title > b.title) return 1;
                  if(a.title < b.title) return -1;
                  return 0;
                }).map((item, index) => {
                  return (
                    <a key={index} href={item.url} target={"_new"} className={"border rounded border-black dark:border-white hover:underline py-1 px-3"}>{item.title}</a>
                  );
                })}
              </div>
            </div>}
          </Box>
          {session.location !== undefined && session.location.geometry !== undefined && <Box title={"Location"}>
            <div>
              <DataRow label="Name" value={session.location.properties?.name} />

              {session.location.properties?.address !== undefined && (
                <DataRow label="Address" value={session.location.properties?.address}/>
              )}
              <div className={"grid grid-cols-2"}>
                <DataRow label="Town" value={session.location.properties?.town} />
                <DataRow label="Postcode" value={session.location.properties?.postcode}/>
              </div>

              <Map height={350} center={mapCenter}>
                <MappingDefaults />

                <Layers.BoundingBox active={true}>
                  <Layers.GeoJSON
                    geoJSON={session.location}
                    zIndex={10}
                    style={MapStyles.Marker}
                  />
                </Layers.BoundingBox>
              </Map>
            </div>
          </Box>}
        </div>
        <div className={"col-span-1"}>
          <Tab.Group defaultIndex={0}>
            <Tab.List className="flex space-x-1 border-b border-zinc-200  p-1 pb-2 dark:border-zinc-700 dark:bg-blue-900/20">
              <Tab className={({selected}) => returnTabButtonStyle(selected)}>
                Attending ({team.attending.length})
              </Tab>
              <Tab className={({selected}) => returnTabButtonStyle(selected)}>
                Not Attending ({team.notAttending.length})
              </Tab>
              <Tab className={({selected}) => returnTabButtonStyle(selected)}>
                Not Responded ({team.unConfirmed.length})
              </Tab>
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel>
                <Box>
                  <TableList
                    team={team.attending}
                    screen={"results"}
                    setSelectedTeamMember={setSelectedTeamMember}
                    setAttendance={handleSetAttendanceWarning}
                  />
                </Box>
              </Tab.Panel>
              <Tab.Panel>
                <Box>
                  <TableList
                    team={team.notAttending}
                    screen={"notAttending"}
                    setAttendance={handleSetAttendance}
                  />
                </Box>
              </Tab.Panel>
              <Tab.Panel>
                <Box>
                  <TableList
                    team={team.unConfirmed}
                    screen={"notResponded"}
                    setAttendance={handleSetAttendance}
                  />
                </Box>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    </div>
  );
};
export default ChainedContext(ManageSession, [
  [OrganisationReceiver, "organisation"],
]);
