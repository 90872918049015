import React, { Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
export default function EventLine({ data }) {
  let multi = dayjs(data.start).format("DD") !== dayjs(data.end).format("DD");
  let isPast = dayjs(data.end).isBefore(dayjs());
  const navigate = useNavigate();

  const goTo = () => {
    switch (data.class) {
      case "course":
        navigate(`/training/courses/event/${data._id}`);
        break;
      case "event":
        navigate(`/events/event/${data._id}`);
        break;
      case "comp":
        navigate(`/training/competencies/session/${data._id}`);
        break;
      case "recruitment":
        navigate(`/recruitment/events/event/${data._id}`);
        break;
      default:
        break;
    }
  };

  return (
    <Fragment key={data._id}>
      <tr
        className={`cursor-pointer divide-y divide-gray-500 text-lg  ${
          isPast && "opacity-50 "
        }`}
        onClick={goTo}
      >
        <td
          rowSpan={2}
          className="  text-md border border-gray-500 bg-zinc-100 p-2 text-center hover:bg-zinc-300 dark:bg-zinc-900 hover:dark:bg-zinc-800"
        >
          <div className="flex flex-col text-primary dark:text-primary-dark ">
            <div className="text-sm">{dayjs(data.start).format("ddd")}</div>
            <div className="text-3xl dark:text-white">
              {dayjs(data.start).format("DD")}
            </div>
            <div className="text-md">{dayjs(data.start).format("MMMM")}</div>
          </div>
        </td>
        <td className=" border border-gray-500 bg-zinc-100 p-2 text-xl hover:bg-zinc-300 dark:bg-zinc-900 hover:dark:bg-zinc-800">
          {data.title}{" "}
          <span className="text-sm italic">({data.eventType} )</span>
        </td>
      </tr>
      <tr
        className={`cursor-pointer divide-y divide-gray-500 text-lg   ${
          isPast && "opacity-50 "
        } `}
        onClick={goTo}
      >
        <td className="w-full border border-gray-500 bg-zinc-100 p-2 text-lg text-primary hover:bg-zinc-300 dark:bg-zinc-900 dark:text-primary-dark hover:dark:bg-zinc-800">
          Time: {dayjs(data.start).format("HH:mm")} -{" "}
          {multi
            ? dayjs(data.end).format("DD-MM-YYYY HH:mm")
            : dayjs(data.end).format("HH:mm")}
        </td>
      </tr>
    </Fragment>
  );
}
