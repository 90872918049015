import React, { useContext, useEffect } from "react";
import {Map} from "../context";
import {FullScreen} from "ol/control";

const FullScreenControl = () => {
  const mapContext = useContext(Map);

  useEffect(() => {
    if (!mapContext) return;

    let fullScreenControl = new FullScreen({});
    mapContext.controls.push(fullScreenControl);

    return () => mapContext.controls.remove(fullScreenControl);
  }, [mapContext])

  return null;
}
export default FullScreenControl;