import React from "react";
import {Link, Route, Routes} from "react-router-dom";
import Dashboard from "./dashboard";
import ManageCourse from "./manageCourse";

const CourseIndex = () => {

  return (
    <Routes>
      <Route index element={<Dashboard />} />
      <Route path={"/event/:eventId"} element={<ManageCourse />} />
      <Route path="*" element={<NoMatch />} />
    </Routes>
  );
}
export default CourseIndex;

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}