import { APIRequest } from "../lib/_sso";
import AppConfig from "../_appConfig";

const server = AppConfig.api_server;

export default {
  dash: () => APIRequest.get(`${server}/assets/dash`).then(res => res.data),
  all: () => APIRequest.get(`${server}/assets`).then(res => res.data),
  add: (data) => APIRequest.post(`${server}/assets`, data).then(res => res.data),
  remove: id => APIRequest.delete(`${server}/assets/${id}`),
  clone: (id, data) => APIRequest.post(`${server}/assets/${id}/clone`, data).then(res => res.data),
  show: (id) => APIRequest.get(`${server}/assets/${id}`).then(res => res.data),
  update: (id, data) => APIRequest.patch(`${server}/assets/${id}`, data).then(res => res.data),
  assign: (id, data) => APIRequest.patch(`${server}/assets/${id}/assign`, data).then(res => res.data),

  photo: {
    getUploadConfig: assetId => APIRequest.get(`${server}/assets/${assetId}/photo/_uploadConfig`).then(res => res.data),
    getAccessLink: assetId => APIRequest.get(`${server}/assets/${assetId}/photo/link`).then(res => res.data),
    uploadPhoto: (assetId, data) => APIRequest.patch(`${server}/assets/${assetId}/photo/upload`, data, {headers: {"Content-Type": "multipart/form-data"}}).then(res => res.data),
  },
  reports: {
    getAssigned: (type = "member", id) =>
      APIRequest.get(`${server}/assets/reports/assigned/${type}/${id}`).then(
        (res) => res.data
      ),
    getContentReport: () =>
      APIRequest.get(`${server}/assets/reports/contents`).then(
        (res) => res.data
      ),
  },
  contents: {
    addContents: (id, data) =>
      APIRequest.post(`${server}/assets/${id}/contents`, data).then(
        (res) => res.data
      ),
    delContents: (assetID, id) =>
      APIRequest.delete(`${server}/assets/${assetID}/contents/${id}`).then(
        (res) => res.data
      ),
    updateContent: (assetID, id, data) =>
      APIRequest.patch(`${server}/assets/${assetID}/contents/${id}`, data).then(
        (res) => res.data
      ),
    getContents: (id) =>
      APIRequest.get(`${server}/assets/${id}/contents`).then((res) => res.data),
  },
  checks: {
    all: (id) =>
      APIRequest.get(`${server}/assets/checks/${id}`).then((res) => res.data),
    add: (id, data) =>
      APIRequest.post(`${server}/assets/checks/${id}`, data).then(
        (res) => res.data
      ),
    update: (assetID, id, data) =>
      APIRequest.patch(
        `${server}/assets/checks/${assetID}/update/${id}`,
        data
      ).then((res) => res.data),
  },
  service: {
    all: (id) =>
      APIRequest.get(`${server}/assets/service/${id}`).then((res) => res.data),
    show: (assetID, id) =>
      APIRequest.get(`${server}/assets/service/${assetID}/show/${id}`).then(
        (res) => res.data
      ),
    add: (id, data) =>
      APIRequest.post(`${server}/assets/service/${id}`, data).then(
        (res) => res.data
      ),
    update: (assetID, id, data) =>
      APIRequest.patch(
        `${server}/assets/service/${assetID}/update/${id}`,
        data
      ).then((res) => res.data),
  },
  settings: {
    all: () =>
      APIRequest.get(`${server}/assets/settings`).then((res) => res.data),
    update: (data) =>
      APIRequest.patch(`${server}/assets/settings`, data).then(
        (res) => res.data
      ),
  },
  suppliers: {
    add: (data) =>
      APIRequest.post(`${server}/assets/suppliers`, data).then(
        (res) => res.data
      ),
    all: () =>
      APIRequest.get(`${server}/assets/suppliers`).then((res) => res.data),
    show: (id) =>
      APIRequest.get(`${server}/assets/suppliers/${id}`).then(
        (res) => res.data
      ),
    update: (id, data) =>
      APIRequest.patch(`${server}/assets/suppliers/${id}`, data).then(
        (res) => res.data
      ),
    getAssets1: (supplier_id) =>
      APIRequest.get(`${server}/assets/suppliers/${supplier_id}/assets`).then(
        (res) => res.data
      ),
    getAssets: (supplier_id) =>
      APIRequest.get(`${server}/assets/reports/supplier/${supplier_id}`).then(
        (res) => res.data
      ),
  },
};