/**
 * This class exits solely to store config data in memory so that other classes can access it in a singular fashion
 */
class Bridge {
    constructor(){
        this.config = null;
        this.contextHandlers = {};
    }
    setConfig(config){
        this.config = config;
    }
    getConfig(item){
        return this.config[item];
    }
    getRAWConfig(){
        return this.config;
    }
    setContextHandlers(handlers){
        this.contextHandlers = handlers;
    }
    getContextHandlers(){
        return this.contextHandlers;
    }
}
export default new Bridge();