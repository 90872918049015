import React, {useState, useEffect, useCallback} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-hot-toast";
import {Skeleton} from "../../components/ui/skeleton";
import Box from "../../components/common/Box";
import RecruitForm from "./_components/RecruitForm";
import api from "../../api";

const RecruitEdit = () => {
  const [loading, setLoading] = useState(true);
  const [recruit, setRecruit] = useState({});

  const navigate = useNavigate();
  const { recruit_id } = useParams();

  useEffect(() => {
    api.recruitment.getRecruit(recruit_id).then(res => {
      setRecruit({
        ...res,
        dob: new Date(res.dob),
        intake: res.intake?._id !== undefined ? res.intake._id  : ""
      });
    }).catch((err) => {
      console.log(err);
      toast.error("Unable to retrieve Recruit, please try again shortly.")
    }).finally(() => {
      setLoading(false);
    });
  }, [recruit_id]);

  const handleOnSubmit = useCallback(data => {
    return api.recruitment.updateRecruit(recruit_id, data).then(() => {
      toast.success("Successfully updated recruits details");
      navigate(`/recruitment/recruits/${recruit_id}`);
    })
  }, [recruit_id]);

  return (
    <div className="w-full p-4">
      <Box title="Edit Recruit">
        {loading && (
          <div className="grid grid-cols-3 gap-4 p-2">
            <div className="space-y-4">
              <Skeleton className="h-10 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
              <Skeleton className="h-10 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
              <Skeleton className="h-10 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
            </div>
            <div className="space-y-4">
              <Skeleton className="h-10 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
              <Skeleton className="h-10 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
              <Skeleton className="h-10 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
            </div>
            <div className="space-y-4">
              <Skeleton className="h-10 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
              <Skeleton className="h-10 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
              <Skeleton className="h-10 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
            </div>
          </div>
        )}
        {!loading && (
          <RecruitForm
            isEdit
            recruit={recruit}
            onSubmit={handleOnSubmit}
            onCancel={() => navigate(`/recruitment/recruits/${recruit_id}`)}
          />
        )}
      </Box>
    </div>
  );
}
export default RecruitEdit;