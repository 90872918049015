import React from "react";
import { Routes, Route, Outlet, Link } from "react-router-dom";
import Dashboard from "./dashboard";
import Competency from "./competency";
import TrainingNav from "./_components/NavBar";
import Courses from "./courses";
import TrainingReportsPages from "./reports";
import {AuthComponents} from "../../lib/_sso";

const TrainingIndex = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Dashboard />} />
        <Route path={"/competencies/*"} element={<Competency />} />
        <Route path={"/courses/*"} element={<Courses />} />
        <Route path={"/reports/*"} element={<TrainingReportsPages />} />
        <Route path="*" element={<NoMatch />} />
      </Route>
    </Routes>
  );
};
export default TrainingIndex;

function Layout() {
  const scope = ["teamsite:admin", "teamsite:management", "teamsite:power_user"];

  return (
    <div className="min-h-full">
      <TrainingNav />
      <main>
        <AuthComponents.Can scope={scope}>
          <Outlet />
        </AuthComponents.Can>
        <AuthComponents.CanNot scope={scope}>
          <div className={"p-2 text-red-500"}>Access Denied: You do not have permission to access this page.</div>
        </AuthComponents.CanNot>
      </main>
    </div>
  );
}
function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}