import React, { useState, useEffect } from "react";
import SettingsReceiver from "../../contexts/settings_context/Settings_Receiver";
import OrganisationReciever from "../../contexts/organisation/receiver";
import ChainedContext from "../../contexts/chainedContext";
import api from "../../api";
import { Dialog, Transition } from "@headlessui/react";

// import TreeView, { flattenTree } from "react-accessible-treeview";
import {
  ArrowDownIcon,
  FaceSmileIcon,
  FolderIcon,
  FolderOpenIcon,
} from "@heroicons/react/20/solid";
import CompModulesList from "./_components/CompModulesList";
import CompSectionsList from "./_components/CompSectionsList";
import CompSkillForm from "./_components/CompSkillForm";

const CompetencyTree2 = ({ settings }) => {
  const [comp, setComp] = useState({});
  const [moduleOpen, setModuleOpen] = useState(false);
  const [sectionOpen, setSectionOpen] = useState(false);
  const [skillOpen, setSkillOpen] = useState(false);
  const [section, setSection] = useState({});
  const [pick, setPick] = useState("");
  const [module, setModule] = useState("");
  const [comps, setComps] = useState([]);
  const [modules, setModules] = useState([]);
  const [sections, setSections] = useState([]);
  const [skills, setSkills] = useState([]);
  useEffect(() => {
    getData();
    getComps();
  }, []);
  useEffect(() => {
    if (pick !== "") {
      getData();
    }
  }, [pick]);

  const getData = () => {
    api.settings.getComp(pick).then((res) => {
      setComp(res);
    });
  };

  const getComps = () => {
    api.settings.getComps().then((res) => {
      setComps(res);
    });
    api.settings.getCompModules().then((res) => {
      setModules(res);
    });
    api.settings.getCompSections().then((res) => {
      setSections(res);
    });
    api.settings.getCompSkills().then((res) => {
      setSkills(res);
    });
  };
  const ModuleDialog = () => {
    return (
      <Transition
        show={moduleOpen}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Dialog
          open={moduleOpen}
          onClose={() => setModuleOpen(false)}
          className="fixed inset-0 z-50 overflow-y-auto "
        >
          <div className="mx-auto min-h-screen max-w-5xl text-center">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-80" />
            <span className="inline-block h-screen " aria-hidden="true">
              &#8203;
            </span>
            <div className="my-8 inline-block w-full  transform overflow-hidden rounded-2xl border-4 border-gray-700 bg-gray-50 p-6 text-left align-middle shadow-xl transition-all dark:bg-zinc-700">
              <div className="pt-4 text-center">
                <Dialog.Title className={"text-2xl"}>
                  Add Module to
                  <span className="font-bold italic">{comp.name}</span>
                </Dialog.Title>
              </div>
              <CompModulesList
                title={"Competency Modules"}
                comps={comps}
                refresh={() => getComps()}
                data={modules}
              />
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  };
  const SectionDialog = () => {
    return (
      <Transition
        show={sectionOpen}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Dialog
          open={sectionOpen}
          onClose={() => {
            setSectionOpen(false);
            getComps();
            getData();
          }}
          className="fixed inset-0 z-50 overflow-y-auto "
        >
          <div className="mx-auto  max-w-5xl text-center">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-80" />
            <span className="inline-block h-screen " aria-hidden="true">
              &#8203;
            </span>
            <div className="my-8 inline-block w-full  transform  rounded-2xl border-4 border-gray-700 bg-gray-50 p-6 text-left align-middle shadow-xl transition-all dark:bg-zinc-800">
              <div className="pt-4 text-center">
                <Dialog.Title className={"text-2xl"}>
                  Add Section to{" "}
                  <span className="font-bold italic">{comp.name}</span>
                </Dialog.Title>
              </div>
              <CompSectionsList
                title={"Competency Sections"}
                modules={modules}
                module={module}
                refresh={() => getComps()}
                data={sections}
              />
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  };
  const SkillDialog = () => {
    return (
      <Transition
        show={skillOpen}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Dialog
          open={skillOpen}
          onClose={() => {
            setSkillOpen(false);
            getComps();
            getData();
          }}
          className="fixed inset-0 z-50 overflow-y-auto "
        >
          <div className="mx-auto min-h-screen max-w-5xl text-center">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-80" />
            <span className="inline-block h-screen " aria-hidden="true">
              &#8203;
            </span>
            <div className="my-8 inline-block w-full  transform overflow-hidden rounded-2xl border-4 border-gray-700 bg-gray-50 p-6 text-left align-middle shadow-xl transition-all dark:bg-zinc-800">
              <div className="pt-4 text-center">
                <Dialog.Title className={"text-2xl"}>
                  Add Skill to{" "}
                  <span className="font-bold italic">{section.name}</span>
                </Dialog.Title>
              </div>
              <div className="">
                <CompSkillForm
                  section={section}
                  sections={sections}
                  data={skills}
                  refresh={getComps}
                />
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  };
  // const data = flattenTree(comp);
  // console.log(comps);
  return (
    <div className="z-20 p-2">
      {ModuleDialog()}
      {SectionDialog()}
      {SkillDialog()}
      <div className="flex gap-2 flex-wrap">
        {comps.map((c) => {
          return (
            <div
              key={c._id}
              className={`cursor-pointer rounded  p-2 ${c.name === comp?.name ? "bg-green-200 dark:bg-green-700" : "bg-zinc-200 dark:bg-zinc-700"}`}
              onClick={() => setPick(c._id)}
            >
              {c.name}
            </div>
          );
        })}
        {comps.length === 0 && <div className={"pt-4 pl-2"}>
          There are no competencies to display.
        </div>}
      </div>
      {comp.name && <>
        <div className="p-2 text-3xl">Competency Name: {comp.name}</div>
        <div className="p-2 text-xl">
          Roles:
          {comp?.roles?.map((r, i) => {
            return (
              <span key={i} className="p-2">
              {r}
            </span>
            );
          })}
        </div>
        <div className=" p-2">
          <div className="flex justify-center  p-4 dark:border-zinc-500">
            <div className="flex  flex-col justify-center     px-8 text-xl text-white">
              <button className=" w-full -rotate-90 rounded border p-4 dark:border-zinc-500 dark:bg-zinc-700">
                {comp.name}
              </button>
            </div>
            <div className="grid w-full  grid-cols-1 ">
              {comp?.children?.map((m) => {
                return (
                  <div
                    key={m._id}
                    className="flex   gap-4 border-b  p-4 dark:border-zinc-500"
                  >
                    <div className="w-82 flex flex-col justify-center ">
                      <button
                        onClick={() => {
                          setSectionOpen(true);
                          setModule(m._id);
                        }}
                        className="w-44  rounded border p-4 dark:border-zinc-500 dark:bg-zinc-700 "
                      >
                        {m.name}
                      </button>
                    </div>
                    <div className="w-full ">
                      {m.children.map((s) => {
                        return (
                          <div
                            key={s._id}
                            className="col-span-1 flex gap-4 border-b-4  p-4 dark:border-zinc-500"
                          >
                            <div className="flex flex-col justify-center border-r p-2 dark:border-zinc-500">
                              <button
                                onClick={() => {
                                  setSkillOpen(true);
                                  setSection(s);
                                }}
                                className=" w-44 rounded border p-4  dark:border-zinc-500 dark:bg-zinc-700"
                              >
                                {s.name}
                              </button>
                            </div>
                            <div className="w-full ">
                              {s.children.map((skill) => {
                                return (
                                  <div
                                    key={skill._id}
                                    className="flex w-full flex-col  border-b border-zinc-500  p-2"
                                  >
                                    <div className="flex items-center justify-between">
                                      <div className="pb-2 text-primary dark:text-primary-dark">
                                        {skill.name}
                                      </div>
                                      {skill.type === "pass/fail" && (
                                        <div className="text-sm text-primary dark:text-primary-dark">
                                          P/F
                                        </div>
                                      )}
                                      {skill.type === "score" && (
                                        <div className="text-sm text-primary dark:text-primary-dark">
                                          Pass Mark:{" "}
                                          {skill.scoreConfig.pass_score}
                                        </div>
                                      )}
                                    </div>
                                    <div className="flex h-full flex-col text-sm ">
                                      {skill.type === "score" &&
                                        skill.scoreConfig.scores.map(
                                          (score, i) => {
                                            return (
                                              <div
                                                key={i}
                                                className="flex  items-center"
                                              >
                                                <div className="border border-zinc-500 p-4">
                                                  {score.score}
                                                </div>
                                                <div className="w-full border border-zinc-500 p-4">
                                                  {score.name}
                                                </div>
                                              </div>
                                            );
                                          }
                                        )}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {/* <div className="flex p-2">
         <table className="w-full table-auto text-left">
         <thead>
         <tr>
         <th>Comp</th>
         <th>Modules</th>
         <th>Sections</th>
         <th>Skils</th>
         </tr>
         </thead>
         <tbody>
         {comp?.children?.map((m, i) => {
         return (
         <tr key={m._id}>
         <td rowSpan={3} className="">
         <button className="rounded border p-4 dark:border-zinc-500 dark:bg-zinc-700">
         {comp.name}
         </button>
         </td>
         <td>
         <button className="rounded border p-4 dark:border-zinc-500 dark:bg-zinc-700">
         {m.name}
         </button>
         </td>
         <td>
         {m.children.map((s) => {
         return (
         <div key={s._id} className=" dark:border-zinc-500">
         <div className="">
         <button className="rounded border p-4 dark:border-zinc-500 dark:bg-zinc-700">
         {s.name}
         </button>
         </div>
         </div>
         );
         })}
         </td>
         </tr>
         );
         })}
         </tbody>
         </table>
         </div> */}
      </>}
    </div>
  );
};
export default ChainedContext(CompetencyTree2, [
  [SettingsReceiver, "settings"],
  [OrganisationReciever, "organisation"],
]);
const TheFolderIcon = ({ isOpen }) =>
  isOpen ? (
    <FolderOpenIcon className="icon h-6 text-green-500" />
  ) : (
    <FolderIcon className="icon h-6 text-zinc-400" />
  );
const FileIcon = ({ filename }) => {
  return <FaceSmileIcon className="h-6" />;
};
