import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-hot-toast";
import { PencilIcon } from "@heroicons/react/24/outline";
import { TrashIcon } from "@heroicons/react/20/solid";
import { Dialog, Transition } from "@headlessui/react";
import { AuthComponents } from "../../lib/_sso";
import Box from "../../components/common/Box";
import API from "../../api";
import ChainedContext from "../../contexts/chainedContext";
import SettingsReceiver from "../../contexts/settings_context/Settings_Receiver";
import Select from "../../components/common/Select";
import { Skeleton } from "../../components/ui/skeleton";
import ToggleSwitch from "../../components/common/ToggleSwitch";

const CourseForm = ({
  reloadCourses,
  pickableRoles,
  isEdit = false,
  data = {},
}) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [validFor, setValidFor] = useState(1);
  const [pickedRoles, setPickedRoles] = useState([]);
  const [isRequired, setIsRequired] = useState(false);


  const isValidationPassed = useMemo(() => {
    if (name.length === 0) return false;
    if (description?.length === 0) return false;
    if (validFor.length === 0 || isNaN(validFor) || validFor <= 0) return false;

    return true;
  }, [name, description, validFor, pickedRoles]);

  useEffect(() => {
    if (data._id !== undefined) {
      setName(data.name);
      setDescription(data.description);
      setValidFor(data.validFor);
      setPickedRoles(data.roles);
      setIsRequired(data.isRequired);
    }
  }, [data]);

  const handleSubmit = () => {
    const payload = {
      name,
      description,
      validFor,
      roles: pickedRoles,
      isRequired
    };
    const api = isEdit
      ? API.settings.courses.update(data._id, payload)
      : API.settings.courses.create(payload);
    api
      .then(() => {
        if (isEdit) {
          toast.success("Updated course successfully.");
        } else {
          toast.success("Created new course successfully.");
        }

        setName("");
        setDescription("");
        setValidFor(1);
        setPickedRoles([]);
        setIsRequired(false);

        reloadCourses();
      })
      .catch((err) => {
        let errorMessage = "There has been and error";
        if (err.isAxiosError !== undefined) {
          if (err.response.data.message !== undefined) {
            errorMessage = err.response.data.message;
          }
        }
        toast.error(errorMessage);
      });
  };

  return (
    <div className="grid w-full items-center gap-2 px-2 align-middle xl:grid-cols-7">
      <div className="col-span-1">
        <label className=" text-center text-sm text-zinc-400">Name*</label>
        <input
          value={name}
          className="w-full rounded-md  border-gray-400 bg-white p-2 shadow-sm dark:bg-zinc-700"
          type={"text"}
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div className="col-span-2">
        <label className=" text-center text-sm text-zinc-400">
          Description*
        </label>
        <input
          value={description}
          className="w-full rounded-md  border-gray-400 bg-white p-1.5 shadow-sm dark:bg-zinc-700"
          type={"text"}
          onChange={(e) => setDescription(e.target.value)}
        />
      </div>
      <div className="col-span-1">
        <label className=" text-center text-sm text-zinc-400">
          Valid For* (years)
        </label>
        <input
          value={validFor}
          className="w-full rounded-md  border-gray-400 bg-white p-2 shadow-sm dark:bg-zinc-700"
          type={"number"}
          onChange={(e) => setValidFor(e.target.value)}
          placeholder="1"
        />
      </div>
      <div className="col-span-1">
        <label className=" text-center text-sm text-zinc-400">
          Is Required
        </label>
        <ToggleSwitch
          isChecked={isRequired}
          // label1={"No"}
          // label2={"Yes"}
          onChange={() => setIsRequired(!isRequired)}
        />
      </div>

      <div className="flex flex-col xl:col-span-1">
        <label className="pb-2 text-center text-sm text-zinc-400">Roles</label>
        <div className="flex w-full flex-col gap-2 ">
          <Select
            options={pickableRoles
            .filter((item) => pickedRoles.indexOf(item.name) === -1)
            .map((item, index) => {
              return {
                key: index,
                text: item.name,
                value: item.name,
              };
            })}
            value=""
            onChange={(e) => setPickedRoles((a) => a.concat([e.target.value]))}
          />
          <div className="flex gap-2 flex-wrap">
            {pickedRoles.map((c, i) => {
              return (
                <div key={i} className="rounded p-1 text-sm dark:bg-zinc-700">
                  {c}{" "}
                  <button
                    onClick={() =>
                      setPickedRoles((a) => a.filter((b) => b !== c))
                    }
                  >
                    X
                  </button>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className="  pt-6 text-right">
        <button
          disabled={!isValidationPassed}
          onClick={handleSubmit}
          className="h-10 cursor-pointer rounded-md border border-green-500 bg-green-300 bg-opacity-40 px-4 py-2 text-green-500 hover:border-green-300 disabled:cursor-not-allowed disabled:opacity-50"
        >
          {isEdit ? "Update" : "Add"}
        </button>
      </div>
    </div>
  );
};

const Courses = ({settings}) => {
  const [courses, setCourses] = useState([]);
  const [roles, setRoles] = useState([]);
  const [editCourse, setEditCourse] = useState({});
  const [deleteCourse, setDelete] = useState({});
  const [loading, setLoading] = useState(true);

  const pickableRoles = useMemo(() => {
    return roles.filter((role) => role.requiresCourse === true);
  }, [roles]);

  useEffect(() => {
    getData();
  }, [settings]);

  const getData = () => {
    setLoading(true);
    API.settings.courses.all().then((res) => {
      setCourses(res);
      setLoading(false);
    });
    API.settings.getRoles().then((res) => {
      setRoles(res);
    });
  };
  const handleDelete = () => {
    if (deleteCourse?._id) {
      API.settings.courses.delete(deleteCourse._id).then(() => {
        toast.success("Course deleted");
        setDelete({});
        getData();
      });
    }
  };
  const DeleteDialog = () => {
    if (deleteCourse._id === undefined) {
      return null;
    }
    return (
      <Transition
        show={true}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Dialog
          open={true}
          onClose={() => setDelete({})}
          className="fixed inset-0 z-10 overflow-y-auto"
        >
          <div className="min-h-screen text-center">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-80" />
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className="my-8 inline-block w-full max-w-2xl transform overflow-hidden rounded-2xl border-4 border-gray-700 bg-gray-50 p-6 text-left align-middle shadow-xl transition-all dark:bg-zinc-700">
              <div className="pt-4 text-center">
                <Dialog.Title className={"text-2xl"}>
                  Delete Course{" "}
                  <span className="font-bold italic">{deleteCourse.name}</span>?
                </Dialog.Title>
                <div className="py-4 text-ss">
                  This action is not reversible and will also result in any
                  course results linked to members being removed so please be
                  sure!
                </div>
              </div>
              <div className="mt-4 flex justify-between border-t border-zinc-500 pt-4">
                <button
                  className="rounded-lg bg-primary px-4 py-2 text-white"
                  onClick={() => setDelete({})}
                >
                  Cancel
                </button>
                <button
                  className="rounded-lg bg-red-500 px-4 py-2 text-white disabled:bg-red-500"
                  onClick={handleDelete}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  };

  return (
    <div className="space-y-4 p-4">
      {DeleteDialog()}
      <Box title="Courses">
        <CourseForm
          reloadCourses={() => {
            getData();
            setEditCourse({});
          }}
          pickableRoles={pickableRoles}
          data={editCourse}
          isEdit={editCourse._id !== undefined}
        />
        <hr />

        <div className="w-full pt-4">
          {loading && (
            <div className="col-span-2 space-y-2">
              <Skeleton className="h-10 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
              <Skeleton className="h-10 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
              <Skeleton className="h-10 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
              <Skeleton className="h-10 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
            </div>
          )}
          {!loading && courses.length > 0 && (
            <table className="tableClass">
              <thead className="tableHeadClass">
              <tr className="tableHeadRowClass">
                <th className="tableHeadCellClass">Name</th>
                <th className="tableHeadCellClass">Description</th>
                <th className="tableHeadCellClass">Valid For</th>
                <th className="tableHeadCellClass">Is Required</th>
                <th className="tableHeadCellClass">Roles</th>
                <th className="tableHeadCellClass"></th>
              </tr>
              </thead>
              <tbody className="tableBodyClass">
                {courses.toSorted((a, b) => {
                  if(a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                  if(a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                  return 0;
                }).map((d) => {
                  return (
                    <tr className="tableBodyRowClass" key={d._id}>
                      <td className="tableBodyCellClass">{d.name}</td>
                      <td className="tableBodyCellClass">{d.description}</td>
                      <td className="tableBodyCellClass w-28">{d.validFor}</td>
                      <td className="tableBodyCellClass w-28">{d.isRequired ? "Yes" : "No"}</td>
                      <td className="tableBodyCellClass text-center">
                        <div className="flex gap-2 flex-wrap">
                          {d.roles.map((r, i) => {
                            return (
                              <div
                                key={i}
                                className="m-1 rounded p-1 dark:bg-zinc-700"
                              >
                                {r}
                              </div>
                            );
                          })}
                        </div>
                      </td>
                      <td className="tableBodyCellClass w-32 space-x-1 text-center">
                          <button
                            onClick={() => setEditCourse(d)}
                            className="rounded-md border bg-primary bg-opacity-80 px-2 py-1 text-sm text-white hover:bg-gray-800 disabled:opacity-20"
                          >
                            <PencilIcon className="h-6 w-6 text-yellow-200"/>
                          </button>
                          <button
                            className="rounded-md border border-red-400 bg-red-700 bg-opacity-80 px-2 py-1 text-sm text-white hover:border-red-300 hover:bg-red-800 disabled:opacity-20"
                            onClick={() => setDelete(d)}
                          >
                            <TrashIcon className="h-6 w-6 text-red-200"/>
                          </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      </Box>
    </div>
  );
};
export default ChainedContext(Courses, [[SettingsReceiver, "settings"]]);
