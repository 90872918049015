import React, { useState, useEffect, useMemo } from "react";
import dayjs from "dayjs";

const Select = ({
  date,
  diffLabel = "Age",
  label = "Date of Birth",
  submit,
  back = true,
  maxOffset = 100,
  // options = [],
  error,
  disabled,
  hideBlank = false,
  hideAge = false,
}) => {
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [age, setAge] = useState("0");

  useEffect(() => {
    if (date) {
      setDay(dayjs(date).format("D"));
      setMonth(dayjs(date).format("MM"));
      setYear(dayjs(date).format("YYYY"));
    }
  }, [date]);
  useEffect(() => {
    if (day !== "" && month !== "" && year !== "") {
      const theDate = dayjs(year + "-" + month + "-" + day);
      setAge(dayjs().diff(dayjs(year + "-" + month + "-" + day), "years"));
      submit(theDate);
    }
  }, [day, month, year]);

  const [days, months, years] = useMemo(() => {
    const days = [];
    for (let i = 1; i < 32; i++) {
      days.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }

    const years = [];
    let year = 0;
    const thisYear = new Date().getFullYear();
    for (let i = 0; i <= maxOffset; i++) {
      if (back) {
        year = thisYear - i;
      } else {
        year = thisYear + i;
      }

      years.push(
        <option key={i} value={year}>
          {year}
        </option>
      );
    }

    const months = [];
    let month_value = "";
    let monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    for (let i = 0; i < monthNames.length; i++) {
      let month_number = i + 1;
      month_value = month_number <= 9 ? "0" + month_number : month_number;
      months.push(
        <option key={i} value={month_value}>
          {monthNames[i]}
        </option>
      );
    }

    return [days, months, years];
  }, [maxOffset]);

  return (
    <div className="w-full rounded border p-1 dark:border-zinc-700">
      <label className={`labelClass `}>{label}</label>
      <div className=" grid w-full grid-cols-4 items-center gap-2">
        <div className="">
          <select
            disabled={disabled}
            value={day}
            onChange={(e) => setDay(e.target.value)}
            autoComplete="off"
            className={`block  w-full rounded border  bg-white px-1 py-2  text-black shadow-sm focus:outline-none dark:border-zinc-500 dark:bg-zinc-700 dark:text-white   sm:text-sm ${
              error ? "border-red-400" : "border-zinc-300"
            }`}
          >
            {!hideBlank ? <option value="">{"--"}</option> : ""}
            {days}
          </select>
          <label
            className={`labelClass ${
              error ? "text-red-400" : "text-primary dark:text-zinc-400"
            }`}
          >
            Day
          </label>
        </div>
        <div className=" ">
          <select
            disabled={disabled}
            value={month}
            onChange={(e) => setMonth(e.target.value)}
            autoComplete="off"
            className={`block  w-full rounded border  bg-white px-1 py-2 text-black shadow-sm focus:outline-none dark:border-zinc-500  dark:bg-zinc-700 dark:text-white   sm:text-sm ${
              error ? "border-red-400" : "border-zinc-300"
            }`}
          >
            {!hideBlank ? <option value="">{"--"}</option> : ""}
            {months}
          </select>
          <label
            className={`labelClass   ${
              error ? "text-red-400" : "text-primary dark:text-zinc-400"
            }`}
          >
            Month
          </label>
        </div>
        <div className="">
          <select
            disabled={disabled}
            value={year}
            onChange={(e) => setYear(e.target.value)}
            autoComplete="off"
            className={`block  w-full rounded border  bg-white px-1 py-2 text-black shadow-sm focus:outline-none dark:border-zinc-500  dark:bg-zinc-700 dark:text-white   sm:text-sm ${
              error ? "border-red-400" : "border-zinc-300"
            }`}
          >
            {!hideBlank ? <option value="">{"--"}</option> : ""}
            {years}
          </select>
          <label
            className={`labelClass   ${
              error ? "text-red-400" : "text-primary dark:text-zinc-400"
            }`}
          >
            Year
          </label>
        </div>
        {!hideAge && (
          <div className=" text-center text-primary dark:text-zinc-300">
            <div className="px-2 text-xl">{age}</div>
            <label className={`labelClass   `}>{diffLabel}</label>
          </div>
        )}
      </div>
    </div>
  );
};
export default Select;
