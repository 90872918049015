import React, { useState, useEffect, useCallback } from "react";
import dayjs from "dayjs";
import API from "../../../api";
import Box from "../../../components/common/Box";
import Stat from "../../../components/common/Stat";
import { Disclosure } from "@headlessui/react";
import {
  ChevronUpIcon,
  HandThumbDownIcon,
  HandThumbUpIcon,
} from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";
import ChainedContext from "../../../contexts/chainedContext";
import MemberReceiver from "../../../contexts/member_context/Member_Receiver";
import SettingsReceiver from "../../../contexts/settings_context/Settings_Receiver";
import ArrayCard from "../../../components/common/ArrayCard";
import RoleCard from "../../../components/common/RoleCard";
import DataRow from "../../../components/common/DataRow";
import { Skeleton } from "../../../components/ui/skeleton";
import DisplayAndUpdatePhoto from "./_components/DisplayAndUpdatePhoto";
import DayJS from "dayjs";
import RequirementsGrid from "../../../components/widgets/RequirementsGrid";
import {toast} from "react-hot-toast";
import RequiredCoursesGrid from "./_components/RequiredCoursesGrid";

function MemberPage({ member, settings }) {
  const [memberRoles, setMemberRoles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [requirements, setRequirements] = useState([]);
  const [stations, setStations] = useState([]);

  const navigate = useNavigate();

  const getRequirementsData = useCallback(
    () =>
      API.member.requirements.all(member._id).then((res) => {
        setRequirements(res);
      }),
    [member]
  );
  const getStationsData = useCallback(() => {
    API.settings.stations.getStations().then((res) => {
      setStations(res);
    });
  }, []);

  useEffect(() => {
    getRequirementsData();
    getStationsData();
  }, [getRequirementsData, getStationsData]);

  useEffect(() => {
    getMemberRoles();
  }, []);

  const handleGetUploadSpec = () => {
    return API.member.photo.getUploadConfig(member._id);
  };
  const handleGettingPhoto = () => {
    return API.member.photo.getAccessLink(member._id);
  };
  const handlePhotoUpload = (formData) => {
    return API.member.photo.uploadPhoto(member._id, formData).then((a) => {
      member._refresh();
      return a;
    });
  };

  const handleRequirementUpdate = (reqId, data) => {
    return API.member.requirements.update(member._id, reqId, data);
  };

  const getMemberRoles = () => {
    API.member.getMemberRoles(member._id).then((res) => {
      setMemberRoles(res);
    }).catch((err) => {
      console.log(err);
    }).finally(() => {
      setLoading(false);
    });
  };
  const handleAddRole = (e) => {
    const addingRole = toast.loading("Adding role...");
    return API.member.addRole(member._id, { role: e }).then(() => {
      getMemberRoles();
      toast.success("Successfully added role to member");
    }).catch(err => {
      toast.error("There was an error while adding role: " + (err.response?.data?.message || err.message))
    }).finally(() => {
      toast.dismiss(addingRole);
    })
  };
  const handleRemoveRole = (e) => {
    const removingRole = toast.loading("Removing role...");
    return API.member.deleteRole(member._id, e._id).then(() => {
      getMemberRoles();
      toast.success("Successfully removed role from member");
    }).catch(err => {
      toast.error("There was an error while removing role: " + (err.response?.data?.message || err.message))
    }).finally(() => {
      toast.dismiss(removingRole);
    })
  }

  return (
    <div className="w-full space-y-2 p-4">
      {loading && (
        <div className="grid grid-cols-3 gap-4">
          <div className="space-y-8">
            <Skeleton className="h-80 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
            <Skeleton className="h-80 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
          </div>
          <div className="col-span-2 space-y-8">
            <Skeleton className="h-52 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
            <Skeleton className="h-20 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
            <Skeleton className="h-20 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
          </div>
        </div>
      )}

      {member.isArchived && <div className={"p-2 border rounded border-red-400 dark:border-red-800"}>Team Member has been archived. {!member.keepAccount && <span className={"font-bold"}>There account is marked for deletion!</span>}</div>}
      {!loading && (
        <div className="grid gap-4 lg:grid-cols-3 ">
          <Box
            title={`${member.firstName} ${member.lastName} `}
            button={{
              text: "Edit",
              colour: "bg-blue-400 text-sm text-white hover:text-gray-200",
              action: () => navigate(`/team/${member._id}/edit`),
            }}
          >
            <div className="w-full  p-1">
              <DisplayAndUpdatePhoto
                className="w-full border-8 border-white shadow-lg dark:shadow-zinc-600"
                canUpdate={true}
                onGetUploadSpec={handleGetUploadSpec}
                onGetPhoto={handleGettingPhoto}
                onUpload={handlePhotoUpload}
              />
            </div>
            <div className="">
              <div className="grid divide-x xl:grid-cols-2">
                <DataRow label={"Callsign"} value={member.callsign} />
                <DataRow
                  label={" Force ID"}
                  value={member.forceId ? member.forceId : " -- "}
                />
              </div>

              <DataRow label={"Phone"} value={member.phone} />
              <DataRow label={"Central/Login Email"} value={member.email} />
              <DataRow label={"Email"} value={member.email_secondary} />
              <DataRow label={"Town"} value={member.Town} />
              <div className="grid divide-x 2xl:grid-cols-2">
                <DataRow
                  label={"DOB"}
                  value={
                    <div className="text-right">
                      <div>
                        {member?.dob
                          ? dayjs(member?.dob).format("DD-MM-YYYY")
                          : "--"}
                      </div>
                      <div className="subtle text-sm">
                        {member?.dob
                          ? dayjs().diff(dayjs(member?.dob), "years") + " years"
                          : "--"}
                      </div>
                    </div>
                  }
                />
                <DataRow
                  label={"Date Joined"}
                  value={
                    <div className="text-right">
                      <div>
                        {member?.dateJoined
                          ? dayjs(member?.dateJoined).format("DD-MM-YYYY")
                          : "--"}
                      </div>
                      <div className="subtle text-sm">
                        {member?.dateJoined
                          ? dayjs().diff(dayjs(member?.dateJoined), "years") +
                            " years"
                          : "--"}
                      </div>
                    </div>
                  }
                />
              </div>
            </div>
            <div className="">
              <Disclosure defaultOpen={true}>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full justify-between  bg-zinc-200 px-4 py-2 text-left font-medium dark:bg-zinc-700  ">
                      <span>Roles</span>
                      <ChevronUpIcon
                        className={`${
                          !open ? "rotate-180 transform" : ""
                        } h-5 w-5 dark:text-zinc-200`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="px-4 pb-2 pt-4 text-sm text-gray-500">
                      <RoleCard
                        data={memberRoles}
                        onAdd={handleAddRole}
                        onDelete={handleRemoveRole}
                      />
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
              {stations.length > 0 && (
                <Disclosure as="div" className="mt-2">
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex w-full justify-between bg-zinc-200 px-4 py-2 text-left font-medium dark:bg-zinc-700  ">
                        <span>
                          Stations (
                          {(member.stations && member.stations.length) || 0})
                        </span>
                        <ChevronUpIcon
                          className={`${
                            !open ? "rotate-180 transform" : ""
                          } h-5 w-5 dark:text-zinc-200`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="px-4 pb-2 pt-4 text-sm text-gray-500">
                        <ArrayCard
                          title="Stations"
                          list={stations.map((a) => {
                            return {
                              _id: a._id,
                              title: a.name,
                            };
                          })}
                          data={member.stations || []}
                          isIds={true}
                          submit={async (e) => {
                            return API.member
                              .update(member._id, { stations: e })
                              .then(() => {
                                member._refresh();
                              });
                          }}
                        />
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              )}
              {settings.sections.length > 0 && (
                <Disclosure as="div" className="mt-2">
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex w-full justify-between bg-zinc-200 px-4 py-2 text-left font-medium dark:bg-zinc-700  ">
                        <span>Sections ({member.sections.length})</span>
                        <ChevronUpIcon
                          className={`${
                            !open ? "rotate-180 transform" : ""
                          } h-5 w-5 dark:text-zinc-200`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="px-4 pb-2 pt-4 text-sm text-gray-500">
                        <ArrayCard
                          title="Sections"
                          list={settings.sections}
                          data={member.sections}
                          submit={(e) => {
                            return API.member
                              .update(member._id, { sections: e })
                              .then(() => {
                                member._refresh();
                              });
                          }}
                        />
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              )}
              <Disclosure as="div" className="mt-2">
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full justify-between bg-zinc-200 px-4 py-2 text-left font-medium dark:bg-zinc-700  ">
                      <span>
                        Driving Categories ({member.drivingCat.length})
                      </span>
                      <ChevronUpIcon
                        className={`${
                          !open ? "rotate-180 transform" : ""
                        } h-5 w-5 dark:text-zinc-200`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="px-4 pb-2 pt-4 text-sm text-gray-500">
                      <ArrayCard
                        title="Driving Categories"
                        list={settings.drivingCat}
                        data={member.drivingCat}
                        submit={(e) => {
                          return API.member
                            .update(member._id, { drivingCat: e })
                            .then(() => {
                              member._refresh();
                            });
                        }}
                        member={member}
                      />
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
              <Disclosure as="div" className="mt-2">
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full justify-between bg-zinc-200 px-4 py-2 text-left font-medium dark:bg-zinc-700  ">
                      <span>
                        Awards ({member.awards.length})
                        {/* <button
                        className="ml-2 rounded bg-zinc-800 px-2 py-1.5 text-green-500"
                        onClick={() => setShowAwards(true)}
                      >
                        <PlusIcon className="h-4 " />
                      </button> */}
                      </span>

                      <ChevronUpIcon
                        className={`${
                          !open ? "rotate-180 transform" : ""
                        } h-5 w-5 dark:text-zinc-200`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="px-4 pb-2 pt-4 text-sm text-gray-500">
                      <ArrayCard
                        title="Awards"
                        list={settings.awards}
                        data={member.awards}
                        submit={(e) => {
                          return API.member
                            .update(member._id, { awards: e })
                            .then(() => {
                              member._refresh();
                            });
                        }}
                      />
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </div>
          </Box>
          <div className="col-span-2 space-y-4">
            <Box title="Basic Details">
              <div className="flex flex-wrap gap-2 p-2">
                <Stat label="Last Callout" value={(member._lastCalloutAt !== undefined && member._lastCalloutAt !== null) ? DayJS(member._lastCalloutAt).format("DD-MM-YYYY") : "[N/A]"} />
                <Stat label="Callouts in 12 months" value={member._totalCalloutsLast12} />
                <Stat label="Total Callouts" value={member._totalCallouts} />
                <Stat
                  label="Operational"
                  subLabel={
                    member.operationalAt !== undefined
                      ? "Since: " +
                        DayJS(member.operationalAt).format("DD MMM YYYY HH:mm")
                      : ""
                  }
                  value={
                    member.operational ? (
                      <HandThumbUpIcon className="h-8 text-green-400" />
                    ) : (
                      <HandThumbDownIcon className="h-8 text-red-400" />
                    )
                  }
                  colour={member.operational ? "oncall" : "nonOp"}
                  action={() =>
                    API.member
                      .update(member._id, { operational: !member.operational })
                      .then(() => {
                        member._refresh();
                        toast.success("Successfully updated Operational status")
                      }).catch(err => {
                        toast.error(err?.response?.data?.message || err.message)
                    })
                  }
                />
                <Stat
                  label={member.onCall ? "On-Call" : "Off-Call"}
                  subLabel={
                    member.onCallAt !== undefined
                      ? "Since: " +
                        DayJS(member.onCallAt).format("DD MMM YYYY HH:mm")
                      : ""
                  }
                  value={
                    member.onCall ? (
                      <HandThumbUpIcon className="h-8 text-green-400" />
                    ) : (
                      <HandThumbDownIcon className="h-8 text-red-400" />
                    )
                  }
                  colour={member.onCall ? "oncall" : "nonOp"}
                  action={() =>
                    member.operational &&
                    API.member
                      .update(member._id, { onCall: !member.onCall })
                      .then(() => {
                        member._refresh();
                        toast.success("Successfully updated oncall status")
                      }).catch(err => {
                        toast.error(err?.response?.data?.message || err.message)
                    })
                  }
                />
              </div>
            </Box>

            <RequirementsGrid
              requirements={requirements}
              onUpdate={handleRequirementUpdate}
              onReload={() => getRequirementsData()}
            />
            <RequiredCoursesGrid member={member} />
          </div>
        </div>
      )}
    </div>
  );
}
export default ChainedContext(MemberPage, [
  [MemberReceiver, "member"],
  [SettingsReceiver, "settings"],
]);