import React, { useState, useEffect } from "react";
import API from "../../api";
import Box from "../../components/common/Box";
import { useNavigate } from "react-router-dom";
import SettingsReceiver from "../../contexts/settings_context/Settings_Receiver";
import ChainedContext from "../../contexts/chainedContext";
import { Skeleton } from "../../components/ui/skeleton";
import VehicleList from "./_components/VehicleList";
import ToggleSwitch from "../../components/common/ToggleSwitch";

function VehiclesList({ settings }) {
  const [vehicles, setVehicles] = useState([]);
  const [seats, setSeats] = useState(null);
  const [tyres, setTyres] = useState(null);
  const [sortByName, setSortByName] = useState(true);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState("");
  const [unitVehicles, setUnitVehicles] = useState(false);
  const [approvedVehicles, setApprovedVehicles] = useState(false);
  const [nonApprovedVehicles, setNonApprovedVehicles] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    getData();
    settings._refresh();
  }, []);

  const getData = () => {
    API.vehicles
      .all()
      .then((res) => {
        setVehicles(res);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getFilteredMembers = () => {
    let filterItems = vehicles

      .filter((item) => {
        let tyresMatch = true;
        if (tyres !== null) {
          tyresMatch = item.tyres === tyres;
        }
        return tyresMatch;
      })
      .filter((item) => {
        let unitMatch = true;
        if (unitVehicles !== null) {
          if (unitVehicles === true) {
            unitMatch = item.unitVehicle === true;
          }
        }
        return unitMatch;
      })
      .filter((item) => {
        let approvedMatch = true;
        if (approvedVehicles !== null) {
          if (approvedVehicles === true) {
            approvedMatch = item.approved === true;
          }
        }
        return approvedMatch;
      })
      .filter((item) => {
        let approvedMatch = true;
        if (nonApprovedVehicles !== null) {
          if (nonApprovedVehicles === true) {
            approvedMatch = item.approved === false;
          }
        }
        return approvedMatch;
      })
      .filter((item) => {
        let seatsMatch = true;
        if (seats !== null) {
          seatsMatch = item.seats >= seats;
        }
        return seatsMatch;
      })
      .filter((item) => {
        if (filter === "") return true;
        let kitMatch = [];
        if (item.kit) {
          kitMatch = item.kit.filter((r) => {
            return r === filter;
          });
        }
        return kitMatch.length >= 1;
      });

    return filterItems.sort((a, b) => {
      if (sortByName) {
        if (a.lastName < b.lastName) {
          return -1;
        }
        if (a.lastName > b.lastName) {
          return 1;
        }
        return 0;
      } else {
        if (a.onCall < b.onCall) {
          return 1;
        }
        if (a.onCall > b.onCall) {
          return -1;
        }
        return 0;
      }
    });
  };

  return (
    <div className="w-full space-y-4 p-2 xl:p-4">
      <Box title="Filters">
        <div className="grid items-center gap-4 p-2 xl:grid-cols-5">
          <div className="xl:col-span-3">
            <div className="flex items-start gap-4">
              <div className="w-80 space-y-8 pt-4">
                <ToggleSwitch
                  label={"Unit Vehicles Only"}
                  isChecked={unitVehicles}
                  onChange={() => {
                    setUnitVehicles(!unitVehicles);
                  }}
                />
                <ToggleSwitch
                  label={"Approved Vehicles Only"}
                  isChecked={approvedVehicles}
                  onChange={() => {
                    setNonApprovedVehicles(false);
                    setApprovedVehicles(!approvedVehicles);
                  }}
                />
                <ToggleSwitch
                  label={"Non-Approved Vehicles Only"}
                  isChecked={nonApprovedVehicles}
                  onChange={() => {
                    setApprovedVehicles(false);
                    setNonApprovedVehicles(!nonApprovedVehicles);
                  }}
                />
              </div>
              <div className="col-span-1 rounded bg-zinc-200 bg-opacity-40 p-1 shadow dark:bg-zinc-800 dark:bg-opacity-40">
                <div className="labelClass text-xl">Seat filter</div>
                <div className="flex flex-wrap gap-4 p-2">
                  <button
                    onClick={() => setSeats(2)}
                    className={`w-14 rounded border  p-2 ${
                      seats === 2 ? "border-green-500" : "border-zinc-500"
                    } text-sm hover:border-zinc-400`}
                  >
                    2
                  </button>
                  <button
                    onClick={() => setSeats(3)}
                    className={`w-14 rounded border  p-2 ${
                      seats === 3 ? "border-green-500" : "border-zinc-500"
                    } text-sm hover:border-zinc-400`}
                  >
                    3
                  </button>
                  <button
                    onClick={() => setSeats(4)}
                    className={`w-14 rounded border  p-2 ${
                      seats === 4 ? "border-green-500" : "border-zinc-500"
                    } text-sm hover:border-zinc-400`}
                  >
                    4
                  </button>
                  <button
                    onClick={() => setSeats(5)}
                    className={`w-14 rounded border  p-2 ${
                      seats === 5 ? "border-green-500" : "border-zinc-500"
                    } text-sm hover:border-zinc-400`}
                  >
                    5
                  </button>
                  <button
                    onClick={() => setSeats(7)}
                    className={`w-14 rounded border  p-2 ${
                      seats === 7 ? "border-green-500" : "border-zinc-500"
                    } text-sm hover:border-zinc-400`}
                  >
                    7+
                  </button>
                  {seats !== null && (
                    <button
                      onClick={() => setSeats(null)}
                      className={`w-14 rounded border  p-2 ${
                        seats !== null ? "border-red-500" : "border-zinc-500"
                      } text-sm hover:border-zinc-400`}
                    >
                      clear
                    </button>
                  )}
                </div>
              </div>
              <div className="col-span-1 rounded bg-zinc-200 bg-opacity-40 p-1 shadow dark:bg-zinc-800 dark:bg-opacity-40">
                <div className="labelClass text-xl">Tyre filter</div>
                <div className="flex flex-wrap gap-4 p-2">
                  <button
                    onClick={() => setTyres("Road")}
                    className={` rounded border  p-2 ${
                      tyres === "Road" ? "border-green-500" : "border-zinc-500"
                    } text-sm hover:border-zinc-400`}
                  >
                    Road
                  </button>
                  <button
                    onClick={() => setTyres("Mud")}
                    className={` rounded border  p-2 ${
                      tyres === "Mud" ? "border-green-500" : "border-zinc-500"
                    } text-sm hover:border-zinc-400`}
                  >
                    Mud
                  </button>
                  <button
                    onClick={() => setTyres("Mud/Snow")}
                    className={` rounded border  p-2 ${
                      tyres === "Mud/Snow"
                        ? "border-green-500"
                        : "border-zinc-500"
                    } text-sm hover:border-zinc-400`}
                  >
                    Mud/Snow
                  </button>
                  <button
                    onClick={() => setTyres("All Terrain")}
                    className={` rounded border  p-2 ${
                      tyres === "All Terrain"
                        ? "border-green-500"
                        : "border-zinc-500"
                    } text-sm hover:border-zinc-400`}
                  >
                    All Terrain
                  </button>

                  {tyres !== null && (
                    <button
                      onClick={() => setTyres(null)}
                      className={`w-14 rounded border  p-2 ${
                        tyres !== null ? "border-red-500" : "border-zinc-500"
                      }`}
                    >
                      clear
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className=" h-full rounded bg-zinc-200  bg-opacity-40 p-1 shadow dark:bg-zinc-800 dark:bg-opacity-40 xl:col-span-2 ">
            <div className="">
              <div className="labelClass text-xl">Kit filter</div>

              <div className="flex flex-wrap gap-8 p-2">
                {settings.vehicleKit &&
                  settings.vehicleKit.length &&
                  settings.vehicleKit.map((r, i) => {
                    return (
                      <button
                        key={i}
                        onClick={() => setFilter(r)}
                        className={` rounded border p-2 ${
                          filter === r ? "border-green-500" : "border-zinc-500"
                        } text-sm hover:border-zinc-400`}
                      >
                        {r}
                      </button>
                    );
                  })}

                {filter !== "" && (
                  <button
                    onClick={() => setFilter("")}
                    className={`w-14 rounded border  p-2 ${
                      filter !== null ? "border-red-500" : "border-zinc-500"
                    }`}
                  >
                    clear
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </Box>
      <Box
        title="Vehicles"
        button={{
          text: "Add",
          colour:
            "bg-green-600 text-zinc-300 hover:bg-primary hover:text-white ",
          action: () => navigate(`/vehicles/add`),
        }}
      >
        {loading && (
          <div className="space-y-6 p-4">
            <div className="flex gap-8">
              <Skeleton className="h-8 w-1/3 dark:bg-zinc-700"></Skeleton>
              <Skeleton className="h-8 w-1/3 dark:bg-zinc-700"></Skeleton>
              <Skeleton className="h-8 w-1/3 dark:bg-zinc-700"></Skeleton>
            </div>
            <div className="flex gap-8">
              <Skeleton className="h-8 w-1/2 dark:bg-zinc-700"></Skeleton>
              <Skeleton className="h-8 w-1/2 dark:bg-zinc-700"></Skeleton>
            </div>
          </div>
        )}
        {!loading && (
          <div>
            <VehicleList
              vehicles={getFilteredMembers()}
              vehicle={(id) => navigate(`/vehicles/${id}`)}
            />
          </div>
        )}
      </Box>
    </div>
  );
}
export default ChainedContext(VehiclesList, [
  [SettingsReceiver, "settings"]
]);