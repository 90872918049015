import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import API from "../../api";
import RequirementsOverviewTable from "./_components/RequirementsOverviewTable";
import RequirementsDetailedTable from "./_components/RequirementsDetailedTable";
import Box from "../../components/common/Box";

const Requirements = () => {
  const [requirements, setRequirements] = useState([]);
  const [search, setSearch] = useSearchParams();

  const handleTitle = (event) => {
    if (event === null) {
      setSearch({});
    } else {
      setSearch({ id: event._id, label: event.title });
    }
  };
  useEffect(() => {
    API.settings.requirements.all().then((res) => {
      setRequirements(res);
    });
  }, []);

  if (requirements.length === 0) {
    return <div>There are no requirements defined in settings.</div>;
  }

  return (
    <div className={"p-2"}>
      <h1 className={"p-2 text-2xl"}>Requirements Report</h1>

      <Box title="Filter">
        <div className={"flex gap-2 flex-wrap p-4"}>
          {requirements.map((requirement) => {
            return (
              <button
                className={`border p-2 ${
                  search.get("id") === requirement._id &&
                  "bg-green-200 dark:bg-green-800"
                }`}
                key={requirement._id}
                onClick={() => handleTitle(requirement)}
              >
                {requirement.title}
              </button>
            );
          })}
          {search.get("id") !== null && (
            <button
              className={`border bg-red-400 p-2`}
              onClick={() => handleTitle(null)}
            >
              Clear
            </button>
          )}
        </div>
      </Box>

      {search.get("id") === null && (
        <RequirementsOverviewTable requirements={requirements} />
      )}
      {search.get("id") !== null && (
        <RequirementsDetailedTable
          requirements={requirements}
          requirement={search.get("id")}
          title={search.get("label")}
        />
      )}
    </div>
  );
};
export default Requirements;
