import React, {useEffect, useMemo, useState} from "react";
import DayJS from "dayjs";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import {useNavigate, useSearchParams} from "react-router-dom";
import {toast} from "react-hot-toast";
import api from "../../../api";


const RecruitEvents = () => {
  const [events, setEvents] = useState([]);
  const navigate = useNavigate();
  const [search, setSearch] = useSearchParams();

  useEffect(() => {
    api.calendar.retrieveAllRecruitmentEvents().then((res) => {
      setEvents(res);
    }).catch(() => {
      toast.error(
        "Unable to retrieve competencies training dates from server."
      );
    });
  }, []);

  const initialDate = useMemo(() => {
    if(search.get("startAt") !== null){
      return search.get("startAt");
    }
    return null;
  }, [search]);
  const initialView = useMemo(() => {
    if(search.get("view") !== null){
      return search.get("view");
    }
    return "";
  }, [search]);

  const handleEventClick = ({ event, el }) => {
    const ev = event.toPlainObject().extendedProps;
    if (ev) {
      navigate(`/recruitment/events/event/${ev._id}`);
    }
  };
  const handleDateClick = (selectInfo) => {
    navigate(`/recruitment/events/create?startAt=${DayJS(selectInfo.date).toISOString()}&endAt=${DayJS(selectInfo.date).add(2, "hours").set("minute", 0).toISOString()}`);
  };
  const handleCalenderViewChange = dateInfo => {
    const startAt = (dateInfo.view.type === "dayGridMonth" && DayJS(dateInfo.start).get("date") !== 1) ?
      DayJS(dateInfo.start).startOf("month").add(1, "month").format("YYYY-MM-DD")
      :
      DayJS(dateInfo.start).format("YYYY-MM-DD");
    setSearch({
      startAt,
      view: dateInfo.view.type
    })
  };

  return (
    <div className="h-1/2 p-2">
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        height={"99vh"}
        locale={"en-GB"}
        firstDay={1}
        editable
        headerToolbar={{
          left: "prev,next today myCustomButton",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        }}
        selectable={true}
        dateClick={handleDateClick}
        eventClick={handleEventClick}
        events={events}
        forceEventDuration={true}
        eventTimeFormat={{
          // like '14:30:00'
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        }}
        datesSet={handleCalenderViewChange}
        initialDate={initialDate}
        initialView={initialView}
        customButtons={{
          myCustomButton: {
            text: "Add Event",
            click: function () {
              handleDateClick({
                date: DayJS().add(1, "hours").set("minute", 0).toDate(),
                end: DayJS().add(2, "hours").set("minute", 0).toDate()
              });
            },
          },
        }}
      />
    </div>
  );
}
export default RecruitEvents;