import React, { useState, useEffect } from "react";
import { Disclosure, Transition, Dialog } from "@headlessui/react";
import Select from "./Select";
import { PlusIcon, XMarkIcon } from "@heroicons/react/20/solid";
import ConfirmBox from "./Confirm";

const ArrayCard = ({
  title,
  list,
  data,
  isIds = false,
  submit,
  textSize = "text-2xl",
}) => {
  const [show, setShow] = useState(false);
  const [value, setValue] = useState("");
  const [confirm, setConfirm] = useState(false);

  const handleAdd = () => {
    let current = data;
    current.push(value);
    submit(current).then(() => {
      setShow(false);
      setValue("");
    });
  };
  const handleDelete = () => {
    let current = data.filter((item) => {
      return item !== value;
    });
    submit(current).then(() => {
      setConfirm(false);
      setValue("");
    });
  };
  const handleDeleteCancel = () => {
    setConfirm(false);
    setValue("");
  }
  const modal = () => {
    return (
      <Transition
        show={show}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Dialog
          open={show}
          onClose={() => setShow(false)}
          className="fixed inset-0 z-50 overflow-y-auto "
        >
          <div className="min-h-screen text-center">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-80" />
            <span className="inline-block h-screen " aria-hidden="true">
              &#8203;
            </span>
            <div className="my-8 inline-block w-full max-w-2xl transform overflow-hidden rounded-2xl border-4 border-gray-700 bg-zinc-200 p-6 text-left align-middle shadow-xl transition-all dark:bg-zinc-800">
              <div className="border-b border-zinc-500 pt-4 text-2xl">
                <Dialog.Title>{title}</Dialog.Title>
              </div>
              <div className="pt-4">
                <Select
                  options={
                    (list !== undefined &&
                      list
                        .filter((item) => {
                          if (isIds) {
                            return data.indexOf(item._id) === -1;
                          }
                          if (data !== undefined) {
                            return data.indexOf(item) === -1;
                          }
                          return true;
                        })
                        .map((item, index) => {
                          return {
                            key: index,
                            text: item.title !== undefined ? item.title : item,
                            value: item._id !== undefined ? item._id : item,
                          };
                        })) ||
                    []
                  }
                  onChange={(e) => setValue(e.target.value)}
                />
              </div>

              <div className="mt-4 flex justify-between border-t border-gray-700 pt-4">
                <button
                  className="rounded-lg bg-red-600 px-4 py-2 text-white"
                  onClick={() => setShow(false)}
                >
                  Cancel
                </button>
                <button
                  className="rounded-lg bg-blue-600 px-4 py-2 text-white"
                  onClick={handleAdd}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  };
  return (
    <div className="">
      {modal()}
      <ConfirmBox show={confirm !== false} action={handleDelete} onClose={handleDeleteCancel} title={`Remove "${confirm}"?`} />

      <div
        className={`flex justify-between border-b border-zinc-500 ${textSize} `}
      >
        {title}{" "}
        <span className="">
          <button
            onClick={() => {
              setShow(true);
            }}
          >
            <PlusIcon className="h-6 text-green-500" />
          </button>
        </span>
      </div>
      <div className="flex flex-wrap gap-2 p-2 ">
        {data.map((d, i) => {
          let title;
          if (isIds) {
            const found = list.filter((a) => a._id === d);
            title = found[0] !== undefined ? found[0].title : "[Unknown]";
          } else {
            title = d;
          }
          return (
            <div
              key={i}
              className="flex flex-nowrap rounded bg-zinc-300 p-2 text-black dark:bg-zinc-700 dark:text-white"
            >
              {title}{" "}
              <button
                onClick={() => {
                  setValue(d);
                  setConfirm(title);
                }}
              >
                <XMarkIcon className="ml-3 h-4 hover:text-red-500" />
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default ArrayCard;
