import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import API from "../../api";
import Box from "../../components/common/Box";
import { Link, useParams, useNavigate } from "react-router-dom";
import Blank from "../../assets/images/vblank.png";
import { CheckIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { toast } from "react-hot-toast";
import { Tab, Dialog, Transition } from "@headlessui/react";
import DatePicker from "react-datepicker";
import { Skeleton } from "../../components/ui/skeleton";
import ChainedContext from "../../contexts/chainedContext";
import SettingsReceiver from "../../contexts/settings_context/Settings_Receiver";
import ArrayCard from "../../components/common/ArrayCard";
import DataRow from "../../components/common/DataRow";
import DataBox from "../../components/common/DataBox";
import VehicleCheckList from "./_components/VehicleCheckList";
import AssetList from "./_components/AssetList";
import DisplayAndUpdatePhoto from "../team/member/_components/DisplayAndUpdatePhoto";
import ChecklistForm from "./_components/ChacklistForm";

const returnTabButtonStyle = (selected) => {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  return classNames(
    "w-full py-2.5 text-lg leading-5 font-medium  rounded border dark:border-zinc-500",
    selected
      ? "text-gray-100 bg-green-900 bg-opacity-50"
      : "text-blue-100 hover:bg-white/[0.12] hover:text-white bg-gray-800"
  );
};

function VehiclePage({ settings }) {
  const { vehicle_id } = useParams();
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [isAvailable, setIsAvailable] = useState(false);
  const [isAppraisal, setIsAppraisal] = useState(false);
  const [isCheck, setIsCheck] = useState(false);
  const [vehicle, setVehicle] = useState({});
  const [checkLogs, setCheckLogs] = useState([]);
  const [appraisal, setAppraisal] = useState("");
  const [weight, setWeight] = useState(0);
  const [data, setData] = useState({
    date: new Date(),
  });
  const [checkResponses, setCheckResponses] = useState({});

  const navigate = useNavigate();
  useEffect(() => {
    if (settings.vehicleChecklist) {
      const stuff = {};

      settings.vehicleChecklist.forEach((c) => {
        stuff[c] = false;
      });
      setCheckResponses(stuff);
    }
    getData();
    getVehicleLog();
  }, []);
  useEffect(() => {
    // console.log("data", data);
  }, [data]);

  const getData = () => {
    API.vehicles.get(vehicle_id).then((res) => {
      setVehicle(res);
      setAppraisal(res.appraisal);
      setLoading(false);
    });
  };
  const onSubmit = (data) => {
    API.vehicles.update(vehicle._id, data).then(() => {
      getData();
      setIsOpen(false);
      toast.success("Vehicle updated");
      setIsAvailable(false);
      setIsAppraisal(false);
    });
  };
  const approveDialog = () => {
    return (
      <Transition
        show={isOpen}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Dialog
          open={isOpen}
          onClose={() => setIsOpen(false)}
          className="fixed inset-0 z-10 overflow-y-auto "
        >
          <div className="min-h-screen text-center">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-80" />
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className="my-8 inline-block w-full max-w-2xl transform overflow-hidden rounded-2xl border-4 border-gray-700 bg-gray-50 p-6 text-left align-middle shadow-xl transition-all dark:bg-zinc-700">
              <div className="pt-4 text-center">
                <Dialog.Title className={"text-2xl"}>
                  {!vehicle.approved ? "Approve" : "Un-approve"} this vehicle
                  for use
                  <span className="font-bold italic">
                    {vehicle.make} {vehicle.model}
                  </span>
                </Dialog.Title>

                {/* {error && (
                  <div className="py-4 text-xl text-red-500">{error}</div>
                )} */}
              </div>

              <div className="mt-4 flex justify-between border-t border-zinc-500 pt-4">
                <button
                  className="rounded-lg bg-primary px-4 py-2 text-white"
                  onClick={() => setIsOpen(false)}
                >
                  Cancel
                </button>
                <button
                  className="rounded-lg bg-green-500 px-4 py-2 text-white disabled:bg-red-500"
                  onClick={() => onSubmit({ approved: !vehicle.approved })}
                >
                  {!vehicle.approved ? "Approve" : "Un-approve"}
                </button>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  };
  const availableDialog = () => {
    return (
      <Transition
        show={isAvailable}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Dialog
          open={isAvailable}
          onClose={() => setIsAvailable(false)}
          className="fixed inset-0 z-10 overflow-y-auto "
        >
          <div className="min-h-screen text-center">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-80" />
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className="my-8 inline-block w-full max-w-2xl transform overflow-hidden rounded-2xl border-4 border-gray-700 bg-gray-50 p-6 text-left align-middle shadow-xl transition-all dark:bg-zinc-700">
              <div className="pt-4 text-center">
                <Dialog.Title className={"text-2xl"}>
                  Mark this vehicle
                  <span className="font-bold italic">
                    {vehicle.make} {vehicle.model}
                  </span>{" "}
                  as {vehicle.available ? "un-available" : "available"}
                </Dialog.Title>

                {/* {error && (
                  <div className="py-4 text-xl text-red-500">{error}</div>
                )} */}
              </div>

              <div className="mt-4 flex justify-between border-t border-zinc-500 pt-4">
                <button
                  className="rounded-lg bg-primary px-4 py-2 text-white"
                  onClick={() => setIsAvailable(false)}
                >
                  Cancel
                </button>
                <button
                  className="rounded-lg bg-green-500 px-4 py-2 text-white disabled:bg-red-500"
                  onClick={() => onSubmit({ available: !vehicle.available })}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  };
  const appraisalDialog = () => {
    return (
      <Transition
        show={isAppraisal}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Dialog
          open={isAppraisal}
          onClose={() => setIsAppraisal(false)}
          className="fixed inset-0 z-10 overflow-y-auto "
        >
          <div className="min-h-screen text-center">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-80" />
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className="my-8 inline-block w-full max-w-2xl transform  rounded-2xl border-4 border-gray-700 bg-gray-50 p-6 text-left align-middle shadow-xl transition-all dark:bg-zinc-700">
              <div className="pt-4 text-center">
                <Dialog.Title className={"text-2xl"}>
                  Change this vehicles (
                  <span className="font-bold italic">
                    {vehicle.make} {vehicle.model}
                  </span>
                  appraisal date
                </Dialog.Title>
                <div className="w-full ">
                  <label
                    htmlFor={"date"}
                    className={`block   dark:text-zinc-400 `}
                  >
                    Appraisal Date
                  </label>
                  <DatePicker
                    onChange={(date) => {
                      setAppraisal(date);
                    }}
                    selected={appraisal ? new Date(appraisal) : ""}
                    dateFormat="dd-MM-yy"
                    // maxDate={new Date()}
                    nextMonthButtonLabel=">"
                    previousMonthButtonLabel="<"
                    className=" w-full rounded border border-pp bg-white p-1 dark:border-zinc-500  dark:bg-zinc-700"
                    data-disable-touch-keyboard
                  />
                </div>
                {/* {error && (
                  <div className="py-4 text-xl text-red-500">{error}</div>
                )} */}
              </div>

              <div className="mt-4 flex justify-between border-t border-zinc-500 pt-4">
                <button
                  className="rounded-lg bg-primary px-4 py-2 text-white"
                  onClick={() => setIsAppraisal(false)}
                >
                  Cancel
                </button>
                <button
                  className="rounded-lg bg-green-500 px-4 py-2 text-white disabled:bg-red-500"
                  onClick={() => onSubmit({ appraisal: appraisal })}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  };
  const getVehicleLog = () => {
    API.vehicles.getVehicleLog(vehicle_id).then((res) => {
      setCheckLogs(res);
    });
  };
  const addLog = (data) => {
    return API.vehicles.addVehicleLog(vehicle._id, data).then(() => {
      setIsCheck(false);
      getVehicleLog();
      toast.success("Vehicle log added.");
    });
  };

  const checkModal = () => {
    return (
      <Transition
        show={isCheck}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Dialog
          open={isCheck}
          onClose={() => setIsCheck(false)}
          className="fixed inset-0 z-10 overflow-y-auto "
        >
          <div className=" min-h-screen text-center">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-80" />
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="my-8 inline-block w-full max-w-2xl transform  rounded-2xl border-4 border-gray-700 bg-gray-50 p-6 text-left  shadow-xl transition-all dark:bg-zinc-700">
              <div className="absolute right-0 top-0 pt-2">
                <button
                  type="button"
                  className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  onClick={() => setIsCheck(false)}
                >
                  <span className="sr-only">Close </span>
                  <XMarkIcon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </button>
              </div>
              <div className="space-y-4 pt-4">
                <Dialog.Title className={"text-2xl"}>
                  Vehicle log and check for{" "}
                  <span className="font-bold italic">
                    {vehicle.make} {vehicle.model}
                  </span>
                </Dialog.Title>

                <ChecklistForm
                  vehicle_id={vehicle._id}
                  onCancel={() => setIsCheck(false)}
                  onSubmit={addLog}
                />
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  };

  const handleGetUploadSpec = () => {
    return API.vehicles.photo.getUploadConfig(vehicle._id);
  };
  const handleGettingPhoto = () => {
    return API.vehicles.photo.getAccessLink(vehicle._id);
  };
  const handlePhotoUpload = (formData) =>
    API.vehicles.photo.uploadPhoto(vehicle._id, formData).then((a) => {
      getData();
      return a;
    });

  return (
    <div className="w-full p-4">
      {approveDialog()}
      {availableDialog()}
      {appraisalDialog()}
      {checkModal()}
      {loading && (
        <div className="grid grid-cols-3 gap-4">
          <div className="space-y-8">
            <Skeleton className="h-80 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
            <Skeleton className="h-80 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
          </div>
          <div className="col-span-2 space-y-8">
            <Skeleton className="h-52 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
            <Skeleton className="h-20 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
            <Skeleton className="h-20 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
          </div>
        </div>
      )}
      {!loading && (
        <div className="grid gap-4 xl:grid-cols-5">
          <div className="xl:col-span-2">
            <Box
              title={`Vehicle ${vehicle.regNumber} Details`}
              button={{
                text: "Edit",
                colour:
                  "bg-blue-400 text-zinc-300 hover:bg-primary hover:text-white ",
                action: () => navigate(`/vehicles/edit/${vehicle._id}`),
              }}
            >
              <DisplayAndUpdatePhoto
                className="w-full border-8 border-white shadow-lg dark:shadow-zinc-600"
                canUpdate={true}
                onGetUploadSpec={handleGetUploadSpec}
                onGetPhoto={handleGettingPhoto}
                onUpload={handlePhotoUpload}
                popoverText={"Change Vehicle Photo"}
                blankImage={Blank}
              />

              <div className="grid grid-cols-1 divide-x divide-zinc-300 dark:divide-zinc-700 sm:grid-cols-2 ">
                <DataRow
                  label={"Registration"}
                  value={
                    vehicle.regNumber ? vehicle.regNumber.toUpperCase() : "--"
                  }
                />
                {vehicle.unitVehicle ? <DataRow label={"Callsign"} value={vehicle.callsign}/> : <>
                  <DataRow
                    label={"Member"}
                    value={
                      vehicle.member ? (
                        <Link
                          to={`/team/${vehicle.member?._id}`}
                          className="rounded border border-zinc-500 px-2 py-1 hover:bg-zinc-100 dark:hover:bg-zinc-800"
                        >
                          {vehicle.member?.firstName}{" "}
                          {vehicle.member?.lastName}{" "}
                          <span className="text-xs italic">
                              ({vehicle.member?.callsign})
                            </span>
                        </Link>
                      ) : (
                        "--"
                      )
                    }
                  />
                </>}

                <DataRow label={"Make"} value={vehicle.make}/>
                <DataRow label={"Model"} value={vehicle.model}/>
                <DataRow label={"Seats"} value={vehicle.seats}/>
                <DataRow label={"Doors"} value={vehicle.doors}/>
                <DataRow label={"Colour"} value={vehicle.colour}/>
                <DataRow label={"Tyres"} value={vehicle.tyres}/>
                <DataRow label={"Taxed"} value={vehicle.taxStatus}/>
                <DataRow
                  label={"Tax Due"}
                  value={
                    vehicle.taxDueDate
                      ? dayjs(vehicle.taxDueDate).format("DD-MM-YY")
                      : "--"
                  }
                />
                <DataRow label={"MoT"} value={vehicle.motStatus}/>
                <DataRow
                  label={"MoT Due"}
                  value={
                    vehicle.motExpiryDate
                      ? dayjs(vehicle.motExpiryDate).format("DD-MM-YY")
                      : "--"
                  }
                />
                <DataRow
                  label={"LEZ Comp"}
                  value={
                    vehicle.lezComp ? (
                      <CheckIcon className="mx-auto h-8 text-green-300"/>
                    ) : (
                      <XMarkIcon className="mx-auto h-8 text-red-700"/>
                    )
                  }
                />
                <DataRow label={"Tow Weight"} value={vehicle.towWeight}/>
                <DataRow
                  label={"Insurance"}
                  value={
                    vehicle.insuranceExpiry ? (
                      <span>
                        {dayjs(vehicle.insuranceExpiry).format("DD-MM-YYYY")}
                      </span>
                    ) : (
                      "--"
                    )
                  }
                />
                <DataRow
                  label={"Year of Manufacture"}
                  value={vehicle.yearOfManufacture}
                />
                <DataRow
                  label={"Approved"}
                  value={
                    vehicle.approved ? (
                      <button
                        onClick={() => setIsOpen(true)}
                        className="ml-2 rounded border border-zinc-500"
                      >
                        <CheckIcon className="mx-auto my-auto h-8 text-green-300"/>
                      </button>
                    ) : (
                      <button
                        onClick={() => setIsOpen(true)}
                        className="ml-2 rounded border border-zinc-500"
                      >
                        <XMarkIcon className="mx-auto my-auto h-8 text-red-700"/>
                      </button>
                    )
                  }
                />
                {vehicle.member?._id ? (
                  <DataRow
                    label={"Appraisal"}
                    value={
                      <button
                        onClick={() => setIsAppraisal(true)}
                        className="ml-2 rounded border border-zinc-500 p-1"
                      >
                        <span className="text-black dark:text-white">
                          {vehicle.appraisal
                            ? dayjs(vehicle.appraisal).format("DD-MM-YYYY")
                            : " -- "}
                        </span>
                      </button>
                    }
                  />
                ) : (
                  <DataRow
                    label={"Available"}
                    value={
                      vehicle.available ? (
                        <button
                          onClick={() => setIsAvailable(true)}
                          className="ml-2 rounded border border-zinc-500"
                        >
                          <CheckIcon className="mx-auto my-auto h-8 text-green-300"/>
                        </button>
                      ) : (
                        <button
                          onClick={() => setIsAvailable(true)}
                          className="ml-2 rounded border border-zinc-500"
                        >
                          <XMarkIcon className="mx-auto my-auto h-8 text-red-700"/>
                        </button>
                      )
                    }
                  />
                )}
                <div className=" col-span-2  border-0">
                  {vehicle.unitVehicle && (
                    <DataRow
                      label={"Location"}
                      value={vehicle.assigned ? vehicle.assigned?.properties?.name : "--"}
                    />
                  )}
                </div>
              </div>

              <div className="border-t border-zinc-500 p-2 text-gray-500">
                <ArrayCard
                  title="Vehicle Kit"
                  list={settings.vehicleKit}
                  data={vehicle.kit}
                  submit={(e) => {
                    return API.vehicles
                    .update(vehicle._id, {kit: e})
                    .then(() => {
                      getData();
                    });
                  }}
                />
              </div>
            </Box>
          </div>
          <div className="xl:col-span-3">
            <Tab.Group defaultIndex={0}>
              <Tab.List className="flex space-x-1 border-b border-zinc-200 p-1 pb-2 dark:border-zinc-700 dark:bg-blue-900/20">
                <Tab
                  className={({selected}) => returnTabButtonStyle(selected)}
                >
                  Driving Log
                </Tab>
                <Tab
                  className={({selected}) => returnTabButtonStyle(selected)}
                >
                  Assets
                </Tab>
              </Tab.List>
              <Tab.Panels>
                <Tab.Panel>
                  <Box
                    title="Vehicle Log"
                    button={{
                      text: "Add Log",
                      colour:
                        "bg-green-700 text-zinc-300 hover:bg-primary hover:text-white ",
                      action: () => setIsCheck(true),
                    }}
                  >
                    {checkLogs.length > 0 ? (
                      <VehicleCheckList
                        vehicle={vehicle}
                        reloadLogs={() => getVehicleLog()}
                        logs={checkLogs}
                      />
                    ) : (
                      <div className={"p-2"}>There are no logs to display.</div>
                    )}
                  </Box>
                </Tab.Panel>
                <Tab.Panel>
                  <Box title="Vehicle Assets">
                    <div className="p-2">
                      <DataBox
                        label={"Weight of Assets"}
                        value={weight + " kg"}
                      />
                    </div>
                    <AssetList
                      vehicle={vehicle._id}
                      weight={(w) => setWeight(w)}
                    />
                  </Box>
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </div>
        </div>
      )}
    </div>
  );
}
export default ChainedContext(VehiclePage, [[SettingsReceiver, "settings"]]);
