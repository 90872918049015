import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { useNavigate, useParams } from "react-router-dom";
import Box from "../../../components/common/Box";
import api from "../../../api";

export default function SupplierAssets() {
  const [assets, setAssets] = useState([]);
  const { supplier_id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    api.assets.suppliers.getAssets(supplier_id).then((res) => {
      setAssets(res);
    });
  };

  return (
    <div>
      <div className="flex gap-4 p-4">
        <Box title={"Assets"}>
          <table className="tableClass">
            <thead>
              <tr className="thrc">
                <th className="thcc ">Asset</th>
                <th className="thcc ">Asset Number</th>
                <th className="thcc ">Purchase Date</th>
                <th className="thcc ">Expires Date</th>
                <th className="thcc ">Tags</th>
              </tr>
            </thead>
            <tbody className="tbc">
              {assets.map((a) => {
                return (
                  <tr
                    key={a._id}
                    className={`tbrc cursor-pointer`}
                    onClick={() => navigate(`/asset/view/${a._id}`)}
                  >
                    <td className="tbcc">{a.name}</td>
                    <td className="tbcc">{a.assetNum}</td>
                    <td className="tbcc   ">
                      {dayjs(a.date_purchased).format("DD-MM-YYYY")}
                    </td>
                    <td className="tbcc ">
                      {a.date_expires
                        ? dayjs(a.date_expires).format("DD-MM-YYYY")
                        : "--"}
                    </td>
                    <td className="tbcc ">
                      {a.tags.map((t, i) => {
                        return (
                          <span
                            key={i}
                            className="m-1 rounded-lg border border-gray-700 p-2 dark:bg-gray-800"
                          >
                            {t}
                          </span>
                        );
                      })}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Box>
      </div>
    </div>
  );
}
