import React, {useEffect, useMemo, useState} from "react";
import DatePicker from "react-datepicker";
import DayJS from "dayjs";
import {toast} from "react-hot-toast";
import {TrashIcon} from "@heroicons/react/20/solid";
import {useNavigate} from "react-router-dom";
import Box from "../../../components/common/Box";
import Input from "../../../components/common/Input";
import Select from "../../../components/common/Select";
import ToggleSwitch from "../../../components/common/ToggleSwitch";
import TextArea from "../../../components/common/TextArea";
import ChainedContext from "../../../contexts/chainedContext";
import SettingsReceiver from "../../../contexts/settings_context/Settings_Receiver";
import OrganisationReceiver from "../../../contexts/organisation/receiver";
import LocationSelector from "./LocationSelector";
import API from "../../../api";
import EventClonePopup from "./EventClonePopup";
import ArrayCard from "../../../components/common/ArrayCard";
import ConfirmBox from "../../../components/common/Confirm";
import ManageWebLinks from "../../../components/widgets/ManageWebLinks";
import {AuthComponents} from "../../../lib/_sso";

const EventEntryForm = ({onSubmit, onCancel, isClone = false, isEdit = false, data, startAt = "", endAt = "", settings, organisation}) => {
  const navigate = useNavigate();

  const eventTypes = useMemo(() => settings && settings?.event_types?.map((item) => {
    return {
      text: item.name,
      value: item.name,
    };
  }), [settings]);

  const [start, setStart] = useState(startAt);
  const [end, setEnd] = useState(endAt);
  const [type, setType] = useState("");
  const [title, setTitle] = useState("");
  const [details, setDetails] = useState("");
  const [organiser, setOrganiser] = useState("");
  const [organiserID, setOrganiserID] = useState("");
  const [note, setNote] = useState("");
  const [cpdRole, setCpdRole] = useState("");
  const [signUp, setSignUp] = useState(true);
  const [allDay, setAllDay] = useState(false);
  const [members, setMembers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [location, setLocation] = useState({});
  const [cloneEvent, setCloneEvent] = useState(false);
  const [signupRestricted, setSignupRestricted] = useState(false);
  const [signupRoles, setSignupRoles] = useState([]);
  const [webLinks, setWebLinks] = useState([]);
  const [deleteWarning, setDeleteWarning] = useState(false);


  useEffect(() => {
    API.member.allCache().then((res) => {
      setMembers(
        res.map((m) => {
          return {
            key: m._id,
            text: m.firstName + " " + m.lastName,
            value: m._id,
          };
        })
      );
    });
    API.settings.getRoles().then((res) => {
      setRoles(res);
    });
  }, []);
  useEffect(() => {
    if (data === undefined || data._id === undefined) return;

    setTitle(data.title);
    setStart(data.start);
    setEnd(data.end);
    setDetails(data.description);
    setOrganiser(data.organiser);
    if (data.organiserID !== undefined && data.organiserID._id !== undefined) setOrganiserID(data.organiserID._id);
    setLocation({
      type: "Feature",
      geometry: data.geometry ? data.geometry : {},
      properties: data.properties ? data.properties : {},
    });
    setType(data.eventType);
    setSignUp(data.canSignUp ? data.canSignUp : false);
    setNote(data.note);
    setAllDay(data.allDay ? data.allDay : false);
    setCpdRole(data.cpdRole || "");
    if (data.signupRoles !== undefined && data.signupRoles.length > 0) {
      setSignupRestricted(true);
      setSignupRoles(data.signupRoles);
    }
    if (data.webLinks !== undefined) setWebLinks(data.webLinks);
  }, [data]);
  useEffect(() => {
    if (!signUp) {
      setSignupRestricted(false);
      setSignupRoles([]);
    }
  }, [signUp]);

  const handleSubmit = () => {
    if (DayJS(start).isAfter(DayJS(end))) {
      toast.error("The end date & time must be after the start date & time.");
      return;
    }
    onSubmit({
      start,
      end,
      description: details,
      title,
      note,
      organiser,
      organiserID,
      properties: location.properties,
      geometry: location.geometry,
      allDay,
      eventType: type,
      canSignUp: signUp,
      signupRoles,
      webLinks
    });
  };
  const handleDelete = () => {
    API.calendar.deleteEvent(data._id).then(() => {
      navigate(`/events`);
      toast.success("Successfully deleted event");
    })
  }

  return (
    <>
      {cloneEvent && (
        <EventClonePopup event={data} onClose={() => setCloneEvent(false)}/>
      )}
      {deleteWarning && <ConfirmBox show title={"Are you sure you would like to permanently delete the selected event."} action={handleDelete} onClose={() => setDeleteWarning(false)}/>}
      <div className="grid gap-2 sm:grid-cols-2">
        <div>
          <Box title="Event Details">
            <div className="space-y-4 p-2">
              <Input
                label="Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              <div className="grid gap-2 sm:grid-cols-2">
                <div className="w-full">
                  <label htmlFor={"date"} className={`block dark:text-zinc-400`}>
                    Start
                  </label>
                  <DatePicker
                    onChange={(date) => setStart(date)}
                    selected={start ? new Date(start) : ""}
                    dateFormat="dd-MM-yy HH:mm"
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={30}
                    nextMonthButtonLabel=">"
                    previousMonthButtonLabel="<"
                    className=" w-full rounded border border-pp bg-white p-1 dark:border-zinc-500  dark:bg-zinc-700"
                    data-disable-touch-keyboard
                  />
                </div>
                <div className="w-full">
                  <label htmlFor={"date"} className={`block dark:text-zinc-400`}>
                    End
                  </label>
                  <DatePicker
                    onChange={(date) => setEnd(date)}
                    selected={end ? new Date(end) : start ? new Date(start) : ""}
                    minDate={start}
                    dateFormat="dd-MM-yy HH:mm"
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={30}
                    nextMonthButtonLabel=">"
                    previousMonthButtonLabel="<"
                    className=" w-full rounded border border-pp bg-white p-1 dark:border-zinc-500  dark:bg-zinc-700"
                    data-disable-touch-keyboard
                  />
                </div>
                <div className="px-1">
                  <label
                    htmlFor={"date"}
                    className={`mb-1 block  dark:text-zinc-400 `}
                  >
                    All day event
                  </label>
                  <ToggleSwitch
                    posColour="bg-green-800"
                    negColour="bg-red-800"
                    isChecked={allDay}
                    onChange={() => setAllDay(!allDay)}
                  />
                </div>
              </div>
              <div className="grid gap-2 sm:grid-cols-2">
                {(data !== undefined && data.isCpdEvent !== undefined && data.isCpdEvent) ? (
                  <Select
                    label={"CPD Role"}
                    options={roles
                    .filter((r) => r.requiresCPD === true)
                    .map((r) => {
                      return {
                        text: r.name + (r.instructorOnly ? " *" : ""),
                        value: r._id,
                      };
                    })}
                    value={cpdRole}
                    onChange={(e) => setCpdRole(e.target.value)}
                  />
                ) : (
                  <Select
                    label={"Type"}
                    options={eventTypes}
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                  />
                )}

                <div className="px-1">
                  <label
                    htmlFor={"date"}
                    className={`mb-1 block dark:text-zinc-400`}
                  >
                    Allow sign up
                  </label>
                  <ToggleSwitch
                    label1="Not Allowed"
                    label2="Allowed"
                    posColour=" bg-green-800"
                    negColour=" bg-red-800"
                    isChecked={signUp}
                    onChange={() => setSignUp(!signUp)}
                  />

                  {signUp && (
                    <div className="">
                      <label
                        htmlFor={"date"}
                        className={`mb-1 block dark:text-zinc-400`}
                      >
                        Restrict to role(s)
                      </label>
                      <ToggleSwitch
                        label1="Un-Restricted"
                        label2="Restricted"
                        posColour=" bg-green-800"
                        negColour=" bg-red-800"
                        isChecked={signupRestricted}
                        onChange={() => {
                          setSignupRestricted(!signupRestricted);
                          if (!signupRestricted) {
                            setSignupRoles([]);
                          }
                        }}
                      />
                    </div>
                  )}
                </div>
                {signupRestricted && (
                  <div className="col-span-2 py-4">
                    <ArrayCard
                      title={"Allowed Roles"}
                      textSize={"text-md"}
                      list={roles.map((a) => {
                        return {
                          _id: a._id,
                          title: a.name,
                        };
                      })}
                      data={signupRoles}
                      isIds={true}
                      submit={async (data) => setSignupRoles(data)}
                    />
                  </div>
                )}
              </div>

              <TextArea
                label="Description"
                value={details}
                onChange={(e) => setDetails(e.target.value)}
              />
              <div className="flex justify-around gap-2">
                <Select
                  label={"Organiser"}
                  options={members}
                  value={organiserID}
                  onChange={(e) => {
                    setOrganiser(
                      members.filter((m) => {
                        return m.value === e.target.value;
                      })[0].text
                    );
                    setOrganiserID(e.target.value);
                  }}
                />
              </div>
              <TextArea
                label="Private Notes"
                value={note}
                onChange={(e) => setNote(e.target.value)}
              />
            </div>
          </Box>
        </div>
        <div>
          <Box title="Location">
            <LocationSelector
              location={location}
              onChange={setLocation}
              organisation={organisation}
              settings={settings}
            />
          </Box>
          <Box title={"Web Links"}>
            <ManageWebLinks links={webLinks} onChange={links => setWebLinks(links)} />
          </Box>
        </div>
      </div>
      <div className="mt-4 flex justify-between border-t border-zinc-500 pt-4">
        <button
          className="rounded-lg bg-red-500 px-4 py-2 text-white"
          onClick={onCancel}
        >
          Cancel
        </button>
        {isEdit && <AuthComponents.Can scope={"events:delete"}><button
          className="rounded-md border bg-opacity-80 px-2 py-1 text-sm text-white border-red-400 bg-red-700 hover:border-red-300 hover:bg-red-800"
          onClick={() => setDeleteWarning(true)}
        >
          <TrashIcon className="h-6 w-6 text-red-200"/>
        </button></AuthComponents.Can>}
        <button
          className="rounded-lg bg-green-500 px-4 py-2 text-white"
          onClick={handleSubmit}
        >
          {isEdit ? "Update" : "Add"}
        </button>
      </div>
    </>
  );
};
export default ChainedContext(EventEntryForm, [
  [SettingsReceiver, "settings"],
  [OrganisationReceiver, "organisation"],
]);
