import axios          from "axios";
import Authentication from "./Authentication";

class APIRequest {
    _updateConfig(config){
        if(config === undefined){
            config = {};
        }
        if(config.headers === undefined){
            config.headers = {};
        }
        return {
            ...config,
            headers: {
                ...config.headers,
                'Authorization': "Bearer " + Authentication.getAccessToken()
            }
        }
    }
    get(url, config){
        return new Promise((resolve, reject) => {
            axios.get(url, this._updateConfig(config)).then((res) => {
                resolve(res);
            }).catch((err) => {
                if(err.response !== undefined){
                    if(err.response.status === 401){
                        return Authentication.logout();
                    }
                }
                reject(err);
            });
        });
    }
    delete(url, config){
        return new Promise((resolve, reject) => {
            axios.delete(url, this._updateConfig(config)).then((res) => {
                resolve(res);
            }).catch((err) => {
                if(err.response !== undefined){
                    if(err.response.status === 401){
                        return Authentication.logout();
                    }
                }
                reject(err);
            });
        });
    }
    head(url, config){
        return new Promise((resolve, reject) => {
            axios.head(url, this._updateConfig(config)).then((res) => {
                resolve(res);
            }).catch((err) => {
                if(err.response !== undefined){
                    if(err.response.status === 401){
                        return Authentication.logout();
                    }
                }
                reject(err);
            });
        });
    }
    options(url, config){
        return new Promise((resolve, reject) => {
            axios.options(url, this._updateConfig(config)).then((res) => {
                resolve(res);
            }).catch((err) => {
                if(err.response !== undefined){
                    if(err.response.status === 401){
                        return Authentication.logout();
                    }
                }
                reject(err);
            });
        });
    }
    post(url, data, config){
        return new Promise((resolve, reject) => {
            axios.post(url, data, this._updateConfig(config)).then((res) => {
                resolve(res);
            }).catch((err) => {
                if(err.response !== undefined){
                    if(err.response.status === 401){
                        return Authentication.logout();
                    }
                }
                reject(err);
            });
        });
    }
    put(url, data, config){
        return new Promise((resolve, reject) => {
            axios.put(url, data, this._updateConfig(config)).then((res) => {
                resolve(res);
            }).catch((err) => {
                if(err.response !== undefined){
                    if(err.response.status === 401){
                        return Authentication.logout();
                    }
                }
                reject(err);
            });
        });
    }
    patch(url, data, config){
        return new Promise((resolve, reject) => {
            axios.patch(url, data, this._updateConfig(config)).then((res) => {
                resolve(res);
            }).catch((err) => {
                if(err.response !== undefined){
                    if(err.response.status === 401){
                        return Authentication.logout();
                    }
                }
                reject(err);
            });
        });
    }
}
export default new APIRequest();