import React, { useState } from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import ChainedContext from "../../../contexts/chainedContext";
import SettingsReceiver from "../../../contexts/settings_context/Settings_Receiver";
import CommonFunctions from "../../../lib/CommonFunctions";
import API from "../../../api";
import SelectZero from "../../common/Select";

const PostcodeForm = ({ settings, onLocationChange }) => {
  const [postcode, setPostcode] = useState("");
  const [postcodeResults, setPostcodeResults] = useState([]);
  const [searching, setSearching] = useState(false);
  const [addressSelected, setAddressSelected] = useState("");

  const handleFindPostcode = () => {
    if (postcode !== "") {
      setSearching(true);
      API.feeds.postcode.getAddresses(postcode).then((res) => {
        setPostcodeResults(res.features);
        setSearching(false);
      });
    }
  };

  const handleSelectPostcode = async (e) => {
    setAddressSelected(e.target.value);
    let address = postcodeResults.filter((r) => {
      return r._id === e.target.value;
    });
    if (address[0]) {
      const selected = address[0];
      const coordinates = [
        selected.geometry.coordinates[0],
        selected.geometry.coordinates[1],
      ];
      const grid = CommonFunctions.getGrid(
        coordinates[1],
        coordinates[0],
        settings.gridType
      ); // lat, lng
      const w3w = await CommonFunctions.getW3w(coordinates[1], coordinates[0]);
      // console.log(selected);
      let geoJSON = {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: coordinates,
        },
        properties: {
          ...(selected?.properties || {}),
          name: selected.properties.building_name
            ? selected.properties.building_name
            : selected.properties.address_line,
          w3w: w3w !== null ? w3w : "",
          grid: grid.toUpperCase(),

          // Address fields
          town: selected.properties.town,
          street: selected.properties.street,
          postcode: selected.properties.postcode,
          address: selected.properties.address_line,
          building_name: selected.properties.building_name,
          uprn: selected.properties.uprn,
          usrn: selected.properties.usrn,
        },
      };
      onLocationChange(geoJSON);
    } else {
      onLocationChange({});
    }
  };

  return (
    <div className="w-full">
      <div className="flex flex-row items-center justify-between">
        <input
          name="postcode_search"
          value={postcode}
          placeholder={"CT4 8HF"}
          onChange={(e) => setPostcode(e.target.value.toUpperCase())}
          autoComplete="off"
          className={
            "w-full rounded-md border border-gray-600 bg-gray-200 p-2 uppercase placeholder-gray-400 dark:bg-zinc-700"
          }
        />
        <button
          disabled={postcode === ""}
          onClick={handleFindPostcode}
          className="rounded-md p-2 text-primary hover:text-gray-700"
        >
          <MagnifyingGlassIcon className="h-8 cursor-pointer" />
        </button>
      </div>
      <div className="w-full ">
        {postcodeResults.length > 0 ? (
          <>
            <div className="p-2">{postcodeResults.length} addresses</div>

            <SelectZero
              value={addressSelected}
              options={postcodeResults
                .sort((a, b) => {
                  let name_a =
                    a.properties.sub_building !== null
                      ? a.properties.sub_building
                      : a.properties.name;
                  let name_b =
                    b.properties.sub_building !== null
                      ? b.properties.sub_building
                      : b.properties.name;

                  if (!Number.isNaN(name_a)) name_a = parseInt(name_a);
                  if (!Number.isNaN(name_b)) name_b = parseInt(name_b);

                  if (name_a > name_b) return 1;
                  if (name_a < name_b) return -1;
                  return 0;
                })
                .map((p) => {
                  return {
                    key: p._id,
                    text: p.properties.address_line,
                    value: p._id,
                  };
                })}
              onChange={handleSelectPostcode}
            />
          </>
        ) : (
          searching && "searching"
        )}
      </div>
    </div>
  );
};
export default ChainedContext(PostcodeForm, [[SettingsReceiver, "settings"]]);
