import React from "react";
import { toast } from "react-hot-toast";
import SettingsReceiver from "../../contexts/settings_context/Settings_Receiver";
import OrganisationReceiver from "../../contexts/organisation/receiver";
import ChainedContext from "../../contexts/chainedContext";
import SettingsArrayList from "./_components/SettingsArrayList";
import RecruitEventTypes from "./_components/RecruitEventTypes";

import API from "../../api";

const RecruitmentSettings = ({ settings }) => {
  const onSubmit = (data) => {
    API.settings.updateSystem(data).then(() => {
      toast.success("Settings updated");
      settings._refresh();
    });
  };

  return (
    <div className="p-4">
      <div className="grid grid-cols-2 gap-4">
        <SettingsArrayList
          title="Recruitment Source"
          field="source"
          data={settings.source}
          onSubmit={onSubmit}
          onDelete={onSubmit}
        />
        <RecruitEventTypes
          title="Recruitment Event Types"
          field="recruit_event_types"
          data={settings.recruit_event_types}
          onSubmit={onSubmit}
          onDelete={onSubmit}
        />
      </div>
    </div>
  );
};
export default ChainedContext(RecruitmentSettings, [
  [SettingsReceiver, "settings"],
  [OrganisationReceiver, "organisation"],
]);
