import {useContext, useEffect, useMemo} from "react";
import {Map, BoundingBoxFeatures, Projection} from "./context";
import OLVectorLayer from "ol/layer/Vector";
import GeoJSON from "ol/format/GeoJSON";
import {get} from "ol/proj";

const VectorLayer = ({source, style, zIndex = 0}) => {
  const mapContext              = useContext(Map);
  const projectionContext       = useContext(Projection);
  const boundingFeaturesContext = useContext(BoundingBoxFeatures);

  const vectorLayer = useMemo(() => new OLVectorLayer({
    source,
    style
  }), [source, style]);

  useEffect(() => {
    if (!mapContext) return;

    mapContext.addLayer(vectorLayer);
    return () => mapContext.removeLayer(vectorLayer);
  }, [mapContext, vectorLayer]);
  useEffect(() => {
    if (!vectorLayer) return;

    vectorLayer.setZIndex(zIndex);
  }, [vectorLayer, zIndex])
  useEffect(() => {
    if (!vectorLayer) return;

    const geoJson = new GeoJSON().writeFeatures(vectorLayer.getSource().getFeatures(), {
      featureProjection: get(projectionContext.projection),
    })
    boundingFeaturesContext.register(geoJson);
    return () => {
      boundingFeaturesContext.deregister(geoJson);
    }
  }, [vectorLayer, boundingFeaturesContext]);


  return null;
}
export default VectorLayer;