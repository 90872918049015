import { APIRequest } from "../lib/_sso";
import AppConfig from "../_appConfig";

const server = AppConfig.api_server;

export default {
  all: () => APIRequest.get(`${server}/vehicles`).then((res) => res.data),
  addVehicle: (data) => APIRequest.post(`${server}/vehicles`, data).then((res) => res.data),
  get: (id) => APIRequest.get(`${server}/vehicles/${id}`).then((res) => res.data),
  delete: (id) => APIRequest.delete(`${server}/vehicles/${id}`).then((res) => res.data),
  update: (id, data) => APIRequest.patch(`${server}/vehicles/${id}`, data).then((res) => res.data),

  formList: () => APIRequest.get(`${server}/vehicles/form_list`).then((res) => res.data),
  dvlaLookup: (regnum) => APIRequest.get(`${server}/vehicles/dvla/lookup/${regnum}`).then((res) => res.data),

  addVehicleLog: (id, data) => APIRequest.post(`${server}/vehicles/${id}/check`, data).then((res) => res.data),
  getVehicleLog: (id) => APIRequest.get(`${server}/vehicles/${id}/check`).then((res) => res.data),
  updateVehicleLogEntry: (id, check_id, data) => APIRequest.patch(`${server}/vehicles/${id}/check/${check_id}`, data).then((res) => res.data),
  deleteVehicleLogEntry: (id, check_id) => APIRequest.delete(`${server}/vehicles/${id}/check/${check_id}`).then((res) => res.data),

  photo: {
    getUploadConfig: (id) => APIRequest.get(`${server}/vehicles/${id}/photo/_uploadConfig`).then(res => res.data),
    getAccessLink: (id) => APIRequest.get(`${server}/vehicles/${id}/photo/link`).then(res => res.data),
    uploadPhoto: (id, vehiclePhoto) => APIRequest.patch(`${server}/vehicles/${id}/photo/upload`, vehiclePhoto, {headers: {"Content-Type": "multipart/form-data"}}),
  }
};
