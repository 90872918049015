import ChangeLogEntries from "../../_changeLog";
import Box from "../../components/common/Box";

const ChangeLog = () => {


  return (
    <div className={"px-3"}>
      <div className={"my-2 p-2 dark:bg-gray-900 rounded-xl border border-gray-700"}>
        Please remember <span className="italic">'No system survives contact with the user'.</span> If you find a bug, first just try a refresh. If the bug persists please drop
        us a line with what you were trying to do at the time of the bug. What would really help is to right click on the page select <span className="italic">inspect</span>, and
        then check the <span className="italic">console</span> tab and send us the screenshot of that to:- <span className="text-primary italic">support@sarstuff.co.uk</span>
      </div>

      <Box title={"Change Log"}>
        <table className="tableClass">
          <thead className="tableHeadClass">
            <tr className="tableHeadRowClass">
              <td className="tableHeadCellClass">Date</td>
              <td className="tableHeadCellClass">Type</td>
              <td className="tableHeadCellClass">Details</td>
            </tr>
          </thead>
          <tbody className="tableBodyClass">{ChangeLogEntries.map((a, i) => {
            return (<tr key={i} className="tableBodyRowClass">
              <td className="tableBodyCellClass whitespace-nowrap">{a.date}</td>
              <td className="tableBodyCellClass whitespace-nowrap">{a.type}</td>
              <td className="tableBodyCellClass w-full">{a.details}</td>
            </tr>);
          })}</tbody>
        </table>
      </Box>
    </div>
  );
}
export default ChangeLog;