import Model from "../../../components/ui/model";
import Box from "../../../components/common/Box";
import EventEntryForm from "./EventEntryForm";
import React from "react";
import api from "../../../api";
import {toast} from "react-hot-toast";
import {useNavigate} from "react-router-dom";

const EventClonePopup = ({ event, onClose }) => {
  const navigate = useNavigate();

  const handleSubmit = data => {
    api.calendar.createEvent(data).then((res) => {
      toast.success("Successfully cloned event");
      navigate(`/events/event/${res._id}`);
    });
  };

  return (
    <Model title={"Clone Event"} onClose={onClose}>
      <EventEntryForm isClone onSubmit={handleSubmit} data={event} onCancel={() => onClose()} />
    </Model>
  );
}
export default EventClonePopup;