import React, { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import SettingsReceiver from "../../contexts/settings_context/Settings_Receiver";
import OrganisationReciever from "../../contexts/organisation/receiver";
import ChainedContext from "../../contexts/chainedContext";
import API from "../../api";
import CompList from "./_components/CompList";
import CompModulesList from "./_components/CompModulesList";
import CompSectionsList from "./_components/CompSectionsList";
import CompSkillForm from "./_components/CompSkillForm";
import { Tab } from "@headlessui/react";
import CompetencyTree2 from "./CompetencyTree2";

const returnTabButtonStyle = (selected) => {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  return classNames(
    "w-full py-2.5 text-lg leading-5 font-medium  rounded-lg",
    selected
      ? "text-gray-100 bg-green-900 bg-opacity-50"
      : "text-blue-100 dark:hover:bg-white/[0.12] hover:text-white bg-gray-800"
  );
};

const CompetencyPage = ({ settings }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [comps, setComps] = useState([]);
  const [modules, setModules] = useState([]);
  const [sections, setSections] = useState([]);
  const [skills, setSkills] = useState([]);
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    getComps();
  }, []);

  const getComps = () => {
    setIsLoading(true);

    Promise.all([
      API.settings.getComps(),
      API.settings.getCompModules(),
      API.settings.getCompSections(),
      API.settings.getCompSkills(),
      API.settings.getRoles(),
    ]).then((res) => {
      setComps(res[0]);
      setModules(res[1]);
      setSections(res[2]);
      setSkills(res[3]);
      setRoles(res[4]);
      setIsLoading(false);
    });
  };
  const onSubmit = (data) => {
    API.settings.updateSystem(data).then(() => {
      toast.success("Settings updated");
      settings._refresh();
    });
  };

  return (
    <Tab.Group defaultIndex={0}>
      <Tab.List className="flex space-x-1 border-b border-zinc-200  p-1 pb-2 dark:border-zinc-700 dark:bg-blue-900/20">
        <Tab className={({ selected }) => returnTabButtonStyle(selected)}>
          Competencies - Step 1
        </Tab>
        <Tab className={({ selected }) => returnTabButtonStyle(selected)}>
          Modules - Step 2
        </Tab>
        <Tab className={({ selected }) => returnTabButtonStyle(selected)}>
          Sections - Step 3
        </Tab>
        <Tab className={({ selected }) => returnTabButtonStyle(selected)}>
          Skills - Step 4
        </Tab>
        <Tab className={({ selected }) => returnTabButtonStyle(selected)}>
          Tree View
        </Tab>
      </Tab.List>
      <Tab.Panels>
        <Tab.Panel className={"p-1"}>
          <CompList
            loading={isLoading}
            title="Competencies"
            roles={
              roles &&
              roles.filter((r) => {
                return r.requiresCompetency;
              })
            }
            data={comps}
            refresh={() => getComps()}
          />
        </Tab.Panel>
        <Tab.Panel className={"p-1"}>
          <div className="space-y-4">
            <CompModulesList
              loading={isLoading}
              title={"Competency Modules"}
              comps={comps}
              refresh={() => getComps()}
              data={modules}
            />
          </div>
        </Tab.Panel>
        <Tab.Panel className={"p-1"}>
          <div className="space-y-4">
            <CompSectionsList
              loading={isLoading}
              title={"Competency Sections"}
              modules={modules}
              refresh={() => getComps()}
              data={sections}
            />
          </div>
        </Tab.Panel>
        <Tab.Panel className={"p-1"}>
          <div className="space-y-4">
            <CompSkillForm
              loading={isLoading}
              title="Competency Skills"
              data={skills}
              sections={sections}
              refresh={() => getComps()}
            />
          </div>
        </Tab.Panel>
        <Tab.Panel className={"p-1"}>
          <CompetencyTree2 />
        </Tab.Panel>
      </Tab.Panels>
    </Tab.Group>
  );
};
export default ChainedContext(CompetencyPage, [
  [SettingsReceiver, "settings"],
  [OrganisationReciever, "organisation"],
]);
