import { APIRequest } from "../lib/_sso";
import AppConfig from "../_appConfig";

const server = AppConfig.api_server;

export default {
  requirements: {
    dashStats: () => APIRequest.get(`${server}/reports/requirements/dashboard`).then(res => res.data),
    getOverview: () => APIRequest.get(`${server}/reports/requirements/overview`).then(res => res.data),
    getDetailed: (requirement_id) => APIRequest.get(`${server}/reports/requirements/${requirement_id}/detailed`).then(res => res.data),
  },

  qualifications: {
    dashStats: () => APIRequest.get(`${server}/reports/qualifications/dashboard`).then(res => res.data),
    getCourseDetailed: (course_id, filterBy) => APIRequest.get(`${server}/reports/qualifications/detailed?course=${course_id}&filter=${filterBy}`).then(res => res.data),
    getCompetenciesDetailed: (comp_id, filterBy)  => APIRequest.get(`${server}/reports/qualifications/detailed?competency=${comp_id}&filter=${filterBy}`).then(res => res.data),
  },

  memberActivity: {
    getResults: (training_type = "None", deployment_type = "None", time_span = 12) =>
      APIRequest.get(`${server}/reports/members_activity/detailed?training_type=${training_type}&deployment_type=${deployment_type}&time_span=${time_span}`)
      .then(res => res.data),
  },

  memberDeployments: {
    getResults: () => APIRequest.get(`${server}/reports/members_deployments/detailed`).then(res => res.data),
  },

  lowland: {
    getConfig: () => APIRequest.get(`${server}/reports/lowland/config`).then(res => res.data),
    setConfig: (data) => APIRequest.patch(`${server}/reports/lowland/config`, data).then(res => res.data),
    runReport: (year) => APIRequest.get(`${server}/reports/lowland/report/${year}`).then(res => res.data),
  },
}