import { APIRequest } from "../lib/_sso";
import AppConfig from "../_appConfig";

const server = AppConfig.api_server;

export default {
  allIncidents: () => APIRequest.get(`${server}/incidents`).then(res => res.data),
  createIncident: (data) => APIRequest.post(`${server}/incidents/add`, data).then(res => res.data),
  getIncident: (incidentId) => APIRequest.get(`${server}/incidents/${incidentId}`).then(res => res.data),
  getAllMembers: (incidentId) => APIRequest.get(`${server}/incidents/${incidentId}/all_members`).then(res => res.data),
  updateIncident: (incidentId, data) => APIRequest.patch(`${server}/incidents/${incidentId}`, data).then(res => res.data),
  sarsys: {
    allIncidents: () => APIRequest.get(`${server}/incidents/sarsys/incidents`).then(res => res.data),
    viewIncident: (sarSysId) => APIRequest.get(`${server}/incidents/sarsys/incidents/${sarSysId}`).then(res => res.data),
    importIncident: (sarSysId, reImport = false) => APIRequest.post(`${server}/incidents/sarsys/incidents/${sarSysId}/import`, { reImport }).then(res => res.data),
  },
  periods: {
    getPeriods: (incidentId, data) => APIRequest.get(`${server}/incidents/${incidentId}/periods`).then(res => res.data),
    createPeriod: (incidentId, data) => APIRequest.post(`${server}/incidents/${incidentId}/periods`, data).then(res => res.data),
    updatePeriod: (incidentId, periodId, data) => APIRequest.patch(`${server}/incidents/${incidentId}/periods/${periodId}`, data).then(res => res.data),
    deletePeriod: (incidentId, periodId) => APIRequest.delete(`${server}/incidents/${incidentId}/periods/${periodId}`).then(res => res.data),
    members: {
      getAssigned: (incidentId, periodId) => APIRequest.get(`${server}/incidents/${incidentId}/periods/${periodId}/members`).then(res => res.data),
      assignMember: (incidentId, periodId, memberId) => APIRequest.post(`${server}/incidents/${incidentId}/periods/${periodId}/members`, {memberId}).then(res => res.data),
      unassignMember: (incidentId, periodId, memberId) => APIRequest.delete(`${server}/incidents/${incidentId}/periods/${periodId}/members/${memberId}`).then(res => res.data),
    }
  }
}