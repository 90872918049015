import React, { useState, useEffect, Fragment } from "react";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import Button from "../../../../components/common/Button";
import api from "../../../../api";

export default function AssetServices({ asset, canUpdate }) {
  const navigate = useNavigate();

  const [services, setServices] = useState([]);

  useEffect(() => {
    api.assets.service.all(asset._id).then((res) => {
      setServices(res);
    });
  }, [asset]);

  return (
    <>
      <div className="p-2">
        <div>
          <div className="flex items-center justify-center gap-4 border-b border-gray-700 p-6">
            <div className="text-xl text-gray-400">
              Next Service:{" "}
              <span className="text-2xl text-white ">
                {asset.NextService
                  ? dayjs(asset.NextService).format("DD-MM-YYYY")
                  : "--"}
              </span>{" "}
            </div>
            <div>
              {/* <Button
               style={"text-lg"}
               text="Update Assignment"
               positive
               action={AssignAsset}
               /> */}
            </div>
          </div>
        </div>
      </div>
      <div className="px-4 py-2">
        <Button
          onClick={() => navigate(`/asset/view/${asset._id}/service/add`)}
          text="Add Service"
          colour="bg-green-700 text-white"
        />
      </div>

      <div>
        <table className="tableClass">
          <thead>
            <tr className="tableHeadRowClass">
              <th className="tableHeadCellClass">Scheduled Date</th>
              <th className="tableHeadCellClass ">Type</th>
              <th className="tableHeadCellClass ">Status</th>
              <th className="tableHeadCellClass ">Serviced Date</th>
            </tr>
          </thead>
          <tbody className="tableBodyClass">
            {services.map((s) => {
              return (
                <tr
                  className="tableBodyRowClass"
                  key={s._id}
                  onClick={() =>
                    navigate(`/asset/view/${asset._id}/service/${s._id}`)
                  }
                >
                  <td className="tableBodyCellClass">
                    {s.date_scheduled
                      ? dayjs(s.date_scheduled).format("DD-MM-YYYY")
                      : "--"}
                  </td>
                  <td className="tableBodyCellClass">{s.type}</td>
                  <td className="tableBodyCellClass">{s.status}</td>
                  <td className="tableBodyCellClass">
                    {s.date_serviced
                      ? dayjs(s.date_serviced).format("DD-MM-YYYY")
                      : "--"}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {/* </Box> */}
    </>
  );
}
