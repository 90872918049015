import React, { useState, useEffect } from "react";
import { Transition, Dialog } from "@headlessui/react";
import Select from "./Select";
import { PlusIcon, XMarkIcon, CheckIcon } from "@heroicons/react/20/solid";
import ConfirmBox from "./Confirm";
import { AuthComponents } from "../../lib/_sso";
import API from "../../api";

const RolesCard = ({ data, onAdd, onDelete }) => {
  const [roles, setRoles] = useState([]);

  const [show, setShow] = useState(false);
  const [value, setValue] = useState("");
  const [confirm, setConfirm] = useState(false);

  useEffect(() => {
    API.settings.getRoles().then((res) => {
      setRoles(res.map((r) => {
        return r.name;
      }));
    })
  }, [])

  const handleAdd = () => {
    onAdd(value).then(() => {
      setShow(false);
      setValue("");
    });
  };
  const handleDelete = () => {
    onDelete(value).then(() => {
      setConfirm(false);
      setValue("");
    });
  };

  const modal = () => {
    return (
      <Transition
        show={show}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Dialog
          open={show}
          onClose={() => setShow(false)}
          className="fixed inset-0 z-50 overflow-y-auto "
        >
          <div className="min-h-screen text-center">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-80" />
            <span className="inline-block h-screen " aria-hidden="true">
              &#8203;
            </span>
            <div className="my-8 inline-block w-full max-w-2xl transform overflow-hidden rounded-2xl border-4 border-gray-700 bg-zinc-200 p-6 text-left align-middle shadow-xl transition-all dark:bg-zinc-800">
              <div className="border-b border-zinc-500 pt-4 text-2xl">
                <Dialog.Title>Assign Role</Dialog.Title>
              </div>
              <div className="pt-4">
                <Select
                  options={
                    (roles !== undefined &&
                      roles.sort((a, b) => {
                        if(a > b) return 1;
                        if(a < b) return -1;
                        return 0;
                      })
                        .filter((item) => {
                          if (data !== undefined) {
                            const aa = data.filter(a => {
                              if(a.role !== undefined && a.role.name !== undefined){
                                return a.role.name === item
                              }
                              return a.name === item;
                            });
                            return aa.length === 0;
                          }
                          return true;
                        })
                        .map((item, index) => {
                          return { key: index, text: item, value: item };
                        })) ||
                    []
                  }
                  onChange={(e) => setValue(e.target.value)}
                />
              </div>

              <div className="mt-4 flex justify-between border-t border-gray-700 pt-4">
                <button
                  className="rounded-lg bg-red-600 px-4 py-2 text-white"
                  onClick={() => setShow(false)}
                >
                  Cancel
                </button>
                <button
                  className="rounded-lg bg-blue-600 px-4 py-2 text-white"
                  onClick={handleAdd}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  };

  return (
    <div className="">
      {modal()}
      {confirm !== false && <ConfirmBox show={true} action={handleDelete} title={`Remove role "${value.role?.name || "[UNKNOWN]"}"?`} onClose={() => setConfirm(false)} />}

      <div className="flex justify-between border-b border-zinc-500  text-2xl">
        Roles
        <span className="">
          <AuthComponents.Can scope="members:update">
            <button
              onClick={() => {
                setShow(true);
              }}
            >
              <PlusIcon className="h-6 text-green-500" />
            </button>
          </AuthComponents.Can>
        </span>
      </div>
      <div className="flex flex-wrap gap-2 p-2 ">
        {data.sort((a, b) => {
          if((a.role?.name || "[UNKNOWN]") > (b.role?.name || "[UNKNOWN]")) return 1;
          if((a.role?.name || "[UNKNOWN]") < (b.role?.name || "[UNKNOWN]")) return -1;
          return 0;
        }).map((d, i) => {
          return (
            <div
              key={i}
              className={`flex gap-4 rounded ${
                d.isActive ? "oncall" : "nonOp"
              } p-2 dark:text-white`}
            >
              <div className="flex w-full flex-col justify-center">
                <div className="text-md"> {d.role?.name || "[UNKNOWN]"}</div>
                <div className="flex w-full items-center gap-2">
                  {d.role?.requiresCPD && (
                    <div className="flex items-center text-xs text-zinc-400">
                      CPD:
                      {d._cpdActive ? (
                        <CheckIcon className="h-4 text-green-400" />
                      ) : (
                        <XMarkIcon className="h-4 text-red-400" />
                      )}
                    </div>
                  )}
                  {d.role?.requiresCompetency && (
                    <div className="flex items-center text-xs text-zinc-400">
                      Comp.:
                      {d._compActive ? (
                        <CheckIcon className="h-4 text-green-400" />
                      ) : (
                        <XMarkIcon className="h-4 text-red-400" />
                      )}
                    </div>
                  )}
                  {d.role?.requiresCourse && (
                    <div className="flex items-center text-xs text-zinc-400">
                      Course.:
                      {d._courseActive ? (
                        <CheckIcon className="h-4 text-green-400" />
                      ) : (
                        <XMarkIcon className="h-4 text-red-400" />
                      )}
                    </div>
                  )}
                </div>
              </div>
              <AuthComponents.Can scope="members:update">
                <sup>
                  <button
                    onClick={() => {
                      setValue(d);
                      setConfirm(true);
                    }}
                  >
                    <XMarkIcon className="ml-3 h-4 hover:text-red-500" />
                  </button>
                </sup>
              </AuthComponents.Can>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default RolesCard;
