import React, { Component } from "react";
import { Routes, Route, Outlet, Link } from "react-router-dom";
import TeamList from "./TeamList";
import AddMember from "./AddMember";
import MemberPages from "./member";
import ArchivedTeamList from "./ArchivedTeamList";
import {AuthComponents} from "../../lib/_sso";

export default class TeamPages extends Component {
  render() {
    return (
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<TeamList />} />
          <Route path="/add" element={<AddMember />} />
          <Route path="/archived" element={<ArchivedTeamList />} />
          <Route path="/:member_id/*" element={<MemberPages />} />
          <Route path="*" element={<NoMatch2 />} />
        </Route>
      </Routes>
    );
  }
}
function Layout() {
  const scope = ["teamsite:admin", "teamsite:management"];

  return (
    <div className="min-h-full">
      <main>
        <AuthComponents.Can scope={scope}>
          <Outlet />
        </AuthComponents.Can>
        <AuthComponents.CanNot scope={scope}>
          <div className={"p-2 text-red-500"}>Access Denied: You do not have permission to access this page.</div>
        </AuthComponents.CanNot>
      </main>
    </div>
  );
}
function NoMatch2() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}
