import React, {useRef, useState, useEffect} from "react";
import DatePicker from "react-datepicker";
import Geosuggest from "react-geosuggest";
import DayJS from "dayjs";
import Input from "../../../components/common/Input";
import Select from "../../../components/common/Select";
import TextArea from "../../../components/common/TextArea";
import DataRow from "../../../components/common/DataRow";
import Button from "../../../components/common/Button";
import ChainedContext from "../../../contexts/chainedContext";
import OrganisationReceiver from "../../../contexts/organisation/receiver";

const IncidentTypes = [
  {
    key: 1,
    text: "Search",
    value: "search"
  }
];

const IncidentForm = ({ editIncident = null, onSubmit, organisation }) => {
  const [errors, setErrors] = useState({});
  const [data, setData] = useState({
    refNum: "",
    name: "",
    outcome: "",
    type: "",
    opened: ""
  });
  const _geoSuggest = useRef(null);

  useEffect(() => {
    if(editIncident !== null){
      setData({
        ...editIncident,
        opened: DayJS(editIncident.opened).toDate()
      });
    }
  }, [editIncident]);

  const onChange = e => {
    setData({...data, [e.target.name]: e.target.value});
  };
  const onSuggestSelect = async (suggest) => {
    // console.log(suggest);
    if (suggest) {
      let coords = [suggest.location.lng, suggest.location.lat];

      let components = suggest.gmaps.address_components;
      let admin_area = "";
      let postal_town = "";
      let postal_code = "";
      // eslint-disable-next-line
      let name = "";
      // eslint-disable-next-line
      let street = "";
      let county = "";
      let town = "";
      if (suggest.gmaps.name) {
        name = suggest.gmaps.name;
      }

      for (let component = 0; component < components.length; component++) {
        if (components[component].types[0] === "premise" && name === "") {
          name = components[component].long_name;
        }
        if (components[component].types[0] === "locality") {
          admin_area = components[component].long_name;
        }
        if (components[component].types[0] === "postal_town") {
          postal_town = components[component].long_name;
        }
        if (components[component].types[0] === "administrative_area_level_2") {
          county = components[component].long_name;
        }
        if (components[component].types[0] === "postal_code") {
          postal_code = components[component].long_name;
        }
        if (admin_area) {
          town = admin_area;
        } else {
          town = postal_town;
        }
      }

      setData({
        ...data,
        geoJSON: {
          geometry: {
            type: "Point",
            coordinates: coords,
          },
          properties: {
            ...data.properties,
            name: name,
            town: town,
            county: county,
            postcode: postal_code,
            address: suggest.gmaps.formatted_address,
          },
        },
      });
    }
  };
  const validate = (rawData) => {
    const newErrors = {};

    if(!rawData.refNum) newErrors.refNum = "Can not be empty";
    if(!rawData.name) newErrors.name = "Can not be empty";
    if(!rawData.type) newErrors.type = "Can not be empty";
    if(!rawData.opened) newErrors.opened = "Can not be empty";
    if(!rawData.outcome) newErrors.outcome = "Can not be empty";
    if(!rawData.geoJSON) newErrors.geoJSON = "Can not be empty";

    return newErrors;
  }
  const handleSubmit = () => {
    const errors = validate(data);
    setErrors(errors);

    if (Object.keys(errors).length === 0) {
      onSubmit(data);
    }
  };

  return (
    <>
      <div className="flex justify-around gap-4 p-2">
        <div className={"w-full"}>
          <div className="flex justify-around gap-4 p-2">
            <div className="w-32">
              <Input label={"ID"} value={data.refNum} field={"refNum"} onChange={onChange} error={errors.refNum !== undefined}/>
              <span className={"text-red-500"}>{errors.refNum}</span>
            </div>
            <div className={"w-full"}>
              <Input label={"Name"} value={data.name} field={"name"} onChange={onChange} error={errors.name !== undefined}/>
              <span className={"text-red-500"}>{errors.name}</span>
            </div>
          </div>
          <div className="flex justify-around gap-4 p-2">
            <div className={"w-full"}>
              <Select
                label={"Type"}
                placeholder={"pick one"}
                field={"type"}
                value={data.type}
                options={IncidentTypes}
                onChange={onChange}
                error={errors.type !== undefined}
              />
              <span className={"text-red-500"}>{errors.type}</span>
            </div>
            <div>
              <label className={`${errors.opened !== undefined ? "text-red-400" : "labelClass"}`}>Date Opened</label>
              <DatePicker
                name="opened"
                onChange={(date) => setData({...data, opened: date})}
                dateFormat="dd-MM-yyyy"
                maxDate={new Date()}
                className="inputClass z-50"
                selected={data.opened}
              />
              <span className={"text-red-500"}>{errors.opened}</span>
            </div>
          </div>
          <div className="p-2">
            <TextArea label={"Outcome"} value={data.outcome} field={"outcome"} onChange={onChange} error={errors.outcome !== undefined}/>
            <span className={"text-red-500"}>{errors.outcome}</span>
          </div>
        </div>
        <div className="w-full p-2">
          <label className={`${errors.geoJSON !== undefined ? "text-red-400" : "labelClass"}`}>Location</label>
          <Geosuggest
            autoComplete="off"
            ref={_geoSuggest}
            placeholder="Molash, Kent"
            inputClassName="inputClass"
            country={organisation.country}
            onSuggestSelect={onSuggestSelect}
            suggestsClassName="dark:bg-zinc-700 dark:text-white bg-zinc-200 text-black"
            suggestsHiddenClassName="geosuggest__suggests--hidden"
            suggestItemClassName="px-1 py-2 border-b border-gray-500 cursor-pointer"
          />
          <span className={"text-red-500"}>{errors.geoJSON}</span>

          {data.geoJSON && (
            <div className="p-2">
              <DataRow label={"Town"} value={data.geoJSON?.properties?.town}/>
              <DataRow label={"County"} value={data.geoJSON?.properties?.county}/>
              <DataRow label={"Postcode"} value={data.geoJSON?.properties?.postcode}/>
            </div>
          )}
        </div>
      </div>
      <div className="p-2">
        <Button text={editIncident !== null ? "Save" : "Add"} onClick={handleSubmit} colour="bg-green-700 text-white"/>
      </div>
    </>
  );
}
export default ChainedContext(IncidentForm, [
  [OrganisationReceiver, "organisation"]
]);